import { Component, OnInit } from '@angular/core';
import {IMenuItem} from '@app/services/navigation/navigation.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  adminMenu: IMenuItem[] = [
      {
        name: 'COURSES',
        type: 'menuItem',
        tooltip: 'Courses',
        icon: 'border_color',
        state: '/admin/course-dashboard'
      }, {
        name: 'NOTIFICATIONS',
        type: 'link',
        tooltip: 'Notifications',
        icon: 'notifications',
        state: '/admin/notifications'
      }, {
        name: 'REPORTS',
        type: 'link',
        tooltip: 'Reports',
        icon: 'show_chart',
        state: '/admin/reports'
      }, {
        name: 'BADGES',
        type: 'link',
        tooltip: 'Badges',
        icon: 'show_chart',
        state: '/admin/badges'
      }, {
        name: 'CERTIFICATES',
        type: 'link',
        tooltip: 'Certificates',
        icon: 'show_chart',
        state: '/admin/certificates'
      }, {
        name: 'ORDERS',
        type: 'link',
        tooltip: 'Orders',
        icon: 'shopping_cart',
        state: '/admin/user-order-history'
      }, {
        name: 'SYSTEM SETTINGS',
        type: 'link',
        tooltip: 'System Settings',
        icon: 'settings',
        state: '/admin/configuration'
      }
    ];

  constructor() { }

  ngOnInit() {
  }

}
