import {
  Component,
  Inject,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Editor } from 'ngx-editor';

import { MenuItem, MenuItemName } from '@app/models/menu/menu-item';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements OnInit {
  hasDropZoneOver: Boolean = false;
  uploader: FileUploader;
  @Input() editor: Editor;
  @Input() url?: string;
  @Input() title: string;
  @Input() uploader_only: boolean;
  @Input() subtext?: string;
  @Input() editorId: string;
  @Output() response_url? = new EventEmitter();
  @Input() requiredImageWidth: number;
  @Input() requiredImageHeight: number;
  incorrectImageSize = false;

  menuItems: MenuItem[] = [
    {
      name: MenuItemName.Remove,
      action: 'button',
      mat_icon: '',
      image_url: 'trash.png',
    },
  ];
  menu_style = {
    width: '180px;',
    'max-width': '100%;',
    'overflow-x': 'hidden;',
    'background-color': '#000',
  };

  constructor(
    @Inject('RichTextImageUploadUrl') private imageUploadUrl: string
  ) {
    this.uploader = new FileUploader({
      url: imageUploadUrl,
      autoUpload: true,
    });


    this.uploader.onBeforeUploadItem = (fileItem) => {
      this.checkImageDimensions(fileItem._file).then(valid => {
        if (!valid) {
          this.incorrectImageSize = true;
          // If image dimensions are not valid, remove it from the upload queue
          for (let i = this.uploader.queue.length - 1; i >= 0; i--) {
            if (this.uploader.queue[i] === fileItem) {
              this.uploader.queue.splice(i, 1);
              break;
            }
          }
          this.removeImageAndUrl();
        } else {
          this.incorrectImageSize = false;
        }
      });
    }

    this.uploader.response.subscribe(response => {
      if (!this.incorrectImageSize) {
        const { url } = JSON.parse(response);
        this.uploader_only
          ? this.uploaderConfig(url)
          : this.editor.commands.insertNewLine().insertImage(url).exec();
        }
    });
  }

  ngOnInit(): void {}

  checkImageDimensions(file: File): Promise<boolean> {
    return new Promise((resolve) => {
      const fileReader = new FileReader();

      fileReader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          if (this.requiredImageHeight && this.requiredImageWidth) {
            const width = image.width;
            const height = image.height;

            resolve(width === this.requiredImageWidth && height === this.requiredImageHeight);
          } else {
            resolve(true);
          }
        };
      };
      fileReader.readAsDataURL(file);
    });
  }

  uploaderConfig(url) {
    this.url = url;
    this.response_url.emit(url);
  }

  fileOver(event) {
    this.hasDropZoneOver = event;
  }

  removeImageAndUrl() {
    this.url = '';
    this.response_url.emit('');
  }

  removeUrl(e) {
    const { item } = e;
    item.name === MenuItemName.Remove ? (this.url = '') : '';
    this.response_url.emit('');
  }

  imageUploadErrorText() {
    return `Image dimensions must be ${this.requiredImageWidth} x ${this.requiredImageHeight}px. NO SOUP FOR YOU!`;
  }
}
