import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AssignmentService} from '@app/services/data-services/assignment.service';

@Component({
  selector: 'app-assignments-manager-overview',
  templateUrl: './assignments-manager-overview.component.html',
  styleUrls: ['./assignments-manager-overview.component.scss']
})
export class AssignmentsManagerOverviewComponent implements OnInit {

  selected = [];
  rows = [];
  temp = [];

  constructor(private router: Router, private assignmentService: AssignmentService) { }

  ngOnInit() {
    this.assignmentService.getInstructorAssignments()
      .then((assignments) => this.rows = this.temp = assignments);
  }

  addAssignment() {
    this.router.navigate(['instructor/classroom/assignments/add']);
  }

  editAssignment(row) {
    this.router.navigate(['instructor/classroom/assignments/manage', row.id]);
  }

  deleteAssignment(row) {

  }

}
