export class Badge {
  id?: number;
  name?: string;
  desc?: string;
  badge_image?: any;
  progress?: number;
  isActive?: boolean;

  constructor() {
    this.isActive = false;
  }
}
