import {Task} from '@app/models/course/content/task/task';
import {TaskAttempt} from "@app/models/course/content/task/task-attempt";

export class ContentTask {
  id?: number;
  task?: Task;
  content_id?: number;
  required_successful_attempts?: number;
  order?: number;
  standards?: string[];
  task_id?: number;
  task_type?: string;
  success?: number;
  failed?: number;
  perfect?: number;
  is_deferred?: boolean;

  constructor() {
    this.task_type = 'practice';
  }
}
