import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromApp from '../../../../../../../store/app.reducers';
import {ContentTask} from '@app/models/course/content/content-task';
import {TaskAttempt} from '@app/models/course/content/task/task-attempt';
import {Subscription} from 'rxjs';
import {DatePipe, LocationStrategy} from '@angular/common';
import {Content} from '@app/models/course/content/content';
import * as AssessmentActions from '../../store/assessment.actions';
import {TaskService} from '@app/services/data-services/task.service';
import {ProgressCalculatorService} from '@app/services/helper/progress-calculator.service';
import {UserProgress} from '@app/models/user/user-progress';
import {StudentAssessmentsStandardsDialogComponent} from '../student-assessments-standards-dialog/student-assessments-standards-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Task } from "@app/models/course/content/task/task";

@Component({
  selector: 'app-student-assessments-task-detail',
  templateUrl: './student-assessments-task-detail.component.html',
  styleUrls: ['./student-assessments-task-detail.component.scss']
})
export class StudentAssessmentsTaskDetailComponent implements OnInit, OnDestroy {
  selectedTask: Task;
  selectedTaskAttempts: TaskAttempt[];
  selectedAssessment: Content;
  subscription: Subscription = new Subscription();
  task_progress = '';
  completed_attempts = 0;
  progress = 0;
  sorted = false;
  datePipe = new DatePipe('en-US');
  user_id: number;
  course_attempt_id: number;
  course_id: number;
  stage_id: number;
  lesson_id: number;
  content_id: number;
  progresses: UserProgress[];
  isCompleted = false;

  constructor(private store: Store<fromApp.AppState>,
              private location: LocationStrategy,
              public dialog: MatDialog,
              private calculatorService: ProgressCalculatorService,
              private taskService: TaskService) { }

  ngOnInit() {
    this.subscription.add(
      this.store.select('assessment').subscribe(state => {
        this.selectedTask = state.selectedTaskToView;
        this.selectedTaskAttempts = state.selectedTaskToViewAttempts;
        this.selectedAssessment = state.selectedAssessment;
        this.user_id = state.selectedStudent.id;
        this.course_id = state.selectedCourse.course_id;
        this.course_attempt_id = state.selectedCourse.course_attempt_id;
        this.stage_id = state.selectedAssessment.stage_id;
        this.lesson_id = state.selectedAssessment.lesson_id;
        this.content_id = state.selectedAssessment.id;
        this.progresses = state.assessmentProgress;
        this.isCompleted = state.isCompleted;
        this.checkCompleted();
      })
    );
  }

  getPerfect(task_id: number, content_id: number) {
    return this.selectedTaskAttempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && (task_attempt.perfect)).length;
  }

  getPassed(task_id: number, content_id: number) {
    return this.selectedTaskAttempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && (task_attempt.success || task_attempt.perfect)).length;
  }

  getMissed(task_id: number, content_id: number) {
    return this.selectedTaskAttempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && (!task_attempt.success)).length;
  }

  checkCompleted() {
    if (this.selectedTask.task_type.toString() === 'practice') {
      this.completed_attempts = 0;
      const content_task = this.selectedTask;
      const num_attempts = this.selectedTaskAttempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'practice' && task_attempt.content_id === content_task.content_id && task_attempt.task_id === content_task.id).length;
      const progress = num_attempts >= content_task.required_successful_attempts ? 'Completed' : num_attempts > 0 ? 'In Progress' : 'Not Started';
      this.task_progress = progress;
      this.completed_attempts = num_attempts;

      const task_progress = (this.completed_attempts / this.selectedTask.required_successful_attempts) * 100;
      this.progress = task_progress >= 0 && task_progress <= 100 ? task_progress >= 100 ? 100 : 0 : task_progress;
    } else {
      this.completed_attempts = 0;
      const content_task = this.selectedTask;
      const num_attempts = this.selectedTaskAttempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.content_id === content_task.content_id && task_attempt.task_id === content_task.id && (task_attempt.perfect || task_attempt.success)).length;
      const missed_attempts = this.selectedTaskAttempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.content_id === content_task.content_id &&  task_attempt.task_id === content_task.id && task_attempt.failed).length;
      const progress = num_attempts >= content_task.required_successful_attempts ? 'Completed' : (num_attempts || missed_attempts) > 0 ? 'In Progress' : 'Not Started';
      this.task_progress = progress;
      this.completed_attempts = num_attempts;

      const task_progress = (this.completed_attempts / this.selectedTask.required_successful_attempts) * 100;
      this.progress = task_progress >= 0 && task_progress <= 100 ? task_progress >= 100 ? 100 : 0 : task_progress;
    }
  }

  removeAttempt(row) {
    const task_progress = this.task_progress;
    this.taskService.remove_attempts([row])
      .then(res => {
        this.store.dispatch(new AssessmentActions.RemoveAttempt(row.id));
        this.checkCompleted();
        if (task_progress === 'Completed' && task_progress !== this.task_progress) {
          this.updateOrCreateInProgress(1);
        }
      });
  }

  updateOrCreateInProgress(status?) {
    this.calculatorService.setProgresses(this.progresses);
    const tempStatus = status ? status : 1;
    this.calculatorService.syncProgressForContent(this.course_attempt_id,{course_id: this.course_id}, {id: this.stage_id}, {id: this.lesson_id}, {id: this.content_id}, null, tempStatus, false, this.user_id)
      .then((res) => {
        if (res) {
          this.store.dispatch(new AssessmentActions.AddAssessmentProgress(res));
        }
      });
  }

  reasonMissed(row) {
    this.dialog.open(StudentAssessmentsStandardsDialogComponent, {
      hasBackdrop: true,
      data: { missed: row.standards_missed, standards: this.selectedTask.standards }
    });
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
