import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Task } from '@app/models/course/content/task/task';
import { TaskService } from '@app/services/data-services/task.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Lesson} from '@app/models/course/workbook/lesson/lesson';

@Component({
  selector: 'app-task-manager-overview',
  templateUrl: './task-manager-overview.component.html',
  styleUrls: ['./task-manager-overview.component.scss']
})
export class TaskManagerOverviewComponent implements OnInit {
  selected: any[] = [];
  rows: any[] = [];
  temp: any[] = [];
  task: Task = new Task();
  @ViewChild('table', { static: true }) table: DatatableComponent;
  expanded: any = {};
  isLoading = true;
  archived: any[] = [];
  published: any[] = [];

  constructor(private taskService: TaskService, private router: Router) { }

  ngOnInit() {
    this.getTasks();
  }

  getTasks(){
    this.isLoading = true;
    this.taskService.getTasksOverview()
      .then((tasks) => {
        this.archived = tasks.filter( (item) => item.is_archived === true);
        this.published = tasks.filter( (item) => item.is_archived === false || item.is_archived == null);
        this.rows = this.temp = this.published;

        this.isLoading = false;
      })
      .catch(() => this.isLoading = false);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  filterTasks(type, event) {
    this.rows = this.temp = type;
    const element = event.target;
    let active = document.getElementsByClassName('is-active');
    while (active.length) active[0].classList.remove('is-active');
    element.classList.add('is-active');
  }

  updateArchive(task) {
    task.is_archived = !task.is_archived;
    this.taskService.updateTask({id: task.id, is_archived: task.is_archived})
      .then(() => {
        if(task.is_archived == false) {
          this.published.push(task);
          this.rows = this.archived = this.archived.filter((item) => item !== task);
        } else {
          this.archived.push(task);
          this.rows = this.published = this.published.filter((item) => item !== task);
        }
      });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function(task) {
      if (task) {
        if (task.name) {
          return ((task.name.toLowerCase().indexOf(val) !== -1 || !val));
        } else if (task.task_category.name) {
          return ((task.task_category.name.toLowerCase().indexOf(val) !== -1 || !val));
        } else {
          return false;
        }
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

  editTask(task: Task) {
    this.router.navigate(['admin/courses/task/manage', task.id]);
  }
}
