import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Badge} from '@app/models/course/course-badge';
import {slideInRight} from '@app/animations/animations';
import {BadgesService} from '@app/services/data-services/badges.service';
import {FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {UserService} from '@app/services/data-services/user.service';
import {HttpService} from '@app/services/http-service.service';
import {SnackbarComponent} from '@app/components/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector:    'app-add-badge',
  templateUrl: './add-badge.component.html',
  styleUrls:   [ './add-badge.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddBadgeComponent implements OnInit {
  badge: Badge = new Badge();

  public uploader: FileUploader = new FileUploader({ });
  subscription: any;

  constructor(private router: Router,
              private badgeService: BadgesService,
              public snackbar: MatSnackBar,
              private httpService: HttpService) { }

  ngOnInit() {
    this.initUploader();
  }

  upload() {
    this.uploader.uploadAll();
    this.snackbar.open('Badge created.', '', {
      duration: 2500
    });
  }

  addBadge() {
    this.badgeService.create(this.badge)
      .then(() => this.confirmMessage());
  }

  confirmMessage() {
    const snackbar = this.snackbar.openFromComponent(SnackbarComponent, {
      data: 'Badge Created'
    });
    this.subscription = snackbar.afterOpened().subscribe(() => {
      this.router.navigate(['/admin/badges']);
    });
  }

  cancel() {
    this.router.navigate(['admin/badges']);
  }

  initUploader() {
    const url = this.httpService.getFullUrl('images/upload');
    this.uploader.setOptions({url: url});
    this.uploader.authTokenHeader = 'Access-Token';
    this.uploader.authToken = localStorage.getItem('cirrus-token');
    this.uploader.onAfterAddingFile = (item => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.badge.badge_image = JSON.parse(response).location;
      this.addBadge();

    };
  }

}
