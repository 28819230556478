<small class="time">{{ invitation.notified_at | date : 'MMM dd, yyyy' }}</small>

<h6 class="pending-connection">
  Pending Connection: {{ invitation?.sender?.contact?.name }}
</h6>

<h4 class="message">{{ invitation.body }}</h4>

<div class="button-group">
  <span class="primary-btn pointer" (click)="acceptInvitation()"> Accept </span>
  <span class="secondary-btn pointer" (click)="declineInvitation()">
    Decline
  </span>
</div>
