
import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { RoutePartsService, IRoutePart } from '../../../services/route-parts.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  routeParts: IRoutePart[];
  showBreadCrumb: boolean = true;

  constructor(
    private router: Router,
    private routePartsService: RoutePartsService,
    private activeRoute: ActivatedRoute
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      this.routeParts.reverse().map((item) => {
        if(item.link  === "admin/lessons/add") {
          this.showBreadCrumb = false;
          return;
        }
        return this.parseText(item);
      });
    });
  }

  ngOnInit() {}

  parseText(part) {
    part.link = part.link.replace(/{{([^{}]*)}}/g, function (a, b) {
      let r = part.params[b];
      if (!r) {
        r = part.parentParams[b];
      }
      return typeof r === 'string' ? r : a;
    });

    return part;
  }

}
