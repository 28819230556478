
export class MenuItem {
  name: MenuItemName;
  action: string;
  mat_icon?: string;
  image_url?: string;
}



export enum MenuItemName {
  Required= 'Required to Complete Lesson',
  Hidden = "Hidden",
  Remove = "Clear Content",
  EditSectionName = 'Edit Section Name',
  InstructorOnly = 'Instructor Only',
  Duplicate = 'Duplicate',
  Edit = 'Edit',
  Enable = 'Enable'
}
