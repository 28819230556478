import { Connection } from '@app/models/connection';
import { TrainingPartner } from '@app/models/training-partner';
import { RowAction } from './row-action';

export class TrainingPartnerRow {
  constructor(
    public trainingPartner: TrainingPartner | Connection,
    public action: RowAction,
  ) {}
}
