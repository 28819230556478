import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ShortTextPipe} from '@app/pipes/short-text.pipe';
import {Assignment} from '@app/models/course/assignment';
import {AssignmentService} from '@app/services/data-services/assignment.service';

@Component({
  selector: 'app-select-assignment-dialog',
  templateUrl: './select-assignment-dialog.component.html',
  styleUrls: ['./select-assignment-dialog.component.scss']
})
export class SelectAssignmentDialogComponent implements OnInit {

  assignments: Assignment[] = [];
  selected: any[] = [];
  selectedAssignment: Assignment;
  shortPipe = new ShortTextPipe();
  columns = [{name: 'Name'}, {name: 'Description', prop: 'desc', pipe: this.shortPipe}];

  constructor(public dialogRef: MatDialogRef<SelectAssignmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private assignmentService: AssignmentService) { }

  ngOnInit() {
    this.assignmentService.getInstructorAssignments()
      .then(assignments => this.assignments = assignments);
  }

  onSelect(row) {
    this.selectedAssignment = row.selected[0];
  }

  save() {
    if (this.selectedAssignment) {
      this.dialogRef.close(this.selectedAssignment);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
