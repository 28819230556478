import { Injectable } from '@angular/core';
import { TaskCategory } from '@app/models/course/content/task/task-category';
import {HttpService} from '@app/services/http-service.service';

@Injectable()
export class TaskCategoryService {
  taskCategoryUrl = 'task_categories';

  constructor(private http: HttpService) { }

  getCategories(): Promise<TaskCategory[]> {
    return this.http.get<TaskCategory[]>(this.taskCategoryUrl)
      .toPromise()
      .catch(TaskCategoryService.handleError);
  }

  getCategory(id: number): Promise<TaskCategory> {
    const url = `${this.taskCategoryUrl}/${id}`;

    return this.http.get(url)
      .toPromise()
      .catch(TaskCategoryService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.taskCategoryUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(TaskCategoryService.handleError);
  }

  create(taskCategory: TaskCategory): Promise<TaskCategory> {
    return this.http
      .post<TaskCategory>(this.taskCategoryUrl, JSON.stringify(taskCategory))
      .toPromise()
      .catch(TaskCategoryService.handleError);
  }

  update(taskCategory: TaskCategory): Promise<TaskCategory> {
    const url = `${this.taskCategoryUrl}/${taskCategory.id}`;
    return this.http
      .put(url, JSON.stringify(taskCategory))
      .toPromise()
      .then(() => taskCategory)
      .catch(TaskCategoryService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
