import { Injectable } from '@angular/core';
import { Aircraft } from '@app/models/aircraft/aircraft';
import { HttpService } from '@app/services/http-service.service';
import { PurchaseItem } from '@app/models/purchase-item';

@Injectable()
export class PurchaseService {

  purchasesUrl = 'purchases';

  constructor(private http: HttpService) {}

  purchase(items: PurchaseItem[]): Promise<Aircraft[]> {
    return this.http.post<Aircraft[]>(this.purchasesUrl, JSON.stringify({courses: items}))
      .toPromise()
      .catch(PurchaseService.handleError);
  }


  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
