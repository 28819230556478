import { ActionReducerMap } from '@ngrx/store';

import * as fromCourse from '../ui/user/course/store/course.reducers';
import * as fromCheckout from '../ui/user/checkout/store/checkout.reducers';
import * as fromAssessment from '../ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/store/assessment.reducers';

export interface AppState {
  course: fromCourse.State;
  checkout: fromCheckout.State;
  assessment: fromAssessment.State;
}

export const reducers: ActionReducerMap<AppState> = {
  course: fromCourse.courseReducer,
  checkout: fromCheckout.checkoutReducer,
  assessment: fromAssessment.assessmentReducer
};
