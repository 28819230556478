<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">User Orders</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-spinner *ngIf="!temp"></mat-spinner>
      <mat-card-content *ngIf="temp">
        <div class="full-width pb-1">
          <input
            type='text'
            style='padding:8px;margin:15px auto;width:30%;'
            placeholder='Filter Orders'
            (keyup)='updateFilter($event)'
          />
        </div>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable #table
                         class="material bg-white"
                         [loadingIndicator]="!temp"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows">
            <ngx-datatable-column name="Order Id" prop='id'></ngx-datatable-column>
            <ngx-datatable-column name="Email" prop='user_email'></ngx-datatable-column>
            <ngx-datatable-column
              name="Order Status" prop="order_status">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{orderStatusMap[orderStatus[row.order_status]]}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Created Date" prop='created_at' [pipe]="datePipe"></ngx-datatable-column>
            <ngx-datatable-column name="Completed Date" prop='completed_date' [pipe]="datePipe"></ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false">
              <ng-template  let-row='row' ngx-datatable-cell-template>
                <button mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">
                  <mat-icon class="text-muted">more_vert</mat-icon>
                </button>
                <mat-menu #msgMenu="matMenu">
                  <button mat-menu-item (click)='viewOrder(row)'>View Order</button>
                </mat-menu>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
