import { Component, OnInit } from '@angular/core';
import { Aircraft } from '@app/models/aircraft/aircraft';
import { AircraftService } from '@app/services/data-services/aircraft.service';
import { slideInRight } from '@app/animations/animations';
import { AvionicsService } from '@app/services/data-services/avionics.service';
import { Avionics } from '@app/models/aircraft/avionics';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddAvionicsDialogComponent } from './add-avionics-dialog/add-avionics-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-aircraft',
  templateUrl: './add-aircraft.component.html',
  styleUrls: ['./add-aircraft.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddAircraftComponent implements OnInit {
  aircraft: Aircraft;
  avionics: Avionics[];
  dialogRef: any;

  constructor(private aircraftService: AircraftService,
              private avionicsService: AvionicsService,
              private router: Router,
              public dialog: MatDialog,
              public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.aircraft = new Aircraft();
  }

  addAircraft() {
    this.aircraftService.create(this.aircraft)
      .then(() => {
        this.snackbar.open('Updated')
        this.aircraft = new Aircraft()
      });
  }

  cancel() {
    this.router.navigate(['admin/courses/aircraft']);
  }

  openDialog(content) {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(AddAvionicsDialogComponent, {
      width,
      height,
      data: content
    });
  }

  addAvionics() {
    this.openDialog(this.aircraft);

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.aircraft.avionics = data;
      }
    });
  }

}
