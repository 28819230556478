import {Component, OnInit, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {GlobalEventManagerService} from '../../../services/global-event-manager/global-event-manager.service';
import {HelpVideoDialogComponent} from './help-video-dialog/help-video-dialog.component';
import {FaqService} from '@app/services/data-services/faq.service';
import {Faq} from '@app/models/faq';
import {DomSanitizer} from '@angular/platform-browser';
import {NavigationService} from '@app/services/navigation/navigation.service';
import * as fromApp from '../../../store/app.reducers';
import {Store} from '@ngrx/store';


@Component({
    selector: 'app-help',
    // providers: [HelpPageService],
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
    scrolling = false;
    subs: any[] = [];
    showNav = true;
    menuType = 'help';
    faqsSorted = [];
    faqs: Faq[] = [];
    subscription: any;


    constructor(private globalEventManagerService: GlobalEventManagerService,
                private navigationService: NavigationService,
                private faqService: FaqService,
                public dialog: MatDialog,
                private domSanitizer: DomSanitizer,
                private store: Store<fromApp.AppState>) {
    }

    isNavOver() {
        return window.matchMedia(`(max-width: 960px)`).matches;
    }


    openDialog() {
        const dialogRef = this.dialog.open(HelpVideoDialogComponent, {
            width: '60%',
            // data: content
        });
    }

    scrollToPosition(selectedSection: any) {
        const section = document.getElementById(selectedSection);

        if (section) {
            window.scroll({
                top: section.offsetTop + section.offsetHeight / 4,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    ngOnInit() {
        this.globalEventManagerService.topBar.emit(true);
        this.subscription = this.store.select('course')
            .subscribe(
                data => {

                    if (data.selectedSection) {
                        this.scrollToPosition(data.selectedSection);

                    }
                });

        this.faqService.getFaqs().then(faqs => {

            this.faqs = faqs;

            for (const faq of faqs) {

                faq.body = this.domSanitizer.bypassSecurityTrustHtml(faq.body) as any;

                const index = this.faqsSorted.map(item => item.category.id).indexOf(faq.faq_category.id);

                if (index === -1) {

                    this.faqsSorted.push({category: faq.faq_category, faqs: [faq]});

                } else {

                    this.faqsSorted[index].faqs.push(faq);
                }

            }

            this.navigationService.setMenuData(this.faqsSorted);
            this.globalEventManagerService.showNavbar.emit(true);
            this.globalEventManagerService.menuType.emit('help');


        });


    }


}
