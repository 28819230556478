import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { TaskService } from '@app/services/data-services/task.service';
import { Task } from '@app/models/course/content/task/task';

@Component({
  selector: 'app-task-dialog-template',
  templateUrl: './task-dialog-template.component.html',
  styleUrls: ['./task-dialog-template.component.scss']
})
export class TaskDialogTemplateComponent implements OnInit {
  @Output() add_tasks: EventEmitter<any> = new EventEmitter<any>();
  rows: Task[] = [];
  temp: Task[] = [];
  selected: Task[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private taskService: TaskService) {
    this.selected = [];
    this.getTasks();
  }

  ngOnInit() {
  }

  getTasks() {
    this.taskService.getTasks()
      .then((tasks) => this.rows = this.temp = tasks);
  }

  cancel() {
    this.add_tasks.emit();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  addTasks() {
    this.add_tasks.emit(this.selected);
  }

  close() {
    this.cancel();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function(task) {
      if (task) {
        if (task.name) {
          return ((task.name.toLowerCase().indexOf(val) !== -1 || !val));
        } else if (task.task_category.name) {
          return ((task.task_category.name.toLowerCase().indexOf(val) !== -1 || !val));
        } else {
          return false;
        }
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

}
