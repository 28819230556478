<div #wrapper class="full-width">
  <!--<div style="padding:56.25% 0 0 0;">-->
  <div><!-- warning setting frameborder seems to lock ipad scroll sometimes -->
    <iframe id="scormFrame" *ngIf="!loading && url" #frame [src]="url"
            style="position:absolute;top:0;left:0;width:100%;height:100%;" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    <p *ngIf="!loading && !url">There was an error loading your file.</p>
    <!-- <iframe #frame src='../../../../../../../../assets/ApproachOverview/index.html'
            style="position:absolute;top:0;left:0;width:100%;height:100%;" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> -->
  </div>
</div>
