import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CourseCategory } from '@app/models/course/course-category';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TaskCategoryService } from '@app/services/data-services/task-category.service';

@Component({
  selector: 'app-add-task-category-dialog',
  templateUrl: './add-task-category-dialog.component.html',
  styleUrls: ['./add-task-category-dialog.component.scss']
})
export class AddTaskCategoryDialogComponent implements OnInit {
  selected = [];
  rows: CourseCategory[] = [];
  temp: CourseCategory[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;
  selectedCategory: any;

  constructor(public dialogRef: MatDialogRef<AddTaskCategoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private categoryService: TaskCategoryService) { }

  ngOnInit() {
    this.categoryService.getCategories()
      .then((categories) => this.rows = this.temp = categories);
  }

  close() {
    this.dialogRef.close();
  }

  onSelect(row) {
    this.selectedCategory = row.selected[0];
  }

  save() {
    if (this.selectedCategory) {
      this.dialogRef.close(this.selectedCategory);
      return;
    }
    this.close();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
