import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-edit-input2',
  templateUrl: './edit-input2.component.html',
  styleUrls: ['./edit-input2.component.scss']
})
export class EditInput2Component implements OnInit {
  @Input() flightOpsEventID: string;
  @Output() focusOut: EventEmitter<string> = new EventEmitter<string>();
  newFlightOpsEventID: string;
  editMode = false;
  constructor() { }

  ngOnInit() {
    this.newFlightOpsEventID = this.flightOpsEventID;
  }
  cancelEdit() {
    this.newFlightOpsEventID = this.flightOpsEventID;
    this.editMode = false;
  }
  saveEdit() {
    this.editMode = false;
    console.log(this.newFlightOpsEventID);
    if(this.newFlightOpsEventID == "") this.newFlightOpsEventID = null;
    this.focusOut.emit(this.newFlightOpsEventID);
  }
}
