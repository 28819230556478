import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortText'
})
export class ShortTextPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.slice(0, 50) + (value.length > 50 ? ' ...' : '');
  }

}
