<app-slideshow></app-slideshow>
<div class="main-wrapper maxwrapper">
  <!--<div class="main-wrapper contentarea maxwrapper">-->
  <!--<div class="couse-wrapper maxwrapper">-->
  <mat-grid-list [cols]="gridSize()" rowHeight="2:1" gutterSize="16px" id="my-learning">
    <mat-grid-tile [colspan]="1">
      <a [routerLink]="['/learning-catalog']">
        <mat-card>
          <mat-card-content>
            <!--<img src="assets/images/Learning Catalog Icon.png" alt="">-->
            <img class='my-learning-icons' src="assets/images/learningcatalog.png" alt="">
          </mat-card-content>
          <mat-card-footer>
            Learning Catalog
          </mat-card-footer>
        </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <a [routerLink]="['/my-courses']">
        <mat-card>
          <mat-card-content>
            <!--<img src="assets/images/My Courses Icon.png" alt="">-->
            <img class='my-learning-icons' src="assets/images/mycourses.png" alt="">
          </mat-card-content>
          <mat-card-footer>
            My Courses
          </mat-card-footer>
        </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <a [routerLink]="['/library']">
        <!--<a [routerLink]="['/instructors']">-->
        <mat-card>
          <mat-card-content>
            <!--<img src="assets/images/Content Library Icon.png" alt="">-->
            <img class='my-learning-icons' src="assets/images/contentlibrary.png" alt="">
          </mat-card-content>
          <mat-card-footer>
            Library
          </mat-card-footer>
        </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <a [routerLink]="['/achievements']">
        <mat-card>
          <mat-card-content>
            <!--<img src="assets/images/Achievements Icon.png" alt="">-->
            <img class='my-learning-icons' src="assets/images/achievements.png" alt="">
          </mat-card-content>
          <mat-card-footer>
            Achievements
          </mat-card-footer>
        </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" *appFeature="'training_centers'">
      <a [routerLink]="['/authorized-training-network']">
        <mat-card>
          <mat-card-content>
            <!--<img src="assets/images/Instructors Icon.png" alt="">-->
            <img class='my-learning-icons' src="assets/images/instructors.png" alt="">
          </mat-card-content>
          <mat-card-footer>
            Training Partners
          </mat-card-footer>
        </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1" *appLegacy="'pre_training_partners'">
      <a [routerLink]="['/instructors']">
        <mat-card>
          <mat-card-content>
            <!--<img src="assets/images/Instructors Icon.png" alt="">-->
            <img class='my-learning-icons' src="assets/images/instructors.png" alt="">
          </mat-card-content>
          <mat-card-footer>
            Instructors
          </mat-card-footer>
        </mat-card>
      </a>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="1">
      <a [routerLink]="['/help-center']">
        <mat-card>
          <mat-card-content>
            <!--<img src="assets/images/Help Icon.png" alt="">-->
            <img class='my-learning-icons' src="assets/images/help.png" alt="">
          </mat-card-content>
          <mat-card-footer>
            Help Center
          </mat-card-footer>
        </mat-card>
      </a>
    </mat-grid-tile>
  </mat-grid-list>

</div>
