<!--<mat-grid-list [cols]="2" gutterSize="25" [rowHeight]="'5:6'">-->
  <!--<mat-grid-tile *ngFor="let card of data">-->
    <!--<a (click)="openDialog(content)" href="javascript:void(0)">-->
    <mat-card class="flight-card" tabindex="0" [ngClass]="{ 'dialog-flight-card': isDialog }">
      <a (click)="openDialog()" href="javascript:void(0)">
      <mat-card-title [ngClass]="card?.completedSimHours > 0 ? 'sim' :card?.log_type === 'flight_assessment' ? 'flight-card-title' : 'gnd'">
        <div fxLayoutAlign="center" fxLayout='column'>
          <p class='flight-header-text'>{{ card?.created_at | date: 'dd' }}</p>
          <p class='flight-header-subtext'>{{ card?.created_at | date: 'MMM dd' }}</p>
        </div>
        <div fxFlex></div>
        <div fxLayoutAlign="center" fxLayout='column'>
          <p class='flight-header-text'>{{ card?.completedSimHours > 0 ? 'SIM' :card?.log_type === 'flight_assessment' ? 'FLT' : 'GND' }}</p>
          <p class='flight-header-subtext'>{{ card?.completedNightHours > 0 ? '1N' : '1D' }}</p>
        </div>

      </mat-card-title>
      <mat-card-content class='flight-card-content'>
        <span class="flight-time">
          <p class='time-font-weight'>{{ card?.hobbsIn }}</p>
          <hr>
          <p class='transfer-font-weight'>{{ card?.hobbsOut > card?.hobbsIn ? card?.hobbsOut - card?.hobbsIn: 0 }}hrs</p>
          <hr>
          <p class='time-font-weight'>{{ card?.hobbsOut }}</p>
        </span>
        <div class="flight-routes">
          <p class='flight-location'>{{ card?.fromAirport }}</p>
          <i class="material-icons airplane-icon">airplanemode_active</i>
          <p class='flight-location'>{{ card?.toAirport }}</p>
        </div>
        <div class="flight-routes-subtext">
          <p>{{ card?.routePath }}</p>
        </div>
        <div class="flight-routes-details">
          <p>{{ card?.aircraft_tail_id }}, {{ card?.content?.plane}}</p>
          <div fxFlex></div>
          <p>GND {{card?.completedGroundInstructionHours > 0 ? card?.completedGroundInstructionHours:0}}hrs</p>
        </div>
      </mat-card-content>
      <ng-content></ng-content>
      <mat-card-actions class='flight-card-footer dialog-footer' *ngIf="isDialog">
        <p class='details-link' (click)="closeDialog()">Close</p>
      </mat-card-actions>
      <mat-card-actions class='flight-card-footer' *ngIf="!isDialog">
        <p class='details-link'>Detail</p>
      </mat-card-actions>
      </a>
    </mat-card>
    <!--</a>-->
  <!--</mat-grid-tile>-->
<!--</mat-grid-list>-->



