<mat-dialog-content id="content-player" class="content-wrapper" [ngClass]="{'video-dialog-container ' : content?.content_type == 0 || content?.content_type == 1,
'test' : content?.content_type == 9 || content?.content_type == 10}">
    <div class="content-wrapper"
         fxLayout="column wrap">
        <div class="navtop" fxFlex="100">
            <button mat-icon-button mat-dialog-close (click)="close()">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
            <div class='content-title-dialog'
                 [ngClass]="{'flight-assess-header ' : content.content_type !== 0 || content.content_type !== 1}">{{content.title}}
            </div>
          <div>
            <mat-icon class="bookmark" (click)="bookmark(content)"
                      *ngIf="!isPreview && [9, 10].indexOf(content.content_type) === -1 && !isBookmarked(content)">bookmark_border
            </mat-icon>
            <mat-icon class="bookmark" (click)="bookmark(content)"
                      *ngIf="!isPreview && [9, 10].indexOf(content.content_type) === -1 && isBookmarked(content) ">bookmark
            </mat-icon>
          </div>
        </div>
        <div class="full-width" [ngClass]="{'content-player-wrapper-video': showContent && content.content_type == 0 || content.content_type == 1,
        'content-player-wrapper-tincan': showContent && content.content_type == 3,
        'content-player-wrapper-image': showContent && content.content_type == 11,
        'content-player-wrapper-document': showContent && content.content_type == 8,
        'content-player-wrapper-interactive': showContent && content.content_type == 7,
        'content-player-wrapper-quiz': showContent && content.content_type == 5,
        'content-player-wrapper-assessment': showContent && content.content_type == 9 || showContent && content.content_type == 10,
        'content-player-wrapper-scorm': showContent && content.content_type == 2}">
            <app-tin-can [content]="content"
                         *ngIf="showContent && content.content_type == 3"></app-tin-can>
            <app-image [content]="content" [isMobile]="isMobile" [isMobileLandscapeOrientation]="isMobileLandscapeOrientation" [isIpadLandscapeOrientation]="isIpadLandscapeOrientation"
                       *ngIf="showContent && content.content_type == 11"></app-image>
            <app-video [content]="content" [isMobile]="isMobile" [isMobileLandscapeOrientation]="isMobileLandscapeOrientation"
                       *ngIf="showContent && content.content_type == 0 || content.content_type == 1"></app-video>
            <app-document *ngIf="showContent && content.content_type == 8 && !comments" [content]="content"></app-document>

            <app-interactive [dialogRef]="dialogRef" [content]="content" *ngIf="(showContent && content.content_type == 7)"></app-interactive>
            <app-quiz [content]="content" *ngIf="showContent && content.content_type == 5"
                      (submit)="quizSubmitted($event)"></app-quiz>
            <app-flight-assessment [stage]="data.stage" [lesson]="data.lesson" [content]="content"
                                   *ngIf="showContent && content.content_type == 9
                                   || showContent && content.content_type == 10"></app-flight-assessment>
            <app-scorm [content]="content" [lesson]="data.lesson" *ngIf="showContent && content.content_type == 2" (create_scorm)="checkProgress(true)" (update_scorm)="checkProgress(false, $event)"></app-scorm>
            <app-comments
                    *ngIf="content.content_type != 10 && content?.comments != false"></app-comments>

            <a *ngIf="showContent && content?.content_type == 6" [href]="content?.content_file"
               class='workbook-content-mat-list' download>Click Here To Download</a>
        </div>
        <div class="navbottom" fxFlex="100" *ngIf="this.data.lesson">
            <button *ngIf="!isFirstContentForLesson()" mat-raised-button class='dialog-buttons'
                    (click)="previousContent()"><span
                    class='previous'></span></button>
            <div fxFlex=""></div>
            <button *ngIf="!isLastContentForLesson()" mat-raised-button class='dialog-buttons' tabindex="-1"
                    (click)="nextContent()"><span
                    class='next'></span></button>
            <button *ngIf="isLastContentForLesson()" mat-dialog-close mat-raised-button class='dialog-buttons' tabindex="-1"
                    (click)="close()"><span
                    class='close'></span></button>
        </div>
    </div>
</mat-dialog-content>
