import {Component, Input, OnChanges, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationService} from '../../../../services/navigation/navigation.service';
import {GlobalEventManagerService} from '../../../../services/global-event-manager/global-event-manager.service';
import {Stage} from '../../../../models/course/workbook/stage/stage';
import {Store} from '@ngrx/store';

import * as fromApp from '../../../../store/app.reducers';
import * as CourseActions from '../../../../ui/user/course/store/course.actions';
import { MatDrawer } from '@angular/material/sidenav';
import {ProgressCalculatorService} from '@app/services/helper/progress-calculator.service';
import {Subscription} from 'rxjs';


@Component({
    selector: 'app-user-sidenav',
    templateUrl: './user-sidenav.component.html',
    styleUrls: ['./user-sidenav.component.scss']
})
export class UserSidenavComponent implements OnInit, OnDestroy {

    hasIconTypeMenuItem;
    iconTypeMenuTitle: string;
    menuItems: Stage[];
    subscription = new Subscription();
    currentCourse: any;
    // active: false;

    @Input() hasNav: boolean;
    @Input() menuType: string;
    @Input() drawer: MatDrawer;

    stages: Stage[];

    constructor(private navService: NavigationService,
                private globalEventManager: GlobalEventManagerService,
                private progressCalculator: ProgressCalculatorService,
                private store: Store<fromApp.AppState>) {
    }

    lessonComplete(lesson) {

        return this.progressCalculator.checkIfLessonIsComplete(lesson);
    }

    ngOnInit() {

        this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

        this.subscription.add(this.globalEventManager.menuType.subscribe((type: any) => {
            this.navService.setMenu(type);
            this.menuItems = this.navService.menuData;
            // sort menu items
            this.menuItems.sort((a, b) => {
              if (this.menuType !== 'help') {
                return a && b ? a.order - b.order : -1;
              }
            });
            // console.log('MENU ITEMS', this.menuItems);
        }));

        this.subscription.add(this.globalEventManager.showNavbar.subscribe((mode: any) => {
              this.hasNav = mode;
        }));

        this.subscription.add(this.navService.sideNavItems$.subscribe((menuItem: any) => {
          this.menuItems = menuItem;
          this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.stage_type === 'icon').length;
        }));
    }

    ngOnDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }

    selectSection(selectedSection: any) {
      this.store.dispatch(new CourseActions.SelectSection(selectedSection));
    }
}

