export class Certificate {
    id?: number;
    name?: string;
    desc?: string;
    certificate_image?: any;
    isActive?: boolean;
    days_to_expire?: number;

    constructor() {
      this.isActive = false;
    }
}
