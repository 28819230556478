export class Payment {
  transaction_id?: number;
  order_id?: number;
  transaction_timestamp?: Date;
  transaction_type?: number;
  payment_type?: number;
  card_number?: number;
  card_exp_date?: string;
  card_cid?: number;
  auth_result?: string;
  auth_code?: string[];
}

export enum PaymentType {
  credit_card = 0
}
export enum TransactionType {
  authCapture = 0,
  authOnly = 1,
  priorAuthCapture = 2,
  captureOnly = 3,
  refund = 4,
  void = 5

}
export const TransactionTypeMap =  {
  0: 'Auth w/Auto Capture',
  1: 'authOnly',
  2: 'priorAuthCapture',
  3: 'captureOnly',
  4: 'Purchase Refunded',
  5: 'Purchase Voided'
};
export const PaymentTypeMap = {
  0: 'Credit Card'
};
