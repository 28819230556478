
<mat-toolbar class="instructor-menu" fxLayout="column wrap" fxLayoutAlign="center center">
  <div fxLayout="column wrap" fxLayoutAlign="center center">
    <h2 class='instructor-search-text'>Find Instructor. Start Flying.</h2>
    <mat-toolbar-row class='instructor-submenu-align'>
      <button mat-button [ngClass]="{'active-search-btn' : selectedFilter === 'Name'}" class='instructor-button' id='1' (click)="selectedFilter = 'Name'">Name</button>
      <button mat-button [ngClass]="{'active-search-btn' : selectedFilter === 'Email'}" class='instructor-button' id='2' (click)="selectedFilter = 'Email'">Email</button>
      <button mat-button [ngClass]="{'active-search-btn' : selectedFilter === 'Training Center'}" class='instructor-button' id='3' (click)="selectedFilter = 'Training Center'">Training Center</button>
      <button mat-button [ngClass]="{'active-search-btn' : selectedFilter === 'Airport'}" class='instructor-button' id='4' (click)="selectedFilter = 'Airport'">Airport</button>
    </mat-toolbar-row>
    <mat-toolbar-row class='instructor-submenu-align'>
      <mat-form-field class='instructor-search-bar' id="instructorSearch">
        <input class="pl-1 instructor-search" id='instructorFilter' (keydown)="searchInstructors($event)" type="text" [(ngModel)]="value" [placeholder]="'Enter Instructor\'s ' + selectedFilter" matInput>
      </mat-form-field>
      <button mat-button class='search-button' (click)="updateFilter()">Search</button>
    </mat-toolbar-row>
    <mat-toolbar-row class='instructor-submenu-align'>
      <a href="https://cirrusaircraft.com/find-us/" target="_blank" class='instructor-search-subtext'>Go to the Cirrus Locator</a>
      <div fxFlex></div>
      <p class='instructor-search-subtext pointer remove-bottom-margin' (click)="clear()">clear results</p>
    </mat-toolbar-row>
  </div>
</mat-toolbar>

<div class="course-wrapper maxwrapper">

  <!--Hidden Filter Chart Starts Here  -->
  <div class="collapsed detail" #detail>
    <div class="category-header"
         fxLayoutAlign="center">
      <h3 class="results-header">Search Results</h3>
      <div fxFlex></div>
      <!--<a class="category-header-button">View All ></a>-->
    </div>

    <ngx-datatable
      class="bg-white material"
      [rows]="filteredInstructors"
      [columnMode]="'force'"
      [headerHeight]="50"
      [footerHeight]="50"
      [limit]="50"
      [rowHeight]="'auto'">
      <ngx-datatable-column name="Instructor" prop="user.contact.lastname" [resizeable]="false">
        <ng-template let-row='row' ngx-datatable-cell-template>
          <p class="instructor-info" [ngClass]="{ 'current-instructor' : connectedInstructor(row) }">{{row?.user?.contact?.firstname}} {{row?.user?.contact?.lastname}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!isMobile" name="Qualifications" prop="user.contact.contact_user.qualifications__c" [resizeable]="false">
        <ng-template let-row='row' ngx-datatable-cell-template>
          <p class="instructor-info" [ngClass]="{ 'current-instructor' : connectedInstructor(row) }">{{row?.user?.contact?.contact_user?.qualifications__c?.split(';').join(", ")}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="Location" prop="user.contact.contact_user.mailingstate" [resizeable]="false">
        <ng-template let-row='row' ngx-datatable-cell-template>
          <p class="instructor-info" [ngClass]="{ 'current-instructor' : connectedInstructor(row) }">{{row?.user?.contact?.contact_user?.mailingcity}} {{row?.user?.contact?.contact_user?.mailingstate}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!isMobile" name="Training Center" prop="user.contact.contact_user.airport_name__c" [resizeable]="false">
        <ng-template let-row='row' ngx-datatable-cell-template>
          <p class="instructor-info" [ngClass]="{ 'current-instructor' : connectedInstructor(row) }">{{row?.user?.contact?.contact_user?.account?.name}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!isMobile" name="Airport" prop="user.contact.contact_user.home_airport.name" [resizeable]="false">
        <ng-template let-row='row' ngx-datatable-cell-template>
          <p class="instructor-info" [ngClass]="{ 'current-instructor' : connectedInstructor(row) }">{{row?.user?.contact?.contact_user?.home_airport?.name}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column *ngIf="!isMobile" name="Phone" prop="user.contact.contact_user.phone" [resizeable]="false">
        <ng-template let-row='row' ngx-datatable-cell-template>
          <p class="instructor-info" [ngClass]="{ 'current-instructor' : connectedInstructor(row) }">{{row?.user?.contact?.contact_user?.phone}}</p>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" [resizeable]="false">
          <ng-template let-row="row" ngx-datatable-cell-template>
            <button *ngIf="!isMobile" mat-icon-button [matMenuTriggerFor]="allInstructorsMenu">
              <mat-icon class="material-icons more pointer">more_vert</mat-icon>
            </button>
            <button *ngIf="isMobile" class="move-right" mat-icon-button [matMenuTriggerFor]="allInstructorsMenu">
              <mat-icon class="material-icons more pointer">more_vert</mat-icon>
            </button>
            <mat-menu #allInstructorsMenu="matMenu">
              <ng-template matMenuContent>
                <a href="mailto:{{row?.user?.contact?.contact_user?.email}}" target="_top">
                  <button mat-menu-item><mat-icon>email</mat-icon>Email</button>
                </a>
                <!--<button mat-menu-item *ngIf="isPendingInstructor(row)" (click)="openDialog(row, false, true)"><mat-icon>face</mat-icon>TEST</button>-->
                <button mat-menu-item *ngIf="!connectedInstructor(row) && isPendingInstructor(row)" (click)="openDialog(row, false, true)"><mat-icon>perm_identity</mat-icon>More Info</button>
                <button mat-menu-item *ngIf="!connectedInstructor(row) && !isPendingInstructor(row)" (click)="openDialog(row, false)"><mat-icon>perm_identity</mat-icon>More Info</button>
                <button mat-menu-item *ngIf="connectedInstructor(row)" (click)="openDialog(row, true)"><mat-icon>perm_identity</mat-icon>More Info</button>
                <button mat-menu-item *ngIf="!connectedInstructor(row)" (click)="connectToInstructor(row)"><mat-icon>link</mat-icon>Connect</button>
                <button mat-menu-item *ngIf="connectedInstructor(row)" (click)="removeInstructor(row)"><mat-icon>link_off</mat-icon>Disconnect</button>
              </ng-template>
            </mat-menu>
          </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <div class="category-header"
       fxLayoutAlign="center">
    <h3 class="my-instructor-header">My Instructors</h3>
    <div fxFlex></div>
  </div>

  <!--My Instructor table-->
  <ngx-datatable
    class="bg-white material"
    [rows]="myInstructors"
    [headerHeight]="50"
    [columnMode]="'force'"
    [rowHeight]="'auto'">
    <ngx-datatable-column name="Instructor" prop="user.contact.lastname" [resizeable]="false">
      <ng-template let-row='row' ngx-datatable-cell-template>
        <p class="instructor-info">{{row?.user?.contact?.firstname}} {{row?.user?.contact?.lastname}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!isMobile" name="Qualifications" prop="user.contact.contact_user.qualifications__c" [resizeable]="false">
      <ng-template let-row='row' ngx-datatable-cell-template>
        <p class="instructor-info">{{row?.user?.contact?.contact_user?.qualifications__c?.split(';').join(", ")}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Location" prop="user.contact.contact_user.mailingstate" [resizeable]="false">
      <ng-template let-row='row' ngx-datatable-cell-template>
        <p class="instructor-info">{{row?.user?.contact?.contact_user?.mailingcity}} {{row?.user?.contact?.contact_user?.mailingstate}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!isMobile" name="Training Center" prop="user.contact.contact_user.airport_name__c" [resizeable]="false">
      <ng-template let-row='row' ngx-datatable-cell-template>
        <p class="instructor-info">{{row?.user?.contact?.contact_user?.account?.name}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!isMobile" name="Airport" prop="user.contact.contact_user.home_airport.name" [resizeable]="false">
      <ng-template let-row='row' ngx-datatable-cell-template>
        <p class="instructor-info">{{row?.user?.contact?.contact_user?.home_airport?.name}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column *ngIf="!isMobile" name="Phone" prop="user.contact.contact_user.phone" [resizeable]="false">
      <ng-template let-row='row' ngx-datatable-cell-template>
        <p class="instructor-info">{{row?.user?.contact?.contact_user?.phone}}</p>
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="" [resizeable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <button *ngIf="!isMobile" mat-icon-button [matMenuTriggerFor]="myInstructorsMenu">
          <mat-icon class="material-icons more pointer">more_vert</mat-icon>
        </button>
        <button *ngIf="isMobile" mat-icon-button class="move-right" [matMenuTriggerFor]="myInstructorsMenu">
          <mat-icon class="material-icons more pointer">more_vert</mat-icon>
        </button>

        <mat-menu #myInstructorsMenu="matMenu">
          <ng-template matMenuContent>
            <a href="mailto:{{row?.user?.contact?.contact_user?.email}}" target="_top">
              <button mat-menu-item><mat-icon>email</mat-icon>Email</button>
            </a>
            <button mat-menu-item *ngIf="!connectedInstructor(row)" (click)="openDialog(row, false)"><mat-icon>perm_identity</mat-icon>More Info</button>
            <button mat-menu-item *ngIf="connectedInstructor(row)" (click)="openDialog(row, true)"><mat-icon>perm_identity</mat-icon>More Info</button>
            <button mat-menu-item *ngIf="!connectedInstructor(row)" (click)="connectToInstructor(row)"><mat-icon>link</mat-icon>Connect</button>
            <button mat-menu-item *ngIf="connectedInstructor(row)" (click)="removeInstructor(row)"><mat-icon>link_off</mat-icon>Disconnect</button>
          </ng-template>
        </mat-menu>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
