import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

type SearchTerms = Record<string, string> & {
  search: string | null;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public searchValue: string;
  @Input() public dataSource: MatTableDataSource<unknown>;
  @Input() additionalSearchTerms: Record<string, string>[] = [];
  @Input() searchToken: BehaviorSubject<unknown>;

  constructor() {}

  ngOnInit(): void {
    if (this.searchValue) {
      this.applySearch();
    }
  }

  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  }

  applySearch() {
    const searchTerms: SearchTerms = Object.assign({ search: this.searchValue }, ...this.additionalSearchTerms);
    this.dataSource.filter = JSON.stringify(searchTerms);
    if (this.searchToken) {
      this.searchToken.next(true);
    }
  }

  clearSearch() {
    this.searchValue = null;
    this.applySearch();
  }
}
