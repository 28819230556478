<mat-dialog-content class="popout-wrapper">
  <!--Top Area-->
  <div class="main-content">
    <div class="full-width dialog-header"><button mat-icon-button mat-dialog-close><mat-icon class="close-icon">close</mat-icon></button></div>
    <div class="course-info-wrapper">
      <div class="course-image-wrapper">
        <div>
          <!--<app-progress-spinner class='dialog-spinner' [badge]="'../../../../../../assets/images/badge1.png'" [progress]="50"></app-progress-spinner>-->
          <img class="badge-image-dialog earnedbadges" src="{{content?.badge?.badge_image}}" />
        </div>
      </div>
    </div>
  </div>

  <!--Bottom Area-->
  <div class="course-content">
    <div class="category-header">
      <h3 class="certs-body-title">{{content?.course?.title}}</h3>
    </div>
    <div class='cert-text-body'>
      <!--<p>By: Cirrus Approach</p>-->
      <div>
        <p></p>
        <div>
          <p class="avionics">{{content?.aircraft}} <br>
            {{content?.avionic}}
          </p>
        </div>
        <div class="max-width">
          <p class="font-weight" *ngIf="isUserBadge">Received: {{content?.received | date: 'MM/dd/yyyy'}} </p>
          <p class='cert-description'>{{content?.badge?.desc}}</p>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
