import {Instructor} from '@app/models/instructor';
import {Stage} from '@app/models/course/workbook/stage/stage';
import {Course} from '@app/models/course/course';
import {Lesson} from '@app/models/course/workbook/lesson/lesson';
import {Status} from '@app/models/shared/status';
import {Assignment} from '@app/models/course/assignment';

export class StudentAssignment {
  id?: number;
  due_date?: Date;
  status?: Status;
  stage_id?: number;
  lesson_id?: number;
  course_id?: number;
  user_id?: number;
  instructor_id?: number;
  stage?: Stage;
  lesson?: Lesson;
  course?: Course;
  instructor?: Instructor;
  assignment?: Assignment;
  assignment_id?: number;
}
