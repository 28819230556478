import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { UserProgress } from '@app/models/user/user-progress';
import { UserCourse } from '@app/models/course/user-course';

@Injectable()
export class ProgressService {
  progressUrl = 'progresses';

  constructor(private http: HttpService) {}

  saveProgress(progress: UserProgress): Promise<UserProgress> {
    return this.http.post<UserProgress>(`${this.progressUrl}`, JSON.stringify(progress))
      .toPromise()
      .catch(ProgressService.handleError);
  }

  saveProgressForSpecificUser(progress: UserProgress, user_id: number, level?: number): Promise<UserProgress> {
    return this.http.post<UserProgress>(`${this.progressUrl}/user/${user_id}`, JSON.stringify(progress))
      .toPromise()
      .catch(ProgressService.handleError);
  }

  updateProgress(progress: UserProgress): Promise<UserProgress> {
    return this.http.put<UserProgress>(`${this.progressUrl}/${progress.id}`, JSON.stringify(progress))
      .toPromise()
      .catch(ProgressService.handleError);
  }

  updateProgressForSpecificUser(progress: UserProgress, user_id: number): Promise<UserProgress> {
    return this.http.put<UserProgress>(`${this.progressUrl}/${progress.id}/${user_id}`, JSON.stringify({
      status: progress.status,
      scorm_progress: JSON.stringify(progress.scorm_progress)
    }))
      .toPromise()
      .catch(ProgressService.handleError);
  }

  updateProgressesComplete(progresses: UserProgress[], course_attempt_id: number): Promise<void> {
    const url = `${this.progressUrl}/force-complete/${course_attempt_id}`;
    const payload = JSON.stringify({progresses});

    return this.http
      .put(url, payload)
      .toPromise()
      .then(() => null)
      .catch(ProgressService.handleError);
  }

  syncProgress(progress: UserProgress): Promise<UserProgress[]> {
    const progressArray = [];
    progressArray.push(progress);
    const progress_entries = {
      progress_entries: progressArray
    };
    return this.http.post<UserProgress[]>(`${this.progressUrl}/sync/add`, JSON.stringify(progress_entries))
      .toPromise()
      .catch(ProgressService.handleError);
  }


  getProgress(user_id: number): Promise<UserProgress[]> {
    return this.http.get<UserProgress[]>(`${this.progressUrl}/user/${user_id}`)
      .toPromise()
      .catch(ProgressService.handleError);
  }

  getCourseProgress(id): Promise<UserProgress[]> {
    return this.http.get<UserProgress[]>(`${this.progressUrl}/${id}`)
      .toPromise()
      .catch(ProgressService.handleError);
  }

  getUserProgress(user_id: number): Promise<UserProgress[]> {
    return this.http.get<UserProgress[]>(`${this.progressUrl}/student/${user_id}`)
      .toPromise()
      .catch(ProgressService.handleError);
  }

  getUserProgressCourseDetail(course_id: number, user_id: number): Promise<UserProgress[]> {
    return this.http.get<UserProgress[]>(`${this.progressUrl}/course/${course_id}/user/${user_id}`)
      .toPromise()
      .catch(ProgressService.handleError);
  }

  getProgressForStudentAssessment(user_id, course_id, course_attempt_id) {
    return this.getProgressForCourseAttempt({ user_id, course_attempt_id, course: { id: course_id } });
  }

  getProgressForCourseAttempt(userCourse: UserCourse): Promise<UserProgress[]> {
    return this.http.get<UserProgress[]>(`${this.progressUrl}/user/${userCourse.user_id}/course/${userCourse.course.id}/attempt/${userCourse.course_attempt_id}`)
      .toPromise()
      .catch(ProgressService.handleError);
  }

  getAssignmentProgress(user_id: number): Promise<UserProgress[]> {
    return this.http.get<UserProgress[]>(`${this.progressUrl}/student/assignments/${user_id}`)
      .toPromise()
      .catch(ProgressService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
