import { Component, OnInit } from '@angular/core';
import { CertificatesService } from '@app/services/data-services/certificates.service';
import { MatDialog } from '@angular/material/dialog';
import { CertsDialogComponent } from './certs-dialog/certs-dialog.component';
import { BadgesCertsComponent } from "../badges-certs.component";

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
  rows: any[] = [];
  fltRows: any[] = [];
  fltSub;
  is_loading = false;
  constructor(private certService: CertificatesService,
              private par: BadgesCertsComponent,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.is_loading = true;
    Promise.all([
      this.par.wait,
      this.certService.getMyCertificates()
        .then(certificates => this.rows = certificates)
    ]).then(x => {

        this.fltSub = this.par.onFilterChanged.subscribe(([ucs, arch, air, av, trn]) => {
          this.fltRows = this.rows;
          const flt = (v, fn) => {
            if (v != 'all') {
              this.fltRows = this.fltRows.filter(fn);
            }
          }
          flt(arch, cert => ucs.filter(uc => uc.course_id == cert.course_id && uc.archived).length > 0);
          flt(air, cert => cert.aircraft_name == air);
          flt(av, cert => cert.avionics_name == av);
          flt(trn, cert => ucs.filter(uc => uc.course_id == cert.course_id && uc.course.course_category_id == trn).length > 0);
          this.is_loading = false;
        });
      this.par.filterChanged()
      }
    )

  }

  ngOnDestroy() {
    if (this.fltSub) {
      this.fltSub.unsubscribe()
    }
  }

  openDialog(content) {
    const dialogRef = this.dialog.open(CertsDialogComponent, {
      maxWidth: '90%',
      data: content
    });
  }


  gridSize() {
    return window.matchMedia(`(max-width: 960px)`).matches ? window.matchMedia(`(max-width: 768px)`).matches ? window.matchMedia(`(max-width: 500px)`).matches ? 1 : 2 : 3 : 4;
  }


}
