import { Component, OnInit } from '@angular/core';
import { TaskService } from '@app/services/data-services/task.service';
import { Task } from '@app/models/course/content/task/task';
import { slideInRight } from '@app/animations/animations';
import { TaskCategoryService } from '@app/services/data-services/task-category.service';
import { TaskCategory } from '@app/models/course/content/task/task-category';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {AddAvionicsDialogComponent} from '../../aircraft-manager/add-aircraft/add-avionics-dialog/add-avionics-dialog.component';
import {AddTaskCategoryDialogComponent} from './add-task-category-dialog/add-task-category-dialog.component';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddTaskComponent implements OnInit {
  task_model: Task;
  task_categories: TaskCategory[];
  dialogRef: any;

  constructor(private taskService: TaskService,
              private taskCategoryService: TaskCategoryService,
              private router: Router,
              public dialog: MatDialog) {
    this.initTask();
  }

  ngOnInit() {
    this.getTaskCategories();
  }

  initTask() {
    this.task_model = new Task();
    this.task_model.standards = [];
  }

  getTaskCategories() {
    this.taskCategoryService.getCategories()
      .then((categories) => this.task_categories = categories);
  }

  addTask() {
    this.taskService.create(this.task_model)
      .then(() => this.initTask());
  }

  addStandard() {
    this.task_model.standards.push('');
  }

  cancel() {
    this.router.navigate(['admin/courses/task']);
  }

  trackFunction(index, item) {
    return index;
  }

  removeStandard(index: number) {
    if (this.task_model.standards && this.task_model.standards.length > 0) {
      this.task_model.standards.splice(index, 1);
    }
  }

  openDialog(content) {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(AddTaskCategoryDialogComponent, {
      width,
      height,
      data: content
    });
  }

  addCategory() {
    this.openDialog(this.task_model);

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.task_model.task_category = data;
      }
    });
  }

}
