import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ContentPlayerComponent} from '../../../course/workbook/content-player/content-player.component';

@Component({
  selector: 'app-badges-dialog',
  templateUrl: './badges-dialog.component.html',
  styleUrls: ['./badges-dialog.component.scss']
})
export class BadgesDialogComponent implements OnInit {
  content = this.data.content;
  isUserBadge = this.data.isUserBadge;
  constructor(public dialogRef: MatDialogRef<ContentPlayerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}

