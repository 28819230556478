import { Injectable } from '@angular/core';
import { CourseCategory } from '@app/models/course/course-category';
import { HttpService } from '@app/services/http-service.service';

@Injectable()
export class ConfigurationService {

  configurationUrl = 'configurations';

  constructor(private http: HttpService) { }
  find(slug): Promise<any> {
    const url = `${this.configurationUrl}/${slug}`;
    return this.http
      .get(url)
      .toPromise()
      .then(configuration => configuration)
      .catch(this.handleError);
  }
  update(configuration): Promise<any> {
    const url = `${this.configurationUrl}/${configuration.slug}/update`;
    return this.http
      .put(url, JSON.stringify(configuration))
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
