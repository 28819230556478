<div class="full-width">

  <div class="courseheader">
    <div id="course-wrapper-id" class="course-wrapper-alt">
      <div class='header-text-area'>
        <mat-icon class="breadcrumb-icon">keyboard_arrow_left</mat-icon>
        <a class="breadcrumb" [routerLink]="'/learning-catalog'">Course Catalog</a>
        <h2 class="course-title-header">Purchase History</h2>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="course-wrapper-alt course-padding-alt top-padding">
    <div class="max-width-course table-container"
         fxLayout='row wrap'
         fxLayout.sm="row"
         fxLayout.xs="column"
         fxLayoutAlign.sm="start"
         fxLayoutAlign.xs="start"
         fxFlex.xs="100">
      <div fxFlex="100"
           fxFlex.sm="100"
           fxFlex.xs="100"
           fxLayout="column"
           fxLayoutAlign="start start" class="full-width">
        <div class="full-width move-left" fxFlex="100">
          <table class="purchase-history-table">
            <tr>
              <th class="col-header">Order #</th>
              <th class="col-header">Item</th>
              <th class="col-header">Date</th>
              <th class="col-header">Total Price</th>
              <th class="col-header" *ngIf="!isMobile">Payment Type</th>
              <th></th>
            </tr>
            <tr *ngFor="let order of orders">
              <td>{{order.id}}</td>
              <td *ngIf="order?.order_line_items?.length == 1">
                {{order?.order_line_items[0]?.product?.title}}
              </td>
              <td *ngIf="order?.order_line_items?.length > 1">
                {{order?.order_line_items?.length}} Courses Purchased
              </td>
              <td>{{order.completed_date | date}}</td>
              <td class="bold-txt">{{order.order_total | currency:'USD'}}</td>
              <td *ngIf="!isMobile">{{paymentTypeMap[paymentType[order.payments[order.payments.length - 1].payment_type]]}}</td>
              <td><button mat-stroked-button class="receipt-btn" (click)="viewReceipt(order.id)">Receipt</button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
