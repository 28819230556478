import * as CheckoutActions from './checkout.actions';

import {Order} from '@app/models/order/order';
import {OrderLineItem} from '@app/models/order/order-line-item';

export interface State {
  order: Order;
}

const initialState: State = {
  order: new Order()
};

export function checkoutReducer(state = initialState, action: CheckoutActions.CheckoutActions) {
  let newState = null;
  let order = state.order;
  switch (action.type) {
    case CheckoutActions.ADD_TO_CART:
      if (!order) {
        order = new Order();
      }

      if (order.order_line_items && order.order_line_items.length) {
        const i = order.order_line_items.map((value) => value.product ? value.product_id : -1).indexOf(action.payload.id);
        if (i === -1) {
          const newLineItem = new OrderLineItem();
          newLineItem.product = action.payload;
          newLineItem.product_id = action.payload.id;
          newLineItem.line_item_unit_price = action.payload.list_price;
          order.order_line_items.push(newLineItem);
        }
      } else {
        const newLineItem = new OrderLineItem();
        newLineItem.product = action.payload;
        newLineItem.product_id = action.payload.id;
        newLineItem.line_item_unit_price = action.payload.list_price;
        order.order_line_items = [];
        order.order_line_items.push(newLineItem);
      }

      newState = {
        ...state,
        order
      };

      if (!localStorage.getItem('cirrus-token')) {
        storeCart(newState);
      }

      return newState;
    case CheckoutActions.REMOVE_FROM_CART:
      order = state.order;
      if (order && order.order_line_items && order.order_line_items.length) {
        const i = order.order_line_items.indexOf(action.payload);
        if (i > -1) {
          order.order_line_items.splice(i, 1);
          if (order.order_line_items.length === 0) {
            order.order_line_items = [];
          }
        }
      }

      newState = {
        ...state,
        order
      };

      if (!localStorage.getItem('cirrus-token')) {
        storeCart(newState);
      }

      return newState;
    case CheckoutActions.SET_ORDER:
      newState = {
        ...state,
        order: action.payload
      };

      if (!localStorage.getItem('cirrus-token')) {
        storeCart(newState);
      }

      return newState;
    case CheckoutActions.CLEAR_CART:
      order = state.order;

      if (order.order_line_items.length) {
        order.order_line_items = [];
      }

      newState = {
        ...state,
        order
      };

      if (!localStorage.getItem('cirrus-token')) {
        storeCart(newState);
      }

      return newState;
    case CheckoutActions.COMPLETE_PURCHASE:
      order = new Order();

      newState = {
        ...state,
        order
      };

      if (!localStorage.getItem('cirrus-token')) {
        storeCart(newState);
      }

      return newState;
    case CheckoutActions.UPDATE_CART:
      newState = {
        ...state,
        order: action.payload
      };

      return newState;
    case CheckoutActions.STORE_CART:
      storeCart(state);
      return state;
    default:
      if (!localStorage.getItem('cirrus-token') && localStorage.getItem('is-checkout')) {
        return JSON.parse(localStorage.getItem('checkout-state'));
      }
      return state;
  }
}

export function storeCart(newState) {
  localStorage.setItem('checkout-state', JSON.stringify(newState));
  localStorage.setItem('is-checkout', 'true');
}
