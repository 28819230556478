<div
  #course_detail
  class="course-wrapper course-detail-wrapper"
  id="export-wrapper"
  *ngIf="!loading"
  [ngStyle]="exporting && { height: '100%' }"
>
  <div class="continue-course-wrapper export-container">
    <div
      class="continue-course"
      fxLayout="wrap"
      fxLayout.xs="column"
      fxLayoutAlign="start center"
      [ngStyle]="exporting && { height: '100%' }"
    >
      <div style="height: 100px; width: 60px">
        <img
          class="badge_image"
          *ngIf="courseAttempt.course.badge"
          #badge_image
          crossorigin="anonymous"
          [src]="courseAttempt.course.badge.badge_image"
          [alt]="courseAttempt.course.badge.name"
        />
        <img
          class="badge_image"
          *ngIf="!courseAttempt.course.badge"
          #default_badge
          [src]="'assets/images/default_badge.png'"
          alt=""
        />
      </div>
      <mat-list style="width: 85%">
        <mat-list-item class="split-row">
          <h2 class="course-title">{{ courseAttempt.course.title }}</h2>
        </mat-list-item>
        <mat-list-item>
          <p class="up-next-course" *ngIf="courseAttempt.course.created_by">
            By {{ courseAttempt.course.created_by }}
          </p>
        </mat-list-item>
      </mat-list>
      <button
        mat-icon-button
        [matMenuTriggerFor]="msgMenu"
        class="hidden-on-open"
      >
        <mat-icon class="text-muted" data-html2canvas-ignore="true"
          >more_vert</mat-icon
        >
      </button>
      <mat-menu #msgMenu="matMenu">
        <button mat-menu-item>Enrollment History</button>
        <button mat-menu-item (click)="downloadTranscript()">
          Download Transcript
        </button>
        <button
          mat-menu-item
          (click)="downloadCertificate(courseAttempt.course.id)"
          *ngIf="courseAttempt.user_course.certificate_awarded"
        >
          Download Certificate
        </button>
        <button *ngIf="showEmbarkID()" mat-menu-item (click)="setEmbarkID()">
          Add Embark Job ID
        </button>
        <button
          *ngIf="showFlightOpsEventId()"
          mat-menu-item
          (click)="setFlightOpsEventID()"
        >
          Add Flight Ops Event ID
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="export-container">
    <h3
      style="
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <span>{{ courseAttempt.user_course.user.display_name }}</span>
      <div
        style="font-size: 0.75rem; display: flex; align-items: baseline"
        *ngIf="courseAttempt.user_course.embark_job_id != null"
        id="embark_job_id"
      >
        <span>Embark Job ID:&nbsp;</span>
        <app-edit-input
          [embarkJobID]="courseAttempt.user_course.embark_job_id"
          (focusOut)="handleEmbarkEdit($event)"
        ></app-edit-input>
      </div>
      <div
        style="font-size: 0.75rem; display: flex; align-items: baseline"
        *ngIf="courseAttempt.user_course?.flight_ops_event_id != null"
        id="flight_ops_event_id"
      >
        <span>Flight Ops Event ID:&nbsp;</span>
        <app-edit-input2
          [flightOpsEventID]="courseAttempt.user_course.flight_ops_event_id"
          (focusOut)="handleFlightOpsEventEdit($event)"
        ></app-edit-input2>
      </div>
    </h3>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="progress-bar-align export-container"
  >
    <h3 *ngIf="courseAttempt.overall_progress >= 100" class="progress-label">
      Completed
    </h3>
    <h3
      *ngIf="
        courseAttempt.overall_progress > 0 &&
        courseAttempt.overall_progress < 100
      "
      class="progress-label"
    >
      In Progress
    </h3>
    <h3 *ngIf="courseAttempt.overall_progress <= 0" class="progress-label">
      Not Started
    </h3>
  </div>
  <div
    fxLayout="row wrap"
    fxFlex="100"
    fxLayoutAlign="space-between start"
    class="mr-20 export-container"
    [ngStyle]="exporting && { height: '100%' }"
  >
    <div
      fxFlex="16"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      class="progress-stage"
    >
      <p class="bold-txt">Enrollment Status</p>
      <p></p>
      <p>Start:</p>
      <p *ngIf="isStarted()">
        {{ courseAttempt.course.progress?.created_at | date }}
      </p>
      <p *ngIf="!isStarted()">Not Started</p>
      <p>Completion:</p>
      <p *ngIf="!isCompleted()">Not Completed</p>
      <p *ngIf="isCompleted()">
        {{ courseAttempt.course.progress?.updated_at | date }}
      </p>
    </div>
    <div
      fxFlex="16"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      class="progress-stage"
    >
      <p class="bold-txt">Lesson Types</p>
      <p></p>
      <p class="max-120">
        <span>Self-Study</span>
        <span class="float-right"
          >{{ courseAttempt.lesson_types.self_study.completed }}/{{
            courseAttempt.lesson_types.self_study.total
          }}</span
        >
      </p>
      <p class="max-120">
        <span>Ground</span>
        <span class="float-right"
          >{{ courseAttempt.lesson_types.ground.completed }}/{{
            courseAttempt.lesson_types.ground.total
          }}</span
        >
      </p>
      <p class="max-120">
        <span>Flight</span>
        <span class="float-right"
          >{{ courseAttempt.lesson_types.flight.completed }}/{{
            courseAttempt.lesson_types.flight.total
          }}</span
        >
      </p>
      <p class="max-120">
        <span>Simulator</span>
        <span class="float-right"
          >{{ courseAttempt.lesson_types.simulator.completed }}/{{
            courseAttempt.lesson_types.simulator.total
          }}</span
        >
      </p>
      <p class="max-120">
        <span class="bold-txt">Total</span>
        <span class="float-right bold-txt"
          >{{ lessonTypeTotals.completed() }}/{{
            lessonTypeTotals.total()
          }}</span
        >
      </p>
    </div>
    <div
      fxFlex="16"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      class="progress-stage"
    >
      <p class="bold-txt">Assessments</p>
      <p></p>
      <p class="max-120">
        <span>Flight</span>
        <span class="float-right"
          >{{ courseAttempt.assessments.flight.completed }}/{{
            courseAttempt.assessments.flight.total
          }}</span
        >
      </p>
      <p class="max-120">
        <span>Ground</span>
        <span class="float-right"
          >{{ courseAttempt.assessments.ground.completed }}/{{
            courseAttempt.assessments.ground.total
          }}</span
        >
      </p>
      <p></p>
      <p></p>
      <p class="max-120 bold-txt" style="padding-top: 5px !important">
        <span>Total</span>
        <span class="float-right bold-txt"
          >{{ assessmentTotals.completed() }}/{{
            assessmentTotals.total()
          }}</span
        >
      </p>
    </div>
    <div
      fxFlex="16"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      class="progress-stage"
    >
      <p class="max-120">
        <span class="bold-txt">Logbook</span>
        <span class="float-right bold-txt">Hrs</span>
      </p>
      <p></p>
      <p class="max-120">
        <span>Flight</span>
        <span class="float-right">{{
          courseAttempt.completed_total_hours | number
        }}</span>
      </p>
      <p class="max-120">
        <span>Ground</span>
        <span class="float-right">{{
          courseAttempt.completed_ground_instruction_hours | number
        }}</span>
      </p>
      <p class="max-120">
        <span>SIM</span>
        <span class="float-right">{{
          courseAttempt.completed_sim_hours | number
        }}</span>
      </p>
      <p class="max-120">
        <span class="bold-txt">Total</span>
        <span class="float-right bold-txt">{{
          courseAttempt.completed_hours | number
        }}</span>
      </p>
    </div>
  </div>
  <div
    fxLayout="column"
    *ngFor="let stage of courseAttempt.course.stages | orderBy : 'order'"
    class="width-controller export-container"
    [ngStyle]="exporting && { height: '100%' }"
  >
    <span
      style="
        margin-left: 15px;
        color: #0088ce;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 10px;
      "
      >{{ stage?.title }}</span
    >
    <div
      [id]="'stage' + stage?.id + 'lesson' + lesson?.id"
      fxLayout="column"
      fxFlex="100"
      *ngFor="
        let lesson of stage?.lessons | orderBy : 'lesson.order';
        let j = index
      "
    >
      <div
        class="stage-wrapper"
        style="
          display: flex;
          background-color: #00273c;
          padding: 15px 5px;
          color: white;
          min-height: 50px;
        "
      >
        <span [id]="lesson.name" style="width: 60%">{{ lesson?.title }}</span>
        <span style="width: 30%">{{
          lesson?.lesson_type_text | spaces | titlecase
        }}</span>
        <span class="complete-items" style="width: 10%">{{
          lesson.progress?.is_force_completed
            ? 'Force Completed'
            : (lesson.progress?.status | spaces | titlecase)
        }}</span>
      </div>
      <div fxFlex="100" class="lesson-spacing">
        <mat-list fxFlex="100">
          <div *ngFor="let content of lesson?.contents; let z = index">
            <mat-list-item class="workbook-content-padding">
              <span style="width: 60%">
                {{ content?.title }}
              </span>
              <span style="width: 30%">{{
                content.summary_content_type | spaces | titlecase
              }}</span>
              <span class="complete-items" style="width: 10%">{{
                content.progress?.is_force_completed
                  ? 'Force Completed'
                  : (content.progress?.status | spaces | titlecase)
              }}</span>
            </mat-list-item>
            <ng-container *ngIf="[9, 10].includes(content.content_type)">
              <div class="full-width">
                <div fxLayout="wrap">
                  <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                    <table class="">
                      <thead>
                        <tr>
                          <th class="thead-left">Tasks</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th *ngIf="content.jet_scoring"></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Task</td>
                          <td>Type</td>
                          <td>Required</td>
                          <td>Passed</td>
                          <td>Missed</td>
                          <td *ngIf="content.jet_scoring">Perfect</td>
                          <td>Status</td>
                        </tr>
                        <tr *ngFor="let content_task of content.tasks">
                          <td class="row-stage-name">
                            {{ content_task?.name }}
                          </td>
                          <td>{{ content_task.task_type | titlecase }}</td>
                          <td class="center-data">
                            {{ content_task?.required_successful_attempts }}
                          </td>
                          <td class="center-data">
                            {{ content_task.success }}
                          </td>
                          <td class="center-data">{{ content_task.failed }}</td>
                          <td class="center-data" *ngIf="content.jet_scoring">
                            {{ content_task.perfect }}
                          </td>
                          <td>
                            {{ content_task.status | spaces | titlecase }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <ng-container
                *ngFor="let lessonFlightLog of courseAttempt.lesson_flight_logs"
              >
                <ng-container *ngIf="lesson.id === lessonFlightLog.lesson_id">
                  <div fxLayout="wrap" class="pt-1 pb-1">
                    <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                      <table class="">
                        <thead>
                          <tr>
                            <th class="thead-left">Logbook</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>LOG ID</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-thead-right">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.id }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>DATE</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.lesson_flight_log_date | date
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>MAKE</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.aircraft_make }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>MODEL</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.aircraft_model }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>ID</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.aircraft_tail_id }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>TO</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.to_airport }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>FROM</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.from_airport }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>TOTAL FLT</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.total_flight_time
                                    | number : '0.1'
                                }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>TOTAL SIM</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.completed_sim_hours | number
                                }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>TOTAL GND</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.completed_ground_instruction_hours
                                    | number
                                }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>INSTRUCTOR</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-instructor-name logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.instructor?.first_name }}
                                {{ lessonFlightLog?.instructor?.last_name }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                        </tbody>
                      </table>
                      <div
                        class="full-width"
                        *ngIf="lessonFlightLog?.comment_body"
                      >
                        <p class="section-label remarks-title">REMARKS</p>
                      </div>
                      <p
                        class="remarks-body"
                        *ngIf="lessonFlightLog?.comment_body"
                      >
                        {{ lessonFlightLog?.comment_body }}
                      </p>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </mat-list>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="loading && !exportTranscript">
  <app-loading-spinner></app-loading-spinner>
</ng-container>
<div #image_container>
  <img
    #image
    style="visibility: hidden"
    [src]="url"
    alt=""
    crossOrigin="Anonymous"
  />
</div>

<ng-container *ngIf="exportTranscript">
  <app-transcript-course-detail
    [course_attempt_id]="course_attempt_id"
    (done)="export()"
  ></app-transcript-course-detail>
</ng-container>
