import { Injectable } from '@angular/core';
import { HttpService } from '../http-service.service';

@Injectable()
export class UserCertificateService {
  userCertificateUrl = 'user_certificates';

  constructor(private http: HttpService) {}

  updateCompletionDate(user_certificate_id: number, date: Date): Promise<void> {
    const url = `${this.userCertificateUrl}/update_completion_date/${user_certificate_id}`;
    return this.http
      .put<void>(url, JSON.stringify({newDate: date}))
      .toPromise()
      .catch(UserCertificateService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
