<h3 class="text-body-header">Completed</h3>
<div class="loading-spinner" *ngIf="is_loading" style="display: flex; justify-content: center">
  <mat-spinner id="loading-spinner-user-badges" style="align-self: center;"></mat-spinner>
</div>
<mat-grid-list [cols]="gridSize()" gutterSize="5" [rowHeight]="'1:1'" *ngIf="!is_loading && fltUsrBadges.length > 0">
  <mat-grid-tile *ngFor="let row of fltUsrBadges">
    <a (click)="openDialog(row, true)" class='text-center clickable-link'>
      <img class="badge-image earnedbadges" mat-card-image src="{{row?.badge?.badge_image}}" />
      <p class='badge-text'>{{row?.course?.title}}</p>
    </a>
  </mat-grid-tile>
</mat-grid-list>
<div class="no-badges" *ngIf="!is_loading && fltUsrBadges.length == 0">
  <p style="text-align: center;">You have not completed any courses or earned any badges yet</p>
</div>
<h3 class="text-body-header">All Badges</h3>
<div class="loading-spinner" *ngIf="is_loading" style="display: flex; justify-content: center">
  <mat-spinner id="loading-spinner-all-badges" *ngIf="is_loading"></mat-spinner>
</div>
<mat-grid-list [cols]="gridSize()" gutterSize="5" [rowHeight]="'1:1'" *ngIf="!is_loading && fltBadges.length > 0">
  <mat-grid-tile *ngFor="let row of fltBadges">
    <a (click)="openDialog(row, false)" class='text-center clickable-link'>
      <img class="badge-image allbadges" mat-card-image src="{{row?.badge?.badge_image}}" />
      <p class='badge-text'>{{row?.course?.title}}</p>
    </a>
  </mat-grid-tile>
</mat-grid-list>
<div class="no-badges" *ngIf="!is_loading && fltBadges.length == 0">
  <p style="text-align: center;">There are no badges for the course(s) you're enrolled in</p>
</div>
