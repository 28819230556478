import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Certificate } from '@app/models/course/certificate';
import { slideInRight } from '@app/animations/animations';
import { CertificatesService } from '@app/services/data-services/certificates.service';
import { FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { HttpService } from '@app/services/http-service.service';
import { jsPDF } from 'jspdf';
import {SnackbarComponent} from '@app/components/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddCertificateComponent implements OnInit {
  certificate: Certificate = new Certificate();

  public uploader: FileUploader = new FileUploader({ });
  @ViewChild('imageUploader', { static: true }) imageUploader: any;
  subscription: any;

  constructor(private router: Router,
              public snackbar: MatSnackBar,
              private certificateService: CertificatesService,
              private http: HttpService) { }

  ngOnInit() {
    this.initUploader();
  }

  upload() {
    if (this.uploader.queue.length) {
      this.uploader.uploadAll();
    }
  }

  addCertificate() {
    this.certificateService.create(this.certificate)
      .then(() => {
        this.confirmMessage();
      });
  }
  confirmMessage() {
    const snackBar = this.snackbar.openFromComponent(SnackbarComponent, {
      data: 'Created Certificate'
    });

    this.subscription = snackBar.afterOpened().subscribe(() => {
      this.snackbar.open('Certificate Created')
      this.router.navigate(['/admin/certificates']);
    });
  }
  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = 1650;
    canvas.height = 1275;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  download() {
    if (this.uploader.queue.length) {
      const doc = new jsPDF('l', 'mm', 'a4');
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();

      const imageData = this.getBase64Image(document.getElementById('content-img'));

      doc.addImage(imageData, 'PNG', 0, 0, width, height);
      doc.setFontSize(32);
      doc.text('John Snow', 118, 112);
      doc.setFontSize(20);
      doc.text('SR22TN Advanced Transition Training Course', 82, 138);
      doc.setFontSize(13);
      doc.text('SR22TN / Garmin Perspective +', 116, 166);
      doc.text('11/14/1987', 220, 166);
      doc.setFontSize(10);
      doc.text('Cert: 1234567890', 227, 194);
      doc.save('Certificate.pdf');

    } else {
      alert('Please upload certificate to preview');
    }
  }

  initUploader() {
    const url = this.http.getFullUrl('images/upload');
    this.uploader.setOptions({url: url});
    this.uploader.authTokenHeader = 'Access-Token';
    this.uploader.authToken = localStorage.getItem('cirrus-token');
    this.uploader.onAfterAddingFile = (item => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.certificate.certificate_image = JSON.parse(response).location;
      this.addCertificate();
    };
  }

  cancel() {
    this.router.navigate(['admin/certificates']);
  }

}
