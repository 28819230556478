import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureService } from "../feature.service";

@Directive({
  selector: '[appFeature]'
})
export class FeatureDirective implements OnInit {
  @Input() private appFeature: string;

  constructor(
    private featureService: FeatureService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
  ) {}

  ngOnInit(): void {
    if (this.appFeature) {
      this.featureService
        .isFeatureEnabled(this.appFeature)
        .subscribe((isEnabled) => {
          if (isEnabled) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainerRef.clear();
          }
        })
    }
  }
}
