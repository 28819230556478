import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TempDataServiceService } from '../../../services/data-services/temp-data-service.service';
import { Quiz } from '../../../models/course/content/quiz/quiz';
import { slideInRight } from "../../../animations/animations";
import {IMenuItem} from '@app/services/navigation/navigation.service';

@Component({
  selector: 'app-quiz-manager',
  templateUrl: './quiz-manager.component.html',
  styleUrls: ['./quiz-manager.component.scss'],
  animations: [
    slideInRight
  ]
})
export class QuizManagerComponent implements OnInit {

  quiz_model: Quiz;

  categoriesItems: IMenuItem[] = [
    {
      name: 'Quizzes',
      state: '/admin/courses/quizzes/quiz-bank',
      type: 'link'
    },
    {
      name: 'Quiz Builder',
      state: '/admin/courses/quizzes/quiz-builder',
      type: 'link'
    },
    {
      name: 'Question Bank',
      state: '/admin/courses/quizzes/question-bank',
      type: 'link'
    },
    {
      name: 'Question Builder',
      state: '/admin/courses/quizzes/question-builder',
      type: 'link'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
