<div (mouseenter)="hover = true" (mouseleave)="hover = false; close()" class="menu-wrapper"
     fxLayout="wrap"
     fxLayoutAlign="center center"
     *ngIf="showMenu" id="menu" [ngStyle]="{'top': model.positionY+'px', 'left': model.positionX+'px'}">
  <div fxFlex="33" fxFlexAlign="column">
    <a href="#">
      <img src="../../../../assets/images/cirrus-icon.png" alt="">
      <h5>Cirrus Aircraft</h5>
    </a>
  </div>
  <div fxFlex="33" fxFlexAlign="column">
    <a href="#">
      <img src="../../../../assets/images/store-icon.png" alt="">
      <h5>Store</h5>
    </a>
  </div>
  <div fxFlex="33" fxFlexAlign="column">
    <a href="#">
      <img src="../../../../assets/images/parts-icon.png" alt="">
      <h5>Parts & Service</h5>
    </a>
  </div>
  <div fxFlex="33" fxFlexAlign="column">
    <a href="#">
      <img src="../../../../assets/images/training-icon.png" alt="">
      <h5>Training</h5>
    </a>
  </div>
  <div fxFlex="33" fxFlexAlign="column">
    <a href="#">
      <img src="../../../../assets/images/community-icon.png" alt="">
      <h5>Community</h5>
    </a>
  </div>
  <div fxFlex="33" fxFlexAlign="column">
    <a href="#">
      <img src="../../../../assets/images/connect-icon.png" alt="">
      <h5>Connect</h5>
    </a>
  </div>
</div>
