<div id="instructor-connect-wrapper">
  <div class="instructor-connect-title-wrapper">
    <h1 mat-dialog-title>Update Salesforce ID</h1>
  </div>
  <mat-dialog-content>
    <div class="full-width pb-1">
      <p>Update Salesforce ID for {{useremail}} </p>
      <input
        id = "salesforceid"
        type='text'
        style='padding:8px;margin:15px auto;width:30%;'
        placeholder= "{{salesforceID}}"
        [(ngModel)]="user_model.salesforce_id"
        (input)="validateSfId()"
      />
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-wrapper">
      <button mat-button type="button" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" (click)="updateSalesforceID()" [disabled]="!salesforceIdIsValid">Update</button>
    </div>
  </mat-dialog-actions>
  <p *ngIf="!salesforceIdIsValid && user_model.salesforce_id" class="sfid-error-message">
    You must enter a number that starts with 005 and is 18 characters long.
  </p>
</div>
