import {ContentType} from 'app/models/course/content/content-type';
import {Status} from 'app/models/shared/status';
import {Task} from 'app/models/course/content/task/task';
import {ContentComment} from '@app/models/course/content/comment';
import {ContentTask} from '@app/models/course/content/content-task';

export class Content {
    id?: number;
    name?: string;
    desc?: string;
    placeholder_image?: string;
    quiz_id?: number;
    quiz?: any;
    exam_id?: number;
    exam?: any;
    audioOnly?: boolean;
    url?: string;
    order?: number;
    title?: string;
    subtitle?: string;
    content_type?: ContentType;
    content_comments?: ContentComment;
    status?: Status;
    maxAttempts?: number;
    actualAttempts?: number;
    score?: number;
    myBookshelf?: boolean;
    category?: any;
    content_html?: string;
    tasks?: Task[];
    meta_tags?: string[];
    created_by?: string;
    contentFile?: string;
    content_filename?: string;
    jet_scoring?: boolean;
    content_tasks?: ContentTask[];
    upload_image?: string;
    starter_file?: string;
    blob_directory?: string;
    content_version_id?: number;
    stage_id?: number;
    course_id?: number;
    lesson_id?: number;
    version?: number;
    should_force_update? = false;
    show_comments?: boolean;
    summary_content_type?: string;

    constructor() {
        this.tasks = [];
        this.meta_tags = [];
        this.jet_scoring = false;
        this.content_tasks = [];
        this.show_comments = true;
    }
}
