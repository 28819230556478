import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Identity, IdentityService } from '@app/identity.service';

@Injectable()
export class HttpServiceInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private identityService: IdentityService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('cirrus-token');

    if (this.isApiRequest(request)) {
      if (token != null && token !== '' && token.length) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      if (request.url.indexOf('/api/') > -1) {
        request = request.clone({
          setHeaders: {
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'no-cache',
            'Cache-control': 'no-store',
            Expires: '0',
            Pragma: 'no-cache',
          },
        });
      }

      if (request.url.indexOf('/api/v3/images/upload/rich_text') === -1) {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
          },
        });
      }
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const handleHeader = (
              headers,
              headerName,
              identity,
              localStorageKey,
              identityKey
            ) => {
              const headerValue = headers.get(headerName);
              if (headerValue !== undefined && headerValue !== null) {
                if (localStorageKey === 'cirrus-role') {
                  localStorage.setItem(localStorageKey, JSON.stringify(headerValue));
                } else {
                  localStorage.setItem(localStorageKey, headerValue);
                }
                identity[identityKey] = headerValue;
              }
            };

            const nextIdentity: Identity = {};

            handleHeader(
              event.headers,
              'Access-Token',
              nextIdentity,
              'cirrus-token',
              'accessToken'
            );
            handleHeader(
              event.headers,
              'Role',
              nextIdentity,
              'cirrus-role',
              'role'
            );
            handleHeader(
              event.headers,
              'User-Id',
              nextIdentity,
              'cirrus-user-id',
              'userId'
            );
            handleHeader(
              event.headers,
              'User',
              nextIdentity,
              'cirrus-user',
              'user'
            );

            this.identityService.updateIdentity(nextIdentity);
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.identityService.clear();
              localStorage.removeItem('cirrus-token');
              localStorage.removeItem('cirrus-role');
              localStorage.removeItem('cirrus-user-id');
              localStorage.removeItem('cirrus-user');

              this.router.navigate(['learning-catalog']);
            } else if (err.status === 500) {
              this.router.navigate(['/error-page']);
            }
          }
        }
      )
    );
  }

  isApiRequest(request: HttpRequest<any>): boolean {
    return request.url.indexOf('cirrusapproach') > -1;
  }
}
