import { Component, Inject, OnInit } from '@angular/core';

@Component({
  template: ``,
  styles: [],
})
export class RetiredRouteRedirectComponent implements OnInit {

  constructor(@Inject('LocationToken') private location: Location) {}

  ngOnInit(): void {
    this.location.replace('/recent-activity');
  }

}
