import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BadgesDialogComponent} from './badges-dialog/badges-dialog.component';
import { BadgesService } from '@app/services/data-services/badges.service';
import {BadgesCertsComponent} from "../badges-certs.component";

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {
  user_badges: any[] = [];
  badges: any[] = [];
  fltUsrBadges;
  fltBadges;
  currentMax = 6;
  editing = false;
  badgesArrays: any;
  fltSub;
  is_loading = false;
  constructor(private badgeService: BadgesService, private par: BadgesCertsComponent,
              public dialog: MatDialog) { }

  ngOnInit() {
    console.log("Init badges component");
    this.is_loading = true;
    Promise.all([
      this.par.wait,
      this.badgeService.getMyBadges()
        .then(badges => this.badgesArrays = badges)
        .then(() => {
          this.badges = this.badgesArrays.badges;
          this.user_badges = this.badgesArrays.user_badges;
        })
    ]).then(x => {
      this.fltSub = this.par.onFilterChanged.subscribe(([ucs, arch, air, av, trn]) => {

        this.fltUsrBadges = this.user_badges;
        console.log("User Badges: ", this.fltUsrBadges);
        this.fltBadges = this.badges;
        this.is_loading = false;

        const flt = (v, fn) => {
          if (v != 'all') {
            this.fltUsrBadges = this.fltUsrBadges.filter(fn);
            this.fltBadges = this.fltBadges.filter(fn)
          }
        };
        flt(arch, b => ucs.filter(uc => uc.course_version.id == b.course.id && uc.archived).length > 0);
        flt(air, b => b.aircraft == air);
        flt(av, b => b.avionic == av);
        flt(trn, b => ucs.filter(uc => uc.course_version.id == b.course.id && uc.course.course_category_id == trn).length > 0);

      });
      this.par.filterChanged();
    })
  }

  ngOnDestroy() {
    if (this.fltSub) {
      this.fltSub.unsubscribe();
    }
  }

  openDialog(content, isUserBadge) {
    const dialogRef = this.dialog.open(BadgesDialogComponent, {
      width: '80vw',
      maxWidth: '90%',
      data: {
        content,
        isUserBadge
      }
    });
  }

  showMore() {
    this.currentMax += 8;
    this.editing = !this.editing;
  }

  showLess() {
    this.currentMax = 6;
    this.editing = !this.editing;
  }

  gridSize() {
    return window.matchMedia(`(max-width: 960px)`).matches ? window.matchMedia(`(max-width: 768px)`).matches ? window.matchMedia(`(max-width: 500px)`).matches ? 1 : 4 : 4 : 6;
  }

}
