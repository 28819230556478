import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gamification-manager',
  templateUrl: './gamification-manager.component.html',
  styleUrls: ['./gamification-manager.component.scss']
})
export class GamificationManagerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
