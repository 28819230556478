<div class="dialog-content-wrapper">
  <button class="close-btn" mat-icon-button mat-dialog-close (click)="close()"><mat-icon class="close-icon">close</mat-icon></button>
  <mat-tab-group fxFlex="grow" class="p-05" (selectedTabChange)="clearSelected($event)">
    <mat-tab label="All Users">
      <mat-dialog-content class="content-dialog-wrapper" id="notif-dialog">
        <div class="content-wrapper"
             fxLayout="column wrap">
          <div class="navtop" fxFlex="100">
            <input
              type='text'
              style='padding:8px;margin:15px auto;width:30%;'
              placeholder='Filter Users'
              (keyup)='updateUserFilter($event)'
            />
          </div>
          <div class="almost-full-width content-wrapper">
            <div class="mat-box-shadow margin-333">
              <ngx-datatable #table class="material bg-white"
                             [rows]="userRows"
                             [columnMode]="'force'"
                             [headerHeight]="50"
                             [footerHeight]="50"
                             [rowHeight]="'auto'"
                             [limit]="50"
                             [selected]="selectedUsers"
                             [selectionType]="'checkbox'"
                             [selectAllRowsOnPage]="false"
                             (select)='onUserSelect($event)'>
                <ngx-datatable-column
                  [width]="30"
                  [sortable]="false"
                  [canAutoResize]="false"
                  [draggable]="false"
                  [resizeable]="false"
                  [headerCheckboxable]="true"
                  [checkboxable]="true">
                </ngx-datatable-column>
                <ngx-datatable-column name="Name"></ngx-datatable-column>
                <ngx-datatable-column name="Email"></ngx-datatable-column>
                <ngx-datatable-column
                  [sortable]="false"
                  [canAutoResize]="true"
                  [draggable]="false"
                  [resizeable]="false"
                  name="Role">
                  <ng-template let-row='row' ngx-datatable-cell-template>
                    {{userRoleMap[userRoles[row.role]]}}
                  </ng-template>
                </ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions id="actions">
        <div fxFlex></div>
        <button class="mr-1" mat-raised-button [color]="'primary'" (click)="createMessage()">Notify Users</button>
      </mat-dialog-actions>
    </mat-tab>
    <mat-tab label="Instructors">
      <mat-dialog-content class="content-dialog-wrapper">
        <div class="content-wrapper"
             fxLayout="column wrap">
          <div class="navtop" fxFlex="100">
            <input
              type='text'
              style='padding:8px;margin:15px auto;width:30%;'
              placeholder='Filter Users'
              (keyup)='updateInstructorFilter($event)'
            />
          </div>
          <div class="almost-full-width content-wrapper">
            <div class="mat-box-shadow margin-333">
              <ngx-datatable #table class="material bg-white"
                             [rows]="instructorRows"
                             [columnMode]="'force'"
                             [headerHeight]="50"
                             [footerHeight]="50"
                             [rowHeight]="'auto'"
                             [limit]="50"
                             [selected]="selectedInstructors"
                             [selectionType]="'checkbox'"
                             [selectAllRowsOnPage]="false"
                             (select)='onInstructorSelect($event)'>
              <ngx-datatable-column
                [width]="30"
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                [headerCheckboxable]="true"
                [checkboxable]="true">
              </ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="false"
                [canAutoResize]="true"
                [draggable]="false"
                [resizeable]="false"
                name="Name">
                <ng-template let-row='row' ngx-datatable-cell-template>
                  {{row.firstName}} {{row.lastName}}
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Email" prop='user.email'></ngx-datatable-column>
              </ngx-datatable>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions id="actions">
        <div fxFlex></div>
        <button class="mr-1" mat-raised-button [color]="'primary'" (click)="createMessage()">Notify Instructors</button>
      </mat-dialog-actions>
    </mat-tab>
  </mat-tab-group>
</div>
