import {Injectable} from '@angular/core';
import {ProgressService} from '@app/services/data-services/progress.service';
import {UserProgressType} from '@app/models/user/user-progress-type';
import {UserProgress} from '@app/models/user/user-progress';
import {Status} from '@app/models/shared/status';
import {UserCourse} from '@app/models/course/user-course';
import {Stage} from '@app/models/course/workbook/stage/stage';
import {Lesson} from '@app/models/course/workbook/lesson/lesson';
import {Content} from '@app/models/course/content/content';
import {reject} from 'q';
import {ContactUser} from '@app/models/user/user';
import {CourseAttempt} from '@app/models/course/course-attempt';

@Injectable()
export class ProgressCalculatorService {


  progresses: any[] = [];
  constructor(public progressService: ProgressService) {

  }

  /**
   * Set's the progresses that the calculator will use to determine completion, etc.
   * @param progresses
   */
  public setProgresses(progresses) {
    this.progresses = progresses;
  }
  /**
   * Gets progress of user course for transcript
   * @param {UserCourse} course
   */
  public getTranscriptProgress(userCourse) {
     if (this.checkIfCourseIsComplete(userCourse)) {
       // console.log('User Course: ', userCourse.course);
      return [true];
    } else {
      const progress = [];
      if (userCourse.course && userCourse.workbook && userCourse.workbook.stages) {
        userCourse.workbook.stages.forEach(stage => {
          progress.push(this.checkIfStageIsComplete(stage));
          // console.log('checkIfStageIsComplete: ', this.checkIfStageIsComplete(stage));
          if (stage.lessons) {
            stage.lessons.forEach(lesson => {
              progress.push(this.checkIfLessonIsComplete(lesson));
              if (lesson && lesson.contents) {
                lesson.contents.forEach(content => {
                  progress.push(this.checkIfContentIsComplete(lesson, content));
                });
              }
            });
          }
        });
      }
      return progress;
    }
  }

  public checkForCourseProgressComplete(course) {
    const completed = this.progresses.filter(progress => progress.progress_type.toString() === 'course' && progress.item_id === course.course_id && progress.status.toString() === 'completed');
    if (completed.length) {
      return completed[0];
    }
    return false;
  }

  /**
   * Gets the progress for the specific course
   * @param {UserCourse} course
   * @returns {any}
   */
  public getProgressForCourse(course: UserCourse) {
    return this.progresses.find(progress => ((progress.progress_type.toString() === 'course' || progress.progress_type === 3) && progress.item_id === course.course_id));

  }

  /**
   * Gets the progress for the specific stage
   * @returns {any}
   * @param stage
   */
  public getProgressForStage(stage: Stage) {

    for (const progress of this.progresses) {

      if (progress.progress_type.toString() === 'stage' && progress.item_id === stage.id) {

        return progress;
      }
    }

    return null;
  }

  /**
   * Gets the progress for the specific lesson
   * @param {Lesson} lesson
   * @returns {any}
   */
  public getProgressForLesson(lesson: Lesson) {

      for (const progress of this.progresses) {

          if (progress.progress_type.toString() === 'lesson' && progress.item_id === lesson.id) {
              return progress;
          }
      }

      return null;
  }


  /**
   * Gets the progress for the specific content
   * @returns {any}
   * @param content
   * @param course
   */
  public getProgressForContent(content: Content, course: UserCourse) {

    for (const progress of this.progresses) {
      if (progress.progress_type.toString() === 'content' && progress.item_id === content.id && course.course_id === progress.course_id) {
        return progress;
      }
    }
    return null;
  }

  public checkIfContentIsForceCompleted(lesson, content, progresses?) {

    if (progresses) {
      this.setProgresses(progresses);
    }

    if (this.checkIfProgressIsSet()) {
      return false;
    }

    return (this.progresses.filter(progress => progress.progress_type.toString() === 'content' && progress.status.toString() === 'completed' && progress.is_force_completed === true && progress.lesson_id === lesson.id && progress.item_id === content.id).length > 0);

  }

  public getScormProgressItem(lesson_id, content) {
    return this.progresses.filter(progress => progress.progress_type.toString() === 'content' && progress.lesson_id === lesson_id && progress.item_id === content.id);
  }

  public checkIfAssignmentIsComplete(lesson, assignment, progresses?) {
    if (progresses) {
      this.setProgresses(progresses);
    }

    if (this.checkIfProgressIsSet()) {
      return false;
    }

    return (this.progresses.filter(progress => progress.lesson_id === lesson.id && progress.progress_type.toString() === 'assignment' && progress.item_id === assignment.id).length !== 0);
  }

  /**
   * Checks to see if a content progress exists for the lesson.
   * @param content
   * @param lesson
   * @param progresses
   * @returns {boolean}
   */
  public progressDoesNotExistForContentInLesson(content, lesson, progresses?): UserProgress[] {

    if (progresses) {
        this.setProgresses(progresses);
    }
    if (this.checkIfProgressIsSet()) {
        return [];
    }
    // console.log(this.progresses);
    // console.log(this.progresses.filter(progress => progress.progress_type && progress.progress_type.toString() === 'content' && progress.lesson_id === lesson.id && progress.item_id === content.id));
    return this.progresses.filter(progress => progress.progress_type && progress.progress_type.toString() === 'content' && progress.lesson_id === lesson.id && progress.item_id === content.id);
  }


  /**
   * Will check if the specific stage is finished. If it is, we can update the status accordingly.
   * @param userCourse
   */

  public checkIfCourseIsComplete(userCourse: UserCourse) {
    if (userCourse && userCourse.course && userCourse.course_attempt_id && userCourse.workbook && userCourse.workbook.stages.length === 0) {
      // console.log('returning false, stages length 0');
      return false;
    }

    if (this.progresses) {
      // console.log('userCourse', userCourse);
      const courseProgress = this.progresses.find(progress => progress.progress_type === 3 || progress.progress_type.toString() === 'course' && progress.item_id === userCourse.course.id && progress.course_attempt_id === userCourse.course_attempt_id);
      // console.log('courseProgress', courseProgress);
      if (!courseProgress) {
        return false;
      }
      // console.log('courseProgress Status', courseProgress.status);
      if (courseProgress.status === 'completed') {
        return true;
      }
    }

    // console.log('here is the user course object', userCourse);
    // console.log('here is the user course course', userCourse.course);
    // console.log('here is the user course course attempt', userCourse.course_attempt);
    // console.log('here is the user course workbook', userCourse.workbook);
    if (userCourse && userCourse.course && userCourse.course_attempt_id && userCourse.workbook) {
      // console.log('Passed the first if...here are the stages', userCourse.workbook.stages);
      for (const stage of userCourse.workbook.stages) {
        // console.log('courseProgress Status 2', this.checkIfStageIsComplete(stage));
        if (!this.checkIfStageIsComplete(stage)) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Will check if the specific stage is finished. If it is, we can update the status accordingly.
   * @param stage
   */

  public checkIfStageIsComplete(stage: Stage) {

      if (stage.lessons.length === 0) {
        return false;
      }

    if (this.progresses) {
      if (this.progresses.filter(progress => progress.progress_type.toString() === 'stage' && progress.item_id === stage.id && progress.status === 'completed').length > 0) {
        return true;
      }
    }

      for (const lesson of stage.lessons) {
          if (!this.checkIfLessonIsComplete(lesson)) {
              return false;
          }
      }

      return true;
  }

  /**
   * Will check if the specific lesson is finished. If it is, we can update the status accordingly.
   * @param {Lesson} lesson
   * @param {Number} course_attempt_id
   */

  public checkIfLessonIsComplete(lesson: Lesson) {

    if (!this.progresses) {
      return false;
    }

    // IF there is no content in the lesson, we don't want to say the lesson is complete
    if (lesson && lesson.contents && (lesson as any).contents.length === 0) {
      return false;
    }

    if (this.progresses.filter(progress => progress.progress_type.toString() === 'lesson' && progress.item_id === lesson.id && progress.status === 'completed').length > 0) {
      return true;
    }

    if (lesson && lesson.contents) {
      for (const content of ((lesson as any).contents)) {
        if (!this.checkIfContentIsComplete(lesson, content)) {
          return false;
        }
      }
    } else {
      return false;
    }

    return true;
  }

  public checkIfContentIsComplete(lesson, content, progresses?) {

    if (progresses) {
      this.setProgresses(progresses);
    }

    if (this.checkIfProgressIsSet()) {
      return false;
    }

    return (this.progresses.filter(progress => (progress.progress_type.toString() === 'content' || progress.progress_type === 0) &&
      (progress.status === 2 || progress.status.toString() === 'completed') && progress.lesson_id === lesson.id && progress.item_id === content.id).length > 0);
  }

  /**
   * Checks is the progress in the service is set so calculations can be made
   * @returns {boolean}
   */
  private checkIfProgressIsSet() {
    return !this.progresses;
  }

  public updateStatusToCompleteForSpecificUser(progressToUpdate, user_id) {
    if (progressToUpdate && progressToUpdate.status !== 'completed' || progressToUpdate.status !== 2) {
      progressToUpdate.status = Status.completed;
      this.progressService.updateProgressForSpecificUser(progressToUpdate, user_id).then(updated_progress => {
        const index = this.progresses.map(progress => progress.id).indexOf(updated_progress.id);
        if (index > -1) {
          this.progresses[index] = updated_progress;
        }
        return (updated_progress.progress_type.toString() === 'course' || updated_progress.progress_type === 3) && (updated_progress.status.toString() === 'completed' || updated_progress.status === 2);
      });
    } else {
      return false;
    }
  }

  public updateStatusToComplete(progressToUpdate): Promise<boolean> {
      if (progressToUpdate && progressToUpdate.status !== Status.completed) {
        progressToUpdate.status = Status.completed;
        return this.progressService.updateProgress(progressToUpdate).then(updated_progress => {
          const index = this.progresses.map(progress => progress.id).indexOf(updated_progress.id);
          if (index > -1) {
            this.progresses[index] = updated_progress;
            // console.log('Changed Progress Items', updated_progress);
          }
          return updated_progress.progress_type.toString() === 'course' && updated_progress.status.toString() === 'completed';
        });
      } else {
        return Promise.resolve(false);
      }
  }

  public updateProgressItem(updated_progress) {
    const index = this.progresses.map(progress => progress.id).indexOf(updated_progress.id);
    if (index > -1) {
      this.progresses[index] = updated_progress;
      // console.log('Changed Progress Items', updated_progress);
    }
  }

  /**
   * Checks the progress of all three items, and generates progress entries
   * @param userCourse
   * @param courseAttempt
   * @param stage
   * @param lesson
   * @param content
   * @param progresses
   * @param callback
   * @returns {boolean}
   */

  checkProgress(userCourse: UserCourse, courseAttempt: CourseAttempt, stage: Stage, lesson: Lesson, content: Content, progresses?: UserProgress[], callback?: Function) {

    if (progresses) {
        this.setProgresses(progresses);
    }

    if (this.checkIfProgressIsSet()) {
        return false;
    }
    this.checkLesson(userCourse, courseAttempt, stage, lesson).then(() => {
      this.checkStage(userCourse, courseAttempt, stage).then(() => {
        this.checkCourse(userCourse, courseAttempt).then((res) => {
          // console.log('COURSE COMPLETED 1 :', res);
          if (res) {
            // console.log('COURSE COMPLETED:', res);
            callback();
          }
        });
      });
    });
  }

  checkProgressForSpecificUser(course_attempt_id: number, user_id: number, course: UserCourse, stage: Stage, lesson: Lesson, content: Content, progresses?: UserProgress[], callback?: Function) {

    if (progresses) {
      this.setProgresses(progresses);
    }

    if (this.checkIfProgressIsSet()) {
      return false;
    }
    this.checkLessonEntryForSpecificUser(course_attempt_id, user_id, course, stage, lesson).then(() => {
      this.checkStageEntryForSpecificUser(course_attempt_id, user_id, course, stage).then(() => {
        this.checkCourseEntryForSpecificUser(course_attempt_id, user_id, course).then((res) => {
          if (res) {
            callback();
          }
        });
      });
    });
  }

  checkLesson(userCourse, courseAttempt, stage, lesson) {
    return new Promise(resolve => {
      if (!this.checkIfLessonExists(lesson)) {
        const progressToSave = new UserProgress();
        progressToSave.course_id = userCourse.course.id;
        progressToSave.course_attempt_id = courseAttempt.id;
        progressToSave.progress_type = UserProgressType.lesson;
        progressToSave.item_id = lesson.id;
        progressToSave.stage_id = stage.id;
        if (this.checkIfLessonIsComplete(lesson)) {
          progressToSave.status = Status.completed;
        } else {
          progressToSave.status = Status.in_progress;
        }
        // console.log('Checking Lesson...');
        const updated = this.saveProgress(progressToSave, false);
        resolve(updated);
      } else {
        if (this.checkIfLessonIsComplete(lesson)) {
          const progressToUpdate = this.getProgressForLesson(lesson);
          this.updateStatusToComplete(progressToUpdate).then(resolve);
        }
      }
    });
  }

  checkStage(userCourse, courseAttempt, stage) {
    return new Promise((resolve => {
      if (!this.checkIfStageExists(stage)) {
        const progressToSave = new UserProgress();
        progressToSave.course_id = userCourse.course.id;
        progressToSave.course_attempt_id = courseAttempt.id;
        progressToSave.progress_type = UserProgressType.stage;
        progressToSave.item_id = stage.id;
        if (this.checkIfStageIsComplete(stage)) {
          progressToSave.status = Status.completed;
        } else {
          progressToSave.status = Status.in_progress;
        }
        // console.log('Checking Stage...');
        const updated = this.saveProgress(progressToSave, false);
        resolve(updated);
      } else {
        if (this.checkIfStageIsComplete(stage)) {
          const progressToUpdate = this.getProgressForStage(stage);
          this.updateStatusToComplete(progressToUpdate).then(resolve);
        }
      }
    }));
  }

  checkCourse(userCourse, courseAttempt) {
    return new Promise((resolve => {
      if (!this.checkIfCourseExists(userCourse)) {
        const progressToSave = new UserProgress();
        progressToSave.course_id = userCourse.course_id;
        progressToSave.course_attempt_id = courseAttempt.id;
        progressToSave.progress_type = UserProgressType.course;
        progressToSave.item_id = userCourse.course_id;

        const completed = this.checkIfCourseIsComplete(userCourse);
        if (completed) {
          progressToSave.status = Status.completed;
        } else {
          progressToSave.status = Status.in_progress;
        }
        // console.log('Checking Course...');

        const updated = this.saveProgress(progressToSave, completed);
        resolve(updated);
      } else {
        if (this.checkIfCourseIsComplete(userCourse)) {
          const progressToUpdate = this.getProgressForCourse(userCourse);
          this.updateStatusToComplete(progressToUpdate).then(resolve);
        }
      }
    }));
  }

  checkLessonEntryForSpecificUser(course_attempt_id, user_id, userCourse, stage, lesson) {
    // console.log('checkLessonEntryForSpecificUser');
    return new Promise(resolve => {
      // console.log('checkIfLessonExists', this.checkIfLessonExists(lesson))
      if (!this.checkIfLessonExists(lesson)) {
        const lesson_to_check = lesson;
        const progressToSave = new UserProgress();
        if (userCourse && userCourse.course && userCourse.course.id) {
          progressToSave.course_id = userCourse.course.id;
        } else if (userCourse && userCourse.id) {
          progressToSave.course_id = userCourse.id;
        }
        progressToSave.course_attempt_id = course_attempt_id;
        progressToSave.progress_type = UserProgressType.lesson;
        progressToSave.item_id = lesson.id;
        progressToSave.stage_id = stage.id;
        progressToSave.status = Status.in_progress;
        const saved = this.saveProgressForSpecificUser(user_id, progressToSave, false).then(res => {
          if (this.checkIfLessonExists(lesson_to_check)) {
            if (this.checkIfLessonIsComplete(lesson_to_check)) {
              const progressToUpdate = this.getProgressForLesson(lesson_to_check);
              const updated = this.updateStatusToCompleteForSpecificUser(progressToUpdate, user_id);
              resolve(updated);
            } else {
              resolve(saved);
            }
          }
        });
      } else if (this.checkIfLessonExists(lesson)) {
        // console.log('checkIfLessonIsComplete', this.checkIfLessonIsComplete(lesson));
        if (this.checkIfLessonIsComplete(lesson)) {
          const progressToUpdate = this.getProgressForLesson(lesson);
          const updated = this.updateStatusToCompleteForSpecificUser(progressToUpdate, user_id);
          resolve(updated);
        }
      }
    });
  }

  checkStageEntryForSpecificUser(course_attempt_id, user_id, userCourse, stage) {
    // console.log('checkStageEntryForSpecificUser');
    return new Promise(resolve => {
      // console.log('checkIfStageExists', this.checkIfStageExists(stage))
      if (!this.checkIfStageExists(stage)) {
        const progressToSave = new UserProgress();
        const stage_to_check = stage;
        if (userCourse && userCourse.course && userCourse.course.id) {
          progressToSave.course_id = userCourse.course.id;
        } else if (userCourse && userCourse.id) {
          progressToSave.course_id = userCourse.id;
        }
        progressToSave.course_attempt_id = course_attempt_id;
        progressToSave.progress_type = UserProgressType.stage;
        progressToSave.item_id = stage.id;
        progressToSave.status = Status.in_progress;
        const saved = this.saveProgressForSpecificUser(user_id, progressToSave, false).then(res => {
          if (this.checkIfStageExists(stage_to_check)) {
            if (this.checkIfStageIsComplete(stage_to_check)) {
              const progressToUpdate = this.getProgressForStage(stage_to_check);
              const updated = this.updateStatusToCompleteForSpecificUser(progressToUpdate, user_id);
              resolve(updated);
            } else {
              resolve(saved);
            }
          }
        });
      } else if (this.checkIfStageExists(stage)) {
        // console.log('checkIfStageIsComplete', this.checkIfStageIsComplete(stage));
        if (this.checkIfStageIsComplete(stage)) {
          const progressToUpdate = this.getProgressForStage(stage);
          const updated = this.updateStatusToCompleteForSpecificUser(progressToUpdate, user_id);
          resolve(updated);
        }
      }
    });
  }

  checkCourseEntryForSpecificUser(course_attempt_id, user_id, course) {
     // console.log('checkCourseEntryForSpecificUser');
    return new Promise(resolve => {
       // console.log('checkIfCourseExists', this.checkIfCourseExists(course))
      if (!this.checkIfCourseExists(course)) {
        const progressToSave = new UserProgress();
        const course_to_check = course;
        progressToSave.course_attempt_id = course_attempt_id;
        progressToSave.course_id = course.course_id;
        progressToSave.progress_type = UserProgressType.course;
        progressToSave.item_id = course.course_id;
        progressToSave.status = Status.in_progress;
        const saved = this.saveProgressForSpecificUser(user_id, progressToSave, false).then(res => {
          // console.log('saved is ', saved)
          if (this.checkIfCourseExists(course_to_check)) {
            // console.log('11-intocheckifexists', course_to_check)
            if (this.checkIfCourseIsComplete(course_to_check)) {
              // console.log('22-intocheckifcoursecomplete', course_to_check)
              const progressToUpdate = this.getProgressForCourse(course_to_check);
              // console.log('33-progressToUpdate', progressToUpdate)
              let updated = this.updateStatusToCompleteForSpecificUser(progressToUpdate, user_id);
              resolve(true);
            } else {
              resolve(saved);
            }
          }
        });
      } else if (this.checkIfCourseExists(course)) {
         // console.log('Course Progress exists');
         // console.log('checkIfCourseIsComplete', this.checkIfCourseIsComplete(course));
        if (this.checkIfCourseIsComplete(course)) {
          const progressToUpdate = this.getProgressForCourse(course);
           // console.log('progressToUpdate: ', progressToUpdate);
          let updated = this.updateStatusToCompleteForSpecificUser(progressToUpdate, user_id);
          resolve(updated);
        }
      }
    });
  }

  //  Used when force completing a course at the course level
  forceCompleteCourseForSpecificUser(user_id, course) {
    return new Promise(resolve => {
      if (!this.checkIfCourseExists(course)) {
        const progressToSave = new UserProgress();
        progressToSave.course_id = course.course_id;
        progressToSave.progress_type = UserProgressType.course;
        progressToSave.item_id = course.course_id;
        progressToSave.status = Status.completed;
        const saved = this.saveProgressForSpecificUser(user_id, progressToSave, false).then(res => {
          resolve(saved);
        });
      } else if (this.checkIfCourseExists(course)) {
        if (this.checkIfCourseIsComplete(course)) {
          const progressToUpdate = this.getProgressForStage(course);
          this.updateStatusToComplete(progressToUpdate).then(resolve);
        }
      }
    });
  }

  public checkIfCourseExists(course: UserCourse) {
    return this.progresses.filter(progress => progress.progress_type.toString() === 'course' && progress.item_id === course.course_id).length > 0;
  }

  public checkIfStageExists(stage: Stage) {
    return this.progresses.filter(progress => progress.progress_type.toString() === 'stage' &&  progress.item_id === stage.id).length > 0;
  }

  public checkIfLessonExists(lesson: Lesson) {
    return this.progresses.filter(progress => progress.progress_type.toString() === 'lesson' && progress.item_id === lesson.id).length > 0;
  }

  private saveProgress(progressToSave, courseComplete?) {
    return this.progressService.saveProgress(progressToSave).then(progressReturned => {
      if (progressReturned) {
        this.progresses.push(progressReturned);
        return !!courseComplete;
      } else {
        return false;
      }
    });
  }

  public saveProgressForSpecificUser(user_id, progressToSave, courseComplete?) {
    return this.progressService.saveProgressForSpecificUser(progressToSave, user_id).then(progressReturned => {
      if (progressReturned) {
        this.progresses.push(progressReturned);
        return !!courseComplete;
      } else {
        return false;
      }
    });
  }

    /**
     * Creates a progress entry for content
     * @param course
     * @param attempt
     * @param stage
     * @param lesson
     * @param content
     * @param status
     * @param score
     * @param scorm_progress
     */
    public createProgressForContent(course, attempt, stage, lesson, content, score?, status?, scorm_progress?) {
      // console.log(content);
      // console.log(lesson);
        return new Promise((resolve) => {
          let progressToSave = new UserProgress();
          const progressItem = this.progressDoesNotExistForContentInLesson(content, lesson);
          // console.log('PROGRESS ITEM IN CALC SERVICE:', progressItem, content, lesson);
          try {
            if (progressItem.length === 0) {
              progressToSave.course_id = course.course_id;
              progressToSave.course_attempt_id = attempt.id;
              progressToSave.progress_type = UserProgressType.content;
              progressToSave.status = status ? status : Status.completed;
              progressToSave.item_id = content.id;
              progressToSave.lesson_id = lesson.id;
              progressToSave.stage_id = stage.id;
              progressToSave.scorm_progress = scorm_progress === null || scorm_progress === '' ? null : JSON.stringify(scorm_progress);
              // If the user wants to submit a score, then this must be a quiz
              if (score !== undefined) {
                progressToSave.score = score;
              }
              this.progressService.saveProgress(progressToSave).then(progressReturned => {
                if (progressReturned) {
                  // console.log('PUSHED PROGRESS TO ARRAY AFTER SAVE: ', progressReturned);
                  this.progresses.push(progressReturned);
                }
                resolve(progressReturned);
              });
            } else if (progressItem.length) {
              progressToSave = progressItem[0];
              if (progressToSave.status.toString() === 'completed') {
                return;
              }
              progressToSave.course_id = course.course_id;
              progressToSave.course_attempt_id = attempt.id;
              progressToSave.progress_type = UserProgressType.content;
              progressToSave.status = status ? status : Status.completed;
              progressToSave.item_id = content.id;
              progressToSave.lesson_id = lesson.id;
              progressToSave.stage_id = stage.id;
              progressToSave.scorm_progress = scorm_progress === null || scorm_progress === '' ? null : JSON.stringify(scorm_progress);
              // If the user wants to submit a score, then this must be a quiz
              if (score !== undefined) {
                progressToSave.score = score;
              }

              this.progressService.updateProgress(progressToSave)
                .then(res => resolve(res));

              // console.log(progressToSave);
              // const updated = this.updateStatusToComplete(progressToSave);
              // resolve(updated);

            }
          } catch (e) {
            // console.log('An error occurred when attempting to create progress...');
            // console.log(e);
          }
        });
    }

  public createProgressForContentForSpecificUser(course_attempt_id, user_id, course, stage, lesson, content, level?, score?, status?, scorm_progress?) {
    return new Promise((resolve) => {
      let progressToSave = new UserProgress();
      const progressItem = this.progressDoesNotExistForContentInLesson(content, lesson);
      // console.log('PROGRESS ITEM IN CALC SERVICE:', progressItem, content, lesson);
      if (progressItem.length === 0) {
        progressToSave.course_attempt_id = course_attempt_id;
        progressToSave.course_id = course.course_id;
        progressToSave.progress_type = UserProgressType.content;
        progressToSave.status = status ? status : Status.completed;
        progressToSave.item_id = content.id;
        progressToSave.lesson_id = lesson.id;
        progressToSave.stage_id = stage.id;
        progressToSave.scorm_progress = scorm_progress;
        // If the user wants to submit a score, then this must be a quiz
        if (score !== undefined) {
          progressToSave.score = score;
        }
        this.progressService.saveProgressForSpecificUser(progressToSave, user_id, level).then(progressReturned => {
          if (progressReturned) {
            this.progresses.push(progressReturned);
          }
          resolve(progressReturned);
        });
      } else if (progressItem.length) {
        progressToSave = progressItem[0];
        if (progressToSave.status.toString() === 'completed') {
          return;
        }
        progressToSave.course_attempt_id = course_attempt_id;
        progressToSave.course_id = course.course_id;
        progressToSave.progress_type = UserProgressType.content;
        progressToSave.status = status ? status : Status.completed;
        progressToSave.item_id = content.id;
        progressToSave.lesson_id = lesson.id;
        progressToSave.stage_id = stage.id;
        progressToSave.scorm_progress = scorm_progress;
        // If the user wants to submit a score, then this must be a quiz
        if (score !== undefined) {
          progressToSave.score = score;
        }

        this.progressService.updateProgressForSpecificUser(progressToSave, user_id)
          .then(res => resolve(res));

        // console.log(progressToSave);
        // const updated = this.updateStatusToComplete(progressToSave);
        // resolve(updated);

      }
    });
  }

  /**
   * Creates a progress entry for content
   * @param course_attempt_id
   * @param course
   * @param stage
   * @param lesson
   * @param content
   * @param status
   * @param store
   * @param user_id
   * @param score
   */
  public syncProgressForContent(course_attempt_id, course, stage, lesson, content, score?, status?, store?, user_id?) {
    return new Promise((resolve) => {
      let progressToSave = new UserProgress();
      const progressItem = this.progressDoesNotExistForContentInLesson(content, lesson);
      // console.log('PROGRESS ITEM IN CALC SERVICE:', progressItem);
      if (progressItem.length === 0) {
        progressToSave.course_attempt_id = course_attempt_id;
        progressToSave.course_id = course.course_id;
        // console.log('STATUS IN CALC SERVICE:', progressToSave);
        progressToSave.status = status ? status : Status.completed;
        progressToSave.progress_type = UserProgressType.content;
        progressToSave.item_id = content.id;
        progressToSave.lesson_id = lesson.id;
        progressToSave.stage_id = stage.id;
        if (user_id) {
          progressToSave.user_id = user_id;
        }
        // If the user wants to submit a score, then this must be a quiz
        if (score !== undefined) {
          progressToSave.score = score;
        }
        this.progressService.syncProgress(progressToSave).then(progressReturned => {
          if (progressReturned && store) {
            // console.log('PUSHED PROGRESS TO ARRAY AFTER SAVE: ', progressReturned);
            this.progresses.push(progressReturned);
          }
          resolve(progressReturned);
        });
      } else if (progressItem.length) {
        progressToSave = progressItem[0];
        if (progressToSave.status.toString() === 'completed' && !status) {
          return;
        }

        progressToSave.course_id = course.course_id;
        progressToSave.course_attempt_id = course_attempt_id;
        progressToSave.progress_type = UserProgressType.content;
        progressToSave.status = status ? status : Status.completed;
        progressToSave.item_id = content.id;
        progressToSave.lesson_id = lesson.id;
        progressToSave.stage_id = stage.id;
        if (user_id) {
          progressToSave.user_id = user_id;
        }
        // If the user wants to submit a score, then this must be a quiz
        if (score !== undefined) {
          progressToSave.score = score;
        }
        this.progressService.syncProgress(progressToSave)
          .then(res => resolve(res));
      }
    });
  }
}
