import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InstructorService} from '@app/services/data-services/instructor.service';
import {ProgressService} from '@app/services/data-services/progress.service';
import {UserProgress} from '@app/models/user/user-progress';
import {Status, StatusMap} from '@app/models/shared/status';
import {ProgressCalculatorService} from '@app/services/helper/progress-calculator.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {LessonFlightLogService} from '@app/services/data-services/lesson-flight-log.service';
import {LessonFlightLog} from '@app/models/course/workbook/lesson/lesson-flight-log';
import {TaskService} from '@app/services/data-services/task.service';
import {TaskAttempt} from '@app/models/course/content/task/task-attempt';
import {DatePipe} from '@angular/common';
import {UserService} from '@app/services/data-services/user.service';
import {Store} from '@ngrx/store';
import * as fromApp from '../../../../../../store/app.reducers';
import * as AssessmentActions from '../store/assessment.actions';
import {Subscription} from 'rxjs';
import {TaskDeferment} from '@app/models/course/content/task/task-deferment';
import {Content} from "@app/models/course/content/content";

@Component({
  selector: 'app-student-assessments',
  templateUrl: './student-assessments.component.html',
  styleUrls: ['./student-assessments.component.scss']
})
export class StudentAssessmentsComponent implements OnInit, OnDestroy {
  selected: any[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;
  assessments: any[] = [];
  rows: any[] = [];
  temp: any[] = [];
  userProgress: UserProgress[] = [];
  lesson_flight_logs: LessonFlightLog[] = [];
  task_attempts: TaskAttempt[] = [];
  user_id: number;
  statusTypes = Status;
  statusMap = StatusMap;
  isLoading = false;
  datePipe = new DatePipe('en-US');
  user_name = '';
  sub: Subscription = new Subscription();
  deferments: TaskDeferment[] = [];

  constructor(private router: Router,
              private instructorService: InstructorService,
              private calculatorService: ProgressCalculatorService,
              private activatedRoute: ActivatedRoute,
              private flightLogService: LessonFlightLogService,
              private taskService: TaskService,
              private progressService: ProgressService,
              private store: Store<fromApp.AppState>,
              private userService: UserService) { }

  ngOnInit() {
    this.store.dispatch(new AssessmentActions.ClearAssessmentInfo());
    this.activatedRoute.params.subscribe(params => {
        this.store.dispatch(new AssessmentActions.FetchAssessmentLogbooks(params));
        this.store.dispatch(new AssessmentActions.FetchAssessmentEntries(params));
        this.store.dispatch(new AssessmentActions.FetchAssessments(params));
        this.store.dispatch(new AssessmentActions.FetchDeferments(params));
        this.store.dispatch(new AssessmentActions.FetchDeferReasons());
        this.store.dispatch(new AssessmentActions.FetchAssessmentProgress(params));
    });
    this.sub.add(this.store.select('assessment').subscribe(state => {
      this.assessments = state.assessments;
      this.lesson_flight_logs = state.assessmentLogbooks;
      this.task_attempts = state.assessmentTaskEntries;
      if (state.selectedStudent.contact != null) {
        this.user_name = state.selectedStudent ? state.selectedStudent.contact.name : '';
      }
      this.user_id = state.selectedStudent.id;
      this.isLoading = state.loading.length > 0;
      this.userProgress = state.assessmentProgress;
      this.deferments = state.deferments;
      this.parseContent();
    }));
  }

  parseContent() {

    const temp = [];
    this.assessments.forEach(assessment => {
      const content = assessment.content;
      const taskStatus = this.getTaskStatus(content, assessment.lesson_id);

      content.course_id = assessment.course_id;
      content.stage_id = assessment.stage_id;
      content.lesson_id = assessment.lesson_id;
      temp.push({
        id: content.id,
        logs: this.getLogs(assessment.content.id),
        lesson: assessment.lesson_title,
        lesson_id: assessment.lesson_id,
        completed_date: this.getProgress(content.id, content.stage_id, content.lesson_id, content.course_id).completed_date,
        status: this.getProgress(content.id, content.stage_id, content.lesson_id, content.course_id).status,
        assessment: content.title,
        content: content,
        total: content.tasks.length,
        completed: taskStatus.completed.length,
        in_progress: taskStatus.in_progress.length,
        type: content.content_type === 9 ? 'Flight' : 'Ground',
        content_version: content.version
      });
    });
    this.rows = this.temp = temp;
  }

  getTaskStatus(assessment, lesson_id) {
    const completed = [];
    const not_started = [];
    const in_progress = [];

    assessment.tasks.forEach(task => {
      if (task.task_type.toString() === 'practice') {
        const num_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'practice' && task_attempt.content_id === assessment.id && task_attempt.task_id === task.id).length;
        const progress = num_attempts >= task.required_successful_attempts ? 'Completed' : num_attempts > 0 ? 'In Progress' : 'Not Started';
        const deferments = this.deferments.filter(deferment => deferment.task_id === task.id && deferment.content_id === assessment.id && deferment.lesson_id === lesson_id && deferment.task_type.toString() === task.task_type.toString()).length;

        if (deferments > 0) {
          // Do nothing
        } else if (progress === 'Completed') {
          completed.push(task);
        } else if (progress === 'In Progress') {
          in_progress.push(task);
        } else if (progress === 'Not Started') {
          not_started.push(task);
        }
      } else {
        const num_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.content_id === assessment.id && task_attempt.task_id === task.id && (task_attempt.perfect || task_attempt.success)).length;
        const missed_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.content_id === assessment.id &&  task_attempt.task_id === task.id && task_attempt.failed).length;
        const progress = num_attempts >= task.required_successful_attempts ? 'Completed' : (num_attempts || missed_attempts) > 0 ? 'In Progress' : 'Not Started';
        const deferments = this.deferments.filter(deferment => deferment.task_id === task.id && deferment.content_id === assessment.id && deferment.lesson_id === lesson_id && deferment.task_type.toString() === task.task_type.toString()).length;

        if (deferments > 0) {
          // Do nothing
        } else if (progress === 'Completed') {
          completed.push(task);
        } else if (progress === 'In Progress') {
          in_progress.push(task);
        } else if (progress === 'Not Started') {
          not_started.push(task);
        }
      }
    });

    return {
      completed,
      not_started,
      in_progress
    };
  }

  getLogs(content_id: number) {
    return this.lesson_flight_logs.filter(lesson_flight_log => {
      return lesson_flight_log.content_id === content_id;
    }).length;
  }

  getProgress(content_id, stage_id, lesson_id, course_id) {
    if (!this.userProgress || !this.userProgress.length) {
      return {completed_date: '', status: 'Not Started'};
    }

    this.calculatorService.setProgresses(this.userProgress);

    const item = this.calculatorService.getProgressForContent({id: content_id}, {course_id});
    let completed_date = '';

    if (item) {
      if (item.status.toString() === 'completed') {
        completed_date = item.updated_at;
      }
      return {completed_date, status: this.statusMap[this.statusTypes[item.status]]};
    } else {
      return {completed_date, status: 'Not Started'};
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return !val ||
        d.stage && d.stage.toLowerCase().indexOf(val) !== -1 ||
        d.lesson && d.lesson.toLowerCase().indexOf(val) !== -1 ||
        d.assessment && d.assessment.toLowerCase().indexOf(val) !== -1 ||
        d.course && d.course.toLowerCase().indexOf(val) !== -1 ||
        d.status && d.status.toLowerCase().indexOf(val) !== -1;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  viewAssessmentTask(selectedRow, rowIndex) {
    let nextAssessmentOfType = null;
    let previousAssessmentOfType = null;

    for (let i = rowIndex; i >= 0; i--) {
      if (this.rows[rowIndex].type === selectedRow.type && i !== rowIndex) {
        previousAssessmentOfType = this.rows[i].content;
        break;
      }
    }

    for (let i = rowIndex; i < this.rows.length; i++) {
      if (this.rows[i].type === selectedRow.type && i !== rowIndex) {
        nextAssessmentOfType = this.rows[i].content;
        break;
      }
    }

    const payload = {
      selectedAssessment: selectedRow.content,
      nextAssessmentOfType,
      previousAssessmentOfType,
      content: selectedRow.content
    };

    this.store.dispatch(new AssessmentActions.UpdateSelectedAssessment(payload));
    const url = `${this.router.routerState.snapshot.url}/assessment-entry/${selectedRow.id}`;
    this.router.navigateByUrl(url);
  }

  getNextPreviousOfType(content, index) {
    const currentAssessment = this.rows[index].content;
    const currentLesson = this.rows[index].lesson_id;
    let previousAssessmentOfType = null;
    let prevLesson = null;

    for (let i = index; i >= 0; i--) {
      if (this.rows[i].type === content.type && i !== index) {
        previousAssessmentOfType = this.rows[i].content;
        prevLesson = this.rows[i].lesson_id;
        break;
      }
    }
    return this.getDeferments(currentAssessment, previousAssessmentOfType, currentLesson, prevLesson);
  }

  getDeferments(currentAssessment, previousAssessmentOfType, currentLesson, prevLesson) {
    let prev_count = 0;
    let current_count = 0;

    if (previousAssessmentOfType && prevLesson) {
      prev_count = this.deferments.filter(deferment => deferment.content_id === previousAssessmentOfType.id && deferment.lesson_id === prevLesson).length;
    }

    if (currentAssessment && currentLesson) {
      current_count = this.deferments.filter(deferment => deferment.content_id === currentAssessment.id && deferment.lesson_id === currentLesson).length;
    }

    return prev_count + current_count;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
