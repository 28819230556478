import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import {CourseReport} from '@app/models/course-report';
import {InstructorReport} from '@app/models/instructor-report';
import {UserReport} from '@app/models/user-report';
import {Instructor} from '@app/models/instructor';
import {User} from '@app/models/user/user';
import {Course} from '@app/models/course/course';

@Injectable()
export class ReportsService {

  reportsUrl = 'reports';

  constructor(private http: HttpService) {
  }

  getCoursesReport(): Promise<CourseReport[]> {
    const url = `${this.reportsUrl}/courses`;
    return this.http.get<CourseReport[]>(url)
      .toPromise()
      .catch(ReportsService.handleError);
  }

  getCourseReport(course: Course): Promise<CourseReport> {
    const url = `${this.reportsUrl}/courses/${course.id}`;
    return this.http.get<CourseReport>(url)
      .toPromise()
      .catch(ReportsService.handleError);
  }

  getInstructorsReport(): Promise<InstructorReport[]> {
    const url = `${this.reportsUrl}/instructors`;
    return this.http.get<InstructorReport[]>(url)
      .toPromise()
      .catch(ReportsService.handleError);
  }

  getInstructorReport(instructor: Instructor): Promise<User[]> {
    const url = `${this.reportsUrl}/instructors/${instructor.id}`;
    return this.http.get<User[]>(url)
      .toPromise()
      .catch(ReportsService.handleError);
  }

  getUsersReport(): Promise<UserReport[]> {
    const url = `${this.reportsUrl}/users`;
    return this.http.get<UserReport[]>(url)
      .toPromise()
      .catch(ReportsService.handleError);
  }

  getUserReport(user: User): Promise<UserReport> {
    const url = `${this.reportsUrl}/users/${user.id}`;
    return this.http.get<UserReport>(url)
      .toPromise()
      .catch(ReportsService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
