import { Stage } from './workbook/stage/stage';
import { CourseCategory } from 'app/models/course/course-category';
import { Aircraft } from 'app/models/aircraft/aircraft';
import { CourseFlightMinimum } from 'app/models/course/flight/course-flight-minimum';
import { CourseFlightProgress } from 'app/models/course/flight/course-flight-progress';
import { Badge } from 'app/models/course/course-badge';
import { CourseType } from 'app/models/course/course-type';
import { Workbook } from 'app/models/course/workbook/workbook';
import { Certificate } from '@app/models/course/certificate';
import { User } from '@app/models/user/user';
import {Content} from "@app/models/course/content/content";
import Progress from '@app/models/course/workbook/progress';

export class Course {
  id?: number;
  name?: string;
  image?: string;
  title?: string;
  aircrafts?: Aircraft[];
  course_categories?: CourseCategory[];
  course_category?: CourseCategory;
  course_category_id?: number;
  is_active?: boolean;
  meta_tags?: string[];
  archived?: number;
  desc?: string;
  course_type?: CourseType;
  percentage?: number;
  purchased?: number;
  stages_are_linear?: boolean;
  is_ecommerce_enabled?: boolean;
  list_price?: number;
  completionBadge?: boolean;
  courseBadge?: string;
  badge?: Badge;
  badge_id?: number;
  certificate?: Certificate;
  certificate_id?: number;
  flightMinimums?: CourseFlightMinimum;
  flightAverages?: CourseFlightProgress;
  flightProgress?: CourseFlightProgress;
  workbook?: Workbook;
  version?: string;
  token?: string;
  overview?: string;
  created_by?: string;
  updated_at?: string;
  sf_course_id?: string;
  has_agreement?: boolean;
  thumbnail_image_url?: string;
  can_reenroll?: boolean;
  agreement_body?: string;
  major_version?: number;
  minor_version?: number;
  users?: User[];
  shipping_price?: number;
  tax_code?: number;
  is_new_major?: boolean;
  fulfillment_part_number?: string;
  is_enrolled?: boolean;
  progress?: Progress;
  stages?: Stage[];
  course_overview_video_id?: number;
  course_overview_video?: Content;

  constructor() {
    this.meta_tags = [];
    this.stages_are_linear = false;
    this.is_ecommerce_enabled = false;
    this.is_active = false;
    this.has_agreement = false;
    this.course_type = 0;
    this.can_reenroll = true;
    this.is_enrolled = false;
    this.workbook = new Workbook();
  }
}

export class MyCourse {
  id?: number;
  name?: string;
  image?: string;
  title?: string;
  aircrafts?: Aircraft[];
  course_categories?: CourseCategory[];
  course_category_id?: number;
  is_active?: boolean;
  archived?: number;
  has_agreement?: boolean;
  agreement_body?: string;
  has_agreed?: boolean;
  thumbnail_image_url?: string;
  can_reenroll?: boolean;
  status?: string;
  is_completed?: string;
  user_course_id?: number;
  user_id?: number;
  lessons_total?: number;
  lessons_completed?: number;
  percent_complete?: number;
}
