import { Injectable } from '@angular/core';
import { BaseApiV4Service } from '../base-api-v4.service';
import { TrainingPartner } from '@app/models/training-partner';
import { Observable } from 'rxjs';
import { Connection, ConnectionStatus } from '@app/models/connection';
import { User } from '@app/models/user/user';
import { Instructor } from '@app/models/instructor';
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ConnectionsService extends BaseApiV4Service {
  forStudent(user: User): Observable<Connection[]> {
    const url = this.buildRequestUrl('connections');
    const student_id = user.id.toString();
    return this.http.get<Connection[]>(url, { params: { student_id }});
  }

  getStudents(statuses: ConnectionStatus[] = []):Observable<any>{
    let params: HttpParams = new HttpParams({ fromObject: { 'status[]': statuses } });
    const url = this.buildRequestUrl('ctc_admin/students');
    return this.http.get<User[]>(url, { params });
  }

  getInstructors():Observable<Instructor[]> {
    const url = this.buildRequestUrl('ctc_admin/instructors');
    return this.http.get<any>(url);
  }

  getAssociatedCtcInstructorsForStudent(id):Observable<Instructor[]> {
    const url = this.buildRequestUrl(`ctc_admin/students_ctc_instructors/${id}`);
    return this.http.get<any>(url);
  }

  getAssociatedCtcStudentsForInstructor(id):Observable<Connection[]> {
    const url = this.buildRequestUrl(`ctc_admin/instructors_ctc_students/${id}`);
    return this.http.get<any>(url);
  }

  myConnections(): Observable<Connection[]> {
    const url = this.buildRequestUrl('connections/my_training_partners');
    return this.http.get<Connection[]>(url);
  }

  create(trainingPartner: TrainingPartner): Observable<Connection> {
    const url = this.buildRequestUrl('connections');
    return this.http.post<Connection>(url, { training_partner: trainingPartner });
  }

  bulkAdd(trainingPartners: TrainingPartner[], users: User[]) {
    const url = this.buildRequestUrl('connections/bulk_add');
    const training_partners = trainingPartners.map((tp) => {
      const { type, id } = tp;
      return { type, id };
    });

    let userIds = users.map(u => u.id);

    if(users[0]['student']){
       userIds = users.map(u => u['student'].id)
    }
    return this.http.post<Connection[]>(url, { training_partners, users: userIds });
  }

  disconnect(connection: Connection){
    const url = this.buildRequestUrl(`connections/${connection.id}/disconnect`);
    return this.http.post<Connection>(url, null);
  }

  accept(connection: Connection): Observable<Connection> {
    const url = this.buildRequestUrl(`connections/${connection.id}/accept`);
    return this.http.post<Connection>(url, null);
  }

  decline(connection: Connection): Observable<Connection> {
    const url = this.buildRequestUrl(`connections/${connection.id}/decline`);
    return this.http.post<Connection>(url, null);
  }
}
