import { Component, OnInit, ViewChild } from '@angular/core';
import { Faq } from '@app/models/faq';
import { FaqService } from '@app/services/data-services/faq.service';
import { ActivatedRoute, Router } from '@angular/router';
import { slideInRight } from '@app/animations/animations';
import { AddFaqCategoryDialogComponent } from '../add-faq/add-faq-category-dialog/add-faq-category-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {HttpService} from '@app/services/http-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-faq',
  templateUrl: './manage-faq.component.html',
  styleUrls: ['./manage-faq.component.scss'],
  animations: [
    slideInRight
  ]
})
export class ManageFaqComponent implements OnInit {
  faq_model: Faq = new Faq();
  dialogRef: any;
  imageUrl = '';

  @ViewChild('ngx_editor', { static: true }) editor: any;

  constructor(private faqService: FaqService,
              private activatedRoute: ActivatedRoute,
              private http: HttpService,
              private router: Router,
              private dialog: MatDialog,
              public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.getFaq(params['id']);
      } else {
        this.route();
      }
    });
  }

  getFaq(id: number) {
    this.faqService.getFaq(id)
      .then(faq => this.faq_model = faq);
  }

  route() {
   this.router.navigate(['admin/faq']);
  }

  save() {
    this.faqService.update(this.faq_model)
      .then(() => {
        this.snackbar.open('Updated')
        this.route()
      });
  }

  addFaqCategory() {
    this.openDialog();

    this.dialogRef.afterClosed().subscribe((category) => {
      if (category) {
        this.faq_model.faq_category = category;
      }
    });
  }


  openDialog() {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(AddFaqCategoryDialogComponent, {
      width,
      height
    });
  }


}
