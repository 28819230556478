<div class="content-title-wrapper">
  <h1 mat-dialog-title>Save As New Version</h1>
  <button mat-icon-button mat-dialog-close (click)="close()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="form-padding mat-box-shadow">
  <div class="margin-333">
    <p>Saving a new version will replace the previous version in all its assigned uses.</p>
    <p><strong>Users currently in-progress with this item will not receive the new version update until after completing the previous version.</strong></p>
    <form class="set-version">
      <label for="major_version">
        <span><strong>Major Version</strong></span>
        <input type='text' id="major_version" style='padding:8px; width:10%;' placeholder='Major Version' name="major_version" [(ngModel)]="version['major_version']" />
      </label>
      <label for="minor_version">
        <span><strong>Minor Version</strong></span>
        <input id="minor_version" type='text' style='padding:8px; width:10%;' placeholder='Minor Version' name="minor_version" [(ngModel)]="version['minor_version']" value="{{ version['minor_version'] }}" />
      </label>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="action-wrapper">
    <button mat-raised-button color="primary" type="submit" (click)="save()">Save New Version</button>
  </div>
</mat-dialog-actions>
