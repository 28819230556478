<div class="notifications-wrap">
  <!-- right side topbar -->
  <mat-toolbar class="inbox-toolbar">
    <!-- Left sidebar toggle button -->
    <!-- multiple email select and control -->
    <mat-checkbox (change)="selectToggleAll()" class="inbox-toggle-all mr-1"><small>All</small></mat-checkbox>
    <button mat-icon-button matTooltip="Archive Selected"><mat-icon class="text-muted" (click)="clearSelectedNotifications()">archive</mat-icon></button>
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="openDialog()">
      New Notification
    </button>
  </mat-toolbar>

  <div class="inbox-hold">
    <mat-list class="messages">
      <mat-list-item *ngFor="let notification of notifications" class="message-item app-accordion">
        <div fxLayout="row" fxLayoutAlign="start center" class="message-head">

          <mat-checkbox [(ngModel)]="notification.selected" class="hidden-on-open mail-check"></mat-checkbox>

          <div fxFlex="1 1 auto" fxLayout="row" class="show-on-open mr-1">
            <div fxLayout="column">
              <span class="mat-card-title m-0" >{{notification?.sender?.email}}</span>
              <small class="mat-card-subtitle m-0">{{notification?.created_at | date: "MMM, dd yyyy"}}</small>
            </div>
          </div>
          <div class="app-accordion-container" #accordion appAccordion fxFlex="18 1 auto" fxLayout="row">
            <div class="mr-1 hidden-on-open" [ngStyle]="{minWidth: '120px'}">{{notificationTypeMap[notificationTypes[notification?.notific_type.toString()]]}}</div>
          </div>
          <small class="hidden-on-open">{{notification?.date | date}}</small>
          <button mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">
            <mat-icon class="text-muted">more_vert</mat-icon>
          </button>
          <mat-menu #msgMenu="matMenu">
            <button mat-menu-item (click)="clearMessage(notification)">Archive</button>
          </mat-menu>

          <!-- Single emial top Icons (show on open) -->
          <div class="show-on-open">
            <button mat-icon-button matTooltip="Move to archive"><mat-icon class="text-muted" (click)="clearMessage(notification)">archive</mat-icon></button>
          </div>
        </div>

        <!-- Single text (show on open) -->
        <div class="message-content accordion-content">
          <div class="message-text">
            <p>{{ notification?.body }}</p>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>

</div>

