import {Component, Input, OnInit} from '@angular/core';
import {ShortTextPipe} from '@app/pipes/short-text.pipe';
import { MatDialog } from '@angular/material/dialog';
import {Instructor} from '@app/models/instructor';
import {ReportsService} from '@app/services/data-services/reports.service';
import {AdminReportsUserDialogComponent} from '../admin-reports-user-dialog/admin-reports-user-dialog.component';

@Component({
  selector: 'app-admin-report-dialog-students-template',
  templateUrl: './admin-report-dialog-students-template.component.html',
  styleUrls: ['./admin-report-dialog-students-template.component.scss']
})
export class AdminReportDialogStudentsTemplateComponent implements OnInit {
  @Input() instructor: Instructor;

  rows: any[] = [];
  selected: any[] = [];
  dialogRef: any;
  shortText = new ShortTextPipe();
  columns = [{name: 'Firstname', prop: 'contact.firstname'}, {name: 'Lastname', prop: 'contact.lastname'}, {name: 'Email'}, {name: 'Course Count', prop: 'user_courses'}];

  constructor(public adminReportsDialogCourseDialog: MatDialog, private reportsService: ReportsService) { }

  ngOnInit() {
    if (this.instructor) {
      this.reportsService.getInstructorReport(this.instructor)
        .then(report => this.rows = report);
    }
  }

  onUserSelect(row) {
    // console.log('selectedUser', row)
    const data = {
      selected: 'user',
      data: row.selected[0]
    };

    const width = '80%';
    const height = '80%';

    this.dialogRef = this.adminReportsDialogCourseDialog.open(AdminReportsUserDialogComponent, {
      width,
      height,
      data: data
    });
  }

}
