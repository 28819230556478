export enum UserProgressType {
  content = 0,
  lesson = 1,
  stage = 2,
  course = 3,
  assignment = 4
}

export const UserProgressTypeMap = {
  0: 'Content',
  1: 'Lesson',
  2: 'Stage',
  3: 'Course',
  4: 'Assignment'
};
