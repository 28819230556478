import { Injectable } from '@angular/core';
import {Transcript} from '@app/models/user/transcript';

@Injectable()
export class TranscriptsService {

  transcriptUrl = '/transcript';
  transcripts = [];

  constructor() {
  }

  getAssessment(): Promise<Transcript[]> {
    return Promise.resolve(this.transcripts);
    // return this.http.get(this.transcriptUrl)
    //   .toPromise()
    //   .then((response) => response)
    //   .catch(this.handleError);
  }

  getOneAssessment(id: number): Promise<Transcript> {
    const url = `${this.transcriptUrl}/${id}`;
    const index = this.transcripts.map(transcript => transcript.id).indexOf(id);
    if (index > -1) {
      return Promise.resolve(this.transcripts[index]);
    }
    return Promise.resolve(null);
    // return this.http.get(url)
    //   .toPromise()
    //   .then(response => response as Assessment)
    //   .catch(this.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.transcriptUrl}/${id}`;
    return Promise.resolve();
    // return this.http.delete(url)
    //   .toPromise()
    //   .then(() => null)
    //   .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
