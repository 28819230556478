import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { TaskDeferment } from '@app/models/course/content/task/task-deferment';

@Injectable()
export class TaskDefermentService {
  taskDefermentUrl = 'deferments';

  constructor(private http: HttpService) { }

  getTaskDefermentsOfCourseByUser(user_id: string, course_id: number): Promise<TaskDeferment[]> {
    const url = `${this.taskDefermentUrl}/${user_id}/${course_id}`;
    return this.http.get<TaskDeferment[]>(url)
      .toPromise()
      .catch(TaskDefermentService.handleError);
  }

  getTaskDefermentsForCourseAttempt(user_id: number, course_attempt_id: number): Promise<TaskDeferment[]> {
    const url = `${this.taskDefermentUrl}/user/${user_id}/course_attempt/${course_attempt_id}`;
    return this.http.get<TaskDeferment[]>(url)
      .toPromise()
      .catch(TaskDefermentService.handleError);
  }

  create(taskDeferment: TaskDeferment): Promise<TaskDeferment> {
    const url = `${this.taskDefermentUrl}/sync/add`;
    return this.http
      .post<TaskDeferment[]>(url, JSON.stringify({deferments: [taskDeferment]}))
      .toPromise()
      .then((res) => res[0])
      .catch(TaskDefermentService.handleError);
  }

  delete(taskDeferments: TaskDeferment[]): Promise<boolean> {
    const url = `${this.taskDefermentUrl}/remove_deferments`;
    return this.http
      .post<boolean>(url, JSON.stringify({deferments: taskDeferments}))
      .toPromise()
      .catch(TaskDefermentService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
