import { Stage } from '@app/models/course/workbook/stage/stage';
import { Task } from '@app/models/course/content/task/task';
import { LessonFlightLog } from './lesson-flight-log';
import { LessonFlightMinimum } from '@app/models/course/workbook/lesson/lesson-flight-minimum';
import { Requirement } from '@app/models/course/workbook/requirement';
import {Content} from "@app/models/course/content/content";
import Progress from "@app/models/course/workbook/progress";

export class Lesson {
  id?: number;
  system_name?: string;
  system_desc?: string;
  student_intro_video: {
    title: string;
    hidden: boolean;
    required: boolean;
    content_id: number;
    content: Content;
  }
  instructor_intro_video: {
    title: string;
    hidden: boolean;
    required: boolean;
    content_id: number;
    content: Content;
  }
  required: boolean;
  title?: string;
  overview?: string;
  overview_image_url: string;
  order?: number;
  lesson_type?: number;
  lesson_type_text?: string;
  stage?: Stage;
  updated_at?: string;
  // tasks?: Task[];
  lessonFlightLogs?: LessonFlightLog[];
  contents?: Content[];
  instructor_contents?: Content[];
  requirements?: Requirement;
  flightMinimums?: LessonFlightMinimum;
  is_preview?: boolean;
  force_new_version?: boolean;
  major_version?: number;
  minor_version?: number;
  contents_are_linear?: boolean;
  progress: Progress;

  constructor() {
    // this.requirements = new Requirement();
    this.lessonFlightLogs = [];
    this.contents = new Array<Content>();
    this.contents_are_linear = false;
    this.is_preview = false;
    this.major_version = 1;
    this.minor_version = 0;
  }
}
