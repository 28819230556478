import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { MenuItem, MenuItemName } from '@app/models/menu/menu-item';
import { Editor, Toolbar } from 'ngx-editor';
import {
  ContentType,
  ContentTypeMap,
} from '@app/models/course/content/content-type';
import { I } from '@angular/cdk/keycodes';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss'],
})
export class ContentSectionComponent implements OnInit, OnDestroy {
  @Input() instructor: boolean = false;
  @Output() emitContent: EventEmitter<any> = new EventEmitter();
  @Output() emitRichText: EventEmitter<any> = new EventEmitter();
  @Output() emitClear: EventEmitter<any> = new EventEmitter();
  @Output() emitEnabled: EventEmitter<any> = new EventEmitter();

  form: FormGroup;
  textForm = new FormControl('');
  textFormSubscription = new Subscription();

  menuItemsStudent: MenuItem[] = [
    {
      name: MenuItemName.Remove,
      action: 'button',
      mat_icon: '',
      image_url: 'trash.png',
    },
  ];
  menuItemsInstructor: MenuItem[] = [
    { name: MenuItemName.Enable, action: 'toggle', mat_icon: '' },
  ];
  mediaItemMenu: MenuItem[] = [
    { name: MenuItemName.Edit, action: 'button', mat_icon: '' },
    { name: MenuItemName.Required, action: 'toggle', mat_icon: '' },
    {
      name: MenuItemName.Remove,
      action: 'button',
      mat_icon: '',
      image_url: 'trash.png',
    },
  ];
  menu_style_intro = {
    width: '180px;',
    'max-width': '100%;',
    'overflow-x': 'hidden;',
    'background-color': '#000',
  };
  menu_style_media = {
    width: '296px;',
    'max-width': '100%;',
    'overflow-x': 'hidden;',
    'background-color': '#000',
  };

  section_collapsed: boolean = false;

  editor: Editor;
  toolbar: Toolbar = [['bold', 'italic', 'underline', 'strike', 'link']];
  text: '';

  constructor(private rootFormGroup: FormGroupDirective) {
    this.editor = new Editor();
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;

    this.textFormSubscription.add(
      this.textForm.valueChanges
        .pipe(
          map(change => ({
            value: {
              value: change,
              instructor: this.instructor,
            },
            instructor: this.instructor,
          }))
        )
        .subscribe(text => {
          this.emitRichText.emit(text);
        })
    );
  }

  ngOnDestroy(): void {
    this.textFormSubscription.unsubscribe();
  }

  get toggleValue() {
    return this.form.get('instructor_content_enabled').value;
  }

  addContent() {
    this.emitContent.emit(this.instructor);
  }
  addRichText(e) {
    let obj = { value: e, instructor: this.instructor };
    this.emitRichText.emit(obj);
  }

  menuClick(e) {
    if (e.item.name === MenuItemName.Remove) {
      this.clear();
    }
    if (e.item.name === MenuItemName.Enable) {
      this.emitEnabled.emit(e.$event.checked);
    }
  }

  clear() {
    this.text = '';
    this.emitClear.emit(this.instructor);
  }
}
