import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import {NotificationService} from '@app/services/data-services/notification.service';
import {Notification} from '@app/models/notification/notification';
import {GlobalEventManagerService} from '@app/services/global-event-manager/global-event-manager.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  public notificationsToken$ = new BehaviorSubject<Notification[]>([]);
  public notifications$ = this.notificationsToken$.pipe();


  @ViewChild(MatSidenav, { static: true }) private sideNav: MatSidenav;


  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    if (localStorage.getItem('cirrus-token')) {
      this.notificationService.getMyNotifications().then((notifications) => {
        this.notificationsToken$.next(notifications);
      });
    }
  }

  isNavOver() {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

}
