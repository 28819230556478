import { Component, OnInit, ViewChild } from '@angular/core';
import { TrainingPartner } from '@app/models/training-partner';
import { TrainingPartnersService } from '@app/training-centers/training-partners.service';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { QualificationsService } from '@app/training-centers/qualifications.service';
import { MatSelectChange } from '@angular/material/select';
import { RowAction } from '@app/training-centers/row-action';
import { ConnectionsService } from '@app/training-centers/connections.service';
import { map, switchMap } from 'rxjs/operators';
import { TrainingPartnerRow } from '@app/training-centers/training-partner-row';
import { Connection, ConnectionStatus } from '@app/models/connection';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { GeneralDialogComponent } from '@app/components/shared/general-dialog/general-dialog.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DeviceType } from '@app/models/DeviceType';
import {trigger, state, animate, transition, style } from '@angular/animations';

class ConnectAction implements RowAction {
  constructor(private trainingPartner: TrainingPartner, private connectionsService: ConnectionsService) {}

  public buttonLabel = 'Connect';

  public snackbarText = 'Invite sent';

  public disabled = false;

  actionHandler(): Observable<boolean> {
    return this.connectionsService
      .create(this.trainingPartner)
      .pipe(
        map(() => true)
        );
  }
}

class DisconnectAction implements RowAction {
  constructor(private connection: Connection, private trainingCentersConnectionsService: ConnectionsService) {}

  public buttonLabel = 'Disconnect';

  public snackbarText = 'Disconnected';

  public disabled = false;

  public modalData = {
    width: '80%',
    maxWidth: '500px',
    data: {
      dialogTitle: 'Confirm Training Partner Removal',
      dialogBodyContent: 'Are you sure you want to remove the Training Partner?',
      showCancelButton: true,
      dialogCancelButtonLabel: 'Cancel',
      dialogConfirmButtonLabel: 'Confirm',
    }
  };

  actionHandler(dialog: MatDialog): Observable<boolean> {
    const dialogRef = dialog.open(GeneralDialogComponent, this.modalData);
    const obs = dialogRef.afterClosed();
    obs.subscribe(confirmRemove => {

      if (confirmRemove) {
        return this.deleteConnection().subscribe();
      }
      const observable = new Observable(subscriber => {
        subscriber.next(false);
      });
      return observable;
    });
    return obs;
  }

  deleteConnection() {
    return this.trainingCentersConnectionsService
      .disconnect(this.connection)
      .pipe(
        map(() => true)
        );
  }
}

class PendingAction implements RowAction {
  public buttonLabel = 'Pending';
  public snackbarText = '';
  public disabled = true;
  actionHandler(): Observable<boolean> {
    return of(false);
  }
}

@Component({
  selector: 'app-training-partners',
  templateUrl: './training-partners.component.html',
  styleUrls: ['./training-partners.component.scss'],
  animations: [
    trigger('toggle', [
      state('true', style({ height: '75vh' })),
      state('void', style({ height: '0' })),
      transition(':enter', animate('500ms ease-in-out')),
      transition(':leave', animate('500ms ease-in-out'))
    ])
  ]
})
export class TrainingPartnersComponent implements OnInit {
  public isLoading = true;
  public refreshToken$ = new BehaviorSubject<unknown>(undefined);
  public searchToken$ = new BehaviorSubject<boolean>(false);

  public qualifications$: Observable<string[]>;
  public existingConnections$ = new BehaviorSubject<Connection[]>([]);
  public trainingPartners$ = new BehaviorSubject<TrainingPartner[]>([]);

  public existingConnectionsDataSource = new MatTableDataSource<TrainingPartnerRow>([]);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  private searchFields = ['name', 'admin', 'location', 'airport'];

  public trainingCentersDataSource = new MatTableDataSource<TrainingPartnerRow>([]);

  public qualificationSearchTerm: Record<string, string>;

  public showCompleteList = this.searchToken$.pipe();

  showMap = false;

  toggleMap() {
    this.showMap = !this.showMap;
  }

  deviceType$: Observable<DeviceType>;

  constructor(
    private trainingPartnersService: TrainingPartnersService,
    qualificationsService: QualificationsService,
    private connectionsService: ConnectionsService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.qualifications$ = qualificationsService.getAllQualifications();
    connectionsService
      .myConnections()
      .subscribe(connections => this.existingConnections$.next(connections));
    trainingPartnersService
      .getTrainingPartners()
      .subscribe(partners => this.trainingPartners$.next(partners));
  }

  ngOnInit(): void {
    //Scroll position restoration
    window.scrollTo(0, 0);
    
    this.trainingCentersDataSource.filterPredicate = createFilterPredicate(this.searchFields);

    this.refreshToken$
      .pipe(switchMap(() => forkJoin([this.trainingPartnersService.getTrainingPartners(), this.connectionsService.myConnections()])))
      .subscribe(([trainingPartners, connections]) => {
        this.trainingPartners$.next(trainingPartners);
        this.existingConnections$.next(connections);
      });

    const combined = combineLatest([this.trainingPartners$, this.existingConnections$]);

    combined.subscribe(([trainingPartners, existingConnections]) => {
      this.isLoading = true;

      const confirmedConnections = existingConnections
        .filter(connection => connection.status === ConnectionStatus.Connected);

      this.existingConnectionsDataSource.data = confirmedConnections
        .map(connection =>
          Object.assign(
            {},
            { trainingPartner: connection },
            { action: new DisconnectAction(connection, this.connectionsService) })
      );
      const rows = this.buildRowsForDataSource(trainingPartners, existingConnections);
      this.trainingCentersDataSource.data = rows;

      this.trainingCentersDataSource.paginator = this.paginator;
      this.isLoading = false;
    });

    this.deviceType$ = this.breakpointObserver
      .observe(['(min-width: 600px)', '(min-width: 1025px)'])
      .pipe(
        map(({ breakpoints, matches }) => {
          if (!matches) {
            return DeviceType.Phone;
          }
          if (breakpoints['(min-width: 1025px)']) {
            return DeviceType.Desktop;
          }
          return DeviceType.Tablet;
        })
      );
  }

  buildRowsForDataSource(trainingPartners: TrainingPartner[], connections: Connection[]): TrainingPartnerRow[] {
    return trainingPartners.map((partner) => {
      const connection = connections.find(c =>
          c.connectable.type === partner.type && c.connectable.id === partner.id
      );
      return new TrainingPartnerRow(partner, this.chooseAction(partner, connection));
    });
  }

  updateQualification({ value }: MatSelectChange) {
    this.qualificationSearchTerm = { qualification: value };
  }

  chooseAction(trainingPartner: TrainingPartner, connection: Connection): RowAction {
    let action: RowAction = new ConnectAction(trainingPartner, this.connectionsService);

    if (connection) {
      switch (connection.status) {
        case ConnectionStatus.Pending:
          action = new PendingAction();
          break;
        case ConnectionStatus.Connected:
          action = new DisconnectAction(connection, this.connectionsService);
          break;
      }
    }

    return action;
  }
}

const createFilterPredicate = (searchFields) => (row: TrainingPartnerRow, filter: string): boolean => {
    const trainingCenter = row.trainingPartner;
    let contentMatches = true;
    let qualificationMatches = true;
    const { search, qualification } = JSON.parse(filter);

    if (search) {
      const dataStr = Object.keys(trainingCenter).reduce((currentTerm: string, key: string) => {
          if (searchFields.includes(key)) {
            return currentTerm + (trainingCenter as {[key: string]: any})[key] + '◬';
          }
          return currentTerm;
        }, '').toLowerCase();

      const transformedFilter = search.trim().toLowerCase();

      contentMatches = dataStr.indexOf(transformedFilter) !== -1;
    }

    if (qualification) {
      if (trainingCenter.training_qualifications) {
        qualificationMatches = trainingCenter.training_qualifications.includes(qualification);
      } else {
        qualificationMatches = false;
      }
    }

    return contentMatches && qualificationMatches;
  };
