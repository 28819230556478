import { Injectable } from '@angular/core';
import { BaseApiV4Service } from "./base-api-v4.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

class FeatureResponse {
  enabled: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureService extends BaseApiV4Service {
  isFeatureEnabled(featureName: string): Observable<boolean> {
    const url = this.buildRequestUrl(`features/${featureName}`);
    return this.http.get<FeatureResponse>(url)
       .pipe(map(feature => feature.enabled));
  }
}
