import {Component, OnInit, ViewChild} from '@angular/core';
import { AircraftService } from '../../../../services/data-services/aircraft.service';
import { Router } from '@angular/router';
import { Aircraft } from '../../../../models/aircraft/aircraft';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-aircraft',
  templateUrl: './aircraft.component.html',
  styleUrls: ['./aircraft.component.scss']
})
export class AircraftComponent implements OnInit {

  aircraft: Aircraft;
  rows: Aircraft[] = [];
  temp: Aircraft[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private aircraftService: AircraftService, 
                      private router: Router,
                      public snackbar: MatSnackBar) {
    this.aircraft = new Aircraft();
    this.getAircraft();
  }

  ngOnInit() {}

  getAircraft() {
    this.aircraftService.getAircraft()
        .then(aircraft => this.rows = this.temp = aircraft);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  editAircraft(aircraft) {
    if (aircraft.id) {
      this.router.navigate(['admin/courses/aircraft/manage', aircraft.id] );
    }
  }

  removeAircraft(aircraft) {
    if (confirm('Are you sure you want to remove this item?')) {
      this.aircraftService.delete(aircraft.id)
          .then(() => {
            this.snackbar.open('Deleted')
            this.rows = this.rows.filter((item) => item !== aircraft)
          });
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.make.toLowerCase().indexOf(val) !== -1 || d.model.toLowerCase().indexOf(val) !== -1 || d.avionics.name.toLowerCase().indexOf(val.toLowerCase()) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
}
