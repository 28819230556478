import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  AfterViewChecked,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { slideInRight } from '@app/animations/animations';
import { LessonService } from '@app/services/data-services/lesson.service';
import { Lesson } from '@app/models/course/workbook/lesson/lesson';
import { HttpService } from '@app/services/http-service.service';
import { LessonTypeMap } from '@app/models/course/workbook/lesson/lesson-type-map';
import { DragulaService } from 'ng2-dragula';
import { Observable, of, Subscription } from 'rxjs';
import { Content } from '@app/models/course/content/content';
import {
  ContentType,
  ContentTypeMap,
} from '@app/models/course/content/content-type';
import { VersionDialogComponent } from '@app/components/shared/version-dialog/version-dialog.component';

import * as lessonHelper from '../../../../helpers/workbook.helper';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ContentService } from '@app/services/data-services/content.service';
import { GeneralTableDialogComponent } from '@app/components/shared/general-table-dialog/general-table-dialog.component';
import * as autoScroll from 'dom-autoscroller';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { startWith, switchMap } from 'rxjs/operators';
import { LocationStrategy } from '@angular/common';
import { ValidationService } from '@app/validation.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TypeModalComponent } from '@app/type-modal/type-modal.component';
import { MenuItem, MenuItemName } from '@app/models/menu/menu-item';
import { LessonV4Service } from '@app/lesson-v4.service';
import { FeatureService } from '@app/feature.service';
import { MatTableConfig } from '@app/models/mat-table/mat-table';

@Component({
  selector: 'app-lesson-editor',
  templateUrl: './lesson-editor.component.html',
  styleUrls: [
    './lesson-editor.component.scss',
    '../../course-manager/courses/course-editor/workbook-editor.scss',
    './drag-drop.scss',
  ],
  animations: [slideInRight],
})
export class LessonEditorComponent implements OnInit, OnDestroy {
  @ViewChild('editor', { static: true }) editor: ElementRef;
  dialogRef: any;

  private checkedDirtySubscription: Subscription;

  lesson: Lesson = new Lesson();
  introContent: any = null;
  subscription: any;
  forceNewVersion: boolean = false;
  setNewVersion: boolean = false;
  editing: boolean = false;
  drakes: any[] = ['content-container'];
  lessonTypeMap = LessonTypeMap;
  contentTypeMap = ContentTypeMap;
  contentType = ContentType;
  drag_subs = new Subscription();
  contentDialog: any;
  versionDialog: any;
  scroll: any;
  isDragging: boolean = false;
  student_intro_video_title: string;
  instructor_intro_video_title: string;

  isPPlEnabled$ = this.featureService.isFeatureEnabled('ppl');
  initialContentState = [];

  isLoading: boolean;
  saveDisabled: boolean;

  lessonForm = this.formBuilder.group({
    id: [''],
    title: ['', Validators.required],
    subtitle: [''],
    minimum_ground_hours: ['', Validators.pattern('^[0-9]+(\.[05])?$')],
    minimum_flight_hours: ['', Validators.pattern('^[0-9]+(\.[05])?$')],
    minimum_landings: [''],
    minimum_sim_hours: ['', Validators.pattern('^[0-9]+(\.[05])?$')],
    completion_time: [''],
    student_intro_video: this.formBuilder.group({
      content_id: '',
      title: '',
      required: false,
      hidden: false,
    }),
    instructor_intro_video: this.formBuilder.group({
      content_id: '',
      title: '',
      required: false,
      hidden: false,
    }),

    mobile_hero_image_url: [''],
    desktop_hero_image_url: [''],
    thumbnail_image_url: [''],

    contents: this.formBuilder.array([]),
    instructor_contents: this.formBuilder.array([]),
    instructor_overview: [''],
    instructor_content_enabled: [false],
    overview: [''],
    overview_image_url: [''],
    lesson_type: ['', ValidationService.selectValidator],
    system_name: ['', Validators.required],
    system_desc: [''],
    is_archived: [false],
    is_preview: [false],
    contents_are_linear: [false],
    major_version: [1],
    minor_version: [0],
    updated_at: [''],
    order: [''],
  });

  showAssessmentTab$ = this.lessonForm.get('lesson_type').valueChanges.pipe(
    startWith(false),
    switchMap(lesson_type => of(parseInt(lesson_type, 10))),
    switchMap(lesson_type => of([1, 2].includes(lesson_type)))
  );
  currentIndex: number = 0;
  viewIntroVideoBoxStudent: boolean = false;
  viewIntroVideoBoxInstructor: boolean = false;
  tabTitle: string = 'General';
  @ViewChild('tabs', { static: false }) tabs;
  selectedIndex: number = 0;
  overview_image_url: string;
  sectionItemsForStudentIntroMenu: MenuItem[] = [
    { name: MenuItemName.Hidden, action: 'toggle', mat_icon: '' },
    {
      name: MenuItemName.Remove,
      action: 'button',
      mat_icon: '',
      image_url: 'trash.png',
    },
  ];
  sectionItemsForInstructorIntroMenu: MenuItem[] = [
    { name: MenuItemName.Hidden, action: 'toggle', mat_icon: '' },
    {
      name: MenuItemName.Remove,
      action: 'button',
      mat_icon: '',
      image_url: 'trash.png',
    },
  ];

  menu_style_intro = {
    width: '180px;',
    'max-width': '100%;',
    'overflow-x': 'hidden;',
    'background-color': '#000',
  };

  constructor(
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private lessonService: LessonService,
    private dragulaService: DragulaService,
    private http: HttpService,
    private formBuilder: FormBuilder,
    private location: LocationStrategy,
    private lessonV4Service: LessonV4Service,
    private featureService: FeatureService
  ) {
    this.route.params.subscribe(params => {
      if (params['id']) {
        const id = params['id'];
        if (id) {
          this.editing = true;
          this.lessonV4Service.getLesson(id).subscribe(lesson => {
            this.lesson = lesson;

            const { student_intro_video, instructor_intro_video } = lesson;

            if (lesson.contents.length) {
              lesson.contents.forEach(c => {
                let content = {
                  id: c.id,
                  title: c.title,
                  desc: c.desc,
                  order: c.order,
                  content_type: c.content_type,
                };
                this.initialContentState.push(content);
                this.studentContentArray().push(this.newContent(content));
              });
            }
            if (lesson.instructor_contents.length) {
              lesson.instructor_contents.forEach(c => {
                let content = {
                  id: c.id,
                  title: c.title,
                  desc: c.desc,
                  order: c.order,
                  content_type: c.content_type,
                };
                this.instructorContentArray().push(this.newContent(content));
              });
            }

            const lessonPatch = {
              ...lesson,
              student_intro_video:
                student_intro_video !== null
                  ? {
                    content_id: student_intro_video.content.id,
                    title: student_intro_video.title,
                    required: student_intro_video.required,
                    hidden: student_intro_video.hidden,
                  }
                  : null,
              instructor_intro_video:
                instructor_intro_video !== null
                  ? {
                    content_id: instructor_intro_video.content.id,
                    title: instructor_intro_video.title,
                    required: instructor_intro_video.required,
                    hidden: instructor_intro_video.hidden,
                  }
                  : null,
            };
            this.lessonForm.patchValue(lessonPatch);

            if (instructor_intro_video !== null) {
              this.instructor_intro_video_title =
                instructor_intro_video.content.title;
              this.viewIntroVideoBoxInstructor =
                instructor_intro_video !== null ? true : false;
            }

            if (student_intro_video !== null) {
              this.student_intro_video_title =
                student_intro_video.content.title;
              this.viewIntroVideoBoxStudent =
                student_intro_video !== null ? true : false;
            }
            this.overview_image_url = lesson.overview_image_url;
          });
        }
      }
    });

    this.drag_subs.add(
      dragulaService.drag().subscribe(({ source }) => {
        this.onDrag(source);
      })
    );

    this.drag_subs.add(
      this.dragulaService.dragend().subscribe(({ el }) => {
        this.onDragEnd(el);
      })
    );

    this.drag_subs.add(
      this.dragulaService.drop().subscribe(() => {
        this.forceNewVersion = this.editing
          ? lessonHelper.forceVersionChange(
            this.studentContentArray().value,
            this.lesson.contents
          )
          : false;
      })
    );
  }

  get displayMinimumGround() {
    return this.currentLessonType != 0;
  }

  get displayMinimumFlightOrLandings() {
    return this.currentLessonType == 2;
  }

  get displayMinimumSimulator() {
    return this.currentLessonType == 3;
  }

  studentContentArray(): FormArray {
    return this.lessonForm.get('contents') as FormArray;
  }

  instructorContentArray(): FormArray {
    return this.lessonForm.get('instructor_contents') as FormArray;
  }

  ngOnInit() {
    this.scroll = autoScroll([this.editor.nativeElement], {
      margin: 30,
      maxSpeed: 25,
      scrollWhenOutside: true,
      autoScroll: function () {
        return this.down;
      },
    });
    this.checkIfContentValueHasChanged();
  }

  get formControl(): { [key: string]: any } {
    return this.lessonForm.controls;
  }

  get currentLessonType() {
    return this.lessonForm.get('lesson_type').value;
  }

  get currentDesktopUrl() {
    return this.lessonForm.get('desktop_hero_image_url').value;
  }

  get currentThumbnailUrl() {
    return this.lessonForm.get('thumbnail_image_url').value;
  }

  get currentMobileUrl() {
    return this.lessonForm.get('mobile_hero_image_url').value;
  }

  get studentIntroVideoHidden() {
    return this.lessonForm.get('student_intro_video').value.hidden;
  }

  get instructorIntroVideoHidden() {
    return this.lessonForm.get('instructor_intro_video').value.hidden;
  }

  private onDrag(args: any): void {
    this.isDragging = true;
  }

  private onDragEnd(args?: any): void {
    this.isDragging = false;
  }

  checkIfContentValueHasChanged() {
    this.checkedDirtySubscription = this.lessonForm
      .get('contents')
      .valueChanges.subscribe(x => {
        if (JSON.stringify(this.initialContentState) === JSON.stringify(x)) {
          this.setNewVersion = false;
          this.forceNewVersion = false;
          return;
        }
        this.setNewVersion = true;
        this.forceNewVersion = true;
      });
  }

  newContent(obj) {
    const { id, title, desc, content_type, order } = obj;
    return this.formBuilder.group({
      id,
      title,
      desc,
      order,
      content_type,
    });
  }

  setUrl($event, type: string) {
    this.lessonForm.patchValue({ [type]: $event });
  }

  upload() {
    if (this.editing && (this.forceNewVersion || this.setNewVersion)) {
      this.setLessonVersion();
    } else {
      this.saveLesson();
    }
  }

  saveLesson() {
    this.saveDisabled = true
    if (this.editing) {
      this.lessonV4Service
        .update(this.lessonForm.value)
        .subscribe(() => {
          this.saveDisabled = false
          this.confirmMessage()
        }
        );
    } else {
      this.lessonV4Service
        .create(this.lessonForm.value)
        .subscribe(() => {
          this.saveDisabled = false
          this.confirmMessage()
        });
    }
  }

  setOverviewImage(e) {
    this.lessonForm.patchValue({ overview_image_url: e });
  }

  setInstructorEnabled(e) {
    this.lessonForm.patchValue({ instructor_content_enabled: e });
  }

  addMedia() {
    this.addContent('media');
  }

  studentIntroVideoMenuSelection(e) {
    const { item, $event } = e;
    item.name === MenuItemName.Remove
      ? this.optionsRemove('student')
      : this.videoSelectionHiddenToggle('student_intro_video', $event.checked);
  }

  instructorIntroVideoMenuSelection(e) {
    const { item, $event } = e;
    item.name === MenuItemName.Remove
      ? this.optionsRemove('instructor')
      : this.videoSelectionHiddenToggle(
        'instructor_intro_video',
        $event.checked
      );
  }

  videoSelectionHiddenToggle(type, bool) {
    this.lessonForm.patchValue({ [type]: { hidden: bool } });
  }

  sectionInstructorOnlyToggle(type, bool) {
    this.lessonForm.patchValue({ [type]: { hidden: bool } });
  }

  emittedContent(e) {
    let result = e ? 'instructor_contents' : 'contents';
    this.addContent(result);
  }

  emittedText(e) {
    const { value, instructor } = e;
    const type = instructor ? 'instructor_overview' : 'overview';
    this.lessonForm.patchValue({ [type]: value.value });
  }

  clearContentSection(e) {
    if (e) {
      this.lessonForm.reset({
        instructor_overview: '',
        instructor_contents: [],
      });
    } else {
      this.lessonForm.reset({ overview: '', contents: [] });
      this.lessonForm.controls;
    }
  }

  addContent(type: string) {
    this.isLoading = true;

    let tableConfig: MatTableConfig = {
      dialogTitle: 'Add Media',
      customConfirmButton: false,
      showCancelButton: false,
      filterCategory: 'content_type',
      selectionType:
        type === 'student_intro' || type === 'instructor_intro'
          ? SelectionType.single
          : SelectionType.multiClick,
      typeMap: this.contentTypeMap,
      hasSidebar: true,
      columns: [
        {
          name: 'Select',
          mat_col_name: 'select',
          nested_name: '',
          type_map: false,
        },
        {
          name: 'Title',
          mat_col_name: 'title',
          nested_name: '',
          type_map: false,
        },
        {
          name: 'Type',
          mat_col_name: 'content_type',
          nested_name: '',
          type_map: true,
        },
        {
          name: 'Description',
          mat_col_name: 'desc',
          nested_name: '',
          type_map: false,
        },
      ],
      dataColumns: ['select', 'title', 'content_type', 'desc'],
      rows: [],
    };

    let httpService = new ContentService(this.http);
    const route =
      type === 'student_intro' || type === 'instructor_intro'
        ? httpService.getVideoContent()
        : httpService.getContent();

    route.subscribe(rows => {
      this.isLoading = false;
      tableConfig.rows = rows;
      this.contentDialog = this.dialog.open(GeneralTableDialogComponent, {
        width: '56vw',
        data: tableConfig,
      });

      this.contentDialog.afterClosed().subscribe((contents: Content[]) => {
        if (!contents) {
          return;
        }
        if (type === 'student_intro') {
          this.student_intro_video_title = contents[0].title;
          this.lessonForm.patchValue({
            student_intro_video: { content_id: contents[0].id },
          });
          return;
        }
        if (type === 'instructor_intro') {
          this.instructor_intro_video_title = contents[0].title;
          this.lessonForm.patchValue({
            instructor_intro_video: { content_id: contents[0].id },
          });
          return;
        }
        contents.forEach((content, i) => {
          const { id, title, desc, content_type } = content;
          let payload = { id, title, desc, content_type, order: i };
          let newContent = this.newContent(payload);
          type === 'contents'
            ? this.studentContentArray().push(newContent)
            : this.instructorContentArray().push(newContent);
        });
        this.orderContent(type);
      });
    });
  }

  orderContent(type) {
    const arrayForm =
      type === 'contents'
        ? this.studentContentArray()
        : this.instructorContentArray();
    arrayForm.controls.forEach((content, i) => {
      content.patchValue({ order: i + 1 });
    });
  }

  toggleIntroVideoBox(type: string) {
    if (type === 'student') {
      this.viewIntroVideoBoxStudent = !this.viewIntroVideoBoxStudent;
    } else {
      this.viewIntroVideoBoxInstructor = !this.viewIntroVideoBoxInstructor;
    }
  }

  openLessonVersionDialog() {
    this.versionDialog = this.dialog.open(VersionDialogComponent, {
      width: '40vw',
      position: {
        top: '80px',
      },
      data: {
        majorVersion: this.lesson.major_version,
        minorVersion: this.lesson.minor_version,
      },
    });
  }
  setLessonVersion() {
    this.openLessonVersionDialog();
    this.versionDialog.afterClosed().subscribe(version => {
      if (version) {
        this.lessonForm.patchValue({ major_version: version.major_version });
        this.lessonForm.patchValue({ minor_version: version.minor_version });
        this.saveLesson();
      }
    });
  }

  confirmMessage() {
    let $this = this;
    const snackBar = this.snackBar.open(
      'Lesson ' + $this.editing ? 'updated' : 'created' + '.'
    );


    this.subscription = snackBar.afterOpened().subscribe(() => {
      this.router.navigate(['/admin/lessons']);
    });
  }

  cancel() {
    this.router.navigate(['/admin/lessons']);
  }

  myTabSelectedTabChange($event) {
    this.selectedIndex = $event.index;
    this.tabTitle = $event.tab.textLabel;
  }

  nextTab() {
    this.selectedIndex = this.selectedIndex + 1;
    this.tabs.realignInkBar();
  }

  prevTab() {
    this.selectedIndex = this.selectedIndex - 1;
    this.tabs.realignInkBar();
  }

  back() {
    this.location.back();
  }

  optionsRemove(type: string) {
    if (type === 'student') {
      this.student_intro_video_title = '';

      this.lessonForm.patchValue({
        student_intro_video: {
          content_id: '',
          title: '',
          required: false,
          hidden: false,
        },
      });
    } else {
      this.instructor_intro_video_title = '';
      this.lessonForm.patchValue({
        instructor_intro_video: {
          content_id: '',
          title: '',
          required: false,
          hidden: false,
        },
      });
    }
  }

  optionsReplace(type) {
    this.addContent(type);
  }

  ngOnDestroy() {
    this.checkedDirtySubscription.unsubscribe();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.drag_subs) {
      this.drag_subs.unsubscribe();
    }
    for (const drake in this.drakes) {
      if (this.dragulaService.find(drake)) {
        this.dragulaService.destroy(drake);
      }
    }
    this.scroll.destroy();
  }
}
