import { Injectable } from '@angular/core';
import { Assignment } from '@app/models/course/assignment';
import { HttpService } from '@app/services/http-service.service';
import { UserAssignment } from '@app/models/course/user-assignment';

@Injectable()
export class AssignmentService {

  assignmentUrl = 'assignments';

  constructor(private http: HttpService) {}

  getStudentAssignments(id: number): Promise<UserAssignment[]> {
    const url = `${this.assignmentUrl}/student/${id}`;
    return this.http.get<UserAssignment[]>(url)
      .toPromise()
      .catch(AssignmentService.handleError);
  }

  getInstructorAssignments(): Promise<Assignment[]> {
    const url = `${this.assignmentUrl}/instructor`;
    return this.http.get<Assignment[]>(url)
      .toPromise()
      .catch(AssignmentService.handleError);
  }

  getOneAssignment(id: number): Promise<Assignment> {
    const url = `${this.assignmentUrl}/${id}`;

    return this.http.get<Assignment>(url)
      .toPromise()
      .catch(AssignmentService.handleError);
  }

  delete(id: number): Promise<void> {
    return this.http.delete(this.assignmentUrl)
      .toPromise()
      .then(() => null)
      .catch(AssignmentService.handleError);
  }

  create(assignment: Assignment): Promise<Assignment> {
    return this.http
      .post(this.assignmentUrl, JSON.stringify(assignment))
      .toPromise()
      .then(res => res)
      .catch(AssignmentService.handleError);
  }

  update(assignment: Assignment): Promise<any> {
    const url = `${this.assignmentUrl}/${assignment.id}`;
    return this.http
      .put(url, JSON.stringify(assignment))
      .toPromise()
      .then(() => assignment)
      .catch(AssignmentService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
