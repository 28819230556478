<div id="instructor-connect-wrapper">
  <div class="instructor-connect-title-wrapper">
    <h1 mat-dialog-title>{{displayTitle()}}</h1>
  </div>
  <mat-dialog-content>
    <div class="full-width pb-1">
      <input type='text' style='padding:8px;margin:15px auto;width:30%;' placeholder='Filter Instructors'
        (keyup)='updateFilter($event)' />
    </div>
    <table mat-table matSort [dataSource]="rows" class="mat-elevation-z3">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let user">{{ user.user?.contact?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let user">{{ user.user?.email }}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let user; columns: displayedColumns" (click)="onSelect(user)"
        [ngClass]="{'selected-row': selectedInstructor === user}"></tr>
    </table>

  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-wrapper">
      <button mat-button type="button" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!selected.length"
        *ngIf="!remove && !connections">Add</button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!selected.length"
        *ngIf="remove || connections">Remove</button>
    </div>
  </mat-dialog-actions>
</div>