import { Injectable } from '@angular/core';
import { BaseApiV5Service } from './base-api-v5.service';
import { Observable } from 'rxjs';
import { Exam } from '@app/models/course/content/quiz/exam';

@Injectable({
  providedIn: 'root',
})
export class ExamService extends BaseApiV5Service {
  getExams(): Observable<Exam[]> {
    const url = this.buildRequestUrl('exams');
    return this.http.get<Exam[]>(url);
  }
}
