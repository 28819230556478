import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-manager-layout',
  templateUrl: './student-manager-layout.component.html',
  styleUrls: ['./student-manager-layout.component.scss']
})
export class StudentManagerLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
