import {Component, OnInit} from '@angular/core';
import {slideInRight} from '@app/animations/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {UserCertificateService} from '@app/services/data-services/user-certificate.service';

@Component({
  selector: 'app-transcript-edit-cert',
  templateUrl: './transcript-edit-cert.component.html',
  styleUrls: ['./transcript-edit-cert.component.scss'],
  animations: [
    slideInRight
  ]
})
export class TranscriptEditCertComponent implements OnInit {
  user_id: number;
  user_certificate_id: number;
  dt1;
  rows: any[] = [];
  showTable = false;

  constructor(private router: Router,
              private userCertificateService: UserCertificateService,
              private activatedRoute: ActivatedRoute) {

    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    dt.setDate(dt.getDate());
    this.dt1 = dt;

  }

  ngOnInit() {
    const sub = this.activatedRoute.params.subscribe(params => {
      if (params['user_certificate_id']) {
        this.user_certificate_id = params['user_certificate_id'];
      }
      if (params['user_id']) {
        this.user_id = params['user_id'];
      }
      // console.log('NgOnInit Cert Id =', this.user_certificate_id);
      // console.log('NgOnInit User Id =', this.user_id);
    });

    sub.unsubscribe();
  }

  clr() {
    this.showTable = false;
    this.rows = [];
  }

  updateCompletionDate() {
    // console.log('Trans Edit Cert TS UCI =', this.user_certificate_id);
    this.userCertificateService.updateCompletionDate(this.user_certificate_id, this.dt1)
      .then(() => {
        this.cancel();
      });
  }

  cancel() {
    const url = `admin/transcripts/${this.user_id}`;

    this.router.navigate([url]);
  }

}
