import { Injectable } from '@angular/core';

@Injectable()
export class TempDataServiceService {

  constructor() { }

  data: any[] = [
      {
        id: 0,
        name: 'Course Name',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'asdf sdf Lorem Ipsum',
        model: 'DDDs FCirrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'Avidyne',
        percentage: 70,
        purchased: 1,
        archived: 0,
        type: 'ddTransition',
        category: 'New Pilots',
        price: 255,
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            complete: 'true',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                complete: 'true',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Flight Assessment',
                  type: 'flight-assessment',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'flight-assessment',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Scorm Example',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'interactive',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
            }
          ]
      },
      {
        id: 1,
        name: 'Course Name 2',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'aasd asd Lorem Ipsum',
        model: 'DDS DFdCirrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'as aaa Avidyne11',
        percentage: '',
        purchased: 0,
        archived: 0,
        price: 250,
        type: 'ffd ssd ddTranddsition',
        category: 'New Pilots',
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
          }
        ]
      },
      {
        id: 2,
        name: 'Course Name 3',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'Lorem Ipsum sfsd sdf ss',
        model: 'DDfs DFdCirrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'wwe Avidyne11',
        percentage: 55,
        purchased: 1,
        archived: 0,
        price: 550,
        type: 'ddd ee assd dTranddsition',
        category: 'New Pilots',
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
          }
        ]
      },
      {
        id: 3,
        name: 'Course Name 4',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'Lorem Ipsum sdf sssd dd',
        model: 'FFD DFdCirrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'sse Avidyne11',
        percentage: '',
        purchased: 0,
        archived: 0,
        price: 450,
        type: 'ssd ddTranddsition aa',
        category: 'New Pilots',
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
          }
        ]
      },
      {
        id: 4,
        name: 'Course Name 5',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'Loremffd sdf sdf Ipsum',
        model: 'DFdCidds rrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'bbs Avidyne11',
        percentage: '',
        purchased: 0,
        archived: 0,
        price: 545,
        type: 'aa ddTranddsition dd',
        category: 'New Pilots',
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
          }
        ]
      },
      {
        id: 5,
        name: 'Course Name 6',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'Lorem ffds dds Ipsum',
        model: 'ssd DFdCirrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'bbs Avidyne11',
        percentage: 77,
        purchased: 1,
        archived: 0,
        price: 225,
        type: 'aa ddTranddsition dd',
        category: 'New Pilots',
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
          }
        ]
      },
      {
        id: 6,
        name: 'Course Name 7',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'Loremss ddfs ssd Ipsum',
        model: 'asddf DFdCirrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'bbs Avidyne11',
        percentage: 55,
        purchased: 1,
        archived: 0,
        price: 335,
        type: 'aa ddTranddsition dd',
        category: 'New Pilots',
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
          }
        ]
      },
      {
        id: 7,
        name: 'Course Name 8',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'Lorem Ipsu dfd ssdm sdd',
        model: 'DFdCirrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'bbs Avidyne11',
        percentage: '',
        purchased: 0,
        archived: 0,
        price: 400,
        type: 'aa ddTranddsition dd',
        category: 'New Pilots',
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
          }
        ]
      },
      {
        id: 8,
        name: 'Course Name 9',
        instructor: 'John Snow',
        credentials: 'Wolf Aviation',
        image: 'assets/images/img3.jpeg',
        title: 'Lorem Ipsum asddw dds',
        model: 'DFdCirrus Aircraft SR Models',
        createdby: 'By Cirrus Approach',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus accusantium, aut cupiditate dolorum, eos eum fuga illo iure iusto necessitatibus numquam obcaecati omnis optio reprehenderit ut. Facilis minima nesciunt similique.',
        avionics: 'bbs Avidyne11',
        percentage: 22,
        purchased: 1,
        archived: 1,
        price: 350,
        type: 'aa ddTranddsition dd',
        category: 'New Pilots',
        minimums: {
          ground: 5,
          flight: 5,
          sim: 'NA',
          x_c: 4,
          landings: 5,
          instrument: 5,
          instrumentApproaches: 5
        },
        averages: {
          ground: 8,
          flight: 10,
          sim: 'NA',
          x_c: 10,
          landings: 20,
          instrument: 5,
          instrumentApproaches: 5
        },
        progress: {
          ground: 5,
          flight: 3,
          sim: 'NA',
          x_c: 4,
          landings: 4,
          instrument: 5,
          instrumentApproaches: 5
        },
        stages: [
          {
            id: 0,
            name: 'Stage 1',
            order: 1,
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true

                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 90,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 1,
            order: 2,
            name: 'Stage 2',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Content Item 3',
                  type: 'scorm',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 95,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 4,
                  order: 5,
                  title: 'Content Item 5',
                  type: 'scorm',
                  status: 0,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'scorm',
                  myBookshelf: true
                }]
              }
            ]
          }, {
            id: 2,
            order: 3,
            name: 'Stage 3',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            lessons: [
              {
                id: 0,
                order: 1,
                title: 'Lesson 1',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 1,
                order: 2,
                title: 'Lesson 2',
                type: 'Flight',
                minimums: {
                  ground: 1,
                  flight: 1.5,
                  sim: 'NA',
                  x_c: 1,
                  landings: 1
                },
                progress: {
                  ground: 0,
                  flight: 0,
                  sim: 0,
                  x_c: 1,
                  landings: 1
                },
                tasks: [
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Crosswind Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 1,
                    negAttempts: 0,
                    required: 1,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Engine Start',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 2,
                    negAttempts: 1,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Takeoff',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 1,
                    required: 2,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Normal Landing',
                    category: 'Normal Procedures',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 3,
                    status: 1,
                    standards: [
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      },
                      {
                        id: 0,
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit'
                      }
                    ]
                  },
                  {
                    id: 0,
                    task: 'Steep Turn',
                    category: 'Maneuvers',
                    score: 3,
                    posAttempts: 3,
                    negAttempts: 0,
                    required: 2,
                    status: 1,
                    standards: []
                  }
                ],
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 2,
                order: 3,
                title: 'Lesson 3',
                type: 'Ground',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: false
                }, {
                  id: 2,
                  order: 3,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 88,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }]
              },
              {
                id: 3,
                order: 4,
                title: 'Lesson 4',
                type: 'Self Study',
                desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
                content: [{
                  id: 0,
                  order: 1,
                  title: 'Content Item 1',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 1,
                  order: 2,
                  title: 'Content Item 2',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 3,
                  order: 4,
                  title: 'Content Item 4',
                  type: 'video',
                  status: 1,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'video',
                  myBookshelf: true
                }, {
                  id: 4,
                  order: 5,
                  title: 'Take the Quiz',
                  type: 'quiz',
                  status: 0,
                  score: 91,
                  subtitle: 'Subtitle lorem ipsum',
                  img: 'assets/images/courseimage.png',
                  category: 'quiz',
                  myBookshelf: false
                }]
              }]
          }
        ]
      }
    ];

  getCourses(): Promise<any> {
    return Promise.resolve(this.data);
  }

  getCourse(id: number): Promise<any> {
    return Promise.resolve(
      this.data.filter(course => course.id === id)
        .reduce(course => course)
    );
  }

  getTasks(id: number): Promise<any> {
    return Promise.resolve(
      this.data.map(course => course.tasks)
    );
  }

  getStages(id: number): Promise<any> {
    return Promise.resolve(
      this.data.filter(course => course.id === id)
                .map(course => course.stages)
    );
  }

  getBinder(): Promise<any> {
    return Promise.resolve(
      this.data.filter(course => course.purchased)
    );
  }

}
