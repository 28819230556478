import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth.service';
import { ReturnPathService } from '@app/return-path.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { filter, switchMap } from 'rxjs/operators';

@Component({ template: '' })
export class AuthenticatedComponent implements AfterViewInit {
  private isAuthenticated$ = this.oidsSecurityService.isAuthenticated$;

  private returnTo = this.returnPathService.returnPath || '/recent-activity';

  constructor(
    private oidsSecurityService: OidcSecurityService,
    private authService: AuthService,
    private router: Router,
    private returnPathService: ReturnPathService
  ) {}

  ngAfterViewInit(): void {
    this.isAuthenticated$
      .pipe(
        filter((isAuthenticated: boolean) => isAuthenticated),
        switchMap(() => this.authService.postAccessToken())
      )
      .subscribe(user => {
        localStorage.setItem('cirrus-user', JSON.stringify(user));
        localStorage.setItem('cirrus-role', JSON.stringify(user.role));
        localStorage.setItem('cirrus-token', user.authentication_token);
        this.router.navigate([this.returnTo]);
      });
  }
}
