import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';

export function configureAuth(
  oidcConfigService: OidcConfigService
): () => Promise<any> {
  return () =>
    oidcConfigService.withConfig({
      stsServer: environment.stsServer,
      redirectUrl: environment.redirectUrl,
      postLogoutRedirectUri: environment.postLogoutRedirectUri,
      clientId: environment.clientId,
      scope: environment.scope,
      responseType: environment.responseType,
      silentRenew: false,
      renewTimeBeforeTokenExpiresInSeconds: 10,
    });
}

@NgModule({
  imports: [AuthModule.forRoot()],
  exports: [AuthModule],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
  ],
})
export class AuthConfigModule {}
