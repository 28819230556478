import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AircraftService } from '@app/services/data-services/aircraft.service';
import { Aircraft } from '@app/models/aircraft/aircraft';
import { slideInRight } from '@app/animations/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AvionicsService } from '@app/services/data-services/avionics.service';
import { Avionics } from '@app/models/aircraft/avionics';
import { MatDialog } from '@angular/material/dialog';
import {AddAvionicsDialogComponent} from '../add-aircraft/add-avionics-dialog/add-avionics-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-aircraft',
  templateUrl: './manage-aircraft.component.html',
  styleUrls: ['./manage-aircraft.component.scss'],
  animations: [
    slideInRight
  ]
})
export class ManageAircraftComponent implements OnInit, OnDestroy {
  aircraft: Aircraft;
  subs: any[] = [];
  aircraftForm: FormGroup;
  avionics: Avionics[];
  dialogRef: any;

  constructor(private activatedRoute: ActivatedRoute,
              private aircraftService: AircraftService,
              private router: Router,
              private fb: FormBuilder,
              private avionicsService: AvionicsService,
              public dialog: MatDialog,
              public snackbar: MatSnackBar) {
    this.aircraft = new Aircraft();
  }

  ngOnInit() {
    this.subs.push(this.activatedRoute.params.subscribe((params) => {
      this.getAircraft(parseInt(params['id']));
    }));
  }

  getAircraft(id: number) {
    this.aircraftService.getOneAircraft(id)
      .then((aircraft) => {
        if (aircraft) {
          this.aircraft = aircraft;
          return;
        }
        this.route();
      });
  }

  updateAircraft() {
    this.aircraftService.update(this.aircraft)
      .then(() => {
        this.snackbar.open('Updated')
        this.route()
      });
  }

  cancel() {
    this.route();
  }

  route() {
    this.router.navigate(['admin/courses/aircraft']);
  }

  openDialog(content) {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(AddAvionicsDialogComponent, {
      width,
      height,
      data: content
    });
  }

  addAvionics() {
    this.openDialog(this.aircraft);

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.aircraft.avionics = data;
      }
    });
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
