import {Component, OnInit, ViewChild} from '@angular/core';
import {slideInRight} from '@app/animations/animations';
import {Router} from '@angular/router';
import {BookshelfContentType, BookshelfContentTypeMap, ContentBookshelf} from '@app/models/content-bookshelf';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {Content} from '@app/models/course/content/content';
import {FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {HttpService} from '@app/services/http-service.service';
import {BookshelfService} from '@app/services/data-services/bookshelf.service';
import {AddContentDialogComponent} from '../../../admin/content-manager/add-content/add-content-dialog/add-content-dialog.component';
import {Task} from '@app/models/course/content/task/task';
import {DragulaService} from 'ng2-dragula';
import {QuizService} from '@app/services/data-services/quiz.service';
import {ContentService} from '@app/services/data-services/content.service';
import {TaskService} from '@app/services/data-services/task.service';
import {ContentType, ContentTypeMap} from '@app/models/course/content/content-type';

@Component({
  selector: 'app-bookshelf-add-content',
  templateUrl: './bookshelf-add-content.component.html',
  styleUrls: ['./bookshelf-add-content.component.scss'],
  animations: [
    slideInRight
  ]
})
export class BookshelfAddContentComponent implements OnInit {
  content_model: ContentBookshelf;
  map = BookshelfContentTypeMap;
  contentEnum = BookshelfContentType;
  accessToken: string;
  dialogRef: MatDialogRef<AddContentDialogComponent>;
  separatorKeysCodes = [ENTER, COMMA];
  selectedQuiz: any;
  @ViewChild('image', { static: true }) image: any;
  @ViewChild('imageUpload') imageUpload: any;
  imageUrl = '';
  is_loading = false;
  tried;

  public imageUploader: FileUploader = new FileUploader({ });
  public fileUploader: FileUploader = new FileUploader({ });
  public imageUploadUploader: FileUploader = new FileUploader({ });

  constructor(private dragulaService: DragulaService,
              private router: Router,
              private bookshelfService: BookshelfService,
              private quizService: QuizService,
              private taskService: TaskService,
              public dialog: MatDialog,
              private http: HttpService) {
    this.content_model = new ContentBookshelf();
  }

  ngOnInit() {
    const url = this.http.getFullUrl('contents/upload');
    const token = localStorage.getItem('cirrus-token');

    this.imageUploader.setOptions({url: url, itemAlias: 'image'});
    this.imageUploader.authTokenHeader = 'Access-Token';
    this.imageUploader.authToken = localStorage.getItem('cirrus-token');
    this.imageUploader.onAfterAddingFile = (item => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    });
    this.imageUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      // console.log(response);
      this.content_model.placeholder_image = JSON.parse(response).location;
      if (this.imageUploadUploader.queue.length) {
        this.imageUploadUploader.uploadAll();
      } else if (this.fileUploader.queue.length) {
        this.setFileUploaderOptions(this.content_model.content_type);
      } else {
        this.createContent();
      }
    };

    this.fileUploader.setOptions({url: url, itemAlias: 'file'});
    this.fileUploader.authTokenHeader = 'Access-Token';
    this.fileUploader.authToken = localStorage.getItem('cirrus-token');
    this.fileUploader.onAfterAddingFile = (item => {
      this.content_model.content_filename = item._file.name;
      // console.log(this.content_model.content_filename);
      item.withCredentials = false;
    });
    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.content_model.content_file = JSON.parse(response).location;
      this.content_model.content_filename = JSON.parse(response).location;
      this.createContent();
    };
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.content_model.meta_tags.push(value.trim());
      // console.log(this.content_model);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: any): void {
    const index = this.content_model.meta_tags.indexOf(tag);

    if (index >= 0) {
      this.content_model.meta_tags.splice(index, 1);
    }
  }
  
  upload() {
    this.tried = true;
    if (this.content_model.content_type && this.content_model.title) {
      this.is_loading = true;
      if (this.imageUploader.queue.length) {
        this.imageUploader.uploadAll();
      } else if (this.imageUploadUploader.queue.length) {
        this.imageUploadUploader.uploadAll();
      } else if (this.fileUploader.queue.length) {
        this.setFileUploaderOptions(this.content_model.content_type);
      } else {
        this.createContent();
      }
      document.getElementById('content-img').style.display = 'none';
    }
  }

  createContent() {
    this.bookshelfService.create(this.content_model)
      .then(() => this.content_model = new ContentBookshelf())
      .then(() => this.fileUploader.clearQueue())
      .then(() => this.imageUploader.clearQueue())
      .then(() => this.imageUploadUploader.clearQueue())
      .then(() => this.image.nativeElement.style.hidden = 'hidden')
      .then(() => {
        this.is_loading = false;
        if (this.imageUpload && this.imageUpload.nativeElement) {
          this.imageUpload.nativeElement.style.hidden = 'hidden';
        }
        this.cancel();
      }).catch(e => {
        if (e.indexOf("404") >= 0) {
          alert("Sorry, your instructor configuration is incorrect.");
        } else {
          alert("Sorry, there is an undisclosed error.");
        }
        this.cancel();
      });
    
  }

  clearContent() {
    this.content_model.url = '';
  }

  cancel() {
    this.router.navigate(['instructor/bookshelf']);
  }

  setFileUploaderOptions(content_type) {
    let url = '';
    if (content_type == this.contentEnum.scorm) {
      url = this.http.getFullUrl('contents/upload/scorm');
    } else if (content_type == this.contentEnum.interactive) {
      url = this.http.getFullUrl('contents/upload/interactive');
    } else if (content_type == this.contentEnum.tinCan) {
      url = this.http.getFullUrl('contents/upload/tincan');
    } else {
      url = this.http.getFullUrl('contents/upload');
    }

    this.fileUploader.setOptions({url: url, itemAlias: 'file'});
    this.fileUploader.authTokenHeader = 'Access-Token';
    this.fileUploader.authToken = localStorage.getItem('cirrus-token');
    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.content_model.content_file = JSON.parse(response).location; // file is uploaded here
      this.createContent();
    };

    this.fileUploader.uploadAll();
  }

}
