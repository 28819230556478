import { Injectable } from '@angular/core';
import { BaseApiV4Service } from './base-api-v4.service';
import { Observable } from 'rxjs';
import { Course, MyCourse } from './models/course/course';
import { IContinueResponse } from './models/course/continue-response';

@Injectable({
  providedIn: 'root',
})
export class CoursesServiceV4 extends BaseApiV4Service {
  create(course: Course): Observable<Course> {
    const url = this.buildRequestUrl('courses');
    return this.http.post<Course>(url, JSON.stringify(course));
  }

  update(course: Course): Observable<Course> {
    const url = this.buildRequestUrl(`courses/${course.id}/update`);
    return this.http.put(url, JSON.stringify(course));
  }

  getCourses(): Observable<Course[]> {
    const url = this.buildRequestUrl('courses');
    return this.http.get<Course[]>(url);
  }

  continueCourse(course_id): Observable<IContinueResponse> {
    const url = this.buildRequestUrl(`courses/${course_id}/continue`);
    return this.http.get<IContinueResponse>(url);
  }

  getMyCourses(): Promise<MyCourse[]> {
    const url = this.buildRequestUrl('user_courses');
    return this.http.get<MyCourse[]>(url)
      .toPromise()
  }
}
