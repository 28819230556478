import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FaqCategory } from '@app/models/faq-category';
import { FaqCategoryService } from '@app/services/data-services/faq-category.service';
import { slideInRight } from '@app/animations/animations';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-faq-category',
  templateUrl: './manage-faq-category.component.html',
  styleUrls: ['./manage-faq-category.component.scss'],
  animations: [
    slideInRight
  ]
})
export class ManageFaqCategoryComponent implements OnInit {

  faq_category: FaqCategory;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private faqCategoryService: FaqCategoryService,
              public snackbar: MatSnackBar) {
    this.faq_category = new FaqCategory();
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.getCourseCategory(params['id']);
        return;
      }
      this.route();
    });
  }

  getCourseCategory(id) {
    this.faqCategoryService.getFaqCategory(id)
      .then((category) => {
        if (category) {
          this.faq_category = category;
          return;
        }
        this.route();
      });
  }

  route() {
    this.router.navigate(['admin/faq-category']);
  }

  updateCategory() {
    this.faqCategoryService.update(this.faq_category)
      .then(() => {
        this.snackbar.open('Updated')
        this.route()
      });
  }

  cancel() {
    this.route();
  }

}
