import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FeatureService } from "./feature.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TrainingCentersGuard implements CanActivate {
  static readonly featureName = 'training_centers';

  constructor(private featureService: FeatureService, private router: Router) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.featureService
      .isFeatureEnabled(TrainingCentersGuard.featureName)
      .pipe(map((isFeatureEnabled) =>
        isFeatureEnabled || this.router.createUrlTree(['/learning-catalog'])
    ));

  }

}
