import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Certificate} from '@app/models/course/certificate';
import {CertificatesService} from '@app/services/data-services/certificates.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-certificate-manager-overview',
  templateUrl: './certificate-manager-overview.component.html',
  styleUrls: ['./certificate-manager-overview.component.scss']
})
export class CertificateManagerOverviewComponent implements OnInit {
  rows = [];
  temp = [];
  selected = [];

  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private router: Router, 
    private certifcatesService: CertificatesService,
    public snackbar: MatSnackBar) {
    this.certifcatesService.getCertificates()
      .then((certs) => this.rows = this.temp = certs);
  }

  ngOnInit() {
  }

  removeCertificate(certificate) {
      this.certifcatesService.delete(certificate.id)
        .then(() => {
          this.snackbar.open('Certificate Created')
          this.rows = this.rows.filter((cert) => cert !== certificate)
        });
  }

  checkAssociations(row) {
    if (confirm('Are you sure you want to remove certificate?')) {
      this.certifcatesService.checkAssociations(row)
        .then(res => res ? this.removeCertificate(row) : alert('Due to associations this certificate could not be deleted.  An alternative is to set as in active.'));
    }
  }

  editCertificate(certificate: Certificate) {
    // console.log(certificate);
    this.router.navigate(['admin/certificates/manage', certificate.id]);
  }

  navigate(url) {
    this.router.navigate([url]);
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeActive(row) {
    row.isActive = !row.isActive;
    this.updateCertificate(row);
  }

  updateCertificate(cert: Certificate) {
    // console.log(cert);
    this.certifcatesService.update(cert)
      .then(res => res);
  }
}
