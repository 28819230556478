export enum ContentType {
  vimeo = 0,
  youtube = 1,
  scorm = 2,
  tinCan = 3,
  pdf = 4,
  quiz = 5,
  download = 6,
  interactive = 7,
  richText = 8,
  flight_assessment = 9,
  ground_assessment = 10,
  image = 11,
  exam = 12
}

export const ContentCategoryMap = {
  0: 'vimeo',
  1: 'youtube',
  2: 'scorm',
  3: 'tinCan',
  4: 'pdf',
  5: 'quiz',
  6: 'download',
  7: 'interactive',
  8: 'richText',
  9: 'flight_assessment',
  10: 'ground_assessment',
  11: 'image',
  12: 'exam'
};
export const ContentTypeMap = {
  0: 'Vimeo',
  1: 'YouTube',
  2: 'SCORM',
  3: 'Tin Can',
  4: 'PDF',
  5: 'Quiz',
  6: 'Document',
  7: 'Interactive',
  8: 'Rich Text',
  9: 'Flight Assessment',
  10: 'Ground Assessment',
  11: 'Image',
  12: 'Exam'
};
