import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';

import * as fromApp from '../../../../../../../store/app.reducers';

import {TaskService} from '@app/services/data-services/task.service';
import {TaskAttempt} from '@app/models/course/content/task/task-attempt';
import {LessonFlightLogService} from '@app/services/data-services/lesson-flight-log.service';
import {LessonFlightLog} from '@app/models/course/workbook/lesson/lesson-flight-log';
import {ProgressCalculatorService} from '@app/services/helper/progress-calculator.service';
import {TaskDefermentService} from '@app/services/data-services/task-deferment.service';
import {TaskDeferment} from '@app/models/course/content/task/task-deferment';

@Component({
  selector: 'app-flight-assessment',
  templateUrl: './flight-assessment.component.html',
  styleUrls: ['./flight-assessment.component.scss']
})
export class FlightAssessmentComponent implements OnInit, OnDestroy {
  @Input('content') content: any;
  @Input('stage') stage: any;
  @Input('lesson') lesson: any;
  currentCourse: any;
  currentCourseAttempt: any;
  subscription: any;
  rows = [];
  columns = [];
  task_attempts: TaskAttempt[] = [];
  lessonFlightLogs: LessonFlightLog[] = [];
  deferred: TaskDeferment[] = [];
  course_id: number;

  contentIsComplete = false;

  constructor(private store: Store<fromApp.AppState>,
              private taskService: TaskService,
              private lessonFlightLogService: LessonFlightLogService,
              private taskDeferService: TaskDefermentService,
              public calculatorService: ProgressCalculatorService) {
  }

  ngOnInit() {
    this.subscription = this.store.select('course').subscribe((state) => {
      this.course_id = state.selectedCourse.course_id;
      this.currentCourseAttempt = state.selectedCourse.course_attempt_id;
      // console.log('currentCourseAttempt: ', this.currentCourseAttempt);
      // console.log('Course ID: ', state.selectedCourse.course_id);
    });
    const user_id = localStorage.getItem('cirrus-user-id');
    this.lessonFlightLogService.getStudentAssessmentFlightLogsByCourseAttempt(user_id, this.content.id, this.course_id, this.currentCourseAttempt).then(lessonFlightLogs => {
      this.lessonFlightLogs = lessonFlightLogs;
      // console.log('lessonFlightLogs: ', this.lessonFlightLogs);
    });

    this.contentIsComplete = this.calculatorService.checkIfContentIsComplete(this.lesson, this.content);
    // console.log('contentIsComplete: ', this.contentIsComplete);
    this.subscription = this.store.select('course').subscribe((state) => {
      this.currentCourse = state.selectedCourse;
      // console.log('currentCourse: ', this.currentCourse);
      this.currentCourseAttempt = state.selectedCourse.course_attempt_id;
      // console.log('currentCourseAttempt: ', this.currentCourseAttempt);
      // this.taskService.getEntries(state.selectedCourse.course.id).then((task_attempts) => {
      this.taskService.getEntriesByCourseAttempt(state.selectedCourse.course.id, this.currentCourseAttempt).then((task_attempts) => {
        // console.log('getEntriesByCourseAttempt: ', this.taskService.getEntriesByCourseAttempt(state.selectedCourse.course.id, this.currentCourseAttempt));
        this.task_attempts = task_attempts;
        // console.log('task_attempts: ', this.task_attempts);
        this.taskDeferService.getTaskDefermentsOfCourseByUser(user_id, this.currentCourse.course.id).then((taskDeferments) => {
          this.deferred = taskDeferments;
          this.normalizeAssessmentData();
        });
      });
    });
  }

  normalizeAssessmentData() {
    const normalizedTasks = [];
    if (this.content.tasks) {
      this.content.tasks.forEach((task) => {
        const normalizedTask = {
          name: task.name,
          task_type: task.task_type.toString() === 'practice' ? 'Practice' : 'Perform',
          required_successful_attempts: task.required_successful_attempts,
          jet_scoring: this.content.jet_scoring,
          passed: this.getPassed(task.id, this.content.id),
          missed: this.getMissed(task.id, this.content.id),
          perfect: this.getPerfect(task.id, this.content.id),
          deferred: this.getDeferred(task.id, this.content.id, this.lesson.id, task.task_type),
          status: "Not Started"
        };
        if (normalizedTask.deferred > 0) normalizedTask.status = 'Deferred';
        if (task.task_type === 'perform') {
          if (normalizedTask.passed > 0) normalizedTask.status = "In Progress";
          if (normalizedTask.passed >= normalizedTask.required_successful_attempts) normalizedTask.status = "Completed";
        } else {
          normalizedTask.status = normalizedTask.passed > 0 || normalizedTask.missed > 0 || (this.content.jet_scoring && normalizedTask.perfect) ? 'Completed' : 'Not Started'
        }
        normalizedTasks.push(normalizedTask);
      });
      this.rows = normalizedTasks;
    }
  }

  getPerfect(task_id: number, content_id: number) {
    return this.task_attempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && (task_attempt.perfect)).length;
  }

  getPassed(task_id: number, content_id: number) {
    let passedAttempts = this.task_attempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && (task_attempt.success || task_attempt.perfect));
      return passedAttempts.length;
  }

  getMissed(task_id: number, content_id: number) {
      return this.task_attempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && (!task_attempt.success)).length;
  }

  getDeferred(task_id: number, content_id: number, lesson_id: number, task_type: string) {
    return this.deferred.filter(task_defer => task_defer.task_id === task_id && task_defer.content_id === content_id && task_defer.lesson_id === lesson_id && task_defer.task_type.toString() === task_type.toString()).length;
  }

  filterItems(type: string) {
      this.rows.sort((a, b) => a[type] > b[type] ? 1 : -1);
  }

  checkCompleted(index: number, task_type: string) {
    if (task_type.toString() === 'practice') {
      const content_task = this.content.content_tasks[index];
      const num_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'practice' && task_attempt.task_id === content_task.task_id).length;
      const progress = num_attempts >= content_task.required_successful_attempts ? 'Completed' : num_attempts > 0 ? 'In Progress' : 'Not Started';

      return progress;
    } else {
      const content_task = this.content.content_tasks[index];
      const num_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.task_id === content_task.task_id && (task_attempt.perfect || task_attempt.success)).length;
      const missed_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' &&  task_attempt.task_id === content_task.task_id && task_attempt.failed).length;
      const progress = num_attempts >= content_task.required_successful_attempts ? 'Completed' : (num_attempts || missed_attempts) > 0 ? 'In Progress' : 'Not Started';

      return progress;
    }
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

}
