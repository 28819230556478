<div class="content-title-wrapper">
  <h1 mat-dialog-title>Add Embark Job ID</h1>
  <button mat-icon-button mat-dialog-close (click)="close()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="form-padding mat-box-shadow">
  <div class="margin-333">
    <form class="set-version">
      <label for="embark_job_id">
        <span><strong>Embark Job ID</strong></span>
        <input type='text' id="embark_job_id" style='padding:8px;' name="embark_job_id" (keydown.enter)="save()" [(ngModel)]="embarkID" />
      </label>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="action-wrapper">
    <button mat-raised-button color="primary" type="submit" (click)="save()">Save</button>
  </div>
</mat-dialog-actions>
