import { Component, OnInit } from '@angular/core';
import {CommentService} from '@app/services/data-services/comment.service';
import {DatePipe} from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-comment-moderating',
  templateUrl: './comment-moderating.component.html',
  styleUrls: ['./comment-moderating.component.scss']
})
export class CommentModeratingComponent implements OnInit {
  rows: any[] = [];
  datePipe = new DatePipe('en-US');

  constructor(private commentService: CommentService,
              public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.commentService.getAllComments()
      .then(res => this.rows = res);
  }

  removeComment(comment) {
    this.commentService.removeComment(comment)
      .then(() => {
        this.snackbar.open('Comment deleted')
        this.rows = this.rows.filter(item => item.id !== comment.id)
      })
      .then(() => this.rows = this.rows.filter( item => item.content_comment_id !== comment.id));
  }

}
