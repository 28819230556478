import { AfterViewInit, Component } from '@angular/core';
import { ReturnPathService } from '@app/return-path.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({ template: '' })
export class AuthenticateComponent implements AfterViewInit {
  constructor(private oidcSecurityService: OidcSecurityService, private returnPathService: ReturnPathService) {}

  ngAfterViewInit(): void {
    if (document.referrer === '') {
      this.returnPathService.returnPath = '/recent-activity';
    } else {
      this.returnPathService.returnPath = new URL(document.referrer).pathname;
    }
    this.oidcSecurityService.authorize();
  }
}
