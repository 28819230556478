<form #form id="assessment-standards-form">
  <!--(ngSubmit)="submit(form)"-->
  <h1 mat-dialog-title>Missed Standards</h1>
  <mat-dialog-content>
    <ng-container *ngIf="!missed.length">
      <div class="form-row" *ngFor="let standard of standards; trackBy:trackFunction; let i = index;">
        <mat-checkbox name="standard-{{i}}" color="primary" (change)="selectStandard(i)">{{standard}}</mat-checkbox>
      </div>
    </ng-container>
    <ng-container *ngIf="missed.length">
      <div class="form-row" *ngFor="let reason of missed; trackBy:trackFunction; let i = index;">
        {{standards[reason]}}
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div fxFlex></div>
    <button *ngIf="missed.length" mat-raised-button color="primary" type="button" (click)="cancel()">Close</button>
    <button *ngIf="!missed.length" mat-button type="button" (click)="cancel()">Cancel</button>
    <button *ngIf="!missed.length" mat-raised-button color="primary" type="submit" (click)="save()" [disabled]="!selectedStandards.length">Submit Standards</button>
  </mat-dialog-actions>
</form>

