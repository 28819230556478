import * as CourseActions from './course.actions';

import {UserCourse} from '../../../../models/course/user-course';
import {Content} from '../../../../models/course/content/content';

export interface State {
  selectedCourse: UserCourse;
  selectedSection: any;
  selectedCourseContent: Content[];
  hasAgreed: boolean;
  isPreview: boolean;
}

const initialState: State = {
  selectedCourse: null,
  selectedSection: null,
  selectedCourseContent: null,
  hasAgreed: false,
  isPreview: false,
};

export function courseReducer(state = initialState, action: CourseActions.CourseActions) {
  let newState;
  switch (action.type) {
    case CourseActions.HAS_AGREED:
      newState = {
        ...state,
        hasAgreed: true
      };

      storeState(newState);

      return newState;
    case CourseActions.SET_PREVIEW:
      newState = {
        ...state,
        isPreview: action.payload
      };
      storeState(newState);
      return newState;
    case CourseActions.SELECT_COURSE:
      newState = {
        ...state,
        selectedCourse: action.payload
      };

      storeState(newState);

      return newState;
    case CourseActions.SELECT_COURSE_CONTENT:
      newState = {
        ...state,
        selectedCourseContent: action.payload
      };

      storeState(newState);

      return newState;
    case CourseActions.DESELECT_COURSE:
      return {
        ...state,
        selectedCourse: null,
        hasAgreed: false
      };
    case CourseActions.SELECT_SECTION:
      return {
        ...state,
        selectedSection: action.payload
      };
    default:
      if (localStorage.getItem('state')) {
        const storedState = JSON.parse(localStorage.getItem('state'));
        return storedState;
      }
      return state;
  }
}

export function storeState(newState) {
  localStorage.setItem('state', JSON.stringify(newState));
}
