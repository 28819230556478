import {Component, OnDestroy, OnInit} from '@angular/core';
import {PurchaseService} from '@app/services/data-services/purchase.service';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as CheckoutActions from '../store/checkout.actions';
import * as fromApp from '../../../../store/app.reducers';
import {Order} from '@app/models/order/order';
import {Subscription} from 'rxjs';
import {storeCart} from '../store/checkout.reducers';
import {environment} from '../../../../../environments/environment';
import {CourseService} from '@app/services/data-services/course.service';
import {UserCourse} from '@app/models/course/user-course';

@Component({
  selector: 'app-user-shopping-cart',
  templateUrl: './user-shopping-cart.component.html',
  styleUrls: ['./user-shopping-cart.component.scss']
})
export class UserShoppingCartComponent implements OnInit, OnDestroy {
  isCartEmpty = false;
  total = 0.00;
  order: Order;
  subscription = new Subscription();
  userCourses: UserCourse[] = [];

  defaultCourseThumb = environment.defaultThumbnailCourse;

  constructor(private purchaseService: PurchaseService,
              private courseService: CourseService,
              private router: Router,
              private store$: Store<fromApp.AppState>) { }

  ngOnInit() {
    if (localStorage.getItem('cirrus-token')) {
      this.courseService.getMyUserCourses()
        .then(user_courses => {
          this.userCourses = user_courses;
        });
    }
    this.subscription.add(this.store$.select('checkout').subscribe(state => {
      this.order = state.order;
      this.total = 0;
      state.order.order_line_items.forEach(lineItem => {
        console.log(lineItem);
        if (lineItem.product && lineItem.product.list_price) {
          this.total += parseFloat(lineItem.product.list_price.toString());
        }
      });
    }));
  }

  removeItem(item) {
    this.store$.dispatch(new CheckoutActions.RemoveFromCart(item));
  }

  isEnrolled(id: number) {
    return this.userCourses.map(item => item.course_id).indexOf(id) !== -1;
  }

  toCheckout() {
    for (const item of this.order.order_line_items) {
      if (this.isEnrolled(item.product.id)) {
        alert('You already own one or more courses in your cart. Please remove to continue.');
        return;
      } else {
        this.router.navigate(['/checkout']);
      }
    }
  }

  login() {
    this.store$.dispatch(new CheckoutActions.StoreCart());
    this.router.navigate(['/sign-in']);
  }

  isLoggedIn() {
    return localStorage.getItem('cirrus-token') != null;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
