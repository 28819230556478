import {Lesson} from './lesson';
import {Aircraft} from '../../../aircraft/aircraft';
import {Instructor} from '@app/models/instructor';

export class LessonFlightLog {
  id?: number;
  user_id?: number;
  lesson_id?: number;
  instructor_id?: number;
  instructor?: Instructor;
  course_attempt_id: number;
  course_id?: number;
  stage_id?: number;
  content_id?: number;
  approachType?: string;
  fromAirport?: string;
  toAirport?: string;
  routePath?: string;
  hobbsOut?: number;
  hobbsIn?: number;
  completedGroundInstructionHours?: number;
  completedTotalHours?: number;
  completedSimHours?: number;
  completedDualReceivedHours?: number;
  completedCrossCountryHours?: number;
  completedCrossCountryLegs?: number;
  completedNightHours?: number;
  completedSoloHours?: number;
  completedPICHours?: number;
  completedActualInstrumentHours?: number;
  completedSimulatedInstrumentHours?: number;
  completedTrackAidHours?: number;
  completedHoldingPatterns?: number;
  completedNightLandings?: number;
  completedDayLandings?: number;
  completedDayTakeoffs?: number;
  approaches?: string;
  completedNightTakeoffs?: number;
  completedTotalLandings?: number;
  aircraft_tail_id?: string;
  comment_body?: string;
  completedAssessment?: boolean;
  completedAdjustmentHours?: number;
  log_type?: string;
  lesson_flight_log_date: Date;
  aircraft_model: string;
  aircraft_make: string;
  trackNavAid: boolean;
  instructor_object?: Instructor;
  total_flight_time?: number;

  // Snake case aliases of properties coming from the Rails server
  to_airport?: string;
  from_airport?: string;
  completed_sim_hours?: string;
  completed_ground_instruction_hours?: string;

  constructor() {
    this.completedAssessment = false;
    this.completedCrossCountryLegs = 0;
    this.completedHoldingPatterns = 0;
    this.completedNightLandings = 0;
    this.completedDayLandings = 0;
    this.completedDayTakeoffs = 0;
    this.completedNightTakeoffs = 0;
    this.completedTotalLandings = 0;
    this.trackNavAid = false;
    this.approaches = '';
  }
}
