<mat-dialog-content class="popout-wrapper no-scroll">
  <div class="main-content">
    <div class="full-width dialog-header">
      <button mat-icon-button mat-dialog-close><mat-icon class="header-icon">close</mat-icon></button>
      <!--<span fxFlex></span>-->
      <button mat-icon-button (click)="download()"><mat-icon class="header-icon">file_download</mat-icon></button>
    </div>
    <div class="course-info-wrapper">
      <div class="course-image-wrapper" fxFlex="100" fxLayout="row">
        <canvas #certCanvas class='canvas-styling' width="381px" height="295px"></canvas>
        <img #certImage id="cert-image" class='cert-styling' crossOrigin="Anonymous" [src]="user_certificate?.certificate?.certificate_image" alt="" (load)="paint()">
      </div>
    </div>
  </div>
  <!--Bottom Area-->
  <div class="course-content">
    <div class="category-header"
         fxLayoutAlign="center">
      <h3 class="certs-body-title">{{user_certificate?.course_name}}</h3>
      <div fxFlex></div>
    </div>
    <div class='cert-text-body'>
      <!--By: Cirrus Approach-->
      <p></p>
      <div>
        <p class="avionics">{{user_certificate?.aircraft_name}} <br>
                            {{user_certificate?.avionics_name}}
        </p>
      </div>
      <div class="full-width">
        <p class="font-weight">Expires: {{user_certificate?.expires | date: 'MM/dd/yyyy'}} </p>
        <p class='cert-description'>{{user_certificate?.certificate?.desc}}</p>
      </div>
    </div>
  </div>
</mat-dialog-content>
