import { Injectable } from '@angular/core';
import { BaseApiV4Service } from './base-api-v4.service';
import { Observable } from 'rxjs';
import { IContinueResponse } from './models/course/continue-response';
import { Lesson } from './models/course/workbook/lesson/lesson';

@Injectable({
  providedIn: 'root'
})
export class LessonV4Service extends BaseApiV4Service {

  create(lesson) {
    const url = this.buildRequestUrl(`lessons`);
    return this.http.post<Lesson>(url, lesson);
  }

  getLesson(id) {
    const url = this.buildRequestUrl(`lessons/${id}/edit`);
    return this.http.get<Lesson>(url);
  }

  update(lesson) {
    const url = this.buildRequestUrl(`lessons/${lesson.id}`);
    return this.http.put<Lesson>(url, lesson);
  }

}
