<div fxLayout='row' #wrapper>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">{{user_name}}</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="card-title-text" style="min-height: 100px;" fxLayout="row">
          <div fxLayout="column">
            <p class="content-label content-status-not-started" *ngIf="assessment_progress === 'not_started'">Not Started</p>
            <p class="content-label content-status-in-progress" *ngIf="assessment_progress === 'in_progress'">In Progress</p>
            <p class="content-label content-status-completed" *ngIf="assessment_progress === 'completed'">Completed</p>
            <h3 class="assessment-title" style="margin-top: 0px;">{{assessment.title}}</h3>
          </div>
          <p class="assessment-type" *ngIf="assessment.content_type === 9">FLIGHT ASSESSMENT</p>
          <p class="assessment-type" *ngIf="assessment.content_type === 10">GROUND ASSESSMENT</p>
        </div>
        <div fxLayout="row" class="indicator-row">
          <div class="card-title-text full-width status-row" fxLayout="row">
            <div fxLayout="column">
              <p class="number">{{rows.length}}</p>
              <p class="status-txt">TOTAL TASKS</p>
            </div>
            <div fxLayout="column" class="padding-left-light">
              <p class="number">{{completed_tasks.length}}</p>
              <p class="status-txt">COMPLETED</p>
            </div>
            <div fxLayout="column" class="padding-left-light">
              <p class="number">{{in_progress_tasks.length}}</p>
              <p class="status-txt">IN PROGRESS</p>
            </div>
            <div fxLayout="column" class="padding-left-light">
              <p class="number">{{not_started_tasks.length}}</p>
              <p class="status-txt">NOT STARTED</p>
            </div>
            <div fxLayout="column" class="padding-left-light">
              <p class="number deferred-status">{{deferredTasks.length + assessment_deferred_tasks.length}}</p>
              <p class="status-txt" style="width: 70px; text-align: center;">DEFERRED</p>
            </div>
          </div>
        </div>
        <div>
          <mat-tab-group (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Tasks">
              <div class="mat-box-shadow">
                <ngx-datatable #table class="material bg-white student-assessment-table table-padding"
                               [columnMode]="'force'"
                               [headerHeight]="50"
                               [footerHeight]="50"
                               [rowHeight]="'auto'"
                               [limit]="50"
                               (sort)="hasSorted($event)"
                               [rows]="rows">
                  <ngx-datatable-column name="Tasks" prop='name' class="task-names"></ngx-datatable-column>
                  <ngx-datatable-column name="Task Category" prop='task_category.name'></ngx-datatable-column>
                  <ngx-datatable-column name="Status" prop="status">
                    <ng-template  let-row='row' ngx-datatable-cell-template>
                      <span [ngClass]="{'orange-text': row.status === 'Deferred'}">{{row?.status}}</span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Required" prop='required_successful_attempts'></ngx-datatable-column>
                  <ngx-datatable-column name="Perfect" prop="perfect" *ngIf="assessment.jet_scoring"></ngx-datatable-column>
                  <ngx-datatable-column name="Passed" prop="passed"></ngx-datatable-column>
                  <ngx-datatable-column name="Missed" prop="missed"></ngx-datatable-column>
                  <ngx-datatable-column name="Type" prop='task_type'>
                    <ng-template  let-row='row' ngx-datatable-cell-template>
                      <span style="text-transform: capitalize;" [ngClass]="{'blue-text': row.task_type.toString() === 'perform'}">{{row?.task_type}}</span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column
                    [sortable]="false"
                    [canAutoResize]="true"
                    [draggable]="false"
                    [resizeable]="false">
                    <ng-template  let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      <button mat-icon-button [matMenuTriggerFor]="entryMenu" class="hidden-on-open">
                        <mat-icon class="text-muted">more_vert</mat-icon>
                      </button>
                      <mat-menu #entryMenu="matMenu">
                        <button *ngIf="!isCompleted" mat-menu-item (click)="createEntry('success', rowIndex, row)">
                          <i class="material-icons icon green">add_circle_outline</i>
                          <span>Pass</span>
                        </button>
                        <button *ngIf="!isCompleted" mat-menu-item (click)="newStandardEntry(rowIndex, row)">
                          <i class="material-icons icon red">remove_circle_outline</i>
                          <span>Miss</span>
                        </button>
                        <button mat-menu-item (click)="createEntry('perfect', rowIndex, row)" *ngIf="!isCompleted && assessment.jet_scoring">
                          <i class="material-icons icon purple">grade</i>
                          <span>Perfect</span>
                        </button>
                        <button *ngIf="!isCompleted" mat-menu-item (click)="undoEntry(row)">
                          <i class="material-icons icon grey">undo</i>
                          <span>Undo</span>
                        </button>
                        <button mat-menu-item  (click)="newDeferReasonEntry(rowIndex, row)" *ngIf="!isCompleted && checkCompleted(rowIndex, row.task_type) !== 'Deferred'">
                          <i class="material-icons icon orange">remove_circle_outline</i>
                          <span>Defer</span>
                        </button>
                        <button mat-menu-item  (click)="undoDefer(row, rowIndex)" *ngIf="!isCompleted && checkCompleted(rowIndex, row.task_type) === 'Deferred'">
                          <i class="material-icons icon orange">remove_circle_outline</i>
                          <span>Undo Defer</span>
                        </button>
                        <button *ngIf="!isCompleted" mat-menu-item  (click)="resetTask(row, rowIndex)">
                          <i class="material-icons icon grey">cached</i>
                          <span>Reset</span>
                        </button>
                        <hr *ngIf="!isCompleted">
                        <button mat-menu-item (click)="viewTaskDetail(row)">
                          <i class="material-icons normal-icon"></i>
                          <span>View Task Detail</span>
                        </button>
                      </mat-menu>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
                <div *ngIf="deferredTasks.length">
                  <div class="full-width bg-orange" style="padding: 10px;">
                    <h5 style="color: white;">PREVIOUS DEFERRED TASKS</h5>
                  </div>
                  <div style="padding: 10px;" class="bg-orange-secondary">
                    <ngx-datatable class="material bg-white student-assessment-table table-padding"
                                   [columnMode]="'force'"
                                   [headerHeight]="50"
                                   [footerHeight]="50"
                                   [rowHeight]="'auto'"
                                   [limit]="50"
                                   [rows]="deferredTasks">
                      <ngx-datatable-column name="Tasks" prop='task.name' class="task-names"></ngx-datatable-column>
                      <ngx-datatable-column name="Task Category" prop='task.task_category.name'></ngx-datatable-column>
                      <ngx-datatable-column name="Status">
                        <ng-template let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                          {{checkCompleted(rowIndex, row.task.task_type, true)}}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Required" prop='task.required_successful_attempts'></ngx-datatable-column>
                      <ngx-datatable-column
                        [sortable]="false"
                        [canAutoResize]="false"
                        [draggable]="false"
                        [resizeable]="false"
                        name="Perfect" *ngIf="previousAssessmentOfType?.jet_scoring">
                        <ng-template let-rowIndex="rowIndex" let-row='row' ngx-datatable-cell-template>
                          <span>
                            <!--<i class="material-icons blue-icon" (click)="createEntry('perfect', rowIndex)">add_circle_outline</i>-->
                            {{getPerfect(row.task)}}
                          </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column
                        [sortable]="false"
                        [canAutoResize]="false"
                        [draggable]="false"
                        [resizeable]="false"
                        name="Passed">
                        <ng-template let-rowIndex="rowIndex" let-row='row' ngx-datatable-cell-template>
                          <!--<i class="material-icons green-icon" (click)="createEntry('success', rowIndex)">add_circle_outline</i>-->
                          {{getPassed(row.task)}}
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false" name="Missed">
                        <ng-template let-rowIndex="rowIndex" let-row='row' ngx-datatable-cell-template>
                      <span>
                        <!--<i class="material-icons red-icon" (click)="newStandardEntry(rowIndex)">remove_circle_outline</i>-->
                        {{getMissed(row.task)}}
                      </span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column name="Type" prop='content_task.task_type'>
                        <ng-template let-rowIndex="rowIndex" let-row='row' ngx-datatable-cell-template>
                          <span style="text-transform: capitalize;" [ngClass]="{'blue-text': row.task.task_type.toString() === 'perform'}">{{row.task.task_type}}</span>
                        </ng-template>
                      </ngx-datatable-column>
                      <ngx-datatable-column
                        [sortable]="false"
                        [canAutoResize]="true"
                        [draggable]="false"
                        [resizeable]="false">
                        <ng-template  let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                          <button mat-icon-button [matMenuTriggerFor]="entryMenu" class="hidden-on-open">
                            <mat-icon class="text-muted">more_vert</mat-icon>
                          </button>
                          <mat-menu #entryMenu="matMenu">
                            <button *ngIf="!isCompleted" mat-menu-item (click)="createEntry('success', rowIndex, row, null, true)">
                              <i class="material-icons icon green">add_circle_outline</i>
                              <span>Pass</span>
                            </button>
                            <button *ngIf="!isCompleted" mat-menu-item (click)="newStandardEntry(rowIndex, row, assessment.id, true)">
                              <i class="material-icons icon red">remove_circle_outline</i>
                              <span>Miss</span>
                            </button>
                            <button mat-menu-item (click)="createEntry('perfect', rowIndex, row, assessment.id)" *ngIf="!isCompleted && assessment.jet_scoring">
                              <i class="material-icons icon purple">grade</i>
                              <span>Perfect</span>
                            </button>
                            <button *ngIf="!isCompleted" mat-menu-item (click)="undoEntry(row, true)">
                              <i class="material-icons icon grey">undo</i>
                              <span>Undo Last Attempt</span>
                            </button>
                            <button *ngIf="!isCompleted" mat-menu-item  (click)="resetTask(row, rowIndex, true)">
                              <i class="material-icons icon grey">cached</i>
                              <span>Reset</span>
                            </button>
                            <hr *ngIf="!isCompleted">
                            <button mat-menu-item (click)="viewTaskDetail(row)">
                              <i class="material-icons normal-icon"></i>
                              <span>View Task Detail</span>
                            </button>
                          </mat-menu>
                        </ng-template>
                      </ngx-datatable-column>
                    </ngx-datatable>
                  </div>
                </div>
                <div *ngIf="!isCompleted" class="button-wrapper full-width">
                  <div class="button-container full-width">
                    <div fxFlex></div>
                    <button id="finishEntry" (click)="completeAssessment()" *ngIf="!loading && (rows.length - assessment_deferred_tasks.length - completed_tasks.length) === 0 && assessment_progress !== 'completed'" mat-raised-button color="primary">
                      Finalize Assessment
                    </button>
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Logbook">
              <div class="mat-box-shadow">
                <ngx-datatable class="material bg-white student-assessment-table table-padding"
                               [columnMode]="'force'"
                               [headerHeight]="50"
                               [footerHeight]="50"
                               [rowHeight]="'auto'"
                               [limit]="50"
                               [rows]="log_rows">
                  <ngx-datatable-column name="Date Posted" prop='lesson_flight_log_date' [pipe]="datePipe"></ngx-datatable-column>
                  <ngx-datatable-column name="Type">
                    <ng-template let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{getType(row)}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="From" prop="fromAirport"></ngx-datatable-column>
                  <ngx-datatable-column name="To" prop="toAirport"></ngx-datatable-column>
                  <ngx-datatable-column name="Total FLT" prop='completedTotalHours'></ngx-datatable-column>
                  <ngx-datatable-column name="Total SIM" prop='completedSimHours'></ngx-datatable-column>
                  <ngx-datatable-column name="Total GND" prop='completedGroundInstructionHours'></ngx-datatable-column>
                  <!--<ngx-datatable-column name="Instructor" prop='instructor_name'></ngx-datatable-column>-->
                  <ngx-datatable-column
                    [sortable]="false"
                    [canAutoResize]="true"
                    [draggable]="false"
                    [resizeable]="false">
                    <ng-template let-row='row' ngx-datatable-cell-template>
                      <button mat-icon-button [matMenuTriggerFor]="logMenu" class="hidden-on-open">
                        <mat-icon class="text-muted">more_vert</mat-icon>
                      </button>
                      <mat-menu #logMenu="matMenu">
                        <button mat-menu-item (click)="viewLogbookEntry(row)">
                          <i class="material-icons icon">open_in_new</i>
                          <span>View Logbook Entry</span>
                        </button>
                        <button *ngIf="!isCompleted" mat-menu-item (click)="removeEntry(row)">
                          <i class="material-icons icon">delete</i>
                          <span>Remove Logbook</span>
                        </button>
                      </mat-menu>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
                <div *ngIf="!isCompleted" class="button-wrapper full-width">
                  <div class="button-container full-width">
                    <div fxFlex></div>
                    <button id="finishEntry" mat-raised-button color="primary" (click)="newEntry()">
                      Add Logbook Entry
                    </button>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
