import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseCategoryService } from '../../../services/data-services/course-category.service';
import { CourseCategory } from '../../../models/course/course-category';
import { IMenuItem } from '../../../services/navigation/navigation.service';

@Component({
  selector: 'app-category-manager',
  templateUrl: './category-manager.component.html',
  styleUrls: ['./category-manager.component.scss']
})
export class CategoryManagerComponent implements OnInit {
  categoriesItems: IMenuItem[] = [
    {
      name: 'Course',
      state: '/admin/courses/category/course/overview',
      type: 'link'
    },
    {
      name: 'Task',
      state: '/admin/courses/category/task/overview',
      type: 'link'
    }
  ];
  constructor() {}

  ngOnInit() {}
}
