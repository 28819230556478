import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LessonFlightLog } from '@app/models/course/workbook/lesson/lesson-flight-log';
import { LessonFlightLogService } from '@app/services/data-services/lesson-flight-log.service';
import {
  StudentAssessmentsApproachesDialogComponent,
} from '../student-assessments-approaches-dialog/student-assessments-approaches-dialog.component';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../../../../store/app.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-student-assessments-entry-dialog',
  templateUrl: './student-assessments-entry-dialog.component.html',
  styleUrls: ['./student-assessments-entry-dialog.component.scss']
})
export class StudentAssessmentsEntryDialogComponent implements OnInit, OnDestroy {
  form: FormGroup;
  lesson_flight_log: LessonFlightLog = new LessonFlightLog();
  approachesDialogRef: MatDialogRef<StudentAssessmentsApproachesDialogComponent>;
  approaches = [];
  edit = false;
  new = false;
  isEditing = false;
  isCompleted = false;
  subscription: Subscription = new Subscription();

  modelGroups = [
    {
      name: 'SR20',
      model: [
        { value: 'SR20 Pre G3', viewValue: 'SR20 Pre G3' },
        { value: 'SR20 G3', viewValue: 'SR20 G3' },
        { value: 'SR20 G6', viewValue: 'SR20 G6' },
        { value: 'SR20 ATD', viewValue: 'SR20 ATD' },
        { value: 'SR20 FTD', viewValue: 'SR20 FTD' },
      ]
    },
    {
      name: 'SR22',
      model: [
        { value: 'SR22 Pre G3', viewValue: 'SR22 Pre G3' },
        { value: 'SR22 G3', viewValue: 'SR22 G3' },
        { value: 'SR22 G5', viewValue: 'SR22 G5' },
        { value: 'SR22 G6', viewValue: 'SR22 G6' },
        { value: 'SR22 ATD', viewValue: 'SR22 ATD' },
        { value: 'SR22 FTD', viewValue: 'SR22 FTD' },
      ]
    },
    {
      name: 'SR22T',
      model: [
        { value: 'SR22T Pre G5', viewValue: 'SR22T Pre G5' },
        { value: 'SR22T G5', viewValue: 'SR22T G5' },
        { value: 'SR22T G6', viewValue: 'SR22T G6' },
        { value: 'SR22T ATD', viewValue: 'SR22T ATD' },
        { value: 'SR22T FTD', viewValue: 'SR22T FTD' }
      ]
    },
    {
      name: 'SR22TN',
      model: [
        { value: 'SR22TN', viewValue: 'SR22TN' },
        { value: 'SR22TN ATD', viewValue: 'SR22TN ATD' },
        { value: 'SR22TN FTD', viewValue: 'SR22TN FTD' }
      ]
    },
    {
      name: 'SF50',
      model: [
        { value: 'SF50', viewValue: 'SF50' },
        { value: 'SF50 FFS', viewValue: 'SF50 FFS' }
      ]
    }
  ];
  date = '';


  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<StudentAssessmentsEntryDialogComponent>,
              public dialog: MatDialog,
              private lessonFlightLogService: LessonFlightLogService,
              private store: Store<fromApp.AppState>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.type === 'edit') {
      this.lesson_flight_log = data.logbook;
      this.approaches = JSON.parse(this.lesson_flight_log.approaches);
      this.edit = true;
      this.new = false;
    } else if (data.type === 'new') {
      this.edit = false;
      this.new = true;
      this.lesson_flight_log.course_attempt_id = data.course_attempt_id;
      this.lesson_flight_log.course_id = data.course_id;
      this.lesson_flight_log.stage_id = data.stage_id;
      this.lesson_flight_log.lesson_id = data.lesson_id;
      this.lesson_flight_log.user_id = data.user_id;
      this.lesson_flight_log.content_id = data.content_id;
      this.lesson_flight_log.completedAssessment = false;
    }
    this.lesson_flight_log.aircraft_make = 'CIRRUS';
    this.subscription.add(this.store.select('assessment').subscribe(state => {
      this.isCompleted = state.isCompleted;
    }));
  }

  ngOnInit() {
  }

  decrease(item) {
    if (this.lesson_flight_log[item] > 0) {
      this.lesson_flight_log[item]--;
    }
  }

  increase(item) {
    this.lesson_flight_log[item]++;
  }

  cancel() {
    this.dialogRef.close(false);
  }

  getDate() {
    const dt = new Date(this.date);
    return new Date(dt.getTime() + dt.getTimezoneOffset() * 60 * 1000);
  }

  submit() {
    this.lesson_flight_log.lesson_flight_log_date = this.getDate();
    this.lesson_flight_log.approaches = JSON.stringify(this.approaches);
    this.lessonFlightLogService.create(this.lesson_flight_log)
    .then(res => {
      this.dialogRef.close(res);
    });
  }

  update() {
    this.lesson_flight_log.lesson_flight_log_date = this.getDate();
    this.lesson_flight_log.approaches = JSON.stringify(this.approaches);
    this.lessonFlightLogService.create(this.lesson_flight_log)
      .then(res => {
        this.dialogRef.close(res);
      });
  }

  addApproach() {
    this.approachesDialogRef = this.dialog.open(StudentAssessmentsApproachesDialogComponent, {
      hasBackdrop: true
    });

    this.approachesDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        for (let i = 0; i < data.length; i++) {
          this.approaches.push({
            'type': data[i].type,
            'airport': data[i].airport,
            'runway': data[i].runway
          });
        }
      }
    });
  }

  toggleEdit() {
    if (this.lesson_flight_log.lesson_flight_log_date) {
      const tempDate = new Date(this.lesson_flight_log.lesson_flight_log_date.toLocaleString('en-GB', { timeZone: 'UTC' }));
      const monthPlaceholder = tempDate.getMonth() <= 8 ? '0' : '';
      const dayPlaceholder = tempDate.getDate() <= 8 ? '0' : '';
      this.date = `${tempDate.getFullYear()}-${monthPlaceholder}${tempDate.getMonth() + 1}-${dayPlaceholder}${tempDate.getDate()}`;
    }
    this.isEditing = !this.isEditing;
  }

  removeApproach(index) {
    this.approaches.splice(index, 1);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
