import {User} from '../user/user';
import {NotificationType} from '@app/models/notification/notification-type';
import {Certificate} from '@app/models/course/certificate';
import {Badge} from '@app/models/course/course-badge';
import {Instructor} from '@app/models/instructor';
import { Connection } from '@app/models/connection';

export class Notification {
  id?: number;
  body?: string;
  created_at?: number;
  sender?: User;
  notific_type?: NotificationType;
  badge?: Badge;
  certificate?: Certificate;
  instructor?: Instructor;
  student?: User;
  user: User;
  notified_at: string;
  notifiable: Notifiable;
  status: string;
}

class Notifiable {
  type: string;
  connection?: Connection;
}
