import * as AssessmentActions from './assessment.actions';
import {User} from '@app/models/user/user';
import {LessonFlightLog} from '@app/models/course/workbook/lesson/lesson-flight-log';
import {TaskAttempt} from '@app/models/course/content/task/task-attempt';
import {UserCourse} from '@app/models/course/user-course';
import {TaskDeferment} from '@app/models/course/content/task/task-deferment';
import {DeferReason} from '@app/models/course/content/task/defer-reason';
import {ContentTask} from '@app/models/course/content/content-task';
import {Content} from '@app/models/course/content/content';
import {UserProgressType} from '@app/models/user/user-progress-type';
import {UserProgress} from '@app/models/user/user-progress';

export interface State {
  selectedStudent: User;
  assessmentLogbooks: LessonFlightLog[];
  assessmentTaskEntries: TaskAttempt[];
  assessments: Content[];
  assessmentProgress: UserProgress[];
  deferments: TaskDeferment[];
  selectedAssessmentDeferments: TaskDeferment[];
  selectedAssessmentDeferredTasks: any[];
  selectedAssessmentLogbookEntries: LessonFlightLog[];
  selectedAssessment: Content;
  nextAssessmentOfType: Content;
  previousAssessmentOfType: Content;
  deferReasons: DeferReason[];
  selectedCourse: UserCourse;
  isCompleted: boolean;
  selectedUserName: string;
  selectedTaskToView: ContentTask;
  selectedTaskToViewAttempts: TaskAttempt[];
  loading: string[];
}

const initialState: State = {
  selectedStudent: null,
  assessmentLogbooks: [],
  assessmentTaskEntries: [],
  assessments: [],
  assessmentProgress: [],
  deferments: [],
  selectedAssessmentDeferments: [],
  selectedAssessmentDeferredTasks: [],
  selectedAssessmentLogbookEntries: [],
  selectedAssessment: null,
  nextAssessmentOfType: null,
  previousAssessmentOfType: null,
  deferReasons: [],
  selectedCourse: null,
  isCompleted: false,
  selectedUserName: '',
  selectedTaskToView: null,
  selectedTaskToViewAttempts: [],
  loading: []
};

export function assessmentReducer(state = initialState, action: AssessmentActions.AssessmentActions) {
  let loading = [];
  let taskAttempts = [];
  let deferments = [];
  let taskDeferments = [];
  let selectedAssessmentDeferments = [];
  let assessmentProgress = [];
  let newState = null;
  let selectedAssessmentLogbookEntries = [];
  let entries = [];
  let selectedTaskAttempts = [];
  let selectedCourse = null;

  switch (action.type) {
    case AssessmentActions.UPDATE_ASSESSMENT_ENTRIES:
      loading = state.loading;
      if (loading.length) {
        const index = loading.indexOf('task_attempts');
        if (index > -1) {
          loading.splice(index, 1);
        }
      }

      newState = {
        ...state,
        assessmentTaskEntries: action.payload,
        loading
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_NEXT_ASSESSMENT_OF_TYPE:
      newState = {
        ...state,
        nextAssessmentOfType: action.payload
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_PREVIOUS_ASSESSMENT_OF_TYPE:
      newState = {
        ...state,
        previousAssessmentOfType: action.payload
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_ASSESSMENT_DEFERMENTS:
      state.previousAssessmentOfType && state.previousAssessmentOfType.tasks.forEach(task => {
        state.deferments.filter(deferment => {
          if (task.id === deferment.task_id && deferment.task_type.toString() === task.task_type.toString() && state.previousAssessmentOfType.id === deferment.content_id) {
            taskDeferments.push({
              task,
              deferment
            });
          }
        });
      });

      newState = {
        ...state,
        selectedAssessmentDeferredTasks: taskDeferments,
        loading
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_ASSESSMENT_LOGBOOKS:
      loading = state.loading;
      if (loading.length) {
        const index = loading.indexOf('logbooks');
        if (index > -1) {
          loading.splice(index, 1);
        }
      }

      newState = {
        ...state,
        assessmentLogbooks: action.payload,
        loading
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_SELECTED_ASSESSMENT_LOGBOOKS:
      selectedAssessmentLogbookEntries = state.assessmentLogbooks.filter(logbookEntry => logbookEntry.content_id === state.selectedAssessment.id && logbookEntry.lesson_id === state.selectedAssessment.lesson_id);

      newState = {
        ...state,
        selectedAssessmentLogbookEntries,
        loading
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_ASSESSMENTS:
      loading = state.loading;
      if (loading.length) {
        const index = loading.indexOf('assessments');
        if (index > -1) {
          loading.splice(index, 1);
        }
      }

      newState = {
        ...state,
        assessments: action.payload,
        loading
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_ASSESSMENT_PROGRESS:
      newState = {
        ...state,
        assessmentProgress: action.payload
      };

      storeState(newState);

      return newState;
    case AssessmentActions.ADD_ASSESSMENT_PROGRESS:
      if (!action.payload[0] || !action.payload[0].id) {
        return state;
      }
      assessmentProgress = state.assessmentProgress.filter(progress => progress.id !== action.payload[0].id);
      assessmentProgress.push(action.payload[0]);

      newState = {
        ...state,
        assessmentProgress
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_DEFERMENTS:
      loading = state.loading;
      if (loading.length) {
        const index = loading.indexOf('deferments');
        if (index > -1) {
          loading.splice(index, 1);
        }
      }

      newState = {
        ...state,
        deferments: action.payload,
        loading
      };

      storeState(newState);

      return newState;
    case AssessmentActions.UPDATE_DEFER_REASONS:
      loading = state.loading;
      if (loading.length) {
        const index = loading.indexOf('defer_reasons');
        if (index > -1) {
          loading.splice(index, 1);
        }
      }

      newState = {
        ...state,
        deferReasons: action.payload,
        loading
      };

      storeState(newState);

      return newState;
    case AssessmentActions.ADD_ATTEMPT:
      taskAttempts = state.assessmentTaskEntries;
      taskAttempts.push(action.payload);

      newState = {
        ...state,
        assessmentTaskEntries: taskAttempts
      };

      storeState(newState);

      return newState;
    case AssessmentActions.ADD_LOGBOOK:
      entries = state.assessmentLogbooks;
      selectedAssessmentLogbookEntries = state.selectedAssessmentLogbookEntries;

      const index = entries.map(logbook => logbook.id).indexOf(action.payload.id);
      const selectedIndex = selectedAssessmentLogbookEntries.map(logbook => logbook.id).indexOf(action.payload.id);

      if (index > -1) {
        entries[index] = action.payload;
      } else {
        entries.push(action.payload);
      }

      if (selectedIndex > -1) {
        selectedAssessmentLogbookEntries[selectedIndex] = action.payload;
      } else {
        selectedAssessmentLogbookEntries.push(action.payload);
      }

      console.log(selectedAssessmentLogbookEntries, entries);

      newState = {
        ...state,
        assessmentLogbooks: entries,
        selectedAssessmentLogbookEntries
      };

      storeState(newState);

      return newState;
    case AssessmentActions.REMOVE_LOGBOOK:
      entries = state.assessmentLogbooks.filter(logbook => logbook.id !== action.payload);
      selectedAssessmentLogbookEntries = state.selectedAssessmentLogbookEntries.filter(logbook => logbook.id !== action.payload);

      newState = {
        ...state,
        assessmentLogbooks: entries,
        selectedAssessmentLogbookEntries
      };

      storeState(newState);

      return newState;
    case AssessmentActions.REMOVE_DEFERMENTS:
      console.log(state.deferments, action.payload);
      deferments = state.deferments.filter(deferment => action.payload.indexOf(deferment) === -1);

      newState = {
        ...state,
        deferments
      };

      storeState(newState);

      return newState;
    case AssessmentActions.REMOVE_ATTEMPTS:
      taskAttempts = state.assessmentTaskEntries.filter(task_attempt => action.payload.task_attempts.indexOf(task_attempt) === -1);

      newState = {
        ...state,
        assessmentTaskEntries: taskAttempts
      };

      storeState(newState);

      return newState;
    case AssessmentActions.REMOVE_ATTEMPT:
      taskAttempts = state.assessmentTaskEntries.filter(task_attempt => task_attempt.id !== action.payload);
      selectedTaskAttempts = [];
      if (state.selectedTaskToViewAttempts.length) {
        selectedTaskAttempts = state.selectedTaskToViewAttempts.filter(task_attempt => task_attempt.id !== action.payload);
      }
      newState = {
        ...state,
        assessmentTaskEntries: taskAttempts,
        selectedTaskToViewAttempts: selectedTaskAttempts
      };

      storeState(newState);

      return newState;
    case AssessmentActions.ADD_DEFERMENT:
      deferments = state.deferments;
      deferments.push(action.payload);

      newState = {
        ...state,
        deferments
      };

      storeState(newState);

      return newState;
    case AssessmentActions.SELECT_STUDENT:
      newState = {
        ...state,
        selectedStudent: action.payload
      };

      storeState(newState);
      console.log('SELECTED STUDENT', action.payload);

      return newState;
    case AssessmentActions.UPDATE_SELECTED_ASSESSMENT:
      newState = {
        ...state,
        selectedAssessment: action.payload.selectedAssessment,
        nextAssessmentOfType: action.payload.nextAssessmentOfType,
        previousAssessmentOfType: action.payload.previousAssessmentOfType,
      };

      storeState(newState);

      return newState;
    case AssessmentActions.SET_COURSE:
      selectedCourse = action.payload;
      console.log(selectedCourse);

      newState = {
        ...state,
        isCompleted: selectedCourse.is_completed,
        selectedCourse
      };

      storeState(newState);

      return newState;
    case AssessmentActions.SET_COURSE_WORKBOOK:
      selectedCourse = state.selectedCourse;
      selectedCourse.workbook = action.payload.workbook;
      selectedCourse.course.workbook = action.payload.workbook;
      newState = {
        ...state,
        selectedCourse: selectedCourse
      };

      storeState(newState);

      return newState;
    case AssessmentActions.SET_COURSE_COMPLETED:
      selectedCourse = state.selectedCourse;
      selectedCourse.is_completed = action.payload;

      newState = {
        ...state,
        isCompleted: action.payload,
        selectedCourse
      };

      storeState(newState);

      return newState;
    case AssessmentActions.CLEAR_SELECTED_COURSE:
      newState = {
        ...state,
        selectedCourse: null,
        assessmentTaskEntries: [],
        assessmentLogbooks: [],
        assessments: [],
        deferments: [],
        currentAssessmentDeferments: [],
        selectedAssessmentDeferments: [],
        selectedAssessmentDeferredTasks: [],
        selectedAssessment: null,
        nextAssessmentOfType: null,
        previousAssessmentOfType: null,
        assessmentProgress: null,
        selectedAssessmentLogbookEntries: [],
        isCompleted: false
      };

      storeState(newState);

      return newState;
    case AssessmentActions.CLEAR_SELECTED_STUDENT:
      newState = initialState;

      storeState(newState);

      return newState;
    case AssessmentActions.CLEAR_ASSESSMENT_INFO:
      newState = {
        ...state,
        currentAssessmentDeferments: [],
        selectedAssessmentDeferments: [],
        selectedAssessmentDeferredTasks: [],
        selectedAssessment: null,
        nextAssessmentOfType: null,
        previousAssessmentOfType: null,
        selectedAssessmentLogbookEntries: []
      };

      storeState(newState);

      return newState;
    case AssessmentActions.SET_USER_NAME:
      newState = {
        ...state,
        selectedUserName: action.payload
      };

      storeState(newState);

      return newState;

    case AssessmentActions.PUSH_LOADING:
      loading = state.loading;

      if (loading.indexOf(action.payload) === -1) {
        loading.push(action.payload);
      }

      newState = {
        ...state,
        loading
      };

      storeState(newState);

      return newState;
    case AssessmentActions.VIEW_TASK_DETAIL:
      const selectedTask = action.payload.task;
      console.log("Selected Task: ", selectedTask);
      selectedTaskAttempts = state.assessmentTaskEntries.filter(attempt => attempt.attempt_type.toString() === selectedTask.task_type.toString() && attempt.content_id === selectedTask.content_id && attempt.task_id === selectedTask.id);

      newState = {
        ...state,
        selectedTaskToView: selectedTask,
        selectedTaskToViewAttempts: selectedTaskAttempts
      };

      storeState(newState);

      return newState;
    default:
      if (localStorage.getItem('assessment')) {
        const storedState = JSON.parse(localStorage.getItem('assessment'));
        return storedState;
      }
      return state;
  }
}

export function storeState(newState) {
  localStorage.setItem('assessment', JSON.stringify(newState));
}
