import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: WysiwygEditorComponent,
    },
  ],
})
export class WysiwygEditorComponent implements OnDestroy, ControlValueAccessor {
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  editor: Editor;

  @Input() displayUploader: boolean;
  @Input() displayEditor: boolean;
  @Input() placeholder: String;
  @Input() editorId: String;
  @Input() spellcheck = true;
  @Input() title: string;
  @Input() text: String;
  @Input() url: string;
  @Input() subtext?: string;
  @Output() textChange = new EventEmitter();
  @Output() urlChange = new EventEmitter();

  private formOnChange = (_text: string) => {};
  private formOnTouched = () => {};

  private touched = false;

  constructor() {
    this.editor = new Editor();
  }

  handleTextChange(text: string) {
    if (text === '<p></p>') {
      text = '';
    }
    this.markAsTouched();
    this.textChange.emit(text);
    this.formOnChange(text);
  }

  handleUrl($event) {
    this.urlChange.emit($event);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  markAsTouched() {
    if (!this.touched) {
      this.formOnTouched();
      this.touched = true;
    }
  }

  registerOnChange(fn: any): void {
    this.formOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.formOnTouched = fn;
  }

  writeValue(text: string): void {
    this.text = text;
  }
}
