import { BreakpointObserver } from '@angular/cdk/layout';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Links } from '@app/models/links';
import { GlobalEventManagerService } from '@app/services/global-event-manager/global-event-manager.service';
import { ActivationStart, Router } from '@angular/router';
import { UserService } from '@app/services/data-services/user.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { IdentityService } from '@app/identity.service';
import { FeatureService } from '@app/feature.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, AfterViewInit {
  @Input() sideNav;
  @Input() notificPanel;
  @Input() notifications;
  @Input() type?: string;
  @Input() showPanel: boolean;

  learnToFly: string = Links.loggedOutFlyLink;
  fixFlight: string = Links.LoggedOutFixLink;
  private role: string;
  sub: Subscription = new Subscription();
  collapse!: boolean;

  @Output() emitCollapse = new EventEmitter<boolean>();
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  @Output() emitDismissHamburgerMenu = new EventEmitter<boolean>();

  showCTCDashboard = false;
  showInstructorDashboard = false;
  showAdminDashboard = false;
  isScreenTablet$!: any;
  isMobile$!: any;
  disableToggle!: boolean;
  instructorStudentsUrl = '/instructor/dashboard';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private userService: UserService,
    private globalEventManagerService: GlobalEventManagerService,
    private router: Router,
    route: ActivatedRoute,
    private identityService: IdentityService,
    private featureService: FeatureService,
  ) {
    router.events
      .pipe(filter(event => event instanceof ActivationStart))
      .subscribe((event: ActivationStart) => {
        const { showNavbar } = event.snapshot.data;
      });
  }

  ngOnInit() {
    const identitySubscription = this.identityService.identity.subscribe(
      identity => {
        this.showCTCDashboard = identity.ctcAdmin;
        this.showAdminDashboard = ['admin', 'super_admin'].includes(
          identity.role
        );
        this.showInstructorDashboard =
          identity.role === 'instructor' || this.showAdminDashboard;
      }
    );
    this.sub.add(identitySubscription);

    const studentsEnabledSubscription = this.featureService.isFeatureEnabled('my_students')
      .subscribe(studentsEnabled => {
        if (studentsEnabled) {
          this.instructorStudentsUrl = '/students';
        }
      });
    this.sub.add(studentsEnabledSubscription);

    this.isScreenTablet$ = this.breakpointObserver
      .observe('(max-width: 959px)')
      .pipe(
        map(({ matches }) => {
          return matches;
        })
      )
      .subscribe(isTablet => {
        this.disableToggle = isTablet;
        this.toggleCollapse(isTablet);
      });

    this.isMobile$ = this.breakpointObserver.observe('(max-width: 599px)').pipe(
      map(({ matches }) => {
        return matches;
      })
    );
  }

  ngAfterViewInit() {
    this.sub.add(
      this.globalEventManagerService.updatedNotifications.subscribe(value => {
        this.notifications = value;
      })
    );
  }

  isNavOver() {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

  isLoggedIn() {
    return localStorage.getItem('cirrus-token') != null;
  }

  toggleCollapse(isTablet?: boolean) {
    isTablet === undefined
      ? (this.collapse = !this.collapse)
      : (this.collapse = isTablet);
    this.emitCollapse.emit(this.collapse);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  dismissHamburgerMenu(isMobile?: boolean) {
    if (isMobile === undefined) {
      this.emitDismissHamburgerMenu.emit(!this.showPanel);
    }
  }
}
