<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Manage Certificate</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for="certificate-name" class="form-label">Name</label>
            <input id="certificate-name" name="certificate-name" class="form-input" [(ngModel)]="certificate.name" placeholder="Name">
          </div>
          <div class="form-row">
            <label for="certificate-desc" class="form-label">Description</label>
            <textarea id="certificate-desc" name="certificate-desc" class="form-textarea" [(ngModel)]="certificate.desc" placeholder="Description"></textarea>
          </div>
          <div class="form-row">
            <label for="certificate-expire" class="form-label">Days to Expire</label>
            <input id="certificate-expire" name="certificate-expire" class="form-input" [(ngModel)]="certificate.days_to_expire" placeholder="Days to Expire">
          </div>
          <div class="form-row">
            <label for="image-upload" class="form-label">Certificate Image</label>
            <div>
              <img id="certificate-img" crossOrigin="Anonymous" *ngIf="certificate?.certificate_image && imageUploader.value == ''" src="{{certificate?.certificate_image}}" alt="" width="325" height="175">
              <img id="content-img" #imageUpload crossOrigin="Anonymous"  src="#" width="325" height="175" alt="" hidden>
            </div>
            <input id="image-upload" #imageUploader type='file' name="image-upload" appAppUploaderPreview [image]="imageUpload" class="form-input" itemid="image-upload" ng2FileSelect [uploader]="uploader">
          </div>
          <div class="form-row">
            <button class="btn cancel-button"  (click)="download()">Preview Template</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="upload()">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
