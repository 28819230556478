<div fxFlex="100" fxLayout="row" style="background: #efefef">
  <div
    class="editor-wrapper"
    fxLayout="row wrap"
    fxFlex="grow"
    style="margin: 0 10px"
    #editor
  >
    <mat-card class="p-0" fxFlex="grow" [formGroup]="courseForm">
      <mat-card-title>
        <div class="card-title-text">
          {{ !editing ? 'Add' : 'Edit' }} Course
        </div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <mat-tab-group fxFlex="grow" class="p-05">
          <mat-tab label="General">
            <div class="general-container">
              <div class="label-row">
                <label for="name" class="bold-text">Course Title* </label
                ><span class="sub-input-text">(up to 60 characters)</span>
              </div>
              <div class="form-row">
                <input
                  id="course-title"
                  name="course-title"
                  class="form-input"
                  formControlName="title"
                />
              </div>
              <div
                *ngIf="
                  formControl.title.dirty &&
                  formControl.title.errors &&
                  formControl.title.errors['maxlength']
                "
              >
                <div class="error">Exceed length of 60.</div>
              </div>
              <div
                *ngIf="
                  formControl.title.touched &&
                  formControl.title.errors &&
                  formControl.title.errors['required']
                "
              >
                <div class="error">Title is required.</div>
              </div>

              <div class="label-row">
                <label for="name" class="bold-text">Course Subtitle </label
                ><span class="sub-input-text">(up to 70 characters)</span>
              </div>
              <div class="form-row">
                <input
                  id="subtitle"
                  name="subtitle"
                  class="form-input"
                  formControlName="subtitle"
                />
              </div>
              <div
                *ngIf="
                  formControl.subtitle.dirty &&
                  formControl.subtitle.errors &&
                  formControl.subtitle.errors['maxlength']
                "
              >
                <div class="error">Exceed length of 70.</div>
              </div>
              <div class="form-row overview-wrapper label-row">
                <app-wysiwyg-editor
                  editorId="course-overview-description"
                  [displayUploader]="true"
                  [displayEditor]="true"
                  formControlName="overview"
                  [title]="'Course Overview Description*'"
                ></app-wysiwyg-editor>
              </div>

              <div class="form-row overview-wrapper label-row">
                <label class="sales-description-label">Sales Description</label>
                <app-wysiwyg-editor
                  editorId="sales-description"
                  [displayUploader]="false"
                  [displayEditor]="true"
                  formControlName="sales_desc"
                  [title]="'Sales Description*'"
                ></app-wysiwyg-editor>
              </div>

              <div class="course-intro-video-wrapper">
                <label class="sales-description-label"
                  >Course Intro Video</label
                >
                <div>
                  <button class="primary-btn" (click)="addContent()">
                    {{
                      contentTitleDisplay
                        ? 'Replace Course Intro Video'
                        : 'Add Course Intro Video'
                    }}
                  </button>
                </div>
                <div class="video-title" *ngIf="contentTitleDisplay">
                  Current Video: {{ contentTitleDisplay }}
                  <span
                    (click)="removeCourseOverviewVideo()"
                    ><p class="close-icon">&times;</p></span
                  >
                </div>
              </div>

              <div class="form-row pb-1">
                <app-uploader
                  editorId="desktop_hero_image_url"
                  [subtext]="'2348 x 1112px'"
                  (response_url)="setUrl($event, 'desktop_hero_image_url')"
                  [url]="currentDesktopUrl"
                  title="Desktop Hero Image"
                  [uploader_only]="true"
                  [requiredImageWidth]="2348"
                  [requiredImageHeight]="1112"
                ></app-uploader>
              </div>

              <div class="form-row pb-1">
                <app-uploader
                  editorId="mobile_hero_image_url"
                  [subtext]="'750 x 536px'"
                  (response_url)="setUrl($event, 'mobile_hero_image_url')"
                  [url]="currentMobileUrl"
                  title="Mobile Hero Image"
                  [uploader_only]="true"
                  [requiredImageWidth]="750"
                  [requiredImageHeight]="536"
                ></app-uploader>
              </div>

              <div class="form-row pb-1">
                <app-uploader
                  editorId="thumbnail_image_url"
                  [subtext]="'650 x 350px'"
                  (response_url)="setUrl($event, 'thumbnail_image_url')"
                  [url]="currentThumbnailUrl"
                  title="Thumbnail Image"
                  [uploader_only]="true"
                  [requiredImageWidth]="650"
                  [requiredImageHeight]="350"
                ></app-uploader>
              </div>

              <div>* = required text</div>

              <mat-accordion id="course-settings-accordion">
                <div class="course-settings-title">
                  <h1 class="card-title-text">Course Settings</h1>
                </div>

                <div class="form-row label-row">
                  <label for="course-name" class="bold-text">System Name</label>
                  <input
                    id="course-name"
                    name="course-name"
                    class="form-input"
                    formControlName="name"
                    placeholder="Name"
                  />
                </div>
                <div class="form-row label-row">
                  <label for="course-desc" class="bold-text"
                    >System Description</label
                  >
                  <textarea
                    id="course-desc"
                    name="course-desc"
                    class="form-textarea"
                    formControlName="desc"
                    placeholder="Description"
                  ></textarea>
                </div>
                <div class="form-row label-row">
                  <label for="course-created-by" class="bold-text"
                    >Created By</label
                  >
                  <input
                    id="course-created-by"
                    name="course-created-by"
                    class="form-input"
                    formControlName="created_by"
                    type="text"
                  />
                </div>

                <div class="label-row">
                  <label for="name" class="bold-text"
                    >Course Completion Time Estimate
                    <span class="helper-text">Format: 22h 15m</span>
                  </label>
                </div>
                <div class="form-row">
                  <input
                    id="completion_time"
                    name="completion_time"
                    class="form-input"
                    formControlName="completion_time"
                  />
                </div>

                <div class="label-row">
                  <label for="name" class="bold-text"
                    >Minimum Flight Time Requirement
                    <span class="helper-text"
                      >Format whole number only: 22</span
                    >
                  </label>
                </div>
                <div class="form-row">
                  <input
                    id="minimum_flight_hours"
                    name="minimum_flight_hours"
                    class="form-input"
                    formControlName="minimum_flight_hours"
                  />
                </div>

                <div class="form-row label-row">
                  <mat-form-field appearance="fill">
                    <mat-label for="course_categories" class="form-label"
                      >Category</mat-label
                    >
                    <mat-select
                      id="course_categories"
                      formControlName="course_categories"
                      name="course_categories"
                      [compareWith]="compareCategoryObjects"
                      multiple
                    >
                      <mat-option
                        *ngFor="let category of courseCategories"
                        [value]="category"
                      >
                        {{ category.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="form-row">
                  <label class="form-label">Associated Aircraft Types</label>
                  <div class="full-width">
                    <button
                      mat-raised-button
                      color="primary"
                      (click)="addAircraft()"
                    >
                      Select Aircraft
                    </button>
                  </div>
                  <div class="full-width mt-1 pb-1" *ngIf="aircrafts.length">
                    <mat-chip-list>
                      <mat-chip
                        *ngFor="let aircraft of aircrafts.controls; index as i"
                        (removed)="rmAir(i)"
                        [removable]="true"
                      >
                        {{ aircraft.value.name }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </div>

                <div class="form-row">
                  <label class="form-label"
                    >Course Allows Student to re-enroll</label
                  >
                  <mat-slide-toggle
                    (change)="changeReEnrollState()"
                    [checked]="canReEnroll().value"
                    [color]="'#0087ce'"
                    name="course_reenroll"
                    id="course-reenroll"
                  >
                    {{ course.can_reenroll ? 'On' : 'Off' }}</mat-slide-toggle
                  >
                </div>
                <div class="form-row">
                  <label class="form-label">Badge</label>
                  <mat-slide-toggle
                    (change)="changeBadgeState()"
                    [checked]="has_badge"
                    [color]="'#0087ce'"
                    name="course_badge"
                    id="course-badge"
                  >
                    {{ has_badge ? 'On' : 'Off' }}</mat-slide-toggle
                  >
                  <div class="form-row" *ngIf="has_badge">
                    <span class="flx" *ngIf="badge && badge.value">
                      <img
                        src="{{ badge.value.badge_image }}"
                        width="75"
                        height="75"
                        alt=""
                      />
                      <mat-icon (click)="rmBadge()" class="remove-item"
                        >remove_circle_outline</mat-icon
                      >
                    </span>
                    <div class="full-width pb-1">
                      <button
                        mat-raised-button
                        color="primary"
                        (click)="addBadge()"
                      >
                        Select Badge
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <label class="form-label">Certificate</label>
                  <mat-slide-toggle
                    (change)="changeCertState()"
                    [checked]="has_certificate"
                    [color]="'#0087ce'"
                    id="course-certificate"
                    >{{ has_certificate ? 'On' : 'Off' }}</mat-slide-toggle
                  >
                  <div class="form-row" *ngIf="has_certificate">
                    <span class="flx" *ngIf="certificate && certificate.value">
                      <img
                        src="{{ certificate.value.certificate_image }}"
                        width="100"
                        height="75"
                        alt=""
                      />
                      <mat-icon (click)="rmCert()" class="remove-item"
                        >remove_circle_outline</mat-icon
                      >
                    </span>
                    <div class="full-width pb-1">
                      <button
                        mat-raised-button
                        color="primary"
                        (click)="addCertificate()"
                      >
                        Select Certificate
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <label class="form-label">Course Agreement</label>
                  <mat-slide-toggle
                    id="has-agreement"
                    name="has-agreement"
                    formControlName="has_agreement"
                    >{{ hasAgreement ? 'On' : 'Off' }}</mat-slide-toggle
                  >
                  <div class="form-row" *ngIf="hasAgreement">
                    <app-wysiwyg-editor
                      editorId="course-overview-description"
                      [displayEditor]="true"
                      placeholder="Enter text here..."
                      formControlName="agreement_body"
                      title="Course"
                    ></app-wysiwyg-editor>
                  </div>
                </div>
              </mat-accordion>
              <mat-accordion id="store-settings-accordion">
                <h1 class="card-title-text">Store Configuration</h1>
                <div class="form-row label-row">
                  <label class="form-label bold-text">Display in Store</label>
                  <mat-slide-toggle
                    formControlName="is_ecommerce_enabled"
                    [color]="'#0087ce'"
                    name="course_badge"
                  >
                    <span>{{ isCommerceEnabled ? 'On' : 'Off' }}</span>
                    <span class="not-active-info"
                      >If turned off, users will not be able to purchase course
                      in the course catalog.</span
                    >
                  </mat-slide-toggle>
                </div>
                <div class="form-row label-row">
                  <label for="course-price" class="form-label bold-text"
                    >Course Price</label
                  >
                  <input
                    id="course-price"
                    name="course-price"
                    class="form-input"
                    formControlName="list_price"
                  />
                </div>
                <div class="form-row label-row">
                  <label for="course-shipping" class="form-label bold-text"
                    >Shipping Price</label
                  >
                  <input
                    id="course-shipping"
                    name="course-shipping"
                    class="form-input"
                    formControlName="shipping_price"
                    value="{{ shippingPrice }}"
                  />
                </div>
                <div class="form-row label-row">
                  <label for="course-token" class="form-label bold-text"
                    >Fulfillment Part Number</label
                  >
                  <input
                    id="course-token"
                    name="course-token"
                    class="form-input"
                    formControlName="fulfillment_part_number"
                  />
                </div>
                <div class="form-row label-row">
                  <label for="course-tax" class="form-label bold-text"
                    >Tax Code</label
                  >
                  <input
                    id="course-tax"
                    name="course-tax"
                    class="form-input"
                    formControlName="tax_code"
                  />
                </div>
              </mat-accordion>
            </div>
          </mat-tab>

          <mat-tab label="Workbook">
            <div
              *ngIf="editing"
              layout="row"
              layout-padding
              layout-wrap
              layout-fill
              class="alert alert-warning alert-dismissible"
            >
              <div md-whiteframe="3" class="padded">
                <h4 class="alert-heading">Warning</h4>
                <p>
                  Adding, removing, or reordering lesson or stage item(s) will
                  require a new version update.
                </p>
              </div>
            </div>
            <mat-card class="bg-white material">
              <mat-card-title>
                <div class="card-title-text">Course Workbook</div>
                <mat-divider></mat-divider>
              </mat-card-title>
              <mat-card-content>
                <div
                  class="wrapper"
                  cdkDropList
                  (cdkDropListDropped)="dropStage($event)"
                  *ngIf="workbookStages"
                >
                  <div
                    class="dnd-item stage-item alert group-handle"
                    cdkDrag
                    *ngFor="
                      let stage of workbookStages.controls;
                      let stageIndex = index
                    "
                  >
                    <div
                      class="dnd-wrapper group-handle full-width"
                      fxLayout="row"
                    >
                      <div
                        class="drag-controller"
                        fxFlex="3"
                        fxFlexAlign="center"
                        fxLayout="row"
                      >
                        <mat-icon mat-list-icon class="group-handle"
                          >format_align_justify</mat-icon
                        >
                      </div>
                      <div
                        class="dnd-details group-handle"
                        fxLayout="column"
                        fxFlex="94"
                      >
                        <div class="dnd-type group-handle">Stage</div>
                        <div class="dnd-title-wrapper group-handle">
                          {{ stageIndex + 1 }}. {{ stage.value.title }}
                        </div>
                        <div
                          class="lesson-list"
                          cdkDropList
                          (cdkDropListDropped)="drop($event, stageIndex)"
                        >
                          <div
                            *ngFor="
                              let lesson of stage.controls.lessons.controls;
                              let lessonIndex = index
                            "
                            cdkDrag
                            class="dnd-item lesson-item lesson-handle alert"
                          >
                            <div
                              fxLayout="row"
                              fxFlexAlign="center"
                              class="dnd-wrapper lesson-handle full-width"
                            >
                              <div
                                class="drag-controller"
                                mat-line
                                fxFlex="3"
                                fxLayout="row"
                                fxFlexAlign="center"
                              >
                                <mat-icon
                                  mat-list-icon
                                  class="drag-icon lesson-handle"
                                  >format_align_justify</mat-icon
                                >
                              </div>
                              <div
                                class="dnd-details lesson-handle"
                                fxLayout="column"
                                fxFlex="95"
                                fxFlexAlign="center"
                              >
                                <div class="dnd-type lesson-handle">
                                  Lesson ({{
                                    lessonTypeMap[lesson.value.lesson_type]
                                  }})
                                </div>
                                <div class="dnd-title-wrapper lesson-handle">
                                  {{ lessonIndex + 1 }}.
                                  {{ lesson.value.title }}
                                </div>
                                <div
                                  class="dnd-description lesson-handle"
                                  mat-line
                                >
                                  {{ lesson.value.system_desc }}
                                </div>
                                <mat-expansion-panel
                                  class="contents-list"
                                  *ngIf="lesson.value.contents.length"
                                >
                                  <mat-expansion-panel-header>
                                    <mat-panel-title>
                                      <h5 style="margin: 0.5rem 0">
                                        Content Items ({{
                                          lesson.value.contents.length
                                        }})
                                      </h5>
                                    </mat-panel-title>
                                  </mat-expansion-panel-header>
                                  <div
                                    class="content-item dnd-item alert"
                                    *ngFor="
                                      let content of lesson.value.contents;
                                      let contentIndex = index
                                    "
                                  >
                                    <div
                                      fxLayout="row"
                                      fxFlexAlign="center"
                                      class="content-wrapper full-width"
                                    >
                                      <div
                                        class="dnd-details"
                                        fxLayout="column"
                                        fxFlex="94"
                                        fxFlexAlign="center"
                                      >
                                        <div class="dnd-type">
                                          {{
                                            contentTypeMap[content.content_type]
                                          }}
                                        </div>
                                        <div class="dnd-title-wrapper">
                                          {{ contentIndex + 1 }}.
                                          {{ content.title }}
                                        </div>
                                        <div class="dnd-description">
                                          {{ content.desc }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </mat-expansion-panel>
                              </div>
                              <div
                                class="dnd-actions"
                                fxFlex="2"
                                fxLayout="row"
                              >
                                <mat-icon
                                  class="delete-icon"
                                  (click)="
                                    deleteWorkbookItem(
                                      'lesson',
                                      stageIndex,
                                      lessonIndex
                                    )
                                  "
                                  >close</mat-icon
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          mat-raised-button
                          class="add-item"
                          fxFlexAlign="flex-start"
                          [color]="'primary'"
                          (click)="addLesson(stage, stageIndex)"
                          mat-line
                        >
                          Add Lesson
                        </button>

                        <div class="form-row">
                          <label class="form-label">Certificate</label>
                          <div class="form-row" >
                            <span class="flx" *ngIf="stage?.value?.certificate">
                              <img
                                src="{{ stage.value.certificate.certificate_image }}"
                                width="100"
                                height="75"
                                alt=""
                              />
                              <mat-icon (click)="rmCert(stage.value.id)" class="remove-item"
                                >remove_circle_outline</mat-icon
                              >
                            </span>
                            <div class="full-width pb-1">
                              <button
                                mat-raised-button
                                color="primary"
                                (click)="addStageCertificate(stage)"
                              >
                                Select Certificate
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="dnd-actions" fxFlex="3" fxLayout="row">
                        <mat-icon
                          class="edit-icon"
                          (click)="openStageEditor('Edit', stage, stageIndex)"
                          >edit</mat-icon
                        >
                        <mat-icon
                          class="delete-icon"
                          (click)="deleteWorkbookItem('stage', stageIndex)"
                          >close</mat-icon
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  mat-raised-button
                  class="add-item"
                  [color]="'primary'"
                  (click)="openStageEditor('Add', null, null)"
                >
                  Add Stage
                </button>
              </mat-card-content>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
  <div
    id="submit-bar"
    class="sidebar-panel navigation-hold"
    @slideInRight
    fxFlex="nogrow"
    style="margin: 0 10px"
  >
    <mat-card class="bg-white material">
      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      <mat-card-title>
        <div class="card-title-text" style="font-weight: bold">
          Course Status
        </div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="form-row">
          <label for="course-active" class="form-label"
            ><strong>Status: </strong>
            <mat-slide-toggle
              [color]="'#0087ce'"
              id="course-active"
              [checked]="isActive.value"
              (change)="courseForm.patchValue({ is_active: !isActive.value })"
            >
              <span>{{ active }}</span>
            </mat-slide-toggle>
          </label>
        </div>
        <div class="editing" *ngIf="editing">
          <div class="last-modified">
            <strong>Last modified on:</strong> {{ updated | date }}<br />
            @ {{ updated | date : 'shortTime' }}
          </div>
          <div class="version-number">
            <strong>Version:</strong>
            {{ majorVersion.value + '.' + minorVersion.value }}
          </div>
          <mat-checkbox
            [color]="'primary'"
            [(ngModel)]="setNewVersion"
            [checked]="forceNewVersion || setNewVersion"
            [disabled]="forceNewVersion"
            >Save as new version</mat-checkbox
          >
        </div>
      </mat-card-content>
      <mat-card-footer fxLayout="row">
        <button mat-raised-button [color]="'danger'" (click)="cancel()">
          Cancel
        </button>
        <button
          mat-raised-button
          [color]="'primary'"
          (click)="saveCourse()"
          [disabled]="isSaving || courseForm.invalid"
        >
          {{ isSaving ? 'Saving...' : 'Save' }}
        </button>
      </mat-card-footer>
    </mat-card>
    <div class="pt-1 ml-1">
      *Course Title must not be blank, and Course Overview Description must have
      at least 20 characters to save.
    </div>
  </div>
</div>
