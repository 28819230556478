<div class="training-centers__table-wrapper mat-elevation-z8">
  <table
    class="training-centers__table"
    mat-table
    [dataSource]="trainingPartners"
    multiTemplateDataRows
  >
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let row">
        {{ row.trainingPartner.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let row">
        {{ row.trainingPartner.location }}
      </td>
    </ng-container>

    <ng-container matColumnDef="airport">
      <th mat-header-cell *matHeaderCellDef>Airport</th>
      <td mat-cell *matCellDef="let row">
        {{ row.trainingPartner.airport }}
      </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let row">
        {{ row.trainingPartner.phone }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let row">
        <div
          *ngIf="
            row.trainingPartner?.type === 'Instructor' ||
            row.trainingPartner?.connectable?.type === 'Instructor'
          "
        >
          <td
            matTooltip="Cirrus Standardized Instructor Pilot"
            style="text-align: left; padding: 0px; border: none"
          >
            CSIP
          </td>
        </div>
        <div
          *ngIf="
            row.trainingPartner?.type === 'Account' ||
            row.trainingPartner?.connectable?.type === 'Account'
          "
        >
          <td
            matTooltip="Cirrus Training Center"
            style="text-align: left; padding: 0px; border: none"
          >
            CTC
          </td>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="rowAction">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <button
          mat-stroked-button
          [disabled]="row.action.disabled"
          (click)="handleAction(row)"
        >
          {{ row.action.buttonLabel }}
        </button>
      </td>
    </ng-container>
    <tr
      mat-header-row
      class="training-centers__row"
      *matHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      mat-row
      class="training-centers__row"
      *matRowDef="let row; columns: displayedColumns"
    ></tr>
    <tr *matNoDataRow class="training-centers__row">
      {{
        noRowsMessage
      }}
    </tr>
  </table>
</div>
