<app-flight-card [card]="data" [isDialog]="true">
  <div class="dialog-content-wrapper"  fxLayout="wrap">
    <!-- "GENERAL" Section -->

    <div class="general" fxFlex="100" fxLayout="row wrap">
      <h3 class="flight-card-content-title" fxFlex="100">GENERAL</h3>
      <div class="general-content-wrapper" fxFlex="100" fxLayout="wrap">
        <div class="flex-col" fxFlex="60" fxLayout="column">
          <div class="date-wrapper">
            <p class="flight-card-content-data-title">Date</p>
            <p>{{data?.created_at | date:'MM/dd/yy'}}</p>
          </div>
          <div class="from-to-wrapper" fxFlex fxLayout="row">
            <div fxFlex="50">
              <p class="flight-card-content-data-title">From</p>
              <p>{{data?.fromAirport}}</p>
            </div>
            <div fxFlex="50">
              <p class="flight-card-content-data-title">To</p>
              <p>{{data?.toAirport}}</p>
            </div>
          </div>
          <div class="route-wrapper">
            <p class="flight-card-content-data-title">Route</p>
            <p>{{data?.routePath}}</p>
          </div>
        </div>
        <div class="flex-col" fxFlex="40" fxLayout="column">
          <div class="aircraft-make-wrapper">
            <p class="flight-card-content-data-title">Aircraft Make</p>
            <p>Cirrus</p>
          </div>
          <div class="aircraft-model-wrapper">
            <p class="flight-card-content-data-title">Aircraft Model</p>
            <p>SR22</p>
          </div>
          <div class="aircraft-id-wrapper">
            <p class="flight-card-content-data-title">Aircraft ID</p>
            <p>{{data?.aircraft_tail_id}}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- "TIME" Section -->
    <div class="time" fxFlex="100" fxLayout="row wrap">
      <h3 class="flight-card-content-title" fxFlex="100">TIME</h3>
      <div class="time-content-wrapper" fxFlex="100">
        <p>{{data?.hobbsOut ? data?.hobbsOut : 0}}<span class="flight-card-content-data-title"> Hobbs Out</span></p>
        <p>{{data?.hobbsIn ? data?.hobbsIn : 0}}<span class="flight-card-content-data-title"> Hobbs In</span></p>
        <p>{{data?.completedGroundInstructionHours ? data?.completedGroundInstructionHours : 0}}<span class="flight-card-content-data-title"> Ground Instruction</span></p>
        <p>{{data?.completedTotalHours ? data?.completedTotalHours : 0}}<span class="flight-card-content-data-title"> Total Flight</span></p>
        <p>{{data?.completedSimulatedInstrumentHours ? data?.completedSimulatedInstrumentHours : 0}}<span class="flight-card-content-data-title"> Simulator</span></p>
        <p>{{data?.completedDualReceivedHours ? data?.completedDualReceivedHours : 0}}<span class="flight-card-content-data-title"> Dual Received</span></p>
        <p>{{data?.completedCrossCountryHours ? data?.completedCrossCountryHours : 0}}<span class="flight-card-content-data-title"> Cross Country</span></p>
        <p>{{data?.completedCrossCountryLegs ? data?.completedCrossCountryLegs : 0}}<span class="flight-card-content-data-title"> Cross Country Legs</span></p>
        <p>{{data?.completedNightHours ? data?.completedNightHours : 0}}<span class="flight-card-content-data-title"> Night</span></p>
        <p>{{data?.completedSoloHours ? data?.completedSoloHours : 0}}<span class="flight-card-content-data-title"> Solo</span></p>
        <p>{{data?.completedPICHours ? data?.completedPICHours : 0}}<span class="flight-card-content-data-title"> PIC</span></p>
      </div>
    </div>

    <!-- "CONDITIONS" Section -->
    <div class="conditions" fxFlex="100" fxLayout="row wrap">
      <h3 class="flight-card-content-title" fxFlex="100">CONDITIONS</h3>
      <div class="conditions-content-wrapper" fxFlex="100">
        <p>{{data?.completedAdjustmentHours ? data?.completedAdjustmentHours : 0}}<span class="flight-card-content-data-title"> Actual Adjustments</span></p>
        <p>{{data?.completedSimulatedInstrumentHours ? data?.completedSimulatedInstrumentHours : 0}}<span class="flight-card-content-data-title"> Simulated Instrument</span></p>
        <p>{{data?.completedTrackAidHours ? data?.completedTrackAidHours : 0}}<span class="flight-card-content-data-title"> Track Nav Aid</span></p>
        <p>{{data?.completedHoldingPatterns ? data?.completedHoldingPatterns : 0}}<span class="flight-card-content-data-title"> Holding Patterns</span></p>
        <p>{{data?.completedNightLandings ? data?.completedNightLandings : 0}}<span class="flight-card-content-data-title"> Night Landings</span></p>
        <p>{{data?.completedNightTakeoffs ? data?.completedNightTakeoffs : 0}}<span class="flight-card-content-data-title"> Night Takeoffs</span></p>
        <p>{{data?.completedDayLandings ? data?.completedDayLandings : 0}}<span class="flight-card-content-data-title"> Day Landings</span></p>
        <p>{{data?.completedDayTakeoffs ? data?.completedDayTakeoffs : 0}}<span class="flight-card-content-data-title"> Day Takeoffs</span></p>
        <p>{{data?.approaches ? data?.approaches : 0}}<span class="flight-card-content-data-title"> Approaches</span></p>
      </div>
    </div>

    <div class="comment-body">
      {{data?.comment_body}}
    </div>
  </div>
</app-flight-card>
