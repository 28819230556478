<div class="assessment-wrapper">
    <div class="assessment-type">
      <P>{{content.content_type == 9 ? "FLIGHT ASSESSMENT": "GROUND ASSESSMENT"}}</P>
    </div>
    <div class="assessment-title">
        <!--<a class="content-status">{{contentIsComplete ? "Complete": "In Progress"}}</a>-->
        <h3 class="course-title">{{content.title}}</h3>
    </div>

    <div fxLayout="wrap"
         fxLayout.sm="row"
         fxLayout.xs="row"
         fxLayoutAlign="space-between"
         fxLayoutGap="20px">

        <div
                fxLayoutGap="0px"
                fxLayout="column"
                fxLayoutAlign="left">
            <p class="header-fa">Course</p>
            <p>{{currentCourse.course.title}}</p>
        </div>

      <div
                fxLayoutGap="0px"
                fxLayout="column"
                fxLayoutAlign="left">
            <p class="header-fa">Stage</p>
            <p>{{stage.title}}</p>
        </div>
        <div
                fxLayoutGap="0px"
                fxLayout="column"
                fxLayoutAlign="left">
            <p class="header-fa">Lesson</p>
            <p>{{lesson.title}}</p>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="full-width">
        <div class="full-width">
            <p class="section-label">Tasks</p>
        </div>
      <div fxLayout="wrap">
        <div fxFlex="100"
             fxFlex.sm="100"
             fxFlex.xs="100" class="assessment-table-wrapper">
          <table>
            <thead>
            <th class="thead-left">Task</th>
            <th>Type</th>
            <th>Required</th>
            <th *ngIf="content.jet_scoring">Perfect Score</th>
            <th>Passed</th>
            <th>Missed</th>
            <th class="thead-right">Status</th>
            </thead>
            <tbody>
            <tr *ngFor="let row of rows">
              <td class="row-stage-name">{{ row.name }}</td>
              <td>{{ row.task_type }}</td>
              <td class="center-data">{{ row.required_successful_attempts }}</td>
              <td class="center-data">{{ row.passed }}</td>
              <td class="center-data">{{ row.missed }}</td>
              <td class="center-data" *ngIf="row.jet_scoring">{{ row.perfect }}</td>
              <td>{{ row.status }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
<!--
      <td *ngIf="content_task.task_type && content_task.task_type.toString() === 'perform'">
        {{getDeferred(content_task.id, content.id, lesson.id, content_task.task_type) ? 'Deferred' : getPassed(content_task.id, content.id) >= content_task.required_successful_attempts ? "Completed": getPassed(content_task.id, content.id) > 0 ? "In Progress" : 'Not Started'}}</td>
      <td *ngIf="content_task.task_type && content_task.task_type.toString() === 'practice'">
        {{(getDeferred(content_task.id, content.id, lesson.id, content_task.task_type) ? 'Deferred' : (getPassed(content_task.task_id, content.id) > 0 || getMissed(content_task.task_id, content.id) > 0 || content.jet_scoring && getPerfect(content_task.task_id, content.id )) ? 'Completed' : 'Not Started')}}
      </td>
-->

      <!--<mat-accordion id="lesson-quizzes-accordion">-->
            <!--<mat-expansion-panel [disabled]="true" class="lessons-header">-->
                <!--<mat-expansion-panel-header>-->
                    <!--<div class="full-width inline-items inline-wrapper">-->
                        <!--<h5 class="header-row-name">Task</h5>-->
                        <!--<h5 class="header-row-cat">Type</h5>-->
                        <!--<h5 class="header-row-attempts">Required</h5>-->
                        <!--<h5 class="header-row-passed">Passed</h5>-->
                        <!--<h5 class="header-row-missed">Missed</h5>-->
                        <!--<h5 class="header-row-status">Status</h5>-->
                    <!--</div>-->
                <!--</mat-expansion-panel-header>-->
            <!--</mat-expansion-panel>-->
            <!--<ng-container>-->
                <!--<mat-expansion-panel [disabled]="true" *ngFor="let content_task of content.content_tasks">-->
                    <!--<mat-expansion-panel-header>-->
                        <!--<div class="full-width inline-items inline-wrapper">-->
                            <!--<h5 class="row-stage-name">{{content_task.task.name}}</h5>-->
                            <!--<h5 class="row-stage-cat">{{content_task.task.task_category.name}}</h5>-->
                            <!--<h5 class="row-stage-attempts">{{content_task.required_successful_attempts}}</h5>-->
                            <!--<h5 class="row-stage-passed">{{getPassed(content_task.task.id, content.id)}}</h5>-->
                            <!--<h5 class="row-stage-missed">{{getMissed(content_task.task.id, content.id)}}</h5>-->
                            <!--<h5 class="row-stage-status">{{getPassed(content_task.task.id, content.id) ===-->
                                <!--content_task.required_successful_attempts ? "Completed": "In Progress"}}</h5>-->
                        <!--</div>-->
                    <!--</mat-expansion-panel-header>-->
                <!--</mat-expansion-panel>-->
            <!--</ng-container>-->

          <!--<mat-accordion id="lesson-quizzes-accordion">-->
            <!--<div fxLayout="column" class="logbook">-->
                <!--<p class="section-label">Logbook</p>-->
                <!--<mat-grid-list [cols]="2" gutterSize="34" [rowHeight]="'2:2'">-->
                    <!--<div *ngFor="let card of lessonFlightLogs">-->
                        <!--<mat-grid-tile>-->
                            <!--<app-flight-card [canClick]="false" [card]="card"></app-flight-card>-->
                        <!--</mat-grid-tile>-->
                    <!--</div>-->
                <!--</mat-grid-list>-->
            <!--</div>-->
          <!--</mat-accordion>-->
      <div class="full-width" *ngIf="lessonFlightLogs.length < 1">
        <p class="section-label">There are currently no logbook entries.</p>
      </div>
      <div *ngFor="let lesson of lessonFlightLogs">
        <div class="full-width">
          <p class="section-label">Logbook Entry {{lessonFlightLogs.indexOf(lesson) + 1}}</p>
        </div>
        <div fxLayout="wrap">
          <div fxFlex="100"
               fxFlex.sm="100"
               fxFlex.xs="100">
            <table class="">
              <thead>
              <th class="thead-left">LOG ID</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th class="logbook-thead-right">
                <p class="margin-bottom-none">{{lesson.id}}</p>
              </th>
              </thead>
              <tbody>
              <!--<tr *ngFor="let content_task of content.content_tasks">-->
              <!--<td class="row-stage-name">{{content_task.task.name}}</td>-->
              <!--<td>{{content_task.task.task_category.name}}</td>-->
              <!--<td>{{content_task.required_successful_attempts}}</td>-->
              <!--<td>{{getPassed(content_task.task.id, content.id)}}</td>-->
              <!--<td>{{getMissed(content_task.task.id, content.id)}}</td>-->
              <!--<td>{{getPassed(content_task.task.id, content.id) ===-->
              <!--content_task.required_successful_attempts ? "Completed": "In Progress"}}</td>-->
              <!--</tr>-->
              <tr class="log-entry">
                <td>DATE</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.lesson_flight_log_date | date: 'MM/dd/yyyy'}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>MAKE</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.aircraft_make}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>MODEL</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.aircraft_model}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>ID</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.aircraft_tail_id}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>TO</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.toAirport}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>FROM</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.fromAirport}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>TOTAL FLT</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.completedTotalHours}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>TOTAL SIM</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.completedSimHours}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>TOTAL GND</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-data">
                  <p class="margin-bottom-none">{{lesson?.completedGroundInstructionHours}}</p>
                </td>
              </tr>
              <tr class="log-entry">
                <td>INSTRUCTOR</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="logbook-instructor-name logbook-data">
                  <p class="margin-bottom-none">{{lesson?.instructor_object?.user.contact.name}}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="full-width" *ngIf="lesson?.comment_body">
            <p class="section-label remarks-title">Remarks</p>
          </div>
          <p class="remarks-body" *ngIf="lesson?.comment_body">{{lesson?.comment_body}}</p>
        </div>
      </div>
    </div>
</div>
