import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserManagerDialogComponent } from './user-manager-dialog/user-manager-dialog.component';
import { UserRole, UserRoleMap } from '@app/models/user/user-role';
import { UserService } from '@app/services/data-services/user.service';
import { CourseService } from '@app/services/data-services/course.service';
import { NotificationService } from '@app/services/data-services/notification.service';
import { InstructorService } from '@app/services/data-services/instructor.service';
import { Notification } from '@app/models/notification/notification';
import { User } from '@app/models/user/user';
import { ManageConnectionsDialogComponent } from '@app/components/training-centers/connections/manage-connections-dialog/manage-connections-dialog.component';
import { ConnectionsService } from '@app/training-centers/connections.service';
import { BehaviorSubject, EMPTY, forkJoin, from, Observable } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { GeneralDialogComponent } from '@app/components/shared/general-dialog/general-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TrainingPartnersService } from '@app/training-centers/training-partners.service';
import { DialogType } from '@app/models/dialog-type/dialog-type';
import { ConnectionsDialogData } from '@app/models/connections-dialog-data/connections-dialog-data';
import { ConnectActionType } from '@app/models/connection-type/connection-type';
import { Identity, IdentityService } from '@app/identity.service';

const sortingDataAccessor = (data: User, sortHeaderId: string): string => {
  switch (sortHeaderId) {
    case 'name':
      return data.contact?.name?.toLowerCase();
    case 'phone':
      return data.contact?.contact_user?.phone;
    case 'deactivated':
      return data.deactivated?.toString().toLowerCase();

    default:
      return data[sortHeaderId];
  }
};

@Component({
  selector: 'app-user-manager-overview',
  templateUrl: './user-manager-overview.component.html',
  styleUrls: ['./user-manager-overview.component.scss'],
})
export class UserManagerOverviewComponent implements OnInit, AfterViewInit {
  dialogRef: any;
  isLoading = false;

  userRoles = UserRole;
  userRoleMap = UserRoleMap;

  public displayedColumns = [
    'select',
    'name',
    'email',
    'phone',
    'role',
    'deactivated',
    'menu',
  ];

  public filterValue = new BehaviorSubject<string>('');
  public selection = new SelectionModel<User>(true, []);
  public dataSource = new MatTableDataSource<User>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private notificationService: NotificationService,
    public snackbar: MatSnackBar,
    private instructorService: InstructorService,
    private courseService: CourseService,
    private connectionsService: ConnectionsService,
    private trainingPartnersService: TrainingPartnersService,
    private identityService: IdentityService
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = sortingDataAccessor;
  }

  ngOnInit() {
    this.isLoading = true;

    const fetchUsers = value =>
      value.length === 0
        ? this.userService.getUserRecords(0)
        : this.userService.searchUserRecords(value);

    this.filterValue
      .pipe(
        debounceTime(500),
        switchMap(value => {
          this.isLoading = true;
          return fetchUsers(value);
        })
      )
      .subscribe(users => {
        this.dataSource.data = users;
        this.isLoading = false;
      });
  }

  isAnySelected() {
    return this.numberSelected() > 0;
  }

  isAllSelected() {
    return this.numberSelected() === this.dataSource.data.length;
  }

  isMultipleSelected() {
    return this.numberSelected() > 1;
  }

  numberSelected() {
    return this.selection.selected.length;
  }

  toggleSelectAll() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(user => this.selection.select(user));
  }

  editUser(user) {
    this.router.navigate(['/admin/user-manager/manage', user.id]);
  }

  viewTranscripts(user) {
    this.router.navigate(['/admin/transcripts', user.id]);
  }

  updateSalesforceID(row) {
    this.openDialog({
      content: row,
      type: 'salesforceID',
      salesforceID: row.salesforce_id,
      useremail: row.email,
    });
    this.dialogRef.afterClosed().subscribe(user_model => {
      if (user_model) {
        this.userService.updateSFID(user_model).then(() => {
          this.filterValue.next(this.filterValue.value);
        });
      }
    });
  }

  viewUserOrders(row) {
    this.router.navigate(['/admin/user-order-history', row.id]);
  }

  addInstructor() {
    if (!this.isAnySelected()) {
      this.snackbar.open('Please select user(s) to an instructor to.');
      return;
    }
    this.openDialog({
      content: this.selection.selected,
      type: 'instructor',
      remove: false,
      reset: false,
    });

    this.dialogRef.afterClosed().subscribe(instructor => {
      if (instructor) {
        this.instructorService
          .createConnection(instructor, this.selection.selected)
          .then(res => {
            this.snackbar.open('Instructor connected');
            this.selection.clear();
          });
      }
    });
  }

  openLegacyConnectionsDialog(user: User) {
    this.openDialog({
      content: [user],
      type: 'instructor',
      remove: false,
      reset: false,
      connections: true,
    });
    this.dialogRef.afterClosed().subscribe(instructor => {
      if (instructor) {
        this.instructorService.removeInstructor(instructor, user).then(res => {
          this.snackbar.open('Instructor disconnected');
          this.selection.clear();
        });
      }
    });
  }

  openConnectionsDialog(user: User) {
    this.isLoading = true;
    this.connectionsService
      .forStudent(user)
      .pipe(
        switchMap(connections => {
          let data: ConnectionsDialogData = {
            entities: connections,
            viewOnly: true,
            type: DialogType.Connections,
            pluralMap: {
              '=0': 'Nothing selected',
              '=1': 'Disconnect # partner',
              other: 'Disconnect # partners',
            },
            connectionType: ConnectActionType.Connect,
          };
          const config: MatDialogConfig = {
            width: '800px',
            height: '605px',
            data: data,
          };

          this.isLoading = false;
          return this.dialog
            .open(ManageConnectionsDialogComponent, config)
            .afterClosed();
        }),
        switchMap(selectedConnections => {
          if (selectedConnections.length > 0) {
            this.isLoading = true;
            return forkJoin(
              selectedConnections.map(connection =>
                this.connectionsService.disconnect(connection)
              )
            );
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe({
        next: () => this.filterValue.next(this.filterValue.value),
        error: null,
        complete: () => {
          this.isLoading = false;
        },
      });
  }

  addTrainingPartner() {
    if (!this.isAnySelected()) {
      this.snackbar.open('No users selected.');
      return;
    }

    this.isLoading = true;
    this.trainingPartnersService
      .getTrainingPartners(true)
      .pipe(
        switchMap(trainingPartners => {
          let data: ConnectionsDialogData = {
            viewOnly: false,
            entities: trainingPartners,
            pluralMap: {
              '=0': 'Nothing selected',
              '=1': 'Connect with # partner',
              other: 'Connect with # partners',
            },
            type: DialogType.TrainingPartners,
            connectionType: ConnectActionType.Connect,
          };
          const config: MatDialogConfig = {
            width: '800px',
            height: '605px',
            data: data,
          };

          this.isLoading = false;
          return this.dialog
            .open(ManageConnectionsDialogComponent, config)
            .afterClosed();
        }),
        filter(selection => selection.length > 0),

        switchMap(partners =>
          this.connectionsService.bulkAdd(partners, this.selection.selected)
        )
      )
      .subscribe(() => {
        this.snackbar.open('Updated');
        this.selection.clear();
      });
  }

  disconnectTrainingPartner() {
    if (!this.isAnySelected()) {
      this.snackbar.open('No users selected.');
      return;
    }

    // TODO - add bulk disconnect?
    if (this.isMultipleSelected()) {
      this.snackbar.open('Please only disconnect one partner.');
      return;
    }

    this.isLoading = true;
    this.connectionsService
      .forStudent(this.selection.selected[0])
      .pipe(
        switchMap(trainingPartners => {
          let data: ConnectionsDialogData = {
            viewOnly: false,
            entities: trainingPartners,
            pluralMap: {
              '=0': 'Nothing selected',
              '=1': 'Disconnect with # partner',
              other: `Can't disconnect multiple training partners`,
            },
            type: DialogType.TrainingPartners,
            connectionType: ConnectActionType.Disconnect,
          };
          const config: MatDialogConfig = {
            width: '800px',
            height: '605px',
            data: data,
          };

          this.isLoading = false;
          return this.dialog
            .open(ManageConnectionsDialogComponent, config)
            .afterClosed();
        }),
        filter(selection => selection.length > 0),
        switchMap(partners => this.connectionsService.disconnect(partners[0]))
      )
      .subscribe(() => {
        this.snackbar.open('Disconnected');
        this.selection.clear();
      });
  }

  impersonateUser(row) {
    this.userService.impersonateUser(row.id).then(res => {
      localStorage.setItem(
        'cirrus-impersonation-return-role',
        localStorage.getItem('cirrus-role')
      );
      localStorage.setItem(
        'cirrus-impersonation-return',
        localStorage.getItem('cirrus-token')
      );
      const user = localStorage.getItem('cirrus-user');
      localStorage.setItem('cirrus-impersonation-return-user', user);

      const impersonateIdentity: Identity = {
        role: res.impersonate_role,
        accessToken: res.impersonate_token,
        ctcAdmin: res.ctc_admin,
      };

      this.identityService.impersonate(impersonateIdentity);

      localStorage.setItem('cirrus-role', JSON.stringify(res.impersonate_role));
      localStorage.setItem('cirrus-token', res.impersonate_token);

      this.router.navigate(['home']);
    });
  }

  promoteToAdmin(user: User) {
    const message = `Promote ${user.email} to Administrator?`;
    this.confirmAction(message)
      .pipe(switchMap(() => this.userService.promoteToAdmin(user)))
      .subscribe(() => {
        this.snackbar.open('The user has been promoted to administrator');
        this.filterValue.next(this.filterValue.value);
      });
  }

  revokeAdmin(user: User) {
    const message = `Change ${user.email}'s role from Administrator to Pilot?'`;
    this.confirmAction(message)
      .pipe(switchMap(() => from(this.userService.revokeAdmin(user))))
      .subscribe(() => {
        this.snackbar.open(
          'The user role has been changed from Administrator to Pilot.'
        );
        this.filterValue.next(this.filterValue.value);
      });
  }

  notify() {
    if (!this.isAnySelected()) {
      this.snackbar.open('Please select user(s) to notify.');
      return;
    }
    this.openDialog({ content: this.selection.selected, type: 'notify' });
    this.dialogRef.afterClosed().subscribe(message => {
      const notification = new Notification();
      notification.notific_type = 1;
      notification.sender = new User();
      notification.sender.id = 1;
      if (message) {
        notification.body = message;
        this.notificationService
          .create(notification, this.selection.selected)
          .then(res => {
            this.snackbar.open('Message sent');
            this.selection.clear();
          });
      }
    });
  }

  addUserToCourse() {
    if (!this.isAnySelected()) {
      this.snackbar.open('Please select user(s) to add to course.');
      return;
    }
    this.openDialog({
      content: this.selection.selected,
      type: 'user-course',
      remove: false,
      reset: false,
    });
  }

  removeUserFromCourse() {
    if (!this.isAnySelected()) {
      this.snackbar.open('Please select a user to remove from a course(s).');
      return;
    }

    if (this.isMultipleSelected()) {
      this.snackbar.open(
        'Please select only one user to remove from a course.'
      );
      return;
    }
    this.openDialog({
      content: this.selection.selected,
      type: 'user-course',
      remove: true,
      reset: false,
    });

    this.dialogRef.afterClosed().subscribe(selected => {
      if (selected) {
        this.courseService.removeUserFromCourses(selected).then(res => {
          let pluralResult =
            selected.length > 1 ? 'Courses removed' : 'Course removed';
          this.snackbar.open(pluralResult);
          this.selection.clear();
        });
      }
    });
  }

  resetCourseAttempts() {
    if (!this.isAnySelected()) {
      this.snackbar.open('Please select a user to reset course attempt(s).');
      return;
    }

    if (this.isMultipleSelected()) {
      this.snackbar.open(
        'Please select only one user at a time to reset course attempts.'
      );
      return;
    }
    this.openDialog({
      content: this.selection.selected,
      type: 'user-course',
      remove: false,
      reset: true,
    });

    this.dialogRef.afterClosed().subscribe(selectedCourses => {
      if (selectedCourses) {
        this.courseService
          .resetCourseAttempts(selectedCourses, this.selection.selected)
          .then(res => {
            this.snackbar.open('Course Attempts have been reset.', '', {
              duration: 2500,
            });
          });
      }
    });
  }

  removeInstructor() {
    if (!this.isAnySelected()) {
      this.snackbar.open('No users selected.');
      return;
    }

    if (this.isMultipleSelected()) {
      this.snackbar.open('Please select only one user to remove instructor.');
      return;
    }

    this.openDialog({
      content: this.selection.selected,
      type: 'instructor',
      remove: true,
      reset: false,
    });

    this.dialogRef.afterClosed().subscribe(instructor => {
      if (instructor) {
        this.instructorService
          .removeInstructor(instructor, this.selection.selected.pop())
          .then(res => this.selection.clear());
      }
    });
  }

  deactivateUser() {
    if (!this.isAnySelected()) {
      this.snackbar.open('Please select user(s) to deactivate.');
      return;
    }

    const message = `Are you sure you want to deactivate ${
      this.isMultipleSelected() ? 'these users' : 'this user'
    }`;
    return this.confirmAction(message)
      .pipe(
        switchMap(() =>
          this.userService.deactivateUsers(this.selection.selected)
        )
      )
      .subscribe(() => {
        this.snackbar.open('User login deactivated');
        this.filterValue.next(this.filterValue.value);
        this.selection.clear();
      });
  }

  activateUser() {
    if (!this.isAnySelected()) {
      this.snackbar.open('Please select user(s) to be activated.');
      return;
    }

    const message = `Are you sure you want to activate ${
      this.isMultipleSelected() ? 'these users' : 'this user'
    }`;
    return this.confirmAction(message)
      .pipe(
        switchMap(() => this.userService.activateUsers(this.selection.selected))
      )
      .subscribe(() => {
        this.snackbar.open('User login activated');
        this.filterValue.next(this.filterValue.value);
        this.selection.clear();
      });
  }

  openDialog(content) {
    const width = content.type === 'notify' ? '400px' : '800px';
    const height = content.type === 'notify' ? '350px' : '600px';

    this.dialogRef = this.dialog.open(UserManagerDialogComponent, {
      width,
      height,
      data: content,
    });
  }

  confirmAction(message: String): Observable<unknown> {
    const dialogConfig = {
      width: '80%',
      maxWidth: '500px',
      data: {
        dialogTitle: 'Confirm',
        dialogBodyContent: message,
        showCancelButton: true,
        dialogCancelButtonLabel: 'Cancel',
        dialogConfirmButtonLabel: 'Confirm',
      },
    };
    return this.dialog
      .open(GeneralDialogComponent, dialogConfig)
      .afterClosed()
      .pipe(filter(Boolean));
  }
}
