import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { Faq } from '@app/models/faq';
import { FaqService } from '@app/services/data-services/faq.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-faq-overview',
  templateUrl: './faq-overview.component.html',
  styleUrls: ['./faq-overview.component.scss']
})
export class FaqOverviewComponent implements OnInit {
  rows: Faq[] = [];
  temp: Faq[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private faqService: FaqService, 
              private router: Router,
              public snackbar: MatSnackBar) {
    this.getFaqs();
  }

  ngOnInit() {
  }

  getFaqs() {
    this.faqService.getFaqs()
      .then(faqs => this.rows = this.temp = faqs);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  editFaq(faq) {
    this.router.navigate(['admin/faq/manage', faq.id]);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.question.toLowerCase().indexOf(val) !== -1 || d.faq_category.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  removeFaq(faq: Faq) {
    if (confirm('Are you sure you want to remove faq?')) {
      this.faqService.delete(faq.id)
        .then(() => {
          this.snackbar.open('Deleted')
          this.rows = this.rows.filter(item => item !== faq)
        });
    }
  }
}
