import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, forkJoin } from 'rxjs';

import { environment } from '../environments/environment';
import { User } from './models/user/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService) {}

  postAccessToken(): Observable<User> {
    const url = `${environment.api_domain}/api/v4/sessions`;
    const id_token = this.oidcSecurityService.getIdToken();
    return this.http.post(url, { id_token });
  }

  logout() {
    this.endApiSession().subscribe(() => {
      localStorage.clear();
      sessionStorage.clear();
      this.oidcSecurityService.logoffLocal();
      window.location.href = environment.logoutUrl;
    });
  }

  endApiSession() {
    return this.http.delete('api/v4/sessions', {});
  }
}
