<div class="lesson-section-item">
  <div class="item-row">
    <div class="centered">
      <mat-icon
        [ngClass]="instructor ? 'instructor-only' : 'default-lesson-section'"
        >menu</mat-icon
      >
      <div>
        <span class="type">{{ item_name }}</span>
      </div>
    </div>
    <div class="item-row">
      <div class="row">
        <mat-icon
          [ngClass]="instructor ? 'instructor-only' : 'default-lesson-section'"
          (click)="collapsed = !collapsed"
          >{{ collapsed ? 'arrow_drop_down' : 'arrow_drop_up' }}</mat-icon
        >
      </div>
    </div>
  </div>

  <div *ngIf="item_name === 'Intro Section'">
    <div class="input-container" *ngIf="!collapsed">
      <div>
        <app-wysiwyg-editor
          [editorId]="instructor.toString()"
          [displayEditor]="true"
          [displayUploader]="true"
          [text]="getRichText()"
          (textChange)="addRichText($event)"
        ></app-wysiwyg-editor>
      </div>
    </div>
  </div>

  <div *ngIf="item_name === 'Media'">
    <div *ngIf="!collapsed" cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let media of contentArray().value; let i = index" cdkDrag>
        <ng-container *ngIf="media.id">
          <div class="menu-item">
            <div><mat-icon>menu</mat-icon></div>
            <div class="middle-menu-item-container">
              <div class="menu-content-type">
                {{ contentTypeMap[media.content_type].toUpperCase() }}
              </div>
              <div class="menu-title">{{ media.title }}</div>
              <div class="menu-desc">{{ media.desc }}</div>
            </div>
            <div class="menu-vert">
              <app-mat-menu
                [menuIcon]="'more_vert'"
                (emitMenuItem)="removeContent($event, i)"
                [iconStyle]="{
                  color: 'black',
                  backgroundColor: 'rgb(225,226,227);'
                }"
                [inputItems]="mediaItems"
                [menu_style]="menu_style"
              ></app-mat-menu>
            </div>
          </div>
        </ng-container>
      </div>
      <button (click)="addContent()" class="add-media-btn">Add Media</button>
    </div>
  </div>
</div>
