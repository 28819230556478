import { Action } from '@ngrx/store';
import {Course} from '@app/models/course/course';
import {Order} from '@app/models/order/order';
import {OrderLineItem} from '@app/models/order/order-line-item';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const FETCH_ORDER = 'FETCH_ORDER';
export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const SET_ORDER = 'SET_ORDER';
export const STORE_CART = 'STORE_CART';
export const COMPLETE_PURCHASE = 'COMPLETE_PURCHASE';
export const UPDATE_CART = 'UPDATE_CART';

// Cart Actions
export class AddToCart implements Action {
  readonly type = ADD_TO_CART;
  constructor(public payload: Course) {}
}

export class RemoveFromCart implements Action {
  readonly type = REMOVE_FROM_CART;
  constructor(public payload: OrderLineItem) {}
}

export class ClearCart implements Action {
  readonly type = CLEAR_CART;
}

export class FetchOrder implements Action {
  readonly type = FETCH_ORDER;
}

export class SetOrder implements Action {
  readonly type = SET_ORDER;
  constructor(public payload: Order) {}
}

export class UpdateCart implements Action {
  readonly type = UPDATE_CART;
  constructor(public payload: Order) {}
}

export class StoreCart implements Action {
  readonly type = STORE_CART;
}

export class PurchaseOrder implements Action {
  readonly type = PURCHASE_ORDER;
  constructor(public payload: Order) {}
}

export class CompletePurchase implements Action {
  readonly type = COMPLETE_PURCHASE;
}

export type CheckoutActions =
  AddToCart |
  RemoveFromCart |
  ClearCart |
  FetchOrder |
  SetOrder |
  StoreCart |
  PurchaseOrder |
  UpdateCart |
  CompletePurchase;
