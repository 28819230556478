<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Comment Moderating</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable class="material bg-white" #table
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows"
                         [selectionType]="'none'">
            <ngx-datatable-column name="Posted Date" prop='created_at' [pipe]="datePipe"></ngx-datatable-column>
            <ngx-datatable-column name="Username" prop='user.email'></ngx-datatable-column>
            <ngx-datatable-column name="Content" prop='content.title'></ngx-datatable-column>
            <ngx-datatable-column name="Comment">
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.original_comment">
                  {{row.original_comment.comment_body}}
                </span>
                <span *ngIf="!row.original_comment">
                  {{row.comment_body}}
                </span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Reply" prop='comment_body'>
              <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.original_comment">{{row.comment_body}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Flagged Count" prop='flag_count'></ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false">
              <ng-template  let-row='row' ngx-datatable-cell-template>
                <mat-icon class='delete-icon' (click)='removeComment(row)'>delete</mat-icon>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
