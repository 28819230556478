import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { FaqCategory } from '@app/models/faq-category';
import { FaqCategoryService } from '@app/services/data-services/faq-category.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-faq-category-overview',
  templateUrl: './faq-category-overview.component.html',
  styleUrls: ['./faq-category-overview.component.scss']
})
export class FaqCategoryOverviewComponent implements OnInit {
  rows: FaqCategory[] = [];
  temp: FaqCategory[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private faqCategoryService: FaqCategoryService, 
              private router: Router,
              public snackbar: MatSnackBar) {
    this.getCategories();
  }

  ngOnInit() {
  }

  getCategories() {
    this.faqCategoryService.getFaqCategories()
      .then(categories => this.rows = this.temp = categories);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  editCategory(category) {
    this.router.navigate(['admin/faq-category/manage', category.id]);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  removeCategory(category: FaqCategory) {
    if (confirm('Are you sure you want to remove category?')) {
      this.faqCategoryService.delete(category.id)
        .then(() => {
          this.snackbar.open('Deleted')
          this.rows = this.rows.filter(item => item !== category)
        });
    }
  }

}
