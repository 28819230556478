export class OrderBillTo {
  order_id?: number;
  created_timestamp?: Date;
  bt_first_name?: string;
  bt_last_name?: string;
  bt_address1?: string;
  bt_city?: string;
  bt_state?: string;
  bt_postal_zip?: string;
  bt_country?: string;
  country_code?: string;
  created_at?: Date;
}
