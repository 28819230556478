import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  static selectValidator(control) {
    let bool = isNaN(parseInt(control.value))
    if(bool) {
      return {selection: bool}
    }
  }
}
