import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-notification-message-dialog',
  templateUrl: './notification-message-dialog.component.html',
  styleUrls: ['./notification-message-dialog.component.scss']
})
export class NotificationMessageDialogComponent implements OnInit {
  count = 0;
  message = '';

  constructor(public dialogRef: MatDialogRef<NotificationMessageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.count = data;
    }
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.message);
  }

}
