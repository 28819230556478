import { Action } from '@ngrx/store';

// Course
export const SELECT_COURSE = 'SELECT_COURSE';
export const SELECT_SECTION = 'SELECT_SECTION';
export const DESELECT_COURSE = 'DESELECT_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const SELECT_COURSE_CONTENT = 'SELECT_COURSE_CONTENT';
export const HAS_AGREED = 'HAS_AGREED';
export const SET_PREVIEW = 'SET_PREVIEW';

// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';

// Course Actions
export class SelectCourse implements Action {
  readonly type = SELECT_COURSE;

  constructor(public payload: any) {}
}

export class SetPreview implements Action {
  readonly type = SET_PREVIEW;

  constructor(public payload: any) {}
}

export class SelectSection implements Action {
  readonly type = SELECT_SECTION;

  constructor(public payload: any) {}
}

export class DeselectCourse implements Action {
  readonly type = DESELECT_COURSE;
}

// Cart Actions
export class AddToCart implements Action {
  readonly type = ADD_TO_CART;
  constructor(public payload: any) {}
}

export class RemoveFromCart implements Action {
  readonly type = REMOVE_FROM_CART;
  constructor(public payload: any) {}
}

export class ClearCart implements Action {
  readonly type = CLEAR_CART;
}

export class SelectCourseContent implements Action {
  readonly type = SELECT_COURSE_CONTENT;

  constructor(public payload: any) {}
}

export class HasAgreed implements Action {
  readonly type = HAS_AGREED;
}

export type CourseActions = SelectCourse |
                          DeselectCourse |
                          HasAgreed |
                          SetPreview |
                          SelectSection |
                          AddToCart |
                          RemoveFromCart |
                          ClearCart |
                          SelectCourseContent;
