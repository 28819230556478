import { Component, OnInit } from '@angular/core';
import {OrderService} from '@app/services/data-services/order.service';
import {PaymentType, PaymentTypeMap} from '@app/models/order/payment';
import {Router} from '@angular/router';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss']
})
export class PurchaseHistoryComponent implements OnInit {
  orders = [];
  paymentType = PaymentType;
  paymentTypeMap = PaymentTypeMap;
  isMobile = false;

  constructor(private orderService: OrderService,
              private router: Router) { }

  ngOnInit() {
    this.checkIfMobile();
      this.orderService.getPurchaseHistory()
      .then(res => this.orders = res);
  }

  viewReceipt(order_id) {
    this.router.navigate(['/receipt', order_id]);
  }

  checkIfMobile() {
    if ((window.matchMedia(`(max-width: 1024px)`).matches && window.matchMedia(`(max-height: 768px)`).matches) ||
      (window.matchMedia(`(max-height: 1024px)`).matches && window.matchMedia(`(max-width: 768px)`).matches)) {
      this.isMobile = true;
    }
  }

}
