import {Component, Inject, OnInit} from '@angular/core';
import {Stage} from '@app/models/course/workbook/stage/stage';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ShortTextPipe} from '@app/pipes/short-text.pipe';
import {Lesson} from '@app/models/course/workbook/lesson/lesson';

@Component({
  selector: 'app-select-lesson-dialog',
  templateUrl: './select-lesson-dialog.component.html',
  styleUrls: ['./select-lesson-dialog.component.scss']
})
export class SelectLessonDialogComponent implements OnInit {

  selectedLesson: Lesson;
  stages: Stage[] = [];
  lessons: Lesson[] = [];
  shortPipe = new ShortTextPipe();
  selected: any[] = [];
  columns = [{name: 'Name', prop: 'lesson_version.title'}, {name: 'Description', prop: 'lesson_version.overview', pipe: this.shortPipe}];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<SelectLessonDialogComponent>) { }

  ngOnInit() {
    if (this.data) {
      if (this.data.course_version && this.data.course_version.workbook) {
        this.data.course_version.workbook.stages.map(stage => {
          stage.lessons.forEach(lesson => this.lessons.push(lesson));
        });
      }
    }
  }

  onSelect(row) {
    this.selectedLesson = row.selected[0];
  }

  save() {
    if (this.selectedLesson) {
      this.dialogRef.close(this.selectedLesson);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
