<div class="search" 
  fxLayout="row wrap" 
  fxLayoutAlign="start center"
>
  <mat-form-field class="search-term">
    <mat-label>Enter a name or location</mat-label>
    <input
      matInput
      type="text"
      aria-label="Search term"
      [(ngModel)]="searchValue"
      (keyup)="keyEvent($event)"
    />
    <button
      mat-button
      *ngIf="searchValue"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      class="clear"
      (click)="clearSearch()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <ng-content></ng-content>
  <button class="search" (click)="applySearch()">Search</button>
</div>
