import { Injectable } from '@angular/core';
import {Stage} from '@app/models/course/workbook/stage/stage';

import {HttpClient} from "@angular/common/http";

@Injectable()
export class CourseWorkbookService {
  workbookUrl = 'workbookUrl';

  data: any[] = [{
      id: 0,
      name: 'Stage 1',
      order: 1,
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      lessons: [
        {
          id: 0,
          order: 1,
          title: 'Lesson 1',
          type: 'Self Study',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          content: [{
            id: 0,
            order: 1,
            title: 'Content Item 1',
            type: 'video',
            status: 1
          }, {
            id: 1,
            order: 2,
            title: 'Content Item 2',
            type: 'video',
            status: 1
          }, {
            id: 2,
            order: 3,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 1,
            score: 90
          }, {
            id: 3,
            order: 4,
            title: 'Content Item 4',
            type: 'video',
            status: 1
          }, {
            id: 4,
            order: 5,
            title: 'Content Item 5',
            type: 'scorm',
            status: 0
          }]
        }
      ]
  }, {
      id: 1,
      order: 2,
      name: 'Stage 2',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      lessons: [
        {
          id: 0,
          order: 1,
          title: 'Lesson 1',
          type: 'Self Study',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          content: [{
            id: 0,
            order: 1,
            title: 'Content Item 1',
            type: 'video',
            status: 1
          }, {
            id: 1,
            order: 2,
            title: 'Content Item 2',
            type: 'scorm',
            status: 1
          }, {
            id: 2,
            order: 3,
            title: 'Content Item 3',
            type: 'scorm',
            status: 1
          }, {
            id: 3,
            order: 4,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 1,
            score: 95
          }, {
            id: 4,
            order: 5,
            title: 'Content Item 5',
            type: 'scorm',
            status: 0
          }]
        }
      ]
  }, {
      id: 2,
      order: 3,
      name: 'Stage 3',
      desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      lessons: [
        {
          id: 0,
          order: 1,
          title: 'Lesson 1',
          type: 'Self Study',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          content: [{
            id: 0,
            order: 1,
            title: 'Content Item 1',
            type: 'video',
            status: 1
          }, {
            id: 1,
            order: 2,
            title: 'Content Item 2',
            type: 'video',
            status: 1
          }, {
            id: 2,
            order: 3,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 1,
            score: 88
          }, {
            id: 3,
            order: 4,
            title: 'Content Item 4',
            type: 'video',
            status: 1
          }, {
            id: 4,
            order: 5,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 0,
            score: 91
          }]
        },
        {
          id: 1,
          order: 2,
          title: 'Lesson 2',
          type: 'Flight',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          content: [{
            id: 0,
            order: 1,
            title: 'Content Item 1',
            type: 'video',
            status: 1
          }, {
            id: 1,
            order: 2,
            title: 'Content Item 2',
            type: 'video',
            status: 1
          }, {
            id: 2,
            order: 3,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 1,
            score: 88
          }, {
            id: 3,
            order: 4,
            title: 'Content Item 4',
            type: 'video',
            status: 1
          }, {
            id: 4,
            order: 5,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 0,
            score: 91
          }]
        },
        {
          id: 2,
          order: 3,
          title: 'Lesson 3',
          type: 'Ground',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          content: [{
            id: 0,
            order: 1,
            title: 'Content Item 1',
            type: 'video',
            status: 1
          }, {
            id: 1,
            order: 2,
            title: 'Content Item 2',
            type: 'video',
            status: 1
          }, {
            id: 2,
            order: 3,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 1,
            score: 88
          }, {
            id: 3,
            order: 4,
            title: 'Content Item 4',
            type: 'video',
            status: 1
          }, {
            id: 4,
            order: 5,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 0,
            score: 91
          }]
        },
        {
          id: 3,
          order: 4,
          title: 'Lesson 4',
          type: 'Self Study',
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
          content: [{
            id: 0,
            order: 1,
            title: 'Content Item 1',
            type: 'video',
            status: 1
          }, {
            id: 1,
            order: 2,
            title: 'Content Item 2',
            type: 'video',
            status: 1
          }, {
            id: 2,
            order: 3,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 1,
            score: 88
          }, {
            id: 3,
            order: 4,
            title: 'Content Item 4',
            type: 'video',
            status: 1
          }, {
            id: 4,
            order: 5,
            title: 'Take the Quiz',
            type: 'quiz',
            status: 0,
            score: 91
          }]
        }
      ]
    }];

  getAll(): Promise<Stage[]> {
    return Promise.resolve(this.data);
  }

  getCourseContent(): Promise<Stage[]> {
    return Promise.resolve(this.data);
  }
  constructor() { }

  // getWorkbooks(): Promise<any[]> {
  //   return this.http.get(this.workbookUrl)
  //     .toPromise()
  //     .then((response) => response)
  //     .catch(this.handleError);
  // }
  //
  // getWorkbook(id: number): Promise<any> {
  //   const url = `${this.workbookUrl}/${id}`;
  //   return this.http.get(url)
  //     .toPromise()
  //     .then(response => response as any)
  //     .catch(this.handleError);
  // }
  //
  // delete(id: number): Promise<void> {
  //   const url = `${this.workbookUrl}/${id}`;
  //   return this.http.delete(url)
  //     .toPromise()
  //     .then(() => null)
  //     .catch(this.handleError);
  // }
  //
  // create(workbook-editor: any): Promise<any> {
  //   return this.http
  //     .post(this.workbookUrl, JSON.stringify(workbook-editor))
  //     .toPromise()
  //     .then(res => res)
  //     .catch(this.handleError);
  // }
  //
  // update(workbook-editor: any): Promise<any> {
  //   const url = `${this.workbookUrl}/${workbook-editor.id}`;
  //   return this.http
  //     .put(url, JSON.stringify(workbook-editor))
  //     .toPromise()
  //     .then(() => workbook-editor)
  //     .catch(this.handleError);
  // }
  //
  // private handleError(error: any): Promise<any> {
  //   console.error('An error occurred', error);
  //   return Promise.reject(error.message || error);
  // }

}
