import { Component, OnInit } from '@angular/core';
import { Avionics } from '../../../../models/aircraft/avionics';
import { AvionicsService } from '../../../../services/data-services/avionics.service';
import { slideInRight } from '../../../../animations/animations';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-avionics',
  templateUrl: './add-avionics.component.html',
  styleUrls: ['./add-avionics.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddAvionicsComponent implements OnInit {
  avionics: Avionics;

  constructor(private avionicsService: AvionicsService, 
              private router: Router,
              public snackbar: MatSnackBar
              ) { }

  ngOnInit() {
    this.avionics = new Avionics();
  }

  addAvionics() {
    this.avionicsService.create(this.avionics)
        .then(() => {
          this.snackbar.open('Updated')
          this.avionics = new Avionics()
        });
  }

  cancel() {
    this.router.navigate(['admin/courses/avionics']);
  }

}
