
import {withLatestFrom, map, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import {Store} from '@ngrx/store';
import * as CheckoutActions from './checkout.actions';
import * as fromApp from '../../../../store/app.reducers';
import {OrderService} from '@app/services/data-services/order.service';
import {Order} from '@app/models/order/order';

@Injectable()
export class CheckoutEffects {

  
  cartFetch$ = createEffect(() => this.actions$.pipe(
                ofType(CheckoutActions.FETCH_ORDER),
                switchMap((action: CheckoutActions.FetchOrder) => {
                  if (localStorage.getItem('cirrus-token')) {
                    return this.orderService.getUserCart();
                  } else {
                    return Promise.resolve(new Order());
                  }
                }),
                map(
                  (order) => {
                    if (!order) {
                      order = new Order();
                    }

                    return {
                      type: CheckoutActions.SET_ORDER,
                      payload: order
                    };
                  }
                ),));

  
  updateOrder$ = createEffect(() => this.actions$.pipe(
    ofType(CheckoutActions.ADD_TO_CART, CheckoutActions.REMOVE_FROM_CART, CheckoutActions.CLEAR_CART, CheckoutActions.UPDATE_CART),
    withLatestFrom(this.store$),
    switchMap(([action, state]) => {
      if (localStorage.getItem('cirrus-token')) {
        return this.orderService.updateUserCart(state.checkout.order);
      } else {
        return Promise.resolve(state.checkout.order);
      }
    }),map(
      (order) => {
        return {
          type: CheckoutActions.SET_ORDER,
          payload: order
        };
      }
    ),));

  constructor(private actions$: Actions,
              private orderService: OrderService,
              private store$: Store<fromApp.AppState>) {}
}
