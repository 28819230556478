import {
  AfterContentInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from '@app/services/data-services/configuration.service';
import { CourseCategoryService } from '@app/services/data-services/course-category.service';
import { CourseService } from '@app/services/data-services/course.service';
import { SnackbarComponent } from '@app/components/shared/snackbar/snackbar.component';
import { GeneralTableDialogComponent } from '@app/components/shared/general-table-dialog/general-table-dialog.component';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';

import * as domHelper from '../../../helpers/dom.helper';
import * as autoScroll from 'dom-autoscroller';
import * as workbookHelper from '../../../helpers/workbook.helper';

@Component({
  selector: 'app-configuration-manager',
  templateUrl: './configuration-manager.component.html',
  styleUrls: [
    './configuration-manager.component.scss',
    './workbook-editor.scss',
    '../course-manager/courses/course-editor/drag-drop.scss',
  ],
})
export class ConfigurationManagerComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  @ViewChild('editor', { static: true }) editor: ElementRef;
  categories: any[] = [];
  categoriesStatic: any[] = [];
  learningCatalogSettings: any = {};
  subscription: any;
  drag_subs = new Subscription();
  drakes: any[] = ['category-bag', 'course-bag'];
  dialogRef: any;
  scroll: any;
  isDragging = false;
  isSaving = false;
  categoriesChanged = false;
  coursesChanged: boolean[] = [];
  allowReset = false;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private categoryService: CourseCategoryService,
    private configurationService: ConfigurationService,
    public dragulaService: DragulaService,
    public dialog: MatDialog
  ) {
    // Fetch categories and courses
    this.categoryService.getCategoriesandCourses().then(categories => {
      // Fetch configuration settings for learning catalog
      this.configurationService.find('learning-catalog').then(configuration => {
        this.learningCatalogSettings = configuration;
        console.log(this.learningCatalogSettings);
        // map the showAll setting from the configuration values
        this.categories = categories.map((category, index) => {
          category.showAll =
            this.learningCatalogSettings !== undefined &&
            this.learningCatalogSettings.settings !== undefined &&
            this.learningCatalogSettings.settings[index] !== undefined &&
            this.learningCatalogSettings.settings[index].showAll !== undefined
              ? this.learningCatalogSettings.settings[index].showAll
              : false;
          return category;
        });
        // deep map the "control" group of categories so we know what's changed
        this.categoriesStatic = workbookHelper.deepMap(
          this.categories,
          category => category
        );
      });
    });

    // Setup drag n drop stuff
    this.dragulaService.createGroup('category-bag', {
      revertOnSpill: true,
      direction: 'vertical',
      moves: (el, source, handle) => {
        return handle.classList.contains('group-handle');
      },
    });
    this.dragulaService.createGroup('course-bag', {
      moves: (el, source, handle) => {
        return handle.classList.contains('course-handle');
      },
    });

    this.drag_subs.add(
      dragulaService.drag().subscribe(({ source }) => {
        this.onDrag(source);
      })
    );

    this.drag_subs.add(
      this.dragulaService.drop().subscribe(({ el }) => {
        this.onDrop(el);
      })
    );
    this.drag_subs.add(
      this.dragulaService.dragend().subscribe(({ el }) => {
        this.onDragEnd(el);
      })
    );
    this.drag_subs.add(
      this.dragulaService.dropModel().subscribe(value => {
        this.onDropModel(value);
      })
    );
  }

  ngOnInit() {
    const $this = this;
    this.scroll = autoScroll([this.editor.nativeElement], {
      margin: 30,
      maxSpeed: 25,
      scrollWhenOutside: true,
      autoScroll: function () {
        return this.down && $this.isDragging;
      },
    });
  }

  ngAfterContentInit() {}

  private onDrag(args: any): void {
    this.isDragging = true;
    const [element] = args;
    domHelper.addClass(document.body, 'is-dragging');
    // domHelper.addClass(document.querySelectorAll('.courses-list'), 'gu-hide');
    if (element.classList.contains('category-item')) {
      domHelper.addClass(document.querySelectorAll('.courses-list'), 'gu-hide');
    }
  }

  private onDrop(args?: any): void {
    domHelper.removeClass(document.body, 'is-dragging');
  }

  private onDragEnd(args?: any): void {
    this.isDragging = false;
    domHelper.removeClass(document.body, 'is-dragging');
    domHelper.removeClass(document.querySelectorAll('.gu-hide'), 'gu-hide');
  }

  private onDropModel(args) {
    domHelper.removeClass(document.body, 'is-dragging');
    domHelper.removeClass(document.querySelectorAll('.gu-hide'), 'gu-hide');
    // Check the stages here, too for order changing
    this.categoriesChanged = workbookHelper.forceVersionChange(
      this.categories,
      this.categoriesStatic
    );
    this.courseOrderChanged();
    this.allowReset =
      this.coursesChanged.includes(true) || this.categoriesChanged;
  }

  private courseOrderChanged() {
    for (
      let categoryIndex = 0;
      categoryIndex < this.categories.length;
      categoryIndex++
    ) {
      // Flag as force new version in the event a draggable has moved order
      this.coursesChanged[categoryIndex] = workbookHelper.forceVersionChange(
        this.categories[categoryIndex].courses,
        this.categoriesStatic[categoryIndex].courses
      );
    }
  }

  updateConfiguration() {
    const categoryOrder = [];
    this.categories.forEach((category, categoryIndex) => {
      const courseIDs = category.courses.map(course => course.id);
      categoryOrder[categoryIndex] = {
        id: category.id,
        showAll: category.showAll,
        courses: courseIDs,
      };
    });
    this.learningCatalogSettings.settings = categoryOrder;
  }

  saveConfiguration() {
    this.updateConfiguration();
    this.configurationService
      .update(this.learningCatalogSettings)
      .then(response => {
        console.log(this.learningCatalogSettings);
        this.confirmMessage();
      });
  }
  showAll() {}
  resetEdits() {
    this.categories = this.categoriesStatic.map(category => category);
    this.categories = workbookHelper.deepMap(
      this.categoriesStatic,
      category => category
    );
    this.categoriesChanged = workbookHelper.forceVersionChange(
      this.categories,
      this.categoriesStatic
    );
    this.courseOrderChanged();
    this.allowReset =
      this.coursesChanged.includes(true) || this.categoriesChanged;
  }

  confirmMessage() {
    const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
      data: 'Updated configuration',
    });

    this.subscription = snackBar.afterOpened().subscribe(() => {
      this.router.navigate(['/admin/dashboard']);
    });
  }

  cancel() {
    this.router.navigate(['/admin/dashboard']);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.drag_subs) {
      this.drag_subs.unsubscribe();
    }
    this.drakes.forEach(drake => {
      if (this.dragulaService.find(drake)) {
        this.dragulaService.destroy(drake);
      }
    });
  }
}
