<form #form="ngForm" id="assignment-form">
  <h1 mat-dialog-title>Add Assignment</h1>
  <mat-dialog-content>
    <div class="form-row">
      <mat-form-field>
        <input matInput name="dueDate" [(ngModel)]="assignment.due_date" [matDatepicker]="picker" placeholder="Choose a date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-row">
      <button mat-raised-button color="primary" (click)="selectCourse()">Select Course</button>
      <div class="full-width mt-1" *ngIf="selectedCourse?.course">
        <p class="selected-item">{{selectedCourse?.course.title}}</p>
      </div>
    </div>
    <div class="form-row">
      <button mat-raised-button color="primary" *ngIf="selectedCourse" (click)="selectStage()">Select Stage</button>
      <div class="full-width mt-1" *ngIf="selectedStage">
        <p class="selected-item">{{selectedStage?.name}}</p>
      </div>
    </div>
    <div class="form-row">
      <button mat-raised-button color="primary" *ngIf="selectedStage" (click)="selectLesson()">Select Lesson</button>
      <div class="full-width mt-1" *ngIf="selectedLesson">
        <p class="selected-item">{{selectedLesson?.name}}</p>
      </div>
    </div>
    <div class="form-row">
      <button mat-raised-button color="primary" *ngIf="selectedLesson" (click)="selectAssignment()">Select Assignment</button>
      <div class="full-width mt-1" *ngIf="selectedAssignment">
        <p class="selected-item">{{selectedAssignment?.name}}</p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="display: block; text-align: center;">
    <button mat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="assign()" [disabled]="!selectedCourse || !selectedStage || !selectedLesson || !selectedAssignment">Add</button>
  </mat-dialog-actions>
</form>
