export class ContentBookshelf {
  id?: number;
  title?: string;
  desc?: string;
  content_html?: string;
  content_type?: BookshelfContentType;
  url?: string;
  user_id?: number;
  created_by?: string;
  meta_tags?: string[];
  content_file?: string;
  content_filename?: string;
  placeholder_image?: string;
  bookshelf?: boolean;

  constructor() {
    this.meta_tags = [];
  }
}


export enum BookshelfContentType {
  vimeo = 0,
  youtube = 1,
  scorm = 2,
  tinCan = 3,
  download = 4,
  interactive = 5,
  richText = 6,
  image = 7
}

export const BookshelfContentTypeMap = {
  0: 'Vimeo',
  1: 'YouTube',
  2: 'SCORM',
  3: 'TinCan',
  4: 'Download',
  5: 'Interactive',
  6: 'Rich Text',
  7: 'Image'
};
