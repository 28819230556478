import { Stage } from './stage/stage';

export class Workbook {
  id?: number;
  courseId?: string;
  stages?: Stage[];

  constructor() {
    this.stages = new Array<Stage>();
  }
}
