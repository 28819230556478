<mat-nav-list
  role="list"
  fxLayout="column"
  fxLayoutAlign.xs="space-between stretch"
  fxLayoutAlign="space-between center"
  fxFill
  (click)="dismissHamburgerMenu()"
>
  <ng-container *ngIf="!isLoggedIn()">
    <div>
      <app-sidenav-link
        location="https://www.cirrusapproach.com/learn-to-fly/"
        isExternal="true"
        newTab="true"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/learn-to-fly-icon.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >Learn To Fly</span
          >
        </div>
      </app-sidenav-link>
      <app-sidenav-link
        location="https://www.youtube.com/playlist?list=PLEZkne-402vDOF39xpPCqDttnRfm90QuZ"
        isExternal="true"
        newTab="true"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/flight-fix-icon.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >Flight Fix</span
          >
        </div>
      </app-sidenav-link>
      <app-sidenav-link location="/learning-catalog">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/catalog.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >Course Catalog</span
          >
        </div>
      </app-sidenav-link>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoggedIn()">
    <div>
      <app-sidenav-link location="/recent-activity">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/recent-activity.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >Recent Activity</span
          >
        </div>
      </app-sidenav-link>
      <app-sidenav-link
        *ngIf="showInstructorDashboard"
        [location]="instructorStudentsUrl"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/my-student.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >My Students</span
          >
        </div>
      </app-sidenav-link>
      <app-sidenav-link location="/my-courses">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/my-courses.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >My Courses</span
          >
        </div>
      </app-sidenav-link>
      <app-sidenav-link location="/learning-catalog">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/catalog.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >Course Catalog</span
          >
        </div>
      </app-sidenav-link>
      <app-sidenav-link location="/authorized-training-network">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/instructor.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >Authorized Training Network</span
          >
        </div>
      </app-sidenav-link>
      <app-sidenav-link location="/library">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/library.svg" />
          <span class="nav-text" [style.display]="!collapse ? 'block' : 'none'"
            >Library</span
          >
        </div>
      </app-sidenav-link>
    </div>
  </ng-container>
  <div
    class="bottom-sidenav-container "
    [fxLayout]="collapse ? 'column' : 'row'"
    fxLayoutAlign.xs="space-between stretch"
    fxLayoutAlign="space-between center"
    [fxLayoutGap]="collapse ? '35px' : '0'"
    [class]="collapse ? 'collapsed' : ''"
  >
    <div
      fxLayout
      [fxLayout]="collapse ? 'column' : 'row'"
      [fxLayoutGap.gt-xs]="collapse ? '35px' : '25px'"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="sb-item"
        *ngIf="isLoggedIn()"
        (click)="toggleNotific()"
      >
        <img src="assets/images/bell.svg" alt="Notifications Icon" />
        <span
          class="nav-text"
          [style.display]="!collapse && !isMobile$ ? 'block' : 'none'"
          >Notifications
        </span>
        <app-badge *ngIf="notifications?.length" class="mat-badge-start-left">{{ notifications.length > 99 ? '99+' : notifications.length }}</app-badge>
      </div>
    </div>

    <div
      fxLayout
      [fxLayout]="collapse ? 'column' : 'row'"
      [fxLayoutGap.gt-xs]="collapse ? '35px' : '25px'"
      class=""
    >

      <app-sidenav-link
        location="https://cirrusaircraft.com/approach/help-center/"
        isExternal="true"
        newTab="true"
      >
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="image-container"
        >
          <img src="assets/images/clarity_help-line.svg" />
          <span class="nav-text" [style.display]="!collapse && !isMobile$ ? 'block' : 'none'"
            >Help Center</span
          >
        </div>
      </app-sidenav-link>

    </div>

    <button id="collapsible-sidenav-trigger" (click)="toggleCollapse()" 
      *ngIf="!disableToggle" mat-icon-button disableRipple>
      <img
        [src]="
          !collapse
            ? 'assets/images/fluent_panel-left-expand-28-regular.svg'
            : 'assets/images/fluent_panel-right-expand-28-regular.svg'
        "
      />
    </button>
  </div>
</mat-nav-list>
