import { SelectionModel } from '@angular/cdk/collections';
import { Content } from '@angular/compiler/src/render3/r3_ast';

import {
  Component,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { HttpService } from '@app/services/http-service.service';
import * as workbookHelper from '../../../helpers/workbook.helper';
import {
  ContentType,
  ContentTypeMap,
} from '@app/models/course/content/content-type';
import { MatSort } from '@angular/material/sort';
import { SelectionType } from '@swimlane/ngx-datatable';
import { LessonTypeMap } from '@app/models/course/workbook/lesson/lesson-type-map';

@Component({
  selector: 'app-general-table-dialog',
  templateUrl: './general-table-dialog.component.html',
  styleUrls: ['./general-table-dialog.component.scss'],
})
export class GeneralTableDialogComponent implements OnInit {
  // @ViewChild('table', { static: true }) table: DatatableComponent;
  @ViewChild('badgeTmpl', { static: true }) badgeTmpl: TemplateRef<any>;
  @ViewChild('certTmpl', { static: true }) certTmpl: TemplateRef<any>;
  @ViewChild('lessonTypeTmpl', { static: true })
  lessonTypeTmpl: TemplateRef<any>;
  @ViewChild('contentTypeTmpl', { static: true })
  contentTypeTmpl: TemplateRef<any>;
  model: any;
  dialogTitle: string = 'Add Items';
  dialogConfirmButtonLabel: string = 'Add Item(s)';
  customConfirmButton: boolean = false;
  showCancelButton: boolean = false;
  dialogCancelButtonLabel: string = 'Cancel';
  rows: any[] = [];
  columns: any[] = [];
  selected = [];
  filterGroups: any;
  filterCategory: string = null;
  hasSidebar: boolean = false;
  selectedRows: any[] = [];
  rowsCount: number = 0;
  httpService: any;
  isLoading: boolean = true;
  typeMap: any;
  checkboxStates = {};
  filterTable = [];

  public dataSource = new MatTableDataSource<any>([]);
  @ViewChild(MatSort) sort: MatSort;
  public selection = new SelectionModel<Content>(true, []);
  contentTypeArr: Array<number> = [];
  searchVal: string = '';

  public dataColumns = this.data.dataColumns;

  get contentType() {
    return ContentType;
  }

  get contentTypeMap() {
    return ContentTypeMap;
  }

  get lessonTypeMap() {
    return LessonTypeMap;
  }

  constructor(
    public dialogRef: MatDialogRef<GeneralTableDialogComponent>,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.dataSource.data = this.data.rows;

    this.dialogTitle = this.data.dialogTitle;
    this.customConfirmButton = this.data.customConfirmButton;
    this.showCancelButton = this.data.showCancelButton;
    if (this.customConfirmButton)
      this.dialogConfirmButtonLabel = this.data.dialogConfirmButtonLabel;
    if (this.showCancelButton)
      this.dialogCancelButtonLabel = this.data.dialogCancelButtonLabel;
    this.filterCategory = this.data.filterCategory;
    this.columns = this.data.columns;
    this.typeMap = this.data.typeMap;
    this.hasSidebar = this.data.hasSidebar;

    this.rows = this.data.rows;
    this.setupFiltering(this.data.rows);

    this.isLoading = false;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  filterFormatForPredicate(record) {
    let array = [];

    for (const [key, value] of Object.entries(record)) {
      array.push(`${value}◬`);
    }

    let format = array.join('').toLowerCase();

    return format.substring(0, format.length - 1);
  }

  setupFiltering(items: any[]) {
    if (this.filterCategory == null) return;
    const groupByCategory = workbookHelper.GroupBy(this.filterCategory);
    if (this.filterCategory !== null) {
      this.filterGroups = groupByCategory(items);
      Object.keys(this.filterGroups).forEach(key => {
        this.checkboxStates[key] = false;
      });
    }
  }

  clearFilters() {
    Object.keys(this.checkboxStates).forEach(key => {
      this.checkboxStates[key] = false;
    });
    this.dataSource.filter = '';
    this.dataSource.data = this.data.rows;
    this.searchVal = '';
  }

  close() {
    this.dialogRef.close();
  }

  isAnySelected() {
    return this.selection.selected.length > 0;
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  selectedCount() {
    return this.selection.selected.length;
  }

  toggle(row) {
    if (
      this.data.selectionType === SelectionType.single &&
      this.selectedCount() === 1
    ) {
      this.selection.clear();
    }

    this.selection.toggle(row);
  }

  applyCheckboxFilter(event: any) {
    let checkboxArr = [];

    for (const [key, value] of Object.entries(this.checkboxStates)) {
      if (value) {
        checkboxArr.push(parseInt(key));
      }
    }

    if (checkboxArr.length === 0) {
      this.dataSource.data = this.data.rows;

      return;
    }

    const filteredData = this.data.rows.filter(data => {
      return checkboxArr.includes(data[this.filterCategory]);
    });

    this.dataSource.data = filteredData;
  }

  applySearchFilter(event: any, type) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
