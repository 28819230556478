import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-student-assessments-standards-dialog',
  templateUrl: './student-assessments-standards-dialog.component.html',
  styleUrls: ['./student-assessments-standards-dialog.component.scss']
})
export class StudentAssessmentsStandardsDialogComponent implements OnInit {
  selectedStandards: number[] = [];
  standards: any[] = [];
  missed = [];
  constructor(public dialogRef: MatDialogRef<StudentAssessmentsStandardsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data && this.data.missed) {
      this.standards = this.data.standards;
      this.missed = this.data.missed;
    } else {
      this.standards = this.data;
    }
  }

  trackFunction(index, value) {
    return index;
  }

  selectStandard(selectedIndex) {
    const index = this.selectedStandards.indexOf(selectedIndex);

    if (index > -1) {
      this.selectedStandards.splice(index, 1);
      return;
    }
    this.selectedStandards.push(selectedIndex);
  }

  save() {
    this.dialogRef.close(this.selectedStandards);
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
