import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationService } from '../../../services/navigation/navigation.service';
import { slideInLeft } from '../../../animations/animations';

@Component({
  selector: 'app-backend-sidenav',
  templateUrl: './backend-sidenav.component.html',
  styleUrls: ['./backend-sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [slideInLeft],
})
export class NavigationComponent implements OnInit {
  hasIconTypeMenuItem;
  iconTypeMenuTitle: string;
  menuItems: any[];
  extendedNav = false;
  navOpen = false;

  courseMenu = [
    {
      name: 'COURSES',
      type: 'link',
      tooltip: 'Courses',
      icon: 'border_color',
      state: '/admin/courses',
    },
    {
      name: 'LESSONS',
      type: 'link',
      tooltip: 'Lessons',
      icon: 'library_books',
      state: '/admin/lessons',
    },
    {
      name: 'CONTENT',
      type: 'link',
      tooltip: 'Content',
      icon: 'library_books',
      state: '/admin/courses/content',
    },
    {
      name: 'AVIONICS',
      type: 'link',
      tooltip: 'Avionics',
      icon: 'track_changes',
      state: '/admin/courses/avionics',
    },
    {
      name: 'AIRCRAFT',
      type: 'link',
      tooltip: 'Aircraft',
      icon: 'airplanemode_active',
      state: '/admin/courses/aircraft',
    },
    {
      name: 'CATEGORIES',
      type: 'link',
      tooltip: 'Categories',
      icon: 'style',
      state: '/admin/courses/category',
    },
    {
      name: 'TASKS',
      type: 'link',
      tooltip: 'Tasks',
      icon: 'format_list_bulleted',
      state: '/admin/courses/task',
    },
    // {
    //   name: 'QUIZZES',
    //   type: 'link',
    //   tooltip: 'Quiz',
    //   icon: 'assignment_turned_in',
    //   state: '/admin/courses/quizzes'
    // },
    {
      name: 'BADGES',
      type: 'link',
      tooltip: 'Badges',
      icon: 'stars',
      state: '/admin/badges',
    },
    {
      name: 'CERTIFICATES',
      type: 'link',
      tooltip: 'Certificates',
      icon: 'description',
      state: '/admin/certificates',
    },
  ];

  classroomMenu = [
    {
      name: 'STUDENTS',
      type: 'link',
      tooltip: 'Students',
      icon: 'class',
      state: '/instructor/classroom/students',
    },
    // {
    //   name: 'ASSIGNMENTS',
    //   type: 'link',
    //   tooltip: 'Assignments',
    //   icon: 'assignment',
    //   state: '/instructor/classroom/assignments'
    // },
  ];

  ctcClassroomMenu = [
    {
      name: 'STUDENTS',
      type: 'link',
      tooltip: 'Students',
      icon: 'class',
      state: '/ctc/classroom/students',
    },
  ];

  // faqMenu = [
  //   {
  //     name: 'FAQ CATEGORIES',
  //     type: 'link',
  //     tooltip: 'Faq Categories',
  //     icon: 'clear_all',
  //     state: '/admin/faq-category'
  //   },
  //   {
  //     name: 'FAQS',
  //     type: 'link',
  //     tooltip: 'Faqs',
  //     icon: 'live_help',
  //     state: '/admin/faq'
  //   }
  // ];

  navLinks: any[];

  @Input() menuType: string;
  @Input() type: string;
  @Input() extendedMenu;

  constructor(private navService: NavigationService) {}
  ngOnInit() {
    // this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    if (this.menuType && this.type !== 'extendedMenu') {
      this.navService.setMenu(this.menuType);
      this.navService.menuItems$.subscribe(menuItem => {
        this.menuItems = menuItem;
        this.hasIconTypeMenuItem = !!this.menuItems.filter(
          item => item.type === 'icon'
        ).length;
      });
    } else {
      this.extendedNav = false;
      this.navOpen = false;
    }
  }

  toggle(type) {
    if (this.type === 'extendedMenu') {
      if (type === 'COURSES') {
        this.extendedNav = true;
        this.navOpen = true;
        this.navLinks = this.courseMenu;
      } else if (type === 'STUDENTS') {
        this.extendedNav = true;
        this.navOpen = true;
        this.navLinks =
          this.menuType === 'ctc' ? this.ctcClassroomMenu : this.classroomMenu;
      } else if (type === 'FAQS') {
        this.extendedNav = true;
        this.navOpen = true;
        // this.navLinks = this.faqMenu;
      } else {
        this.extendedNav = false;
        this.navOpen = false;
        this.navLinks = null;
      }
    } else if (this.extendedMenu) {
      this.extendedMenu.toggle(type, type);
    }
  }
}
