import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {SelectCourseDialogComponent} from '../select-course-dialog/select-course-dialog.component';
import {SelectStageDialogComponent} from '../select-stage-dialog/select-stage-dialog.component';
import {SelectLessonDialogComponent} from '../select-lesson-dialog/select-lesson-dialog.component';
import {UserCourse} from '@app/models/course/user-course';
import {StudentAssignment} from '@app/models/course/student-assignment';
import {Lesson} from '@app/models/course/workbook/lesson/lesson';
import {Stage} from '@app/models/course/workbook/stage/stage';
import {AssignmentService} from '@app/services/data-services/assignment.service';
import {SelectAssignmentDialogComponent} from '../select-assignment-dialog/select-assignment-dialog.component';
import {Assignment} from '@app/models/course/assignment';

// TODO: Is this component used?

@Component({
  selector: 'app-add-assignment-dialog',
  templateUrl: './add-assignment-dialog.component.html',
  styleUrls: ['./add-assignment-dialog.component.scss']
})
export class AddAssignmentDialogComponent implements OnInit {
  selectedCourse: UserCourse = null;
  selectedStage: Stage = null;
  selectedLesson: Lesson = null;
  selectedAssignment: Assignment = null;
  assignment: StudentAssignment;
  student_id: number;
  dialogRef: any;

  constructor(public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private assignmentService: AssignmentService,
              public ref: MatDialogRef<AddAssignmentDialogComponent>) { }

  ngOnInit() {
    this.student_id = this.data;
    this.assignment = new StudentAssignment();
  }

  selectCourse() {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(SelectCourseDialogComponent, {
      width,
      height,
      data: this.student_id
    });

    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.selectedCourse = data;
        this.assignment.course_id = this.selectedCourse.course.id;
      }
    });
  }

  selectStage() {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(SelectStageDialogComponent, {
      width,
      height,
      data: this.selectedCourse
    });

    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.selectedStage = data;
        this.assignment.stage_id = data.id;
      }
    });
  }

  selectLesson() {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(SelectLessonDialogComponent, {
      width,
      height,
      data: this.selectedCourse
    });

    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.selectedLesson = data;
        this.assignment.lesson_id = data.id;
      }
    });
  }

  selectAssignment() {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(SelectAssignmentDialogComponent, {
      width,
      height
    });

    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.selectedAssignment = data;
        this.assignment.assignment_id = data.id;
      }
    });
  }

  assign() {
  }

  cancel(assignment) {
    if (assignment) {
      this.ref.close(assignment);
    } else {
      this.ref.close();
    }
  }

}
