import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keys'
})
export class KeysPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || typeof value !== 'object') {
      return null;
    }
    let keys = [];
    for (const key in value) {
      keys.push({key: key, value: value[key]});
    }
    if (args === 'filter') {
      keys = keys.filter(value => value.key !== '4');
    }
    return keys;
  }

}

// vimeo = 0,
//   youtube = 1,
//   scorm = 2,
//   tinCan = 3,
//   pdf = 4,
//   quiz = 5,
//   download = 6,
//   interactive = 7,
//   richText = 8,
//   flight_assessment = 9,
//   ground_assessment = 10
