import { Component } from '@angular/core';
import {DeferReason} from '@app/models/course/content/task/defer-reason';
import {DeferReasonService} from '@app/services/data-services/defer-reason.service';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {slideInRight} from '@app/animations/animations';

@Component({
  selector: 'app-add-defer-reason',
  templateUrl: './add-defer-reason.component.html',
  styleUrls: ['./add-defer-reason.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddDeferReasonComponent {
  defer_reason_model: DeferReason;

  constructor(private deferReasonService: DeferReasonService,
              private router: Router,
              public dialog: MatDialog) {
    this.initDeferReason();
  }

  initDeferReason() {
    this.defer_reason_model = new DeferReason();
  }

  addDeferReason() {
    if (this.defer_reason_model.reason) {
      this.deferReasonService.create(this.defer_reason_model)
        .then(() => this.initDeferReason());
    }
  }

  cancel() {
    this.router.navigate(['admin/courses/task/defer-reasons-overview']);
  }
}
