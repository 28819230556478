import { Component, OnInit } from '@angular/core';
import { AvionicsService } from '../../../../services/data-services/avionics.service';
import { Avionics } from '../../../../models/aircraft/avionics';
import { ActivatedRoute, Router } from '@angular/router';
import { slideInRight } from '../../../../animations/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-avionics',
  templateUrl: './manage-avionics.component.html',
  styleUrls: ['./manage-avionics.component.scss'],
  animations: [
    slideInRight
  ]
})
export class ManageAvionicsComponent implements OnInit {
  avionics: Avionics;
  subs: any[] = [];
  avionicsForm: FormGroup;

  constructor(private avionicsService: AvionicsService,
              private router: Router,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              public snackbar: MatSnackBar) {
    this.avionics = new Avionics();
    this.createForm();
  }

  ngOnInit() {
    this.subs.push(this.activatedRoute.params.subscribe((params) => {
      this.getAvionics(parseInt(params['id']));
      // console.log(parseInt(params['id']));
    }));
  }

  updateAvionics() {
    this.avionicsService.update(this.avionics)
        .then(() => {
          this.snackbar.open('Updated')
          this.router.navigate(['admin/courses/avionics'])
        })
        .catch((e) => this.avionics = new Avionics());
  }

  getAvionics(id: number) {
    this.avionicsService.getOneAvionics(id)
        .then((avionics) => {
          if (avionics) {
            this.avionics = avionics;
            return;
          }
          this.route();
        });
  }

  createForm() {
    this.avionicsForm = this.fb.group({
      name: ['', Validators.required],
      desc: [''],
    })
  }
  cancel() {
    this.route();
  }

  route() {
    this.router.navigate(['admin/courses/avionics']);
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
