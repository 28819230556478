import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CourseService} from '@app/services/data-services/course.service';
import {User} from '@app/models/user/user';
import {UserCourse} from '@app/models/course/user-course';
import {SnackbarComponent} from '@app/components/shared/snackbar/snackbar.component';
import {Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { FullstoryService } from '@app/services/data-services/fullstory.service';

@Component({
  selector: 'app-user-manager-dialog',
  templateUrl: './user-manager-dialog.component.html',
  styleUrls: ['./user-manager-dialog.component.scss']
})
export class UserManagerDialogComponent implements OnInit {

  users: User[];
  user_courses: UserCourse[];
  user: User;
  remove: boolean;
  reset: boolean;
  update: boolean;
  promote: boolean;
  revoke: boolean;
  salesforceID: string;
  useremail: string;
  connections: boolean;
  isCtc: boolean=false;
  selected_courses: any[];
  embark_job_id: string;
  flight_ops_event_id: string;
  subscription: any;
  disableModalButtonSubject = new BehaviorSubject<boolean>(false);
  disableModalButton$ = this.disableModalButtonSubject.asObservable()

  constructor(public dialogRef: MatDialogRef<UserManagerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private courseService: CourseService,
              private router: Router,
              public snackbar: MatSnackBar,
              private fullstoryService: FullstoryService) {
    this.users = this.data.content;
    this.user = this.data.content;
    this.user_courses = this.data.content;
    this.remove = data.remove;
    this.reset = data.reset;
    this.connections = data.connections;
    this.isCtc = data.ctc

  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  sendMessage(message) {
    this.dialogRef.close(message);
  }

  addInstructor(instructor) {
    this.dialogRef.close(instructor);
  }

  addUsersToCourses(data) {
    this.disableModalButtonSubject.next(true)
    this.selected_courses = data.selected;
    this.embark_job_id = data.embark_job_id;
    this.flight_ops_event_id = data.flight_ops_event_id
    this.courseService.addUsersToCourses(this.selected_courses, this.users, this.embark_job_id, this.flight_ops_event_id)
      .then(res => {
        this.disableModalButtonSubject.next(false)
        let pluralResult = this.selected_courses.length > 1 ? 'Courses added' : 'Course Added'
        this.snackbar.open(pluralResult);
        this.adminAddedUsersFullstory(this.selected_courses)
        this.close()
      });
  }

  adminAddedUsersFullstory(selectedCourses) {
    const coursesForFullstory = selectedCourses.map(course => course.title);
    const userIds = this.users.map(selectedUser => selectedUser.id);
    const userRoles = this.users.map(selectedUser => selectedUser.role);

    this.fullstoryService.event('Admin Added User Course', {
      courses: coursesForFullstory,
      userid_of_user_getting_course: userIds,
      user_role_user_getting_course: userRoles
    })
  }

  resetCourseAttempts(data) {
    this.dialogRef.close(data)
  }

  removeUserFromCourse(data) {
    this.dialogRef.close(data);
  }

  updateSalesforceID(data) {
    this.dialogRef.close(data);
  }

  promoteToAdmin(data) {
    this.dialogRef.close(data);
  }

  revokeAdmin(data) {
    this.dialogRef.close(data);
  }
}
