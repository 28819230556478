import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-route-passthrough',
  templateUrl: './route-passthrough.component.html',
})
export class RoutePassthroughComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
