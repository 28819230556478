import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { M } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import {
  ContentType,
  ContentTypeMap,
} from '@app/models/course/content/content-type';
import { MenuItem, MenuItemName } from '@app/models/menu/menu-item';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import { Editor, Toolbar } from 'ngx-editor';

@Component({
  selector: 'app-content-section-item',
  templateUrl: './content-section-item.component.html',
  styleUrls: ['./content-section-item.component.scss'],
})
export class ContentSectionItemComponent implements OnInit {
  @Input() instructor: boolean = false;
  @Input() item_name: string;

  @Output() emitContent: EventEmitter<any> = new EventEmitter();
  @Output() emitRichText: EventEmitter<any> = new EventEmitter();
  @Output() emitClear: EventEmitter<any> = new EventEmitter();
  text: string;

  form: FormGroup;
  mediaItems: MenuItem[] = [
    {
      name: MenuItemName.Remove,
      action: 'button',
      mat_icon: '',
      image_url: 'trash.png',
    },
  ];

  menu_style = {
    width: '180px;',
    'max-width': '100%;',
    'overflow-x': 'hidden;',
    'background-color': '#000',
  };

  contentTypeMap = ContentTypeMap;
  contentType = ContentType;
  collapsed: boolean = false;
  editor: Editor;
  toolbar: Toolbar = [['bold', 'italic', 'underline', 'strike', 'link']];

  constructor(private rootFormGroup: FormGroupDirective) {
    this.editor = new Editor();
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    this.editor.setContent(this.getRichText());
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  contentArray(): FormArray {
    const type = this.instructor ? 'instructor_contents' : 'contents';
    return this.form.get(type) as FormArray;
  }

  getRichText() {
    const type = this.instructor ? 'instructor_overview' : 'overview';
    if (this.form) {
      return this.form.get(type).value;
    }
  }

  addContent() {
    this.emitContent.emit(this.instructor);
  }
  addRichText(e) {
    let obj = { value: e, instructor: this.instructor };
    this.emitRichText.emit(obj);
  }

  removeContent(e, index) {
    this.contentArray().removeAt(index);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.contentArray().controls,
      event.previousIndex,
      event.currentIndex
    );
    this.contentArray().controls.forEach((content, i) => {
      content.patchValue({ order: i + 1 });
    });
  }
}
