import { Component, OnInit } from '@angular/core';
import { InstructorService } from '@app/services/data-services/instructor.service';
import { UserRole, UserRoleMap } from '@app/models/user/user-role';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CourseService } from '@app/services/data-services/course.service';
import { InstructorManagerDialogComponent } from './instructor-manager-dialog/instructor-manager-dialog.component';
import { ConnectionsService } from '@app/training-centers/connections.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConnectionsDialogData } from '@app/models/connections-dialog-data/connections-dialog-data';
import { ManageConnectionsDialogComponent } from '@app/components/training-centers/connections/manage-connections-dialog/manage-connections-dialog.component';
import { filter, switchMap } from 'rxjs/operators';
import { DialogType } from '@app/models/dialog-type/dialog-type';
import { SelectionModel } from '@angular/cdk/collections';
import { User } from '@app/models/user/user';
import { Instructor } from '@app/models/instructor';
import { ConnectActionType } from '@app/models/connection-type/connection-type';
import { ConnectionStatus } from "@app/models/connection";

@Component({
  selector: 'app-instructor-manager',
  templateUrl: './instructor-manager.component.html',
  styleUrls: ['./instructor-manager.component.scss']
})
export class InstructorManagerComponent implements OnInit {
  rows: any[] = [];
  temp: any[] = [];
  columns: any[] = [];
  dialogRef: any;
  isLoading = false;
  public selection = new SelectionModel<any>(true, []);
  userRoles = UserRole;
  userRoleMap = UserRoleMap;
  isCtc:boolean = false;
  user: User;

  constructor(private router: Router,
              public dialog: MatDialog,
              public snackbar: MatSnackBar,
              private courseService: CourseService,
              private instructorService: InstructorService,
              private connectionService: ConnectionsService,
              route: ActivatedRoute) {
                route.url.subscribe(() => {
                  const { data } = route.snapshot
                  if(data && data.type === 'ctc' )
                    this.isCtc = true;
                 });
              }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('cirrus-user'));
    this.isCtc ? this.getCtcInstructors() : this.getInstructors();
  }

  getInstructors() {
    this.isLoading = true;
    this.instructorService.getInstructors()
      .then(instructors => {
        this.rows = this.temp = instructors;
        this.isLoading = false;
      })
      .catch(err => this.isLoading = false);
  }

  getCtcInstructors() {
    this.connectionService.getInstructors().subscribe(data => {
      this.rows = this.temp = data;
      this.isLoading = false;
    })
  }


  addTrainingPartner() {
    if (!this.selection.selected.length) {
    this.snackbar.open('Please select instructor(s) to add.');
    return;
  }
  if (this.selection.selected.length > 1) {
    this.snackbar.open('Please select ony one instructor.');
    return;
  }
    this.connectionService.getStudents([ConnectionStatus.Connected]).pipe(
      switchMap((trainingPartners) => {
        let trainingPartnerFormat = trainingPartners.map(tp =>{
          return {
            id: tp.student.id,
            name: tp.student.contact_user?.contact?.name,
            email: tp.student.email
          }
          })
        let data: ConnectionsDialogData = {
          viewOnly: false,
          entities: trainingPartnerFormat,
          pluralMap: {'=0': 'Nothing selected', '=1': 'Connect with # partner', 'other': 'Connect with # partners'},
          type: DialogType.Students,
          connectionType: ConnectActionType.Connect
        }
        const config: MatDialogConfig = {
          width: '800px',
          height: '605px',
          data: data
        };

        this.isLoading = false;
        return this.dialog.open(ManageConnectionsDialogComponent, config).afterClosed();
      }),
      filter(selection => selection.length > 0),
      switchMap(partners => this.connectionService.bulkAdd(this.selection.selected, partners)),
    ).subscribe(() => {
      this.snackbar.open('Updated')
      this.selection.clear()
    });
  }

  removeTrainingPartner() {
    if (!this.selection.selected.length) {
      this.snackbar.open('Please select instructor(s) to add.');
      return;
    }
    if (this.selection.selected.length > 1) {
      this.snackbar.open('Please select ony one instructor.');
      return;
    }
      this.connectionService.getAssociatedCtcStudentsForInstructor(this.selection.selected[0].id).pipe(
        switchMap((trainingPartners) => {
          let trainingPartnerFormat = trainingPartners.map(tp =>{
            return {
              id: tp.id,
              name: tp.student.contact_user?.contact?.name,
              email: tp.student.email
            }
            })
          let data: ConnectionsDialogData = {
            viewOnly: false,
            entities: trainingPartnerFormat,
            pluralMap: {'=0': 'Nothing selected', '=1': 'Disconnect with # partner', 'other': `Can't disconnect multiple students`},
            type: DialogType.Students,
            connectionType: ConnectActionType.Disconnect
          }
          const config: MatDialogConfig = {
            width: '800px',
            height: '605px',
            data: data
          };

          this.isLoading = false;
          return this.dialog.open(ManageConnectionsDialogComponent, config).afterClosed();
        }),
        filter(selection => selection.length > 0),
        switchMap(partners => this.connectionService.disconnect(partners[0])),
      ).subscribe(() => {
        this.snackbar.open('Updated')
        this.selection.clear()
      });
  }


  openDialog(content) {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(InstructorManagerDialogComponent, {
      width,
      height,
      data: content
    });
  }

  addToCourse() {
    if (!this.selection.selected.length) {
      this.snackbar.open('Please select instructor(s) to add to a course.');
      return;
    }
    this.openDialog({users: this.selection.selected, remove: false});
    this.dialogRef.afterClosed().subscribe(data => {
      const { selected, embark_job_id, flight_ops_event_id } = data
      if (data) {
        const users: User[] = this.selection.selected.map(({ user_id: id }) => ({ id }));
        this.courseService.addUsersToCourses(selected, users, embark_job_id, flight_ops_event_id)
          .then(() => {
            let pluralResult = data.selected.length > 1 ? 'Courses added' : 'Course Added'
            this.snackbar.open(pluralResult);
          });
      }
    });
  }

  removeFromCourse() {
    if (!this.selection.selected.length) {
      alert('Please select an instructor to remove from a course.');
      return;
    }

    if (this.selection.selected.length > 1) {
      alert('Please select only one instructor to remove from a course.');
      return;
    }

    this.openDialog({users: this.selection.selected, remove: true});

    this.dialogRef.afterClosed().subscribe(selected => {
      if (selected) {
        this.courseService.removeUserFromCourses(selected.selected, this.user)
        .then(res => {
          let pluralResult = selected.length > 1 ? 'Courses removed' : 'Course removed'
          this.snackbar.open(pluralResult);
          this.selection.clear()
        });
      }
    });
  }

  onSelect({ selected }) {
    this.selection.selected.splice(0, this.selection.selected.length);
    this.selection.selected.push(...selected);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function(d) {
      return !val ||
              d.user && d.user.contact && d.user.contact.name.toLowerCase().indexOf(val) !== -1 ||
              d.user && d.user.contact && d.user.contact.phone && d.user.contact.phone.toLowerCase().indexOf(val) !== -1 ||
              d.user && d.user.email.toLowerCase().indexOf(val) !== -1;
    });

    this.rows = temp;
  }

}
