<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="switchAssAct()">Assessment Activity</button>
          <button mat-menu-item (click)="switchAssSum()">Assessment Summary</button>
          <button mat-menu-item (click)="switchBadgeSum()">Badge Summary</button>
          <button mat-menu-item (click)="switchCertSum()">Certificate Summary</button>
          <button mat-menu-item (click)="switchCrsAct()">Course Activity</button>
          <button mat-menu-item (click)="switchCrsSum()">Course Summary</button>
          <button mat-menu-item (click)="switchInsAct()">Instructor Activity</button>
          <button mat-menu-item (click)="switchInsStudentAct()">Instructor Student Activity</button>
          <button mat-menu-item (click)="switchLogSum()">Logbook Summary</button>
          <button mat-menu-item (click)="switchSysAct()">System Activity</button>
          <button mat-menu-item (click)="switchTaskSum()">Task Summary</button>
          <button mat-menu-item (click)="switchTransSum()">Transaction Summary</button>
          <button mat-menu-item (click)="switchUsrAct()">User Activity</button>
          <!-- <hr>
          <button mat-menu-item (click)="reset();courseReport()">Course Report</button>
          <button mat-menu-item (click)="reset();instructorReport()">Instructor Report</button>
          <button mat-menu-item (click)="reset();userReport()">User Report</button> -->
        </mat-menu>
        <button  mat-raised-button color="primary" [matMenuTriggerFor]="appMenu">
          Select Report
        </button>&nbsp;
        <strong>{{ title }}</strong>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div *ngIf="viewFn">

          <mat-form-field>
            <input matInput [matDatepicker]="pick1" placeholder="Start" [(ngModel)]="dt1" (ngModelChange)="clr()">
            <mat-datepicker-toggle matSuffix [for]="pick1"></mat-datepicker-toggle>
            <mat-datepicker #pick1></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input matInput [matDatepicker]="pick2" placeholder="End" [(ngModel)]="dt2" (ngModelChange)="clr()">
            <mat-datepicker-toggle matSuffix [for]="pick2"></mat-datepicker-toggle>
            <mat-datepicker #pick2></mat-datepicker>
          </mat-form-field>

          <span class="form-row" *ngIf="is('assAct')">
              <mat-form-field>
                  <mat-select placeholder="Course" [(ngModel)]="conCrsId" (ngModelChange)="clr(); conId=''">
                    <mat-option *ngFor="let ca of crsAssess" [value]="ca.course_id">{{ca.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="crsAssess && conCrsId">
                  <mat-select placeholder="Assessment" [(ngModel)]="conId" (ngModelChange)="clr()">
                    <mat-option *ngFor="let con of getCrsAssess().array_agg" [value]="con[0]">{{con[1]}}</mat-option>
                  </mat-select>
              </mat-form-field>
              <br>
          </span>

          <span class="form-row" *ngIf="is('assSum')">
            <mat-form-field>
              <mat-select multiple placeholder="Course" [(ngModel)]="courseIds" (ngModelChange)="clr()">
                <!-- <mat-option value="ALL">ALL</mat-option> -->
                <mat-option *ngFor="let c of courses" [value]="c.id">{{c.name}}</mat-option>
              </mat-select>
            </mat-form-field><br>
          </span>

          <span class="form-row crsAct" *ngIf="is('crsAct')">
            <mat-form-field id='course'>
              <mat-select multiple placeholder="Course" [(ngModel)]="courseIds" (ngModelChange)="clr()">
                <!-- <mat-option value="ALL">ALL</mat-option> -->
                <mat-option *ngFor="let c of courses" [value]="c.id">{{c.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>

          <span class="form-row crsSum" *ngIf="is('crsSum')">
          </span>

          <!-- If Select Report = Instructor Activity then show this span -->
          <span class="form-row" *ngIf="is('insAct')">
            <mat-form-field>
              <!-- Instructor Search field -->
                <input matInput placeholder="Instructor Search" [(ngModel)]="insTxt" (ngModelChange)="insSel.open()" >
            </mat-form-field>
            <mat-form-field>
              <!-- Instructor Select field - allows multiple Instructors to be selected -->
              <mat-select multiple (openedChange)="clrOnClose($event, 'insTxt')" [(ngModel)]="insIds" #insSel>
                <mat-option *ngFor="let ins of insFlt()" [value]="ins.id">{{ins.name}}</mat-option>
              </mat-select>
            </mat-form-field><br>
          </span>

          <!-- If Select Report = Instructor Student Activity then show this span -->
          <span class="form-row" *ngIf="is('insStudentAct')">
            <mat-form-field>
              <!-- Instructor Search field -->
                <input matInput placeholder="Instructor Search" [(ngModel)]="insTxt" (ngModelChange)="insSel.open()" >
            </mat-form-field>
            <mat-form-field>
              <!-- Instructor Select field - allows multiple Instructors to be selected -->
              <mat-select multiple (openedChange)="clrOnClose($event, 'insTxt')" [(ngModel)]="insIds" #insSel>
                <mat-option *ngFor="let ins of insFlt()" [value]="ins.id">{{ins.name}}</mat-option>
              </mat-select>
            </mat-form-field><br>
            <mat-radio-group [(ngModel)]="oStatus" (ngModelChange)="clr()">
              <mat-radio-button value="">Any</mat-radio-button>
              <mat-radio-button value="1">Completed</mat-radio-button>
              <mat-radio-button value="0">In Progress</mat-radio-button>
            </mat-radio-group><br><br>
          </span>

          <span class="form-row" *ngIf="is('logSum')">
            <mat-form-field>
              <mat-select multiple placeholder="Course" [(ngModel)]="courseIds" (ngModelChange)="clrLog('courseIds')">
                <mat-option value="ALL">ALL</mat-option>
                <mat-option *ngFor="let c of courses" [value]="c.id">{{c.name}}</mat-option>
              </mat-select>
            </mat-form-field><br>
            <mat-form-field>
                <input matInput placeholder="User Search" [(ngModel)]="usrNameTxt" (ngModelChange)="usrSel.open()" >
            </mat-form-field>
            <mat-form-field>
              <mat-select multiple (openedChange)="clrLog('usrNameTxt'); clrOnClose($event, 'usrNameTxt')" [(ngModel)]="usrIds" #usrSel>
                <mat-option *ngFor="let u of usrFlt()" [value]="u.id">{{u.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Instructor Search" [(ngModel)]="insTxt" (ngModelChange)="insSel.open()" >
            </mat-form-field>
            <mat-form-field>
              <mat-select multiple (openedChange)="clrLog('insTxt'); clrOnClose($event, 'insTxt')" [(ngModel)]="insIds" #insSel>
                <mat-option *ngFor="let ins of insFlt()" [value]="ins.id">{{ins.name}}</mat-option>
              </mat-select>
            </mat-form-field><br>
          </span>

          <span class="xform-row" *ngIf="is('sysAct')"></span>

          <span class="form-row" *ngIf="is('taskSum')">
            <mat-form-field>
                <input matInput placeholder="Task" [(ngModel)]="task" (ngModelChange)="clr()" (keyup.enter) = "view()">
            </mat-form-field>
          </span>

          <span class="form-row" *ngIf="is('transSum')">
            <mat-form-field>
                <input matInput placeholder="User Search" [(ngModel)]="usrNameTxt" (ngModelChange)="usrSel.open()" >
            </mat-form-field>
            <mat-form-field>
              <mat-select multiple (openedChange)="clrOnClose($event, 'usrNameTxt')" [(ngModel)]="usrIds" #usrSel>
                <mat-option *ngFor="let u of usrFlt()" [value]="u.id">{{u.name}}</mat-option>
              </mat-select>
            </mat-form-field><br>
            <!-- <mat-form-field>
              <input matInput placeholder="User Id" [(ngModel)]="uid" (ngModelChange)="clr()" (keyup.enter) = "transSum()">
            </mat-form-field>
            <mat-form-field class="email">
              <input matInput placeholder="Email" [(ngModel)]="email" (ngModelChange)="clr()" (keyup.enter) = "transSum()">
            </mat-form-field><br> -->
            <mat-radio-group [(ngModel)]="oStatus" (ngModelChange)="clr()">
              <mat-radio-button value="">Any</mat-radio-button>
              <mat-radio-button value="1">Completed</mat-radio-button>
              <mat-radio-button value="0">In Progress</mat-radio-button>
            </mat-radio-group>
          </span>

          <span class="form-row usrAct" *ngIf="is('usrAct')">
            <mat-form-field>
              <mat-select placeholder="Training Center" [(ngModel)]="trainId" (ngModelChange)="clrUsrAct('trainId')">
                <!-- <mat-option value="">ALL</mat-option> -->
                <mat-option *ngFor="let t of training" [value]="t.id">{{t.name}}</mat-option>
              </mat-select>
            </mat-form-field><br>
            <mat-form-field>
                <input matInput placeholder="User Search" [(ngModel)]="usrNameTxt" (ngModelChange)="usrSel.open()" >
            </mat-form-field>
            <mat-form-field>
              <mat-select multiple (openedChange)="clrUsrAct('usrNameTxt'); clrOnClose($event, 'usrNameTxt')" [(ngModel)]="usrIds" #usrSel>
                <!-- <mat-option value="ALL">ALL</mat-option> -->
                <mat-option *ngFor="let u of usrFlt()" [value]="u.id">{{u.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
                <input matInput placeholder="Instructor Search" [(ngModel)]="insTxt" (ngModelChange)="insSel.open()" >
            </mat-form-field>
            <mat-form-field>
                <mat-select multiple (openedChange)="clrUsrAct('insTxt'); clrOnClose($event, 'insTxt')" [(ngModel)]="insIds" #insSel>
                  <!-- <mat-option value="ALL">ALL</mat-option> -->
                  <mat-option *ngFor="let ins of insFlt()" [value]="ins.id">{{ins.name}}</mat-option>
                </mat-select>
            </mat-form-field><br>
          </span>

          <button mat-raised-button color="primary" (click)="view()">View</button>
          <span *ngIf="rows.length > 0">
            <button mat-icon-button matTooltip="Download CSV" (click)="csv(csv_a)"><mat-icon class="text-muted">cloud_download</mat-icon></button>
            <button mat-icon-button matTooltip="Print" (click)="print()"><mat-icon class="text-muted">print</mat-icon></button>
            <a #csv_a [hidden]=true [download]="title.replace(' ', '') + '.csv'"></a>
          </span>
        </div>
        <br>

        <div class="mat-box-shadow margin-333" *ngIf="showTable && !is('insStudentAct')">
          <ngx-datatable id="tbl" #table
                         class="material bg-white"
                         [scrollbarH]="true"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows"
                         [columns]="columns">
          </ngx-datatable>
        </div>
        <div class="mat-box-shadow margin-333" *ngIf="showTable && is('insStudentAct')">
          <ngx-datatable id="tbl2" #table
                         class="material bg-white"
                         [scrollbarH]="true"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows"
                         [columns]="columns">
            <ngx-datatable-column name="Student" prop="name" [canAutoResize]="false" [width]="170">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row.name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" [canAutoResize]="false" [width]="170">
              <ng-template let-row='row' ngx-datatable-cell-template>
                <a style="text-decoration: underline; color: blue" href="mailto:{{row.email}}">{{row?.email.toString()}}</a>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Course" prop="cname" [canAutoResize]="false" [width]="180">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row.cname}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="is_completed" [canAutoResize]="false" [width]="150">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{ row.is_completed ? "Completed" : "In Progress"}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Started" prop="created_at" [canAutoResize]="false" [width]="110">
            <ng-template let-row='row' ngx-datatable-cell-template>
              {{row.created_at | date : 'shortDate'}}
            </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Completed" prop="is_completed" [canAutoResize]="false" [width]="110">
              <ng-template let-row='row' ngx-datatable-cell-template>
                <span *ngIf=row.is_completed>{{row.updated_at | date : 'shortDate'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Ground" [canAutoResize]="false" [width]="100">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row.ground}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Flight" [canAutoResize]="false" [width]="100">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row.flight}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Sim" [canAutoResize]="false" [width]="100">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row.sim}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Dual Given" prop="given" [canAutoResize]="false" [width]="100">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row.given}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Inst Approach" [canAutoResize]="false" [width]="100">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row.instApproach}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Landings" [canAutoResize]="false" [width]="100">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row.landings}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              [width]="100">
              <ng-template  let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                <button mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">
                  <mat-icon class="text-muted">more_vert</mat-icon>
                </button>
                <mat-menu #msgMenu="matMenu">
                  <button mat-menu-item (click)='viewDetail(row.course_id, row.user_id)'>View Detail</button>
                </mat-menu>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-spinner *ngIf="spin"></mat-spinner>
  </div>
</div>
<div #image_container>
  <img #image style="visibility: hidden;" [src]="url" alt="" crossOrigin="Anonymous">
</div>
<ng-container *ngIf="export_transcript">
  <app-transcript-course-detail [course_id]="course_id" [user_id]="user_id" (done)="export()"></app-transcript-course-detail>
</ng-container>
