import { TaskAttempt } from './task-attempt';
import { TaskCategory } from 'app/models/course/content/task/task-category';
import {Badge} from '@app/models/course/course-badge';

export class Task {
  id?: number;
  content_id?: number;
  name?: string;
  task_category?: TaskCategory;
  requiredSucessfulAttempts?: number;
  required_successful_attempts?: number;
  status?: number;
  taskAttempts?: TaskAttempt[]; // Removing
  standards?: string[];
  defer_reasons?: string[];
  badge?: Badge;
  order?: number;
  passed?: number;
  missed?: number;
  perfect?: number;
  task_type?: string;

  constructor() {
    this.standards = [];
    this.defer_reasons = [];
    this.task_type = 'practice';
  }
}

export enum TaskType {
  practice = 0,
  perform = 1
}

export const TaskTypeMap = {
  0: 'Practice',
  1: 'Perform'
};

