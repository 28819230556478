import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ContentPlayerComponent} from '../../../course/workbook/content-player/content-player.component';
import {PdfService} from '@app/services/helper/pdf.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-certs-dialog',
  templateUrl: './certs-dialog.component.html',
  styleUrls: ['./certs-dialog.component.scss']
})
export class CertsDialogComponent implements OnInit, AfterViewInit {
  user_certificate = this.data;

  @ViewChild('certCanvas', { static: true }) canvasRef: ElementRef;
  @ViewChild('certImage', { static: true }) certRef: ElementRef;

  ctx: CanvasRenderingContext2D;

  constructor(public dialogRef: MatDialogRef<ContentPlayerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private pdfService: PdfService,
              private sanitizer: DomSanitizer) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.ctx  = this.canvasRef.nativeElement.getContext('2d');
  }

  paint() {
    // console.log('did paint');
    this.ctx.drawImage(this.certRef.nativeElement, 0, 0, 381, 295);

    const user_name = this.data.user.contact && this.data.user.contact.name ? this.data.user.contact.name : 'No Contact Info';
    const aircraft_avionics = this.data.aircraft_name + ' / ' + this.data.avionics_name;
    const date = new Date(this.data.created_at);
    const course_name = this.data.course_name;

    this.ctx.font = '15px Arial';
    const user_name_width = this.ctx.measureText(user_name).width;
    this.ctx.fillText(user_name, (381 / 2) - (user_name_width / 2), 160);

    const course_name_width = this.ctx.measureText(course_name).width;
    this.ctx.fillText(course_name, (381 / 2) - (course_name_width / 2), 195);

    if (this.data.aircraft_name && this.data.avionics_name) {
      this.ctx.font = '8px Arial';
      const aircraft_avionics_width = this.ctx.measureText(aircraft_avionics).width;
      this.ctx.fillText(aircraft_avionics, (381 / 2) - (aircraft_avionics_width / 2), 235);
    }

    const date_width = this.ctx.measureText(date.toLocaleDateString('en-US')).width;
    this.ctx.fillText(date.toLocaleDateString('en-US'), ((381 / 2) - (date_width / 2) + 105), 235);
  }

  download() {
    const image = document.getElementById('cert-image');
    if (image) {
      this.pdfService.download(image, this.data);
    }
  }
}
