import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { LessonFlightLog } from '@app/models/course/workbook/lesson/lesson-flight-log';

@Injectable()
export class LessonFlightLogService {
  lessonFlightLogUrl = 'lesson_flight_logs';

  constructor(private http: HttpService) {}

  getLessonFlightLogs(id: number): Promise<LessonFlightLog[]> {
    const url = `${this.lessonFlightLogUrl}/course/${id}`;
    return this.http.get<LessonFlightLog[]>(url)
      .toPromise()
      .catch(LessonFlightLogService.handleError);
  }

  getLessonFlightLogsCourseDetail(course_id: number, user_id: number): Promise<LessonFlightLog[]> {
    const url = `${this.lessonFlightLogUrl}/user/${user_id}/course/${course_id}`;
    return this.http.get<LessonFlightLog[]>(url)
      .toPromise()
      .catch(LessonFlightLogService.handleError);
  }

  getLessonFlightLogsForCourseAttempt(user_id, course_attempt_id): Promise<LessonFlightLog[]> {
    const url = `${this.lessonFlightLogUrl}/user/${user_id}/course_attempt/${course_attempt_id}`;
    return this.http.get<LessonFlightLog[]>(url)
      .toPromise()
      .catch(LessonFlightLogService.handleError);
  }

  getStudentAssessmentFlightLogsByCourseAttempt(id: string, content_id: number, course_id: number, course_attempt_id: number): Promise<LessonFlightLog[]> {
    const url = `${this.lessonFlightLogUrl}/user/${id}/assessment/${content_id}/course/${course_id}/course_attempt_id/${course_attempt_id}`;
    return this.http.get<LessonFlightLog[]>(url)
      .toPromise()
      .catch(LessonFlightLogService.handleError);
  }

  getStudentFlightLogs(student_id: number, content_version_id: number): Promise<LessonFlightLog[]> {
    const url = `${this.lessonFlightLogUrl}/assessment/${content_version_id}/student/${student_id}`;

    return this.http.get<LessonFlightLog[]>(url)
      .toPromise()
      .catch(LessonFlightLogService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.lessonFlightLogUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(LessonFlightLogService.handleError);
  }

  create(lessonFlightLog: LessonFlightLog): Promise<LessonFlightLog> {
    return this.http
      .post<LessonFlightLog>(this.lessonFlightLogUrl, JSON.stringify(lessonFlightLog))
      .toPromise()
      .catch(LessonFlightLogService.handleError);
  }

  update(lessonFlightLog: LessonFlightLog): Promise<void> {
    const url = `${this.lessonFlightLogUrl}/${lessonFlightLog.id}`;
    return this.http
      .put(url, JSON.stringify(lessonFlightLog))
      .toPromise()
      .then(() => null)
      .catch(LessonFlightLogService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
