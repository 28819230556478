import { Injectable } from '@angular/core';
import { BaseApiV4Service } from '../base-api-v4.service';
import { Observable } from 'rxjs';
import { TrainingPartner } from '@app/models/training-partner';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrainingPartnersService extends BaseApiV4Service {

  getTrainingPartners(include_tcis = false): Observable<TrainingPartner[]> {
    const params = {};
    if (include_tcis) {
      params['include_tcis'] = '';
    }
    return this.http
      .get<TrainingPartner[]>(this.buildRequestUrl('training_partners'), { params });
  }
}
