// import { Component, OnInit } from '@angular/core';
import {Component, Inject, OnInit, Input} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-flightcard-content-player',
  templateUrl: './flightcard-content-player.component.html',
  styleUrls: ['./flightcard-content-player.component.scss']
})
export class FlightcardContentPlayerComponent implements OnInit {
  content: any;

  constructor(public dialogRef: MatDialogRef<FlightcardContentPlayerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.content = this.data;
  }

  close() {
    this.dialogRef.close();
    // this.dialogRef.componentInstance.resizeDialog();
  }
}
