import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Content } from '@app/models/course/content/content';
import { ContentType, ContentTypeMap } from '@app/models/course/content/content-type';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '@app/services/data-services/content.service';
import { slideInRight } from '@app/animations/animations';
import { FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { HttpService } from '@app/services/http-service.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Task, TaskType, TaskTypeMap } from '@app/models/course/content/task/task';
import { AddContentDialogComponent } from '../add-content/add-content-dialog/add-content-dialog.component';
import { CourseType } from '@app/models/course/course-type';
import { ContentTask } from '@app/models/course/content/content-task';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { SnackbarComponent } from "@app/components/shared/snackbar/snackbar.component";
import { Course } from '@app/models/course/course';

@Component({
  selector: 'app-manage-content',
  templateUrl: './manage-content.component.html',
  styleUrls: ['./manage-content.component.scss'],
  animations: [slideInRight],
})
export class ManageContentComponent implements OnInit, OnDestroy {
  separatorKeysCodes = [ENTER, COMMA];
  content_model: Content = new Content();
  content: Content = new Content();
  contentEnum = ContentType;
  map = ContentTypeMap;
  contentType = ContentType;
  placeholder_image: any = null;
  content_type: ContentType;
  selectedQuiz: any;
  selectedExam: any;
  subscription: any;
  dialogRef: MatDialogRef<AddContentDialogComponent>;
  types = ContentType;
  @ViewChild('image', { static: true }) image: any;
  @ViewChild('imageUpload') imageUpload: any;
  imageUrl = '';
  is_loading = false;
  subs = new Subscription();
  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
      ['fontName', 'fontSize', 'color'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
      ['link', 'unlink', 'image', 'code'],
    ],
  };

  taskType: TaskType;
  taskMap = TaskTypeMap;

  public imageUploader: FileUploader = new FileUploader({});
  public fileUploader: FileUploader = new FileUploader({});
  public imageUploadUploader: FileUploader = new FileUploader({});

  private shouldIncrementVersion: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private contentService: ContentService,
    private dragulaService: DragulaService,
    private http: HttpService
  ) {
    this.subs.add(
      this.dragulaService.drop().subscribe(() => {
        this.shouldIncrementVersion = true;
        this.setOrder();
      })
    );
  }

  ngOnInit() {
    this.imageUrl = this.http.getFullUrl(this.imageUrl + 'images/upload/rich_text');
    const url = this.http.getFullUrl('contents/upload');
    this.activatedRoute.params.subscribe(params => {
      if (params['id'] && /^(\d)+$/.test(params['id'])) {
        const id = parseInt(params['id']);
        if (id) {
          this.contentService.getOneContent(id).then(content => {
            if (content) {
              this.content_model = JSON.parse(JSON.stringify(content));
              this.content_type = content.content_type;
              return;
            }
            this.cancel();
          });
          return;
        }
        this.cancel();
      }
    });

    this.imageUploader.setOptions({ url: url, itemAlias: 'image' });
    this.imageUploader.authTokenHeader = 'Access-Token';
    this.imageUploader.authToken = localStorage.getItem('cirrus-token');
    this.imageUploader.onAfterAddingFile = item => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    };
    this.imageUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.content_model.placeholder_image = JSON.parse(response).location;
      if (this.imageUploadUploader.queue.length) {
        this.imageUploadUploader.uploadAll();
      } else if (this.fileUploader.queue.length) {
        this.setFileUploaderOptions(this.content_model.content_type);
      } else {
        this.updateContent();
      }
    };

    this.imageUploadUploader.setOptions({ url: url, itemAlias: 'image' });
    this.imageUploadUploader.authTokenHeader = 'Access-Token';
    this.imageUploadUploader.authToken = localStorage.getItem('cirrus-token');
    this.imageUploadUploader.onAfterAddingFile = item => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    };
    this.imageUploadUploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: ParsedResponseHeaders
    ) => {
      this.content_model.upload_image = JSON.parse(response).location;
      if (this.fileUploader.queue.length) {
        this.setFileUploaderOptions(this.content_model.content_type);
      } else {
        this.updateContent();
      }
    };

    this.fileUploader.setOptions({ url: url, itemAlias: 'file' });
    this.fileUploader.authTokenHeader = 'Access-Token';
    this.fileUploader.authToken = localStorage.getItem('cirrus-token');
    this.fileUploader.onAfterAddingFile = item => {
      this.content_model.content_filename = item._file.name;
      item.withCredentials = false;
    };
    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.content_model.contentFile = JSON.parse(response).location; // file is uploaded here
      this.content_model.blob_directory = JSON.parse(response).blob_directory;
      this.content_model.starter_file = JSON.parse(response).resource_file;
      this.updateContent();
    };
  }

  /**
   * upload()
   *
   * Uploads the content item to the server.
   * Sets is_loading to true, checks for any files in the imageUploader, imageUploadUploader, and fileUploader queues, and sets the fileUploader options accordingly.
   * Hides the content image element.
   */
  upload() {
    this.is_loading = true;
    if (this.imageUploader.queue.length) {
      this.imageUploader.uploadAll();
    } else if (this.imageUploadUploader.queue.length) {
      this.imageUploadUploader.uploadAll();
    } else if (this.fileUploader.queue.length) {
      this.setFileUploaderOptions(this.content_model.content_type);
    } else {
      this.shouldIncrementVersion = true;
      this.updateContent();
    }
    document.getElementById('content-img').style.display = 'none';
  }

  /**
   * Updates the content model, optionally bumping the version number.
   * Note: Setting this.shouldIncrementVersion determines if the version is incremented or not on save.
   */
  updateContent(): void {
    // if content models have tasks, set the sort order
    if (this.content_model.content_tasks) {
      for (let i = 0; i < this.content_model.content_tasks.length; i++) {
        this.content_model.content_tasks[i].order = i;
      }
    }

    if (this.shouldIncrementVersion) this.content_model.version++;

    this.contentService
      .update(this.content_model)
      .then(content => (this.content_model = new Content()))
      .then(() => this.fileUploader.clearQueue())
      .then(() => this.imageUploader.clearQueue())
      .then(() => this.imageUploadUploader.clearQueue())
      .then(() => (this.image.nativeElement.style.hidden = 'hidden'))
      .then(() => {
        this.is_loading = false;
        if (this.imageUpload && this.imageUpload.nativeElement) {
          this.imageUpload.nativeElement.style.hidden = 'hidden';
        }
        this.confirmMessage();
      });
  }
  confirmMessage() {
    const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
      data: 'Updated Content',
    });

    this.subscription = snackBar.afterOpened().subscribe(() => {
      this.router.navigate(['/admin/courses/content']);
    });
  }

  cancel() {
    this.router.navigate(['admin/courses/content']);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.content_model.meta_tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: any): void {
    if (this.content_model.meta_tags) {
      const index = this.content_model.meta_tags.indexOf(tag);

      if (index >= 0) {
        this.content_model.meta_tags.splice(index, 1);
      }
    }
  }

  openDialog(type: string, task?: Task) {
    this.dialogRef = this.dialog.open(AddContentDialogComponent, {
      width: '800px',
      height: '600px',
      data: type,
    });

    if (type === 'task') {
      this.dialogRef.afterClosed().subscribe(data => {
        this.addTasks(data);
        this.shouldIncrementVersion = true;
      });
    } else if (type === 'badge') {
      this.dialogRef.afterClosed().subscribe(data => {
        if (data) {
          task.badge = data;
        }
      });
    } else if (type === 'quiz') {
      this.dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.selectedQuiz = data;
          this.content_model.quiz_id = data.id;
          this.content_model.quiz = data
          this.content_model.exam_id = null
          this.content_model.exam = null
        }
      });
    } else if (type === 'exam') {
      this.dialogRef.afterClosed().subscribe(data => {
        if (data) {
          this.selectedExam = data;
          this.content_model.exam_id = data.id;
          this.content_model.exam = data;
          // rudimentary, but we need to clear out quiz info. apologies in advance
          this.content_model.quiz_id = null
          this.content_model.quiz = null
        }
      });
    }
  }

  /**
   * Adds tasks from the given data array to the content_model's content_tasks array
   * @param {Array} data an array of tasks to add
   */
  addTasks(data) {
    if (data instanceof Array) {
      for (const item of data) {
        const content_task = new ContentTask();
        content_task.task = item;
        content_task.task_id = item.id;
        content_task.required_successful_attempts = 1;

        if (this.content_model.content_tasks) {
          this.content_model.content_tasks.push(content_task);
        } else {
          this.content_model.content_tasks = [];
          this.content_model.content_tasks.push(content_task);
        }
        this.shouldIncrementVersion = true;
      }
    }

    this.setOrder();
  }

  /**
   * Checks if the required_successful_attempts is less than 1 and if so, sets it to 1.
   * @param {object} content_task - The content task object to check
   * @returns {void}
   */
  checkNegAttempt(content_task) {
    this.shouldIncrementVersion = true;
    if (content_task.required_successful_attempts < 1) {
      content_task.required_successful_attempts = 1;
    }
  }

  /**
   * Removes a given task from the content model's content tasks
   * @param {Object} task - The task to be removed from the content model
   */
  removeContentTask(task) {
    if (this.content_model.content_tasks) {
      const index = this.content_model.content_tasks.indexOf(task);
      if (index !== -1) {
        this.content_model.content_tasks.splice(index, 1);
      }
    }
    this.shouldIncrementVersion = true;
  }

  /**
   * Sets the file uploader options for the content type specified
   * @param {contentEnum} content_type - The type of content for which the uploader options are to be set
   */
  setFileUploaderOptions(content_type) {
    let url = '';
    if (content_type === this.contentEnum.scorm) {
      url = this.http.getFullUrl('contents/upload/scorm');
    } else if (content_type === this.contentEnum.interactive) {
      url = this.http.getFullUrl('contents/upload/interactive');
    } else if (content_type === this.contentEnum.tinCan) {
      url = this.http.getFullUrl('contents/upload/tincan');
    } else {
      url = this.http.getFullUrl('contents/upload');
    }

    this.fileUploader.setOptions({ url: url, itemAlias: 'file' });
    this.fileUploader.authTokenHeader = 'Access-Token';
    this.fileUploader.authToken = localStorage.getItem('cirrus-token');
    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      if (status === 201) {
        this.content_model.contentFile = JSON.parse(response).location; // file is uploaded here;
        this.content_model.blob_directory = JSON.parse(response).blob_directory;
        this.content_model.starter_file = JSON.parse(response).resource_file;
        this.updateContent();
      } else {
        alert('There was an error uploading your file');
      }
    };

    this.fileUploader.uploadAll();
  }

  /**
   * Sets the order of the content tasks in the content model.
   * Iterates through the content tasks array and assigns
   * the index of the current task to its order property.
   */
  setOrder() {
    this.content_model.content_tasks = this.content_model.content_tasks.map((task, index) => {
      task.order = index;
      return task;
    });
  }

  /**
   * Updates the task type of the content task at the given index.
   * @param index The index of the content task to update.
   * @param value The new task type to set.
   */
  updateType(index, value) {
    this.shouldIncrementVersion = true;
    this.content_model.content_tasks[index].task_type = value;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
