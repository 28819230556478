import {Component, OnInit, ViewChild} from '@angular/core';
import {DeferReason} from '@app/models/course/content/task/defer-reason';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {DeferReasonService} from '@app/services/data-services/defer-reason.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-defer-reason-overview',
  templateUrl: './defer-reason-overview.component.html',
  styleUrls: ['./defer-reason-overview.component.scss']
})
export class DeferReasonOverviewComponent implements OnInit {
  rows: DeferReason[] = [];
  temp: DeferReason[] = [];

  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private deferReasonService: DeferReasonService, private router: Router) {
    this.getDeferReasons();
  }

  ngOnInit() {
  }

  getDeferReasons() {
    this.deferReasonService.getDeferReasons()
      .then((deferReasons) => this.rows = this.temp = deferReasons);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  editDeferReason(deferReason: DeferReason) {
    this.router.navigate(['admin/courses/task/manage-defer-reason', deferReason.id]);
  }

  removeDeferReason(deferReason: DeferReason) {
    if (confirm('Are you sure you want to remove defer reason?')) {
      this.deferReasonService.delete(deferReason)
        .then(() => this.rows = this.rows.filter(row => row.id !== deferReason.id));
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    const temp = this.temp.filter(function(deferReason) {
      if (deferReason) {
        if (deferReason.reason) {
          return ((deferReason.reason.toLowerCase().indexOf(val) !== -1 || !val));
        } else {
          return false;
        }
      }
    });
    this.rows = temp;
    this.table.offset = 0;
  }

}
