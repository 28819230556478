import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {CourseService} from '@app/services/data-services/course.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {InstructorService} from '@app/services/data-services/instructor.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UserCourse } from '@app/models/course/user-course';
import { Course } from '@app/models/course/course';
import { SelectionModel } from '@angular/cdk/collections';
import { CoursesServiceV4 } from '@app/courses-v4.service';

const sortingDataAccessorRemove = (data: any, sortHeaderId: string): string => {

  switch (sortHeaderId) {
    case 'title':
      return data.course.title.toLowerCase()
    case 'desc':
      return data.course.desc.toLowerCase()
    default:
      return data[sortHeaderId];
  }
};

const sortingDataAccessor= (data: any, sortHeaderId: string): string => {

  switch (sortHeaderId) {
    case 'title':
      return data.title.toLowerCase()
    case 'desc':
      return data.desc.toLowerCase()
    default:
      return data[sortHeaderId];
  }
};


@Component({
  selector: 'app-instructor-manager-dialog',
  templateUrl: './instructor-manager-dialog.component.html',
  styleUrls: ['./instructor-manager-dialog.component.scss']
})
export class InstructorManagerDialogComponent implements OnInit {
  remove = false;
  temp: any[] = [];
  selected = [];
  selectedCourse: any;
  public selection = new SelectionModel<Course>(true, []);
  users: any[] = [];
  isLoading = false;
  @ViewChild('table', { static: true }) table: DatatableComponent;
  public displayedColumns = ['select', 'title', 'desc']
  public rows = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private courseService: CourseService,
              private coursesServiceV4: CoursesServiceV4,
              public dialogRef: MatDialogRef<InstructorManagerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.remove = this.data.remove;
    this.users = this.data.users;
  }

  ngOnInit() {
    this.getCourses();
  }

  ngAfterViewInit(): void {
    this.rows.paginator = this.paginator;
    this.rows.sort = this.sort;
    this.rows.sortingDataAccessor = this.remove ? sortingDataAccessorRemove : sortingDataAccessor;


  }

  getCourses() {
    this.isLoading = true;
    if (this.remove) {
      this.courseService.getMyUserCourses(this.users[0])
        .then(courses => {
          this.rows.data = this.temp = courses ? courses : [];
          this.isLoading = false;
        })
        .catch(err => this.isLoading = false);
      return;
    }
    this.coursesServiceV4.getCourses()
      .subscribe(courses => {
        this.rows.data = this.temp = courses;
        this.isLoading = false;
      })
  }

  isMultipleSelected() {
    return this.numberSelected() > 1;
  }

  numberSelected() {
    return this.selection.selected.length;
  }

  isAnySelected() {
    return this.numberSelected() > 0;
  }

  isAllSelected() {
    return this.numberSelected() === this.rows.data.length;
  }

  toggleSelectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.rows.data.forEach(user => this.selection.select(user));
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  ellipsesLength(str){
    return str.length > 30 ? `${str.slice(0,29)}...` : str
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    let remove = this.remove
    // filter our data
    const temp = this.temp.filter(function (d) {
      let title = remove ? d.course.title : d.title
      let desc = remove ? d.course.desc : d.desc
      return !val ||
      title && title.toLowerCase().indexOf(val) !== -1 ||
        desc && desc.toLowerCase().indexOf(val) !== -1;
    });
    // update the rows
    this.rows.data = temp;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    let obj = {
      selected: this.selection.selected,
    }
      this.dialogRef.close(obj);
  }

}
