import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { FaqCategory } from '@app/models/faq-category';

@Injectable()
export class FaqCategoryService {
  faqCategoryUrl = 'faq_categories';

  constructor(private http: HttpService) { }

  getFaqCategories(): Promise<FaqCategory[]> {
    return this.http.get<FaqCategory[]>(this.faqCategoryUrl)
      .toPromise()
      .catch(FaqCategoryService.handleError);
  }

  getFaqCategory(id: number): Promise<FaqCategory> {
    const url = `${this.faqCategoryUrl}/${id}`;
    return this.http.get<FaqCategory>(url)
      .toPromise()
      .catch(FaqCategoryService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.faqCategoryUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(FaqCategoryService.handleError);
  }

  create(faqCategory: FaqCategory): Promise<FaqCategory> {
    return this.http
      .post<FaqCategory>(this.faqCategoryUrl, JSON.stringify(faqCategory))
      .toPromise()
      .catch(FaqCategoryService.handleError);
  }

  update(faqCategory: FaqCategory): Promise<void> {
    const url = `${this.faqCategoryUrl}/${faqCategory.id}`;
    return this.http
      .put(url, JSON.stringify(faqCategory))
      .toPromise()
      .then(() => null)
      .catch(FaqCategoryService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
