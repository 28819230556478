import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LessonService } from '@app/services/data-services/lesson.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Lesson } from '@app/models/course/workbook/lesson/lesson';
import { LessonTypeMap } from '@app/models/course/workbook/lesson/lesson-type-map';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-lesson-overview',
  templateUrl: './lesson-overview.component.html',
  styleUrls: ['./lesson-overview.component.scss'],
})
export class LessonOverviewComponent implements OnInit {
  selected: any[] = [];
  rows: any[] = [];
  temp: any[] = [];
  lesson: Lesson = new Lesson();
  @ViewChild('table', { static: true }) table: DatatableComponent;
  expanded: any = {};
  isLoading = true;
  archived: any[] = [];
  published: any[] = [];
  lessonTypeMap = LessonTypeMap;
  constructor(
    private router: Router,
    private lessonService: LessonService,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getLessons();
  }

  getLessons(archived = false) {
    this.isLoading = true;
    this.lessonService
      .getLessonsOverview()
      .then(lessons => {
        this.archived = lessons.filter(item => item.is_archived == true);
        this.published = lessons.filter(
          item => item.is_archived == false || item.is_archived == null
        );
        this.rows = this.temp = this.published;

        this.isLoading = false;
      })
      .catch(err => (this.isLoading = false));
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  filterLessons(type, event) {
    this.rows = this.temp = type;
    const element = event.target;
    let active = document.getElementsByClassName('is-active');
    while (active.length) active[0].classList.remove('is-active');
    element.classList.add('is-active');
  }

  archiveLesson(lesson) {
    console.log(lesson);
    this.lessonService.archiveLesson(lesson.id).then(() => {
      this.archived.push(lesson);
      this.rows = this.published = this.published.filter(
        item => item !== lesson
      );
    });
  }
  updateArchive(lesson) {
    lesson.is_archived = !lesson.is_archived;
    this.lessonService
      .updateArchive({ id: lesson.id, is_archived: lesson.is_archived })
      .then(() => {
        if (lesson.is_archived == false) {
          this.snackbar.open('Lesson unarchived');
          this.published.push(lesson);
          this.rows = this.archived = this.archived.filter(
            item => item !== lesson
          );
        } else {
          this.snackbar.open('Lesson archived');
          this.archived.push(lesson);
          this.rows = this.published = this.published.filter(
            item => item !== lesson
          );
        }
      });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    const filterColumns = ['system_name', 'system_desc', 'lesson_type'];
    // filter our data
    // if(val.length > 0) {
    //   this.rows = this.temp.filter(function(row) {
    //     // get the columns and filter to the ones that are configured to be searchable
    //     let rowCols = Object.keys(row).filter(column => filterColumns.length > 0 ? filterColumns.includes(column) : column);
    //     console.log(rowCols);
    //     // set initial value to false (so row isn't arbitrarily returned)
    //     let queryFound = false;
    //
    //     //Loop through the filtered columns and see if the search string matches the value in that column
    //     for(let columnIndex = 0; columnIndex < rowCols.length; columnIndex++) {
    //       // a true value means the whole row can be returned so break the loop to save time
    //       if(queryFound) break;
    //       console.log(row);
    //       console.log(row[rowCols[columnIndex]]);
    //       if(row[rowCols[columnIndex]] == null) continue;
    //       queryFound = row[rowCols[columnIndex]].toLowerCase().indexOf(val) !== -1 || !val;
    //     }
    //     //loop finishes, return row or not
    //     return queryFound;
    //   });
    // }
    const temp = this.temp.filter(function (d) {
      return (
        d.system_name.toLowerCase().indexOf(val) !== -1 ||
        (d.system_desc && d.system_desc.toLowerCase().indexOf(val) !== -1) ||
        !val
      );
    });
    // update the rows
    // Whenever the filter changes, always go back to the first page
    this.rows = temp;
    this.table.offset = 0;
  }

  editLesson(lesson) {
    this.router.navigate(['admin/lessons/' + lesson.id + '/edit']);
  }
  duplicateLesson(lesson) {}
}
