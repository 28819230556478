import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StudentService } from '@app/services/data-services/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassroomService } from '@app/services/data-services/classroom.service';
import { InstructorService } from '@app/services/data-services/instructor.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../../../store/app.reducers';
import * as AssessmentActions from '../student-assessments-layout/store/assessment.actions';
import { ConnectionsService } from '@app/training-centers/connections.service';
import { SelectionModel } from '@angular/cdk/collections';

import { Notification } from '@app/models/notification/notification';
import { NotificationService } from '@app/services/data-services/notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UserManagerDialogComponent } from '@app/ui/admin/user-manager/user-manager-overview/user-manager-dialog/user-manager-dialog.component';
import { User } from '@app/models/user/user';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ManageConnectionsDialogComponent } from '@app/components/training-centers/connections/manage-connections-dialog/manage-connections-dialog.component';
import { filter, switchMap, map, tap } from 'rxjs/operators';
import { DialogType } from '@app/models/dialog-type/dialog-type';
import { GeneralDialogComponent } from '@app/components/shared/general-dialog/general-dialog.component';
import { ConnectionsDialogData } from '@app/models/connections-dialog-data/connections-dialog-data';
import { ConnectActionType } from '@app/models/connection-type/connection-type';
import { ViewAssessmentsDialogComponent } from './view-assessments-dialog/view-assessments-dialog.component';


const sortingDataAccessorCtc = (data: User, sortHeaderId: string): string => {

  switch (sortHeaderId) {
    case 'name':
      return data.contact_user?.contact?.name.toLowerCase()
    case 'phone':
      return data.contact_user?.phone;
    default:
      return data[sortHeaderId];
  }
};

const sortingDataAccessorInstructor = (data: User, sortHeaderId: string): string => {

  switch (sortHeaderId) {
    case 'name':
      return data.contact?.name.toLowerCase()
    case 'phone':
      return data.contact?.contact_user?.phone
    default:
      return data[sortHeaderId];
  }
};
@Component({
  selector: 'app-student-manager',
  templateUrl: './student-manager.component.html',
  styleUrls: ['./student-manager.component.scss']
})
export class StudentManagerComponent implements OnInit, AfterViewInit {
  // rows: any[] = [];
  temp: any[] = [];
  columns: any[];
  selected: any[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;
  isLoading = false;
  sub: any;
  isCtc: boolean = false;
  routePath: string = 'instructor';
  public selection = new SelectionModel<User>(true, []);
  public displayedColumnsCTC = ['select', 'name', 'email', 'phone', 'role', 'status', 'menu'];
  public displayedColumnsInstructor = ['name', 'email', 'phone', 'menu'];
  public displayedColumns = this.displayedColumnsInstructor;
  dialogRef: any;
  viewAssessmentsDialog: MatDialogRef<ViewAssessmentsDialogComponent>;
  public rows = new MatTableDataSource<User>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private classroomService: ClassroomService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private activatedRoute: ActivatedRoute,
    private instructorService: InstructorService,
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
    public snackbar: MatSnackBar,
    public dialog: MatDialog,
    route: ActivatedRoute) {
    route.url.subscribe(() => {
      const { data } = route.snapshot
      if (data && data.type === 'ctc') {
        this.isCtc = true
        this.routePath = 'ctc'
        this.displayedColumns = this.displayedColumnsCTC
      }
    });
  }


  ngOnInit() {
    this.isCtc ? this.getCtcStudents() : this.getStudents();
  }

  ngAfterViewInit(): void {
    this.rows.paginator = this.paginator;
    this.rows.sort = this.sort;
    this.rows.sortingDataAccessor = this.isCtc ? sortingDataAccessorCtc : sortingDataAccessorInstructor;
  }

  getCtcStudents() {
    this.connectionsService.getStudents().subscribe(data => {
      let formatData = data.map(d => {
        let addVars = { status: d.status, c_id: d.id }
        return Object.assign(addVars, d.student);
      })
      this.rows.data = this.temp = formatData;
      this.isLoading = false;
    })
  }

  getStudents() {
    this.isLoading = true;
    this.store.dispatch(new AssessmentActions.ClearSelectedStudent());
    this.instructorService.getStudents()
      .then(students => {
        this.rows.data = this.temp = students;
        this.isLoading = false;
      })
      .catch(err => this.isLoading = false);
  }

  isMultipleSelected() {
    return this.numberSelected() > 1;
  }

  numberSelected() {
    return this.selection.selected.length;
  }

  isAnySelected() {
    return this.numberSelected() > 0;
  }

  isAllSelected() {
    return this.numberSelected() === this.rows.data.length;
  }

  toggleSelectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.rows.data.forEach(user => this.selection.select(user));
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  viewTranscripts(row) {
    this.router.navigate([`/${this.routePath}/classroom/transcripts`, row.id]);
  }

  viewAssignments(row) {
    this.router.navigate([`/${this.routePath}/classroom/assignments/student`, row.id]);
  }

  viewAssessments() {
    this.viewAssessmentsDialog = this.dialog.open(ViewAssessmentsDialogComponent, {
      width: '500px',
      height: '470px',
      panelClass: 'view-assessments-dialog'
    });
  }

  openUserManagerDialog(content) {
    const width = content.type === 'notify' ? '400px' : '800px';
    const height = content.type === 'notify' ? '350px' : '600px';

    let formatContent = this.isCtc ? Object.assign(content, { ctc: true }) : content

    this.dialogRef = this.dialog.open(UserManagerDialogComponent, {
      width,
      height,
      data: formatContent
    });
  }

  TCIConnections(row) {
    const selectedStudentId = row.id
    this.isLoading = true;
    this.connectionsService.getAssociatedCtcInstructorsForStudent(selectedStudentId).pipe(
      switchMap((instructors) => {
        let data: ConnectionsDialogData = {
          viewOnly: true,
          entities: instructors,
          type: DialogType.Connections,
          pluralMap: { '=0': 'Nothing selected', '=1': 'Remove partner' },
          connectionType: ConnectActionType.Connect
        }
        const config: MatDialogConfig = {
          width: '800px',
          height: '605px',
          data: data,
        };

        this.isLoading = false;
        return this.dialog.open(ManageConnectionsDialogComponent, config).afterClosed();
      }),
    ).subscribe(() => this.selection.clear());
  }

  disconnectStudent(row) {
    row.id = row.c_id
    let name;
    this.connectionsService.disconnect(row)
      .subscribe(() => this.rows.data = this.rows.data.map(item => {
        if (item.id === row.id) {
          name = item.contact_user.contact.name
          item.status = 'disconnected'
          this.snackbar.open(`${name} disconnected`)
        }
        return item
      }));
  }

  removeStudent(row) {
    this.instructorService.removeStudent(row.id)
      .then(() => this.rows.data = this.rows.data.filter(item => item.id !== row.id));
  }

  showRemoveStudentAlert(row) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      width: '80%',
      maxWidth: '500px',
      data: {
        dialogTitle: 'Confirm Student Removal',
        dialogBodyContent: 'Are you sure you want to remove the student?',
        showCancelButton: true,
        dialogCancelButtonLabel: 'Cancel',
        dialogConfirmButtonLabel: 'Confirm',
      }
    });

    dialogRef.afterClosed().subscribe(confirmRemove => {
      if (confirmRemove === true) {
        this.isCtc ? this.disconnectStudent(row) : this.removeStudent(row)
      }
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const isCtc = this.isCtc
    // filter our data
    const temp = this.temp.filter(function (d) {
      let name = isCtc ? d.contact_user?.contact?.name : d.contact?.name
      let phone = isCtc ? d.contact_user?.phone : d.contact?.contact_user?.phone
      return !val ||
        name && name.toLowerCase().indexOf(val) !== -1 ||
        d.email && d.email.toLowerCase().indexOf(val) !== -1 ||
        phone && phone.toLowerCase().indexOf(val) !== -1;
    });
    // update the rows
    this.rows.data = temp;
  }

  openDialog(content) {
    const width = content.type === 'notify' ? '400px' : '800px';
    const height = content.type === 'notify' ? '350px' : '600px';

    let formatContent = this.isCtc ? Object.assign(content, { ctc: true }) : content

    this.dialogRef = this.dialog.open(ManageConnectionsDialogComponent, {
      width,
      height,
      data: formatContent
    });
  }

  notify() {
    if (!this.isAnySelected()) {
      this.snackbar.open('Please select user(s) to notify.');
      return;
    }
    this.openUserManagerDialog({ content: this.selection.selected, type: 'notify' });
    this.dialogRef.afterClosed().subscribe(message => {
      const notification = new Notification();
      notification.notific_type = 1;
      notification.sender = new User();
      notification.sender.id = 1;
      if (message) {
        notification.body = message;
        this.notificationService.create(notification, this.selection.selected)
          .then(res => {
            this.snackbar.open('Notification sent!')
            this.selection.clear()
          });
      }
    });
  }

  removeInstructor() {
    if (!this.isAnySelected()) {
      this.snackbar.open('No users selected.');
      return;
    }

    if (this.isMultipleSelected()) {
      this.snackbar.open('Please select one user for this action.');
      return;
    }

    const selectedStudentId = this.selection.selected[0].id

    this.isLoading = true;
    this.connectionsService.getAssociatedCtcInstructorsForStudent(selectedStudentId).pipe(
      switchMap((instructors) => {
        let data: ConnectionsDialogData = {
          viewOnly: false,
          entities: instructors,
          type: DialogType.Instructors,
          pluralMap: { '=0': 'Nothing selected', '=1': 'Remove partner' },
          connectionType: ConnectActionType.Disconnect
        }
        const config: MatDialogConfig = {
          width: '800px',
          height: '605px',
          data: data,
        };
        this.isLoading = false;
        return this.dialog.open(ManageConnectionsDialogComponent, config).afterClosed();
      }),
      switchMap(selection => this.connectionsService.disconnect(selection[0])),
    ).subscribe((instructor) => {
      if (instructor && instructor.name) {
        this.snackbar.open(`${instructor.name} removed`)
      }
      this.selection.clear()
    });
  }

  addInstructors() {
    if (!this.isAnySelected()) {
      this.snackbar.open('No users selected.');
      return;
    }
    this.isLoading = true;
    this.connectionsService.getInstructors().pipe(
      switchMap((instructors) => {
        let data: ConnectionsDialogData = {
          viewOnly: false,
          entities: instructors,
          type: DialogType.Instructors,
          pluralMap: { '=0': 'Nothing selected', '=1': 'Connect with # partner', 'other': 'Connect with # partners' },
          connectionType: ConnectActionType.Connect
        }
        const config: MatDialogConfig = {
          width: '800px',
          height: '605px',
          data: data
        };
        this.isLoading = false;
        return this.dialog.open(ManageConnectionsDialogComponent, config).afterClosed();
      }),
      filter(selection => selection.length > 0),
      switchMap(instructors => this.connectionsService.bulkAdd(instructors, this.selection.selected))
    ).subscribe((instructors) => {
      if (instructors.length === 1) {
        this.snackbar.open(`${instructors[0].name} added!`);
      } else if (instructors.length > 1) {
        this.snackbar.open('Instructors added!')
      }
      this.selection.clear();
    })

  }

  isSelectable(user: User) {
    return user.status.toLowerCase() === 'connected';
  }
}
