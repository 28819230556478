import { Injectable } from '@angular/core';
import { Task } from '@app/models/course/content/task/task';
import {HttpService, IRequestOptions} from '@app/services/http-service.service';
import {TaskAttempt} from '@app/models/course/content/task/task-attempt';

@Injectable()
export class TaskService {
  private taskUrl = 'tasks';

  constructor(private http: HttpService) { }

  getTasksOverview(): Promise<any[]> {
    const url = `${this.taskUrl}`;

    return this.http.get<any[]>(url)
      .toPromise()
      .catch(TaskService.handleError);
  }

  getTasks(): Promise<Task[]> {
    return this.http.get<Task[]>(this.taskUrl)
      .toPromise()
      .catch(TaskService.handleError);
  }

  getTask(id: number): Promise<Task> {
    const url = `${this.taskUrl}/${id}`;
    return this.http.get(url)
      .toPromise()
      .then(response => response as Task)
      .catch(TaskService.handleError);
  }

  remove_attempts(task_attempts: TaskAttempt[]): Promise<Boolean> {
    const url = `task_attempts/remove_attempts`;
    return this.http.post<boolean>(url, {task_attempts})
      .toPromise()
      .catch(TaskService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.taskUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(TaskService.handleError);
  }
  updateTask(task: any): Promise<void> {
    const url = `${this.taskUrl}/update_archive/${task.id}`;
    return this.http
      .put(url, JSON.stringify(task))
      .toPromise()
      .then(() => null)
      .catch(TaskService.handleError);
  }

  taskEntry(task_attempt: TaskAttempt): Promise<TaskAttempt> {
    const url = 'task_attempts';
    return this.http
      .post<TaskAttempt>(url, JSON.stringify(task_attempt))
      .toPromise()
      .catch(TaskService.handleError);
  }

  getEntries(course_id: number): Promise<TaskAttempt[]> {
    const url = `task_attempts/entries/${course_id}`;
    return this.http.get<TaskAttempt[]>(url)
      .toPromise()
      .catch(TaskService.handleError);
  }

  getEntriesByCourseAttempt(course_id: number, course_attempt_id: number): Promise<TaskAttempt[]> {
    const url = `task_attempts/entries/${course_id}/course_attempt_id/${course_attempt_id}`;
    return this.http.get<TaskAttempt[]>(url)
      .toPromise()
      .catch(TaskService.handleError);
  }

  getEntriesCourseDetail(course_id: number, user_id: number): Promise<TaskAttempt[]> {
    const url = `task_attempts/entries/user/${user_id}/course/${course_id}`;
    return this.http.get<Task[]>(url)
      .toPromise()
      .catch(TaskService.handleError);
  }

  create(task: Task): Promise<Task> {
    return this.http
      .post<Task>(this.taskUrl, JSON.stringify(task))
      .toPromise()
      .catch(TaskService.handleError);
  }
  update(task: Task): Promise<void> {
    const url = `${this.taskUrl}/${task.id}`;
    return this.http
      .put(url, JSON.stringify(task))
      .toPromise()
      .then(() => null)
      .catch(TaskService.handleError);
  }
  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
