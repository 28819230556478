import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BookshelfContentType, BookshelfContentTypeMap, ContentBookshelf} from '@app/models/content-bookshelf';
import {BookshelfService} from '@app/services/data-services/bookshelf.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {HttpService} from '@app/services/http-service.service';
import {slideInRight} from '@app/animations/animations';
import { MatChipInputEvent } from '@angular/material/chips';
import {Content} from '@app/models/course/content/content';
import {ContentType} from '@app/models/course/content/content-type';
import {AddContentDialogComponent} from '../../../admin/content-manager/add-content/add-content-dialog/add-content-dialog.component';
import {Task} from '@app/models/course/content/task/task';

@Component({
  selector: 'app-bookshelf-manage-content',
  templateUrl: './bookshelf-manage-content.component.html',
  styleUrls: ['./bookshelf-manage-content.component.scss'],
  animations: [slideInRight]
})
export class BookshelfManageContentComponent implements OnInit {
  content_model = new ContentBookshelf();
  types = BookshelfContentType;
  map = BookshelfContentTypeMap;
  imageUrl = '';
  contentEnum = BookshelfContentType;
  content_type: BookshelfContentType;
  @ViewChild('imageUpload') imageUpload: any;
  is_loading = false;
  editorConfig = {
    "toolbar": [
      ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
      ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
      ["link", "unlink", "image", "code"]
    ]
  };

  accessToken: string;
  separatorKeysCodes = [ENTER, COMMA];
  @ViewChild('image', { static: true }) image: any;

  public imageUploader: FileUploader = new FileUploader({});
  public fileUploader: FileUploader = new FileUploader({});
  public imageUploadUploader: FileUploader = new FileUploader({});

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private bookshelfService: BookshelfService,
              private http: HttpService) { }

  ngOnInit() {
    this.imageUrl = this.http.getFullUrl(this.imageUrl + 'images/upload/rich_text');
    const url = this.http.getFullUrl('contents/upload');
    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] && /^(\d)+$/.test(params['id'])) {
        const id = parseInt(params['id']);
        if (id) {
          this.bookshelfService.getOneContentBookshelf(id)
            .then((content) => {
              if (content) {
                this.content_model = JSON.parse(JSON.stringify(content));
                this.content_type = content.content_type;
                return;
              }
              this.cancel();
            });
          return;
        }
        this.cancel();
      }
    });

    // this.imageUploadUploader.setOptions({url: url, itemAlias: 'image'});
    // this.imageUploadUploader.authTokenHeader = 'Access-Token';
    // this.imageUploadUploader.authToken = localStorage.getItem('cirrus-token');
    // this.imageUploadUploader.onAfterAddingFile = (item => {
    //   document.getElementById('content-img').style.display = 'block';
    //   item.withCredentials = false;
    // });
    // this.imageUploadUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
    //   this.content_model.upload_image = JSON.parse(response).location;
    //   if (this.fileUploader.queue.length) {
    //     this.setFileUploaderOptions(this.content_model.content_type);
    //   } else {
    //     this.updateContent();
    //   }
    // };

    this.fileUploader.setOptions({url: url, itemAlias: 'file'});
    this.fileUploader.authTokenHeader = 'Access-Token';
    this.fileUploader.authToken = localStorage.getItem('cirrus-token');
    this.fileUploader.onAfterAddingFile = (item => {
      this.content_model.content_filename = item._file.name;
      item.withCredentials = false;
    });
    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.content_model.content_file = JSON.parse(response).location;
      this.content_model.content_filename = JSON.parse(response).location;
      this.updateContent();
    };
  }

  upload() {
    this.is_loading = true;
    if (this.imageUploader.queue.length) {
      this.imageUploader.uploadAll();
    } else if (this.imageUploadUploader.queue.length) {
      this.imageUploadUploader.uploadAll();
    } else if (this.fileUploader.queue.length) {
      this.setFileUploaderOptions(this.content_model.content_type);
    } else {
      this.updateContent();
    }
    document.getElementById('content-img').style.display = 'none';
  }

  updateContent() {
    // if (this.content_model.tasks) {
    //   for (let i = 0; i < this.content_model.tasks.length; i++) {
    //     this.content_model.tasks[i].order = i;
    //   }
    // }
    this.content_model.content_type = parseInt(this.types[this.content_model.content_type].toString());
    this.bookshelfService.update(this.content_model)
      .then((content) => this.content_model = new ContentBookshelf())
      .then(() => this.fileUploader.clearQueue())
      .then(() => this.imageUploader.clearQueue())
      .then(() => this.imageUploadUploader.clearQueue())
      .then(() => this.image.nativeElement.style.hidden = 'hidden')
      .then(() => {
        this.is_loading = false;
        if (this.imageUpload && this.imageUpload.nativeElement) {
          this.imageUpload.nativeElement.style.hidden = 'hidden';
        }
      });
  }

  cancel() {
    this.router.navigate(['instructor/bookshelf']);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.content_model.meta_tags.push(value.trim());
      // console.log(this.content_model);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: any): void {
    if (this.content_model.meta_tags) {
      const index = this.content_model.meta_tags.indexOf(tag);

      if (index >= 0) {
        this.content_model.meta_tags.splice(index, 1);
      }
    }
  }

  setFileUploaderOptions(content_type) {
    let url = '';
    if (content_type == this.contentEnum.scorm) {
      url = this.http.getFullUrl('contents/upload/scorm');
    } else if (content_type == this.contentEnum.interactive) {
      url = this.http.getFullUrl('contents/upload/interactive');
    } else if (content_type == this.contentEnum.tinCan) {
      url = this.http.getFullUrl('contents/upload/tincan');
    } else {
      url = this.http.getFullUrl('contents/upload');
    }

    this.fileUploader.setOptions({url: url, itemAlias: 'file'});
    this.fileUploader.authTokenHeader = 'Access-Token';
    this.fileUploader.authToken = localStorage.getItem('cirrus-token');
    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.content_model.content_file = JSON.parse(response).location; // file is uploaded here
      this.updateContent();
    };

    this.fileUploader.uploadAll();
  }
}
