
<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Student Assignments</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class='full-width pb-1'>
          <button mat-raised-button [color]="'primary'" (click)="addAssignment()"> Add Assignment </button>
        </div>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable class="material bg-white" *ngIf="rows && rows.length"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [columnMode]="'force'"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows">
            <ngx-datatable-column name="Due Date" prop='due_date' [pipe]="datePipe"></ngx-datatable-column>
            <ngx-datatable-column name="Assigned By">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row?.instructor?.firstName}} {{row?.instructor?.lastName}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Course" prop='course.name' [width]="200"></ngx-datatable-column>
            <ngx-datatable-column name="Stage" prop='stage.name' [width]="75"></ngx-datatable-column>
            <ngx-datatable-column name="Lesson" prop='lesson.name' [width]="75"></ngx-datatable-column>
            <ngx-datatable-column name="Name" prop='assignment.name' [width]="225"></ngx-datatable-column>
            <ngx-datatable-column name="Status">
              <ng-template let-rowIndex="rowIndex" let-row='row' ngx-datatable-cell-template>
                <mat-checkbox *ngIf="!checkStatus(row); else completed;" (click)="markCompleted(row)">Mark as Complete</mat-checkbox>
              </ng-template>
              <ng-template #completed>
                Completed
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
