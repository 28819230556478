import { Component, OnInit } from '@angular/core';
import { IMenuItem } from '../../../../services/navigation/navigation.service';

@Component({
  selector: 'app-course-dashboard',
  templateUrl: './course-dashboard.component.html',
  styleUrls: ['./course-dashboard.component.scss']
})
export class CourseDashboardComponent implements OnInit {
  courseMenu = [
    {
      name: 'ADMIN DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: '/admin/dashboard'
    },
    {
      name: 'COURSES',
      type: 'link',
      tooltip: 'Courses',
      icon: 'border_color',
      state: '/admin/courses'
    },
    {
      name: 'LESSONS',
      type: 'link',
      tooltip: 'Lessons',
      icon: 'library_books',
      state: '/admin/lessons'
    },
    {
      name: 'CONTENT',
      type: 'link',
      tooltip: 'Content',
      icon: 'library_books',
      state: '/admin/courses/content'
    },
    {
      name: 'AVIONICS',
      type: 'link',
      tooltip: 'Avionics',
      icon: 'track_changes',
      state: '/admin/courses/avionics'
    },
    {
      name: 'AIRCRAFT',
      type: 'link',
      tooltip: 'Aircraft',
      icon: 'airplanemode_active',
      state: '/admin/courses/aircraft'
    },
    {
      name: 'CATEGORIES',
      type: 'link',
      tooltip: 'Categories',
      icon: 'style',
      state: '/admin/courses/category'
    },
    {
      name: 'TASKS',
      type: 'link',
      tooltip: 'Tasks',
      icon: 'format_list_bulleted',
      state: '/admin/courses/task'
    },
    // {
    //   name: 'QUIZZES',
    //   type: 'link',
    //   tooltip: 'Quiz',
    //   icon: 'assignment_turned_in',
    //   state: '/admin/courses/quizzes'
    // },
  ];
  constructor() { }

  ngOnInit() {
  }
}
