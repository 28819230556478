import { Component, OnInit } from '@angular/core';
import {OrderService} from '@app/services/data-services/order.service';
import {Order} from '@app/models/order/order';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  order = new Order();
  tax: number;
  subTotal: number;
  total: number;
  constructor(private orderService: OrderService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private location: LocationStrategy) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        try {
          const id = params['id'];
          this.orderService.getOrder(id)
            .then(order => this.order = order);
        } catch (e) {
          this.location.back();
        }
      } else {
        // this.location.back();
      }
    });
  }

  goBack() {
    this.location.back();
  }

  getTotal(unit_price, tax, quantity) {
    if (unit_price && tax && quantity) {
      return (parseFloat(unit_price) + parseFloat(tax)) * parseInt(quantity);
    } else {
      return null;
    }
  }

  refund() {
    alert('Howdy')
  }
}
