import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-help-video-dialog',
  templateUrl: './help-video-dialog.component.html',
  styleUrls: ['./help-video-dialog.component.scss']
})
export class HelpVideoDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<HelpVideoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
    // this.dialogRef.componentInstance.resizeDialog();
  }


}
