import { Component, OnInit } from '@angular/core';
import { OrderService } from '@app/services/data-services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { Order } from '@app/models/order/order';
import * as CourseActions from '../../course/store/course.actions';
import { Store } from '@ngrx/store';
import * as fromCourse from '../../course/store/course.reducers';
import { UserCourseAgreementDialogComponent } from '../../my-courses/user-course-agreement-dialog/user-course-agreement-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { CourseService } from '@app/services/data-services/course.service';
import { UserCourse } from '@app/models/course/user-course';
import { CoursesServiceV4 } from '@app/courses-v4.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-recent-purchases',
  templateUrl: './recent-purchases.component.html',
  styleUrls: ['./recent-purchases.component.scss'],
})
export class RecentPurchasesComponent implements OnInit {
  order = new Order();
  user_courses = [];
  loading = -1;
  defaultCourseThumb = environment.defaultThumbnailCourse;

  constructor(
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private location: LocationStrategy,
    private courseService: CourseService,
    private store$: Store<fromCourse.State>,
    private coursesServiceV4: CoursesServiceV4
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        try {
          const id = params['id'];
          this.orderService.getRecentPurchase(id).then(res => {
            this.user_courses = res.user_courses;
            this.order = res.order;
          });
          // .catch((e) => this.location.back());
        } catch (e) {
          this.location.back();
        }
      }
    });
  }

  viewReceipt() {
    this.router.navigate(['/receipt', this.order.id]);
  }

  routeToCourse(id) {
    this.coursesServiceV4.continueCourse(id).subscribe(data => {
      const { course_id } = data;
      this.router.navigate([`/courses/${course_id}`]);
    });
  }

  startCourse(user_course: UserCourse) {
    this.store$.dispatch(new CourseActions.HasAgreed());
    this.loading = user_course.id;
    this.courseService
      .getUserCourse(user_course.id)
      .then(res => {
        this.store$.dispatch(new CourseActions.SelectCourse(res));
        this.store$.dispatch(new CourseActions.SetPreview(false));

        if (res.course && res.course.id) {
          this.routeToCourse(res.course.id)
        }
        this.loading = -1;
      })
      .catch(e => {
        this.loading = -1;
      });
  }
}
