import { Injectable } from '@angular/core';
import { User, UserAccount } from '@app/models/user/user';
import { HttpService } from '../http-service.service';
import { Bookmark } from '@app/models/course/bookmark';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { FullstoryService } from './fullstory.service';
import { environment } from 'environments/environment';

declare let pendo: any;

@Injectable()
export class UserService {
  userUrl = 'users';

  constructor(
    private http: HttpService,
    public oidcSecurityService: OidcSecurityService,
    private fullStoryService: FullstoryService,
  ) {}

  idForAnalytics(user: any) {
    if (!environment.analyticsEnabled) {
      return
    }
    this.fullStoryService.identify(user);
    pendo.initialize({
      visitor: { id: user.full_sfid, role: user.role }
    });
  }

  getUserName(id: number): Promise<string> {
    const url = `${this.userUrl}/user_name/${id}`;
    return this.http.get<string>(url).toPromise().catch(UserService.handleError);
  }

  login(user: any): Promise<User> {
    return this.http
      .post<User>('users/signin', JSON.stringify({ username: user.email, password: user.user_id }))
      .toPromise()
      .catch(UserService.handleError);
  }

  getUsers(): Promise<User[]> {
    const url = `${this.userUrl}/all`;
    return this.http.get<User[]>(url).toPromise().catch(UserService.handleError);
  }

  getUserRecords(offset: number = 0): Observable<User[]> {
    const url = `${this.userUrl}/user_records`;
    return this.http.post<User[]>(url, JSON.stringify({ offset: offset }));
  }

  searchUserRecords(filter: string): Observable<User[]> {
    const url = `${this.userUrl}/user_records/search`;
    return this.http.post<User[]>(url, JSON.stringify({ filter: filter }));
  }

  impersonateUser(id: number): Promise<User> {
    const url = `${this.userUrl}/impersonate/${id}`;
    return this.http.get<User>(url).toPromise().catch(UserService.handleError);
  }

  getUser(id: string): Promise<User> {
    const url = `${this.userUrl}/${id}`;
    return this.http.get<User>(url).toPromise().catch(UserService.handleError);
  }

  getBookmarks(): Promise<Bookmark[]> {
    const url = `${this.userUrl}/bookmarks`;
    return this.http.get<Bookmark[]>(url).toPromise().catch(UserService.handleError);
  }

  delete(id: number): Promise<null> {
    const url = `${this.userUrl}/${id}`;
    return this.http
      .delete(url)
      .toPromise()
      .then(() => null)
      .catch(UserService.handleError);
  }

  create(user: any): Promise<User> {
    const url = `${this.userUrl}/signup`;
    return this.http
      .post<User>(url, JSON.stringify({ username: user.email, password: user.user_id }))
      .toPromise()
      .catch(UserService.handleError);
  }

  update(user: User): Promise<User> {
    const url = `${this.userUrl}/${user.id}`;
    return this.http
      .put(url, JSON.stringify(user))
      .toPromise()
      .then(() => user)
      .catch(UserService.handleError);
  }

  updateSFID(user: User): Promise<User> {
    const url = `${this.userUrl}/update_salesforce_id/${user.id}`;
    return this.http
      .put(url, JSON.stringify({ user: user }))
      .toPromise()
      .then(() => user)
      .catch(UserService.handleError);
  }

  promoteToAdmin(user: User): Promise<User> {
    const url = `${this.userUrl}/promote_administrator/${user.id}`;
    return this.http
      .put(url, JSON.stringify({ user: user }))
      .toPromise()
      .then(() => user)
      .catch(UserService.handleError);
  }

  revokeAdmin(user: User): Promise<User> {
    const url = `${this.userUrl}/revoke_administrator/${user.id}`;
    return this.http
      .put(url, JSON.stringify({ user: user }))
      .toPromise()
      .then(() => user)
      .catch(UserService.handleError);
  }

  checkLogin(): Promise<Boolean> {
    const url = `${this.userUrl}/checkUser`;
    return this.http.post<boolean>(url, null).toPromise().catch(UserService.handleError);
  }

  deactivateUsers(users: User[]): Promise<Boolean> {
    const url = `${this.userUrl}/deactivate`;
    return this.http.post<boolean>(url, JSON.stringify({ users })).toPromise().catch(UserService.handleError);
  }

  activateUsers(users: User[]): Promise<Boolean> {
    const url = `${this.userUrl}/activate`;
    return this.http.post<boolean>(url, JSON.stringify({ users })).toPromise().catch(UserService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
