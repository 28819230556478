import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Avionics} from '@app/models/aircraft/avionics';
import {AvionicsService} from '@app/services/data-services/avionics.service';

@Component({
  selector: 'app-add-avionics-dialog',
  templateUrl: './add-avionics-dialog.component.html',
  styleUrls: ['./add-avionics-dialog.component.scss']
})
export class AddAvionicsDialogComponent implements OnInit {
  selected = [];
  rows: Avionics[] = [];
  temp: Avionics[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;
  selectedAvionics: any;

  constructor(public dialogRef: MatDialogRef<AddAvionicsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private avionicsService: AvionicsService) { }

  ngOnInit() {
    this.avionicsService.getAvionics()
      .then(rows => this.rows = this.temp = rows);
  }

  close() {
    this.dialogRef.close();
  }

  onSelect(row) {
    this.selectedAvionics = row.selected[0];
  }

  save() {
    if (this.selectedAvionics) {
      this.dialogRef.close(this.selectedAvionics);
      return;
    }
    this.close();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
}
