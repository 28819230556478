import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {GlobalEventManagerService} from '../../../services/global-event-manager/global-event-manager.service';
import {Router} from "@angular/router";

import { AppModule } from '../../../app.module';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Output() toggle: EventEmitter<any> = new EventEmitter();
  isMobile = false;

  constructor( private globalEventManagerService: GlobalEventManagerService, private router: Router ) {
  }

  barState = 'solid';

  copyrightYear = new Date().getFullYear();

  ngOnInit() {
    if (localStorage.getItem('isMobile') !== null) {
      this.isMobile = true;
    }
    this.globalEventManagerService.topBar.subscribe( ( data: any ) => {
      if ( data === true ) {
        this.barState = 'solid';
      } else {
        this.barState = 'hidden';
      }
    } );
  }

}
