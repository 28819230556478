import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable, isDevMode} from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export function applicationHttpClientCreator(http: HttpClient) {
  return new HttpService(http);
}

@Injectable()
export class HttpService {

  private version = 'v3';

  // Extending the HttpClient through the Angular DI.
  public constructor(public http: HttpClient) {}
  private domain = environment.api_domain;

  /**
   * Build API url.
   * @param url
   * @returns {string}
   */
  public getFullUrl(url: string): string {
    return `${this.domain}/api/${this.version}/${url}`;
  }

  /**
   * GET request
   * @param {string} endPoint it doesn't need / in front of the end point
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.get<T>(this.getFullUrl(endPoint), options);
  }

  /**
   * POST request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.http.post<T>(this.getFullUrl(endPoint), params, options);
  }

  /**
   * PUT request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public put<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.http.put<T>(this.getFullUrl(endPoint), params, options);
  }

  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(this.getFullUrl(endPoint), options);
  }
}
