import { TrainingPartner } from '@app/models/training-partner';
import { User } from './user/user';

export class Connection {
  id: number;
  student: User;
  connectable: TrainingPartner;
  status: ConnectionStatus;
  airport: string;
  name: string;
  email: string;
  training_qualifications: string[];
  location: string;
  phone: string;
}

export enum ConnectionStatus {
  Pending = 'pending',
  Connected = 'connected',
  Declined = 'declined',
  Disconnected = 'disconnected',
}
