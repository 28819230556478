<div id="message-wrapper">
  <div class="message-title-wrapper">
    <h1 mat-dialog-title>Notify ({{count}}) Users</h1>
  </div>
  <mat-dialog-content>
    <div class="form-row">
      <label for="message" class="form-label">Message</label>
      <textarea id="message" name="message" class="form-textarea" [(ngModel)]="message" placeholder="Message" required></textarea>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-wrapper">
      <button mat-button type="button" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!message.length">Send</button>
    </div>
  </mat-dialog-actions>
</div>
