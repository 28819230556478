import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiV4Service } from '../base-api-v4.service';

@Injectable({
  providedIn: 'root'
})
export class QualificationsService extends BaseApiV4Service {

  getAllQualifications(): Observable<string[]> {
    return this.http.get<string[]>(this.buildRequestUrl('training_partners/qualifications'));
  }
}
