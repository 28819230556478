import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { Task } from '@app/models/course/content/task/task';
import { TaskService } from '@app/services/data-services/task.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-add-content-dialog',
  templateUrl: './add-content-dialog.component.html',
  styleUrls: ['./add-content-dialog.component.scss']
})
export class AddContentDialogComponent implements OnInit {
  rows: Task[] = [];
  temp: Task[] = [];
  selected: Task[] = [];
  @ViewChild('table') table: DatatableComponent;

  constructor(private taskService: TaskService,
              public dialogRef: MatDialogRef<AddContentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selected = [];
    this.getTasks();
  }

  ngOnInit() {
  }

  getTasks() {
    this.taskService.getTasks()
      .then((tasks) => this.rows = this.temp = tasks);
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  close() {
    this.dialogRef.close();
  }

  save(item) {
    if (item) {
      this.dialogRef.close(item);
      return;
    }
    this.close();
  }


}
