import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { FeatureService } from '../feature.service';

@Directive({
  selector: '[appLegacy]',
})
export class LegacyDirective implements OnInit {
  @Input() private appLegacy: string;

  constructor(
    private featureService: FeatureService,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) {}

  ngOnInit(): void {
    if (this.appLegacy) {
      this.featureService.isFeatureEnabled('true').subscribe(isEnabled => {
        if (isEnabled) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainerRef.clear();
        }
      });
    }
  }
}
