import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-tin-can',
  templateUrl: './tin-can.component.html',
  styleUrls: ['./tin-can.component.scss']
})
export class TinCanComponent implements OnInit {
  @Input('content') content: any;
  url: any = '';

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.content_file);
  }

}
