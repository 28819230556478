import {Component, OnInit, ViewChild} from '@angular/core';
import {Order, OrderStatus, OrderStatusMap} from '@app/models/order/order';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {OrderService} from '@app/services/data-services/order.service';
import {DatePipe, LocationStrategy} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-user-order-history',
  templateUrl: './user-order-history.component.html',
  styleUrls: ['./user-order-history.component.scss']
})
export class UserOrderHistoryComponent implements OnInit {
  temp: Order[];
  rows: Order[] = [];
  @ViewChild('table') table: DatatableComponent;
  orderStatus = OrderStatus;
  orderStatusMap = OrderStatusMap;
  datePipe = new DatePipe('en-US');

  constructor(private orderService: OrderService,
              private router: Router,
              private location: LocationStrategy,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        try {
          const id = parseInt(params['id']);
          this.orderService.getOrdersByUser(id)
            .then(res => this.temp = this.rows = res);
        } catch (e) {
          this.location.back();
        }
      } else {
        this.orderService.getAllOrders()
          .then(res => this.temp = this.rows = res).catch(e => {
            this.temp = [];
            alert('An unknown error has occurred.');  
          });
      }
    });
  }

  viewOrder(order: Order) {
    this.router.navigate(['/admin/user-order-history/user-order', order.id]);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = (this.temp || []).filter(function(d) {
      return !val ||
        d.id && d.id.toString().toLowerCase().indexOf(val) !== -1 ||
        d.user &&  d.user.email.toString().toLowerCase().indexOf(val) !== -1;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
