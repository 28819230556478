// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: process.env['NODE_ENV'] == 'production',
  profile: process.env['PROFILE_URL'],
  api_domain: process.env['API_URL'],
  sentry_dsn: process.env['SENTRY_DSN'],
  defaultThumbnailCourse:
    'https://cirrusapproachherokuprod.blob.core.windows.net/cirruslmsherokudevcontainer/content-items/images/default-course-thumbnail.jpg',
  stsServer: process.env['AUTHORITY_URI'],
  redirectUrl: process.env['REDIRECT_URL'],
  postLogoutRedirectUri: process.env['POST_LOGOUT_REDIRECT_URL'],
  clientId: process.env['CLIENT_ID'],
  scope: 'openid profile email',
  responseType: 'id_token token',
  logoutUrl: process.env['LOGOUT_URL'],
  fullstoryOrgId: process.env['FULLSTORY_ORG_ID'],
  analyticsEnabled: process.env['NODE_ENV'] == 'production',

  // Remove me once force completion is live...
  force_completion: 'false',
};
