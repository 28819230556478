<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Course Tasks</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class='full-width pb-1'>
          <button mat-raised-button [color]="'primary'" (click)="navigate('admin/courses/task/add')"> Add Task </button>
          <input
            type='text'
            style='padding:8px;margin:15px auto;width:30%;'
            placeholder='Filter Tasks'
            (keyup)='updateFilter($event)'
          />
        </div>
        <div *ngIf="!isLoading" class="full-width pb-1">

          <span class="filter-table is-active" (click)="filterTasks(published, $event)">Published <span class="count">({{ published.length }})</span></span> | <span class="filter-table" (click)="filterTasks(archived, $event)">Archived <span class="count">({{ archived.length }})</span></span>
        </div>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable #table class="material bg-white"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows"
                         [loadingIndicator]="isLoading"
                         [selectAllRowsOnPage]="false"
                         [sorts]="[{ prop: 'id', dir: 'asc' }]"
                         [selectionType]='false'>
            <ngx-datatable-column
              [sortable]="true"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="ID">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{ row.id }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name"></ngx-datatable-column>
            <ngx-datatable-column name="Task Category">
              <ng-template let-row='row' let-value="value" ngx-datatable-cell-template>
                {{ row?.task_category?.name }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false">
              <ng-template let-row='row' ngx-datatable-cell-template>
                <div style="text-align: right">
                  <button mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">
                    <mat-icon class="text-muted">more_vert</mat-icon>
                  </button>
                  <mat-menu #msgMenu="matMenu">
                    <button mat-menu-item (click)='editTask(row)'><mat-icon>edit</mat-icon>Edit</button>
                    <button mat-menu-item (click)='updateArchive(row)'> <mat-icon>{{ row.is_archived ? 'unarchive' : 'archive' }}</mat-icon> {{ row.is_archived ? 'Unarchive' : 'Archive' }}</button>
                  </mat-menu>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
