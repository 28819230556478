import { Injectable } from '@angular/core';
import { Certificate } from '@app/models/course/certificate';
import { HttpService } from '@app/services/http-service.service';
import { UserCertificate } from '@app/models/user/user';

@Injectable()
export class CertificatesService {
  private certUrl = 'certificates';

  constructor(private http: HttpService) {}

  getCertificates(): Promise<Certificate[]> {
    return this.http
      .get<Certificate[]>(this.certUrl)
      .toPromise()
      .catch(CertificatesService.handleError);
  }

  getMyCertificates(): Promise<UserCertificate[]> {
    return this.http
      .get<UserCertificate[]>(`${this.certUrl}/user-certificates`)
      .toPromise()
      .catch(CertificatesService.handleError);
  }

  getStudentCertificate(
    course_id: number,
    user_id: number
  ): Promise<UserCertificate> {
    const url = `${this.certUrl}/course/${course_id}/user/${user_id}`;
    return this.http
      .get<UserCertificate>(url)
      .toPromise()
      .catch(CertificatesService.handleError);
  }

  checkCertificates(course_attempt_id: number, user_id: number): Promise<any> {
    return this.http
      .post(
        `${this.certUrl}/check-certificates/${course_attempt_id}/${user_id}`,
        null
      )
      .toPromise()
      .catch(CertificatesService.handleError);
  }

  getCertificate(id: number): Promise<Certificate> {
    const url = `${this.certUrl}/${id}`;
    return this.http
      .get<Certificate>(url)
      .toPromise()
      .catch(CertificatesService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.certUrl}/${id}`;
    return this.http
      .delete(url)
      .toPromise()
      .then(() => null)
      .catch(CertificatesService.handleError);
  }

  create(certificate: Certificate): Promise<Certificate> {
    return this.http
      .post<Certificate>(this.certUrl, JSON.stringify(certificate))
      .toPromise()
      .catch(CertificatesService.handleError);
  }

  update(certificate: Certificate): Promise<Certificate> {
    const url = `${this.certUrl}/${certificate.id}`;
    return this.http
      .put<Certificate>(url, JSON.stringify(certificate))
      .toPromise()
      .catch(CertificatesService.handleError);
  }

  checkAssociations(certificate: Certificate): Promise<boolean> {
    const url = `${this.certUrl}/checkAssociations/${certificate.id}`;
    return this.http
      .get<boolean>(url)
      .toPromise()
      .catch(CertificatesService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
