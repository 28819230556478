import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { AvionicsService } from '../../../../services/data-services/avionics.service';
import { Avionics } from '../../../../models/aircraft/avionics';
import { slideInRight } from '../../../../animations/animations';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-avionics',
  templateUrl: './avionics.component.html',
  styleUrls: ['./avionics.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AvionicsComponent implements OnInit {
  avionics: Avionics;
  rows: Avionics[] = [];
  temp: Avionics[] = [];
  editing: boolean;
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private avionicsService: AvionicsService, 
              private router: Router,
              public snackbar: MatSnackBar) {
    this.avionics = new Avionics();
    this.getAvionics();
  }

  ngOnInit() {
  }

  getAvionics() {
    this.avionicsService.getAvionics()
        .then(avionics => this.rows = this.temp = avionics);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  editAvionics(avionics) {
    if(avionics.id) {
      this.router.navigate(['admin/courses/avionics/manage', avionics.id] );
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  removeAvionics(avionics) {
    if (confirm('Are you sure you want to remove this item?')) {
      this.avionicsService.delete(avionics.id)
          .then(() => {
            this.snackbar.open('Deleted')
            this.rows = this.rows.filter((item) => item !== avionics)
          });
    }
  }
}
