<h2 class="text-body-header">Your Cart</h2>

<!--If user cart is not empty-->
<div *ngIf="order?.order_line_items?.length; else empty;">
  <div class="course-wrapper-alt">
    <div class="max-width-course"
      fxLayout='column'
      fxFlex="100"
    >
      <div fxFlex="100"
           fxLayout="row"
           fxLayoutAlign="start start" class="top-row">
        <p class="cart-total">{{order?.order_line_items?.length}} Course in Cart</p>
      </div>
      <div 
        fxFlex="100"
        fxLayout="row wrap"
        fxLayoutAlign="start start"
        class="cart-item"
        *ngFor="let item of order.order_line_items"
      >
          <img
            class="course-img"
            [src]="
              item?.product?.thumbnail_image_url
                ? item?.product?.thumbnail_image_url
                : defaultCourseThumb
            "
          />
        <div fxLayout="column" class="course-column">
          <h5 class="course-name">{{item?.product?.title}}</h5>
          <p *ngIf="isEnrolled(item?.product?.id)" class="course-enrolled-warning">You already own this course. Please remove it to continue.</p>
          <p class="remove-txt" (click)="removeItem(item)">Remove</p>
        </div>
        <p class="course-price">{{item?.product?.list_price | currency:'USD'}}</p>
      </div>
    </div>
    <div 
      fxFlex="100"
      fxLayout="column"
      fxLayoutAlign="end end"
    >
      <h6 class="total-txt">Total:</h6>
      <p class="total-price large">{{total | currency:'USD'}}</p>
      <button mat-button class="checkout-btn" *ngIf="isLoggedIn()" (click)="toCheckout()">Checkout</button>
      <button mat-button class="checkout-btn" *ngIf="!isLoggedIn()" (click)="login()">Log In / Sign Up</button>
    </div>
  </div>
</div>

<!--If user cart is empty-->
<ng-template #empty>
  <div class="course-wrapper-alt course-padding">
    <div 
      class="max-width-course"
    >
      <div class="course-number-wrapper">
        <p class="empty-cart">0 Courses in Cart</p>
      </div>

      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        class="empty-cart-wrapper"
      >
        <p class="empty-cart-text">Your cart is empty. Keep shopping to find a course!</p>
        <button mat-button class="keep-shopping-btn" [routerLink]="'/learning-catalog'">Keep Shopping</button>
      </div>
    </div>
  </div>
</ng-template>
