import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {User} from '../../../models/user/user';
import {NotificationType, NotificationTypeMap} from '../../../models/notification/notification-type';
import {NotificationService} from '../../../services/data-services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import {NotificationAdminDialogComponent} from './notification-admin-dialog/notification-admin-dialog.component';
import {AddAvionicsDialogComponent} from '../aircraft-manager/add-aircraft/add-avionics-dialog/add-avionics-dialog.component';
import {Notification} from '@app/models/notification/notification';

@Component({
  selector: 'app-notifications-admin-manager',
  templateUrl: './notifications-admin-manager.component.html',
  styleUrls: ['./notifications-admin-manager.component.scss']
})
export class NotificationsAdminManagerComponent implements OnInit {

  notifications = [];
  selectToggleFlag = false;
  notificationTypeMap = NotificationTypeMap;
  notificationTypes = NotificationType;
  dialogRef: any;

  constructor(private router: Router,
              private notificationService: NotificationService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }

  getNotifications() {
    this.notificationService.getMyNotifications()
      .then(notifications => {
        this.notifications = notifications;
      });
  }

  studentTranscript(user: User) {
    this.router.navigate(['/instructor/classroom/students/transcripts']);
  }

  selectToggleAll() {
    this.selectToggleFlag = !this.selectToggleFlag;
    this.notifications.forEach((msg) => { msg['selected'] = this.selectToggleFlag; });
  }

  openDialog() {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(NotificationAdminDialogComponent, {
      width,
      height
    });
  }

  clearSelectedNotifications() {
    const notifications = this.notifications.filter((msg) => msg['selected']);
    this.notificationService.clearNotifications(notifications)
      .then(() => this.notifications = this.notifications.filter((msg) => !msg['selected']));
  }

  clearMessage(notification: Notification) {
    const notifications = new Array<Notification>();
    notifications.push(notification);
    this.notificationService.clearNotifications(notifications)
      .then(() => this.notifications = this.notifications.filter(notific => notific !== notification));
  }
}
