import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as domHelper from '../../../helpers/dom.helper';
import { Router } from '@angular/router';
import { Notification } from '@app/models/notification/notification';
import { NotificationService } from '@app/services/data-services/notification.service';
import { GlobalEventManagerService } from '@app/services/global-event-manager/global-event-manager.service';
import { environment } from '../../../../environments/environment';
import { IdentityService } from '@app/identity.service';

declare var SFIDWidget: any;

@Component({
  selector: 'app-backend-topbar',
  templateUrl: './backend-topbar.component.html',
  styleUrls: ['./backend-topbar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminTopbarComponent implements OnInit {
  @Input() sidenav;
  @Input() notificPanel;
  notifications: Notification[] = [];
  count: number;
  // TODO Add User Type
  user = JSON.parse(localStorage.getItem('cirrus-user'));

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private globalEventManagerService: GlobalEventManagerService,
    private identityService: IdentityService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('cirrus-token')) {
      this.notificationService.getMyNotifications().then(notifications => (this.notifications = notifications));
      this.globalEventManagerService.updatedNotifications.subscribe(ntfs => (this.notifications = ntfs));
    }
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  toggleCollapse() {
    const appBody = document.body;
    domHelper.toggleClass(appBody, 'collapsed-menu');
    domHelper.removeClass(document.getElementsByClassName('has-submenu'), 'open');
  }

  login() {
    // SFIDWidget.login();
  }

  getImpersonation() {
    if (localStorage.getItem('cirrus-impersonation-return') != null) {
      if (localStorage.getItem('cirrus-impersonation-return') === 'null') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  logoutOfImpersonation() {
    localStorage.setItem('cirrus-token', localStorage.getItem('cirrus-impersonation-return'));
    localStorage.setItem('cirrus-user', localStorage.getItem('cirrus-impersonation-return-user'));
    localStorage.setItem('cirrus-role', localStorage.getItem('cirrus-impersonation-return-role'));

    localStorage.setItem('cirrus-impersonation-return-role', null);
    localStorage.setItem('cirrus-impersonation-return', null);
    localStorage.setItem('cirrus-impersonation-return-user', null);

    this.identityService.endImpersonation();

    this.router.navigate(['/admin/dashboard']);
  }

  logout() {
    this.router.navigate(['/sign-out']);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  hrefSfProfile() {
    try {
      return environment.profile + JSON.parse(localStorage.getItem('cirrus-user')).salesforce_id;
    } catch (x) {}
  }

  getRole() {
    return localStorage.getItem('cirrus-role');
  }

  showCTCDashboard() {
    return this.user.ctc_admin;
  }

  showInstructorDashboard() {
    return this.user.role === 'instructor';
  }

  showAdminDashboard() {
    return this.user.role === 'admin' || this.user.role === 'super-admin';
  }
}
