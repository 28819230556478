import {Lesson} from '../lesson/lesson';
import {Certificate} from '@app/models/course/certificate';
import {Badge} from '@app/models/course/course-badge';

export class Stage {
  id?: number;
  order?: number;
  desc?: string;
  lessons?: Lesson[];
  stage_type?: string;
  name?: string;
  title?: string;
  overview?: string;
  lessons_are_linear?: boolean;
  certificate?: Certificate;
  certificate_id?: number;

  constructor() {
    this.lessons = new Array<Lesson>();
    this.lessons_are_linear = false;
  }
}
