import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from '@app/services/data-services/user.service';
import {User} from '@app/models/user/user';
import {SnackbarComponent} from '@app/components/shared/snackbar/snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { isValidSfId } from '@app/helpers/validators.helper';

@Component({
  selector: 'app-user-update-salesforceid-template',
  templateUrl: './user-update-salesforceid-template.component.html',
  styleUrls: ['./user-update-salesforceid-template.component.scss']
})

export class UserUpdateSalesforceidTemplateComponent implements OnInit {
  rows: any[] = [];
  user_model: User = new User();
  @Input() user: any;
  @Output() update_salesforceid: EventEmitter<any> = new EventEmitter<any>();
  @Input () salesforceID: string;
  @Input () useremail: string;
  @Input () update: boolean;
  salesforceIdIsValid = false;

  subscription: any;

  constructor(private userService: UserService,
              private router: Router,
              public snackbar: MatSnackBar,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }
  updateSalesforceID() {
    this.user_model.id = this.user.id;
    this.update_salesforceid.emit(this.user_model);
  }

  validateSfId() {
    if (isValidSfId(this.user_model.salesforce_id)) {
      this.salesforceIdIsValid = true;  
    } else {
      this.salesforceIdIsValid = false;
    }
  }

  /*updateSalesforceID() {
    this.user_model.id = this.user.id;
    // console.log(this.user_model)
    this.userService.updateSFID(this.user_model)
       .then(() => this.confirmMessage());
    // this.dialog.closeAll();
  }*/

  /*confirmMessage() {
    const snackbar = this.snackbar.openFromComponent(SnackbarComponent, {
      duration: 2500,
      data: 'The Salesforce ID has been updated'
    });
    console.log(this.user_model)
    this.subscription = snackbar.afterOpened().subscribe(() => {
      this.router.navigate(['/admin/user-manager/users']);
    });
  }*/

}
