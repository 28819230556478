import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class InstructorGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const cirrusRole = JSON.parse(localStorage.getItem('cirrus-role'));
    const hasInstructorAccess = ['instructor', 'admin', 'super_admin'].includes(cirrusRole);

    if (hasInstructorAccess) {
      return true;
    } else {
      this.router.navigate(['learning-catalog']);
      return false;
    }
  }
}
