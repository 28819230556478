<mat-nav-list class="backend-sidenav" role="list" *ngIf="!extendedNav; else alt">
  <!--==== Side menu items ====-->
  <div class="icon-menu" *ngIf="hasIconTypeMenuItem">
    <!-- Icon menu separator -->
    <div class="mt-1 mb-1 icon-menu-separator">
      <mat-divider [style.margin]="'0 -24px'"></mat-divider>
      <span [ngStyle]="{paddingLeft: 0, marginLeft: '-6px'}" class="nav-section-title text-muted" >{{iconTypeMenuTitle}}</span>
    </div>
    <!-- Icon menu items -->
    <div class="icon-menu-item" *ngFor="let item of menuItems">
      <button
        *ngIf="!item.disabled && item.type === 'icon'"
        mat-raised-button
        [matTooltip]="item.tooltip"

        routerLink="/{{item.state}}"
        routerLinkActive="mat-bg-primary"><mat-icon>{{item.icon}}</mat-icon></button>
    </div>
  </div>
  <!-- Regular menu items -->
  <div *ngFor="let item of menuItems">
    <!-- if it's not desabled and not a separator and not icon -->
    <mat-list-item
      sideNavAccordion
      class="sidebar-list-item"
      role="listitem"
      *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'"
      [ngClass]="{'has-submenu': item.type === 'dropDown'}"
      routerLinkActive="open">
      <a routerLink="/{{item.state}}" *ngIf="item.type === 'link'" (click)="toggle(item.name)">
        <span
          class="menu-item-tooltip"
          [matTooltip]="item.tooltip"
          matTooltipPosition="right"></span>
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.name}}</span>
      </a>
      <a *ngIf="item.type === 'menuItem'" (click)="toggle(item.name)">
        <span
          class="menu-item-tooltip"
          [matTooltip]="item.tooltip"
          matTooltipPosition="right"></span>
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.name}}</span>
      </a>
      <a *ngIf="item.type === 'dropDown'">
        <span
          class="menu-item-tooltip"
          [matTooltip]="item.tooltip"
          matTooltipPosition="above"></span>
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.name}}</span>
        <span fxFlex></span>
        <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
      </a>
      <a [href]="item.state" *ngIf="item.type === 'extLink'" target="_blank">
        <span
          class="menu-item-tooltip"
          [matTooltip]="item.tooltip"
          matTooltipPosition="right"></span>
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.name}}</span>
      </a>
      <mat-nav-list class="sub-menu" role="list" *ngIf="item.type === 'dropDown'">
        <mat-list-item
          routerLinkActive="selected"
          *ngFor="let subItem of item.sub">
          <a routerLink="{{item.state ? '/'+item.state : ''}}/{{subItem.state}}">{{subItem.name}}</a>
        </mat-list-item>
      </mat-nav-list>
    </mat-list-item>

    <!-- If item type is separator -->
    <div class="mt-1 mb-1" *ngIf="!item.disabled && item.type === 'separator'">
      <mat-divider></mat-divider>
      <span class="nav-section-title text-muted" *ngIf="!!item.name">{{item.name}}</span>
    </div>
  </div>
</mat-nav-list>

<ng-template #alt>
  <mat-nav-list class="extended-sidenav" (mouseleave)="navOpen = false;" role="list" *ngIf="navLinks && navLinks.length && navOpen" @slideInLeft>
    <!--==== Side menu items ====-->
    <div class="icon-menu" *ngIf="hasIconTypeMenuItem">
      <!-- Icon menu separator -->
      <div class="mt-1 mb-1 icon-menu-separator">
        <mat-divider [style.margin]="'0 -24px'"></mat-divider>
        <span [ngStyle]="{paddingLeft: 0, marginLeft: '-6px'}" class="nav-section-title text-muted" >{{iconTypeMenuTitle}}</span>
      </div>
      <!-- Icon menu items -->
      <div class="icon-menu-item" *ngFor="let item of navLinks">
        <button
          *ngIf="!item.disabled && item.type === 'icon'"
          mat-raised-button
          [matTooltip]="item.tooltip"

          routerLink="/{{item.state}}"
          routerLinkActive="mat-bg-primary"><mat-icon>{{item.icon}}</mat-icon></button>
      </div>
    </div>
    <!-- Regular menu items -->
    <div *ngFor="let item of navLinks">
      <!-- if it's not desabled and not a separator and not icon -->
      <mat-list-item
        sideNavAccordion
        class="sidebar-list-item"
        role="listitem"
        *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'"
        [ngClass]="{'has-submenu': item.type === 'dropDown'}"
        routerLinkActive="open">
        <a class="link-item" routerLink="/{{item.state}}" *ngIf="item.type === 'link'" fxLayout="row">
        <span
          class="menu-item-tooltip"
          [matTooltip]="item.tooltip"
          matTooltipPosition="right"></span>
          <span>{{item.name}}</span>
          <span fxFlex></span>
          <mat-icon>{{item.icon}}</mat-icon>
        </a>
        <a *ngIf="item.type === 'dropDown'">
        <span
          class="menu-item-tooltip"
          [matTooltip]="item.tooltip"
          matTooltipPosition="above"></span>
          <span>{{item.name}}</span>
          <mat-icon>{{item.icon}}</mat-icon>
          <span fxFlex></span>
          <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
        </a>
        <a [href]="item.state" *ngIf="item.type === 'extLink'" target="_blank">
        <span
          class="menu-item-tooltip"
          [matTooltip]="item.tooltip"
          matTooltipPosition="right"></span>
          <span>{{item.name}}</span>
          <mat-icon>{{item.icon}}</mat-icon>
        </a>
        <mat-nav-list class="sub-menu" role="list" *ngIf="item.type === 'dropDown'">
          <mat-list-item
            routerLinkActive="selected"
            *ngFor="let subItem of item.sub">
            <a routerLink="{{item.state ? '/'+item.state : ''}}/{{subItem.state}}">{{subItem.name}}</a>
          </mat-list-item>
        </mat-nav-list>
      </mat-list-item>

      <!-- If item type is separator -->
      <div class="mt-1 mb-1" *ngIf="!item.disabled && item.type === 'separator'">
        <mat-divider></mat-divider>
        <span class="nav-section-title text-muted" *ngIf="!!item.name">{{item.name}}</span>
      </div>
    </div>
  </mat-nav-list>
</ng-template>
