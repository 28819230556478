import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Faq } from '@app/models/faq';
import { FaqService } from '@app/services/data-services/faq.service';
import { MatDialog } from '@angular/material/dialog';
import {slideInRight} from '@app/animations/animations';
import {AddFaqCategoryDialogComponent} from './add-faq-category-dialog/add-faq-category-dialog.component';
import {HttpService} from '@app/services/http-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddFaqComponent implements OnInit {
  faq_model: Faq;
  rows: Faq[];
  dialogRef: any;
  imageUrl = '';

  constructor(private http: HttpService, 
              private faqService: FaqService, 
              private router: Router, 
              public dialog: MatDialog,
              public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.faq_model = new Faq();
  }

  createFaq() {
    this.faqService.create(this.faq_model)
      .then(() => {
        this.snackbar.open('Updated')
        this.faq_model = new Faq()
      });
  }

  addFaqCategory() {
    this.openDialog();

    this.dialogRef.afterClosed().subscribe((category) => {
      if (category) {
        this.faq_model.faq_category = category;
      }
    });
  }

  openDialog() {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(AddFaqCategoryDialogComponent, {
      width,
      height
    });
  }

  cancel() {
    this.router.navigate(['admin/faq-category']);
  }
}
