import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Notification} from '@app/models/notification/notification';
import {NotificationService} from '@app/services/data-services/notification.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-instructor-layout',
  templateUrl: './instructor-layout.component.html',
  styleUrls: ['./instructor-layout.component.scss']
})
export class InstructorLayoutComponent implements OnInit {
  public notificationsToken$ = new BehaviorSubject<Notification[]>([]);
  public notifications$ = this.notificationsToken$.pipe();

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    if (localStorage.getItem('cirrus-token')) {
      this.notificationService.getMyNotifications().then((notifications) => {
        this.notificationsToken$.next(notifications);
      });
    }
  }

  isNavOver() {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }
}
