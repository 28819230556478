import {animate, style, transition, trigger} from '@angular/animations';

export const slideInLeft = trigger('slideInLeft', [
  transition(':enter', [
    style({
      left: -200
    }),
    animate(300)
  ]),
  transition(':leave',
    style({
      left: -200
    })
  )
]);

export const slideInRight = trigger('slideInRight', [
  transition(':enter', [
    style({
      right: -200
    }),
    animate(300)
  ]),
  transition(':leave',
    style({
      right: -200
    })
  )
]);

export const expand = trigger('expand', [
  transition('expand', [
    style({
      height: 'intial'
    }),
    animate(300)
  ]),
  transition('collapse',
    style({
      right: '50px'
    })
  ),
  // transition('* <=> *')
]);
