export enum Status {
  not_started = 0,
  in_progress = 1,
  completed = 2,
  failed = 3
}

export const StatusMap = {
  0: 'Not Started',
  1: 'In Progress',
  2: 'Completed',
  3: 'Failed'
};
