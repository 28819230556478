<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Edit Certificate Completion Date</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
<!--            <label class="form-label">The current date of completion is </label>-->
            <mat-form-field>
              <input matInput [matDatepicker]="pick1" placeholder="New Completion Date" [(ngModel)]="dt1" name="reg_date" (ngModelChange)="clr()">
              <mat-datepicker-toggle matSuffix [for]="pick1"></mat-datepicker-toggle>
              <mat-datepicker #pick1></mat-datepicker>
            </mat-form-field>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="updateCompletionDate()">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
