
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {InstructorService} from '@app/services/data-services/instructor.service';
import { ConnectionsService } from '@app/training-centers/connections.service';

@Component({
  selector: 'app-user-instructor-connect-template',
  templateUrl: './user-instructor-connect-template.component.html',
  styleUrls: ['./user-instructor-connect-template.component.scss']
})
export class UserInstructorConnectTemplateComponent implements OnInit {
  @Input() users: any[];
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Input() remove: boolean;
  @Input () connections: boolean;
  @Input() isCtc: boolean
  rows: any[] = [];
  temp: any[] = [];

  columns: any[];
  selected = [];
  isLoading = false;
  selectedInstructor: any;
  @ViewChild('table', { static: true }) table: DatatableComponent;
  public displayedColumns = ['name', 'email'];

  constructor(private instructorService: InstructorService,
              private connectionService: ConnectionsService) {
  }

  ngOnInit() {
    this.isCtc ? this.getCtcInstructors() : this.getInstructors();  
  }


  displayTitle() {
    let userTitle = `${this.users[0]?.contact?.name}'s Instructors` 
    if(this.connections){
      return userTitle
    } else if(this.remove && !this.connections) {
      return 'Remove Instructor'
    } else if(!this.remove && !this.connections) {
      let inst = 'Add Instructor'
      return this.users.length > 1 ? 
        `${inst} ${this.users.length} Users`
        :
        userTitle
    }
  }


    getCtcInstructors() {
      let userId = this.users[0].id;

      this.remove ?   
      this.connectionService.getAssociatedCtcInstructorsForStudent(userId).subscribe(data => {
        this.rows = this.temp = data;
        this.isLoading = false;
      })       
      : 
      this.connectionService.getInstructors().subscribe(data => {
        this.rows = this.temp = data;
        this.isLoading = false;
      })
    }

  getInstructors() {
    this.isLoading = true;
    if (this.remove || this.connections) {
      this.instructorService.getUserInstructors(this.users[0])
        .then(instructors => {
          this.rows = this.temp = instructors;
          this.isLoading = false;
        }).catch(err => this.isLoading = false);
      return;
    }
    this.instructorService.getInstructors()
      .then((instructors) => {
        this.rows = this.temp = instructors;
        this.isLoading = false;
      }).catch(err => this.isLoading = false);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function(d) {
      return !val || d.user && d.user.contact && (d.user.contact.name.toLowerCase().indexOf(val) !== -1 || d.user.email.toLowerCase().indexOf(val) !== -1) ;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  onSelect(row) {
    this.selected = []
    this.selectedInstructor = row
    this.selected.push(this.selectedInstructor)
  }

  save() {
    if (this.selectedInstructor) {
      this.submit.emit(this.selectedInstructor);
    }
  }

}
