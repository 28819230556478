<mat-dialog-content class="content-dialog-wrapper">
  <div class="content-wrapper" fxLayout="column wrap">
    <div class="navtop" fxFlex="100">
      <button mat-icon-button mat-dialog-close (click)="close()"><mat-icon class="close-icon">close</mat-icon></button>
      <input
        type="text"
        style="padding: 8px; margin: 15px auto; width: 30%"
        placeholder="Filter Exams"
        (keyup)="updateFilter($event)"
      />
    </div>
    <div class="full-width content-wrapper">
      <div class="mat-box-shadow margin-333">
        <ngx-datatable
          #table
          class="material bg-white"
          [rows]="rows"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [limit]="50"
          [selected]="selected"
          [selectionType]="'single'"
          [selectAllRowsOnPage]="false"
          (select)="onSelect($event)"
        >
          <ngx-datatable-column name="Name"></ngx-datatable-column>
          <ngx-datatable-column name="Description" prop="desc"></ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex></div>
  <button class="mr-1" mat-raised-button [color]="'primary'" (click)="save()">Add</button>
</mat-dialog-actions>
