<h3 class="text-body-header">Completed</h3>
<div class="loading-spinner" *ngIf="is_loading" style="display: flex; justify-content: center">
  <mat-spinner id="loading-spinner-certificates" class="spinner-white" style="align-self: center;"></mat-spinner>
</div>
<mat-grid-list [cols]="gridSize()" gutterSize="16" [rowHeight]="'5:5'" *ngIf="!is_loading && fltRows.length > 0">
  <mat-grid-tile *ngFor="let row of fltRows">
    <a (click)="openDialog(row)">
      <mat-card class="cert-card" tabindex="0">
        <!--<img class="card-image" mat-card-image [src]='row?.certificate?.certificate_image' />-->
        <img class="card-image cert-img" mat-card-image src="../../../../../assets/images/certificate-thumbnail.jpg" />
      </mat-card>
      <p class='certificate-text'>{{row?.course_name}}</p>
    </a>
  </mat-grid-tile>
</mat-grid-list>

<div class="no-certificates" *ngIf="!is_loading && fltRows.length == 0">
  <p style="text-align: center; font-size: 1rem">You have not completed any course(s) or earned any certificate(s) yet.</p>
</div>
