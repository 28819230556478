import { Component, OnInit } from '@angular/core';

@Component({
  template: `
    <div></div>
  `,
  styleUrls: ['./no-op.component.scss']
})
export class NoOpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
