<h2 class="text-body-header">Library</h2>

<mat-toolbar class="submenu">
    <mat-toolbar-row class="filterMenu course-wrapper maxwrapper">
      <div fxLayout="column" fxLayoutAlign="center center">
            <mat-form-field class='dropdown-width'>
                <mat-select placeholder="Collection" (selectionChange)="filterChanged()" [formControl]='collectionFilter'>
                    <mat-option value="all">Show All</mat-option>
                    <mat-option value="bookmarked">Bookmarked</mat-option>
                    <mat-option value="bookshelf" *ngIf="['instructor', 'admin', 'super_admin'].indexOf(getRole()) > -1">Bookshelf</mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div fxLayout="column" fxLayoutAlign="center center">
            <mat-form-field class='dropdown-width'>
                <mat-select placeholder="Media" [formControl]="mediaFilter" (selectionChange)="filterChanged()">
                    <mat-option value="all">Show All</mat-option>
                    <mat-option *ngFor="let contentType of mediaTypes" [value]="contentType?.type">
                        {{contentType?.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="collectionFilter.value !== 'bookshelf'">
            <mat-form-field class='dropdown-width'>
                <mat-select placeholder="Aircraft" (selectionChange)="filterChanged()" [formControl]="aircraftFilter">
                    <mat-option value="all">Show All</mat-option>
                    <mat-option *ngFor="let aircraft of aircrafts" [value]="aircraft?.id">{{aircraft?.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="collectionFilter.value !== 'bookshelf'">
            <mat-form-field class='dropdown-width'>
                <mat-select placeholder="Avionics" (selectionChange)="filterChanged()" [formControl]="avionicsFilter">
                    <mat-option value="all">Show All</mat-option>
                    <mat-option *ngFor="let avionic of avionics" [value]="avionic?.id">{{avionic?.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="collectionFilter.value !== 'bookshelf'">
            <mat-form-field class='dropdown-width'>
                <mat-select placeholder="Training" [formControl]='trainingFilter' (selectionChange)="filterChanged()">
                    <mat-option value="all">Show All</mat-option>
                    <mat-option *ngFor="let category of categories" [value]="category?.id">{{category?.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex></div>
        <mat-form-field id="searchContainer">
            <input id="search" type="text" placeholder='Search' matInput #searchInput [(ngModel)]="filter"
                   (keyup)='filterChanged()'>
        </mat-form-field>
    </mat-toolbar-row>
</mat-toolbar>

<mat-toolbar class="smallscreen-submenu">
    <mat-toolbar-row class="filterMenu">
        <!--<div fxLayout="column" fxLayoutAlign="center center">-->
            <!--<button mat-button [matMenuTriggerFor]="menu">Filter</button>-->
            <!--<mat-menu #menu="matMenu">-->
                <!--<button mat-menu-item>Item 1</button>-->
                <!--<button mat-menu-item>Item 2</button>-->
            <!--</mat-menu>-->
        <!--</div>-->
        <!--<div fxFlex></div>-->
      <mat-form-field id="searchContainer">
        <input id="search" type="text" placeholder='Search' matInput #searchInput [(ngModel)]="filter"
               (keyup)='filterChanged()'>
      </mat-form-field>
    </mat-toolbar-row>
</mat-toolbar>

<ng-container *ngIf="isLoading">
  <app-loading-spinner></app-loading-spinner>
</ng-container>
<div *ngIf="!isLoading" class="course-wrapper maxwrapper">
  <div *ngIf="mediaCategories.length == 0 && bookshelfMedia.length == 0">
    <h3 class="text-center">There are no content items.</h3>
  </div>
  <div *ngIf="mediaCategories.length != 0">
      <div *ngFor="let contentType of mediaCategories">
          <div class="category-header"
               fxLayoutAlign="center">
              <h4 class="text-body-header">{{contentType?.title}}</h4>
              <div fxFlex></div>
          </div>
        <mat-grid-list [cols]="gridSize()" gutterSize="25" [rowHeight]="'5:6'" class='catalog-grid'>
          <mat-grid-tile *ngFor="let content of contentType?.content">
            <mat-icon class="bookmark" (click)="bookmark(content)"
                      *ngIf="!isBookmarked(content)">bookmark_border
            </mat-icon>
            <mat-icon class="bookmark" (click)="bookmark(content)"
                      *ngIf="isBookmarked(content)">bookmark
            </mat-icon>
            <a *ngIf="content?.content_type !== 6" (click)="openDialog(content)">
              <mat-card class="course-card" tabindex="0">
                <img *ngIf="content?.placeholder_image" class="card-image content-lib-image" mat-card-image src="{{content?.placeholder_image}}"/>
                <img *ngIf="!content?.placeholder_image" class="content-lib-image" mat-card-image src="assets/images/img3.jpeg" alt="">
                <mat-card-content>
                  <mat-card-subtitle class='card-header-text'>{{contentType?.title}}</mat-card-subtitle>
                  <mat-card-title class="line-height"><h6>{{content?.title}}</h6></mat-card-title>
                </mat-card-content>
              </mat-card>
            </a>
            <a *ngIf="content?.content_type == 6" [href]="content?.content_file" TARGET = "_blank" download>
            <!--<a *ngIf="content?.content_type == 'download'" [href]="content?.content_file" download>-->
            <mat-card class="course-card" tabindex="0">
                <img class="card-image content-lib-image" mat-card-image src="{{content?.placeholder_image}}"/>
                <mat-card-content>
                  <mat-card-subtitle class='card-header-text'>{{ contentType?.title }}</mat-card-subtitle>
                  <mat-card-title class="line-height"><h6>{{content?.title}}</h6></mat-card-title>
                </mat-card-content>
              </mat-card>
            </a>
          </mat-grid-tile>
          <ng-container *ngFor="let category of bookshelfMedia">
            <ng-container *ngIf="category.type === contentType.type">
              <mat-grid-tile *ngFor="let content of category?.content">
                <mat-icon class="bookmark" (click)="bookmarkBookshelf(content)"
                          *ngIf="!isBookshelfBookmarked(content)">bookmark_border
                </mat-icon>
                <mat-icon class="bookmark" (click)="bookmarkBookshelf(content)"
                          *ngIf="isBookshelfBookmarked(content)">bookmark
                </mat-icon>
                <a *ngIf="content?.content_type !== 6" (click)="openDialog(content)">
                  <mat-card class="course-card" tabindex="0">
                    <img *ngIf="content?.placeholder_image" class="card-image content-lib-image" mat-card-image src="{{content?.placeholder_image}}"/>
                    <img *ngIf="!content?.placeholder_image" class="content-lib-image" mat-card-image src="assets/images/img3.jpeg" alt="">
                    <mat-card-content>
                      <mat-card-subtitle class='card-header-text'>{{contentType?.title}}</mat-card-subtitle>
                      <mat-card-title class="line-height"><h6>{{content?.title}}</h6></mat-card-title>
                    </mat-card-content>
                  </mat-card>
                </a>
                <a *ngIf="content?.content_type == 6" [href]="content?.content_file" download>
                  <mat-card class="course-card" tabindex="0">
                    <img class="card-image content-lib-image" mat-card-image src="{{content?.placeholder_image}}"/>
                    <mat-card-content>
                      <mat-card-subtitle class='card-header-text'>{{ contentType?.title }}
                      </mat-card-subtitle>
                      <mat-card-title class="line-height"><h6>{{content?.title}}</h6></mat-card-title>
                    </mat-card-content>
                  </mat-card>
                </a>
              </mat-grid-tile>
            </ng-container>
          </ng-container>
        </mat-grid-list>
      </div>
    </div>
    <div *ngIf="bookshelfMedia.length != 0 && mediaCategories.length == 0">
      <div *ngFor="let contentType of bookshelfMedia">
        <div class="category-header"
             fxLayoutAlign="center">
          <h3 class="text-body-header">{{contentType?.title}}</h3>
          <div fxFlex></div>
        </div>
        <mat-grid-list [cols]="gridSize()" gutterSize="25" [rowHeight]="'5:6'" class='catalog-grid'>
          <mat-grid-tile *ngFor="let content of contentType?.content">
            <mat-icon class="bookmark" (click)="bookmarkBookshelf(content)"
                      *ngIf="!isBookshelfBookmarked(content)">bookmark_border
            </mat-icon>
            <mat-icon class="bookmark" (click)="bookmarkBookshelf(content)"
                      *ngIf="isBookshelfBookmarked(content)">bookmark
            </mat-icon>
            <a *ngIf="content?.content_type !== 6" (click)="openDialog(content)">
              <mat-card class="course-card" tabindex="0">
                <img *ngIf="content?.placeholder_image" class="card-image content-lib-image" mat-card-image src="{{content?.placeholder_image}}"/>
                <img *ngIf="!content?.placeholder_image" class="content-lib-image" mat-card-image src="assets/images/img3.jpeg" alt="">
                <mat-card-content>
                  <mat-card-subtitle class='card-header-text'>{{contentType?.title}}</mat-card-subtitle>
                  <mat-card-title class="line-height"><h6>{{content?.title}}</h6></mat-card-title>
                </mat-card-content>
              </mat-card>
            </a>
            <a *ngIf="content?.content_type == 6" [href]="content?.content_file" download>
              <mat-card class="course-card" tabindex="0">
                <img class="card-image content-lib-image" mat-card-image src="{{content?.placeholder_image}}"/>
                <mat-card-content>
                  <mat-card-subtitle class='card-header-text'>{{ contentType?.title }}
                  </mat-card-subtitle>
                  <mat-card-title class="line-height"><h6>{{content?.title}}</h6></mat-card-title>
                </mat-card-content>
              </mat-card>
            </a>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
</div>

<div class="filter-menu-background" *ngIf="catalogMenu" (click)="catalogMenu = false"></div>

<mat-nav-list class="catalog-menu" role="list" *ngIf="catalogMenu">
    <!-- Regular menu items -->
    <div *ngFor="let item of catalogTabs">
        <!-- if it's not disabled and not a separator and not icon -->
        <mat-list-item
                class="sidebar-list-item"
                role="listitem"
                routerLinkActive="open"
                (click)="filterResults(item.state)">
            <a>
      <span
              class="menu-item-tooltip"
              [matTooltip]="item.tooltip"
              matTooltipPosition="right"></span>
                <mat-icon>{{item.icon}}</mat-icon>
                <span>{{item.name}}</span>
            </a>
        </mat-list-item>
    </div>
</mat-nav-list>
