<div class="lesson-page">
  <mat-toolbar>
    <div fxFlex="7">
      <mat-icon (click)="back()" class="back-arrow">arrow_back_ios</mat-icon>
    </div>
    <div fxFlex="100" class="row">
      <mat-tab-group
        class="lesson-tab-bar"
        mat-align-tabs="start"
        [(selectedIndex)]="selectedIndex"
        (selectedTabChange)="myTabSelectedTabChange($event)"
        #tabs
      >
        <mat-tab class="tab-text general" label="General"></mat-tab>
        <mat-tab class="tab-text content" label="Content"></mat-tab>
      </mat-tab-group>
    </div>
  </mat-toolbar>
  <div fxLayout="row" style="background: #efefef">
    <div fxFlex="72" fxLayout="column">
      <div class="add-lesson-container-background">
        <div class="add-lesson-container">
          <div>{{ !editing ? 'Add ' : 'Edit' }} Lesson</div>
          <h1 class="card-title-text no-padding no-margin">{{ tabTitle }}</h1>
        </div>
      </div>

      <div fxFlex="100" fxLayout="row">
        <div
          class="editor-wrapper"
          fxLayout="row wrap"
          fxFlex="grow"
          style="margin: 0 10px; padding-bottom: 40px"
          #editor
        >
          <mat-card class="p-0" fxFlex="grow">
            <mat-card-content>
              <form
                #form="ngForm"
                class="form-padding"
                id="lesson-form"
                [formGroup]="lessonForm"
              >
                <div *ngIf="selectedIndex === 0" class="tab-container">
                  <div class="form-row">
                    <mat-card-content class="no-padding">
                      <div>
                        <label for="name" class="bold-text"
                          >Lesson Title* </label
                        ><span class="sub-input-text"
                          >(up to 60 characters)</span
                        >
                      </div>
                      <div>
                        <input
                          id="lesson-title"
                          name="lesson-title"
                          formControlName="title"
                        />
                        <div
                          *ngIf="
                            formControl.title.errors &&
                            formControl.title.touched
                          "
                        >
                          <div class="error">Title is required.</div>
                        </div>
                      </div>
                    </mat-card-content>
                  </div>
                  <div class="form-row">
                    <mat-card-content class="no-padding">
                      <div>
                        <label for="name" class="bold-text"
                          >Lesson Subtitle </label
                        ><span class="sub-input-text"
                          >(up to 70 characters)</span
                        >
                      </div>
                      <div>
                        <input
                          id="lesson-subtitle"
                          name="lesson-subtitle"
                          formControlName="subtitle"
                          maxlength="70"
                        />
                      </div>
                    </mat-card-content>
                  </div>

                  <div class="form-row pb-1">
                    <app-uploader
                      [subtext]="'2348px x 1620px'"
                      (response_url)="setUrl($event, 'desktop_hero_image_url')"
                      [url]="currentDesktopUrl"
                      title="Desktop Hero Image"
                      editorId="Desktop Hero Image"
                      [uploader_only]="true"
                      [requiredImageWidth]="2348"
                      [requiredImageHeight]="1620"
                    ></app-uploader>
                  </div>

                  <div class="form-row pb-1">
                    <app-uploader
                      [subtext]="'750px x 500px'"
                      (response_url)="setUrl($event, 'mobile_hero_image_url')"
                      [url]="currentMobileUrl"
                      title="Mobile Hero Image"
                      editorId="Mobile Hero Image"
                      [uploader_only]="true"
                      [requiredImageWidth]="750"
                      [requiredImageHeight]="500"
                    ></app-uploader>
                  </div>

                  <div class="form-row pb-1">
                    <app-uploader
                      [subtext]="'240px x 240px'"
                      (response_url)="setUrl($event, 'thumbnail_image_url')"
                      [url]="currentThumbnailUrl"
                      title="Thumbnail Image"
                      editorId="Thumbnail Image"
                      [uploader_only]="true"
                      [requiredImageWidth]="240"
                      [requiredImageHeight]="240"
                    ></app-uploader>
                  </div>

                  <div class="sub-input-text">* = required field</div>
                  <div>
                    <div class="lesson-settings-title">
                      <h1 class="card-title-text">Lesson Settings</h1>
                    </div>
                    <div>
                      <div class="form-row bold-text pb-1">
                        <label for="lesson-type" class="form-label"
                          >Lesson Type*</label
                        >
                        <div class="selection-wrapper">
                          <select
                            id="lesson-type"
                            formControlName="lesson_type"
                            name="type"
                            class="form-select bold-text"
                          >
                            <option [value]="null">Select Lesson Type</option>
                            <option
                              [value]="type.key"
                              *ngFor="let type of lessonTypeMap | keyvalue"
                              [selected]="currentLessonType == type.key"
                            >
                              {{ type.value }}
                            </option>
                          </select>
                          <div
                            *ngIf="
                              formControl.lesson_type.errors &&
                              formControl.lesson_type.touched
                            "
                          >
                            <div class="error">Lesson type is required.</div>
                          </div>
                        </div>
                      </div>

                      <div fxLayout="row" fxLayoutGap="15px">
                        <div
                          class="form-row lesson-type-select pb-1"
                          *ngIf="currentLessonType && displayMinimumGround"
                        >
                          <div>
                            <label for="minimum_ground_hours" class="bold-text"
                              >Minimum Ground Hours
                            </label>
                          </div>
                          <div>
                            <input
                              id="minimum_ground_hours"
                              type="number"
                              formControlName="minimum_ground_hours"
                              name="minimum_ground_hours"
                              step="0.5"
                            />
                          </div>
                          <div
                            *ngIf="
                              formControl.minimum_ground_hours.errors &&
                              formControl.minimum_ground_hours.touched
                            "
                          >
                            <div class="error">Increments of 0.5 only</div>
                          </div>
                        </div>

                        <div
                          class="form-row lesson-type-select pb-1"
                          *ngIf="
                            currentLessonType && displayMinimumFlightOrLandings
                          "
                        >
                          <div>
                            <label for="minimum_flight_hours" class="bold-text"
                              >Minimum Flight Hours
                            </label>
                          </div>
                          <div>
                            <input
                              id="minimum_flight_hours"
                              type="number"
                              formControlName="minimum_flight_hours"
                              name="minimum_flight_hours"
                              step="0.5"
                            />
                          </div>
                          <div
                            *ngIf="
                              formControl.minimum_flight_hours.errors &&
                              formControl.minimum_flight_hours.touched
                            "
                          >
                            <div class="error">Increments of 0.5 only</div>
                          </div>
                        </div>

                        <div
                          class="form-row lesson-type-select pb-1"
                          *ngIf="
                            currentLessonType && displayMinimumFlightOrLandings
                          "
                        >
                          <div>
                            <label for="minimum_landings" class="bold-text"
                              >Minimum Landings
                            </label>
                          </div>
                          <div>
                            <input
                              id="minimum_landings"
                              type="number"
                              formControlName="minimum_landings"
                              name="minimum_landings"
                            />
                          </div>
                        </div>
                        <div
                          class="form-row lesson-type-select pb-1"
                          *ngIf="currentLessonType && displayMinimumSimulator"
                        >
                          <div>
                            <label for="minimum_sim_hours" class="bold-text"
                              >Minimum Sim Hours
                            </label>
                          </div>
                          <div>
                            <input
                              id="minimum_sim_hours"
                              type="number"
                              formControlName="minimum_sim_hours"
                              name="minimum_sim_hours"
                              step="0.5"
                            />
                          </div>
                        </div>
                        <div
                          *ngIf="
                            formControl.minimum_sim_hours.errors &&
                            formControl.minimum_sim_hours.touched
                          "
                        >
                          <div class="error">Increments of 0.5 only</div>
                        </div>
                      </div>

                      <div class="form-row pb-1">
                        <div>
                          <label for="system_name" class="bold-text"
                            >System Name*
                          </label>
                        </div>
                        <div>
                          <input
                            id="system_name"
                            type="text"
                            formControlName="system_name"
                            name="lesson-name"
                          />
                        </div>
                      </div>
                      <div
                        *ngIf="
                          formControl.system_name.errors &&
                          formControl.system_name.touched
                        "
                      >
                        <div class="error">System Name is required.</div>
                      </div>
                      <div class="form-row pb-1">
                        <div>
                          <label for="system_name" class="bold-text"
                            >System Description
                          </label>
                        </div>
                        <div>
                          <input
                            id="lesson-desc"
                            type="text"
                            formControlName="system_desc"
                            name="lesson-desc"
                          />
                        </div>
                      </div>

                      <div class="form-row completion-time pb-1">
                        <div>
                          <label for="system_name" class="bold-text"
                            >Lesson Completion Time
                          </label>
                          <div class="completion-sub-title">
                            Enter hours in increments of .25
                          </div>
                        </div>
                        <div>
                          <input
                            id="completion_time"
                            step=".25"
                            type="number"
                            formControlName="completion_time"
                            name="completion_time"
                          />
                        </div>
                      </div>

                      <div class="form-row pb-1">
                        <mat-checkbox
                          formControlName="contents_are_linear"
                          [color]="'primary'"
                          name="lesson-linear"
                          >Force Content Order
                        </mat-checkbox>
                        <br />
                        <sub
                          >Force lesson content to be completed in order.</sub
                        >
                      </div>
                      <div class="form-row pb-1">
                        <mat-checkbox
                          formControlName="is_preview"
                          [color]="'primary'"
                          name="lesson-preview"
                          >Lesson is Preview Lesson
                        </mat-checkbox>
                        <br />
                        <sub
                          >If this is a preview lesson, then student can view
                          this lesson content without purchasing the
                          course.</sub
                        >
                      </div>
                      <div class="sub-input-text">* = required field</div>
                    </div>
                  </div>
                </div>

                <div
                  class="card-margin tab-container"
                  *ngIf="selectedIndex === 1"
                >
                  <div class="bold-text intro-video-title">INTRO VIDEO</div>

                  <mat-card
                    class="bg-white material no-margin intro-video"
                    [ngStyle]="
                      viewIntroVideoBoxStudent
                        ? { 'padding-bottom': '0px' }
                        : {}
                    "
                  >
                    <div class="space-between">
                      <div>
                        <div class="bold-text">Student</div>

                        <div class="row" fxLayoutAlign="none center">
                          <div>
                            {{
                              student_intro_video_title
                                ? student_intro_video_title
                                : 'No video selected'
                            }}
                          </div>
                          <button
                            class="ignore-item action-btn intro-btn"
                            (click)="
                              viewIntroVideoBoxStudent
                                ? addContent('student_intro')
                                : toggleIntroVideoBox('student')
                            "
                          >
                            {{
                              viewIntroVideoBoxStudent
                                ? student_intro_video_title
                                  ? 'REPLACE'
                                  : 'Select Video'
                                : '+ ADD INTRO VIDEO'
                            }}
                          </button>
                        </div>
                      </div>
                      <div class="menu-container">
                        <mat-card-content
                          fxLayoutAlign="end start"
                          class="no-padding no-margin"
                        >
                          <app-mat-menu
                            [menuIcon]="'more_vert'"
                            [inputItems]="sectionItemsForStudentIntroMenu"
                            [iconStyle]="{ color: 'black' }"
                            [isToggled]="studentIntroVideoHidden"
                            [menu_style]="menu_style_intro"
                            (emitMenuItem)="
                              studentIntroVideoMenuSelection($event)
                            "
                          ></app-mat-menu>
                        </mat-card-content>
                      </div>
                    </div>

                    <div
                      *ngIf="viewIntroVideoBoxStudent"
                      class="intro-video-container"
                      formGroupName="student_intro_video"
                    >
                      <mat-card-content class="no-padding">
                        <div>
                          <label for="name" class="bold-text"
                            >Intro Video Title: </label
                          ><span class="sub-input-text"
                            >(up to 60 characters)</span
                          >
                        </div>
                        <div>
                          <input
                            id="name"
                            type="text"
                            formControlName="title"
                          />
                        </div>
                      </mat-card-content>
                    </div>
                  </mat-card>
                  <mat-card
                    class="material no-margin intro-video-instructor"
                    [ngClass]="
                      viewIntroVideoBoxInstructor
                        ? 'intro-video-instructor-expanded'
                        : 'intro-video-instructor'
                    "
                  >
                    <div class="space-between">
                      <div>
                        <div class="bold-text">Instructor</div>

                        <div class="row" fxLayoutAlign="none center">
                          <div>
                            {{
                              instructor_intro_video_title
                                ? instructor_intro_video_title
                                : 'No video selected'
                            }}
                          </div>
                          <button
                            class="ignore-item action-btn intro-btn"
                            (click)="
                              viewIntroVideoBoxInstructor
                                ? addContent('instructor_intro')
                                : toggleIntroVideoBox('instructor')
                            "
                          >
                            {{
                              viewIntroVideoBoxInstructor
                                ? instructor_intro_video_title
                                  ? 'REPLACE'
                                  : 'Select Video'
                                : '+ ADD INTRO VIDEO'
                            }}
                          </button>
                        </div>
                      </div>
                      <div
                        [ngClass]="
                          viewIntroVideoBoxInstructor
                            ? 'menu-container-expanded'
                            : 'menu-container'
                        "
                      >
                        <mat-card-content
                          fxLayoutAlign="end start"
                          class="no-padding no-margin"
                        >
                          <app-mat-menu
                            [menuIcon]="'more_vert'"
                            [inputItems]="sectionItemsForInstructorIntroMenu"
                            [iconStyle]="{
                              color: 'white',
                              backgroundColor: '#444'
                            }"
                            [isToggled]="instructorIntroVideoHidden"
                            [menu_style]="menu_style_intro"
                            (emitMenuItem)="
                              instructorIntroVideoMenuSelection($event)
                            "
                          ></app-mat-menu>
                        </mat-card-content>
                      </div>
                    </div>
                    <div
                      *ngIf="viewIntroVideoBoxInstructor"
                      class="intro-video-container"
                      formGroupName="instructor_intro_video"
                    >
                      <mat-card-content class="no-padding">
                        <div>
                          <label for="name" class="bold-text"
                            >Intro Video Title: </label
                          ><span class="sub-input-text"
                            >(up to 60 characters)</span
                          >
                        </div>
                        <div>
                          <input
                            id="name"
                            type="text"
                            formControlName="title"
                          />
                        </div>
                      </mat-card-content>
                    </div>
                  </mat-card>

                  <ng-container [formGroup]="lessonForm">
                    <app-content-section
                      [lessonForm]="lessonForm"
                      (emitContent)="emittedContent($event)"
                      (emitRichText)="emittedText($event)"
                      (emitClear)="clearContentSection($event)"
                    ></app-content-section>
                    <app-content-section
                      [instructor]="true"
                      (emitEnabled)="setInstructorEnabled($event)"
                      [lessonForm]="lessonForm"
                      (emitContent)="emittedContent($event)"
                      (emitRichText)="emittedText($event)"
                      (emitClear)="clearContentSection($event)"
                    ></app-content-section>
                  </ng-container>
                </div>
              </form>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="sidebar-panel navigation-hold" fxFlex="26">
      <mat-card class="bg-white material">
        <mat-card-title>
          <div class="card-title-text-status" style="font-weight: bold">
            Lesson Status
          </div>
          <mat-divider></mat-divider>
        </mat-card-title>
        <mat-card-content>
          <div class="editing" *ngIf="editing">
            <div class="last-modified">
              <b>Last modified on:</b> {{ lesson.updated_at | date }}<br />
              @ {{ lesson.updated_at | date : 'shortTime' }}
            </div>
            <div class="version-number">
              <b>Version:</b>
              {{ lesson.major_version + '.' + lesson.minor_version }}
            </div>
            <div class="checkbox">
              <mat-checkbox
                [color]="'primary'"
                [(ngModel)]="setNewVersion"
                [checked]="forceNewVersion || setNewVersion"
                [disabled]="forceNewVersion"
                >Save as new version</mat-checkbox
              >
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer>
          <div *ngIf="isLoading || saveDisabled" class="loading-spinner-container">
            <app-loading-spinner></app-loading-spinner>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="space-between"
            class="lesson-status-btn-container"
          >
            <button mat-raised-button [color]="'danger'" (click)="cancel()">
              Cancel
            </button>
            <button
              mat-raised-button
              [color]="'primary'"
              (click)="upload()"
              [disabled]="!lessonForm.valid || saveDisabled"
              [ngClass]="(!lessonForm.valid || saveDisabled) ? 'disabled-save-btn' : 'save-btn'"
            >
              Save
            </button>
          </div>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
