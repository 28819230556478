import { Component, Input, OnInit } from '@angular/core';
import { IMenuItem, NavigationService } from '@app/services/navigation/navigation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @Input('menuType') menuType;
  @Input('menuItems') menuItems;

  constructor(private navService: NavigationService) { }

  ngOnInit() {
    if (!this.menuItems && this.menuType) {
      this.navService.setMenu(this.menuType);
      this.navService.menuItems$.subscribe(menuItem => {
        this.menuItems = menuItem.filter(item => item.name !== 'DASHBOARD');
      });
    }
  }

  gridSize() {
    return window.matchMedia(`(max-width: 960px)`).matches ? window.matchMedia(`(max-width: 500px)`).matches ? 1 : 2 : 3;
  }
}
