<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Create Badge</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for="badge-name" class="form-label">Name</label>
            <input id="badge-name" name="badge-name" class="form-input" [(ngModel)]="badge.name" placeholder="Name">
          </div>
          <div class="form-row">
            <label for="badge-desc" class="form-label">Description</label>
            <textarea id="badge-desc" name="badge-desc" class="form-textarea" [(ngModel)]="badge.desc" placeholder="Description"></textarea>
          </div>
          <div class="form-row">
            <label for="image-upload" class="form-label">Badge Image</label>
            <div>
              <img id="content-img" #imageUpload src="#" width="175" height="175" alt="" hidden>
            </div>
            <input id="image-upload" #imageUploader type='file' name="image-upload" appAppUploaderPreview [image]="imageUpload" class="form-input" itemid="image-upload" ng2FileSelect [uploader]="uploader">
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="upload()">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
