import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Certificate } from '@app/models/course/certificate';
import { slideInRight } from '@app/animations/animations';
import { CertificatesService } from '@app/services/data-services/certificates.service';
import { HttpService } from '@app/services/http-service.service';
import { FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { jsPDF } from 'jspdf';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-certificate',
  templateUrl: './manage-certificate.component.html',
  styleUrls: ['./manage-certificate.component.scss'],
  animations: [slideInRight],
})
export class ManageCertificateComponent implements OnInit {
  certificate: Certificate = new Certificate();
  showTable = false;
  rows: any[] = [];
  dt1;

  public uploader: FileUploader = new FileUploader({});
  downloadedImg: any;

  constructor(
    private router: Router,
    private certificateService: CertificatesService,
    private httpService: HttpService,
    private activatedRoute: ActivatedRoute,
    public snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initUploader();
    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] && /^(\d)+$/.test(params['id'])) {
        const id = parseInt(params['id']);
        if (id) {
          this.certificateService.getCertificate(id).then((cert) => {
            if (cert) {
              this.certificate = JSON.parse(JSON.stringify(cert));
              return;
            }
            this.cancel();
          });
          return;
        }
        this.cancel();
      }
    });
  }

  clr() {
    this.showTable = false;
    this.rows = [];
  }

  upload() {
    if (this.uploader.queue.length) {
      this.uploader.uploadAll();
    } else {
      this.updateCertificate();
    }
  }

  updateCertificate() {
    this.certificateService.update(this.certificate).then(() => {
      this.snackbar.open('Changes Saved');
      this.cancel();
    });
  }

  initUploader() {
    const url = this.httpService.getFullUrl('images/upload');
    this.uploader.setOptions({ url: url });
    this.uploader.authTokenHeader = 'Access-Token';
    this.uploader.authToken = localStorage.getItem('cirrus-token');
    this.uploader.onAfterAddingFile = (item) => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    };
    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: ParsedResponseHeaders
    ) => {
      this.certificate.certificate_image = JSON.parse(response).location;
      this.updateCertificate();
    };
  }

  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    // 1650 × 1275
    // canvas.width = 1122.519685;
    // canvas.height = 793.7007874;

    canvas.width = 1650;
    canvas.height = 1275;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  download() {
    // console.log('manage certificate', this.uploader, this.certificate);
    if (this.uploader.queue.length || this.certificate.certificate_image) {
      // const doc = new jsPDF('A4');
      // const doc = new jsPDF('l', 'mm', [297, 210]);
      const doc = new jsPDF('l', 'mm', 'a4');
      let imageData: any;

      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();

      // when not uploaded image is fuzzy, logic is same, perhaps browser / memory managment issue
      if (this.uploader.queue.length) {
        imageData = this.getBase64Image(document.getElementById('content-img'));
      } else {
        imageData = this.getBase64Image(
          document.getElementById('certificate-img')
        );
      }

      doc.addImage(imageData, 'PNG', 0, 0, width, height);
      doc.setFontSize(32);
      doc.text('John Snow', 118, 112);
      doc.setFontSize(20);
      doc.text('SR22TN Advanced Transition Training Course', 82, 138);
      doc.setFontSize(13);
      doc.text('SR22TN / Garmin Perspective +', 116, 166);
      doc.text('11/14/1987', 220, 166);
      doc.setFontSize(10);
      doc.text('Cert: 1234567890', 227, 194);
      doc.save('Certificate.pdf');
    } else {
      alert('Please upload certificate to preview');
    }
  }

  cancel() {
    this.router.navigate(['admin/certificates']);
  }
}
