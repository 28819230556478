import {Directive, ElementRef, HostListener, Input, OnChanges, Renderer2} from '@angular/core';

@Directive({
  selector: '[appAppUploaderPreview]'
})
export class AppUploaderPreviewDirective implements OnChanges {
  @Input('image') image: ElementRef;

  constructor(private uploader: ElementRef, private renderer: Renderer2) { }

  @HostListener('change') ngOnChanges() {
    // console.log(this.uploader, this.image);
    if (this.uploader.nativeElement.files && this.uploader.nativeElement.files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.renderer.setAttribute(this.image, 'src', e.target.result);
      };

      reader.readAsDataURL(this.uploader.nativeElement.files[0]);
      this.renderer.removeAttribute(this.image, 'hidden');
    }
  }
}
