<app-slideshow></app-slideshow>

<mat-toolbar class="submenu">
  <mat-toolbar-row class="filterMenu course-wrapper maxwrapper">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class='dropdown-width'>
        <mat-select placeholder="Courses" (selectionChange)="filterChanged()" [formControl]="courseFilter">
          <mat-option value="all">Show All</mat-option>
          <mat-option value="archived">Archived</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class='dropdown-width'>
        <mat-select placeholder="Aircraft" (selectionChange)="filterChanged()" [formControl]='aircraftFilter'>
          <mat-option value="all">Show All</mat-option>
          <mat-option *ngFor="let aircraft of aircrafts" [value]="aircraft.name">{{aircraft.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class='dropdown-width'>
        <mat-select placeholder="Avionics" (selectionChange)="filterChanged()" [formControl]='avionicsFilter'>
          <mat-option value="all">Show All</mat-option>
          <mat-option *ngFor="let avionic of avionics" [value]="avionic.name">{{avionic.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class='dropdown-width'>
        <mat-select placeholder="Training" (selectionChange)="filterChanged()" [formControl]="trainingFilter">
          <mat-option value="all">Show All</mat-option>
          <mat-option *ngFor="let category of courseCategories" [value]="category.id">{{category.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex></div>

    <app-tab-bar class='achieve-tab-bar' [navLinks]='tabItems'></app-tab-bar>
  </mat-toolbar-row>
</mat-toolbar>


<mat-toolbar class="smallscreen-submenu">
  <mat-toolbar-row class="filterMenu">
    <!--<div fxLayout="column"  fxLayoutAlign="center center">-->
      <!--<button mat-button [matMenuTriggerFor]="menu">Filter</button>-->
      <!--<mat-menu #menu="matMenu">-->
        <!--<button mat-menu-item>Item 1</button>-->
        <!--<button mat-menu-item>Item 2</button>-->
      <!--</mat-menu>-->
    <!--</div>-->
    <!--<div fxFlex></div>-->
    <app-tab-bar class='achieve-tab-bar' [navLinks]='tabItems'></app-tab-bar>
  </mat-toolbar-row>
</mat-toolbar>


<div class="course-wrapper">
  <div class="full-width">
    <div class="maxwrapper course-padding">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
