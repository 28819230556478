import { Component, OnInit } from '@angular/core';
import { slideInRight } from '../../../../../animations/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskCategory } from '../../../../../models/course/content/task/task-category';
import { TaskCategoryService } from '../../../../../services/data-services/task-category.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector:    'app-manage-task-category',
  templateUrl: './manage-task-category.component.html',
  styleUrls:   [ './manage-task-category.component.scss'],
  animations: [
    slideInRight
  ]
})
export class ManageTaskCategoryComponent implements OnInit {

  task_category: TaskCategory;
  subs: any[] = [];
  form: FormGroup;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private taskCategoryService: TaskCategoryService,
              private fb: FormBuilder,
              public snackbar: MatSnackBar) {
    this.task_category = new TaskCategory();
    this.createForm();
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.params.subscribe((params) => {
        if(params['id']) {
          this.getCourseCategory(parseInt(params['id']));
          return;
        }
        this.route();
      })
    );
  }

  getCourseCategory(id) {
    this.taskCategoryService.getCategory(id)
        .then((category) => {
          if (category) {
            Object.assign(this.task_category, category);
            return;
          }
          this.route();
        })
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      desc: [''],
    })
  }

  route() {
    this.router.navigate(['admin/courses/category/task/overview']);
  }

  updateCategory() {
    this.taskCategoryService.update(this.task_category)
        .then(() => {
          this.snackbar.open('Updated')
          this.route()
        });
  }

  cancel() {
    this.route();
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

}
