import { Injectable } from '@angular/core';
import { Quiz } from '@app/models/course/content/quiz/quiz';
import { HttpService } from '@app/services/http-service.service';

@Injectable()
export class QuizService {

  quizUrl = 'quizzes';

  constructor(private http: HttpService) {
  }

  getQuizzes(): Promise<Quiz[]> {
    return this.http.get<Quiz[]>(this.quizUrl)
      .toPromise()
      .catch(QuizService.handleError);
  }

  getQuiz(id: number): Promise<Quiz> {
    const url = `${this.quizUrl}/${id}`;

    return this.http.get<Quiz>(url)
      .toPromise()
      .catch(QuizService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.quizUrl}/${id}`;

    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(QuizService.handleError);
  }

  create(quiz: Quiz): Promise<Quiz> {
    return this.http
      .post<Quiz>(this.quizUrl, JSON.stringify(quiz))
      .toPromise()
      .catch(QuizService.handleError);
  }

  update(quiz: Quiz): Promise<Quiz> {
    const url = `${this.quizUrl}/${quiz.id}`;

    return this.http
      .put(url, JSON.stringify(quiz))
      .toPromise()
      .then(() => quiz)
      .catch(QuizService.handleError);
  }

    private static handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

}
