import { Injectable } from '@angular/core';
import { Badge } from '@app/models/course/course-badge';
import { HttpService } from '@app/services/http-service.service';

@Injectable()
export class BadgesService {

  private badgeUrl = 'badges';

  constructor(private http: HttpService) { }

  getBadges(): Promise<Badge[]> {
    return this.http.get<Badge[]>(this.badgeUrl)
      .toPromise()
      .catch(BadgesService.handleError);
  }

  getMyBadges(): Promise<Badge[]> {
    return this.http.get<Badge[]>(`${this.badgeUrl}/user-badges`)
      .toPromise()
      .catch(BadgesService.handleError);
  }

  checkBadges(course_id: number, user_id: number): Promise<any> {
    return this.http.post(`${this.badgeUrl}/check-badges/${course_id}/${user_id}`, null)
      .toPromise()
      .catch(BadgesService.handleError);
  }

  getBadge(id: number): Promise<Badge> {
    const url = `${this.badgeUrl}/${id}`;
    return this.http.get<Badge>(url)
      .toPromise()
      .catch(BadgesService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.badgeUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(BadgesService.handleError);
  }

  create(badge: Badge): Promise<Badge> {
    return this.http
      .post<Badge>(this.badgeUrl, JSON.stringify(badge))
      .toPromise()
      .catch(BadgesService.handleError);
  }

  update(badge: Badge): Promise<Badge> {
    const url = `${this.badgeUrl}/${badge.id}`;
    return this.http
      .put<Badge>(url, JSON.stringify(badge))
      .toPromise()
      .catch(BadgesService.handleError);
  }

  checkAssociations(badge: Badge): Promise<boolean> {
    const url = `${this.badgeUrl}/checkAssociations/${badge.id}`;
    return this.http
      .get<boolean>(url)
      .toPromise()
      .catch(BadgesService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
