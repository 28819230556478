import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UserService} from '@app/services/data-services/user.service';
import {User} from '@app/models/user/user';
import {SnackbarComponent} from '@app/components/shared/snackbar/snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Router} from '@angular/router';

@Component({
  selector: 'app-user-promote-admin-template',
  templateUrl: './user-promote-admin-template.component.html',
  styleUrls: ['./user-promote-admin-template.component.scss']
})

export class UserPromoteAdminTemplateComponent implements OnInit {
  rows: any[] = [];
  user_model: User = new User();
  @Input() user: any;
  @Output() promote_admin: EventEmitter<any> = new EventEmitter<any>();
  @Input () useremail: string;
  @Input () promote: boolean;

  subscription: any;

  constructor(private userService: UserService,
              private router: Router,
              public snackbar: MatSnackBar,
              public dialog: MatDialog) {
  }

  ngOnInit() {
  }
  promoteToAdmin() {
    this.user_model.id = this.user.id;
    this.promote_admin.emit(this.user_model);
  }
}
