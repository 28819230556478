import { UserRole } from '@app/models/user/user-role';
import { UserCourse } from '@app/models/course/user-course';
import { Badge } from '@app/models/course/course-badge';
import { Certificate } from '@app/models/course/certificate';

export class UserBadge {
  id?: number;
  badge_id?: number;
  badge?: Badge;
  created_at?: Date;
}

export class UserCertificate {
  id?: number;
  certificate_id?: number;
  certificate?: Certificate;
  created_at?: Date;
  expires?: Date;
  aircraft_name?: string;
  avionics_name?: string;
  serial?: string;
}

export class UserAirport {
  id?: number;
  altitude__c?: number;
  airport_name__c?: string;
  state_province__c?: string;
  name?: string;
  country__c?: string;
  isdeleted?: boolean;
  systemmodstamp?: Date;
  longitude__c?: number;
  createddate?: number;
  latitude__c?: number;
  city__c?: string;
  sfid?: string;
}

export class UserAccount {
  name?: string;
}

export class UserContact {
  id?: number;
  firstname?: string;
  lastname?: string;
  name?: string;
  phone?: string;
  isdeleted?: boolean;
  relationship_type__c?: string;
  mailingcity?: string;
  mailingstate?: string;
  systemmodstamp?: Date;
  createddate?: Date;
  home_airport__c?: string;
  email?: string;
  qualifications__c?: string;
  sfid?: string;
  home_airport?: UserAirport;
  contact_user?: ContactUser;
}

export class User {
  id?: number;
  authentication_token?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: UserRole;
  phone?: string;
  company?: string;
  deactivated?: boolean;
  user_courses?: UserCourse[];
  user_badges?: UserBadge[];
  user_certificates?: UserCertificate[];
  contact?: UserContact;
  impersonate_token?: string;
  impersonate_role?: string;
  salesforce_id?: string;
  display_name?: string;
  contact_user?: ContactUser;
  status?: string;
  c_id?: string;
  ctc_admin?: boolean;
}

export class ContactUser {
  account?: UserAccount;
  home_airport?: UserAirport;
  mailingpostalcode?: number;
  mailingstreet?: string;
  phone: string;
  contact?: UserContact;
}
