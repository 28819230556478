<div id="instructor-connect-wrapper">
  <h1 mat-dialog-title align="center">{{type}}</h1>
  <mat-dialog-content>
    <mat-form-field>
      <mat-label>Filter {{type}}</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z3">
      <ng-container matColumnDef="select" *ngIf='!data.viewOnly'>
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleSelectAll() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let row">{{row.name}}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let row">{{row.email}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr *matNoDataRow>No {{type}}</tr>
    </table>

    <mat-paginator pageSize="5"></mat-paginator>
  </mat-dialog-content>
  <mat-dialog-actions align="center" *ngIf='!data.viewOnly'>
    <button mat-button [mat-dialog-close]="[]">Cancel</button>
    <button mat-raised-button [mat-dialog-close]="selection.selected" [disabled]="isDisabled()">{{ selectedCount() | i18nPlural: data.pluralMap}}</button>
  </mat-dialog-actions>
</div>
