<h2 class="text-body-header">Checkout</h2>

<div>
  <div class="course-wrapper-alt bottom-padding top-padding">
    <div class="max-width-course"
         fxLayout='row wrap'
         fxLayout.lt-sm="column"
         fxLayoutAlign.sm="start"
         fxLayoutAlign.xs="start"
         fxFlex.xs="100">
      <div class="error-wrapper" *ngIf="error">
        <span><mat-icon class="error">error_outline</mat-icon> {{errorText}}</span>
      </div>
      <div fxFlex="40"
           fxFlex.sm="100"
           fxFlex.xs="100"
           fxLayout="column"
           fxLayoutAlign="start start" class="left-col">
        <h6 class="item-count">Your Items: ({{order?.order_line_items?.length}})</h6>
        <div
          fxLayout="row"
          fxLayoutAlign="start start"
          *ngFor="let item of order?.order_line_items"
          class="checkout-course"
        >
          <img
            class="course-img"
            [src]="
              item?.product?.thumbnail_image_url
                ? item?.product?.thumbnail_image_url
                : defaultCourseThumb
            "
          />
          <div fxLayout="column" class="checkout-course-row">
            <h5 class="course-name">{{item?.product?.title}}</h5>
            <p class="course-price">{{item?.product?.list_price | currency:'USD'}}</p>
          </div>
        </div>
      </div>
      <div fxFlex="60"
           fxFlex.sm="100"
           fxFlex.xs="100"
           fxLayout="column"
           fxLayoutAlign="start start" class="right-col">
        <p class="total-price large">Total: {{total | currency:'USD'}}</p>
        <div class="checkout-form-wrapper">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div fxLayout="row" class="payment-icon-row">
              <i class="fa fa-cc-visa payment-icon" style="color:#fff;"></i>
              <i class="fa fa-cc-amex payment-icon" style="color:#fff;"></i>
              <i class="fa fa-cc-mastercard payment-icon" style="color:#fff;"></i>
            </div>

            <div fxLayout="row" class="checkout-row-top">
              <mat-form-field id="credit-input" class="full-width">
                <input matInput placeholder="Card Number" formControlName="card_number" maxlength="16"
                       (keydown)="checkForNumbers($event)" pattern="^[2-6]\d*$">
                  <mat-error>
                    Please double-check your card number.
                  </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row wrap" class="card-details-row">
              <mat-form-field id="credit-input" class="one-third top-padding-light">
                <mat-select matInput placeholder="MM" formControlName="month"
                            (change)="checkForValidDate()">
                  <mat-option value="01">01</mat-option>
                  <mat-option value="02">02</mat-option>
                  <mat-option value="03">03</mat-option>
                  <mat-option value="04">04</mat-option>
                  <mat-option value="05">05</mat-option>
                  <mat-option value="06">06</mat-option>
                  <mat-option value="07">07</mat-option>
                  <mat-option value="08">08</mat-option>
                  <mat-option value="09">09</mat-option>
                  <mat-option value="10">10</mat-option>
                  <mat-option value="11">11</mat-option>
                  <mat-option value="12">12</mat-option>
                </mat-select>
                <mat-error>
                  Please select a month.
                </mat-error>
              </mat-form-field>

              <mat-form-field id="credit-input" class="one-third top-padding-light">
                <mat-select matInput placeholder="YYYY" formControlName="year"
                            (change)="checkForValidDate()">
                  <mat-option value="2023">2023</mat-option>
                  <mat-option value="2024">2024</mat-option>
                  <mat-option value="2025">2025</mat-option>
                  <mat-option value="2026">2026</mat-option>
                  <mat-option value="2027">2027</mat-option>
                  <mat-option value="2028">2028</mat-option>
                  <mat-option value="2029">2029</mat-option>
                  <mat-option value="2030">2030</mat-option>
                  <mat-option value="2031">2031</mat-option>
                  <mat-option value="2032">2032</mat-option>
                  <mat-option value="2033">2033</mat-option>
                  <mat-option value="2034">2034</mat-option>
                  <mat-option value="2035">2035</mat-option>
                  <mat-option value="2036">2036</mat-option>
                  <mat-option value="2037">2037</mat-option>
                  <mat-option value="2038">2038</mat-option>
                  <mat-option value="2039">2039</mat-option>
                  <mat-option value="2040">2040</mat-option>
                  <mat-option value="2041">2041</mat-option>
                  <mat-option value="2042">2042</mat-option>
                  <mat-option value="2043">2043</mat-option>
                </mat-select>
                <mat-error>
                  Please select a year.
                </mat-error>
              </mat-form-field>

              <!--Card Security Code-->
              <mat-form-field id="credit-input" class="one-third top-padding-light">
                <input matInput placeholder="Security Code" formControlName="cid" maxlength="4"
                       (keydown)="checkForNumbers($event)">
                <mat-error>
                  Please check your security code.
                </mat-error>
              </mat-form-field>
              <p class="error-msg" *ngIf="expirationError">Invalid Expiration Date</p>
            </div>

            <!--Cardholder Name-->
            <div fxLayout="row" class="checkout-row">
              <mat-form-field id="credit-input" class="full-width">
                <input matInput placeholder="Name on Card" formControlName="name_on_card">
                <mat-error>
                  Please provide your name.
                </mat-error>
              </mat-form-field>
            </div>
            <!--Cardholder Address-->
            <div fxLayout="row" class="checkout-row">
              <mat-form-field id="credit-input" class="full-width">
                <input matInput placeholder="Street Address" formControlName="address_on_card">
                <mat-error>
                  Please provide your address.
                </mat-error>
              </mat-form-field>
            </div>
            <!--Zip-->
            <div fxLayout="row wrap" class="checkout-row">
              <mat-form-field id="credit-input" class="half">
                <input matInput placeholder="Zip/Postal Code" formControlName="postal_code"
                       (keydown)="checkForNumbersOrLetters($event)">
                <mat-error>
                  Please provide your zip.
                </mat-error>
              </mat-form-field>

              <!--Country Code-->
              <mat-form-field id="credit-input" class="half">
                <mat-select matInput placeholder="Country" formControlName="country">
                  <mat-option *ngFor='let country of countries; let i = index;' [value]="i">
                    {{ country.name }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Please select a country.
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" class="terms-row top-padding-light">
              <button style="min-width: 150px;" mat-raised-button [disabled]="form.invalid || loading || expirationError"
                      class="valid-payment-btn" type="submit">
                <img *ngIf="loading" src="assets/images/oval.svg" alt="">
                <ng-container *ngIf="!loading">Complete Payment</ng-container>
              </button>
              <div class="secure-txt" fxLayout="row">
                <mat-icon class="secure-icon">lock</mat-icon>
                <div fxLayout="column">
                  <p>Secure</p>
                  <p class="connection-txt">Connection</p>
                </div>
              </div>
            </div>
            <div class="terms-wrapper">
              <p class="terms-of-service-txt xsmall">By completing your purchase you agree to these
                <a href="https://cirrusaircraft.com/terms/" class="terms-of-service-link">Terms of Service</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
