<div fxLayout='row' #wrapper>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <p class="back-button content-status-not-started hover-pointer" (click)="goBack()" style="display: inline-flex;"><mat-icon class="white-text" style="width: 20px!important;">keyboard_arrow_left</mat-icon> <span class="white-text" style="margin-top: 2px; font-weight: bold; text-transform: uppercase;">Back</span></p>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text" fxLayout="row" >
          <app-progress-spinner style="position: relative;" [progress]="progress">
            <span *ngIf="completed_attempts < selectedTask.required_successful_attempts" class="attempts_text">{{completed_attempts}}/{{selectedTask?.required_successful_attempts}}</span>
            <span *ngIf="completed_attempts >= selectedTask.required_successful_attempts" class="complete_text">COMPLETE</span>
          </app-progress-spinner>
          <div fxLayout="column" class="task-status-col">
            <p class="content-label content-status-not-started" *ngIf="task_progress === 'Not Started'">Not Started</p>
            <p class="content-label content-status-in-progress" *ngIf="task_progress === 'In Progress'">In Progress</p>
            <p class="content-label content-status-completed" *ngIf="task_progress === 'Completed'">Completed</p>
            <h3 class="assessment-title" style="margin-top: 0px;">{{selectedTask?.name}}</h3>
            <div fxLayout="row">
              <p style="padding-right: 10px">Type: {{selectedTask?.task_type?.toString() === 'practice' ? 'Practice' : 'Perform'}}</p>
              <p>Category: {{selectedTask?.task_category?.name}}</p>
            </div>
          </div>
        </div>
        <div fxLayout="row" class="indicator-row">
          <div class="card-title-text full-width status-row" fxLayout="row">
            <div fxLayout="column">
              <p class="number">{{selectedTask?.required_successful_attempts}}</p>
              <p class="status-txt">REQUIRED</p>
            </div>
            <div fxLayout="column" class="padding-left-light" *ngIf="selectedAssessment?.jet_scoring">
              <p class="number">{{selectedTask.perfect}}</p>
              <p class="status-txt">PERFECT</p>
            </div>
            <div fxLayout="column" class="padding-left-light">
              <p class="number">{{selectedTask.passed}}</p>
              <p class="status-txt">PASSED</p>
            </div>
            <div fxLayout="column" class="padding-left-light">
              <p class="number">{{selectedTask.missed}}</p>
              <p class="status-txt">MISSED</p>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div>
          <mat-tab-group>
            <mat-tab label="Standards">
              <div class="mat-box-shadow">
                <div fxLayout="column" class="table-padding">
                  <p>Task Completion Standards</p>
                    <mat-list role="list">
                      <mat-list-item role="listitem" *ngFor="let standard of selectedTask?.standards">{{standard}}</mat-list-item>
                    </mat-list>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Attempts Log">
              <div class="mat-box-shadow">
                <ngx-datatable #table class="material bg-white student-assessment-table"
                               [columnMode]="'force'"
                               [headerHeight]="50"
                               [footerHeight]="50"
                               [rowHeight]="'auto'"
                               [limit]="50"
                               [selected]=""
                               [selectionType]="'row'"
                               [rows]="selectedTaskAttempts">
                  <ngx-datatable-column name="Date" prop='created_at' class="task-names" [pipe]="datePipe"></ngx-datatable-column>
                  <ngx-datatable-column name="Attempt" prop='id' class="task-names">
                    <ng-template let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{selectedTaskAttempts.indexOf(row) + 1}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Perfect" prop='perfect' class="task-names"  *ngIf="selectedAssessment?.jet_scoring">
                    <ng-template  let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{row?.perfect ? 1 : 0}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Passed" prop='passed' class="task-names">
                    <ng-template  let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      {{row?.success ? 1 : 0}}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Missed" prop='missed' class="task-names">
                    <ng-template let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      <span [ngClass]="{'orange-text' : row?.failed}">{{row?.failed ? 1 : 0}}</span>
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Instructor" prop='instructor.user.contact.name' class="task-names"></ngx-datatable-column>
                  <ngx-datatable-column
                    [sortable]="false"
                    [canAutoResize]="true"
                    [draggable]="false"
                    [resizeable]="false">
                    <ng-template let-row='row' let-rowIndex="rowIndex" ngx-datatable-cell-template>
                      <button *ngIf="!isCompleted || row.failed" mat-icon-button [matMenuTriggerFor]="entryMenu" class="hidden-on-open">
                        <mat-icon class="text-muted">more_vert</mat-icon>
                      </button>
                      <mat-menu #entryMenu="matMenu">
                        <button *ngIf="row.failed" mat-menu-item (click)="reasonMissed(row)">
                          <i class="material-icons normal-icon"></i>
                          <span>Reasons Missed</span>
                        </button>
                        <hr *ngIf="!isCompleted && row.failed">
                        <button *ngIf="!isCompleted" mat-menu-item (click)="removeAttempt(row)">
                          <i class="material-icons icon green">delete</i>
                          <span>Delete</span>
                        </button>
                      </mat-menu>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
