import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingSpinnerComponent } from '@app/components/shared/loading-spinner/loading-spinner.component';
import { CourseService } from '@app/services/data-services/course.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-user-course-agreement-dialog',
  templateUrl: './user-course-agreement-dialog.component.html',
  styleUrls: ['./user-course-agreement-dialog.component.scss']
})
export class UserCourseAgreementDialogComponent implements OnInit {
  course: any;
  agreement_body: string;

  private _buttonDisabledSubject = new Subject()
  buttonDisabled$ = this._buttonDisabledSubject.asObservable()

  constructor(public dialogRef: MatDialogRef<UserCourseAgreementDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private courseService: CourseService) { }

  ngOnInit() {
    this.course = this.data;
    this.agreement_body = this.course?.course?.agreement_body ? this.course.course.agreement_body : this.course.agreement_body
  }

  close() {
    this.dialogRef.close({
      course: this.course,
      agreed: false
    });
  }

  agree() {
    const user_course_id = this.course?.user_course_id ? this.course.user_course_id : this.course.id
    this._buttonDisabledSubject.next(true)
    this.courseService.agreeToTerms(user_course_id)
      .then(res => {
        this._buttonDisabledSubject.next(false)
        if (res) {
          this.course.has_agreed = true;
          this.dialogRef.close({
            course: this.course,
            agreed: true
          });
        }
      });
  }
}
