import { Avionics } from 'app/models/aircraft/avionics';

export class Aircraft {
  id?: number;
  name?: string;
  desc?: string;
  make?: string;
  model?: string;
  avionics?: Avionics;
  generation?: string;
}

