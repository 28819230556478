import { Component, Input, OnInit } from '@angular/core';
import { Notification } from '@app/models/notification/notification';
import { ConnectionsService } from '@app/training-centers/connections.service';
import { Connection } from '@app/models/connection';
import { NotificationService } from '@app/services/data-services/notification.service';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-training-center-invitation',
  templateUrl: './training-center-invitation.component.html',
  styleUrls: ['./training-center-invitation.component.scss'],
  host: {'class': 'mat-list-text'}
})
export class TrainingCenterInvitationComponent implements OnInit {
  @Input() invitation: Notification;
  @Input() notificationToken: BehaviorSubject<Notification[]>;

  constructor(
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
  ) {

  }

  ngOnInit(): void {
  }

  acceptInvitation() {
    return this.connectionsService.accept(<Connection>this.invitation.notifiable.connection)
      .pipe(first())
      .subscribe(() => {
        this.notificationService.getMyNotifications()
          .then((notifications) => { this.notificationToken.next(notifications); });
      });
  }

  declineInvitation() {
    return this.connectionsService.decline(<Connection>this.invitation.notifiable.connection)
      .subscribe(() => {
        this.notificationService.getMyNotifications()
        .then((notifications) => { this.notificationToken.next(notifications); });
      });
  }
}
