import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Quiz } from '@app/models/course/content/quiz/quiz';
import { QuizService } from '@app/services/data-services/quiz.service';

@Component({
  selector: 'app-quiz-dialog-template',
  templateUrl: './quiz-dialog-template.component.html',
  styleUrls: ['./quiz-dialog-template.component.scss']
})
export class QuizDialogTemplateComponent implements OnInit, AfterViewInit {
  @Input() course: any;
  @Output() add_quiz: EventEmitter<any> = new EventEmitter<any>();
  selected = [];
  rows: Quiz[] = [];
  temp: Quiz[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;
  selectedQuiz: any;

  constructor(private quizService: QuizService) {}

  ngOnInit() {
    this.quizService.getQuizzes()
      .then(rows => this.rows = this.temp = rows);
  }

  ngAfterViewInit() {
    this.table.offset = 0;
  }

  save() {
    if (this.selectedQuiz) {
      this.add_quiz.emit(this.selectedQuiz);
    }
  }

  onSelect(row) {
    this.selectedQuiz = row.selected[0];
  }

  cancel() {
    this.add_quiz.emit();
  }

  close() {
    this.cancel();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
