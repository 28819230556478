import {Component, Inject, Input, OnInit} from '@angular/core';
import {User} from '@app/models/user/user';
import {ShortTextPipe} from '@app/pipes/short-text.pipe';
import {ReportsService} from '@app/services/data-services/reports.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-reports-user-dialog',
  templateUrl: './admin-reports-user-dialog.component.html',
  styleUrls: ['./admin-reports-user-dialog.component.scss']
})
export class AdminReportsUserDialogComponent implements OnInit {
  badgeRows: any[] = [];
  certificateRows: any[] = [];
  courseRows: any[] = [];
  shortPipe = new ShortTextPipe();

  constructor(public adminUserReportDialogRef: MatDialogRef<AdminReportsUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public adminUserReportDialogData: any, private reportsService: ReportsService) {
  }

  ngOnInit() {
    if (this.adminUserReportDialogData) {
      this.reportsService.getUserReport(this.adminUserReportDialogData.data)
        .then(report => {
          this.badgeRows = report.badges;
          this.certificateRows = report.certificates;
          this.courseRows = report.courses;
        });
    }
  }

}
