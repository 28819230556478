import { Component, AfterViewInit } from '@angular/core';
import { AuthService } from '@app/auth.service';

@Component({ template: '' })
export class SignOutComponent implements AfterViewInit {
  constructor(private authService: AuthService) {}

  ngAfterViewInit(): void {
    this.authService.logout();
  }
}
