<div fxLayout="row">
  <div class="editor-wrapper" fxLayout="row wrap" fxFlex="grow">
    <mat-card class="p-0" fxFlex="grow">
      <mat-card-title>
        <div class="card-title-text">
          Courses
          <button
            mat-raised-button
            [color]="'primary'"
            (click)="navigate('admin/courses/add')"
          >
            Add Course
          </button>
        </div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="full-width pb-1">
          <input
            type="text"
            style="padding: 8px; margin: 15px auto; width: 30%"
            placeholder="Filter Courses"
            (keyup)="updateFilter($event)"
          />
        </div>
        <div class="full-width pb-1">
          <span
            class="filter-table is-active"
            (click)="filterCourses(active, $event)"
            >Active <span class="count">({{ active.length }})</span></span
          >
          |
          <span class="filter-table" (click)="filterCourses(inactive, $event)"
            >Inactive <span class="count">({{ inactive.length }})</span></span
          >
        </div>
        <div class="mat-box-shadow-margin-333">
          <ngx-datatable
            #table
            class="material bg-white list-view"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [rowHeight]="'auto'"
            [limit]="25"
            [rows]="rows"
            [loadingIndicator]="isLoading"
          >
            <ngx-datatable-column
              [sortable]="true"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="System Name"
              prop="name"
            >
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="Version"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.major_version == null ? '1' : row.major_version }}.{{
                  row.minor_version == null ? '0' : row.minor_version
                }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="Price"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{
                  !row.list_price
                    ? 'Free'
                    : (row.list_price | currency : 'USD' : 'symbol')
                }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="Last Modified Date"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.updated_at | date }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="Store"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <mat-icon *ngIf="row.is_ecommerce_enabled" class="is-active"
                  >check_circle</mat-icon
                >
                <mat-icon *ngIf="!row.is_ecommerce_enabled" class="not-active"
                  >fiber_manual_record</mat-icon
                >
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="Students Can Re-Enroll"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <mat-icon *ngIf="row.can_reenroll" class="is-active"
                  >check_circle</mat-icon
                >
                <mat-icon *ngIf="!row.can_reenroll" class="not-active"
                  >fiber_manual_record</mat-icon
                >
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="msgMenu"
                  class="hidden-on-open"
                >
                  <mat-icon class="text-muted">more_vert</mat-icon>
                </button>
                <mat-menu #msgMenu="matMenu" class="row-context-menu">
                  <button mat-menu-item (click)="editCourse(row)">
                    <mat-icon>edit</mat-icon> Edit
                  </button>
                  <button
                    *ngIf="row.is_active"
                    mat-menu-item
                    (click)="updateEcommerce(row)"
                  >
                    <mat-icon>{{
                      row.is_ecommerce_enabled
                        ? 'remove_circle_outline'
                        : 'add_circle_outline'
                    }}</mat-icon>
                    {{
                      row.is_ecommerce_enabled
                        ? 'Remove From Store'
                        : 'Add To Store'
                    }}
                  </button>
                  <!--                  <button mat-menu-item><mat-icon>file_copy</mat-icon> Duplicate</button>-->
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="updateActive(row)">
                    <mat-icon>{{
                      row.is_active ? 'archive' : 'unarchive'
                    }}</mat-icon>
                    {{ row.is_active ? 'Deactivate' : 'Activate' }}
                  </button>
                </mat-menu>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
