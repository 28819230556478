import {User, UserContact} from '@app/models/user/user';

export enum ContentCommentVoteType {
    up = 0,
    down = 1
}

export const ContentCommentVoteTypeMap = {
  0: 'Up',
  1: 'Down'
};

class ContentCommentVote {
  id?: number;
  user_id?: number;
  user?: User;
  content_comment_vote_type?: ContentCommentVoteType;
  content_comment_id?: number;
  content_comment?: ContentComment;
}

export enum ContentCommentType {
  question = 0,
  comment = 1
}

export const ContentCommentTypeMap = {
  0: 'Question',
  1: 'Comment'
};

class ContentCommentFlags {
  id?: number;
  user_id?: number;
  user?: User;
  content_comment_id?: number;
  content_comment?: ContentComment;
}

export class ContentComment {
    id?: number;
    comment_body?: string;
    content_id?: number;
    user?: User;
    contact?: UserContact;
    created_at?: Date;
    up_vote_count?: number;
    down_vote_count?: number;
    replies?: ContentComment[];
    content_comment_type?: ContentCommentType;
    content_comment_id?: number;
    content_comment_flags?: ContentCommentFlags[];
    content_comment_votes?: ContentCommentVote[];

    constructor(comment_body, content_id, content_comment_type, content_comment_id = null) {
        this.comment_body = comment_body;
        this.content_id = content_id;
        this.content_comment_type = content_comment_type;
        this.content_comment_id = content_comment_id;
    }
}
