import {Component, Input, OnInit} from '@angular/core';

import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
    @Input() content: any;
    @Input() isMobile: any;
    @Input() isMobileLandscapeOrientation: any;
    videoUrl: any;
    constructor(public sanitizer: DomSanitizer) {
    }

    ngOnInit() {
      console.log('Route: ', 'video.component.ts');
      // console.log('isMobile from video component: ', this.isMobile);
      // console.log('isMobileLandscapeOrientation from video component: ', this.isMobileLandscapeOrientation);
      if (this.content.content_type === 1) {
        const new_url = 'https://www.youtube.com/embed/' + this.content.url + '?rel=0&amp;showinfo=0&fs=1&playsinline=1';
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(new_url);
      } else if (this.content.content_type === 0) {
        const new_url = 'https://player.vimeo.com/video/' + this.content.url + '?title=0&byline=0&portrait=0';
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(new_url);
      }
    }

}
