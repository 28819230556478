<div id="instructor-connect-wrapper">
  <div class="instructor-connect-title-wrapper">
    <h1 mat-dialog-title>Promote to Administrator</h1>
  </div>
  <mat-dialog-content>
    <div class="full-width pb-1">
      <p>Promote {{useremail}} to Administrator</p>
      <!-- <input
        id = "salesforceid"
        type='text'
        style='padding:8px;margin:15px auto;width:30%;'
        placeholder= "{{salesforceID}}"
        [(ngModel)]="user_model.salesforce_id"
      /> -->
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-wrapper">
      <button mat-button type="button" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" (click)="promoteToAdmin()" [disabled]="!useremail.length">Promote</button>
    </div>
  </mat-dialog-actions>
</div>
