import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-user-notify-template',
  templateUrl: './user-notify-template.component.html',
  styleUrls: ['./user-notify-template.component.scss']
})
export class UserNotifyTemplateComponent implements OnInit {
  @Input() users: any[];
  message = '';
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  save() {
    if (this.message) {
      this.submit.emit(this.message);
    }
  }

}
