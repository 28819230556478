<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0 pb-1" fxFlex='grow'>
      <mat-card-title class="">
        <div class="card-title-text">Add Content</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for='content-type' class='form-label'>Content Type</label>
            <div class="selection-wrapper">
              <select id="content-type" [(ngModel)]="content_model.content_type" (change)='clearContent()' name="content-type" class="form-select" >
                <option default value="-1">Select Type</option>
                <option [value]="type.key" *ngFor='let type of map | keys'>{{ type.value }}</option>
              </select> <span class="err" *ngIf="tried && !content_model.content_type">Required</span>
            </div>
          </div>
          <div class="form-row">
            <label for="content-name" class="form-label">Title</label>
            <input id="content-name" name="content-name" class="form-input" [(ngModel)]="content_model.title"> <span class="err" *ngIf="tried && !content_model.title">Required</span>
          </div>
          <div class="form-row">
            <label for="content-desc" class="form-label">Description</label>
            <textarea id="content-desc" name="content-desc" class="form-textarea" [(ngModel)]="content_model.desc"></textarea>
          </div>
          <div class="form-row">
            <label for="created-by" class="form-label">Created By</label>
            <input id="created-by" name="created-by" class="form-input" [(ngModel)]="content_model.created_by">
          </div>
          <div class="form-row">
            <label for="content-placeholder" class="form-label">Preview Image  <span class='message'>This image is used for library cards.</span></label>
            <div>
              <img id="content-img" #image src="#" width="325" height="175" alt="" hidden>
            </div>
            <input id="content-placeholder" type='file' name="placeholder" appAppUploaderPreview [image]="image" class="form-input" itemid="placeholder" ng2FileSelect [uploader]="imageUploader">
          </div>
          <div class="form-row">
            <label for="meta-tags" class="form-label">Meta Tags <span class="meta-tag-info">Type new meta tags and separate by commas or hit enter to add tag</span></label>
            <mat-form-field class="meta-tags-input">
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let tag of content_model?.meta_tags"
                          [removable]="true" (remove)="remove(tag)">
                  {{tag}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false" (matChipInputTokenEnd)="add($event)" id="meta-tags" name="meta-tags" />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="form-row" *ngIf='[contentEnum.vimeo.toString(),contentEnum.youtube.toString()].indexOf(content_model?.content_type) > -1'>
            <mat-accordion id="help-video-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a YouTube or Vimeo Video</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>Follow these steps to upload a YouTube or Vimeo video:</p>
                      <small>Note: If you copy the video url directly from your browser, the upload process will not work.</small>
                      <p>Step 1: Find the video you would like to upload.</p>
                      <p>Step 2: Click "Share".</p>
                      <P>Step 3: Copy your video link.</P>
                      <p>Step 4: Paste your link into the input field below. </p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <label for="content-video" class="form-label">Video Link</label>
            <input id="content-video" placeholder="Video Embed Link" type='text' name="form-control" class="form-input" [(ngModel)]="content_model.url">
          </div>
          <div class="form-row" *ngIf='[contentEnum.scorm.toString(),contentEnum.tinCan.toString(),contentEnum.download.toString(),contentEnum.interactive.toString(),contentEnum.image.toString()].indexOf(content_model.content_type) > -1'>
            <mat-accordion id="help-video-accordion" *ngIf="content_model?.content_type != contentEnum.image">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a SCORM File</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>Step 1: Find the file you would like to upload.</p>
                      <p>Step 2: Right-click on the file and compress it into a Zip file.</p>
                      <P>Step 3: Upload the zip file.</P>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <label for="scorm" class="form-label">Upload File</label>
            <input #scorm id="scorm" type='file' ng2FileSelect [uploader]="fileUploader" name="scorm" >
          </div>
          <div class="form-row" *ngIf="content_model?.content_type == contentEnum.richText">
            <label for="content">Content</label>
            <mat-accordion id="help-video-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a YouTube or Vimeo Video</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>Follow these steps to upload a YouTube or Vimeo video:</p>
                      <p>Step 1: Find the video you would like to upload.</p>
                      <p>Step 2: For YouTube, click the share button. Next, click "Embed". For Vimeo, click "Share".</p>
                      <P>Step 3: Copy all of the code that is generated for you. </P>
                      <p>Step 4: In the text editor below, click the very last button or "View Code".</p>
                      <p>Step 5: If you cannot see the text area, simply click the bottom border and drag down to expand it open.
                        Paste the code you copied from YouTube or Vimeo into the text area.</p>
                      <p>Step 6: Click the "View Code" button again to un-toggle.</p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <app-wysiwyg-editor editorId='content' title="Bookshelf" placeholder="Enter text here..." [(text)]="content_model.content_html" ></app-wysiwyg-editor>
          </div>
        </form>
      </mat-card-content>
      <mat-spinner id="loading-spinner" *ngIf="is_loading"></mat-spinner>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="upload()">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
