import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FaqCategory } from '@app/models/faq-category';
import { FaqCategoryService } from '@app/services/data-services/faq-category.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-add-faq-category-dialog',
  templateUrl: './add-faq-category-dialog.component.html',
  styleUrls: ['./add-faq-category-dialog.component.scss']
})
export class AddFaqCategoryDialogComponent implements OnInit {
  selected: FaqCategory[] = [];
  rows: FaqCategory[] = [];
  temp: FaqCategory[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(public dialogRef: MatDialogRef<AddFaqCategoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private categoryService: FaqCategoryService) {
  }

  ngOnInit() {
    this.categoryService.getFaqCategories()
      .then(categories => this.rows = this.temp = categories );
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if (this.selected.length) {
      this.dialogRef.close(this.selected[0]);
      return;
    }
    this.close();
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}

