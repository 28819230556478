<div id="instructor-connect-wrapper">
  <div class="instructor-connect-title-wrapper">
    <h1 *ngIf="!remove && !reset" mat-dialog-title>
      Add ({{ users.length }}) User(s) to Course
    </h1>
    <h1 *ngIf="remove && !reset" mat-dialog-title>
      Remove User from Course(s)
    </h1>
    <h1 *ngIf="!remove && reset" mat-dialog-title>Reset Course Attempt(s)</h1>
  </div>
  <mat-dialog-content>
    <div class="full-width pb-1">
      <input
        type="text"
        style="padding: 8px; margin: 15px auto; width: 30%"
        placeholder="Filter Courses"
        (keyup)="updateFilter($event)"
      /><br />
      <input
        *ngIf="!remove && !reset"
        type="text"
        [(ngModel)]="embark_job_id"
        style="padding: 8px; margin: 15px; width: 30%"
        placeholder="Embark Job ID"
      /><br />
      <input
        *ngIf="!remove && !reset"
        type="text"
        [(ngModel)]="flight_ops_event_id"
        style="padding: 8px; margin: 15px; width: 30%"
        placeholder="Flight Ops Event ID"
      />
    </div>
    <div class="mat-box-shadow margin-333">
      <ngx-datatable
        #table
        *ngIf="!remove && !reset"
        class="material bg-white"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="50"
        [rows]="rows"
        [loadingIndicator]="isLoading"
        [selected]="selected"
        [selectionType]="'checkbox'"
        (select)="onSelect($event)"
      >
        <ngx-datatable-column
          [width]="30"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          [sortable]="false"
          [canAutoResize]="true"
          [draggable]="false"
          [resizeable]="false"
          name="Course Title"
          prop="title"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          name="Description"
          prop="desc"
        ></ngx-datatable-column>
      </ngx-datatable>
      <ngx-datatable
        #table
        *ngIf="remove"
        class="material bg-white"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="50"
        [rows]="rows"
        [loadingIndicator]="isLoading"
        [selected]="selected"
        [selectionType]="'checkbox'"
        (select)="onSelect($event)"
      >
        <ngx-datatable-column
          [width]="30"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          [sortable]="false"
          [canAutoResize]="true"
          [draggable]="false"
          [resizeable]="false"
          name="Course Title"
          prop="title"
        >
        </ngx-datatable-column>
      </ngx-datatable>
      <ngx-datatable
        #table
        *ngIf="reset"
        class="material bg-white"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [limit]="50"
        [rows]="rows"
        [loadingIndicator]="isLoading"
        [selected]="selected"
        [selectionType]="'checkbox'"
        (select)="onSelect($event)"
      >
        <ngx-datatable-column
          [width]="30"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [headerCheckboxable]="true"
          [checkboxable]="true"
        >
        </ngx-datatable-column>
        <ngx-datatable-column
          [sortable]="false"
          [canAutoResize]="true"
          [draggable]="false"
          [resizeable]="false"
          name="Course Title"
          prop="title"
        >
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="action-wrapper">
      <button mat-button type="button" mat-dialog-close>Cancel</button>
      <button
        *ngIf="!remove && !reset"
        mat-raised-button
        color="primary"
        (click)="enroll()"
        [disabled]="!selected.length || disableModalButton"
      >
        Add
      </button>
      <button
        *ngIf="remove"
        mat-raised-button
        color="primary"
        (click)="removeUserFromCourse()"
        [disabled]="!selected.length"
      >
        Remove
      </button>
      <button
        *ngIf="reset"
        mat-raised-button
        color="primary"
        (click)="resetCourseAttempt()"
        [disabled]="!selected.length"
      >
        Reset Attempt
      </button>
    </div>
  </mat-dialog-actions>
</div>
