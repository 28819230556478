import { Component, OnInit } from '@angular/core';
import { Badge } from '@app/models/course/course-badge';
import { slideInRight } from '@app/animations/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { BadgesService } from '@app/services/data-services/badges.service';
import { HttpService } from '@app/services/http-service.service';
import {FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-badge',
  templateUrl: './manage-badge.component.html',
  styleUrls: ['./manage-badge.component.scss'],
  animations: [
    slideInRight
  ]
})
export class ManageBadgeComponent implements OnInit {
  badge: Badge = new Badge();

  public uploader: FileUploader = new FileUploader({ });

  constructor(private router: Router,
              private badgeService: BadgesService,
              private activatedRoute: ActivatedRoute,
              public snackbar: MatSnackBar,
              private httpService: HttpService) { }

  ngOnInit() {

    this.activatedRoute.params.subscribe((params) => {
      if (params['id'] && /^(\d)+$/.test(params['id'])) {
        const id = parseInt(params['id']);
        if (id) {
          this.badgeService.getBadge(id)
            .then((badge) => {
              if (badge) {
                this.badge = JSON.parse(JSON.stringify(badge));
                return;
              }
              this.cancel();
            });
          return;
        }
        this.cancel();
      }
    });

    this.initUploader();
  }

  upload() {
    if (this.uploader.queue.length) {
      this.uploader.uploadAll();
    } else {
      this.updateBadge();
    }
  }

  updateBadge() {
    this.badgeService.update(this.badge)
      .then(() => {
        this.snackbar.open('Changes Saved')
        this.cancel()
      });
  }

  cancel() {
    this.router.navigate(['admin/badges']);
  }

  initUploader() {
    const url = this.httpService.getFullUrl('images/upload');
    this.uploader.setOptions({url: url});
    this.uploader.authTokenHeader = 'Access-Token';
    this.uploader.authToken = localStorage.getItem('cirrus-token');
    this.uploader.onAfterAddingFile = (item => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    });
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.badge.badge_image = JSON.parse(response).location;
      this.updateBadge();
    };
  }

}
