import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  Renderer2,
  Input,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { NotificationService } from '@app/services/data-services/notification.service';
import { Notification } from '@app/models/notification/notification';
import { GlobalEventManagerService } from '@app/services/global-event-manager/global-event-manager.service';
import * as CheckoutActions from '../../../ui/user/checkout/store/checkout.actions';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducers';
import { Order } from '@app/models/order/order';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss'],
})
export class UserLayoutComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav, { static: true }) private sideNav: MatSidenav;
  @ViewChild('user_layout_wrapper', { static: true }) layoutWrapper;
  @Input() navOpen: boolean;

  private togglePanelSubject = new BehaviorSubject(false);
  showPanel$ = this.togglePanelSubject.asObservable();

  collapse!: boolean;
  hasNav = false;
  isMobile = false;
  order: Order = null;
  subscription: any;
  isScreenSmall: boolean;

  public notificationsToken$ = new BehaviorSubject<Notification[]>([]);
  public notifications$ = this.notificationsToken$.pipe();

  constructor(
    private notificationService: NotificationService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    if (localStorage.getItem('isMobile') !== null) {
      this.isMobile = true;
    }
    if (localStorage.getItem('cirrus-token')) {
      this.notificationService.getMyNotifications().then(notifications => {
        this.notificationsToken$.next(notifications);
      });
    }

    this.breakpointObserver
      .observe('(max-width: 600px)')
      .pipe(
        map(({ matches }) => {
          return matches;
        })
      )
      .subscribe(isScreenSmall => {
        this.isScreenSmall = isScreenSmall;
      });
  }

  togglePanel(e) {
    if (!this.isScreenSmall) {
      return;
    }
    this.togglePanelSubject.next(e);
  }

  isNavOver() {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

  toggleCollapse(collapse: boolean) {
    this.collapse = collapse;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
