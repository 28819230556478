<div class="enrollment-history">
  <mat-card>
    <mat-card-title>
      <h3 class="card-title-text">{{userName}}</h3>
    </mat-card-title>

    <mat-card-content>
      <h3 class="text-block-label">Enrollment History</h3>
      <h3 *ngIf="!isLoading" class="course-title">{{course.name}}</h3>

      <ngx-datatable #table class="material bg-white list-view" [reorderable]="false" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="25" [rows]="courseAttempts" [loadingIndicator]="isLoading" style="width:100%;">
        <ngx-datatable-column [sortable]="false" [canAutoResize]="true" [draggable]="false" [resizeable]="false" name="Attempt Number">
          <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
            {{ courseAttempts.length - rowIndex }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" prop="course_version" [canAutoResize]="true" [draggable]="false" [resizeable]="false" name="Version"></ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" [canAutoResize]="true" [draggable]="false" [resizeable]="false" name="Started">
          <ng-template let-row='row' ngx-datatable-cell-template>
            {{ row.started_at | date:'shortDate' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" [canAutoResize]="true" [draggable]="false" [resizeable]="false" name="Completed">
          <ng-template let-row='row' ngx-datatable-cell-template>
            {{ row.completed_at | date:'shortDate' }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" [canAutoResize]="true" [draggable]="false" [resizeable]="false" name="Status">
          <ng-template let-row='row' ngx-datatable-cell-template>
            {{ row.status | spaces | titlecase }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" [canAutoResize]="true" [draggable]="false" [resizeable]="false">
          <ng-template let-row='row' ngx-datatable-cell-template>
            <button mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">
              <mat-icon class="text-muted">more_vert</mat-icon>
            </button>
            <mat-menu #msgMenu="matMenu">
              <button mat-menu-item routerLink="/instructor/classroom/transcripts/{{userCourse.user_id}}/course-detail/{{row.id}}">View Detail</button>
            </mat-menu>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </mat-card-content>

  </mat-card>
</div>
