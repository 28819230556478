<div class="bold-text">
  {{ title }}
  <span *ngIf="subtext" class="dimensions">({{ subtext }})</span>
</div>
<div class="incorrect-image-size-text" *ngIf="incorrectImageSize">{{imageUploadErrorText()}}</div>
<div
  class="upload-container"
  ng2FileDrop
  [uploader]="uploader"
  (fileOver)="fileOver($event)"
>
  <div class="inner-container">
    <div class="pl-half" fxFlex="39">
      <img *ngIf="url" src="{{ url }}" alt="" />
    </div>
    <label for="{{ editorId }}" fxFlex="55"
      ><div class="image-btn" *ngIf="uploader_only; else wys">
        {{ url ? 'Replace' : 'SELECT IMAGE' }}
      </div>
      <ng-template #wys
        ><div class="image-btn">ADD IMAGE TO EDITOR</div></ng-template
      >
    </label>
    <app-mat-menu
      *ngIf="uploader_only"
      [menuIcon]="'more_vert'"
      [inputItems]="menuItems"
      [iconStyle]="{ color: 'black', backgroundColor: 'white' }"
      [menu_style]="menu_style"
      (emitMenuItem)="removeUrl($event)"
    ></app-mat-menu>
    <input
      type="file"
      id="{{ editorId }}"
      ng2FileSelect
      [uploader]="uploader"
    />
  </div>
</div>
