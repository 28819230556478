import { Component, Input, OnInit } from '@angular/core';
import { TrainingPartnerRow } from '@app/training-centers/training-partner-row';
import { MatTableDataSource } from '@angular/material/table';
import { state, style, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@app/components/shared/snackbar/snackbar.component';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { DeviceType } from '@app/models/DeviceType';

@Component({
  selector: 'app-training-partners-table',
  templateUrl: './training-partners-table.component.html',
  styleUrls: ['./training-partners-table.component.scss'],
  animations: [
    trigger('rowExpand', [
      state('collapsed', style({ height: 0, minHeight: 0 })),
      state('expanded', style({ height: '*' })),
    ]),
  ],
})
export class TrainingPartnersTableComponent implements OnInit {
  @Input() trainingPartners: MatTableDataSource<TrainingPartnerRow>;
  @Input() refreshToken: BehaviorSubject<unknown>;
  @Input() noRowsMessage = 'No data';

  _deviceType: DeviceType;
  get deviceType(): DeviceType {
    return this._deviceType;
  }
  @Input() set deviceType(value: DeviceType) {
    this._deviceType = value;
    this.setDisplayedColumn();
  }

  public displayedColumns: string[] = [
    'name',
    'location',
    'airport',
    'phone',
    'type',
    'rowAction',
  ];
  public expandedRow: TrainingPartnerRow | null;

  constructor(private snackBar: MatSnackBar, public dialog: MatDialog) {}

  ngOnInit(): void {}

  handleAction(row) {
    row.action.actionHandler(this.dialog).subscribe((isSuccessful) => {
      if (isSuccessful) {
        this.snackBar.open(row.action.snackbarText);
        this.refreshToken.next(undefined);
      }
    });
  }

  setDisplayedColumn() {
    switch (this._deviceType) {
      case DeviceType.Desktop: {
        this.displayedColumns = [
          'name',
          'location',
          'airport',
          'phone',
          'type',
          'rowAction',
        ];
        break;
      }
      case DeviceType.Tablet: {
        this.displayedColumns = [
          'name',
          'location',
          'phone',
          'rowAction',
        ];
        break;
      }
      case DeviceType.Phone: {
        this.displayedColumns = ['name', 'location', 'rowAction'];
        break;
      }
    }
  }
}
