import { Injectable } from '@angular/core';
import { BaseApiV4Service } from './base-api-v4.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IContinueResponse } from './models/course/continue-response';
import { Lesson } from './models/course/workbook/lesson/lesson';
import { concatMap, finalize, map, tap } from 'rxjs/operators';
import { PdfDownloadFile } from './models/pdf-download-file';

@Injectable({
  providedIn: 'root',
})
export class PdfV4Service extends BaseApiV4Service {
  private transcriptLoadingSubject = new BehaviorSubject(false);
  transcriptloading$ = this.transcriptLoadingSubject.asObservable();

  downloadCertificate(user_certificate_id: number): Observable<PdfDownloadFile> {
    const url = this.buildRequestUrl(
      `user_certificates/${user_certificate_id}.html`
    );

    return of(null).pipe(
      tap(() => this.transcriptLoadingSubject.next(true)),
      concatMap(() => this.http.get(url, { observe: 'response', responseType: 'blob' })),
      map(response => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const name = this.parseFilename(contentDisposition as string);
        const newPdfFile: PdfDownloadFile = {
          filename: name,
          file: response.body as Blob
        }

        return newPdfFile;
      }),
      finalize(() => this.transcriptLoadingSubject.next(false))
    );
  }

  downloadTranscript(course_id: number, course_attempt_id: number): Observable<PdfDownloadFile> {
    const param =
      course_attempt_id > 0 ? `?course_attempt_id=${course_attempt_id}` : '';
    const url = this.buildRequestUrl(
      `courses/${course_id}/transcript.pdf` + param
    );

    return of(null).pipe(
      tap(() => this.transcriptLoadingSubject.next(true)),
      concatMap(() => this.http.get(url, { observe: 'response', responseType: 'blob' })),
      map(response => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const name = this.parseFilename(contentDisposition as string);
        const newPdfFile: PdfDownloadFile = {
          filename: name,
          file: response.body as Blob
        }

        return newPdfFile;
      }),
      finalize(() => this.transcriptLoadingSubject.next(false))
    );
  }

  parseFilename(contentDisposition: string): string {
    const filenameStarRegex = /filename\*=UTF-8''([^;]+)/;
    const matchesStar = filenameStarRegex.exec(contentDisposition);
    if (matchesStar && matchesStar[1]) {
      return decodeURIComponent(matchesStar[1]);
    }

    const filenameRegex = /filename="([^"]+)"/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches && matches[1]) {
      return matches[1];
    }

    return '';
  }
}
