import {Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LessonFlightLogService} from '@app/services/data-services/lesson-flight-log.service';
import {CourseService} from '@app/services/data-services/course.service';
import {UserCourse} from '@app/models/course/user-course';
import {TaskService} from '@app/services/data-services/task.service';
import {ProgressCalculatorService} from '@app/services/helper/progress-calculator.service';
import {ProgressService} from '@app/services/data-services/progress.service';
import {ContentType, ContentTypeMap} from '@app/models/course/content/content-type';
import {UserService} from '@app/services/data-services/user.service';
import {PdfService} from '@app/services/helper/pdf.service';
import {DomSanitizer} from '@angular/platform-browser';
import {TaskDeferment} from '@app/models/course/content/task/task-deferment';
import { TaskDefermentService } from '@app/services/data-services/task-deferment.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SnackbarComponent} from '@app/components/shared/snackbar/snackbar.component';
import {UserProgressType} from '@app/models/user/user-progress-type';
import {Status} from '@app/models/shared/status';
import * as CourseActions from '../../../../user/course/store/course.actions';
import {UserProgress} from '@app/models/user/user-progress';
import {Store} from '@ngrx/store';
import * as fromApp from '../../../../../store/app.reducers';
import {CertificatesService} from '@app/services/data-services/certificates.service';
import {BadgesService} from '@app/services/data-services/badges.service';
import {Task} from '@app/models/course/content/task/task';
import {environment} from '../../../../../../environments/environment';
import {EmbarkJobDialogComponent} from '@app/components/shared/embark-job-dialog/embark-job-dialog.component';


@Component({
  selector: 'app-admin-report-course-detail',
  templateUrl: './admin-report-course-detail.component.html',
  styleUrls: ['./admin-report-course-detail.component.scss']
})
export class AdminReportCourseDetailComponent implements OnInit {
  deferred: TaskDeferment[] = [];
  lessonFlightLogs = [];
  user_course: UserCourse;
  task_attempts = [];
  progresses = [];
  contentType = ContentType;
  contentTypeMap = ContentTypeMap;
  requirements: any[] = [];
  start_date: Date;
  course_attempt_id: number;
  completed_at: Date;
  overall = 0;
  total_lessons = 0;
  total_self_study = 0;
  total_ground = 0;
  total_flight = 0;
  completed_self = 0;
  completed_ground = 0;
  completed_flight = 0;
  total_flight_assessments = 0;
  total_ground_assessments = 0;
  completed_flight_assessments = 0;
  completed_ground_assessments = 0;
  course_progress_record = null;
  total_flight_hours = 0;
  total_sim_hours = 0;
  total_ground_hours = 0;
  user_name = '';
  url = null;
  loading = true;
  exporting = false;
  embarkDialog: any;
  contents: any[] = [];
  export_transcript = false;
  isLoading = false;
  @Input('user_id') user_id: number;
  @Input('course_id') course_id: number;
  @ViewChild('badge_image') badge_image: ElementRef;
  @ViewChild('default_badge') default_badge: ElementRef;
  @ViewChild('course_detail') course_detail: ElementRef;
  @Output('done') done: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('image', { static: true }) image: ElementRef;

  // Remove me once force completion is live...
  force_complete_config: string;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              public dialog: MatDialog,
              private courseService: CourseService,
              private userService: UserService,
              private taskService: TaskService,
              public progressCalculator: ProgressCalculatorService,
              private progressService: ProgressService,
              private taskDeferService: TaskDefermentService,
              private snackBar: MatSnackBar,
              private store: Store<fromApp.AppState>,
              private certificateService: CertificatesService,
              private badgeService: BadgesService,
              private pdfService: PdfService,
              private sanitizer: DomSanitizer,
              private _ngZone: NgZone,
              private lessonFlightLogService: LessonFlightLogService) {
    window['transcriptDetail'] = {component: this, zone: _ngZone};
  }

  ngOnInit() {
    this.getRole();
    // Remove me once force complete is live...
    this.force_complete_config = environment.force_completion;
    // console.log('Force Complete Config: ', this.force_complete_config);

    if (this.course_id && this.user_id) {
      this.getCourseDetail(this.course_id, this.user_id);
    } else {
      this.activatedRoute.params.subscribe(params => {
        if (params['id'] && params['user_id']) {
          this.getCourseDetail(params['id'], params['user_id']);
          this.user_id = params['user_id'];
        }
      });
    }
  }

  getCourseDetail(course_id, user_id) {
    // console.log('user id: ', user_id);
    this.courseService.getUserCourseDetail(course_id, user_id)
      .then(user_course => {
        this.user_course = user_course;
        this.course_attempt_id = user_course.course_attempt_id;
      })
      .then(() => {
        this.progressService.getUserProgressCourseDetail(course_id, user_id)
          .then(progresses => {
            this.progresses = progresses;
            this.progressCalculator.setProgresses(progresses);
          })
          .then(() => this.setCourseInfo())
          .then(() => this.setRequirements());
      });

    this.userService.getUserName(user_id)
      .then(res => this.user_name = res);

    this.lessonFlightLogService.getLessonFlightLogsCourseDetail(course_id, user_id)
      .then(lessonFlightLogs => this.lessonFlightLogs = lessonFlightLogs)
      .then(() => this.calcFlightTime());

    this.taskService.getEntriesCourseDetail(course_id, user_id)
      .then(task_attempts => this.task_attempts = task_attempts);

    this.taskDeferService.getTaskDefermentsOfCourseByUser(user_id, course_id)
      .then(taskDeferments => this.deferred = taskDeferments);
  }

  setCourseInfo() {
    const isComplete: any = this.progressCalculator.checkForCourseProgressComplete(this.user_course.course);
    let overall: any = '0';

    const progresses = this.getCourseProgress(this.user_course);

    if (progresses.length) {
      overall = ((progresses.filter(progress => progress === true).length / progresses.length) * 100).toFixed(0);
    }
    // console.log('Progresses', this.progresses);
    const start_date  = this.progresses.filter(progress => progress.progress_type.toString() ===  'course' && progress.course_id === this.user_course.course_id);
    // console.log('Start Date: ', start_date);
    this.course_progress_record = start_date.length ? start_date[0] : '';
    this.start_date = start_date.length ? start_date[0].created_at : '';
    this.completed_at = start_date.length ? start_date[0].updated_at : '';
    this.overall = overall;
    this.loading = false;

    // This is for triggering exporting to pdf within transcript list view
    if (this.course_id && this.user_id) {
      this.done.emit();
    }
  }

  calcFlightTime() {
    this.total_flight_hours = 0;
    this.total_ground_hours = 0;
    this.total_sim_hours = 0;
    this.lessonFlightLogs.forEach(flight_log => {
      this.total_ground_hours += Number(flight_log.completedGroundInstructionHours);
      this.total_flight_hours += Number(flight_log.completedTotalHours);
      this.total_sim_hours += Number(flight_log.completedSimHours);
    });
  }

  getCourseProgress(userCourse) {
    return this.progressCalculator.getTranscriptProgress(userCourse);
  }

  isCompleted(course, stage, lesson, content) {
    return this.progressCalculator.checkIfContentIsComplete(lesson, content, this.progresses);
  }

  isForceCompleted(course, stage, lesson, content) {
    return this.progressCalculator.checkIfContentIsForceCompleted(lesson, content, this.progresses);
  }

  setRequirements() {
    if (this.user_course.course != null) {
      this.total_lessons = 0;
      this.user_course.workbook.stages.forEach((stage, index) => {
        stage.lessons.forEach((lesson) => {
          this.total_lessons++;
          switch (lesson.lesson_type) {
            case 0:
              this.total_self_study++;
              if (this.checkIfComplete(lesson, 'lesson')) {
                this.completed_self++;
              }
              break;
            case 1:
              this.total_ground++;
              if (this.checkIfComplete(lesson, 'lesson')) {
                this.completed_ground++;
              }
              break;
            case 2:
              this.total_flight++;
              if (this.checkIfComplete(lesson, 'lesson')) {
                this.completed_flight++;
              }
              break;
            default:
              break;
          }

          if (lesson && lesson.contents) {
            lesson.contents.forEach((content) => {
              switch (content.content_type) {
                case 9:
                  this.total_flight_assessments++;
                  if (this.checkIfComplete(content, 'content')) {
                    this.completed_flight_assessments++;
                  }
                  break;
                case 10:
                  this.total_ground_assessments++;
                  if (this.checkIfComplete(content, 'content')) {
                    this.completed_ground_assessments++;
                  }
                  break;
                default:
                  break;
              }
            });
          }
        });
      });
    }
  }

  checkIfComplete(item, type) {
    return this.progresses.filter(progress => progress.progress_type.toString() === type && progress.item_id === item.id && progress.status.toString() === 'completed').length;
  }

  checkIfForceComplete(item, type) {
    return this.progresses.filter(progress => progress.progress_type.toString() === type && progress.item_id === item.id && progress.is_force_completed === true && progress.status.toString() === 'completed').length;
  }

  checkIfInProgress(item, type) {
    return this.progresses.filter(progress => progress.progress_type.toString() === type && progress.item_id === item.id && progress.status.toString() === 'in_progress').length;
  }

  getPassed(task_id: number, content_id: number) {
    return this.task_attempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && task_attempt.success).length;
  }

  getMissed(task_id: number, content_id: number) {
    return this.task_attempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && (!task_attempt.success)).length;
  }

  getPerfect(task_id: number, content_id: number) {
    return this.task_attempts.filter(task_attempt => task_attempt.task_id === task_id && task_attempt.content_id === content_id && task_attempt.perfect).length;
  }

  getDeferred(task_id: number, content_id: number, lesson_id: number, task_type: string) {
    return this.deferred.filter(task_defer => task_defer.task_id === task_id && task_defer.content_id === content_id && task_defer.lesson_id === lesson_id && task_defer.task_type.toString() === task_type.toString()).length;
  }

  getRole() {
    return localStorage.getItem('cirrus-role');
  }


  checkIfTaskAttemptExists(task_id: number, content_id: number) {
  }

  forceCompleteTasksForAssessment(course_id: number, stage_id: number, lesson_id: number, content_id: number, task: Task) {
    // this.checkIfTaskAttemptExists(task.id, content_id);

    // const new_attempt = new TaskAttempt();
    //
    // new_attempt.course_id = course_id;
    // new_attempt.stage_id = stage_id;
    // new_attempt.lesson_id = lesson_id;
    // new_attempt.content_id = content_id;
    // new_attempt.task_id = task.id;
    // new_attempt.user_id = this.user_id;
    // new_attempt.success = 1;
    //
    // if (task.task_type.toString() === 'practice') {
    //   new_attempt.attempt_type = 0;
    // } else {
    //   new_attempt.attempt_type = 1;
    // }

    // this.taskService.taskEntry(new_attempt)
    //   .then(task_attempt => {
    //
    //   });
  }

  // forceCompleteCourse() {
  //   if (confirm('Are you sure you want to force complete this course?')) {
  //     if (this.user_course.course != null) {
  //       for (const stage of this.user_course.course.workbook.stages) {
  //         for (const lesson of stage.lessons) {
  //           for (const content of (lesson as any).contents) {
  //             this.progressCalculator.createProgressForContentForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage, lesson, content).then(() => {
  //             });
  //           }
  //           this.progressCalculator.checkLessonEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage, lesson).then(() => {
  //             // console.log('Lesson check has finished');
  //           });
  //         }
  //         this.progressCalculator.checkStageEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage).then(() => {
  //           // console.log('Stage check has finished.');
  //         });
  //       }
  //       this.progressCalculator.forceCompleteCourseForSpecificUser(this.user_id, this.user_course).then((res) => {
  //         // console.log('Course check has finished.');
  //         this.checkForCompletion();
  //       });
  //     }
  //   }
  // }

  // forceCompleteStage(stage_id) {
  //   if (confirm('Are you sure you want to force complete this stage?')) {
  //     if (this.user_course.course != null) {
  //       for (const stage of this.user_course.course.workbook.stages) {
  //         if (stage.id === stage_id) {
  //           for (const lesson of stage.lessons) {
  //             for (const content of (lesson as any).contents) {
  //               this.progressCalculator.createProgressForContentForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage, lesson, content).then(() => {
  //               });
  //             }
  //             this.progressCalculator.checkLessonEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage, lesson).then(() => {
  //               // console.log('Lesson check has finished');
  //             });
  //           }
  //           this.progressCalculator.checkStageEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage).then(() => {
  //             // console.log('Stage check has finished');
  //           });
  //         }
  //       }
  //       this.progressCalculator.checkCourseEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course).then((res) => {
  //         // console.log('Course check has finished');
  //         this.checkForCompletion();
  //       });
  //     }
  //   }
  // }
  //
  // forceCompleteLesson(lesson_id) {
  //   if (confirm('Are you sure you want to force complete this lesson?')) {
  //     if (this.user_course.course != null) {
  //       for (const stage of this.user_course.course.workbook.stages) {
  //         for (const lesson of stage.lessons) {
  //           if (lesson.id === lesson_id) {
  //             for (const content of (lesson as any).contents) {
  //                 this.progressCalculator.createProgressForContentForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage, lesson, content).then(() => {
  //                 });
  //             }
  //             this.progressCalculator.checkLessonEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage, lesson).then(() => {
  //               // console.log('Lesson check has finished');
  //             });
  //             this.progressCalculator.checkStageEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage).then(() => {
  //               // console.log('Stage check has finished.');
  //             });
  //           }
  //         }
  //       }
  //       this.progressCalculator.checkCourseEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course).then((res) => {
  //         // console.log('course check has finished.');
  //           this.checkForCompletion();
  //       });
  //     }
  //   }
  // }
  //
  // forceCompleteContent(course, stage, lesson, content) {
  //   this.progressCalculator.createProgressForContentForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage, lesson, content).then(() => {
  //     this.progressCalculator.checkLessonEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage, lesson).then(() => {
  //       this.progressCalculator.checkStageEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course, stage).then(() => {
  //         this.progressCalculator.checkCourseEntryForSpecificUser(this.course_attempt_id, this.user_id, this.user_course).then(() => {
  //           this.checkForCompletion();
  //         });
  //       });
  //     });
  //   });
  // }


  checkForCompletion() {
    // console.log('CHECKING FOR COMPLETION');
    if (this.user_course.is_completed) {
      if (!this.user_course.certificate_awarded) {
        // console.log('CHECKING FOR COMPLETION: TRIGGERING CERT 1');
        this.certificateService.checkCertificates(this.user_course.course_attempt_id, this.user_id)
          .then(res => res ? this.user_course.certificate_awarded = true : null);
      }

      if (!this.user_course.badge_awarded) {
        // console.log('CHECKING FOR COMPLETION: TRIGGERING BADGE 1');
        this.badgeService.checkBadges(this.user_course.course_id, this.user_id)
          .then(res => res ? this.user_course.badge_awarded = true : null);
      }
    } else {
      // console.log('CHECKING FOR COMPLETION FIRST TIME');
      const complete = this.progressCalculator.checkIfCourseIsComplete(this.user_course);
      const course_progress = this.progresses.filter(progress => progress.progress_type.toString() === 'course' && progress.item_id === this.user_course.course_id);
      if (complete) {
        let progressToSave = new UserProgress();

        if (course_progress.length && course_progress[0]) {
          progressToSave = course_progress[0];
        }
        // console.log(progressToSave);

        progressToSave.course_id = this.user_course.course_id;
        progressToSave.progress_type = UserProgressType.course;
        progressToSave.item_id = this.user_course.course_id;
        progressToSave.status = Status.completed;

        // console.log('CHECKING FOR COMPLETION FIRST TIME: COURSE IS COMPLETE: TRIGGERING BADGE AND CERT');
        if (!course_progress.length) {
          this.progressService.saveProgress(progressToSave)
            .then(res => {
              if (res) {
                this.certificateService.checkCertificates(this.user_course.course_attempt_id, this.user_id)
                  .then(certRes => {
                    if (certRes) {
                      this.user_course.certificate_awarded = true;
                      this.store.dispatch(new CourseActions.SelectCourse(this.user_course));
                    }
                  });
                this.badgeService.checkBadges(this.user_course.course_id, this.user_id)
                  .then(badgeRes => {
                    if (badgeRes) {
                      this.user_course.badge_awarded = true;
                      this.store.dispatch(new CourseActions.SelectCourse(this.user_course));
                    }
                  });
              }
            });
        } else {
          this.progressService.updateProgress(progressToSave)
            .then(res => {
              if (res) {
                this.certificateService.checkCertificates(this.user_course.course_attempt_id, this.user_id)
                  .then(certRes => {
                    if (certRes) {
                      this.user_course.certificate_awarded = true;
                      this.store.dispatch(new CourseActions.SelectCourse(this.user_course));
                    }
                  });
                this.badgeService.checkBadges(this.user_course.course_id, this.user_id)
                  .then(badgeRes => {
                    if (badgeRes) {
                      this.user_course.badge_awarded = true;
                      this.store.dispatch(new CourseActions.SelectCourse(this.user_course));
                    }
                  });
              }
            });
        }
      }
    }
  }

  openCompletionSnackBar(content) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 3000,
      data: '"' + content.title + '" has been updated to Complete.'
    });
  }
  openDialog() {
    this.embarkDialog = this.dialog.open(EmbarkJobDialogComponent);
  }
  setEmbarkID() {
    this.openDialog();
    this.embarkDialog.afterClosed().subscribe((embarkID) => {
      if (embarkID) {
        this.handleEmbarkEdit(embarkID);
      }
    });
  }

  downloadPdf(callback?: Function) {
    console.log('downloadPdf=', 'reached');
    this.exporting = true;
    setTimeout(() => {
      let badge = this.badge_image;
      if (this.default_badge) {
        badge = this.default_badge;
      }
      // const containers = document.getElementsByClassName('export-container');
      const container = document.getElementById('export-wrapper');
      this.pdfService.createPdf(container, this.course_detail.nativeElement.scrollHeight, this.user_name, badge.nativeElement, callback);
      this.exporting = false;
    }, 0);
  }
  download(cert) {
    this.url = cert.certificate.certificate_image;
    this.image.nativeElement.onload = () => {
      this.pdfService.download(this.image.nativeElement, cert, () => {
        this.url = '';
      });
    };
  }
  downloadCertificate(course_id) {
    this.certificateService.getStudentCertificate(course_id, this.user_id)
      .then(cert => this.download(cert));
  }
  downloadTranscript(course_id) {
    console.log('this =', this);
    this.course_id = course_id;
    this.export_transcript = true;
    this.isLoading = true;
  }
  export() {
    window['transcriptDetail'].component.downloadPdf(() => {
      this.export_transcript = false;
      this.isLoading = false;
    });
  }
  addEmbarkID() {

  }
  handleEmbarkEdit(value) {
    if(value !== this.user_course.embark_job_id) {
      this.courseService.updateEmbarkID(this.user_course.id, value)
        .then(response => {
          this.user_course.embark_job_id = value;
          let embarkStatus = value !== null ? 'updated' : 'removed';
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: 'Embark Job ID has been ' + embarkStatus
          });
        });
    }
  }
}
