import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-view-assessments-dialog',
    templateUrl: './view-assessments-dialog.component.html',
    styleUrls: ['./view-assessments-dialog.component.scss']
})
export class ViewAssessmentsDialogComponent {
    title = 'Important Update:';
    description = 'Please switch to the iOS Cirrus Approach Instructor app for continued access. ' +
        'The web instructor experience is being phased out, and the web assessment editor is ' +
        'no longer accessible via web browsers.';

    constructor(public dialogRef: MatDialogRef<ViewAssessmentsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
        ) {}

    close() {
        this.dialogRef.close();
    }
}