export enum UserRole {
  user = 0,
  pilot = 1,
  instructor = 2,
  admin = 3,
  super_admin = 4
}

export const UserRoleMap = {
  0: 'User',
  1: 'Pilot',
  2: 'Instructor',
  3: 'Administrator',
  4: 'Super Admin'
};
