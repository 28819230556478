import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { Faq } from '@app/models/faq';

@Injectable()
export class FaqService {
  faqUrl = 'faqs';

  constructor(private http: HttpService) { }

  getFaqs(): Promise<Faq[]> {
    return this.http.get<Faq[]>(this.faqUrl)
      .toPromise()
      .catch(FaqService.handleError);
  }

  getFaq(id: number): Promise<Faq> {
    const url = `${this.faqUrl}/${id}`;
    return this.http.get<Faq>(url)
      .toPromise()
      .catch(FaqService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.faqUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(FaqService.handleError);
  }

  create(faq: Faq): Promise<Faq> {
    return this.http
      .post(this.faqUrl, JSON.stringify(faq))
      .toPromise()
      .then(res => res)
      .catch(FaqService.handleError);
  }

  update(faq: Faq): Promise<void> {
    const url = `${this.faqUrl}/${faq.id}`;
    return this.http
      .put(url, JSON.stringify(faq))
      .toPromise()
      .then(() => null)
      .catch(FaqService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
