import { Component, OnInit } from '@angular/core';
import {Assignment} from '@app/models/course/assignment';
import {slideInRight} from '@app/animations/animations';
import {AssignmentService} from '@app/services/data-services/assignment.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpService} from '@app/services/http-service.service';

@Component({
  selector: 'app-assignments-manager-manage',
  templateUrl: './assignments-manager-manage.component.html',
  styleUrls: ['./assignments-manager-manage.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AssignmentsManagerManageComponent implements OnInit {
  assignment_model = new Assignment();

  constructor(private assignmentService: AssignmentService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private http: HttpService) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.assignmentService.getOneAssignment(params['id'])
          .then(assignment => this.assignment_model = assignment);
      }
    });
  }

  update() {
    this.assignmentService.update(this.assignment_model)
      .then(() => this.cancel());
  }

  cancel() {
    this.router.navigate(['instructor/classroom/assignments']);
  }

}
