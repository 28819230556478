<mat-dialog-content class="content-dialog-wrapper" style="position: relative;">
  <button class="close-btn" mat-icon-button mat-dialog-close><mat-icon class="close-icon">close</mat-icon></button>
  <mat-tab-group fxFlex="grow" class="p-05">
    <mat-tab label="Courses">
      <div class="content-wrapper"
           fxLayout="column wrap">
        <div class="full-width content-wrapper">
          <div class="mat-box-shadow margin-333">
            <ngx-datatable class="material bg-white"
                           [rows]="courseRows"
                           [columnMode]="'force'"
                           [headerHeight]="50"
                           [footerHeight]="50"
                           [rowHeight]="'auto'"
                           [limit]="50">
              <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
              <ngx-datatable-column name="Description" prop="desc" [pipe]="shortPipe"></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Badges">
      <div class="content-wrapper"
           fxLayout="column wrap">
        <div class="full-width content-wrapper">
          <div class="mat-box-shadow margin-333">
            <ngx-datatable class="material bg-white"
                           [rows]="badgeRows"
                           [columnMode]="'force'"
                           [headerHeight]="50"
                           [footerHeight]="50"
                           [rowHeight]="'auto'"
                           [limit]="50">
              <ngx-datatable-column
                [sortable]="false"
                [canAutoResize]="true"
                [draggable]="false"
                [resizeable]="false">
                <ng-template  let-row='row' ngx-datatable-cell-template>
                  <img src="{{row?.badge_image}}" alt="" width="75" height="75">
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
              <ngx-datatable-column name="Description" prop="desc"></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Certificates">
      <div class="content-wrapper"
           fxLayout="column wrap">
        <div class="full-width content-wrapper">
          <div class="mat-box-shadow margin-333">
            <ngx-datatable class="material bg-white"
                           [rows]="certificateRows"
                           [columnMode]="'force'"
                           [headerHeight]="50"
                           [footerHeight]="50"
                           [rowHeight]="'auto'"
                           [limit]="50">
              <ngx-datatable-column
                [sortable]="false"
                [canAutoResize]="true"
                [draggable]="false"
                [resizeable]="false">
                <ng-template  let-row='row' ngx-datatable-cell-template>
                  <img src="{{row?.certificate_image}}" alt="" width="75" height="75">
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Name" prop="name"></ngx-datatable-column>
              <ngx-datatable-column name="Description" prop="desc"></ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
