<mat-sidenav-container class="app-side-nav-container mat-drawer-transition">
    <!-- Main side admin-user-sidenav -->
    <mat-sidenav
      #sidenav
      [opened]="!isNavOver()"
      [mode]="isNavOver() ? 'over' : 'side'"
      class="sidebar-panel"
    >
      <div id="scroll-area" class="navigation-hold" fxLayout="column">
        <!-- App Logo -->
        <div class="branding default-bg">
          <img
            src="assets/images/cirrus-approach-logo.svg"
            alt=""
            class="expanded-logo"
          />
        </div>

        <!--&lt;!&ndash; Sidebar user &ndash;&gt;-->
        <!--<div class="app-user">-->
        <!--<div class="app-user-photo">-->
        <!--<img src="assets/images/person-icon.png" alt="">-->
        <!--</div>-->
        <!--</div>-->
        <!-- Navigation -->
        <app-backend-sidenav
          [extendedMenu]="extendedMenu"
           menuType="ctc"
        ></app-backend-sidenav>
      </div>
    </mat-sidenav>
    <div
      id="extended-scroll-area"
      class="sidebar-panel navigation-hold"
      fxLayout="column"
    >
    <app-backend-sidenav
        menuType="ctc"
        #extendedMenu
        [type]="'extendedMenu'"
        menuType="ctc"
      ></app-backend-sidenav>
    </div>

    <!-- Top Bar -->
    <!-- Template reference variables of Left sidebar and Right notification is supplied -->
    <!-- Listens language change event -->
    <app-backend-topbar [sidenav]="sidenav" [notificPanel]="notificationPanel">
    </app-backend-topbar>
    <app-breadcrumb #breadcrumb></app-breadcrumb>

    <!-- App content -->
    <div class="rightside-content-hold">
      <router-outlet></router-outlet>
    </div>
    <mat-sidenav
      #notificationPanel
      mode="over"
      class=""
      position="end"
      style="background-color: lightgrey !important"
    >
      <div class="navigation-hold" fxLayout="column">
        <app-notifications
          [notificationsToken]='notificationsToken$'
          [notifications$]="notifications$"
          [notificPanel]="notificationPanel"
        ></app-notifications>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>