export function forceVersionChange(activeSet: any[], controlSet: any[]) {
  const activeIds = activeSet.map(object => object.id),
    controlSetIds = controlSet.map(object => object.id),
    lengthChanged = activeSet.length !== controlSet.length;
  // console.log("activeSet Length: ", activeSet.length);
  // console.log("controlSet Length: ", controlSet.length);

  return (
    lengthChanged ||
    _orderChanged(activeIds, controlSetIds) ||
    _IDsChanged(activeIds, controlSetIds)
  );
}

function _IDsChanged(activeIDs, controlIDs) {
  const activeSet = activeIDs.slice().sort(),
    controlSet = controlIDs.slice().sort();
  // console.log("IDsChanged Active Set: ", activeIDs);
  // console.log("IDsChanged Control Set: ", controlIDs);
  return activeSet.toString() !== controlSet.toString();
}

function _orderChanged(activeSetOrder, controlSetOrder) {
  // console.log("orderChanged Active Set Order: ", activeSetOrder.toString());
  // console.log("orderChanged Control Set Order: ", controlSetOrder.toString());
  return activeSetOrder.toString() !== controlSetOrder.toString();
}

export const GroupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    if (value !== null)
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export function sortWorkbook(course) {
  if (course.workbook && course.workbook.stages) {
    course.workbook.stages.forEach((stage, stageIndex) => {
      stage.order = stageIndex;
      stage.lessons.forEach((lesson, lessonIndex) => {
        lesson.order = lessonIndex;
      });
    });
  }
  return course;
}

function modelSort(a, b) {
  return a && b ? a.order - b.order : -1;
}

export function deepMap(obj, f, ctx?) {
  if (Array.isArray(obj)) {
    return obj.map(function (val, key) {
      return typeof val === 'object'
        ? deepMap(val, f, ctx)
        : f.call(ctx, val, key);
    });
  } else if (typeof obj === 'object') {
    if (obj == null) return obj;
    let res = {};
    for (var key in obj) {
      var val = obj[key];
      if (typeof val === 'object') {
        res[key] = deepMap(val, f, ctx);
      } else {
        res[key] = f.call(ctx, val, key);
      }
    }
    return res;
  } else {
    return obj;
  }
}
