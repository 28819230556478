import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, searchProperty: string, sortProperty?: string, sortValueCheck?: any, courseFilter?: string): any[] {
    // if empty array
    if (!items) { return []; }

    // if search box is empty check for sort property and value
    if (!searchText) {
      if (sortProperty && sortValueCheck != null) {
        return items.filter(item => {
            if (courseFilter && courseFilter === 'all') {
              return item[sortProperty] === sortValueCheck;
            }else if (courseFilter && courseFilter === 'enrolled') {
              return item[sortProperty] === sortValueCheck && item.purchased;
            }
            return item[sortProperty] === sortValueCheck;
        });
      }
      return items;
    }

    // if seach box contains text
    searchText = searchText.toLowerCase();
    return items.filter(item => {
         if (sortProperty !== '' || sortProperty !== null) {
           if (courseFilter && courseFilter !== 'all') {
             return item[searchProperty].toLowerCase().includes(searchText) && item[sortProperty] === sortValueCheck;
           }else if (courseFilter && courseFilter === 'enrolled') {
             return item[searchProperty].toLowerCase().includes(searchText) && item[sortProperty] === sortValueCheck && item.purchased;
           }
          return item[searchProperty].toLowerCase().includes(searchText) && item[sortProperty] === sortValueCheck;
        }
        return item[searchProperty].toLowerCase().includes(searchText);
    });
  }
}
