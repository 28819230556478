import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';
import {ContentPlayerComponent} from '../../content-player.component';

@Component({
    selector: 'app-interactive',
    templateUrl: './interactive.component.html',
    styleUrls: ['./interactive.component.scss']
})
export class InteractiveComponent implements OnInit, AfterViewInit {

    @Input('content') content: any;
    @Input('dialogRef') dialogRef: MatDialogRef<ContentPlayerComponent>;
    @ViewChild('frame', { static: true }) frame: ElementRef;
    hasResized = false;
    @Output() create_interactive: EventEmitter<any> = new EventEmitter<any>();
    url: any = '';
    constructor(private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
      console.log('Route: ', 'interactive.component.ts');
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.content_file);
    }

    ngAfterViewInit() {
    }

    resize() {
      setTimeout(() => {
        if (!this.hasResized) {
          this.hasResized = true;
          const dialogContainer = this.dialogRef['_containerInstance']['_elementRef'].nativeElement;
          dialogContainer.style.width = '0px';

          setTimeout(() => {
            dialogContainer.style.width = '100%';
          }, 200);
          // console.log('ms 200: ', 'True');
        }
      }, 3000);
      // console.log('ms 3000: ', 'True');
    }

}
