import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-classroom-dashboard',
  templateUrl: './classroom-dashboard.component.html',
  styleUrls: ['./classroom-dashboard.component.scss']
})
export class ClassroomDashboardComponent implements OnInit {
  classroomMenu = [
    {
      name: 'INSTRUCTOR DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: '/instructor/dashboard'
    }, {
      name: 'STUDENTS',
      type: 'link',
      tooltip: 'Students',
      icon: 'class',
      state: '/instructor/classroom/students'
    },
    // {
    //   name: 'ASSIGNMENTS',
    //   type: 'link',
    //   tooltip: 'Assignments',
    //   icon: 'assignment',
    //   state: '/instructor/classroom/assignments'
    // },
  ];
  constructor() { }

  ngOnInit() {
  }

}
