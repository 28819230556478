import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-reports-dialog',
  templateUrl: './admin-reports-dialog.component.html',
  styleUrls: ['./admin-reports-dialog.component.scss']
})
export class AdminReportsDialogComponent implements OnInit {

  constructor(public adminReportsDialogRef: MatDialogRef<AdminReportsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public adminReportsDialogData: any) {
  }

  ngOnInit() {
  }



}
