<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Certificates</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="full-width pb-1">
          <button mat-raised-button color="primary" (click)="navigate('admin/certificates/add')">
            Add Certificate
          </button>
          <input
            type='text'
            style='padding:8px;margin:15px auto;width:30%;'
            placeholder='Filter Certs'
            (keyup)='updateFilter($event)'
          />
        </div>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable class="material bg-white" #table
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows"
                         [selected]="selected"
                         [selectionType]="'row'">
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false">
              <ng-template  let-row='row' ngx-datatable-cell-template>
                <img src="{{row?.certificate_image}}" alt="" width="125" height="100">
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop='name'></ngx-datatable-column>
            <ngx-datatable-column name="Description" prop='desc'></ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false">
              <ng-template let-row='row' ngx-datatable-cell-template>
                <mat-icon></mat-icon>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="Active">
              <ng-template  let-row='row' ngx-datatable-cell-template>
                <mat-slide-toggle
                  class="example-margin"
                  [color]="'#0087ce'"
                  [checked]="row.isActive"
                  (change)="changeActive(row)">
                </mat-slide-toggle>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false">
              <ng-template  let-row='row' ngx-datatable-cell-template>
                <mat-icon class='edit-icon' (click)='editCertificate(row)'>edit</mat-icon>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false">
              <ng-template  let-row='row' ngx-datatable-cell-template>
                <mat-icon class='delete-icon' (click)='checkAssociations(row)'>delete</mat-icon>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
