import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {UserCourse} from '@app/models/course/user-course';
import {Stage} from '@app/models/course/workbook/stage/stage';
import {ShortTextPipe} from '@app/pipes/short-text.pipe';

@Component({
  selector: 'app-select-stage-dialog',
  templateUrl: './select-stage-dialog.component.html',
  styleUrls: ['./select-stage-dialog.component.scss']
})
export class SelectStageDialogComponent implements OnInit {
  selectedCourse: UserCourse;
  stages: Stage[] = [];
  shortPipe = new ShortTextPipe();
  selectedStage: Stage;
  selected: any[] = [];
  columns = [{name: 'Name', prop: 'stage_version.title'}, {name: 'Description', prop: 'stage_version.overview', pipe: this.shortPipe}];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<SelectStageDialogComponent>) { }

  ngOnInit() {
    if (this.data) {
      this.selectedCourse = this.data;
      if (this.data.course_version && this.data.course_version.workbook) {
        this.stages = this.data.course_version.workbook.stages;
      }
    }
  }

  onSelect(row) {
    this.selectedStage = row.selected[0];
  }

  save() {
    if (this.selectedStage) {
      this.dialogRef.close(this.selectedStage);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
