<div
  #course_detail
  class="course-wrapper course-detail-wrapper"
  id="export-wrapper"
  *ngIf="!loading"
  [ngStyle]="exporting && { height: '100%' }"
>
  <div style="float: right">
    <!--    <span  style="font-size: .75rem" *ngIf="user_course?.embark_job_id != null" id="embark_job_id">Embark Job ID: {{user_course?.embark_job_id}}</span>-->
    <!--    <button  mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">-->
    <!--      <mat-icon class="text-muted" data-html2canvas-ignore="true">more_vert</mat-icon>-->
    <!--    </button>-->
    <!--    <mat-menu #msgMenu="matMenu">-->
    <!--      <button mat-menu-item (click)='downloadPdf()'>Download Course Detail</button>-->
    <!--      <button *ngIf="((['admin', 'super_admin'].indexOf(getRole()) > -1) && overall < 100 && force_complete_config === true)" mat-menu-item (click)='forceCompleteCourse()'>Force Complete Course</button>-->
    <!--    </mat-menu>-->
  </div>
  <div class="continue-course-wrapper export-container">
    <div
      class="continue-course"
      fxLayout="wrap"
      fxLayout.xs="column"
      fxLayoutAlign="start center"
      [ngStyle]="exporting && { height: '100%' }"
    >
      <div style="height: 100px; width: 60px">
        <img
          class="badge_image"
          *ngIf="user_course?.course?.badge?.badge_image"
          #badge_image
          crossorigin="anonymous"
          [src]="user_course?.course?.badge?.badge_image"
          alt=""
        />
        <img
          class="badge_image"
          *ngIf="!user_course?.course?.badge?.badge_image"
          #default_badge
          [src]="'assets/images/default_badge.png'"
          alt=""
        />
      </div>
      <mat-list style="width: 85%">
        <mat-list-item class="split-row">
          <h2 class="course-title">{{ user_course?.course?.title }}</h2>
        </mat-list-item>
        <mat-list-item>
          <p class="up-next-course" *ngIf="user_course?.course?.created_by">
            By {{ user_course?.course?.created_by }}
          </p>
        </mat-list-item>
      </mat-list>
      <button
        mat-icon-button
        [matMenuTriggerFor]="msgMenu"
        class="hidden-on-open"
      >
        <mat-icon class="text-muted" data-html2canvas-ignore="true"
          >more_vert</mat-icon
        >
      </button>
      <mat-menu #msgMenu="matMenu">
        <button mat-menu-item (click)="downloadPdf()">
          Download Course Detail
        </button>
        <button mat-menu-item>Enrollment History</button>
        <button
          mat-menu-item
          (click)="downloadTranscript(user_course.course_id)"
        >
          Download Transcript
        </button>
        <button
          mat-menu-item
          (click)="downloadCertificate(user_course.course_id)"
          *ngIf="user_course.certificate_awarded"
        >
          Download Certificate
        </button>
        <button
          *ngIf="user_course?.embark_job_id == null"
          mat-menu-item
          (click)="setEmbarkID()"
        >
          Add Embark Job ID
        </button>
        <button
          *ngIf="
            ['admin', 'super_admin'].indexOf(getRole()) > -1 &&
            overall < 100 &&
            force_complete_config === 'true'
          "
          mat-menu-item
          (click)="forceCompleteCourse()"
        >
          Force Complete Course
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="export-container">
    <h3
      style="
        margin-top: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <span>{{ user_name }}</span>
      <div
        style="font-size: 0.75rem; display: flex; align-items: baseline"
        *ngIf="user_course?.embark_job_id != null"
        id="embark_job_id"
      >
        <span>Embark Job ID:&nbsp;</span>
        <app-edit-input
          [embarkJobID]="user_course.embark_job_id"
          (focusOut)="handleEmbarkEdit($event)"
        ></app-edit-input>
      </div>
    </h3>
  </div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="progress-bar-align export-container"
  >
    <!--    <h3 class="progress-label">{{ course_progress_record ? course_progress_record.status === 'in_progress' ? 'In Progress' : course_progress_record.status === 'completed' ? 'Completed' : 'Not Started' : 'Not Started'}}</h3>-->
    <h3 *ngIf="overall >= 100" class="progress-label">Completed</h3>
    <h3 *ngIf="overall > 0 && overall < 100" class="progress-label">
      In Progress
    </h3>
    <h3 *ngIf="overall <= 0" class="progress-label">Not Started</h3>
    <span class="percent-label">{{ overall }}%</span>
    <div class="progress-wrapper">
      <div id="myProgress">
        <div id="myBar" [ngStyle]="{ width: overall + '%' }"></div>
      </div>
    </div>
  </div>
  <div
    fxLayout="row wrap"
    fxFlex="100"
    fxLayoutAlign="space-between start"
    class="mr-20 export-container"
    [ngStyle]="exporting && { height: '100%' }"
  >
    <div
      fxFlex="16"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      class="progress-stage"
    >
      <p class="bold-txt">Enrollment Status</p>
      <p></p>
      <p>Start:</p>
      <p *ngIf="start_date">{{ start_date | date }}</p>
      <p *ngIf="!start_date">Not Started</p>
      <p>Completion:</p>
      <p *ngIf="overall.toString() !== '100'">Not Completed</p>
      <p *ngIf="overall.toString() === '100'">{{ completed_at | date }}</p>
    </div>
    <div
      fxFlex="16"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      class="progress-stage"
    >
      <p class="bold-txt">Lesson Types</p>
      <p></p>
      <p class="max-120">
        <span>Self-Study</span>
        <span class="float-right"
          >{{ completed_self }}/{{ total_self_study }}</span
        >
      </p>
      <p class="max-120">
        <span>Ground</span>
        <span class="float-right"
          >{{ completed_ground }}/{{ total_ground }}</span
        >
      </p>
      <p class="max-120">
        <span>Flight</span>
        <span class="float-right"
          >{{ completed_flight }}/{{ total_flight }}</span
        >
      </p>
      <p class="max-120">
        <span class="bold-txt">Total</span>
        <span class="float-right bold-txt"
          >{{ completed_self + completed_ground + completed_flight }}/{{
            total_lessons
          }}</span
        >
      </p>
    </div>
    <div
      fxFlex="16"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      class="progress-stage"
    >
      <p class="bold-txt">Assessments</p>
      <p></p>
      <p class="max-120">
        <span>Flight</span>
        <span class="float-right"
          >{{ completed_flight_assessments }}/{{
            total_flight_assessments
          }}</span
        >
      </p>
      <p class="max-120">
        <span>Ground</span>
        <span class="float-right"
          >{{ completed_ground_assessments }}/{{
            total_ground_assessments
          }}</span
        >
      </p>
      <p></p>
      <p></p>
      <p class="max-120 bold-txt" style="padding-top: 5px !important">
        <span>Total</span>
        <span class="float-right bold-txt"
          >{{ completed_ground_assessments + completed_flight_assessments }}/{{
            total_ground_assessments + total_flight_assessments
          }}</span
        >
      </p>
    </div>
    <div
      fxFlex="16"
      fxLayout="column wrap"
      fxLayoutAlign="start"
      class="progress-stage"
    >
      <p class="max-120">
        <span class="bold-txt">Logbook</span>
        <span class="float-right bold-txt">Hrs</span>
      </p>
      <p></p>
      <p class="max-120">
        <span>Flight</span>
        <span class="float-right">{{ total_flight_hours | number }}</span>
      </p>
      <p class="max-120">
        <span>Ground</span>
        <span class="float-right">{{ total_ground_hours | number }}</span>
      </p>
      <p class="max-120">
        <span>SIM</span>
        <span class="float-right">{{ total_sim_hours | number }}</span>
      </p>
      <p class="max-120">
        <span class="bold-txt">Total</span>
        <span class="float-right bold-txt">{{
          total_flight_hours + total_ground_hours + total_sim_hours | number
        }}</span>
      </p>
    </div>
  </div>
  <div
    fxLayout="wrap"
    *ngFor="
      let stage of user_course.workbook.stages | orderBy : 'stage.order';
      let i = index
    "
    class="width-controller export-container"
    [ngStyle]="exporting && { height: '100%' }"
  >
    <span
      style="
        margin-left: 15px;
        color: #0088ce;
        font-weight: bold;
        margin-bottom: 10px;
        margin-top: 10px;
      "
      >{{ stage?.title }}</span
    >
    <span style="margin-right: -40px">
      <button
        mat-icon-button
        [matMenuTriggerFor]="stageMenu"
        class="hidden-on-open"
        *ngIf="
          ['admin', 'super_admin'].indexOf(getRole()) > -1 &&
          !checkIfComplete(stage, 'stage') &&
          force_complete_config === 'true'
        "
      >
        <mat-icon class="text-muted" data-html2canvas-ignore="true"
          >more_vert</mat-icon
        >
      </button>
      <mat-menu #stageMenu="matMenu">
        <!--            <button mat-menu-item (click)='forceCompleteStage(stage.id)'>Force Complete Stage</button>-->
      </mat-menu>
    </span>
    <div
      [id]="'stage' + stage?.id + 'lesson' + lesson?.id"
      fxLayout="column"
      fxFlex="100"
      *ngFor="
        let lesson of stage?.lessons | orderBy : 'lesson.order';
        let j = index
      "
    >
      <!--      <div class="stage-wrapper">-->
      <!--        <span [id]='lesson.name' style="width: 60%;">{{j + 1}}. {{lesson?.lesson_version?.title}}</span>-->
      <div
        class="stage-wrapper"
        style="
          display: flex;
          background-color: #00273c;
          padding: 15px 5px;
          color: white;
          min-height: 50px;
        "
      >
        <span [id]="lesson.name" style="width: 60%">{{ lesson?.title }}</span>
        <span style="width: 30%">{{
          lesson?.lesson_type === 0
            ? 'Self-Study'
            : lesson?.lesson_type === 1
            ? 'Ground Lesson'
            : 'Flight Lesson'
        }}</span>
        <span class="complete-items" style="width: 10%">{{
          checkIfForceComplete(lesson, 'lesson')
            ? 'Force Completed'
            : checkIfComplete(lesson, 'lesson')
            ? 'Completed'
            : checkIfInProgress(lesson, 'lesson')
            ? 'In Progress'
            : 'Not Started'
        }}</span>
        <span style="margin-right: -40px">
          <button
            mat-icon-button
            [matMenuTriggerFor]="lessonMenu"
            class="hidden-on-open"
            *ngIf="
              ['admin', 'super_admin'].indexOf(getRole()) > -1 &&
              !checkIfComplete(lesson, 'lesson') &&
              force_complete_config === 'true'
            "
          >
            <mat-icon class="text-muted" data-html2canvas-ignore="true"
              >more_vert</mat-icon
            >
          </button>
          <mat-menu #lessonMenu="matMenu">
            <!--            <button mat-menu-item (click)='forceCompleteLesson(lesson.id)'>Force Complete Lesson</button>-->
          </mat-menu>
        </span>
      </div>
      <div fxFlex="100" class="lesson-spacing">
        <mat-list fxFlex="100">
          <div *ngFor="let content of lesson?.contents; let z = index">
            <mat-list-item class="workbook-content-padding">
              <span style="width: 60%">
                {{ content?.title }}
              </span>
              <span style="width: 30%">
                {{
                  [0, 1].includes(content?.content_type)
                    ? 'Video'
                    : [7, 2, 3].includes(content?.content_type)
                    ? 'Interactive'
                    : [6, 11, 4].includes(content?.content_type)
                    ? 'Document'
                    : content?.content_type === 8
                    ? 'Rich Text'
                    : contentTypeMap[content?.content_type]
                }}
              </span>
              <span class="complete-items" style="width: 10%">{{
                isForceCompleted(user_course, stage, lesson, content)
                  ? 'Force Completed'
                  : isCompleted(user_course, stage, lesson, content)
                  ? 'Completed'
                  : checkIfInProgress(content, 'content')
                  ? 'In Progress'
                  : 'Not Started'
              }}</span>
              <span style="margin-right: -40px">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="contentMenu"
                  class="hidden-on-open"
                  *ngIf="
                    ['admin', 'super_admin'].indexOf(getRole()) > -1 &&
                    !isCompleted(user_course, stage, lesson, content) &&
                    force_complete_config == 'true'
                  "
                >
                  <mat-icon class="text-muted" data-html2canvas-ignore="true"
                    >more_vert</mat-icon
                  >
                </button>
                <mat-menu #contentMenu="matMenu">
                  <!--                <button mat-menu-item (click)='forceCompleteContent(user_course, stage, lesson, content)'>Force Complete Content</button>-->
                </mat-menu>
              </span>
            </mat-list-item>
            <ng-container *ngIf="[9, 10].includes(content.content_type)">
              <div class="full-width">
                <div fxLayout="wrap">
                  <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                    <table class="">
                      <thead>
                        <tr>
                          <th class="thead-left">Tasks</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th *ngIf="content.jet_scoring"></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Task</td>
                          <td>Type</td>
                          <td>Required</td>
                          <td>Passed</td>
                          <td>Missed</td>
                          <td *ngIf="content.jet_scoring">Perfect</td>
                          <td>Status</td>
                        </tr>
                        <tr *ngFor="let content_task of content.tasks">
                          <td class="row-stage-name">
                            {{ content_task?.name }}
                          </td>
                          <td>
                            {{
                              content_task.task_type &&
                              content_task.task_type.toString() === 'practice'
                                ? 'Practice'
                                : 'Perform'
                            }}
                          </td>
                          <td class="center-data">
                            {{ content_task?.required_successful_attempts }}
                          </td>
                          <td class="center-data">
                            {{ getPassed(content_task.id, content.id) }}
                          </td>
                          <td class="center-data">
                            {{ getMissed(content_task.id, content.id) }}
                          </td>
                          <td class="center-data" *ngIf="content.jet_scoring">
                            {{ getPerfect(content_task.id, content.id) }}
                          </td>
                          <td
                            *ngIf="
                              content_task.task_type &&
                              content_task.task_type.toString() === 'perform'
                            "
                          >
                            {{
                              getDeferred(
                                content_task.id,
                                content.id,
                                lesson.id,
                                content_task.task_type
                              )
                                ? 'Deferred'
                                : getPassed(content_task.id, content.id) >=
                                  content_task.required_successful_attempts
                                ? 'Completed'
                                : getPassed(content_task.id, content.id) > 0
                                ? 'In Progress'
                                : 'Not Started'
                            }}
                          </td>
                          <td
                            *ngIf="
                              content_task.task_type &&
                              content_task.task_type.toString() === 'practice'
                            "
                          >
                            {{
                              getDeferred(
                                content_task.id,
                                content.id,
                                lesson.id,
                                content_task.task_type
                              )
                                ? 'Deferred'
                                : getPassed(content_task.id, content.id) > 0 ||
                                  getMissed(content_task.id, content.id) > 0 ||
                                  (content.jet_scoring &&
                                    getPerfect(content_task.id, content.id))
                                ? 'Completed'
                                : 'Not Started'
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <ng-container *ngFor="let lessonFlightLog of lessonFlightLogs">
                <ng-container *ngIf="lesson.id === lessonFlightLog.lesson_id">
                  <div fxLayout="wrap" class="pt-1 pb-1">
                    <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100">
                      <table class="">
                        <thead>
                          <tr>
                            <th class="thead-left">Logbook</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>LOG ID</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-thead-right">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.id }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>DATE</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.lesson_flight_log_date | date
                                }}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>MAKE</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.aircraft_make }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>MODEL</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.aircraft_model }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>ID</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.aircraft_tail_id }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>TO</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.toAirport }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>FROM</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{ lessonFlightLog?.fromAirport }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>TOTAL FLT</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.hobbsIn -
                                    lessonFlightLog?.hobbsOut | number : '0.1'
                                }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>TOTAL SIM</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.completedSimHours | number
                                }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>TOTAL GND</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.completedGroundInstructionHours
                                    | number
                                }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                          <tr>
                            <td>INSTRUCTOR</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="logbook-instructor-name logbook-data">
                              <p class="margin-bottom-none">
                                {{
                                  lessonFlightLog?.instructor_object?.user
                                    .contact.name
                                }}
                              </p>
                            </td>
                          </tr>
                          <mat-divider class="table-divider"></mat-divider>
                        </tbody>
                      </table>
                      <div
                        class="full-width"
                        *ngIf="lessonFlightLog?.comment_body"
                      >
                        <p class="section-label remarks-title">REMARKS</p>
                      </div>
                      <p
                        class="remarks-body"
                        *ngIf="lessonFlightLog?.comment_body"
                      >
                        {{ lessonFlightLog?.comment_body }}
                      </p>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </mat-list>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="loading && !course_id">
  <app-loading-spinner></app-loading-spinner>
</ng-container>
<div #image_container>
  <img
    #image
    style="visibility: hidden"
    [src]="url"
    alt=""
    crossOrigin="Anonymous"
  />
</div>

<ng-container *ngIf="export_transcript">
  <app-transcript-course-detail
    [course_id]="course_id"
    [user_id]="user_id"
    (done)="export()"
  ></app-transcript-course-detail>
</ng-container>
