import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {
  RoutePartsService,
  IRoutePart,
} from '../../../services/route-parts.service';

@Component({
  selector: 'app-slideshow',
  templateUrl: './slideshow.component.html',
  styleUrls: ['./slideshow.component.scss'],
})
export class SlideshowComponent implements OnInit {
  routeParts: IRoutePart[];
  slideIndex = 1;
  isMobile = false;

  constructor(
    private router: Router,
    private routePartsService: RoutePartsService,
    private activeRoute: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.routeParts = this.routePartsService.generateRouteParts(
          this.activeRoute.snapshot
        );
        this.routeParts.reverse().map(item => {
          item.breadcrumb = this.parseText(item);
          return item;
        });
      });
  }

  ngOnInit() {
    //this.showSlides(this.slideIndex);
    if (localStorage.getItem('isMobile') !== null) {
      this.isMobile = true;
    }
  }

  parseText(part) {
    part.breadcrumb = part.breadcrumb.replace(/{{([^{}]*)}}/g, function (a, b) {
      const r = part.params[b];
      return typeof r === 'string' ? r : a;
    });
    return part.breadcrumb;
  }

  plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  showSlides(n) {
    // const slides: any = document.getElementsByClassName('mySlides');
    // const dots: any = document.getElementsByClassName('dot');
    // if (n > slides.length) { this.slideIndex = 1; }
    // if (n < 1) {this.slideIndex = slides.length; }
    // for (let i = 0; i < slides.length; i++) {
    //   slides[i].style.display = 'none';
    // }
    // for (let i = 0; i < dots.length; i++) {
    //   dots[i].className = dots[i].className.replace('active', '');
    // }
    // slides[this.slideIndex - 1].style.display = 'block';
    // dots[this.slideIndex - 1].className += ' active';
  }
}
