import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {LessonFlightLogService} from '@app/services/data-services/lesson-flight-log.service';
import {TaskService} from '@app/services/data-services/task.service';
import {ActivatedRoute} from '@angular/router';
import {InstructorService} from '@app/services/data-services/instructor.service';
import {UserService} from '@app/services/data-services/user.service';
import {Store} from '@ngrx/store';
import * as fromApp from '../../../../../store/app.reducers';
import * as AssessmentActions from './store/assessment.actions';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-student-assessments-layout',
  templateUrl: './student-assessments-layout.component.html',
  styleUrls: ['./student-assessments-layout.component.scss']
})
export class StudentAssessmentsLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  sub: Subscription = new Subscription();
  user_id: number;
  course_id: number;
  fetchedStudent = false;
  fetchedAssessments = false;
  fetchedCourse = false;

  constructor(private flightLogService: LessonFlightLogService,
              private taskService: TaskService,
              private store: Store<fromApp.AppState>,
              private activatedRoute: ActivatedRoute,
              private lessonFlightLogService: LessonFlightLogService,
              private instructorService: InstructorService,
              private userService: UserService) { }

  ngOnInit() {
    // this.activatedRoute.params.subscribe(params => {
    //   this.sub.add(this.store.select('assessment').subscribe(state => {
    //     if (params['user_id'] && params['user_id'] !== 'undefined' && params['course_id'] && params['course_id'] !== 'undefined') {
    //       if (!state.selectedStudent && !this.fetchedStudent) {
    //         this.fetchedStudent = true;
    //         this.getStudent(params);
    //       } else if (+params['user_id'] !== state.selectedStudent.id) {
    //         this.fetchedStudent = true;
    //         this.getStudent(params);
    //       }
    //
    //       if (!state.selectedCourse && !this.fetchedCourse) {
    //         this.fetchedCourse = true;
    //         this.getSelectedCourse(params);
    //       } else if (state.selectedCourse && +params['course_id'] !== state.selectedCourse.course_id) {
    //         this.fetchedAssessments = true;
    //         this.getSelectedCourse(params);
    //         this.getStudentAssessmentForCourse(params);
    //       }
    //
    //       if (!state.assessments && !this.fetchedAssessments) {
    //         this.fetchedAssessments = true;
    //         this.getStudentAssessmentForCourse(params);
    //       }
    //     }
    //   }));
    //
    //   if (+params['user_id'] && +params['course_id'] && (+params['user_id'] !== this.user_id || +params['course_id'] !== this.course_id)) {
    //     this.user_id = params['user_id'];
    //     this.course_id = params['course_id'];
    //     this.store.dispatch(new AssessmentActions.FetchAssessmentLogbooks(params));
    //     this.store.dispatch(new AssessmentActions.FetchAssessmentEntries(params));
    //     this.store.dispatch(new AssessmentActions.FetchAssessments(params));
    //     this.store.dispatch(new AssessmentActions.FetchDeferments(params));
    //     this.store.dispatch(new AssessmentActions.FetchDeferReasons());
    //     this.store.dispatch(new AssessmentActions.FetchAssessmentProgress(params));
    //   }
    // });
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
