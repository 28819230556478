import { Component, OnInit } from '@angular/core';
import {LessonFlightLog} from '@app/models/course/workbook/lesson/lesson-flight-log';
import {LessonFlightLogService} from '@app/services/data-services/lesson-flight-log.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-student-assessments-view',
  templateUrl: './student-assessments-view.component.html',
  styleUrls: ['./student-assessments-view.component.scss']
})
export class StudentAssessmentsViewComponent implements OnInit {
  data: LessonFlightLog[] = [];
  sub: Subscription = new Subscription();

  constructor(private lessonFlightLogService: LessonFlightLogService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['contents_lesson_version_id']) {
        this.activatedRoute.parent.params.subscribe(parent_params => {
          if (parent_params['id']) {
            this.lessonFlightLogService.getStudentFlightLogs(parent_params['id'], params['contents_lesson_version_id'])
              .then(lesson_flight_logs => {
                this.data = lesson_flight_logs;
              });
          }
        });
      }
    });
  }
}
