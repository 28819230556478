import { Component, OnInit } from '@angular/core';
import { IMenuItem } from '@app/services/navigation/navigation.service';

@Component({
  selector: 'app-instructor-dashboard',
  templateUrl: './instructor-dashboard.component.html',
  styleUrls: ['./instructor-dashboard.component.scss']
})
export class InstructorDashboardComponent implements OnInit {
  instructorMenu: IMenuItem[] = [
    {
      name: 'STUDENTS',
      type: 'menuItem',
      tooltip: 'Students',
      icon: 'class',
      state: '/instructor/classroom/students'
    }, {
      name: 'BOOKSHELF',
      type: 'link',
      tooltip: 'Bookshelf',
      icon: 'library_books',
      state: '/instructor/bookshelf'
    }
  ];

  constructor() { }

  ngOnInit() {
  }
}
