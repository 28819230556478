<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Course Categories</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class='full-width pb-1'>
          <button mat-raised-button [color]="'primary'" (click)="navigate('admin/courses/category/course/add')"> Add Category </button>
          <input
            type='text'
            style='padding:8px;margin:15px auto;width:30%;'
            placeholder='Filter Course Categories'
            (keyup)='updateFilter($event)'
          />
        </div>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable #table class="material bg-white"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows"
                         [selectAllRowsOnPage]="false"
                         [selectionType]='false'>
            <ngx-datatable-column name="Name"></ngx-datatable-column>
            <ngx-datatable-column name="Desc"></ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false">
              <ng-template  let-row='row' ngx-datatable-cell-template>
                <mat-icon class='edit-icon' (click)='editCategory(row)'>edit</mat-icon>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false">
              <ng-template  let-row='row' ngx-datatable-cell-template>
                <mat-icon class='delete-icon' (click)='removeCategory(row)'>delete</mat-icon>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
