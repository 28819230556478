<div class="modal-container">
  <div *ngIf="isLoading" class="loading-spinner-container">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="content-title-wrapper">
    <h1 mat-dialog-title>{{ dialogTitle }}</h1>
    <div class="close-icon-container">
      <button mat-icon-button mat-dialog-close (click)="close()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-dialog-content class="form-padding">
    <div *ngIf="!hasSidebar" class="full-width" style="padding: 1rem">
      <input
        *ngIf="!isLoading"
        type="text"
        [(ngModel)]="searchVal"
        style="padding: 8px; width: 33%; box-sizing: border-box"
        placeholder="Search"
        (keyup)="applySearchFilter($event)"
      />
    </div>
    <div
      class="{{
        rowsCount > 0 ? 'mat-box-shadow ' : ''
      }}margin-333 dialog-content-wrapper"
      style="margin-top: 0 !important"
    >
      <div *ngIf="hasSidebar" class="items-filter" [fxFlex]="36">
        <div class="items-filter-header">
          <span>Filter Options</span>
        </div>
        <div class="input-container">
          <input
            *ngIf="!isLoading"
            type="text"
            placeholder="Search"
            [(ngModel)]="searchVal"
            (keyup)="applySearchFilter($event)"
          />
        </div>
        <ul *ngIf="!isLoading">
          <li *ngFor="let type of filterGroups | keys; let index = index">
            <mat-checkbox
              class="filter-category"
              [value]="type.key"
              [(ngModel)]="checkboxStates[type.key]"
              (change)="applyCheckboxFilter($event)"
              >{{ typeMap[type.key] }} ({{ type.value.length }})</mat-checkbox
            >
          </li>
          <li class="select-all" (click)="clearFilters()">
            <span>Clear All</span>
          </li>
        </ul>
      </div>

      <div [fxFlex]="hasSidebar ? 60 : 100" class="table-container">
        <mat-dialog-content class="bottom-border">
          <table
            mat-table
            matSort
            [dataSource]="dataSource"
            class="mat-elevation-z0"
          >
            <div *ngFor="let column of columns">
              <div *ngIf="column.mat_col_name == 'select'; else otherCol">
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef class="select-column">
                    <div class="arrow-icons-container">
                      <mat-icon>unfold_more</mat-icon>
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox
                      (click)="$event.stopPropagation()"
                      (change)="$event ? toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                    ></mat-checkbox>
                  </td>
                </ng-container>
              </div>
              <ng-template #otherCol>
                <ng-container matColumnDef="{{ column.mat_col_name }}">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ column.name }}
                    <div class="arrow-icons-container">
                      <mat-icon>unfold_more</mat-icon>
                    </div>
                  </th>
                  <div *ngIf="!column.nested_name; else nested">
                    <td class="column" mat-cell *matCellDef="let row">
                      {{
                        column.type_map
                          ? typeMap[row[column.mat_col_name]]
                          : (row[column.mat_col_name] | truncate : 25)
                      }}
                    </td>
                  </div>
                  <ng-template #nested>
                    <td class="column" mat-cell *matCellDef="let row">
                      {{
                        row[column.mat_col_name] &&
                        row[column.mat_col_name][column.nested_name]
                          ? row[column.mat_col_name][column.nested_name]
                          : ''
                      }}
                    </td>
                  </ng-template>
                </ng-container>
              </ng-template>
            </div>

            <tr
              mat-header-row
              *matHeaderRowDef="dataColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: dataColumns"></tr>
            <tr *matNoDataRow>
              No Soup For You!
            </tr>
          </table>
        </mat-dialog-content>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content
    [ngClass]="!hasSidebar ? 'no-sidenav' : 'custom-border-row'"
  >
    <div [fxFlex]="36.2" [ngClass]="!hasSidebar ? 'border-top' : null"></div>
    <div [fxFlex]="59.2" class="border-top"></div>
  </mat-dialog-content>

  <div class="selected-text" *ngIf="data.selectionType === 'multiClick'">
    {{ selectedCount() }} Selected /
    {{
      dataSource.filteredData.length
        ? dataSource.filteredData.length
        : data.rows.length
    }}
    Total
  </div>
  <mat-dialog-actions>
    <div class="action-wrapper">
      <!-- <button mat-raised-button [color]="'primary'" type="submit" (click)="save()">{{ dialogConfirmButtonLabel }}</button> -->
    </div>
    <div class="action-wrapper">
      <button
        *ngIf="!customConfirmButton"
        [mat-dialog-close]="selection.selected"
        class="action-btn"
        color="primary"
        type="submit"
        [disabled]="selection.selected.length === 0"
      >
        Add {{ rowsCount == 0 ? '' : rowsCount + ' ' }}Item(s)
      </button>
      <button
        *ngIf="customConfirmButton"
        class="action-btn"
        color="primary"
        type="submit"
        [mat-dialog-close]="selection.selected"
        [disabled]="selection.selected.length === 0"
      >
        {{ dialogConfirmButtonLabel }}
      </button>
      <button
        *ngIf="showCancelButton"
        class="action-btn"
        [color]="'danger'"
        (click)="close()"
      >
        {{ dialogCancelButtonLabel }}
      </button>
    </div>
  </mat-dialog-actions>

  <ng-template #badgeTmpl let-row="row">
    <img
      src="{{
        row?.badge_image ? row?.badge_image : 'assets/images/default_badge.png'
      }}"
      width="125"
      height="100"
      alt=""
    />
  </ng-template>
  <ng-template #certTmpl let-row="row">
    <img
      src="{{
        row?.certificate_image
          ? row?.certificate_image
          : 'assets/images/certificate-thumbnail.jpg'
      }}"
      width="125"
      height="100"
      alt=""
    />
  </ng-template>
</div>
