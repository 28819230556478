import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CourseAttemptIndexRow, CourseAttemptService } from "@app/services/data-services/course-attempt.service";
import { UserCourse } from "@app/models/course/user-course";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "@app/services/data-services/user.service";
import { Course } from "@app/models/course/course";
import { from ,  forkJoin ,  of } from "rxjs";
import { concatMap } from "rxjs/operators";

@Component({
  selector: 'app-enrollment-history',
  templateUrl: './enrollment-history.component.html',
  styleUrls: ['./enrollment-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EnrollmentHistoryComponent implements OnInit {
  isLoading = false;
  role?: string;
  @Input('userCourse') userCourse?: UserCourse;
  courseAttempts: CourseAttemptIndexRow[];
  course: Course;
  userName: string;

  constructor(
    public dialog: MatDialog,
    private courseAttemptService: CourseAttemptService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.role = localStorage.getItem('cirrus-role');

    const getCourseAttempts = (userCourse) => this.courseAttemptService.getCourseAttemptsForUserCourse(userCourse);
    const getUserName = ({ user_id }: UserCourse) => this.userService.getUserName(user_id);

    this.activatedRoute.params
      .subscribe((params) => {
        if (!this.userCourse) {
          this.userCourse = params;
        }

        getCourseAttempts(params)
          .subscribe(({ course, course_attempts: courseAttempts}) => {
            this.course = course;
            this.courseAttempts = courseAttempts;
            this.isLoading = false;
          });

        from(getUserName(params))
          .subscribe((userName) => {
            this.userName = userName;
          });
      });
  }
}
