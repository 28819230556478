import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { Instructor } from '@app/models/instructor';

@Injectable()
export class ClassroomService {
  classroomUrl = 'instructors/classrooms';

  constructor(private http: HttpService) {}

  getClassrooms(): Promise<Instructor> {
    return this.http.get<Instructor>(this.classroomUrl)
      .toPromise()
      .catch(ClassroomService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
