<div class="agreement-wrapper">
  <div *ngIf="buttonDisabled$ | async" class="spinner-container">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="agreement-title-wrapper">
    <div mat-dialog-title id="mat-title-1">Terms & Conditions</div>
    <div mat-dialog-title id="mat-title-2">Please read the following, click 'I Agree' to continue:</div>
    <!--<hr>-->
  </div>
  <mat-dialog-content>
      <div class="agreement-body-wrapper" [innerHtml]="agreement_body"></div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="actions-wrapper">
      <button  mat-button (click)="agree()" [disabled]="buttonDisabled$ | async" class="dialog-action-button agree">I Agree</button>
      <button (click)="close()" class="dialog-action-button disagree">Disagree</button>
    </div>
  </mat-dialog-actions>
</div>
