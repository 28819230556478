import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalEventManagerService } from '../../../../services/global-event-manager/global-event-manager.service';
import { TempDataServiceService } from '../../../../services/data-services/temp-data-service.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InstructorService } from '@app/services/data-services/instructor.service';
import { Instructor } from '@app/models/instructor';
import { User } from '@app/models/user/user';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@app/components/shared/snackbar/snackbar.component';
import { BadgesDialogComponent } from '../../badges-certs/badges/badges-dialog/badges-dialog.component';
import { InstructorInfoDialogComponent } from './instructor-info-dialog/instructor-info-dialog.component';
import { NotificationService } from '@app/services/data-services/notification.service';
import { Notification } from '@app/models/notification/notification';
import { GeneralDialogComponent } from '@app/components/shared/general-dialog/general-dialog.component';

@Component({
  selector: 'app-course-instructors',
  templateUrl: './course-instructors.component.html',
  styleUrls: ['./course-instructors.component.scss'],
})
export class CourseInstructorsComponent implements OnInit {
  filteredInstructors: Instructor[] = [];
  pendingInstructors: Notification[] = [];
  temp: Instructor[] = [];
  value: string;
  myInstructors: Instructor[] = [];
  open = false;
  isMobile = false;
  // columns: any[];
  instructors: any;
  filteredCourses: any;
  currentMax = 8;
  selectedFilter = 'Name';
  @ViewChild('detail') detailContainer;

  buttons: any = [
    {
      name: 'Name',
      id: 1,
      value: 'Name',
    },
    {
      name: 'Email',
      id: 2,
    },
    {
      name: 'Training Center',
      id: 3,
    },
    {
      name: 'Airport',
      id: 4,
    },
  ];

  constructor(
    private globalEventManagerService: GlobalEventManagerService,
    private instructorService: InstructorService,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.isMobile = window.matchMedia(`(max-width: 960px)`).matches;
  }

  ngOnInit() {
    this.instructorService.getInstructors().then((instructors) => {
      this.filteredInstructors = this.temp = instructors;
      this.temp = this.temp.filter(function (instructor) {
        return instructor.user.role.toString() == 'instructor';
      });
    });

    this.notificationService.getPendingNotifications().then((instructors) => {
      this.pendingInstructors = instructors;
    });

    this.instructorService
      .getCurrentUserInstructors()
      .then((myInstructors) => (this.myInstructors = myInstructors));
    setTimeout(() => {
      this.globalEventManagerService.showNavbar.emit(false);
    }, 0);
  }

  updateFilter() {
    let val = this.value;

    if (val) {
      val = val.toLowerCase();
      if (this.selectedFilter === 'Name') {
        this.filteredInstructors = this.temp.filter(function (d) {
          if (d.user && d.user.contact && d.user.contact.name) {
            return (
              d.user.contact.name.toLowerCase().indexOf(val) !== -1 || !val
            );
          }
        });
      } else if (this.selectedFilter === 'Email') {
        this.filteredInstructors = this.temp.filter(function (d) {
          if (d.user && d.user.email) {
            return d.user.email.toLowerCase().indexOf(val) !== -1 || !val;
          }
        });
      } else if (this.selectedFilter === 'Training Center') {
        this.filteredInstructors = this.temp.filter(function (d) {
          if (
            d.user &&
            d.user.contact &&
            d.user.contact.contact_user &&
            d.user.contact.contact_user.account &&
            d.user.contact.contact_user.account.name
          ) {
            return (
              d.user.contact.contact_user.account.name
                .toLowerCase()
                .indexOf(val) !== -1 || !val
            );
          }
        });
      } else if (this.selectedFilter === 'Airport') {
        this.filteredInstructors = this.temp.filter(function (d) {
          if (
            d.user &&
            d.user.contact &&
            d.user.contact.contact_user &&
            d.user.contact.contact_user.home_airport &&
            d.user.contact.contact_user.home_airport.name
          ) {
            return (
              d.user.contact.contact_user.home_airport.name
                .toLowerCase()
                .indexOf(val) !== -1 || !val
            );
          }
        });
      }
    } else {
      // update the rows
      this.filteredInstructors = this.temp;
    }
    this.showDetail();
  }

  showDetail() {
    if (this.detailContainer.nativeElement.classList.contains('collapsed')) {
      this.detailContainer.nativeElement.classList.remove('collapsed');
      this.detailContainer.nativeElement.classList.add('expanded');
    }
  }

  clear() {
    this.value = '';
    if (this.detailContainer.nativeElement.classList.contains('expanded')) {
      this.detailContainer.nativeElement.classList.remove('expanded');
      this.detailContainer.nativeElement.classList.add('collapsed');
    }
  }

  connectedInstructor(instructor) {
    if (instructor && instructor.id) {
      return this.myInstructors.map((i) => i.id).indexOf(instructor.id) > -1;
    }
    return false;
  }

  isPendingInstructor(instructor) {
    if (instructor && instructor.id) {
      return (
        this.pendingInstructors
          .map((i) => i.instructor.id)
          .indexOf(instructor.id) > -1
      );
    }
    return false;
  }

  removeInstructor(instructor) {
    this.showAlert(instructor);
  }

  showAlert(instructor) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      width: '80%',
      maxWidth: '500px',
      data: {
        dialogTitle: 'Confirm Instructor Removal',
        dialogBodyContent: 'Are you sure you want to remove the instructor?',
        showCancelButton: true,
        dialogCancelButtonLabel: 'Cancel',
        dialogConfirmButtonLabel: 'Confirm',
      },
    });

    dialogRef.afterClosed().subscribe((confirmRemove) => {
      if (confirmRemove === true) {
        this.instructorService
          .removeConnection(instructor, {
            id: parseInt(localStorage.getItem('cirrus-user-id')),
          })
          .then((res) => {
            this.snackBar.open('Updated');
            this.myInstructors = this.myInstructors.filter(
              (i) => i.id !== instructor.id
            );
          });
      }
    });
  }

  connectToInstructor(instructor) {
    this.instructorService
      .sendInstructorRequest(instructor)
      .then((res) => {
        this.snackBar.open('Invite sent');
      })
      .catch((res) => {
        if (res.indexOf('422') > -1) {
          this.snackBar.open('Invite already sent');
        }
      });
  }

  openDialog(content, isConnectedInstructor, isPendingInstructor) {
    const dialogRef = this.dialog.open(InstructorInfoDialogComponent, {
      width: '450px',
      height: '450px',
      data: {
        content,
        isConnectedInstructor,
        isPendingInstructor,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'remove') {
        this.removeInstructor(content);
      } else if (result) {
        this.pendingInstructors.push(result);
      }
    });
  }

  searchInstructors(e) {
    if (e.keyCode === 13) {
      this.updateFilter();
    }
  }
}
