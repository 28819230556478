import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BadgesService } from '@app/services/data-services/badges.service';
import {Badge} from '@app/models/course/course-badge';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-badges-manager-overview',
  templateUrl: './badges-manager-overview.component.html',
  styleUrls: ['./badges-manager-overview.component.scss']
})
export class BadgesManagerOverviewComponent implements OnInit {
  rows = [];
  temp = [];
  selected = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private router: Router, 
    private badgesService: BadgesService,
    public snackbar: MatSnackBar) {
    this.badgesService.getBadges()
      .then(badges => this.rows = this.temp = badges);
  }

  ngOnInit() {
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  editBadge(row) {
    this.router.navigate(['admin/badges/manage', row.id]);
  }

  removeBadge(row) {
      this.badgesService.delete(row.id)
        .then((res) => {
          this.snackbar.open('Badge Deleted')
          this.rows = this.rows.filter(item => item !== row)
        });
  }

  checkAssociations(row) {
    if (confirm('Are you sure you want to remove badge?')) {
      this.badgesService.checkAssociations(row)
        .then(res => res ? this.removeBadge(row) : alert('Due to associations this badge could not be deleted.  An alternative is to set as in active.'));
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  changeActive(row) {
    row.isActive = !row.isActive;
    this.updateBadge(row);
  }

  updateBadge(badge: Badge) {
    this.badgesService.update(badge)
      .then(res => res);
  }
}
