<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0 pb-1" fxFlex='grow'>
      <mat-card-title class="">
        <div class="card-title-text">Manage Content</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for='content-type' class='form-label'>Content Type</label>
            <div class="selection-wrapper">
              <select id="content-type" [(ngModel)]="content_model.content_type" name="content-type" class="form-select" >
                <option default value="-1">Select Type</option>
                <option [value]="types[type.key]" [selected]="type?.key?.toString() == types[content_model?.content_type]" *ngFor='let type of map | keys'>{{ type.value }}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <label for="content-name" class="form-label">Title</label>
            <input id="content-name" name="content-name" class="form-input" [(ngModel)]="content_model.title">
          </div>
          <div class="form-row">
            <label for="content-desc" class="form-label">Description</label>
            <textarea id="content-desc" name="content-desc" class="form-textarea" [(ngModel)]="content_model.desc"></textarea>
          </div>
          <div class="form-row">
            <label for="created-by" class="form-label">Created By</label>
            <input id="created-by" name="content-by" class="form-input" [(ngModel)]="content_model.created_by">
          </div>
          <div class="form-row">
            <label for="content-placeholder" class="form-label">Placeholder Image  <span class='message'>This image is used for library cards.</span></label>
            <div>
              <img id="content-img" #image src="#" width="325" height="175" alt="" hidden>
              <img *ngIf="content_model.placeholder_image && content_model.placeholder_image != '' && !imageUploader.queue.length" [src]="content_model.placeholder_image" width="325" height="175">
            </div>
            <input id="content-placeholder" appAppUploaderPreview [image]="image" type='file' name="content-placeholder" class="form-input">
          </div>
          <div class="form-row">
            <label for="meta-tags" class="form-label">Meta Tags <span class="meta-tag-info">Type new meta tags and separate by commas or hit enter to add tag</span></label>
            <mat-form-field class="meta-tags-input">
              <mat-chip-list #chipList>
                <mat-chip *ngFor="let tag of content_model?.meta_tags" [removable]="true" (remove)="remove(tag)">
                  {{tag}}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="false" (matChipInputTokenEnd)="add($event)" id="meta-tags" name="meta-tags" />
              </mat-chip-list>
            </mat-form-field>
          </div>

          <!--vimeo = 0,-->
          <!--youtube = 1,-->
          <!--scorm = 2,-->
          <!--tinCan = 3,-->
          <!--pdf = 4,-->
          <!--download = 5,-->
          <!--interactive = 6,-->
          <!--richText = 7-->
          <div class="form-row" *ngIf='[0,1].indexOf(types[content_model?.content_type]) > -1'>
            <mat-accordion id="help-video-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a YouTube or Vimeo Video</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>Follow these steps to upload a YouTube or Vimeo video:</p>
                      <small>Note: If you copy the video url directly from your browser, the upload process will not work.</small>
                      <p>Step 1: Find the video you would like to upload.</p>
                      <p>Step 2: Click "Share".</p>
                      <P>Step 3: Copy your video link.</P>
                      <p>Step 4: Paste your link into the input field below. </p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <label for="content-video" class="form-label">Video Link</label>
            <input id="content-video" type='text' name="form-control" placeholder="Video Embed Link" class="form-input" [(ngModel)]="content_model.url">
          </div>
          <div class="form-row" *ngIf='[2,3,4,5,6,7].indexOf(types[content_model?.content_type]) > -1'>
            <label for="scorm" class="form-label">Upload New File</label>
            <input class="pb-1" #scorm id="scorm" type='file' ng2FileSelect [uploader]="fileUploader" name="scorm" >
            <div fxLayout="row">
              <p class="pr-1">Current File: </p>
              <span *ngIf="content_model.content_file && content_model.content_file != '' && !fileUploader.queue.length">{{content_model.content_filename}}</span>
            </div>
          </div>
          <div class="form-row" *ngIf="content_model?.content_type == types.richText">
            <label for="content">Content</label>
            <mat-accordion id="help-video-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a YouTube or Vimeo Video</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>Follow these steps to upload a YouTube or Vimeo video:</p>
                      <p>Step 1: Find the video you would like to upload.</p>
                      <p>Step 2: For YouTube, click the share button. Next, click "Embed". For Vimeo, click "Share".</p>
                      <P>Step 3: Copy the all of the code that is generated for you. </P>
                      <p>Step 4: In the text editor below, click the very last button or "View Code".</p>
                      <p>Step 5: If you cannot see the text area, simply click the bottom border and drag down to expand it open.
                        Paste the code you copied from YouTube or Vimeo into the text area.</p>
                      <p>Step 6: Click the "View Code" button again to un-toggle.</p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion id="help-image-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload an Image</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>To upload an image, click inside the text editor before attempting to upload or the upload will fail and will
                        display the error: "Range out of the editor"</p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <app-wysiwyg-editor editorId='content' title="Bookshelf" placeholder="Enter text here..." [(text)]="content_model.content_html" ></app-wysiwyg-editor>
          </div>
        </form>
      </mat-card-content>
      <mat-spinner id="loading-spinner" *ngIf="is_loading"></mat-spinner>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="upload()">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
