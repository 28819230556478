import { Component, OnInit } from '@angular/core';
import { IMenuItem } from '@app/services/navigation/navigation.service';

@Component({
  selector: 'app-ctc-dashboard',
  templateUrl: './ctc-dashboard.component.html',
  styleUrls: ['./ctc-dashboard.component.scss']
})
export class CtcDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  ctcMenu: IMenuItem[] = [
    {
      name: 'STUDENTS',
      type: 'menuItem',
      tooltip: 'Students',
      icon: 'class',
      state: '/ctc/classroom/students'
    }, {
      name: 'INSTRUCTORS',
      type: 'menuItem',
      tooltip: 'Students',
      icon: 'library_books',
      state: '/ctc/instructors'
    },
  ];

}
