<div fxFlex='100' fxLayout='row' style="background: #EFEFEF;">
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow' style="margin: 0 10px;" #editor>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">{{ 'Edit' }} Configuration</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <mat-tab-group fxFlex="grow" class="p-05">
          <mat-tab label="Category/Course Order">
            <mat-card class="bg-white material">
              <mat-card-title>
                <div class="card-title-text">
                  Category & Course Order <button mat-raised-button [disabled]="!allowReset" [color]="'danger'" (click)="resetEdits()"><mat-icon>undo</mat-icon> Reset</button>
                </div>
                <mat-divider></mat-divider>
              </mat-card-title>
              <mat-card-content>
                <div class="wrapper" [dragula]="'category-bag'" [(dragulaModel)]="categories">
                  <div class="dnd-item category-item alert group-handle" *ngFor="let category of categories, let categoryIndex = index">
                    <div class="dnd-wrapper group-handle full-width" fxLayout="row">
                      <div class="dnd-details group-handle" fxLayout="column" fxFlex="94">
                        <div class="dnd-title-wrapper group-handle">{{categoryIndex + 1}}. {{ category.name }}</div>
                        <div class="courses-list" [dragula]="'course-bag-' + categoryIndex" [(dragulaModel)]="category.courses">
                          <mat-card class="course-item course-handle bg-white course-card" tabindex="0"*ngFor="let course of category.courses, let courseIndex = index">
                            <div fxLayout="column" fxFlexAlign="center" class="dnd-wrapper course-handle">
                              <img mat-card-image [src]="course.image ? course.image : 'assets/images/img3.jpeg'" class="course-handle" />
                              <mat-card-content class="dnd-details course-handle" fxLayout="column" fxFlex="95" fxFlexAlign="flex-start">
                                <div class="dnd-title-wrapper course-handle">{{courseIndex + 1}}. {{ course.title }}</div>
                              </mat-card-content>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                      <div class="drag-controller" fxFlex="3" fxFlexAlign="flex-start" fxLayout="row">
                        <button mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">
                          <mat-icon class="text-muted">more_vert</mat-icon>
                        </button>
                        <mat-menu #msgMenu="matMenu">
                          <button mat-menu-item (click)="category.showAll = !category.showAll"><mat-icon>{{ category.showAll ? 'check_box' : 'check_box_outline_blank' }}</mat-icon> Show All Courses</button>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" fxFlex="nogrow" style="margin: 0 10px;">
    <mat-card class="bg-white material">
      <mat-card-footer fxLayout="row">
        <button mat-raised-button [color]="'danger'" (click)="cancel()"> Cancel</button>
        <button mat-raised-button [color]="'primary'" (click)="saveConfiguration()" [disabled]="isSaving">{{ isSaving ? 'Saving...' : 'Save' }}</button>
      </mat-card-footer>
    </mat-card>
  </div>
</div>
