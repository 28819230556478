import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { GlobalEventManagerService } from '../../../services/global-event-manager/global-event-manager.service';
import { FormControl } from '@angular/forms';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { CourseService } from "@app/services/data-services/course.service";
import { CourseCategoryService } from "@app/services/data-services/course-category.service";
import { AvionicsService } from "@app/services/data-services/avionics.service";
import { Avionics } from "@app/models/aircraft/avionics";
import { Aircraft } from "@app/models/aircraft/aircraft";
import { AircraftService } from "@app/services/data-services/aircraft.service";
import { CourseCategory } from "@app/models/course/course-category";

@Component({
  selector: 'app-badges-certs',
  templateUrl: './badges-certs.component.html',
  styleUrls: ['./badges-certs.component.scss']
})
export class BadgesCertsComponent implements OnInit, OnDestroy {
  courseFilter: FormControl;
  usrCourses;

  aircraftFilter: FormControl;
  aircrafts: Aircraft[];

  avionicsFilter: FormControl;
  avionics: Avionics[];

  trainingFilter: FormControl;
  courseCategories: CourseCategory[];

  fltNms = ['courseFilter', 'aircraftFilter', 'avionicsFilter', 'trainingFilter'];
  onFilterChanged = new EventEmitter();
  wait;

  sub: any;
  tabItems: any;
  tabType = 'achieveTab';

  rows: any[] = [];

  constructor(private globalEventManagerService: GlobalEventManagerService,
              private navService: NavigationService,
              private courseService: CourseService,
              private aircraftService: AircraftService,
              private avionicService: AvionicsService,
              private categoryService: CourseCategoryService
              ) {
    this.navService.setMenu(this.tabType);
  }

  ngOnInit() {
    this.globalEventManagerService.topBar.emit(true);
    this.sub = this.navService.achieveTabs$.subscribe((menuItems) => {
      this.tabItems = [];
      this.tabItems = menuItems;
    });
    this.fltNms.forEach(nm => {
      this[nm] = new FormControl('all')
    })
    this.wait = Promise.all([
      this.courseService.getMyUserCourses().then(uc => this.usrCourses = uc),
      this.aircraftService.getAircraft().then(aircrafts => this.aircrafts = aircrafts),
      this.avionicService.getAvionics().then(avionics => this.avionics = avionics),
      this.categoryService.getCategories().then(categories => this.courseCategories = categories)
    ])
}

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public filterChanged() {
    this.onFilterChanged.emit([this.usrCourses,
      ...this.fltNms.map(e => this[e].value)])
  }

}
