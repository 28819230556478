<!--<div class="popout-wrapper">-->
<!--<div class="quizcontent">-->
<!--<div *ngFor="let question of data; let i = index">-->
<!--<h5>{{i}}. {{question.body}}</h5>-->
<!--<mat-radio-group>-->
<!--<mat-radio-button [value]="choice.id" *ngFor="let choice of question.quiz_answers" class="radio-button">-->
<!--{{choice.body}}-->
<!--</mat-radio-button>-->
<!--</mat-radio-group>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<div class="popout-wrapper">
    <div class="quizcontent">
        <div *ngIf="graded == false; else showResults">
            <div *ngFor="let question of data; let i = index">
                <h5>{{i + 1}}. <span [innerHtml]="question.body"></span></h5>
                <div *ngIf="question.question_type === 'single_choice'">
                    <ol type="A">
                        <li (click)="selectAnswer(i, j)" *ngFor="let choice of question.quiz_answers; let j = index">
                            <b *ngIf="chosenAnswers[i] == choice">{{choice.body}}</b>
                            <div *ngIf="chosenAnswers[i] != choice">{{choice.body}}</div>
                        </li>
                    </ol>
                </div>
                <div *ngIf="question.question_type === 'multiple_choice'">
                    <ol type="A">
                        <li (click)="selectMultipleChoiceAnswer(i, j)"
                            *ngFor="let choice of question.quiz_answers; let j = index">
                            <b *ngIf="isSelectedChoice(i, j)">{{choice.body}}</b>
                            <div *ngIf="!isSelectedChoice(i, j)">{{choice.body}}</div>
                        </li>
                    </ol>

                </div>
            </div>
            <button (click)="gradeQuiz()" mat-raised-button color="primary">Submit</button>

        </div>
        <ng-template #showResults>
            <div *ngFor="let question of data; let i = index">
                <h5>{{i + 1}}. {{question.body}}</h5>
                <ol type="A">
                    <div *ngIf="question.question_type === 'single_choice'">
                        <div *ngFor="let choice of question.quiz_answers; let j = index">
                            <div *ngIf="chosenAnswers[i] == choice">
                                <li class="answer-correct" *ngIf="choice.isAnswer == true">{{choice.body}} (Selected & Correct)</li>
                                <li class="answer-incorrect" *ngIf="choice.isAnswer == false">{{choice.body}} (Selected & Incorrect)</li>

                            </div>
                            <div *ngIf="chosenAnswers[i] != choice">
                                <li class="answer-correct" *ngIf="choice.isAnswer == true">{{choice.body}}</li>
                                <li *ngIf="choice.isAnswer == false">{{choice.body}}</li>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="question.question_type === 'multiple_choice'">
                        <div *ngFor="let choice of question.quiz_answers; let j = index">
                            <div *ngIf="isSelectedChoice(i, j)">
                                <li class="answer-correct" *ngIf="choice.isAnswer == true">{{choice.body}} (Selected & Correct)</li>
                                <li class="answer-incorrect" *ngIf="choice.isAnswer == false">{{choice.body}} (Selected & Incorrect)</li>
                            </div>

                            <div *ngIf="!isSelectedChoice(i, j)">
                                <li class="answer-not-selected" *ngIf="choice.isAnswer == true">{{choice.body}} (Correct Answer)</li>
                                <li *ngIf="choice.isAnswer == false">{{choice.body}}</li>
                            </div>
                        </div>
                    </div>
                </ol>
            </div>
        </ng-template>


    </div>


</div>
