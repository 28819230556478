<app-slideshow></app-slideshow>
<mat-drawer-container>
    <mat-drawer [ngClass]="{'side-nav-fixed': scrolling}" [mode]="showNav && !isNavOver() ? 'side' : 'over'"
                [opened]="showNav && !isNavOver()">
        <app-user-sidenav [hasNav]="true" [menuType]="menuType"></app-user-sidenav>
    </mat-drawer>

    <!--Click this to expand sidebar for small screens -->
    <div class='side-button-nav'></div>

    <mat-drawer-content>
        <div class="main-wrapper-nested">
            <div fxLayout="column">

                <div fxLayout="column" fxFlex="100" *ngFor="let entry of faqsSorted; let i = index">
                    <div fxLayout="column" fxFlex="100" class="description-wrapper">
                        <h5 [id]="entry.category.id" class='course-title'>{{entry.category.name}}</h5>

                        <mat-accordion id="help-page-accordion">
                            <mat-expansion-panel *ngFor="let section of entry.faqs;">
                                <mat-expansion-panel-header>
                                    <div class="full-width inline-items inline-wrapper">
                                        <h5 class="question-title">{{section.question}}</h5>
                                    </div>
                                </mat-expansion-panel-header>

                                <div class="full-width">
                                    <div class="full-width inline-items inline-wrapper test"
                                         [innerHtml]="section.body"></div>
                                    <!--<a (click)="openDialog(content)">-->
                                    <!--<p class='play-video-option'><i class="material-icons">play_circle_outline</i>-->
                                    <!--Watch Demonstration</p>-->
                                    <!--</a>-->
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
