<h2 class="text-body-header">My Courses</h2>

<mat-toolbar class="submenu">
  <mat-toolbar-row class="filterMenu course-wrapper maxwrapper">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="dropdown-width">
        <mat-select
          placeholder="Courses"
          (selectionChange)="filterChanged()"
          [formControl]="courseFilter"
        >
          <mat-option value="all">Show All</mat-option>
          <mat-option value="archived">{{ archiveFilterLabel }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="dropdown-width">
        <mat-select
          placeholder="Aircraft"
          (selectionChange)="filterChanged()"
          [formControl]="aircraftFilter"
        >
          <mat-option value="all">Show All</mat-option>
          <mat-option
            *ngFor="let aircraft of aircrafts"
            [value]="aircraft.id"
            >{{ aircraft.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="dropdown-width">
        <mat-select
          placeholder="Avionics"
          (selectionChange)="filterChanged()"
          [formControl]="avionicsFilter"
        >
          <mat-option value="all">Show All</mat-option>
          <mat-option *ngFor="let avionic of avionics" [value]="avionic.id">{{
            avionic.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="dropdown-width">
        <mat-select
          placeholder="Training"
          (selectionChange)="filterChanged()"
          [formControl]="trainingFilter"
        >
          <mat-option value="all">Show All</mat-option>
          <mat-option
            *ngFor="let category of courseCategories"
            [value]="category.id"
            >{{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex></div>
    <mat-form-field class="small-search-screen" id="searchContainer">
      <input
        id="search"
        class="my-courses-filter"
        type="text"
        placeholder="Search"
        matInput
        (keyup)="filterChanged()"
        [(ngModel)]="filter"
      />
    </mat-form-field>
  </mat-toolbar-row>
</mat-toolbar>

<mat-toolbar class="smallscreen-submenu">
  <mat-toolbar-row class="filterMenu">
    <mat-form-field class="small-search-screen" id="mobileSearchContainer">
      <input
        id="mobileSearch"
        type="text"
        placeholder="Search"
        matInput
        (keyup)="filterChanged()"
        [(ngModel)]="filter"
      />
    </mat-form-field>
  </mat-toolbar-row>
</mat-toolbar>

<!--contentarea-->
<div
  class="course-wrapper course-padding"
  kramkoob
  (codeEntered)="show($event)"
>
  <ng-container *ngIf="!isLoading">
    <div *ngIf="!isLoading && filteredCourses && filteredCourses.length == 0">
      <p style="text-align: center; font-size: 1.25rem">
        There are no courses to show.
      </p>
    </div>
    <!-- Unarchived Courses grid list template -->
    <mat-grid-list
      class="catalog-grid"
      [cols]="gridSize()"
      gutterSize="30"
      [rowHeight]="'5:6'"
    >
      <!--| filter:searchInput.value:'title':'archived':sortFilter:courseFilter | slice:0:currentMax-->
      <mat-grid-tile
        *ngFor="
          let row of filteredCourses | slice : 0 : currentMax;
          let i = index
        "
      >
        <!-- Purchased Course card template-->
        <!--<a (click)="selectCourse(course)">-->
        <mat-card class="my-courses-card" tabindex="0">
          <ng-container
            *ngIf="row?.thumbnail_image_url; else original_background_image"
          >
            <img
              class="pointer"
              (click)="selectCourse(row)"
              mat-card-image
              [src]="row.thumbnail_image_url"
            />
          </ng-container>
          <ng-template #original_background_image>
            <ng-container
              *ngIf="row?.thumbnail_image_url; else default"
            >
              <img
                class="pointer"
                (click)="selectCourse(row)"
                mat-card-image
                [src]="row.thumbnail_image_url"
              />
            </ng-container>
          </ng-template>
          <ng-template #default>
            <img
              class="pointer"
              (click)="selectCourse(row)"
              mat-card-image
              [src]="defaultCourseThumb"
            />
          </ng-template>

          <mat-card-content>
            <mat-icon
              [matMenuTriggerFor]="appMenu"
              class="card-menu-button"
              (click)="setCourseMenu(row)"
            >
              more_vert
            </mat-icon>
            <mat-menu #appMenu="matMenu">
              <button mat-menu-item (click)="selectCourse(row)">
                Overview
              </button>
              <button
                mat-menu-item
                *ngIf="user && user.role !== 'pilot'"
                (click)="selectCourse(row, '/instructors')"
              >
                Instructors
              </button>
              <button
                mat-menu-item
                (click)="
                  selectCourse(row, '/recent-activity/achievements')
                "
              >
                Certificate
              </button>
              <button
                *ngIf="
                  row.status == 'completed' &&
                  row.can_reenroll
                "
                mat-menu-item
                (click)="reenrollCourse(row)"
              >
                Re-Enroll
              </button>
              <mat-divider></mat-divider>
              <button
                mat-menu-item
                (click)="archiveCourse(row)"
                *ngIf="!row?.archived"
              >
                {{ archiveLabel }}
              </button>
              <button
                mat-menu-item
                (click)="archiveCourse(row)"
                *ngIf="row?.archived"
              >
                {{ unarchiveLabel }}
              </button>
            </mat-menu>
            <a (click)="selectCourse(row)">
              <mat-card-subtitle
                *ngIf="
                  row?.course_type?.toString().toUpperCase()
                "
                class="card-header-text"
                >{{
                  row?.course_type?.toString().toUpperCase()
                }}</mat-card-subtitle
              >
              <mat-card-title class="line-height">{{
                row.title
              }}</mat-card-title>
              <div class="description">
                <div class="progresspercentage" >
                  {{ row.percent_complete }}%
                </div>
                <section class="progress-section">
                  <mat-progress-bar
                    class="example-margin"
                    [color]="'primary'"
                    [mode]="'Determinate'"
                    [value]="row.percent_complete"
                    [bufferValue]="75"
                  >
                  </mat-progress-bar>
                </section>
              </div>
            </a>
          </mat-card-content>
        </mat-card>
        <!--</a>-->
      </mat-grid-tile>
    </mat-grid-list>

    <!-- Archived Courses Grid List Template -->
    <ng-template #archived>
      <mat-grid-list
        [cols]="gridSize()"
        gutterSize="25"
        [rowHeight]="'5:6'"
        class="content-grid-my-courses"
      >
        <mat-grid-tile
          *ngFor="
            let row of archivedCourses
              | filter
                : searchInput.value
                : 'title'
                : ''
                : sortFilter
                : courseFilter
              | slice : 0 : currentMax
          "
          (mouseenter)="hovering = row.user_course?.course"
          (mouseleave)="hovering = false"
        >
          <mat-icon
            *ngIf="row.user_course?.course?.purchased"
            [matMenuTriggerFor]="appMenu"
            class="card-menu-button"
            (click)="setCourseMenu(row.user_course?.course)"
            >more_vert
          </mat-icon>
          <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="selectCourse(row.user_course)">
              Overview
            </button>
            <button
              mat-menu-item
              (click)="selectCourse(row.user_course, '/course/workbook')"
            >
              Workbook
            </button>
            <button
              mat-menu-item
              (click)="
                selectCourse(row.user_course, '/course/progress/summary')
              "
            >
              Progress
            </button>
            <button
              mat-menu-item
              (click)="selectCourse(row.user_course, '/instructors')"
            >
              Instructors
            </button>
            <button
              mat-menu-item
              (click)="
                selectCourse(row.user_course, '/achievements/certificates')
              "
            >
              Certificate
            </button>
            <button
              mat-menu-item
              (click)="archiveCourse(row.user_course)"
              *ngIf="!row.user_course?.archived"
            >
              {{ archiveLabel }}
            </button>
            <button
              mat-menu-item
              (click)="archiveCourse(row.user_course)"
              *ngIf="row.user_course?.archived"
            >
              {{ unarchiveLabel }}
            </button>
          </mat-menu>
          <a>
            <mat-card class="my-archived-card" tabindex="0">
              <img
                *ngIf="row.user_course?.thumbnail_image_url"
                mat-card-image
                src="{{ row.user_course?.thumbnail_image_url }}"
              />
              <img
                *ngIf="!row.user_course?.thumbnail_image_url"
                mat-card-image
                src="assets/images/img3.jpeg"
                alt=""
              />

              <mat-card-content>
                <mat-card-subtitle>COURSE</mat-card-subtitle>
                <mat-card-title class="card-header-text line-height">{{
                  row.user_course?.course.title
                }}</mat-card-title>
                <div class="description">
                  <p class="model">
                    By {{ row.user_course?.course?.created_by }}
                  </p>
                  <div class="progresspercentage">
                    {{ row.course_progress }}%
                  </div>
                  <section class="progress-section">
                    <mat-progress-bar
                      class="example-margin"
                      [color]="'primary'"
                      [mode]="'Determinate'"
                      [value]="row.course_progress"
                      [bufferValue]="75"
                    >
                    </mat-progress-bar>
                  </section>
                </div>
              </mat-card-content>
            </mat-card>
          </a>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-template>
    <div class="full-width">
      <button
        id="show-more-btn"
        mat-button
        *ngIf="currentMax < courses?.length"
        (click)="showMore()"
      >
        View More
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </ng-container>
</div>
