import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaServerService } from '@app/services/data-services/media-server.service';
import { Content } from '@app/models/course/content/content';
import { ProgressCalculatorService } from '@app/services/helper/progress-calculator.service';
import { UserProgress } from '@app/models/user/user-progress';
import { Lesson } from '@app/models/course/workbook/lesson/lesson';
import { Router } from '@angular/router';

@Component({
  selector: 'app-scorm',
  templateUrl: './scorm.component.html',
  styleUrls: ['./scorm.component.scss']
})
export class ScormComponent implements AfterViewInit {
  @Input('content') content: Content;
  @Input('lesson') lesson: Lesson;
  @Input('lesson_id') lesson_id: number;
  @Output() create_scorm: EventEmitter<any> = new EventEmitter<any>();
  @Output() update_scorm: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('frame') frame: any;
  @ViewChild('wrapper', { static: true }) wrapper: any;
  complete = false;
  scorm_progress = '';
  url: any = '';
  api: any;
  loading = false;
  progresses: UserProgress[] = [];

  constructor(private sanitizer: DomSanitizer,
              private calculatorService: ProgressCalculatorService,
              private router: Router,
              private mediaServerService: MediaServerService) {
    const $this = this;
    const route = this.router.url;
    this.api = {
      LMSInitialize: function() {
        this.data = {};
        this.data['cmi.core.lesson_status'] = 'not attempted';
        if (route === '/library') {
          this.data['cmi.suspend_data'] = '';
          return 'true';
        } else {
          const progressItem = $this.calculatorService.getScormProgressItem($this.lesson.id, $this.content);
          if (progressItem.length && progressItem[0] && progressItem[0].scorm_progress) {
            if (progressItem[0].scorm_progress.includes('_isComplete')) {
              this.data['cmi.suspend_data'] = progressItem[0].scorm_progress;
            } else {
              this.data['cmi.suspend_data'] = progressItem[0].scorm_progress.replace(/"/g, '');
            }
          } else {
            this.data['cmi.suspend_data'] = '';
          }
          return 'true';
        }
      },
      LMSFinish: function() {
        // console.log('LMS Finish');
        return 'true';
      },
      LMSGetValue: function(key) {
        // console.log('LMS Get Value');
        // window.console && console.log('LMSGetValue("' + key + '") - ' + this.data[key]);
        return this.data[key];
      },
      LMSSetValue: function(key, value) {
        // window.console && console.log('LMSSetValue("' + key + '") - ' + value);
        this.data[key] = value;
        if (key === 'cmi.suspend_data') {
          let newValue = null;
          try {
            newValue = JSON.parse(value);
          } catch (e) {
            newValue = value;
          }
          $this.scorm_progress = newValue;
          if (newValue && newValue._isComplete) {
            $this.create_scorm.emit({status: 2, scorm_progress: newValue});
          } else if (newValue && newValue._progress === 100) {
            $this.update_scorm.emit({status: 2, scorm_progress: newValue});
          } else if (newValue) {
            if ($this.complete) {
              return;
            }
            $this.update_scorm.emit({status: 1, scorm_progress: newValue});
          }
        } else if (key === 'cmi.core.lesson_status') {
          if (value === 'completed') {
            $this.complete = true;
            $this.update_scorm.emit({status: 2, scorm_progress: $this.scorm_progress});
          }
        }

        return 'true';
      },
      LMSCommit: function() {
        // console.log('LMS Commit');
        return 'true';
      },
      LMSGetLastError: function() {
        // console.log('LMS Get Last Error');
        return 0;
      },
      LMSGetErrorString: function() {
        return 'Fake error string.';
      },
      LMSGetDiagnostic: function() {
        return 'Fake diagnostic information.';
      }
    };
  }

  ngAfterViewInit() {
    console.log('Route: ', 'scorm.component.ts');
    window['API'] = this.api;
    this.loading = true;
    this.mediaServerService.scormStartingURL(this.content.blob_directory)
      .then(url => {
        if (url) {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(url.toString());
          console.log('this.url: ', this.url);
        } else {
          this.url = null;
        }
        this.loading = false;
      }).catch((err) => {
      this.url = null;
      console.log(err);
      this.loading = false;
    });
  }
}
