import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input('content') content: any;
  url: any = '';
  @Input() isMobile: any;
  @Input() isMobileLandscapeOrientation: any;
  @Input() isIpadLandscapeOrientation: any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log('isMobile from image component: ', this.isMobile);
    console.log('isMobileLandscapeOrientation from image component: ', this.isMobileLandscapeOrientation);
    console.log('isIpadLandscapeOrientation from image component: ', this.isIpadLandscapeOrientation);
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.content.upload_image);
  }

}
