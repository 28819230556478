import {Component, Input, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlightcardContentPlayerComponent } from './flightcard-content-player/flightcard-content-player.component';

@Component({
  selector: 'app-flight-card',
  templateUrl: './flight-card.component.html',
  styleUrls: ['./flight-card.component.scss']
})

export class FlightCardComponent implements OnInit {
  @Input('card') card: any;
  @Input('isDialog') isDialog = false;
  @Input('canClick') canClick = true;
  // content = this.data;

  constructor( public dialog: MatDialog ) {
  }

  ngOnInit() {
    // console.log(this.card);
  }

  openDialog() {
    if (!this.isDialog && this.canClick) {

      const dialogRef = this.dialog.open( FlightcardContentPlayerComponent, {
        width: '300px',
        height: '500px',
        data: this.card
      });

    }
  }

  closeDialog() {
    if (this.dialog) {
      this.dialog.closeAll();
    }
  }

  gridSize() {
    return window.matchMedia(`(max-width: 960px)`).matches ? window.matchMedia(`(max-width: 768px)`).matches ? window.matchMedia(`(max-width: 500px)`).matches ? 1 : 2 : 3 : 4;
  }
}
