import { Action } from '@ngrx/store';

// ASSESSMENTS
export const FETCH_ASSESSMENT_ENTRIES = 'FETCH_ASSESSMENT_ENTRIES';
export const FETCH_ASSESSMENT_LOGBOOKS = 'FETCH_ASSESSMENT_LOGBOOKS';
export const FETCH_ASSESSMENTS = 'FETCH_ASSESSMENTS';
export const FETCH_ASSESSMENT_PROGRESS = 'FETCH_ASSESSMENT_PROGRESS';
export const UPDATE_ASSESSMENT_PROGRESS = 'UPDATE_ASSESSMENT_PROGRESS';
export const UPDATE_ASSESSMENT_ENTRIES = 'UPDATE_ASSESSMENT_ENTRIES';
export const UPDATE_ASSESSMENT_LOGBOOKS = 'UPDATE_ASSESSMENT_LOGBOOKS';
export const UPDATE_ASSESSMENTS = 'UPDATE_ASSESSMENTS';
export const UPDATE_SELECTED_ASSESSMENT = 'UPDATE_SELECTED_ASSESSMENT';
export const UPDATE_PREVIOUS_ASSESSMENT_OF_TYPE = 'UPDATE_PREVIOUS_ASSESSMENT_OF_TYPE';
export const UPDATE_NEXT_ASSESSMENT_OF_TYPE = 'UPDATE_NEXT_ASSESSMENT_OF_TYPE';
export const UPDATE_ASSESSMENT_DEFERMENTS = 'UPDATE_ASSESSMENT_DEFERMENTS';
export const CLEAR_ASSESSMENT_INFO = 'CLEAR_ASSESSMENT_INFO';
export const ADD_ASSESSMENT_PROGRESS = 'ADD_ASSESSMENT_PROGRESS';
export const UPDATE_SELECTED_ASSESSMENT_LOGBOOKS = 'UPDATE_SELECTED_ASSESSMENT_LOGBOOKS';
export const REMOVE_LOGBOOK = 'REMOVE_LOGBOOK';
export const VIEW_TASK_DETAIL = 'VIEW_TASK_DETAIL';

// Course
export const SET_COURSE = 'SET_COURSE';
export const FETCH_COURSE_WORKBOOK = 'FETCH_COURSE_WORKBOOK';
export const SET_COURSE_WORKBOOK = 'SET_COURSE_WORKBOOK';
export const CLEAR_SELECTED_COURSE = 'CLEAR_SELECTED_COURSE';
export const SET_COURSE_COMPLETED = 'SET_COURSE_COMPLETED';

// LOGBOOK ENTRIES
export const ADD_LOGBOOK = 'ADD_LOGBOOK';
export const DELETE_LOGBOOK = 'DELETE_LOGBOOK';
export const UPDATE_LOGBOOK = 'UPDATE_LOGBOOK';

// TASK ENTRIES
export const ADD_ATTEMPT = 'ADD_ATTEMPT';
export const REMOVE_ATTEMPTS = 'ADD_ATTEMPTS';
export const REMOVE_ATTEMPT = 'REMOVE_ATTEMPT';

// STUDENT
export const SELECT_STUDENT = 'SELECT_STUDENT';
export const CLEAR_SELECTED_STUDENT = 'CLEAR_SELECTED_STUDENT';
export const SET_USER_NAME = 'SET_USER_NAME';

export const PUSH_LOADING = 'PUSH_LOADING';

// DEFERMENT
export const FETCH_DEFERMENTS = 'FETCH_DEFERMENTS';
export const FETCH_DEFER_REASONS = 'FETCH_DEFER_REASONS';
export const UPDATE_DEFERMENTS = 'UPDATE_DEFERMENTS';
export const UPDATE_DEFER_REASONS = 'UPDATE_DEFER_REASONS';
export const ADD_DEFERMENT = 'ADD_DEFERMENT';
export const REMOVE_DEFERMENTS = 'REMOVE_DEFERMENTS';

export class FetchAssessmentEntries implements Action {
  readonly type = FETCH_ASSESSMENT_ENTRIES;

  constructor(public payload: any) {}
}

export class FetchAssessmentLogbooks implements Action {
  readonly type = FETCH_ASSESSMENT_LOGBOOKS;

  constructor(public payload: any) {}
}

export class FetchAssessments implements Action {
  readonly type = FETCH_ASSESSMENTS;

  constructor(public payload: any) {}
}

export class FetchAssessmentProgress implements Action {
  readonly type = FETCH_ASSESSMENT_PROGRESS;

  constructor(public payload: any) {}
}

export class FetchDeferments implements Action {
  readonly type = FETCH_DEFERMENTS;

  constructor(public payload: any) {}
}

export class FetchDeferReasons implements Action {
  readonly type = FETCH_DEFER_REASONS;
}

export class UpdateDeferReasons implements Action {
  readonly type = UPDATE_DEFER_REASONS;

  constructor(public payload: any) {}
}

export class UpdateNextAssessmentOfType implements Action {
  readonly type = UPDATE_NEXT_ASSESSMENT_OF_TYPE;

  constructor(public payload: any) {}
}

export class UpdatePreviousAssessmentOfType implements Action {
  readonly type = UPDATE_PREVIOUS_ASSESSMENT_OF_TYPE;

  constructor(public payload: any) {}
}

export class UpdateAssessmentDeferments implements Action {
  readonly type = UPDATE_ASSESSMENT_DEFERMENTS;
}

export class UpdateAssessmentEntries implements Action {
  readonly type = UPDATE_ASSESSMENT_ENTRIES;

  constructor(public payload: any) {}
}

export class UpdateAssessmentLogbooks implements Action {
  readonly type = UPDATE_ASSESSMENT_LOGBOOKS;

  constructor(public payload: any) {}
}

export class UpdateSelectedAssessmentLogbooks implements Action {
  readonly type = UPDATE_SELECTED_ASSESSMENT_LOGBOOKS;
}

export class UpdateAssessments implements Action {
  readonly type = UPDATE_ASSESSMENTS;

  constructor(public payload: any) {}
}

export class UpdateAssessmentProgress implements Action {
  readonly type = UPDATE_ASSESSMENT_PROGRESS;

  constructor(public payload: any) {}
}

export class UpdateDeferments implements Action {
  readonly type = UPDATE_DEFERMENTS;

  constructor(public payload: any) {}
}

export class UpdateSelectedAssessment implements Action {
  readonly type = UPDATE_SELECTED_ASSESSMENT;

  constructor(public payload: any) {}
}

export class AddAssessmentProgress implements Action {
  readonly type = ADD_ASSESSMENT_PROGRESS;

  constructor(public payload: any) {}
}

export class ClearSelectedCourse implements Action {
  readonly type = CLEAR_SELECTED_COURSE;
}

export class ClearAssessmentInfo implements Action {
  readonly type = CLEAR_ASSESSMENT_INFO;
}

export class AddLogbook implements Action {
  readonly type = ADD_LOGBOOK;

  constructor(public payload: any) {}
}

export class DeleteLogbook implements Action {
  readonly type = DELETE_LOGBOOK;

  constructor(public payload: any) {}
}

export class UpdateLogbook implements Action {
  readonly type = UPDATE_LOGBOOK;

  constructor(public payload: any) {}
}

export class AddAttempt implements Action {
  readonly type = ADD_ATTEMPT;

  constructor(public payload: any) {}
}

export class RemoveAttempts implements Action {
  readonly type = REMOVE_ATTEMPTS;

  constructor(public payload: any) {}
}

export class RemoveAttempt implements Action {
  readonly type = REMOVE_ATTEMPT;

  constructor(public payload: any) {}
}

export class RemoveLogbook implements Action {
  readonly type = REMOVE_LOGBOOK;

  constructor(public payload: any) {}
}

export class AddDeferment implements Action {
  readonly type = ADD_DEFERMENT;

  constructor(public payload: any) {}
}

export class RemoveDeferments implements Action {
  readonly type = REMOVE_DEFERMENTS;

  constructor(public payload: any) {}
}

export class SelectStudent implements Action {
  readonly type = SELECT_STUDENT;

  constructor(public payload: any) {}
}

export class ClearSelectedStudent implements Action {
  readonly type = CLEAR_SELECTED_STUDENT;
}

export class SetCourse implements Action {
  readonly type = SET_COURSE;

  constructor(public payload: any) {}
}
export class FetchCourseWorkbook implements Action {
  readonly type = FETCH_COURSE_WORKBOOK;

  constructor(public payload: any) {}
}
export class SetCourseWorkbook implements Action {
  readonly type = SET_COURSE_WORKBOOK;

  constructor(public payload: any) {}
}

export class SetCourseCompleted implements Action {
  readonly type = SET_COURSE_COMPLETED;

  constructor(public payload: any) {}
}

export class SetUserName implements Action {
  readonly type = SET_USER_NAME;

  constructor(public payload: any) {}
}

export class PushLoading implements Action {
  readonly type = PUSH_LOADING;

  constructor(public payload: any) {}
}

export class ViewTaskDetail implements Action {
  readonly type = VIEW_TASK_DETAIL;

  constructor(public payload: any) {}
}
export type AssessmentActions = FetchAssessmentEntries
                              | FetchAssessmentLogbooks
                              | FetchDeferments
                              | FetchDeferReasons
                              | FetchAssessmentProgress
                              | UpdateAssessmentLogbooks
                              | UpdateAssessmentEntries
                              | UpdateAssessments
                              | UpdateDeferments
                              | UpdateDeferReasons
                              | UpdateAssessmentDeferments
                              | UpdateSelectedAssessment
                              | UpdateNextAssessmentOfType
                              | UpdateAssessmentProgress
                              | UpdatePreviousAssessmentOfType
                              | UpdateSelectedAssessmentLogbooks
                              | RemoveLogbook
                              | SetCourse
                              | FetchCourseWorkbook
                              | SetCourseWorkbook
                              | SetCourseCompleted
                              | ClearSelectedCourse
                              | SetUserName
                              | AddLogbook
                              | DeleteLogbook
                              | UpdateLogbook
                              | AddAttempt
                              | AddAssessmentProgress
                              | RemoveAttempts
                              | RemoveAttempt
                              | AddDeferment
                              | RemoveDeferments
                              | SelectStudent
                              | ClearSelectedStudent
                              | ClearAssessmentInfo
                              | PushLoading
                              | ViewTaskDetail;
