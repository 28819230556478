<app-uploader
  *ngIf="displayUploader"
  [uploader_only]="false"
  [editor]="editor"
  title="{{ title }}"
  subtext="{{ subtext }}"
  editorId="{{ editorId }}"
></app-uploader>
<div class="editor-container" *ngIf="displayEditor">
  <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
  <ngx-editor
    [id]="editorId"
    [editor]="editor"
    [placeholder]="placeholder"
    [spellcheck]="spellcheck"
    outputFormat="html"
    [ngModel]="text"
    (ngModelChange)="handleTextChange($event)"
  >
  </ngx-editor>
</div>
