<div  fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Course Content</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class='full-width pb-1'>
          <button mat-raised-button [color]="'primary'" (click)="navigate('admin/courses/content/add')"> Add Content </button>
          <input
            type='text'
            style='padding:8px;margin:15px auto;width:30%;'
            placeholder='Filter Content'
            (keyup)='updateFilter($event)'
          />
        </div>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable class="material bg-white" #table
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows"
                         [selected]="selected"
                         [selectionType]="'row'"
                         [loadingIndicator]="isLoading">
            <ngx-datatable-column name="Title" prop='title'></ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
              name="Type">
              <ng-template let-row='row' ngx-datatable-cell-template>
                  {{map[row.content_type]}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Description" prop='desc'></ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false">
              <ng-template let-row='row' ngx-datatable-cell-template>
                <div style="text-align: right">
                  <button mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">
                    <mat-icon class="text-muted">more_vert</mat-icon>
                  </button>
                  <mat-menu #msgMenu="matMenu">
                    <button mat-menu-item (click)='editContent(row)'><mat-icon>edit</mat-icon>Edit</button>
<!--                    <button mat-menu-item (click)="removeContent(row)">Remove</button>-->
                  </mat-menu>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
