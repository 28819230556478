import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {AddAssignmentDialogComponent} from './add-assignment-dialog/add-assignment-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {AssignmentService} from '@app/services/data-services/assignment.service';
import {DatePipe} from '@angular/common';
import {ProgressService} from '@app/services/data-services/progress.service';
import {UserProgress} from '@app/models/user/user-progress';

// TODO: Is this component used?

@Component({
  selector: 'app-student-assignments',
  templateUrl: './student-assignments.component.html',
  styleUrls: ['./student-assignments.component.scss']
})
export class StudentAssignmentsComponent implements OnInit {
  selected: any[] = [];
  newEditDialogRef: MatDialogRef<AddAssignmentDialogComponent>;
  rows: any[] = [];
  student_id: number;
  datePipe = new DatePipe('en-US');
  userProgress: UserProgress[] = [];

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog,
              private assignmentService: AssignmentService,
              private progressService: ProgressService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.student_id = params['id'];
        this.getUserAssignments();
      }
    });
  }

  getUserAssignments() {
    this.assignmentService.getStudentAssignments(this.student_id)
      .then(assignments => this.rows = assignments);

    this.progressService.getAssignmentProgress(this.student_id)
      .then(progresses => this.userProgress = progresses);
  }

  addAssignment() {
    const width = '600px';
    const height = '600px';

    this.newEditDialogRef = this.dialog.open(AddAssignmentDialogComponent, {
      width,
      height,
      data: this.student_id
    });

    this.newEditDialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.getUserAssignments();
      }
    });
  }

  checkStatus(row) {
    if (this.userProgress && this.userProgress.length) {
      return this.userProgress.map(progress => {
        if (progress.status.toString() === 'completed') {
          return progress.item_id;
        }
      }).indexOf(row.id) > -1;
    } else {
      return false;
    }
  }

  markCompleted(row) {
  }

}
