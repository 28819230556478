export class SummaryTotal {
  private summaries: Summary[];

  constructor(summaries: Summary[]) {
    this.summaries = summaries;
  }

  completed() {
    return this.summaries.reduce((sum, summary): number => {
      sum += summary.completed;
      return sum;
    }, 0)
  }

  total() {
    return this.summaries.reduce((sum, summary): number => {
      sum += summary.total;
      return sum;
    }, 0)
  }
}

export class Summary {
  total: number;
  completed: number;
}
