
export class Assignment {
  id?: number;
  name?: string;
  desc?: string;
  content?: string;
  content_type?: string;

  constructor() {
    this.content_type = 'assignment';
  }
}
