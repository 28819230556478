<div fxFlex="100" fxLayout="row">
  <div class="editor-wrapper" fxLayout="row wrap" fxFlex="grow">
    <mat-card class="p-0 pb-1" fxFlex="grow">
      <mat-card-title class="">
        <div class="card-title-text">Add Content</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for="title" class="form-label">Title</label>
            <input
              id="title"
              name="title"
              class="form-input"
              [(ngModel)]="content_model.title"
              placeholder="Title"
            />
          </div>
          <div class="form-row">
            <label for="subtitle" class="form-label">Subtitle</label>
            <input
              id="subtitle"
              name="subtitle"
              class="form-input"
              [(ngModel)]="content_model.subtitle"
              placeholder="Subtitle"
            />
          </div>
          <!--<div class="form-row">-->
          <!--<label for="max-attempts" class="form-label">Max Attempts <span class='message'>Set to zero if unlimited attempts.</span></label>-->
          <!--<input id="max-attempts" name="max-attempts" class="form-input" [(ngModel)]="content_model.maxAttempts" placeholder="Max Attempts">-->
          <!--</div>-->
          <div class="form-row">
            <label for="desc" class="form-label">Description</label>
            <textarea
              id="desc"
              name="desc"
              class="form-textarea"
              [(ngModel)]="content_model.desc"
              placeholder="Description"
            ></textarea>
          </div>
          <div class="form-row">
            <label for="createdBy" class="form-label">Created By</label>
            <input
              id="createdBy"
              name="createdBy"
              class="form-input"
              [(ngModel)]="content_model.created_by"
              placeholder="Created By"
            />
          </div>
          <div class="form-row">
            <label for="placeholder" class="form-label"
              >Placeholder Image
              <span class="message"
                >This image is used for library cards.</span
              ></label
            >
            <div>
              <img
                id="content-img"
                #image
                src="#"
                width="325"
                height="175"
                alt=""
                hidden
              />
            </div>
            <input
              id="placeholder"
              type="file"
              name="placeholder"
              appAppUploaderPreview
              [image]="image"
              class="form-input"
              itemid="placeholder"
              ng2FileSelect
              [uploader]="imageUploader"
            />
          </div>
          <div class="form-row">
            <label for="meta-tags" class="form-label"
              >Meta Tags
              <span class="meta-tag-info"
                >Type new meta tags and separate by commas or hit enter to add
                tag</span
              ></label
            >
            <mat-form-field id="meta-tags" class="meta-tags-input">
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let tag of content_model?.meta_tags"
                  [removable]="true"
                  (remove)="remove(tag)"
                >
                  {{ tag }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder="Add Meta Tags"
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="false"
                  (matChipInputTokenEnd)="add($event)"
                  name="meta-tags"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="form-row">
            <label for="type" class="form-label">Content Type</label>
            <div class="selection-wrapper">
              <select
                id="type"
                [(ngModel)]="content_model.content_type"
                (change)="clearContent()"
                name="type"
                class="form-select"
              >
                <option value="-1" disabled>Select Type</option>
                <option
                  [value]="type.key"
                  *ngFor="let type of map | keys : 'filter'"
                >
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="form-row"
            *ngIf="content_model?.content_type == contentEnum.quiz"
          >
            <label for="quiz" class="form-label">Quiz</label>
            <div class="full-width" *ngIf="selectedQuiz">
              <p class="selected-quiz">{{ selectedQuiz?.name }}</p>
            </div>
            <button
              mat-raised-button
              [color]="'primary'"
              (click)="openDialog('quiz')"
            >
              Add Quiz
            </button>
          </div>
          <div
            class="form-row"
            *ngIf="content_model?.content_type == contentEnum.exam"
          >
            <label for="exam" class="form-label">Exam</label>
            <div class="full-width" *ngIf="selectedExam">
              <p class="selected-exam">{{ selectedExam?.name }}</p>
            </div>
            <button
              mat-raised-button
              [color]="'primary'"
              (click)="openDialog('exam')"
            >
              Add Exam
            </button>
          </div>
          <!--vimeo = 0,-->
          <!--youtube = 1,-->
          <!--scorm = 2,-->
          <!--tinCan = 3,-->
          <!--pdf = 4,-->
          <!--quiz = 5,-->
          <!--download = 6,-->
          <!--interactive = 7,-->
          <!--richText = 8,-->
          <!--flight_assessment = 9,-->
          <!--ground_assessment = 10-->
          <div
            class="form-row"
            *ngIf="
              [
                contentEnum.vimeo?.toString(),
                contentEnum.youtube?.toString()
              ].indexOf(content_model?.content_type) > -1
            "
          >
            <label for="video" class="form-label">Video Link</label>
            <input
              id="video"
              type="text"
              name="video"
              class="form-input"
              [(ngModel)]="content_model.url"
            />
          </div>
          <div
            class="form-row"
            *ngIf="
              [
                contentEnum.scorm?.toString(),
                contentEnum.tinCan?.toString(),
                contentEnum.pdf?.toString(),
                contentEnum.download?.toString(),
                contentEnum.interactive?.toString()
              ].indexOf(content_model.content_type) > -1
            "
          >
            <mat-accordion id="help-video-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a SCORM File</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>Step 1: Find the file you would like to upload.</p>
                      <p>
                        Step 2: Right-click on the file and compress it into a
                        Zip file.
                      </p>
                      <P>Step 3: Upload the zip file.</P>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <label for="scorm" class="form-label">Upload File</label>
            <input
              #scorm
              id="scorm"
              type="file"
              ng2FileSelect
              [uploader]="fileUploader"
              name="scorm"
            />
          </div>
          <div
            class="form-row"
            *ngIf="content_model?.content_type == contentEnum.image"
          >
            <div>
              <img
                id="upload-img"
                #imageUpload
                src="#"
                width="325"
                height="175"
                alt=""
                hidden
              />
            </div>
            <input
              id="image-upload"
              type="file"
              name="image-upload"
              appAppUploaderPreview
              [image]="imageUpload"
              class="form-input"
              itemid="image-upload"
              ng2FileSelect
              [uploader]="imageUploadUploader"
            />
          </div>
          <div
            class="form-row"
            *ngIf="
              [
                contentEnum.flight_assessment?.toString(),
                contentEnum.ground_assessment?.toString()
              ].indexOf(content_model?.content_type) > -1
            "
          >
            <mat-checkbox
              name="course-jet-scoring"
              [(ngModel)]="content_model.jet_scoring"
              >Perfect Scoring</mat-checkbox
            >
          </div>
          <div
            class="form-row"
            *ngIf="
              [
                contentEnum.flight_assessment?.toString(),
                contentEnum.ground_assessment?.toString()
              ].indexOf(content_model?.content_type) > -1
            "
          >
            <label for="tasks" class="form-label">Tasks</label>
            <div
              id="tasks"
              [dragula]="'task-bag'"
              [(dragulaModel)]="content_model.tasks"
            >
              )
              <div
                class="dnd-item"
                *ngFor="let task of content_model?.tasks; let i = index"
              >
                <div
                  class="quiz_question"
                  fxFlex="90"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <mat-icon class="black-text">drag_handle</mat-icon>
                  <span>{{ i + 1 }}</span> <span>{{ task.name }}</span>
                  <input
                    [(ngModel)]="task.required_successful_attempts"
                    class="form-input"
                    name="rsa"
                    min="1"
                    type="number"
                    placeholder="Required Successful Attempts"
                  />
                  <mat-button-toggle-group
                    name="task_type"
                    (change)="updateType(i, group.value)"
                    #group="matButtonToggleGroup"
                  >
                    <mat-button-toggle [checked]="true" value="practice">
                      Practice
                    </mat-button-toggle>
                    <mat-button-toggle value="perform">
                      Perform
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                  <mat-icon class="black-text" (click)="removeTask(task)"
                    >close</mat-icon
                  >
                  <!-- TODO: Add back when task badge functionality is needed, Removed to free up space for practice vs perform -->
                  <!--<img *ngIf="task?.badge" [src]="task?.badge?.badge_image" width="50" height="50" alt="">-->
                  <!--<button mat-raised-button [color]="'primary'" (click)='openDialog("badge", task)'>Select Badge</button>-->
                </div>
              </div>
            </div>
            <div class="form-row">
              <button
                mat-raised-button
                [color]="'primary'"
                (click)="openDialog('task')"
              >
                Add Task(s)
              </button>
            </div>
          </div>
          <div
            class="form-row"
            *ngIf="content_model?.content_type == contentEnum.richText"
          >
            <label for="content">Content</label>
            <mat-accordion id="help-image-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload an Image</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>
                        To upload an image, click inside the text editor before
                        attempting to upload or the upload will fail and will
                        display the error: "Range out of the editor"
                      </p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <app-wysiwyg-editor
              editorId="content"
              displayEditor="true"
              displayUploader="true"
              title="Content"
              placeholder="Enter text here..."
              [(text)]="content_model.content_html"
            ></app-wysiwyg-editor>
          </div>
        </form>
      </mat-card-content>
      <mat-spinner id="loading-spinner" *ngIf="is_loading"></mat-spinner>
    </mat-card>
  </div>
  <div
    id="submit-bar"
    class="sidebar-panel navigation-hold"
    @slideInRight
    fxFlex="nogrow"
  >
    <button
      class="btn save-btn"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="upload()"
    >
      Save <mat-icon>save</mat-icon>
    </button>
    <button
      class="btn cancel-btn"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="cancel()"
    >
      Cancel <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
