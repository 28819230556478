<h2 class="text-body-header">Course Catalog</h2>

<mat-toolbar class="submenu">
  <mat-toolbar-row class="filterMenu course-wrapper maxwrapper">
    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="dropdown-width">
        <mat-select
          placeholder="Courses"
          (selectionChange)="filterChanged()"
          [formControl]="courseFilter"
        >
          <mat-option value="all">Show All</mat-option>
          <mat-option *ngIf="isLoggedIn()" value="enrolled"
            >Enrolled</mat-option
          >
          <mat-option value="free">Free</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="dropdown-width">
        <mat-select
          placeholder="Aircraft"
          (selectionChange)="filterChanged()"
          [formControl]="aircraftFilter"
        >
          <mat-option value="all">Show All</mat-option>
          <mat-option
            *ngFor="let aircraft of aircrafts"
            [value]="aircraft.id"
            >{{ aircraft.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="dropdown-width">
        <mat-select
          placeholder="Avionics"
          (selectionChange)="filterChanged()"
          [formControl]="avionicsFilter"
        >
          <mat-option value="all">Show All</mat-option>
          <mat-option *ngFor="let avionic of avionics" [value]="avionic.id">{{
            avionic.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
      <mat-form-field class="dropdown-width">
        <mat-select
          placeholder="Training"
          (selectionChange)="filterChanged()"
          [formControl]="trainingFilter"
        >
          <mat-option value="all">Show All</mat-option>
          <mat-option
            *ngFor="let category of courseCategories"
            [value]="category.id"
            >{{ category.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field class="dropdown-width">
      <mat-select
        [(value)]="sort"
        placeholder="Sort"
        (selectionChange)="filterChanged()"
      >
        <mat-option value="">Category</mat-option>
        <mat-option value="1">A-Z</mat-option>
        <mat-option value="-1">Z-A</mat-option>
      </mat-select>
    </mat-form-field>
    <div fxFlex></div>
    <mat-form-field class="small-search-screen" id="searchContainer">
      <input
        id="search"
        type="text"
        placeholder="Search"
        matInput
        (keyup)="filterChanged()"
        [(ngModel)]="filter"
      />
    </mat-form-field>
  </mat-toolbar-row>
</mat-toolbar>

<mat-toolbar class="smallscreen-submenu">
  <mat-toolbar-row class="filterMenu">
    <mat-form-field class="small-search-screen" id="searchContainer">
      <input
        id="search"
        type="text"
        placeholder="Search"
        matInput
        (keyup)="filterChanged()"
        [(ngModel)]="filter"
      />
    </mat-form-field>
  </mat-toolbar-row>
</mat-toolbar>

<div class="course-wrapper-learning-catalog maxwrapper">
  <mat-spinner id="loading-spinner" *ngIf="is_loading"></mat-spinner>
  <div *ngIf="filteredCourses.length != 0">
    <div *ngFor="let category of filteredCourses">
      <div *ngIf="this.sort == ''" class="category-header">
        <h4>{{ category?.category.name }}</h4>

        <h5>{{ category?.category.desc }}</h5>
        <span class="small"
          >{{ category.courses.length }}
          {{ category.courses.length === 1 ? 'Course' : 'Courses' }}</span
        >
        <a
          class="category-header-button"
          *ngIf="
            !category.editing &&
            category?.courses &&
            category?.showAll == false &&
            category?.courses?.length > category.currentMax
          "
          (click)="showMore(category)"
          >Show All</a
        >
        <a
          class="category-header-button"
          *ngIf="category.editing"
          (click)="showLess(category)"
          >Show Less</a
        >
      </div>

      <mat-grid-list
        [cols]="gridSize()"
        gutterSize="25"
        [rowHeight]="'5:6'"
        class="catalog-grid"
      >
        <mat-grid-tile
          *ngFor="
            let course of category.showAll
              ? (category?.courses | slice : 0 : category.courses.length)
              : (category?.courses | slice : 0 : category.currentMax)
          "
        >

          <a *ngIf="course.is_enrolled" (click)="selectEnrolledCourse(course)">
            <mat-card class="course-card" tabindex="0">
              <span *ngIf="course.has_agreement" class="instructor-required-badge">INSTRUCTOR REQ'D</span>
              <img
                mat-card-image
                [src]="
                  course?.thumbnail_image_url
                    ? course?.thumbnail_image_url
                    : defaultCourseThumb
                "
              />
              <mat-card-content>
                <!--                              <mat-card-subtitle class='card-header-text'>{{course?.course_type?.toString().toUpperCase()}}</mat-card-subtitle>-->
                <h5 class="line-height">{{ course?.title }}</h5>
                <span class="enroll-badge">Enrolled</span>
              </mat-card-content>
            </mat-card>
          </a>
          <a *ngIf="!course.is_enrolled" (click)="openDialogOrNavigate(course)">
            <mat-card class="course-card" tabindex="0">
              <span *ngIf="course.has_agreement" class="instructor-required-badge">INSTRUCTOR REQ'D</span>
              <img
                mat-card-image
                [src]="
                  course?.thumbnail_image_url
                    ? course?.thumbnail_image_url
                    : defaultCourseThumb
                "
              />
              <mat-card-content>
                <!--                                <mat-card-subtitle class='card-header-text'>{{course?.course_type?.toString().toUpperCase()}}</mat-card-subtitle>-->
                <h5 class="line-height">{{ course?.title }}</h5>
                <span *ngIf="!(course?.list_price > 0)" class="free-badge">Free</span>
                <span *ngIf="course?.list_price > 0" class="course-price">Price: {{ course?.list_price | currency }}</span>
              </mat-card-content>
            </mat-card>
          </a>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
  <div *ngIf="filteredCourses.length == 0 && !is_loading">
    <h3 class="text-center">There are no courses.</h3>
  </div>
</div>
