<mat-dialog-content class="content-dialog-wrapper" style="position: relative;">
  <button class="close-btn" mat-icon-button mat-dialog-close><mat-icon class="close-icon">close</mat-icon></button>
  <div class="content-wrapper"
       fxLayout="column wrap">
    <div class="full-width content-wrapper">
      <div class="mat-box-shadow margin-333">
        <ngx-datatable class="material bg-white"
                       [rows]="rows"
                       [columns]="columns"
                       [columnMode]="'force'"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       [rowHeight]="'auto'"
                       [limit]="50"
                       [selected]="selected"
                       [selectionType]="'single'"
                       (select)='onUserSelect($event)'>
        </ngx-datatable>
      </div>
    </div>
  </div>
</mat-dialog-content>
