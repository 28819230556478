import { filter } from 'rxjs/operators';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import {
  AfterContentInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Course } from '@app/models/course/course';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '@app/services/data-services/course.service';
import { slideInRight } from '@app/animations/animations';
import { SnackbarComponent } from '@app/components/shared/snackbar/snackbar.component';
import { FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { Aircraft } from '@app/models/aircraft/aircraft';
import { GeneralTableDialogComponent } from '@app/components/shared/general-table-dialog/general-table-dialog.component';
import { StageEditorDialogComponent } from './stage-editor-dialog/stage-editor-dialog.component';
import { VersionDialogComponent } from '@app/components/shared/version-dialog/version-dialog.component';
import { CourseCategoryService } from '@app/services/data-services/course-category.service';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Certificate } from '@app/models/course/certificate';
import { Badge } from '@app/models/course/course-badge';
import { Stage } from '@app/models/course/workbook/stage/stage';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import { ContentTypeMap } from '@app/models/course/content/content-type';

import * as workbookHelper from '../../../../../helpers/workbook.helper';
import { HttpService } from '@app/services/http-service.service';
import { AircraftService } from '@app/services/data-services/aircraft.service';
import { SelectionType } from '@swimlane/ngx-datatable';
import { CertificatesService } from '@app/services/data-services/certificates.service';
import { BadgesService } from '@app/services/data-services/badges.service';
import { LessonService } from '@app/services/data-services/lesson.service';
import { LessonTypeMap } from '@app/models/course/workbook/lesson/lesson-type-map';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CoursesServiceV4 } from '@app/courses-v4.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { isEqual } from 'lodash';
import { MatTableConfig } from '@app/models/mat-table/mat-table';
import { CourseCategory } from '@app/models/course/course-category';
import { ContentService } from '@app/services/data-services/content.service';
import { Content } from '@app/models/course/content/content';

@Component({
  selector: 'app-course-editor',
  templateUrl: './course-editor.component.html',
  styleUrls: [
    './course-editor.component.scss',
    './workbook-editor.scss',
    './drag-drop.scss',
  ],
  animations: [slideInRight],
})
export class CourseEditorComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  @ViewChild('editor', { static: true }) editor: ElementRef;
  public image_uploader: FileUploader = new FileUploader({});
  course: Course = new Course();
  stages: Stage[] = [];
  courseCategories: any[] = [];
  editing: boolean = false;
  subscription: any;
  contentDialog: any;
  has_badge: boolean = false;
  has_certificate: boolean = false;
  forceNewVersion: boolean = false;
  setNewVersion: boolean = false;
  stagesChanged: boolean = false;
  lessonsChanged: boolean[] = [];
  imageUrl = '';
  separatorKeysCodes = [ENTER, COMMA, TAB];
  contentTypeMap = ContentTypeMap;
  lessonTypeMap = LessonTypeMap;
  agreement = '';
  course_id: any;
  preview_change = false;
  drag_subs = new Subscription();
  drakes: any[] = ['stage-bag', 'lesson-bag'];
  dialogRef: any;
  versionDialog: any;
  stageEditorRef: any;
  isSaving: boolean = false;
  scroll: any;
  isDragging: boolean = false;
  initialStages = [];
  contentTitleDisplay: string;
  isLoading: boolean;

  private checkedDirtySubscription: Subscription;

  courseForm = this.formBuilder.group({
    id: [''],
    title: ['', [Validators.required, Validators.maxLength(60)]],
    subtitle: ['', Validators.maxLength(70)],
    name: [''],
    is_active: [false],
    desc: [''],
    completion_time: [''],
    minimum_flight_hours: [''],
    created_by: [''],
    course_category_ids: [[]],
    course_category: this.formBuilder.group({
      desc: '',
      id: '',
      name: '',
    }),
    course_categories: [[]],
    course_id: [null],
    has_agreement: [false],
    major_version: [null],
    is_ecommerce_enabled: [''],

    minor_version: [null],

    course_overview_video_id: [''],

    overview: ['', [Validators.required, Validators.minLength(27)]],
    sales_desc: ['',  [Validators.minLength(27)]],
    list_price: [''],
    shipping_price: [''],
    fulfillment_part_number: [''],
    tax_code: [''],
    mobile_hero_image_url: [''],
    desktop_hero_image_url: [''],
    thumbnail_image_url: [''],
    course_type: [''],
    agreement_body: [null, Validators.minLength(27)],
    avionics: [[]],
    updated_at: [],
    badge: [null],
    badge_id: [null],
    can_reenroll: [true],
    certificate: [null],
    certificate_id: [null],
    sf_course_id: [null],
    aircrafts: this.formBuilder.array([]),
    stages: this.formBuilder.array([]),
    workbook: this.formBuilder.group({
      stages: this.formBuilder.array([]),
    }),
  });

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private courseServiceV4: CoursesServiceV4,
    private categoryService: CourseCategoryService,
    public dragulaService: DragulaService,
    private http: HttpService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder
  ) {
    // Fetch categories
    this.categoryService.getCategories().then(categories => {
      this.courseCategories = categories.filter(category => category.id !== 0);
    });

    // Check if we're editing then set some values
    this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.courseService.getCourseToEdit(params['id']).then(course => {
          if (course) {
            this.editing = true;
            this.agreement = course.agreement_body;
            this.has_certificate = course.certificate !== null;
            this.has_badge = course.badge !== null;

            if(course.course_overview_video){
              this.contentTitleDisplay = course.course_overview_video.title;
            }

            course.aircrafts.forEach(air => {
              this.aircrafts.push(this.newAircraft(air));
            });
            this.courseForm.patchValue({
              course_categories: course.course_categories,
            });

            if (course.workbook.stages) {
              course.workbook.stages
                .sort((a, b) => a.order - b.order)
                .forEach((stage, i) => {
                  this.initialStages.push(stage);
                  this.workbookStages.push(this.newStage(stage));
                });
            }
            this.courseForm.patchValue(course);
          } else this.cancel();
        });
      }
    });
  }

  get formControl(): { [key: string]: any } {
    return this.courseForm.controls;
  }

  get hasAgreement() {
    return this.courseForm.get('has_agreement').value;
  }

  get isCommerceEnabled() {
    return this.courseForm.get('is_ecommerce_enabled').value;
  }

  get shippingPrice() {
    return this.courseForm.get('shipping_price').value;
  }

  get currentDesktopUrl() {
    return this.courseForm.get('desktop_hero_image_url').value;
  }

  get currentThumbnailUrl() {
    return this.courseForm.get('thumbnail_image_url').value;
  }

  get currentMobileUrl() {
    return this.courseForm.get('mobile_hero_image_url').value;
  }

  get updated() {
    return this.courseForm.get('updated_at').value;
  }

  get active() {
    const is_active = this.courseForm.get('is_active').value;
    return is_active ? 'Active' : 'Inactive';
  }

  ngOnInit() {
    this.imageUrl = this.http.getFullUrl(
      this.imageUrl + 'images/upload/rich_text'
    );
    this.checkIfWorkbookValueHasChanged();
  }

  ngAfterContentInit() {
    this.initUploader();
  }

  checkIfWorkbookValueHasChanged() {
    this.checkedDirtySubscription = this.workbookStages.valueChanges.subscribe(
      val => {
        this.compareInitialAndCurrentCourseFormState(this.initialStages, val);
      }
    );
  }

  addContent() {
    this.isLoading = true;
    let tableConfig: MatTableConfig = {
      dialogTitle: 'Add Media',
      customConfirmButton: false,
      showCancelButton: false,
      filterCategory: 'content_type',
      selectionType: SelectionType.single,
      typeMap: this.contentTypeMap,

      hasSidebar: true,
      columns: [
        {
          name: 'Select',
          mat_col_name: 'select',
          nested_name: '',
          type_map: false,
        },
        {
          name: 'Title',
          mat_col_name: 'title',
          nested_name: '',
          type_map: false,
        },
        {
          name: 'Type',
          mat_col_name: 'content_type',
          nested_name: '',
          type_map: true,
        },
        {
          name: 'Description',
          mat_col_name: 'desc',
          nested_name: '',
          type_map: false,
        },
      ],
      dataColumns: ['select', 'title', 'content_type', 'desc'],
      rows: [],
    };
    let httpService = new ContentService(this.http);
    httpService.getVideoContent().subscribe(rows => {
      this.isLoading = false;
      tableConfig.rows = rows;
      this.contentDialog = this.dialog.open(GeneralTableDialogComponent, {
        width: '56vw',
        data: tableConfig,
      });

      this.contentDialog.afterClosed().subscribe((content: Content[]) => {
        if (!content) {
          return;
        }
        this.contentTitleDisplay = content[0].title;
        this.courseForm.patchValue({
          course_overview_video_id: content[0].id,
        });
      });
    });
  }

  compareInitialAndCurrentCourseFormState(initial, current) {
    if (isEqual(initial, current)) {
      this.forceNewVersion = false;
      this.setNewVersion = false;
    } else {
      this.forceNewVersion = true;
      this.setNewVersion = true;
    }
  }

  workbook() {
    return this.courseForm.get('workbook');
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }

  get workbookStages(): FormArray {
    return this.workbook().get('stages') as FormArray;
  }

  workbookStage(index): FormArray {
    return this.workbookStages.at(index) as FormArray;
  }

  workbookStageLessons(stageIndex): FormArray {
    return this.workbookStages.at(stageIndex).get('lessons') as FormArray;
  }

  workbookStageLesson(stageIndex, lessonIndex) {
    return (this.workbookStages.at(stageIndex).get('lessons') as FormArray).at(
      lessonIndex
    );
  }

  get aircrafts(): FormArray {
    return this.courseForm.get('aircrafts') as FormArray;
  }

  get course_categories() {
    return this.courseForm.get('course_categories');
  }

  get badge() {
    return this.courseForm.get('badge');
  }

  get certificate() {
    return this.courseForm.get('certificate');
  }

  canReEnroll() {
    return this.courseForm.get('can_reenroll');
  }

  get majorVersion() {
    return this.courseForm.get('major_version');
  }

  get courseCategory() {
    return this.courseForm.get('course_category');
  }

  get minorVersion() {
    return this.courseForm.get('minor_version');
  }

  get isActive() {
    return this.courseForm.get('is_active');
  }

  dropStage(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.workbookStages.controls,
      event.previousIndex,
      event.currentIndex
    );

    this.compareInitialAndCurrentCourseFormState(
      this.initialStages,
      this.workbookStages
    );
  }

  drop(event: CdkDragDrop<string[]>, stageIndex) {
    moveItemInArray(
      this.workbookStageLessons(stageIndex).controls,
      event.previousIndex,
      event.currentIndex
    );

    this.compareInitialAndCurrentCourseFormState(
      this.initialStages,
      this.workbookStages
    );
  }

  newStage(stage): FormGroup {
    const {
      desc,
      id,
      lessons,
      lessons_are_linear,
      name,
      order,
      overview,
      stage_type,
      title,
      certificate,
      certificate_id
    } = stage;

    return this.formBuilder.group({
      desc,
      id,
      lessons: this.formBuilder.array(lessons),
      lessons_are_linear,
      name,
      order,
      overview,
      stage_type,
      title,
      certificate,
      certificate_id
    });
  }

  newLesson(lesson): FormGroup {
    const {
      completion_time,
      contents,
      contents_are_linear,
      id,
      image,
      instructor_content_enabled,
      instructor_contents,
      instructor_intro_video,
      instructor_overview,
      is_archived,
      is_preview,
      lesson_type,
      major_version,
      minimum_flight_hours,
      minimum_ground_hours,
      minimum_landings,
      minimum_sim_hours,
      minor_version,
      mobile_hero_image_url,
      order,
      overview,
      student_intro_video,
      subtitle,
      system_desc,
      system_name,
      thumbnail_image_url,
      title,
      updated_at,
    } = lesson;
    return this.formBuilder.group({
      completion_time,
      contents: this.formBuilder.array(contents),
      contents_are_linear,
      id,
      image,
      instructor_content_enabled,
      instructor_contents,
      instructor_intro_video,
      instructor_overview,
      is_archived,
      is_preview,
      lesson_type,
      major_version,
      minimum_flight_hours,
      minimum_ground_hours,
      minimum_landings,
      minimum_sim_hours,
      minor_version,
      mobile_hero_image_url,
      order,
      overview,
      student_intro_video,
      subtitle,
      system_desc,
      system_name,
      thumbnail_image_url,
      title,
      updated_at,
    });
  }

  setUrl($event, type: string) {
    this.courseForm.patchValue({ [type]: $event });
  }

  initUploader() {
    const url = this.http.getFullUrl('images/upload');
    const token = '';

    this.image_uploader.setOptions({ url: url });
    this.image_uploader.authTokenHeader = 'Access-Token';
    this.image_uploader.authToken = localStorage.getItem('cirrus-token');

    this.image_uploader.onAfterAddingFile = item => {
      item.withCredentials = false;
      if (this.image_uploader.queue.length) {
        this.image_uploader.uploadAll();
      }
    };

    this.image_uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: ParsedResponseHeaders
    ) => {
      this.course.image = JSON.parse(response).location;
    };
  }

  changeReEnrollState() {
    this.courseForm.patchValue({ can_reenroll: !this.canReEnroll().value });
    this.course.can_reenroll = !this.course.can_reenroll;
  }

  changeBadgeState() {
    this.has_badge = !this.has_badge;
    if (!this.has_badge) {
      this.course.badge = null;
      this.course.badge_id = null;
    }
  }
  changeCertState() {
    this.has_certificate = !this.has_certificate;
    if (!this.has_certificate) {
      this.course.certificate = null
      this.course.certificate_id = null;
      }
  }

  openVersionDialog() {
    this.versionDialog = this.dialog.open(VersionDialogComponent, {
      width: '40vw',
      position: {
        top: '80px',
      },
      data: {
        majorVersion: this.majorVersion.value,
        minorVersion: this.minorVersion.value,
      },
    });
  }

  setVersion() {
    this.openVersionDialog();
    this.versionDialog.afterClosed().subscribe(version => {
      if (version) {
        this.courseForm.patchValue({ major_version: version.major_version });
        this.courseForm.patchValue({ minor_version: version.minor_version });
        this.buildCourse();
      }
    });
  }

  saveCourse() {
    if (this.editing && (this.forceNewVersion || this.setNewVersion)) {
      this.setVersion();
    } else {
      this.buildCourse();
    }
  }

  openDialog(tableConfig): any {
    this.dialogRef = this.dialog.open(GeneralTableDialogComponent, {
      width: '50vw',
      data: tableConfig,
    });
  }

  addBadge() {
    let tableConfig: MatTableConfig = {
      dialogTitle: 'Add Items',
      customConfirmButton: false,
      showCancelButton: false,
      selectionType: SelectionType.single,
      columns: [
        {
          name: 'Select',
          mat_col_name: 'select',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Name',
          mat_col_name: 'name',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Description',
          mat_col_name: 'desc',
          type_map: false,
          nested_name: '',
        },
      ],
      rows: [],
      dataColumns: ['select', 'name', 'desc'],
    };

    let httpService = new BadgesService(this.http);
    httpService.getBadges().then(rows => {
      tableConfig.rows = rows;
      this.openDialog(tableConfig);

      this.dialogRef.afterClosed().subscribe((data: Badge) => {
        if (data) {
          this.courseForm.patchValue({ badge: data[0], badge_id: data[0].id});
        }
      });
    });
  }

  rmBadge() {
    this.courseForm.patchValue({ badge: null, badge_id: null });
  }

  removeCourseOverviewVideo() {
    this.courseForm.patchValue({
      course_overview_video_id: null
    })
    this.contentTitleDisplay = ''
  }

  addCertificate() {
    let tableConfig: MatTableConfig = {
      dialogTitle: 'Add Items',
      customConfirmButton: false,
      showCancelButton: false,
      filterCategory: null,
      selectionType: SelectionType.single,
      columns: [
        {
          name: 'Select',
          mat_col_name: 'select',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Name',
          mat_col_name: 'name',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Description',
          mat_col_name: 'desc',
          type_map: false,
          nested_name: '',
        },
      ],
      rows: [],
      dataColumns: ['select', 'name', 'desc'],
    };

    let httpService = new CertificatesService(this.http);
    httpService.getCertificates().then(rows => {
      tableConfig.rows = rows;
      this.openDialog(tableConfig);

      this.dialogRef.afterClosed().subscribe((data: Certificate) => {
        if (data) {
          this.courseForm.patchValue({ certificate: data[0], certificate_id: data[0].id });
        }
      });
    });
  }

  addStageCertificate(stage: FormGroup) {
    let tableConfig: MatTableConfig = {
      dialogTitle: 'Add Items',
      customConfirmButton: false,
      showCancelButton: false,
      filterCategory: null,
      selectionType: SelectionType.single,
      columns: [
        {
          name: 'Select',
          mat_col_name: 'select',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Name',
          mat_col_name: 'name',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Description',
          mat_col_name: 'desc',
          type_map: false,
          nested_name: '',
        },
      ],
      rows: [],
      dataColumns: ['select', 'name', 'desc'],
    };

    let httpService = new CertificatesService(this.http);
    httpService.getCertificates().then(rows => {
      tableConfig.rows = rows;
      this.openDialog(tableConfig);

      this.dialogRef.afterClosed().subscribe((data: Certificate) => {
        if (data) {
              stage.patchValue({ certificate: data[0], certificate_id: data[0].id });
        }
      });
    });
  }

  rmCert(stageId?: number) {
    if (stageId) {
      this.workbookStages.controls.forEach((stage, i) => {
        if (stage.value.id === stageId) {
          stage.patchValue({ certificate: null, certificate_id: null });
        }
      });
      return;
    } else {
      this.courseForm.patchValue({ certificate: null, certificate_id: null });
    }
  }

  addAircraft() {
    let tableConfig: MatTableConfig = {
      dialogTitle: 'Add Items',
      customConfirmButton: false,
      showCancelButton: false,
      selectionType: SelectionType.multiClick,
      columns: [
        {
          name: 'Select',
          mat_col_name: 'select',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Name',
          mat_col_name: 'name',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Make',
          mat_col_name: 'make',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Model',
          mat_col_name: 'model',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Avionics',
          mat_col_name: 'avionics',
          nested_name: 'name',
          type_map: false,
        },
      ],

      dataColumns: ['select', 'name', 'make', 'model', 'avionics'],

      rows: [],
    };
    let httpService = new AircraftService(this.http);
    httpService.getAircraft().then(rows => {
      tableConfig.rows = rows;
      this.openDialog(tableConfig);

      this.dialogRef.afterClosed().subscribe((data: Aircraft[]) => {
        if (!data) return;
        data.forEach(a => {
          this.aircrafts.push(this.newAircraft(a));
        });
      });
    });
  }

  newAircraft(aircraft): FormGroup {
    const { id, avionics, desc, generation, make, model, name } = aircraft;
    return this.formBuilder.group({
      id,
      avionics,
      desc,
      generation,
      model,
      make,
      name,
    });
  }

  newCourseCategory(cat): FormGroup {
    const { desc, id, name } = cat;
    return this.formBuilder.group({
      id,
      desc,
      name,
    });
  }

  rmAir(i) {
    this.aircrafts.removeAt(i);
  }

  /* Workbook */

  openStageEditor(type?: string, stage?, stageIndex?: number) {
    const data = { stage, type };

    if (stage == null) stage = new Stage();
    this.stageEditorRef = this.dialog.open(StageEditorDialogComponent, {
      width: '45vw',
      data: data,
    });
    this.stageEditorRef.afterClosed().subscribe((data: Stage) => {
      if (!data) {
        return;
      }
      if (type === 'Add') {

        this.workbookStages.push(this.newStage(data));
        console.log('newStage', this.workbookStages.value)
      } else {
        this.workbookStages.at(stageIndex).setValue(data);
      }
    });
  }

  deleteWorkbookItem(type, stageIndex?, lessonIndex?) {
    if (type === 'lesson') {
      this.workbookStageLessons(stageIndex).removeAt(lessonIndex);
    } else if (type === 'stage') {
      this.workbookStages.removeAt(stageIndex);
    }
  }

  addLesson(stage, index) {
    if (index > -1) {
      let tableConfig: MatTableConfig = {
        dialogTitle: 'Add Items',
        customConfirmButton: false,
        showCancelButton: false,
        filterCategory: 'lesson_type',
        selectionType: SelectionType.multiClick,
        typeMap: LessonTypeMap,
        hasSidebar: true,
        columns: [
          { name: 'Select', mat_col_name: 'select' },
          { name: 'Title', mat_col_name: 'title' },
          { name: 'Type', mat_col_name: 'lesson_type', type_map: true },
          { name: 'Description', mat_col_name: 'system_desc' },
        ],
        rows: [],
        dataColumns: ['select', 'title', 'lesson_type', 'system_desc'],
      };
      let httpService = new LessonService(this.http);
      httpService.getLessonsForDialog().then(rows => {
        tableConfig.rows = rows;
        const lessonDialogRef = this.dialog.open(GeneralTableDialogComponent, {
          width: '45vw',
          data: tableConfig,
        });

        lessonDialogRef.afterClosed().subscribe(data => {
          if (data) {
            data.forEach(lesson => {
              this.workbookStageLessons(index).push(this.newLesson(lesson));
            });
            if (this.editing) {
              this.stagesChanged = workbookHelper.forceVersionChange(
                this.stages,
                this.course.workbook.stages
              );

              this.forceNewVersion =
                this.lessonsChanged.includes(true) || this.stagesChanged;
            }
          }
        });
      });
    }
  }

  buildCourse() {
    this.checkIfHasAgreement()
    this.isSaving = true;

    this.orderStagesAndLessons();

    if (this.editing) {
      this.courseServiceV4.update(this.courseForm.value).subscribe(() => {
        this.confirmMessage();
      });
    } else {
      this.courseServiceV4.create(this.courseForm.value).subscribe(() => {
        this.confirmMessage();
      });
    }
  }

  orderStagesAndLessons() {
    this.workbookStages.controls.forEach((stage, i) => {
      stage.patchValue({ order: i });
      this.workbookStageLessons(i).controls.forEach((l, i) => {
        l.setValue({ ...l.value, order: i });
      });
    });
  }

  checkIfHasAgreement() {
    if (!this.hasAgreement){
      this.courseForm.patchValue({agreement_body: null})
    }
  }

  confirmMessage() {
    const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
      data: this.editing ? 'Updated course' : 'Created course',
    });

    this.subscription = snackBar.afterOpened().subscribe(() => {
      this.router.navigate(['/admin/courses']);
    });
  }

  cancel() {
    this.router.navigate(['/admin/courses']);
  }

  ngOnDestroy() {
    this.checkedDirtySubscription.unsubscribe();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.drag_subs) {
      this.drag_subs.unsubscribe();
    }
    this.drakes.forEach(drake => {
      if (this.dragulaService.find(drake)) {
        this.dragulaService.destroy(drake);
      }
    });
  }
}
