import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss'],
})
export class GeneralDialogComponent implements OnInit {
  dialogTitle: string;
  dialogBodyContent: string;
  dialogConfirmButtonLabel: string;
  showCancelButton = false;
  confirmReenroll = false;
  dialogCancelButtonLabel: string;

  constructor(
    public dialogRef: MatDialogRef<GeneralDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogTitle = this.data.dialogTitle;
    this.dialogBodyContent = this.data.dialogBodyContent;
    this.dialogConfirmButtonLabel = this.data.dialogConfirmButtonLabel;
    this.showCancelButton = this.data.showCancelButton;
    if (this.showCancelButton)
      this.dialogCancelButtonLabel = this.data.dialogCancelButtonLabel;
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  save(info) {
    this.dialogRef.close(true);
  }
}
