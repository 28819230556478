import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ProgressService} from '@app/services/data-services/progress.service';
import {Bookmark} from '@app/models/course/bookmark';
import {ContentService} from '@app/services/data-services/content.service';
import {UserService} from '@app/services/data-services/user.service';
import {CommentService} from '@app/services/data-services/comment.service';
import {ContentComment, ContentCommentType} from '@app/models/course/content/comment';
import {ProgressCalculatorService} from '@app/services/helper/progress-calculator.service';

@Component({
    selector: 'app-content-player',
    templateUrl: './content-player.component.html',
    styleUrls: ['./content-player.component.scss']
})
export class ContentPlayerComponent implements OnInit, AfterViewInit {
    content: any;
    comments = false;
    showComments = false;
    input = false;
    questionIndexes = [];
    answerIndexes = [];
    bookmarks: Bookmark[] = [];
    bmProm;
    questions: ContentComment[] = [];
    showContent = false;
    isPreview = false;
    isMobile = false;
    isMobileLandscapeOrientation = false;
    isIpadLandscapeOrientation = false;

  constructor(public dialogRef: MatDialogRef<ContentPlayerComponent>,
                public progressService: ProgressService,
                private contentService: ContentService,
                private commentService: CommentService,
                private userService: UserService,
                private snackBar: MatSnackBar,
                private progressCalculator: ProgressCalculatorService,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
      console.log('Route: ', 'content-player.component.ts');
      this.check();
      this.content = this.data.content;
      // console.log('Content: ', this.data.content);
      if (this.isMobile && this.data.content.content_type !== 11) {
        this.dialogRef.updateSize('100vw', '85vh');
      } else if (this.isMobile && this.isIpadLandscapeOrientation && this.data.content.content_type === 11) {
        this.dialogRef.updateSize('70vw', '85vh');
      } else if (this.isMobile && !this.isIpadLandscapeOrientation && this.data.content.content_type === 11) {
        this.dialogRef.updateSize('100vw', '85vh');
      }
      this.showContent = true;
      this.isPreview = this.data.isPreview;
      this.progressCalculator.setProgresses(this.data.progresses);
      if (this.data.isPreview) {
        return;
      }
      // console.log('is ipad landscape?', this.isIpadLandscapeOrientation);
      this.getBookMarks();
      this.dialogRef.beforeClosed().subscribe(() => this.dialogRef.close(this.bmProm));
    }

    check() {
      this.checkIfIpadPro();
      this.checkIfMobilePhoneLandscape();
      this.checkIfIpadLandscape();
      this.checkIfMobile();
    }

    checkIfIpadPro() {
      if ((window.matchMedia(`(width: 1024px)`).matches && window.matchMedia(`(height: 1366px)`).matches) ||
        (window.matchMedia(`(height: 1024px)`).matches && window.matchMedia(`(width: 1366px)`).matches)) {
        this.isMobile = true;
      }
    }

    checkIfMobilePhoneLandscape() {
      if ((window.matchMedia(`(min-width: 312px)`).matches && window.matchMedia(`(max-width: 812px)`).matches) && window.matchMedia(`(orientation: landscape)`).matches) {
        this.isMobileLandscapeOrientation = true;
      }
    }

    checkIfIpadLandscape() {
      if ((window.matchMedia(`(min-width: 768px)`).matches && window.matchMedia(`(max-width: 1200px)`).matches) && window.matchMedia(`(orientation: landscape)`).matches) {
        this.isIpadLandscapeOrientation = true;
      }
    }

    checkIfMobile() {
      if ((window.matchMedia(`(max-width: 1024px)`).matches && window.matchMedia(`(max-height: 768px)`).matches) ||
        (window.matchMedia(`(max-height: 1024px)`).matches && window.matchMedia(`(max-width: 768px)`).matches)) {
        // console.log('Mobile dialog is active');
        this.isMobile = true;
      }
    }


    quizSubmitted(answers) {

        const score = ((answers.filter(value => {

            return value === true;

        }).length) / answers.length) * 100;

        // Check to see if the quiz already exists
        if (this.progressCalculator.progressDoesNotExistForContentInLesson(this.content, this.data.lesson)) {
            this.progressCalculator.createProgressForContent(this.data.currentUserCourse, this.data.activeCourseAttempt, this.data.stage, this.data.lesson, this.content, score);
        }


    }

    submitComment(comment, keydownEvent) {

        if (keydownEvent.key === 'Enter' && keydownEvent.shiftKey) {
          // console.log('hee');
            if (comment === null) {

                const commentToPost = new ContentComment(keydownEvent.target.value, this.content.id, ContentCommentType.question);
                this.commentService.postComment(commentToPost).then(newComment => {
                    this.addNewComment(newComment);
                });

            } else {

                const commentToPost = new ContentComment(keydownEvent.target.value, this.content.id, ContentCommentType.comment, comment.id);
                this.commentService.postComment(commentToPost).then(newComment => {
                    this.addReplyToComment(comment, newComment);

                });
            }

          keydownEvent.target.value = '';

        }


    }


    private addReplyToComment(comment, newComment) {
        comment.replies.push(newComment);
    }

    private addNewComment(newComment) {
        this.questions.push(newComment);

        this.questionIndexes.push({
            start: 0,
            end: 1,
            isOpen: false
        });
    }

    isBookmarked(content) {

        return this.bookmarks.map(item => item.content_id).indexOf(content.id) !== -1;
    }

    bookmark(content) {
        const index = this.bookmarks.map(item => item.content_id).indexOf(content.id);

        if (index === -1) {
            this.bmProm = this.contentService.bookmark(content.id).then((bookmark) => {
                this.bookmarks.push(bookmark);
                content.myBookshelf = !content.myBookshelf;
                return this.bookmarks;
            });
        } else {
            this.bmProm = this.contentService.removeBookmark(this.bookmarks[index].id).then(() => {
                this.bookmarks.splice(index, 1);
                content.myBookshelf = !content.myBookshelf;
                return this.bookmarks;
            });
        }
    }

    checkProgress(createProgress?, event?) {
        if (this.data.progresses && !event) {
          // console.log('SHOULD BE CREATED:', this.checkIfProgressShouldBeCreated());
          if (this.checkIfProgressShouldBeCreated() || createProgress) {
            // console.log('Is course a preview?: ', this.isPreview);
            if (!this.isPreview) {
              this.progressCalculator.createProgressForContent(this.data.currentUserCourse, this.data.attempt, this.data.stage, this.data.lesson, this.content).then(progresses => {
                this.progressCalculator.updateProgressItem(progresses);
                this.progressCalculator.checkProgress(this.data.currentUserCourse, this.data.attempt, this.data.stage, this.data.lesson, this.content, null, () => this.data.callback());
              });
            }
          }
        } else if (this.data.progresses && event) {
          // console.log('CHECK PROGRESS WITH EVENT');
          this.progressCalculator.createProgressForContent(this.data.currentUserCourse, this.data.attempt, this.data.stage, this.data.lesson, this.content, 0, event.status, event.scorm_progress).then(progress => {
            this.progressCalculator.updateProgressItem(progress);
            this.progressCalculator.checkProgress(this.data.currentUserCourse, this.data.attempt, this.data.stage, this.data.lesson, this.content, null, () => this.data.callback());
          });
      }
    }


    private checkIfProgressShouldBeCreated() {
        return this.content.content_type === 0
                || this.content.content_type === 1
                || this.content.content_type === 4
                || this.content.content_type === 6
                || this.content.content_type === 7
                || this.content.content_type === 8
                || this.content.content_type === 11;
    }

    isCurrentContentCompleted() {

        return (this.data.progresses.filter(progress => progress.lesson_id === this.data.lesson.id && progress.item_id === this.content.id).length !== 0);

    }


    nextContent() {

      this.comments = false;

      const index = this.data.lesson.contents.indexOf(this.content);

      if (index === this.data.lesson.contents.length - 1) {

          this.close();

          return;
      }

      if (this.data.lesson.contents_are_linear === true) {
          if (this.isCurrentContentCompleted()) {
            this.showContent = false;
            this.content = this.data.lesson.contents[index + 1];
            setTimeout(() => this.showContent = true, 0);
          } else {
              this.snackBar.open('You need to finish this content before moving to the next.');
          }

      } else {
        this.showContent = false;
        this.content = this.data.lesson.contents[index + 1];
        setTimeout(() => this.showContent = true, 0);
      }

      this.checkProgress();

    }

    previousContent() {

      this.comments = false;

        const index = this.data.lesson.contents.indexOf(this.content);

        if (index === 0) {

            return;
        }
      this.showContent = false;
      this.content = this.data.lesson.contents[index - 1];
        setTimeout(() => this.showContent = true, 0);

      this.checkProgress();

    }


    isLastContentForLesson() {

        return this.data.lesson.contents.indexOf(this.content) === this.data.lesson.contents.length - 1;
    }

    isFirstContentForLesson() {

        return this.data.lesson.contents.indexOf(this.content) === 0;
    }

    ngAfterViewInit() {
      this.checkProgress();
    }

    private getBookMarks() {
        this.userService.getBookmarks().then(bookmarks => {
            this.bookmarks = bookmarks;
        });
    }

    close() {
        // this.dialogRef.close(this.bmProm);
        // this.dialogRef.componentInstance.resizeDialog();
    }

    toggleComments() {
        this.comments = !this.comments;
        if (this.comments) {
            this.getComments();
        } else if (!this.comments) {
          this.showComments = false;
        }
    }

    private getComments() {

        this.commentService.getCommentForContent(this.content).then(comments => {

            this.questions = comments;

            for (let i = 0; i < this.questions.length; i++) {
                this.questionIndexes.push({
                    start: 0,
                    end: 1,
                    isOpen: false
                });
                this.answerIndexes.push({
                    isOpen: false
                });
            }

            this.showComments = true;
        });
    }

    toggleInput(i: number) {
        if (this.answerIndexes[i]) {
            this.answerIndexes[i].isOpen = !this.answerIndexes[i].isOpen;
        } else {
            this.answerIndexes[i] = {isOpen: true};
        }
    }

    showAnswers(i: number) {
        if (this.questionIndexes[i]) {
            if (!this.questionIndexes[i].isOpen) {
                this.questionIndexes[i].isOpen = true;
                this.questionIndexes[i].end = this.questions[i].replies.length + 1;
            } else {
                this.questionIndexes[i].isOpen = false;
                this.questionIndexes[i].end = 1;
            }
        }
    }

    vote_up(comment, index, comment_index?) {
      this.commentService.up(comment)
        .then(res => {
          if (comment_index === undefined) {
            this.questions[index] = res;
          } else {
            this.questions[index].replies[comment_index] = res;
          }
        });
    }

    vote_down(comment, index, comment_index?) {
      this.commentService.down(comment)
        .then(res => {
          if (comment_index === undefined) {
            this.questions[index] = res;
          } else {
            this.questions[index].replies[comment_index] = res;
          }
        });
    }

    up_vote_count(comment) {
      return comment.content_comment_votes.filter(vote => vote.content_comment_vote_type.toString() === 'up').length;
    }

    checkIfVoteUp(comment) {
      const user_id = localStorage.getItem('cirrus-user-id');

      if (user_id) {
        return comment.content_comment_votes.filter(vote => vote.content_comment_vote_type.toString() === 'up').map(vote => vote.user_id).indexOf(parseInt(user_id)) > -1;
      } else {
        return false;
      }
    }

    checkIfVoteDown(comment) {
      const user_id = localStorage.getItem('cirrus-user-id');

      if (user_id) {
        return comment.content_comment_votes.filter(vote => vote.content_comment_vote_type.toString() === 'down').map(vote => vote.user_id).indexOf(parseInt(user_id)) > -1;
      } else {
        return false;
      }
    }

    hasFlagged(comment) {
      const user_id = localStorage.getItem('cirrus-user-id');

      if (user_id) {
        return comment.content_comment_flags.map(flag => flag.user_id).indexOf(parseInt(user_id)) > -1;
      } else {
        return false;
      }
    }

    flagComment(comment, index, comment_index?) {
      this.commentService.flag(comment)
        .then(res => {
          if (comment_index === undefined) {
            this.questions[index] = res;
          } else {
            this.questions[index].replies[comment_index] = res;
          }
        });
    }

    removeFlag(comment, index, comment_index?) {
      this.commentService.flag(comment)
        .then(res => {
          if (comment_index === undefined) {
            this.questions[index] = res;
          } else {
            this.questions[index].replies[comment_index] = res;
          }
        });
    }
}
