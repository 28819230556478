import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    const cirrusRole = JSON.parse(localStorage.getItem('cirrus-role'));
    const hasUserAccess = ['user', 'pilot', 'instructor', 'admin', 'super_admin'].includes(cirrusRole);
    
    if (hasUserAccess || state.url == '/learning-catalog') {
      return true;
    } else if (state.url.includes('course')) {
      // to-do should do further check if in state of preview
      return true;
    } else {
      return this.router.createUrlTree(['/learning-catalog']);
    }
  }
}
