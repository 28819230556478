<div
  #user_layout_wrapper
  id="user-layout-wrapper"
  [ngClass]="(showPanel$ | async) ? 'fixed' : ''"
>
  <app-user-topbar
    *ngIf="!isMobile"
    [notifications]="notifications$ | async"
    [showPanel]="showPanel$ | async"
    [scrolling]="scrolling"
    [sidenav]="sidenav"
    [notificPanel]="notificationPanel"
    [shoppingCart]="shoppingCart"
    [appsMenu]="appsMenu"
    (displayHamburger)="togglePanel($event)"
  ></app-user-topbar>

  <mat-sidenav
    #notificationPanel
    mode="over"
    [ngClass]="collapse ? 'sidenav-collapsed' : 'sidenav-extended'"
    position="end"
  >
    <div class="navigation-hold" fxLayout="column">
      <app-notifications
        [notifications$]="notifications$"
        [notificPanel]="notificationPanel"
        [notificationsToken]="notificationsToken$"
      ></app-notifications>
    </div>
  </mat-sidenav>

  <main fxLayout="row">
    <div
      [fxFlex.gt-xs]="collapse ? '0 0 125px' : '0 0 244px'"
      [ngClass]="
        (showPanel$ | async) ? 'sidenav-container opened' : 'sidenav-container'
      "
    >
      <!-- Main side admin-user-sidenav -->

      <app-sidenav
        (emitCollapse)="toggleCollapse($event)"
        (emitDismissHamburgerMenu)="togglePanel($event)"
        [showPanel]="showPanel$ | async"
        [notifications]="notifications$ | async"
        [notificPanel]="notificationPanel"
      ></app-sidenav>
    </div>
    <div fxFlex="1 1 100%" fxLayout="column">
      <router-outlet></router-outlet>

      <app-footer></app-footer>
    </div>
  </main>
</div>
<app-shopping-cart #shoppingCart></app-shopping-cart>
<app-apps-menu #appsMenu></app-apps-menu>
