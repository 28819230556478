import { Component, OnInit } from '@angular/core';
import { CourseCategory } from '@app/models/course/course-category';
import { CourseCategoryService } from '@app/services/data-services/course-category.service';
import { slideInRight } from '@app/animations/animations';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector:    'app-add-course-category',
  templateUrl: './add-course-category.component.html',
  styleUrls:   [ './add-course-category.component.scss'],
  animations:  [
    slideInRight
  ]
})
export class AddCourseCategoryComponent implements OnInit {
  course_category: CourseCategory;
  rows: CourseCategory[];

  constructor(private categoryService: CourseCategoryService, 
              private router: Router,
              public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.course_category = new CourseCategory();
  }

  addCategory() {
    this.categoryService.create(this.course_category)
      .then(() => {
        this.snackbar.open('Updated')
        this.course_category = new CourseCategory()
      });
  }

  cancel() {
    this.router.navigate(['admin/courses/category/course/overview']);
  }
}
