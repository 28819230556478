import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseCategory } from 'app/models/course/course-category';
import { CourseCategoryService } from 'app/services/data-services/course-category.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { slideInRight } from 'app/animations/animations';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector:    'app-manage-course-category',
  templateUrl: './manage-course-category.component.html',
  styleUrls:   [ './manage-course-category.component.scss'],
  animations:  [
    slideInRight
  ]
})
export class ManageCourseCategoryComponent implements OnInit, OnDestroy {
  course_category: CourseCategory;
  subs: any[] = [];
  form: FormGroup;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private categoryService: CourseCategoryService,
              private fb: FormBuilder,
              public snackbar: MatSnackBar) {
    this.course_category = new CourseCategory();
    this.createForm();
  }

  ngOnInit() {
    this.subs.push(
      this.activatedRoute.params.subscribe((params) => {
        if(params['id']) {
          this.getCourseCategory(parseInt(params['id']));
          return;
        }
        this.route();
      })
    );
  }

  getCourseCategory(id) {
    this.categoryService.getCategory(id)
      .then((category) => {
        if (category) {
          Object.assign(this.course_category, category);
          return;
        }
        this.route();
      });
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      desc: [''],
    });
  }

  route() {
    this.router.navigate(['admin/courses/category/course/overview']);
  }

  updateCategory() {
    this.categoryService.update(this.course_category)
      .then(() => {
        this.snackbar.open('Updated')
        this.route()
      });
  }

  cancel() {
    this.route();
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

}
