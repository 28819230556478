import { AppComponent } from './app.component';

// ANGULAR CORE
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { KonamiCodeDirective } from './directives/konami.directive';
// THIRD PARTY ANGULAR
import { CalendarCommonModule, CalendarModule } from 'angular-calendar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FileUploadModule } from 'ng2-file-upload';
import { DragulaModule } from 'ng2-dragula';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import {
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatOptionModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

// Layout Components
import { InstructorLayoutComponent } from './components/layouts/instructor-layout/instructor-layout.component';
import { NavigationComponent } from './components/layouts/backend-sidenav/backend-sidenav.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SidenavComponent } from './components/layouts/sidenav/sidenav.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { AdminLayoutComponent } from './components/layouts/admin-layout/admin-layout.component';
import { AdminTopbarComponent } from './components/layouts/backend-topbar/backend-topbar.component';
import { UserLayoutComponent } from './components/layouts/user-layout/user-layout.component';
import { CtcLayoutComponent } from './components/layouts/ctc-layout/ctc-layout.component';
import { UserSidenavComponent } from './components/layouts/user-layout/user-sidenav/user-sidenav.component';
import { UserTopbarComponent } from './components/layouts/user-layout/user-topbar/user-topbar.component';
import { UserTabbarComponent } from './components/layouts/tab-bar/tab-bar';

// ADMIN
import { UserManagerComponent } from './ui/admin/user-manager/user-manager.component';
import { GamificationManagerComponent } from './ui/admin/gamification-manager/gamification-manager.component';
import { AdminDashboardComponent } from './ui/admin/admin-dashboard/admin-dashboard.component';
import { AdminReportsManagerComponent } from './ui/admin/admin-reports-manager/admin-reports-manager.component';
import { AdminReportCourseDetailComponent } from './ui/admin/admin-reports-manager/admin-reports-dialog/admin-report-course-detail/admin-report-course-detail.component';

// Services
import { NavigationService } from './services/navigation/navigation.service';
import { GlobalEventManagerService } from './services/global-event-manager/global-event-manager.service';
import { CourseWorkbookService } from './services/data-services/course-workbook.service';
import { CertificatesService } from './services/data-services/certificates.service';
import { RoutePartsService } from './services/route-parts.service';
import { CourseCategoryService } from '@app/services/data-services/course-category.service';
import { QuizService } from '@app/services/data-services/quiz.service';
import { ExamService } from '@app/exams.service';
import { QuestionService } from '@app/services/data-services/question.service';
import { AircraftService } from '@app/services/data-services/aircraft.service';
import { AvionicsService } from '@app/services/data-services/avionics.service';
import { TaskService } from '@app/services/data-services/task.service';

import { CourseService } from '@app/services/data-services/course.service';

import { CoursesServiceV4 } from '@app/courses-v4.service';
import { ContentService } from '@app/services/data-services/content.service';
import { MediaServerService } from '@app/services/data-services/media-server.service';

// PIPES
import { FilterPipe } from './pipes/filter.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { KeysPipe } from './pipes/keys.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

// SHARED
import { ProgressSpinnerComponent } from './components/shared/progress-spinner/progress-spinner.component';
import { ShoppingCartComponent } from './components/shared/shopping-cart/shopping-cart.component';
import { BreadcrumbComponent } from './components/shared/breadcrumb/breadcrumb.component';
import { SlideshowComponent } from './components/shared/slideshow/slideshow.component';
import { HeroImageComponent } from './components/shared/hero-image/hero-image.component';
import { AppsMenuComponent } from './components/shared/apps-menu/apps-menu.component';
import { SnackbarComponent } from './components/shared/snackbar/snackbar.component';
import { BadgeComponent } from './components/shared/badge/badge.component';
import { LoginComponent } from './ui/login/login.component';

// USER
import { BadgesAchievementsComponent } from './ui/user/course/badges-achievements/badges-achievements.component';
import { TrainingRecordsComponent } from './ui/user/training-records/training-records.component';
import { MyCoursesComponent } from './ui/user/my-courses/my-courses.component';
import { BadgesComponent } from './ui/user/badges-certs/badges/badges.component';
import { ContentLibraryComponent } from './ui/user/content-library/content-library.component';
import { CourseComponent } from './ui/user/course/course.component';
import { MyLearningComponent } from './ui/user/my-learning/my-learning.component';
import { OverviewComponent } from './ui/user/course/overview/overview.component';
import { WorkbookComponent } from './ui/user/course/workbook/workbook.component';
import { ProgressComponent } from './ui/user/course/progress/progress.component';
import { ContentPlayerComponent } from './ui/user/course/workbook/content-player/content-player.component';
import { ProgressSummaryComponent } from './ui/user/course/progress/summary/progress-summary.component';
import { SkillsAssessmentComponent } from './ui/user/course/progress/skills-assessment/skills-assessment.component';
import { FlightLogComponent } from './ui/user/course/progress/flight-log/flight-log.component';
import { CertificatesComponent } from './ui/user/badges-certs/certificates/certificates.component';
import { UserContentLibraryComponent } from './ui/user/course/content-library/user-content-library.component';
import { TempDataServiceService } from './services/data-services/temp-data-service.service';
import { InteractiveComponent } from './ui/user/course/workbook/content-player/content-templates/interactive/interactive.component';
import { VideoComponent } from './ui/user/course/workbook/content-player/content-templates/video/video.component';
import { FlightAssessmentComponent } from './ui/user/course/workbook/content-player/content-templates/flight-assessment/flight-assessment.component';
import { QuizComponent } from './ui/user/course/workbook/content-player/content-templates/quiz/quiz.component';
import { ScormComponent } from './ui/user/course/workbook/content-player/content-templates/scorm/scorm.component';
import { ImageComponent } from './ui/user/course/workbook/content-player/content-templates/image/image.component';
import { DocumentComponent } from './ui/user/course/workbook/content-player/content-templates/document/document.component';
import { CourseCatalogComponent } from './ui/user/course-catalog/course-catalog.component';
import { CommentsComponent } from './ui/user/course/workbook/content-player/content-templates/comments/comments.component';
import { BadgesCertsComponent } from './ui/user/badges-certs/badges-certs.component';
import { CertsDialogComponent } from './ui/user/badges-certs/certificates/certs-dialog/certs-dialog.component';
import { ContentSlideshowComponent } from './ui/user/course/workbook/content-player/content-templates/content-slideshow/content-slideshow.component';

// INSTRUCTOR
import { QuizzesComponent } from './ui/admin/quiz-manager/quizzes/quizzes.component';
import { CreateQuizComponent } from './ui/admin/quiz-manager/create-quiz/create-quiz.component';
import { QuestionBankComponent } from './ui/admin/quiz-manager/question-bank/question-bank.component';
import { CreateQuizDialogComponent } from './ui/admin/quiz-manager/create-quiz/create-quiz-dialog/create-quiz-dialog.component';
import { CourseEditorComponent } from './ui/admin/course-manager/courses/course-editor/course-editor.component';
import { CoursesComponent } from './ui/admin/course-manager/courses/courses.component';
import { LessonOverviewComponent } from './ui/admin/lesson-manager/lesson-overview/lesson-overview.component';
import { LessonEditorComponent } from './ui/admin/lesson-manager/lesson-editor/lesson-editor.component';
import { AddCourseCategoryComponent } from './ui/admin/category-manager/course-categories/add-course-category/add-course-category.component';
import { ManageCourseCategoryComponent } from './ui/admin/category-manager/course-categories/manage-course-category/manage-course-category.component';
import { ClassroomComponent } from './ui/instructor/classroom-manager/classroom/classroom.component';
import { AssignmentsManagerLayoutComponent } from './ui/instructor/classroom-manager/assignments-manager-layout/assignments-manager-layout.component';
import { StudentManagerComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-manager/student-manager.component';
import { ViewAssessmentsDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-manager/view-assessments-dialog/view-assessments-dialog.component';
import { TranscriptsManagerComponent } from './ui/instructor/transcripts-manager/transcripts-manager.component';
import { QuizManagerComponent } from './ui/admin/quiz-manager/quiz-manager.component';
import { InstructorDashboardComponent } from './ui/instructor/instructor-dashboard/instructor-dashboard.component';
import { CourseInstructorsComponent } from './ui/user/course/course-instructors/course-instructors.component';
import { InstructorsComponent } from './ui/user/instructors/instructors.component';
import { CategoryManagerComponent } from './ui/admin/category-manager/category-manager.component';
import { AircraftComponent } from './ui/admin/aircraft-manager/aircraft/aircraft.component';
import { AddAircraftComponent } from './ui/admin/aircraft-manager/add-aircraft/add-aircraft.component';
import { ManageAircraftComponent } from './ui/admin/aircraft-manager/manage-aircraft/manage-aircraft.component';
import { AddContentComponent } from './ui/admin/content-manager/add-content/add-content.component';
import { ManageContentComponent } from './ui/admin/content-manager/manage-content/manage-content.component';
import { ContentManagerOverviewComponent } from './ui/admin/content-manager/content-manager-overview/content-manager-overview.component';
import { TaskManagerComponent } from './ui/admin/task-manager/task-manager.component';
import { AddTaskComponent } from './ui/admin/task-manager/add-task/add-task.component';
import { ManageTaskComponent } from './ui/admin/task-manager/manage-task/manage-task.component';
import { TaskManagerOverviewComponent } from './ui/admin/task-manager/task-manager-overview/task-manager-overview.component';
import { AddContentDialogComponent } from './ui/admin/content-manager/add-content/add-content-dialog/add-content-dialog.component';
import { AddBadgeComponent } from './ui/admin/badges-manager/add-badge/add-badge.component';
import { BadgesManagerOverviewComponent } from './ui/admin/badges-manager/badges-manager-overview/badges-manager-overview.component';
import { InstructorInfoDialogComponent } from './ui/user/course/course-instructors/instructor-info-dialog/instructor-info-dialog.component';
// NGRX
import { StoreModule } from '@ngrx/store';
import { MappingPipe } from './pipes/mapping.pipe';
import { BadgesDialogComponent } from './ui/user/badges-certs/badges/badges-dialog/badges-dialog.component';
import { HelpComponent } from './ui/user/help/help.component';
import { CourseDashboardComponent } from './ui/admin/course-manager/course-dashboard/course-dashboard.component';
import { ClassroomDashboardComponent } from './ui/instructor/classroom-manager/classroom-dashboard/classroom-dashboard.component';
import { DashboardComponent } from './components/shared/dashboard/dashboard.component';
import { AddAvionicsComponent } from './ui/admin/avionics-manager/add-avionics/add-avionics.component';
import { AvionicsComponent } from './ui/admin/avionics-manager/avionics/avionics.component';
import { ManageAvionicsComponent } from './ui/admin/avionics-manager/manage-avionics/manage-avionics.component';
import { LogbookDialogComponent } from './ui/user/course/progress/flight-log/logbook-dialog/logbook-dialog.component';
import { AssessmentDialogComponent } from './ui/user/course/progress/skills-assessment/assessment-dialog/assessment-dialog.component';
import { FlightCardComponent } from './components/shared/flight-card/flight-card.component';
import { AddTaskCategoryComponent } from './ui/admin/category-manager/task-categories/add-task-category/add-task-category.component';
import { ManageTaskCategoryComponent } from './ui/admin/category-manager/task-categories/manage-task-category/manage-task-category.component';
import { CourseCategoriesOverviewComponent } from './ui/admin/category-manager/course-categories/course-categories-overview/course-categories-overview.component';
import { TaskCategoriesOverviewComponent } from './ui/admin/category-manager/task-categories/task-categories-overview/task-categories-overview.component';
import { TaskCategoryService } from '@app/services/data-services/task-category.service';
import { NgxEditorModule } from 'ngx-editor';
import { TooltipModule } from 'ngx-bootstrap';
import { ClassroomService } from '@app/services/data-services/classroom.service';
import { StudentService } from '@app/services/data-services/student.service';
import { StudentAssignmentsComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assignments/student-assignments.component';
import { StudentManagerLayoutComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-manager-layout.component';
import { AddAssignmentDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assignments/add-assignment-dialog/add-assignment-dialog.component';
import { StudentAssessmentsComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments/student-assessments.component';
import { StudentAssessmentsLayoutComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-layout.component';
import { StudentAssessmentsEntryComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-entry/student-assessments-entry.component';
import { StudentAssessmentsEntryDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-entry/student-assessments-entry-dialog/student-assessments-entry-dialog.component';
import { FlightcardContentPlayerComponent } from './components/shared/flight-card/flightcard-content-player/flightcard-content-player.component';
import { FlightcardDialogTemplatesComponent } from './components/shared/flight-card/flightcard-content-player/flightcard-dialog-templates/flightcard-dialog-templates.component';
import { FltComponent } from './components/shared/flight-card/flightcard-content-player/flightcard-dialog-templates/flt/flt.component';
import { GndComponent } from './components/shared/flight-card/flightcard-content-player/flightcard-dialog-templates/gnd/gnd.component';
import { SimComponent } from './components/shared/flight-card/flightcard-content-player/flightcard-dialog-templates/sim/sim.component';
import { HelpVideoDialogComponent } from './ui/user/help/help-video-dialog/help-video-dialog.component';
import { TranscriptsService } from '@app/services/data-services/transcripts.service';
import { StudentAssessmentsViewComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-view/student-assessments-view.component';
import { StudentAssessmentsStandardsDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-entry/student-assessments-standards-dialog/student-assessments-standards-dialog.component';
import { AssignmentsManagerOverviewComponent } from './ui/instructor/classroom-manager/assignments-manager-layout/assignments-manager-overview/assignments-manager-overview.component';
import { AssignmentsManagerAddComponent } from './ui/instructor/classroom-manager/assignments-manager-layout/assignments-manager-add/assignments-manager-add.component';
import { AssignmentsManagerManageComponent } from './ui/instructor/classroom-manager/assignments-manager-layout/assignments-manager-manage/assignments-manager-manage.component';
import { CreateQuestionComponent } from './ui/admin/quiz-manager/create-question/create-question.component';
import { AppAccordionDirective } from './directives/app-accordion.directive';
import { BookshelfManagerOverviewComponent } from './ui/instructor/bookshelf-manager/bookshelf-manager-overview/bookshelf-manager-overview.component';
import { BookshelfAddContentComponent } from './ui/instructor/bookshelf-manager/bookshelf-add-content/bookshelf-add-content.component';
import { BookshelfManageContentComponent } from './ui/instructor/bookshelf-manager/bookshelf-manage-content/bookshelf-manage-content.component';
import { ManageBadgeComponent } from './ui/admin/badges-manager/manage-badge/manage-badge.component';
import { AddCertificateComponent } from './ui/admin/certificates-manager/add-certificate/add-certificate.component';
import { ManageCertificateComponent } from './ui/admin/certificates-manager/manage-certificate/manage-certificate.component';
import { CertificateManagerOverviewComponent } from './ui/admin/certificates-manager/certificate-manager-overview/certificate-manager-overview.component';
import { AddUserComponent } from './ui/admin/user-manager/add-user/add-user.component';
import { ManageUserComponent } from './ui/admin/user-manager/manage-user/manage-user.component';
import { UserManagerOverviewComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-overview.component';
import { StageEditorDialogComponent } from './ui/admin/course-manager/courses/course-editor/stage-editor-dialog/stage-editor-dialog.component';
import { UserManagerDialogComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-dialog/user-manager-dialog.component';
import { UserInstructorConnectTemplateComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-dialog/user-instructor-connect-template/user-instructor-connect-template.component';
import { UserNotifyTemplateComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-dialog/user-notify-template/user-notify-template.component';
import { UserService } from '@app/services/data-services/user.service';
import { NotificationService } from '@app/services/data-services/notification.service';
import { InstructorService } from '@app/services/data-services/instructor.service';
import {
  applicationHttpClientCreator,
  HttpService,
} from '@app/services/http-service.service';
import { HttpServiceInterceptorService } from '@app/services/http-service-interceptor.service';
import { BadgesService } from '@app/services/data-services/badges.service';
import { AssignmentService } from '@app/services/data-services/assignment.service';
import { AddAvionicsDialogComponent } from './ui/admin/aircraft-manager/add-aircraft/add-avionics-dialog/add-avionics-dialog.component';
import { AddTaskCategoryDialogComponent } from './ui/admin/task-manager/add-task/add-task-category-dialog/add-task-category-dialog.component';
import { TaskDialogTemplateComponent } from './ui/admin/content-manager/add-content/add-content-dialog/task-dialog-template/task-dialog-template.component';
import { QuizDialogTemplateComponent } from './ui/admin/content-manager/add-content/add-content-dialog/quiz-dialog-template/quiz-dialog-template.component';
import { ExamDialogTemplateComponent } from './ui/admin/content-manager/add-content/add-content-dialog/exam-dialog-template/exam-dialog-template.component';
import { InstructorManagerComponent } from './ui/admin/user-manager/instructor-manager/instructor-manager.component';
import { InstructorManagerDialogComponent } from './ui/admin/user-manager/instructor-manager/instructor-manager-dialog/instructor-manager-dialog.component';
import { AddFaqCategoryComponent } from './ui/admin/faq-categories-manager/add-faq-category/add-faq-category.component';
import { FaqCategoryOverviewComponent } from './ui/admin/faq-categories-manager/faq-category-overview/faq-category-overview.component';
import { ManageFaqCategoryComponent } from './ui/admin/faq-categories-manager/manage-faq-category/manage-faq-category.component';
import { FaqOverviewComponent } from './ui/admin/faq-manager/faq-overview/faq-overview.component';
import { AddFaqComponent } from './ui/admin/faq-manager/add-faq/add-faq.component';
import { ManageFaqComponent } from './ui/admin/faq-manager/manage-faq/manage-faq.component';
import { FaqCategoryService } from '@app/services/data-services/faq-category.service';
import { FaqService } from '@app/services/data-services/faq.service';
import { AddFaqCategoryDialogComponent } from './ui/admin/faq-manager/add-faq/add-faq-category-dialog/add-faq-category-dialog.component';
import { AppUploaderPreviewDirective } from './directives/app-uploader-preview.directive';
import { ProgressService } from '@app/services/data-services/progress.service';
import { CommentService } from '@app/services/data-services/comment.service';
import { BookshelfService } from '@app/services/data-services/bookshelf.service';
import { LessonFlightLogService } from '@app/services/data-services/lesson-flight-log.service';
import { ProgressCalculatorService } from '@app/services/helper/progress-calculator.service';
import { ErrorPageComponent } from './ui/user/error-page/error-page.component';
import { PurchaseService } from '@app/services/data-services/purchase.service';
import { UserCourseAgreementDialogComponent } from './ui/user/my-courses/user-course-agreement-dialog/user-course-agreement-dialog.component';
import { UserAgreementReviewDialogComponent } from './ui/user/course/overview/user-agreement-review-dialog/user-agreement-review-dialog.component';
import { AdminGuard } from './guards/admin.guard';
import { InstructorGuard } from './guards/instructor.guard';
import { UserGuard } from './guards/user.guard';
import { CTCGuard } from './guards/ctc.guard';
import { EnrollUserCourseTemplateComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-dialog/enroll-user-course-template/enroll-user-course-template.component';
import { ShortTextPipe } from './pipes/short-text.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { EmailPipe } from './pipes/email.pipe';
import { NameToInitialsPipe } from './pipes/name-to-initials.pipe';
import { NotificationsInstructorManagerComponent } from './ui/instructor/notifications-instructor-manager/notifications-instructor-manager.component';
import { NotificationsInstructorDialogComponent } from './ui/instructor/notifications-instructor-manager/notifications-instructor-dialog/notifications-instructor-dialog.component';
import { NotificationsAdminManagerComponent } from './ui/admin/notifications-admin-manager/notifications-admin-manager.component';
import { NotificationAdminDialogComponent } from './ui/admin/notifications-admin-manager/notification-admin-dialog/notification-admin-dialog.component';
import { NotificationMessageDialogComponent } from './ui/admin/notifications-admin-manager/notification-message-dialog/notification-message-dialog.component';
import { ReportsService } from '@app/services/data-services/reports.service';
import { AdminReportsDialogComponent } from './ui/admin/admin-reports-manager/admin-reports-dialog/admin-reports-dialog.component';
import { AdminReportsDialogCourseTemplateComponent } from './ui/admin/admin-reports-manager/admin-reports-dialog/admin-reports-dialog-course-template/admin-reports-dialog-course-template.component';
import { AdminReportDialogStudentsTemplateComponent } from './ui/admin/admin-reports-manager/admin-reports-dialog/admin-report-dialog-students-template/admin-report-dialog-students-template.component';
import { SelectCourseDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assignments/select-course-dialog/select-course-dialog.component';
import { SelectStageDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assignments/select-stage-dialog/select-stage-dialog.component';
import { SelectLessonDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assignments/select-lesson-dialog/select-lesson-dialog.component';
import { SelectAssignmentDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assignments/select-assignment-dialog/select-assignment-dialog.component';
import { TinCanComponent } from './ui/user/course/workbook/content-player/content-templates/tin-can/tin-can.component';
import { CommentModeratingComponent } from './ui/admin/comment-moderating/comment-moderating.component';
import { AdminReportsUserDialogComponent } from './ui/admin/admin-reports-manager/admin-reports-dialog/admin-reports-user-dialog/admin-reports-user-dialog.component';
import { PdfService } from '@app/services/helper/pdf.service';
import { OrderModule } from 'ngx-order-pipe';
import { AddDeferReasonComponent } from './ui/admin/task-manager/add-defer-reason/add-defer-reason.component';
import { ManageDeferReasonComponent } from './ui/admin/task-manager/manage-defer-reason/manage-defer-reason.component';
import { DeferReasonOverviewComponent } from './ui/admin/task-manager/defer-reason-overview/defer-reason-overview.component';
import { DeferReasonService } from '@app/services/data-services/defer-reason.service';
import { EffectsModule } from '@ngrx/effects';
import { CheckoutEffects } from './ui/user/checkout/store/checkout.effects';
import { reducers } from './store/app.reducers';
import { OrderService } from '@app/services/data-services/order.service';
import { FullstoryService } from '@app/services/data-services/fullstory.service';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserShoppingCartComponent } from './ui/user/checkout/user-shopping-cart/user-shopping-cart.component';
import { CheckoutComponent } from './ui/user/checkout/checkout/checkout.component';
import { UserOrderHistoryComponent } from './ui/admin/user-order-history/user-order-history.component';
import { UserOrderComponent } from './ui/admin/user-order-history/user-order/user-order.component';
import { ReceiptComponent } from './ui/user/checkout/receipt/receipt.component';
import { RecentPurchasesComponent } from './ui/user/checkout/recent-purchases/recent-purchases.component';
import { PurchaseHistoryComponent } from './ui/user/purchase-history/purchase-history.component';
import { TranscriptCourseDetailComponent } from './ui/instructor/transcripts-manager/transcript-course-detail/transcript-course-detail.component';
import { LoadingSpinnerComponent } from './components/shared/loading-spinner/loading-spinner.component';
import { TaskDefermentService } from '@app/services/data-services/task-deferment.service';
import { StudentUserCoursesComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-user-courses/student-user-courses.component';
import { StudentAssessmentsViewLogbookDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-entry/student-assessments-view-logbook-dialog/student-assessments-view-logbook-dialog.component';
import { StudentAssessmentsTaskDetailComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-entry/student-assessments-task-detail/student-assessments-task-detail.component';
import { AssessmentEffects } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/store/assessment.effects';
import { StudentAssessmentsApproachesDialogComponent } from './ui/instructor/classroom-manager/student-manager-layout/student-assessments-layout/student-assessments-entry/student-assessments-approaches-dialog/student-assessments-approaches-dialog.component';
import { EditInputComponent } from '@app/components/shared/edit-input/edit-input.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { EmbarkJobDialogComponent } from './components/shared/embark-job-dialog/embark-job-dialog.component';
import { FlightOpsEventDialogComponent } from './components/shared/flight-ops-event-dialog/flight-ops-event-dialog.component';
import { LessonService } from '@app/services/data-services/lesson.service';
import { RoutePassthroughComponent } from '@app/components/shared/route-passthrough/route-passthrough.component';
import { VersionDialogComponent } from '@app/components/shared/version-dialog/version-dialog.component';
import { GeneralDialogComponent } from '@app/components/shared/general-dialog/general-dialog.component';
import { GeneralTableDialogComponent } from '@app/components/shared/general-table-dialog/general-table-dialog.component';
import { UserUpdateSalesforceidTemplateComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-dialog/user-update-salesforceid-template/user-update-salesforceid-template.component';
import { UserPromoteAdminTemplateComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-dialog/user-promote-admin-template/user-promote-admin-template.component';
import { ConfigurationManagerComponent } from 'app/ui/admin/configuration-manager/configuration-manager.component';
import { ConfigurationService } from '@app/services/data-services/configuration.service';
import { UserRevokeAdminTemplateComponent } from './ui/admin/user-manager/user-manager-overview/user-manager-dialog/user-revoke-admin-template/user-revoke-admin-template.component';
import { TranscriptEditCertComponent } from './ui/instructor/transcripts-manager/transcript-edit-cert/transcript-edit-cert.component';
import { UserCertificateService } from '@app/services/data-services/user-certificate.service';
import { EditInput2Component } from '@app/components/shared/edit-input2/edit-input2.component';
import { CourseAttemptService } from './services/data-services/course-attempt.service';
import { SpacesPipe } from './spaces.pipe';
import { EnrollmentHistoryComponent } from './ui/instructor/enrollment-history/enrollment-history.component';
import { WysiwygEditorComponent } from './editor/wysiwyg-editor/wysiwyg-editor.component';
import { UploaderComponent } from './editor/wysiwyg-editor/uploader/uploader.component';
import { TrainingPartnersComponent } from './ui/training-partners/training-partners.component';
import { SearchComponent } from './ui/training-partners/search/search.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FeatureDirective } from './ui/feature.directive';
import { SidenavLinkComponent } from './components/layouts/sidenav/sidenav-link/sidenav-link.component';
import { TrainingPartnersTableComponent } from './ui/training-partners/training-partners-table/training-partners-table.component';
import { ApiV4Interceptor } from './api-v4-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiCsrfInterceptor } from './api-csrf.interceptor';
import { TrainingCenterInvitationComponent } from '@app/components/notifications/training-center-invitation/training-center-invitation.component';
import { ManageConnectionsDialogComponent } from './components/training-centers/connections/manage-connections-dialog/manage-connections-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { LayoutModule } from '@angular/cdk/layout';

import { CtcDashboardComponent } from './ui/ctc/ctc-dashboard/ctc-dashboard.component';
import { LegacyDirective } from './ui/legacy.directive';
import { NoOpComponent } from './no-op/no-op.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TypeModalComponent } from './type-modal/type-modal.component';
import { MatMenuComponent } from './components/shared/mat-menu/mat-menu.component';
import { ContentSectionComponent } from './ui/admin/lesson-manager/lesson-editor/content-section/content-section.component';
import { ContentSectionItemComponent } from './ui/admin/lesson-manager/lesson-editor/content-section-item/content-section-item.component';
import { FormatCompletionPercentagePipe } from './ui/user/my-courses/pipes/format-completion-percentage.pipe';
import { RetiredRouteRedirectComponent } from './retired-route-redirect/retired-route-redirect.component';
import { PdfV4Service } from './pdf-v4-service';
import { AuthConfigModule } from './auth/auth-config.module';
import { AuthenticatedComponent } from './authenticated/authenticated.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AdminTopbarComponent,
    NavigationComponent,
    NotificationsComponent,
    LoginComponent,
    MyCoursesComponent,
    TrainingRecordsComponent,
    InstructorsComponent,
    CourseInstructorsComponent,
    BadgesComponent,
    ContentLibraryComponent,
    UserContentLibraryComponent,
    CourseComponent,
    LessonOverviewComponent,
    LessonEditorComponent,
    MyLearningComponent,
    OverviewComponent,
    WorkbookComponent,
    ProgressComponent,
    ContentPlayerComponent,
    ProgressSummaryComponent,
    SkillsAssessmentComponent,
    FlightLogComponent,
    BadgesAchievementsComponent,
    CertificatesComponent,
    UserLayoutComponent,
    CtcLayoutComponent,
    UserSidenavComponent,
    UserTopbarComponent,
    UserTabbarComponent,
    FooterComponent,
    SlideshowComponent,
    HeroImageComponent,
    SidenavComponent,
    FilterPipe,
    InteractiveComponent,
    VideoComponent,
    FlightAssessmentComponent,
    QuizComponent,
    ScormComponent,
    ImageComponent,
    DocumentComponent,
    ProgressSpinnerComponent,
    SortPipe,
    CourseCatalogComponent,
    ShoppingCartComponent,
    AppsMenuComponent,
    SnackbarComponent,
    CommentsComponent,
    BadgeComponent,
    BadgesCertsComponent,
    CertsDialogComponent,
    InstructorLayoutComponent,
    AssignmentsManagerLayoutComponent,
    StudentManagerComponent,
    ViewAssessmentsDialogComponent,
    TranscriptsManagerComponent,
    TranscriptEditCertComponent,
    QuizManagerComponent,
    InstructorDashboardComponent,
    UserManagerComponent,
    GamificationManagerComponent,
    AdminDashboardComponent,
    CourseEditorComponent,
    CoursesComponent,
    AddCourseCategoryComponent,
    ManageCourseCategoryComponent,
    ClassroomComponent,
    BreadcrumbComponent,
    QuizzesComponent,
    CreateQuizComponent,
    QuestionBankComponent,
    CreateQuizDialogComponent,
    SlideshowComponent,
    AdminReportsManagerComponent,
    AdminReportCourseDetailComponent,
    ContentSlideshowComponent,
    CategoryManagerComponent,
    AircraftComponent,
    AddAircraftComponent,
    ManageAircraftComponent,
    AddContentComponent,
    ManageContentComponent,
    ContentManagerOverviewComponent,
    AddBadgeComponent,
    BadgesManagerOverviewComponent,
    KeysPipe,
    TaskManagerComponent,
    AddTaskComponent,
    ManageTaskComponent,
    TaskManagerOverviewComponent,
    AddContentDialogComponent,
    MappingPipe,
    BadgesDialogComponent,
    HelpComponent,
    CourseDashboardComponent,
    ClassroomDashboardComponent,
    DashboardComponent,
    AddAvionicsComponent,
    AvionicsComponent,
    ManageAvionicsComponent,
    LogbookDialogComponent,
    AssessmentDialogComponent,
    FlightCardComponent,
    AddAircraftComponent,
    AddTaskCategoryComponent,
    ManageTaskCategoryComponent,
    CourseCategoriesOverviewComponent,
    TaskCategoriesOverviewComponent,
    StudentAssignmentsComponent,
    StudentManagerLayoutComponent,
    AddAssignmentDialogComponent,
    StudentAssessmentsComponent,
    StudentAssessmentsLayoutComponent,
    StudentAssessmentsEntryComponent,
    StudentAssessmentsEntryDialogComponent,
    FlightcardContentPlayerComponent,
    FlightcardDialogTemplatesComponent,
    FltComponent,
    GndComponent,
    SimComponent,
    HelpVideoDialogComponent,
    StudentAssessmentsViewComponent,
    StudentAssessmentsStandardsDialogComponent,
    AssignmentsManagerOverviewComponent,
    AssignmentsManagerAddComponent,
    AssignmentsManagerManageComponent,
    CreateQuestionComponent,
    AppAccordionDirective,
    BookshelfManagerOverviewComponent,
    BookshelfAddContentComponent,
    BookshelfManageContentComponent,
    ManageBadgeComponent,
    AddCertificateComponent,
    ManageCertificateComponent,
    CertificateManagerOverviewComponent,
    AddUserComponent,
    ManageUserComponent,
    UserManagerOverviewComponent,
    StageEditorDialogComponent,
    UserManagerDialogComponent,
    UserInstructorConnectTemplateComponent,
    UserNotifyTemplateComponent,
    AddAvionicsDialogComponent,
    AddTaskCategoryDialogComponent,
    TaskDialogTemplateComponent,
    QuizDialogTemplateComponent,
    ExamDialogTemplateComponent,
    InstructorManagerComponent,
    InstructorManagerDialogComponent,
    AddFaqCategoryComponent,
    FaqCategoryOverviewComponent,
    ManageFaqCategoryComponent,
    FaqOverviewComponent,
    AddFaqComponent,
    ManageFaqComponent,
    AddFaqCategoryDialogComponent,
    AppUploaderPreviewDirective,
    ErrorPageComponent,
    UserCourseAgreementDialogComponent,
    UserAgreementReviewDialogComponent,
    EnrollUserCourseTemplateComponent,
    UserUpdateSalesforceidTemplateComponent,
    ShortTextPipe,
    TruncatePipe,
    EmailPipe,
    NameToInitialsPipe,
    NotificationsAdminManagerComponent,
    NotificationAdminDialogComponent,
    NotificationsInstructorManagerComponent,
    NotificationsInstructorDialogComponent,
    NotificationMessageDialogComponent,
    AdminReportsDialogComponent,
    AdminReportsDialogCourseTemplateComponent,
    AdminReportDialogStudentsTemplateComponent,
    SelectCourseDialogComponent,
    SelectStageDialogComponent,
    SelectLessonDialogComponent,
    SelectAssignmentDialogComponent,
    TinCanComponent,
    CommentModeratingComponent,
    AdminReportsUserDialogComponent,
    TimeAgoPipe,
    AddDeferReasonComponent,
    ManageDeferReasonComponent,
    DeferReasonOverviewComponent,
    UserShoppingCartComponent,
    PurchaseHistoryComponent,
    CheckoutComponent,
    ReceiptComponent,
    RecentPurchasesComponent,
    UserOrderHistoryComponent,
    UserOrderComponent,
    TranscriptCourseDetailComponent,
    LoadingSpinnerComponent,
    StudentUserCoursesComponent,
    StudentAssessmentsViewLogbookDialogComponent,
    StudentAssessmentsTaskDetailComponent,
    StudentAssessmentsApproachesDialogComponent,
    EditInputComponent,
    AutofocusDirective,
    EmbarkJobDialogComponent,
    FlightOpsEventDialogComponent,
    RoutePassthroughComponent,
    VersionDialogComponent,
    KonamiCodeDirective,
    GeneralDialogComponent,
    GeneralTableDialogComponent,
    UserUpdateSalesforceidTemplateComponent,
    UserPromoteAdminTemplateComponent,
    UserRevokeAdminTemplateComponent,
    ConfigurationManagerComponent,
    EditInput2Component,
    SpacesPipe,
    EnrollmentHistoryComponent,
    InstructorInfoDialogComponent,
    WysiwygEditorComponent,
    UploaderComponent,
    TrainingPartnersComponent,
    SearchComponent,
    FeatureDirective,
    SidenavLinkComponent,
    TrainingPartnersTableComponent,
    TrainingCenterInvitationComponent,
    ManageConnectionsDialogComponent,
    CtcDashboardComponent,
    LegacyDirective,
    NoOpComponent,
    TypeModalComponent,
    MatMenuComponent,
    ContentSectionComponent,
    ContentSectionItemComponent,
    FormatCompletionPercentagePipe,
    RetiredRouteRedirectComponent,
    AuthenticatedComponent,
    SignOutComponent,
    AuthenticateComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    MatBadgeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatCardModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    MatDatepickerModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatOptionModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSortModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxDatatableModule,
    CalendarModule,
    CalendarCommonModule,
    DragulaModule.forRoot(),
    FileUploadModule,
    NgxEditorModule,
    TooltipModule.forRoot(),
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([CheckoutEffects, AssessmentEffects]),
    OrderModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    MatTableModule,
    MatPaginatorModule,
    LayoutModule,
    DragDropModule,
    AuthConfigModule,
  ],
  providers: [
    UserService,
    ConfigurationService,
    NotificationService,
    InstructorService,
    NavigationService,
    GlobalEventManagerService,
    CourseWorkbookService,
    CertificatesService,
    TempDataServiceService,
    RoutePartsService,
    QuestionService,
    QuizService,
    ExamService,
    ProgressService,
    CourseCategoryService,
    AvionicsService,
    AircraftService,
    CourseService,
    CoursesServiceV4,
    LessonService,
    ContentService,
    MediaServerService,
    CommentService,
    TaskService,
    TaskCategoryService,
    ClassroomService,
    StudentService,
    TranscriptsService,
    BadgesService,
    CertificatesService,
    AssignmentService,
    FaqCategoryService,
    FaqService,
    BookshelfService,
    LessonFlightLogService,
    ProgressCalculatorService,
    PurchaseService,
    AdminGuard,
    InstructorGuard,
    CTCGuard,
    UserGuard,
    ReportsService,
    PdfService,
    DeferReasonService,
    OrderService,
    FullstoryService,
    TaskDefermentService,
    UserCertificateService,
    CourseAttemptService,
    PdfV4Service,
    CookieService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    {
      provide: HttpService,
      useFactory: applicationHttpClientCreator,
      deps: [HttpClient],
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2000,
        horizontalPosition: 'left',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiCsrfInterceptor,
      multi: true,
    },
    {
      provide: 'APIV4BaseUrl',
      useValue: `${environment.api_domain}/api/v4`,
    },
    {
      provide: 'APIV5BaseUrl',
      useValue: `${environment.api_domain}/api/v5`,
    },
    {
      provide: 'RichTextImageUploadUrl',
      useValue: `${environment.api_domain}/api/v3/images/upload/rich_text`,
    },
    {
      provide: 'LocationToken',
      useValue: window.location,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
