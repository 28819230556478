import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss']
})
export class EditInputComponent implements OnInit {
  @Input() embarkJobID: string;
  @Output() focusOut: EventEmitter<string> = new EventEmitter<string>();
  newEmbarkID: string;
  editMode = false;
  constructor() { }

  ngOnInit() {
    this.newEmbarkID = this.embarkJobID;
  }
  cancelEdit() {
    this.newEmbarkID = this.embarkJobID;
    this.editMode = false;
  }
  saveEdit() {
    this.editMode = false;
    console.log(this.newEmbarkID);
    if(this.newEmbarkID == "") this.newEmbarkID = null;
    this.focusOut.emit(this.newEmbarkID);
  }
}
