<div fxFlex="100" fxLayout="row">
  <div class="editor-wrapper" fxLayout="row wrap" fxFlex="grow">
    <mat-card class="p-0 pb-1" fxFlex="grow">
      <mat-card-title class="">
        <div class="card-title-text">Manage Content</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form>
          <div class="form-row">
            <label for="title" class="form-label">Title</label>
            <input
              id="title"
              name="title"
              class="form-input"
              [(ngModel)]="content_model.title"
              placeholder="Title"
            />
          </div>
          <div class="form-row">
            <label for="subtitle" class="form-label">Subtitle</label>
            <input
              id="subtitle"
              name="subtitle"
              class="form-input"
              [(ngModel)]="content_model.subtitle"
              placeholder="Subtitle"
            />
          </div>
          <div class="form-row">
            <label for="desc" class="form-label">Description</label>
            <textarea
              id="desc"
              name="desc"
              class="form-textarea"
              [(ngModel)]="content_model.desc"
              placeholder="Description"
            ></textarea>
          </div>
          <div class="form-row">
            <label for="created_by" class="form-label">Created By</label>
            <input
              id="created_by"
              name="created_by"
              class="form-input"
              [(ngModel)]="content_model.created_by"
              placeholder="Created By"
            />
          </div>
          <div class="form-row">
            <label for="placeholder" class="form-label"
              >Placeholder Image
              <span class="message"
                >This image is used for library cards.</span
              ></label
            >
            <div>
              <img
                *ngIf="content_model?.placeholder_image && uploader.value == ''"
                src="{{ content_model.placeholder_image }}"
                width="325"
                height="175"
                alt=""
              />
              <img
                id="content-img"
                #image
                src=""
                alt=""
                width="325"
                height="175"
                hidden
              />
            </div>
            <input
              #uploader
              id="placeholder"
              type="file"
              name="placeholder"
              class="form-input"
              itemid="placeholder"
              appAppUploaderPreview
              [image]="image"
              ng2FileSelect
              [uploader]="imageUploader"
            />
          </div>
          <div class="form-row">
            <label for="meta-tags" class="form-label"
              >Meta Tags
              <span class="meta-tag-info"
                >Type new meta tags and separate by commas or hit enter to add
                tag</span
              ></label
            >
            <mat-form-field id="meta-tags" class="meta-tags-input">
              <mat-chip-list #chipList>
                <mat-chip
                  *ngFor="let tag of content_model?.meta_tags"
                  [removable]="true"
                  (remove)="remove(tag)"
                >
                  {{ tag }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  placeholder=""
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="false"
                  (matChipInputTokenEnd)="add($event)"
                  name="meta-tags"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div class="form-row">
            <label for="type" class="form-label">Content Type</label>
            <div class="selection-wrapper">
              <select
                id="type"
                [(ngModel)]="content_model.content_type"
                name="type"
                class="form-select"
              >
                <option value="-1">Select Type</option>
                <option
                  [value]="type.key"
                  [selected]="
                    type?.key?.toString() == map[content_model?.content_type]
                  "
                  *ngFor="let type of map | keys : 'filter'"
                >
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-row" *ngIf="content_model?.content_type == 5">
            <label for="quiz" class="form-label">Quiz</label>
            <div class="full-width" *ngIf="content_model.quiz">
              <p class="selected-quiz">
                Selected Quiz: {{ content_model?.quiz?.name}}
              </p>
            </div>
            <button
              mat-raised-button
              [color]="'primary'"
              (click)="openDialog('quiz')"
            >
              Add Quiz
            </button>
          </div>
          <div class="form-row" *ngIf="content_model?.content_type == 12">
            <label for="exam" class="form-label">Exam</label>
            <div class="full-width" *ngIf="content_model.exam">
              <p class="selected-exam">
                Selected Exam: {{ content_model?.exam?.name}}
              </p>
            </div>
            <button
              mat-raised-button
              [color]="'primary'"
              (click)="openDialog('exam')"
            >
              Add Exam
            </button>
          </div>
          <!--vimeo = 0,-->
          <!--youtube = 1,-->
          <!--scorm = 2,-->
          <!--tinCan = 3,-->
          <!--pdf = 4,-->
          <!--quiz = 5,-->
          <!--download = 6,-->
          <!--interactive = 7,-->
          <!--richText = 8,-->
          <!--flight_assessment = 9,-->
          <!--ground_assessment = 10-->
          <div
            class="form-row"
            *ngIf="[0, 1].includes(content_model?.content_type)"
          >
            <mat-accordion id="help-video-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a YouTube or Vimeo Video</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>
                        Follow these steps to upload a YouTube or Vimeo video:
                      </p>
                      <small
                        >Note: If you copy the video url directly from your
                        browser, the upload process will not work.</small
                      >
                      <p>Step 1: Find the video you would like to upload.</p>
                      <p>Step 2: Click "Share".</p>
                      <P>Step 3: Copy your video link.</P>
                      <p>Step 4: Paste your link into the input field below.</p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <label for="video" class="form-label">Video Link</label>
            <input
              id="video"
              type="text"
              name="video"
              class="form-input"
              [(ngModel)]="content_model.url"
            />
          </div>
          <div
            class="form-row"
            *ngIf="[2, 3, 4, 6, 7].includes(content_model?.content_type)"
          >
            <label for="scorm" class="form-label">Upload New File</label>
            <input
              class="pb-1"
              #scorm
              id="scorm"
              type="file"
              ng2FileSelect
              [uploader]="fileUploader"
              name="scorm"
            />
            <div fxLayout="row">
              <p class="pr-1">
                Current File: {{ content_model.content_filename }}
              </p>
              <span
                *ngIf="
                  content_model.contentFile &&
                  content_model.contentFile != '' &&
                  !fileUploader.queue.length
                "
                >{{ content_model.contentFile }}</span
              >
            </div>
          </div>
          <div class="form-row">
            <label class="form-label">Display Comments</label>
            <mat-slide-toggle
              id="show-comments"
              name="show-comments"
              [(ngModel)]="content_model.show_comments"
              >{{
                content_model.show_comments ? 'On' : 'Off'
              }}</mat-slide-toggle
            >
          </div>
          <div
            class="form-row"
            *ngIf="[9, 10].includes(content_model?.content_type)"
          >
            <label for="tasks" class="form-label">Tasks</label>
            <div class="form-row">
              <mat-checkbox
                name="course-jet-scoring"
                [(ngModel)]="content_model.jet_scoring"
                >Perfect Scoring</mat-checkbox
              >
            </div>
            <div
              id="contenttaskstasks"
              [dragula]="'task-bag'"
              [(dragulaModel)]="content_model.content_tasks"
            >
              <div
                class="dnd-item"
                *ngFor="
                  let content_task of content_model?.content_tasks;
                  let i = index
                "
              >
                <div
                  class="quiz_question"
                  fxFlex="90"
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                >
                  <mat-icon class="black-text">drag_handle</mat-icon>
                  {{ content_task?.task?.name }}
                  <input
                    [(ngModel)]="content_task.required_successful_attempts"
                    class="form-input"
                    [name]="'rsa' + i"
                    min="1"
                    type="number"
                    placeholder="Required Successful Attempts"
                    (blur)="checkNegAttempt(content_task)"
                  />
                  <mat-button-toggle-group
                    name="task_type"
                    (change)="updateType(i, group.value)"
                    #group="matButtonToggleGroup"
                  >
                    <mat-button-toggle
                      [checked]="
                        content_task?.task_type?.toString() === 'practice'
                      "
                      value="practice"
                      >``` Practice
                    </mat-button-toggle>
                    <mat-button-toggle
                      [checked]="
                        content_task?.task_type?.toString() === 'perform'
                      "
                      value="perform"
                    >
                      Perform
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                  <mat-icon
                    class="black-text"
                    (click)="removeContentTask(content_task)"
                    >close</mat-icon
                  >
                  <!-- TODO: Add back when task badge functionality is needed, Removed to free up space for practice vs perform -->
                  <!--<img *ngIf="content_task?.task?.badge" [src]="content_task?.task?.badge?.badge_image" width="50" height="50" alt="">-->
                  <!--<button mat-raised-button [color]="'primary'" (click)='openDialog("badge", content_task)'>Select Badge</button>-->
                </div>
              </div>
            </div>
            <div class="form-row">
              <button
                mat-raised-button
                [color]="'primary'"
                (click)="openDialog('task')"
              >
                Add Task(s)
              </button>
            </div>
          </div>
          <div class="form-row" *ngIf="content_model?.content_type == 8">
            <label for="content">Content</label>
            <mat-accordion id="help-video-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a YouTube or Vimeo Video</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>
                        Follow these steps to upload a YouTube or Vimeo video:
                      </p>
                      <p>Step 1: Find the video you would like to upload.</p>
                      <p>
                        Step 2: For YouTube, click the share button. Next, click
                        "Embed". For Vimeo, click "Share".
                      </p>
                      <P
                        >Step 3: Copy the all of the code that is generated for
                        you.
                      </P>
                      <p>
                        Step 4: In the text editor below, click the very last
                        button or "View Code".
                      </p>
                      <p>
                        Step 5: If you cannot see the text area, simply click
                        the bottom border and drag down to expand it open. Paste
                        the code you copied from YouTube or Vimeo into the text
                        area.
                      </p>
                      <p>
                        Step 6: Click the "View Code" button again to un-toggle.
                      </p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion id="help-image-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload an Image</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>
                        To upload an image, click inside the text editor before
                        attempting to upload or the upload will fail and will
                        display the error: "Range out of the editor"
                      </p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <app-wysiwyg-editor
              editorId="content"
              title="Content"
              displayUploader="true"
              displayEditor="true"
              placeholder="Enter text here..."
              [(text)]="content_model.content_html"
            ></app-wysiwyg-editor>
          </div>
          <div class="form-row" *ngIf="content_model?.content_type == 11">
            <div>
              <img
                *ngIf="
                  content_model?.upload_image &&
                  contentImageUploader.value == ''
                "
                src="{{ content_model.upload_image }}"
                width="325"
                height="175"
                alt=""
              />
              <img
                id="upload-img"
                #imageUpload
                src="#"
                width="325"
                height="175"
                alt=""
                hidden
              />
            </div>
            <input
              #contentImageUploader
              id="image-upload"
              type="file"
              name="image-upload"
              appAppUploaderPreview
              [image]="imageUpload"
              class="form-input"
              itemid="image-upload"
              ng2FileSelect
              [uploader]="imageUploadUploader"
            />
          </div>
        </form>
      </mat-card-content>
      <mat-spinner id="loading-spinner" *ngIf="is_loading"></mat-spinner>
    </mat-card>
  </div>
  <div
    id="submit-bar"
    class="sidebar-panel navigation-hold"
    @slideInRight
    fxFlex="nogrow"
  >
    <button
      class="btn save-btn"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="upload()"
    >
      Save <mat-icon>save</mat-icon>
    </button>
    <button
      class="btn cancel-btn"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      (click)="cancel()"
    >
      Cancel <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
