import { Injectable } from '@angular/core';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import robotoRegular from './roboto-regular';

@Injectable()
export class PdfService {
  image: any;
  data: any;

  constructor() {}

  getBase64Image(image, type?, width?, height?) {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const filetype = type ? type : 'image/png';

    canvas.width = 1650;
    canvas.height = 1275;
    if (width && height) {
      ctx.drawImage(image, 0, 0, width, height);
    } else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    }
    return canvas.toDataURL(filetype, 1);
  }

  download(image, data, callback?) {
    const doc = new jsPDF('l', 'mm', 'a4');

    doc.addFileToVFS('Roboto-Regular-normal.ttf', robotoRegular);
    doc.addFont('Roboto-Regular-normal.ttf', 'Roboto-Regular', 'normal');
    doc.setFont('Roboto-Regular', 'normal');
    console.log(doc.getFontList());

    const width = doc.internal.pageSize.getWidth();
    const height = doc.internal.pageSize.getHeight();

    const imageData = this.getBase64Image(image);
    doc.addImage(imageData, 'PNG', 0, 0, width, height);

    const user_name =
      data.user.contact && data.user.contact.name
        ? data.user.contact.name
        : 'No Contact Info';
    const aircraft_avionics = data.aircraft_name + ' / ' + data.avionics_name;

    let font = 33;
    doc.setFontSize(font);
    doc.text(user_name, 148 - this.getTextWidth(doc, font, user_name) / 2, 112);

    font = 22;
    doc.setFontSize(font);
    doc.text(
      data.course_name,
      148 - this.getTextWidth(doc, font, data.course_name) / 2,
      138
    );

    if (data.aircraft_name && data.avionics_name) {
      font = 12;
      doc.setFontSize(font);
      doc.text(
        aircraft_avionics,
        148 - this.getTextWidth(doc, font, aircraft_avionics) / 2,
        166
      );
    }

    const date = new Date(data.created_at);
    doc.text(date.toLocaleDateString('en-US'), 220, 166);

    doc.setFontSize(10);
    doc.text(`Cert: ${data.serial}`, 227, 194);

    doc.save('Certificate.pdf');
    if (callback) {
      callback();
    }
  }

  getTextWidth(doc, fontSize, text) {
    return ((doc.getStringUnitWidth(text) * fontSize) / 72) * 25.4;
  }
  createPdf(container, height, user_name, image, callback) {
    // const doc = new jsPDF('p', 'px', [1200, height]);
    const doc = new jsPDF('p', 'px');
    console.log(container);
    this.htmlToPdf(container, 0, 0, user_name, image, doc, callback);

    // return Promise.resolve();
  }

  htmlToPdf(container, index, currentY, user_name, image, doc, callback) {
    html2canvas(container).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/jpeg');

      const imgWidth = 440;
      const pageHeight = 625;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      // doc.addImage(contentDataURL, 'jpeg', 20, currentY, doc.internal.pageSize.getWidth() - 40, containers[index].scrollHeight);
      doc.addImage(contentDataURL, 'jpeg', 0, position, imgWidth, imgHeight);

      let name = '';
      if (user_name) {
        name = user_name.split(' ').join('_');
      }
      const date = new Date();
      const file_name = `course_detail_${name.toLowerCase()}_${
        date.getMonth() + 1
      }_${date.getDate()}_${date.getFullYear()}.pdf`;

      let badgeImageDataUrl = null;
      if (
        image.complete &&
        typeof image.naturalWidth !== 'undefined' &&
        image.naturalWidth > 0
      ) {
        badgeImageDataUrl = this.getBase64Image(image, 'image/png');
      }

      if (badgeImageDataUrl) {
        doc.addImage(badgeImageDataUrl, 'jpeg', 5, 8, 27, 30);
      }

      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      doc.save(file_name);
      if (callback) {
        callback();
      }
    });
  }
}
