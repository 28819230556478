import { CoursesServiceV4 } from '@app/courses-v4.service';
import { Component, OnInit } from '@angular/core';
import { GlobalEventManagerService } from '@app/services/global-event-manager/global-event-manager.service';
import { Course, MyCourse } from '@app/models/course/course';
import * as fromApp from '../../../store/app.reducers';
import * as CourseActions from '../course/store/course.actions';
import { Store } from '@ngrx/store';
import { CourseService } from '@app/services/data-services/course.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Avionics } from '@app/models/aircraft/avionics';
import { CourseCategory } from '@app/models/course/course-category';
import { Aircraft } from '@app/models/aircraft/aircraft';
import { CourseCategoryService } from '@app/services/data-services/course-category.service';
import { AircraftService } from '@app/services/data-services/aircraft.service';
import { AvionicsService } from '@app/services/data-services/avionics.service';
import { ProgressService } from '@app/services/data-services/progress.service';
import { UserProgress } from '@app/models/user/user-progress';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserCourseAgreementDialogComponent } from './user-course-agreement-dialog/user-course-agreement-dialog.component';
import { ProgressCalculatorService } from '@app/services/helper/progress-calculator.service';
import { UserCourse } from '@app/models/course/user-course';
import { environment } from '../../../../environments/environment';
import { GeneralDialogComponent } from '@app/components/shared/general-dialog/general-dialog.component';
import { calculateProgressPercentage } from './helper/my-courses-helper';

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.scss'],
})
export class MyCoursesComponent implements OnInit {
  currentMax = 8;
  courses: any;
  filteredCourses: any;
  sortFilter = 0;

  archivedCourses: any;

  courseFilter: FormControl;

  avionicsFilter: FormControl;
  categoryFilter: FormControl;
  aircraftFilter: FormControl;
  trainingFilter: FormControl;

  selectedAvionic: Avionics;
  selectedCategory: CourseCategory;
  selectedCourse: Course;
  selectedAircraft: Aircraft;
  filter = '';

  courseCategories: CourseCategory[];
  aircrafts: Aircraft[];
  avionics: Avionics[];
  progresses: UserProgress[] = [];
  isLoading = false;
  rows: any[] = [];
  isKonamiEntered: boolean = false;
  archiveLabel: string = 'Archive';
  unarchiveLabel: string = 'Unarchive';
  archiveFilterLabel: string = 'Archived';
  defaultCourseThumb = environment.defaultThumbnailCourse;

  constructor(
    private globalEventManagerService: GlobalEventManagerService,
    private courseService: CourseService,
    private store: Store<fromApp.AppState>,
    private categoryService: CourseCategoryService,
    private avionicService: AvionicsService,
    public dialog: MatDialog,
    public snackbar: MatSnackBar,
    private calculatorService: ProgressCalculatorService,
    private aircraftService: AircraftService,
    private progressService: ProgressService,
    private router: Router,
    private coursesServiceV4: CoursesServiceV4
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.globalEventManagerService.topBar.emit(true);
    const user = JSON.parse(localStorage.getItem('cirrus-user'));
    const user_id = user.id;
    this.store.dispatch(new CourseActions.SetPreview(false));

    this.avionicsFilter = new FormControl('all');
    this.trainingFilter = new FormControl('all');
    this.aircraftFilter = new FormControl('all');
    this.categoryFilter = new FormControl('all');
    this.courseFilter = new FormControl('all');

    Promise.all([
      this.coursesServiceV4.getMyCourses(),
      this.avionicService.getAvionics(),
      this.aircraftService.getAircraft(),
      this.categoryService.getCategories(),
    ]).then(([userCourses, avionics, aircraft, categories]) => {
      this.courses = userCourses;
      this.avionics = avionics;
      this.aircrafts = aircraft;
      this.courseCategories = categories;
      this.isLoading = false;
      this.filteredCourses = userCourses;
    });
  }

  public show(event: boolean): void {
    if (event) {
      this.archiveLabel = 'Kramkoob';
      this.unarchiveLabel = 'Kramkoobnu';
      this.archiveFilterLabel = 'Dekramkoob';
      this.snackbar.open('Oh hey.', '', {
        duration: 3000,
      });
    }
  }

  public filterChanged() {
    this.filteredCourses = this.filterCourses();
  }

  showMore() {
    this.currentMax += 8;
  }

  filterCourses() {
    return this.courses
      .filter(row => {
        if (this.aircraftFilter.value === 'all') {
          return true;
        }
        return (
          row.aircrafts.filter(
            aircraft => aircraft.id === this.aircraftFilter.value
          ).length !== 0
        );
      })
      .filter(row => {
        if (this.avionicsFilter.value === 'all') {
          return true;
        }
        return (
          row.avionics.filter(
            avionic => avionic.id === this.avionicsFilter.value
          ).length !== 0
        );
      })
      .filter(row => {
        if (this.trainingFilter.value === 'all') {
          return true;
        }

        return (
          row.course_categories.filter(c => c.id === this.trainingFilter.value)
            .length > 0
        );
      })
      .filter(row => {
        if (this.filter.trim() === '') {
          return true;
        }
        if (row) {
          return (
            row.title
              .toLowerCase()
              .indexOf(this.filter.toLowerCase().trim()) !== -1
          );
        } else {
          return false;
        }
      })
      .filter(row => {
        if (this.courseFilter.value === 'archived') {
          return row.archived === true;
        }
        return row.archived === false;
      });
  }

  selectCourse(selected_user_course: MyCourse, url?: string) {
    this.store.dispatch(new CourseActions.HasAgreed());
    this.courseService
      .getUserCourse(selected_user_course.user_course_id)
      .then(user_course => {
        this.store.dispatch(new CourseActions.SelectCourse(user_course));
        this.store.dispatch(new CourseActions.SetPreview(false));
        if (url) {
          this.router.navigate([url]);
        } else {
          this.router.navigate(['/courses', selected_user_course.id]);
        }
      });
  }

  gridSize() {
    return window.matchMedia(`(max-width: 1300px)`).matches
      ? window.matchMedia(`(max-width: 950px)`).matches
        ? window.matchMedia(`(max-width: 540px)`).matches
          ? 1
          : 2
        : 3
      : 4;
  }

  archiveCourse(course: any) {
    course.archived = !course.archived;

    this.courseService.updateUserCourse(course).then(() => {
      course.archived
        ? this.snackbar.open('Course archived')
        : this.snackbar.open('Course unarchived');
    });

    this.filterChanged();
  }

  setCourseMenu(course: any) {
    this.selectedCourse = course;
  }

  reenrollCourse(userCourse: UserCourse) {
    const dialogRef = this.dialog.open(GeneralDialogComponent, {
      width: '80%',
      maxWidth: '500px',
      data: {
        dialogTitle: 'Confirm Re-enroll',
        dialogBodyContent:
          'Upon completion, you may take the course again without losing or resetting your previous course enrollment records and certificate.',
        showCancelButton: true,
        dialogCancelButtonLabel: 'Cancel',
        dialogConfirmButtonLabel: 'Confirm',
      },
    });

    dialogRef.afterClosed().subscribe(confirmReenroll => {
      if (confirmReenroll === true) {
        this.courseService.reenrollUserToCourse(
          userCourse.id,
          userCourse.user_id
        );
      }
    });
  }

  openUserAgreementDialog(course, url?: string) {
    const dialogRef = this.dialog.open(UserCourseAgreementDialogComponent, {
      width: '80%',
      maxWidth: '500px',
      data: course,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.agreed) {
        if (url) {
          this.selectCourse(course, url);
        } else {
          this.selectCourse(course);
        }
      }
    });
  }
}
