import { Component, OnInit } from '@angular/core';
import { slideInRight } from '@app/animations/animations';
import { Router } from '@angular/router';
import { FaqCategoryService } from '@app/services/data-services/faq-category.service';
import { FaqCategory } from '@app/models/faq-category';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-faq-category',
  templateUrl: './add-faq-category.component.html',
  styleUrls: ['./add-faq-category.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddFaqCategoryComponent implements OnInit {
  faq_category: FaqCategory;
  rows: FaqCategory[];

  constructor(private faqCategoryService: FaqCategoryService, 
              private router: Router,
              public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.faq_category = new FaqCategory();
  }

  addCategory() {

    this.faqCategoryService.create(this.faq_category)
      .then(() => {
        this.snackbar.open('Updated')
        this.faq_category = new FaqCategory()
      });
  }

  cancel() {
    this.router.navigate(['admin/faq-category']);
  }

}
