import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {InstructorService} from '@app/services/data-services/instructor.service';
import {UserCourse} from '@app/models/course/user-course';
import {LocationStrategy} from '@angular/common';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import * as AssessmentActions from '../student-assessments-layout/store/assessment.actions';
import * as fromApp from '../../../../../store/app.reducers';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-student-user-courses',
  templateUrl: './student-user-courses.component.html',
  styleUrls: ['./student-user-courses.component.scss']
})
export class StudentUserCoursesComponent implements OnInit {
  user_id: number = null;
  isLoading = false;
  user_courses: UserCourse[] = [];
  rows: UserCourse[] = [];
  selected: UserCourse[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private activatedRoute: ActivatedRoute,
              private instructorService: InstructorService,
              private location: LocationStrategy,
              private store: Store<fromApp.AppState>,
              private router: Router) { }

  ngOnInit() {
    this.store.dispatch(new AssessmentActions.ClearSelectedCourse());

    const sub = this.activatedRoute.params.subscribe(params => {
      if (params['user_id']) {
        this.user_id = params['user_id'];
        this.isLoading = true;
        this.instructorService.getStudentCourses(params['user_id'])
          .then((user_courses) => {
            this.user_courses = this.rows =  user_courses.filter((user_course) => user_course.is_unenrolled !== true);
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.location.back();
      }
    });

    sub.unsubscribe();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.user_courses.filter(function(userCourse) {
      return !val ||
        userCourse.course && userCourse.course.title.toLowerCase().indexOf(val) !== -1;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  onSelect({selected}) {
    const { course_id, course_attempt_id } = selected[0];
    const route = `/instructor/classroom/student/${this.user_id}/course/${course_id}/course_attempt/${course_attempt_id}/assessments`;
    this.router.navigate([route]);
    this.store.dispatch(new AssessmentActions.SetCourse(selected[0]));
    this.store.dispatch(new AssessmentActions.FetchCourseWorkbook(selected[0]));
    this.store.dispatch(new AssessmentActions.SetCourseCompleted(selected[0].is_completed));
  }

}
