import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuizService} from '@app/services/data-services/quiz.service';
import {Quiz} from '@app/models/course/content/quiz/quiz';
import {QuizQuestion} from '@app/models/course/content/quiz/quiz_question';
import {QuestionAnswer} from '@app/models/course/content/quiz/question_answer';
import {ProgressService} from '@app/services/data-services/progress.service';
import {UserProgress} from '@app/models/user/user-progress';

@Component({
    selector: 'app-quiz',
    templateUrl: './quiz.component.html',
    styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
    @Input('content') content: any;
    @Output() submit: EventEmitter<any> = new EventEmitter();

    data: QuizQuestion[] = [];
    graded = false;
    chosenAnswers: any[] = [];

    constructor(private quizService: QuizService) {

    }

    selectAnswer(questionIndex, answerIndex) {

        this.chosenAnswers[questionIndex] = this.data[questionIndex].quiz_answers[answerIndex];
    }

    selectMultipleChoiceAnswer(questionIndex, answerIndex) {

        if (this.chosenAnswers[questionIndex] == null) {
            this.chosenAnswers[questionIndex] = [];
        }

        const index = this.chosenAnswers[questionIndex].indexOf(this.data[questionIndex].quiz_answers[answerIndex]);
        if (index !== -1) {
            this.chosenAnswers[questionIndex].splice(index, 1);
        } else {
            this.chosenAnswers[questionIndex].push(this.data[questionIndex].quiz_answers[answerIndex]);
        }
    }

    isSelectedChoice(questionIndex, answerIndex) {

        if (this.chosenAnswers[questionIndex] == null) {
            return false;
        }

        return this.chosenAnswers[questionIndex].indexOf(this.data[questionIndex].quiz_answers[answerIndex]) !== -1;
    }

    gradeQuiz() {

        for (const value of this.chosenAnswers) {

            if (value == null) {

                alert('Fill out all the questions');

                return;
            }
        }

        this.graded = true;

        // const answers = this.data.map((question, index, array) => {
        //   let temp = [];
        //   return temp.push(question.quiz_answers.map((quiz_answer, j, arr) => {
        //     if (question.question_type.toString() === 'multiple_choice') {
        //       if (!quiz_answer.isAnswer && this.chosenAnswers[index].indexOf(quiz_answer)) {
        //         return false;
        //       } else if (quiz_answer.isAnswer && !(this.chosenAnswers[index].indexOf(quiz_answer))) {
        //         return false;
        //       }
        //     } else {
        //       if (quiz_answer.isAnswer && !(this.chosenAnswers[index] === quiz_answer)) {
        //         return false;
        //       }
        //     }
        //   }));
        // });

        const answers = this.data.map((question, index, array) => {
            if (question.question_type.toString() === 'single_choice') {
                const i = question.quiz_answers.indexOf(this.chosenAnswers[index]);

                if (i > -1) {
                    return question.quiz_answers[i].isAnswer;
                }
            } else {
                let isCorrect = true;
                this.chosenAnswers[index].forEach(answer => {
                    if (!isCorrect) {
                        return;
                    }
                    const i = question.quiz_answers.indexOf(answer);

                    if (i > -1) {
                        if (question.quiz_answers[i].isAnswer) {
                            isCorrect = true;
                        } else {
                            isCorrect = false;
                        }
                    }
                });

                return isCorrect;
            }
            // return question.quiz_answers.map((quiz_answer, j, arr) => {
            //   // if (question.question_type.toString() === 'multiple_choice') {
            //   //   if (!quiz_answer.isAnswer && this.chosenAnswers[index].indexOf(quiz_answer)) {
            //   //     return false;
            //   //   } else if (quiz_answer.isAnswer && !(this.chosenAnswers[index].indexOf(quiz_answer))) {
            //   //     return false;
            //   //   }
            //   // } else {
            //
            //  });
        });

        // this.progressService.saveProgress(progressToPost).then(progress => {
        //
        // });

        this.submit.emit(answers);
    }

    ngOnInit() {

        this.data = this.content.quiz.quiz_questions;
        this.chosenAnswers = [];
        // this.quizService.getQuiz(this.content.quiz.id).then((quiz: Quiz) => {
        //
        //     this.data = quiz.quiz_questions;
        //
        //     this.chosenAnswers = new Array<null>(quiz.quiz_questions.length);
        //
        // });
    }

}
