<form id="approaches-form" #form>
  <!--(ngSubmit)="submit(form)"-->
  <h1 mat-dialog-title>Add Approaches</h1>
  <mat-dialog-content>
    <div class="form-row">
      <label for="quantity">Quantity</label>
      <div class="float-right">
        {{quantity}}
        <button class="ml-1 blue-border white-background flight-log-button" mat-raised-button (click)="decrease()">
          <mat-icon class="text-muted-white blue-text">remove</mat-icon>
        </button>
        <button class="blue-border white-background flight-log-button" mat-raised-button (click)="increase()" >
          <mat-icon  class="text-muted-white blue-text">add</mat-icon>
        </button>
      </div>
    </div>
    <div class="form-row">
      <label for="aircraft-approach">Approach Type</label>
      <div class="selection-wrapper">
        <select id="aircraft-approach" required name="aircraft-approach" [(ngModel)]="approachType" class="form-select">
            <option *ngFor="let approach of approaches" [value]="approach.value">
              {{ approach.value }}
            </option>
        </select>
      </div>
    </div>
    <div class="form-row">
      <label for="airport">Airport</label>
      <input class="form-input float-right" type="text" id="airport" name="airport" [(ngModel)]="airport" placeholder="Optional">
    </div>
    <div class="form-row">
      <label for="runway">Runway</label>
      <input class="form-input float-right" type="text" id="runway" name="runway" [(ngModel)]="runway" placeholder="Optional">
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div fxFlex></div>
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" type="submit" (click)="save()" [disabled]="form.valid">Add</button>
  </mat-dialog-actions>
</form>

