import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-embark-job-dialog',
  templateUrl: './embark-job-dialog.component.html',
  styleUrls: ['./embark-job-dialog.component.scss']
})
export class EmbarkJobDialogComponent implements OnInit {
  embarkID: string;
  constructor(public dialogRef: MatDialogRef<EmbarkJobDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {}

  close() {
    this.dialogRef.close();
  }

  save() {
    this.dialogRef.close(this.embarkID);
  }
}
