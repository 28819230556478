import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { Content } from '@app/models/course/content/content';
import { ContentComment } from '@app/models/course/content/comment';

@Injectable()
export class CommentService {

  commentUrl = 'content_comments';

  constructor(private http: HttpService) {
  }

  getAllComments(): Promise<ContentComment[]> {
    return this.http.get<ContentComment[]>(`${this.commentUrl}`)
      .toPromise()
      .catch(CommentService.handleError);
  }

  getAllFlaggedComments(): Promise<ContentComment[]> {
    return this.http.get<ContentComment[]>(`${this.commentUrl}/comments/flagged`)
      .toPromise()
      .catch(CommentService.handleError);
  }

  removeComment(comment: ContentComment): Promise<boolean> {
    return this.http.post<boolean>(`${this.commentUrl}/comments/remove/${comment.id}`, null)
      .toPromise()
      .catch(CommentService.handleError);
  }

  getCommentForContent(content: Content): Promise<ContentComment[]> {
    return this.http.get<ContentComment[]>(`${this.commentUrl}/${content.id}`)
      .toPromise()
      .catch(CommentService.handleError);
  }

  postComment(content: ContentComment): Promise<ContentComment> {
    return this.http.post<ContentComment>(`${this.commentUrl}`, JSON.stringify(content))
      .toPromise()
      .catch(CommentService.handleError);
  }


  up(comment): Promise<ContentComment> {
    return this.http.post<ContentComment>(`${this.commentUrl}/vote`, JSON.stringify({
      content_comment_vote_type: 0,
      content_comment_id: comment.id
    }))
      .toPromise()
      .catch(CommentService.handleError);
  }

  down(comment): Promise<ContentComment> {
    return this.http.post<ContentComment>(`${this.commentUrl}/vote`, JSON.stringify({
      content_comment_vote_type: 1,
      content_comment_id: comment.id
    }))
      .toPromise()
      .catch(CommentService.handleError);
  }

  flag(comment): Promise<ContentComment> {
    return this.http.post<ContentComment>(`${this.commentUrl}/flag/${comment.id}`, null)
      .toPromise()
      .catch(CommentService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
