<mat-icon [matMenuTriggerFor]="menu" [ngStyle]="iconStyle">{{
  menuIcon
}}</mat-icon>

<mat-menu #menu="matMenu" xPosition="before" class="menu">
  <div [ngStyle]="menu_style">
    <div *ngFor="let item of inputItems" class="item">
      <div mat-menu-item>
        <div
          [ngClass]="item.action === 'toggle' ? 'toggle-row' : 'item-row'"
          (click)="selectItem($event, item)"
        >
          <div *ngIf="item.icon">
            <mat-icon>{{ item.mat - icon }}</mat-icon>
          </div>
          <div *ngIf="item.image_url">
            <img class="icon" [src]="setImage(item)" alt="" />
          </div>

          <div>
            <div [ngClass]="item.name === 'Clear Content' ? 'remove' : 'name'">
              {{ item.name }}
            </div>
          </div>

          <div
            *ngIf="item.action === 'toggle'"
            (click)="$event.stopPropagation()"
          >
            <mat-slide-toggle
              (change)="selectItem($event, item)"
              [hasBackdrop]="true"
              [checked]="isToggled"
            >
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-menu>
