import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '../../../../services/data-services/course.service';
import {Course} from '@app/models/course/course';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {CourseTypeMap} from '@app/models/course/course-type';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  selected: any[] = [];
  rows: any[] = [];
  temp: any[] = [];
  active: any[] = [];
  inactive: any[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;
  map = CourseTypeMap;
  expanded: any = {};
  isLoading = false;

  constructor(private router: Router, 
              private courseService: CourseService,
              public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.getCourses();
  }

  getCourses(){
    this.isLoading = true;
    this.courseService.getManagerCourses()
      .then((courses) => {
        this.active = courses.filter( (item) => item.is_active == true);
        this.inactive = courses.filter( (item) => item.is_active == false || item.is_active == null);
        this.rows = this.temp = this.active;
        this.isLoading = false;
      })
      .catch(err => this.isLoading = false);
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  filterCourses(type, event) {
    this.rows = this.temp = type;
    const element = event.target;
    let active = document.getElementsByClassName('is-active');
    while (active.length) active[0].classList.remove('is-active');
    element.classList.add('is-active');
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
              d.desc && d.desc.toLowerCase().indexOf(val) !== -1 ||
                d.course_category && d.course_category.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  editCourse(course) {
    const url = `admin/courses/manage/${course.id}`;
    this.router.navigate([url]);
  }

  expandPanel(matExpansionPanel: any, event: Event) {
    event.stopPropagation();

    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.close();
    }
  }

  private _isExpansionIndicator(target: any): boolean {
    const expansionIndicatorClass = 'mat-expansion-indicator';
    return (target.classList && target.classList.contains(expansionIndicatorClass) );
  }

  updateActive(course) {
    course.is_active = !course.is_active;
    if (!course.is_active) {
      course.is_ecommerce_enabled = false;
    }
    this.courseService.updateActive({id: course.id, is_active: course.is_active, is_ecommerce_enabled: course.is_ecommerce_enabled})
      .then(() => {
        if(!course.is_active) {
          this.snackbar.open('Course deactivated')
          this.inactive.push(course);
          this.rows = this.active = this.active.filter((item) => item !== course);
        }else {          
          this.snackbar.open('Course activated')
          this.active.push(course);
          this.rows = this.inactive = this.inactive.filter((item) => item !== course);
        }
      });
  }

  updateEcommerce(course) {
    course.is_ecommerce_enabled = !course.is_ecommerce_enabled;
    this.courseService.updateActive({id: course.id, is_active: course.is_active, is_ecommerce_enabled: course.is_ecommerce_enabled})
      .then(() => {
        this.snackbar.open('Updated')
        null 
      });
  }
}
