import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Connection } from '@app/models/connection';
import { ConnectActionType } from '@app/models/connection-type/connection-type';
import { DialogType } from '@app/models/dialog-type/dialog-type';
import { TrainingPartner } from '@app/models/training-partner';
import { User } from '@app/models/user/user';

const filterPredicate = (data: Connection | TrainingPartner, filter: string) => {
  console.log('data', data)
  const transformedFilter = filter.trim().toLowerCase();
  const searchContent = `${data.name}◬${data.email}`.toLowerCase();
  return searchContent.indexOf(transformedFilter) !== -1;
};

interface DialogData {
  entities: Connection[] | TrainingPartner[];
  type: DialogType;
  pluralMap: {[k: string]: string};
  viewOnly?: boolean;
  connectionType: ConnectActionType
}

@Component({
  selector: 'app-manage-connections-dialog',
  templateUrl: './manage-connections-dialog.component.html',
  styleUrls: ['./manage-connections-dialog.component.scss']
})
export class ManageConnectionsDialogComponent implements OnInit, AfterViewInit {
  public displayedColumnsActions = ['select', 'name', 'email'];
  public displayedColumnsViewOnly = ['name', 'email'];
  public displayedColumns = [];
  public dataSource = new MatTableDataSource<Connection | TrainingPartner>([]);
  public selection = new SelectionModel<Connection | TrainingPartner>(true, []);
  type:DialogType=this.data.type
  name:string;
  email:string;
  ConnectActionType: ConnectActionType = this.data.connectionType
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.displayedColumns = this.data.viewOnly ? this.displayedColumnsViewOnly : this.displayedColumnsActions;
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = filterPredicate;
  }

  ngOnInit(): void {
    this.dataSource.data = this.data.entities;
  }


  isAnySelected() {
    return this.selection.selected.length > 0;
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  toggleSelectAll() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(connection => this.selection.select(connection));
  }

  selectedCount() {
    return this.selection.selected.length;
  }

  isDisabled(){
    return !this.isAnySelected() || (this.selectedCount() > 1 && this.ConnectActionType === ConnectActionType.Disconnect)
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }

}
