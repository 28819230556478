<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title class="">
        <div class="card-title-text">Create Task Defer Reason</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for="defer-reason" class="form-label">Defer Reason</label>
            <input id="defer-reason" name="defer-reason" class="form-input" [(ngModel)]="defer_reason_model.reason" placeholder="Reason" required>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="addDeferReason();">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
