import {Component, OnDestroy, OnInit} from '@angular/core';
import {TaskService} from '@app/services/data-services/task.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DeferReason} from '@app/models/course/content/task/defer-reason';
import {DeferReasonService} from '@app/services/data-services/defer-reason.service';

@Component({
  selector: 'app-manage-defer-reason',
  templateUrl: './manage-defer-reason.component.html',
  styleUrls: ['./manage-defer-reason.component.scss']
})
export class ManageDeferReasonComponent implements OnInit, OnDestroy {
  defer_reason_model: DeferReason;
  subs: any[] = [];

  constructor(private deferReasonService: DeferReasonService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.defer_reason_model = new DeferReason();
  }

  ngOnInit() {
    this.subs.push(this.activatedRoute.params.subscribe((params) => {
      this.getDeferReason(parseInt(params['id']));
    }));
  }

  updateDeferReason() {
    this.deferReasonService.update(this.defer_reason_model)
      .then(() => this.router.navigate(['admin/courses/task/defer-reasons-overview']))
      .catch((e) => this.defer_reason_model = new DeferReason());
  }

  getDeferReason(id: number) {
    this.deferReasonService.getDeferReason(id)
      .then((defer_reason) => {
        if (defer_reason) {
          this.defer_reason_model = JSON.parse(JSON.stringify(defer_reason));
          return;
        }
        this.route();
      });
  }

  cancel() {
    this.route();
  }

  route() {
    this.router.navigate(['admin/courses/task/defer-reasons-overview']);
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }


}
