import { Injectable } from '@angular/core';
import { User } from '@app/models/user/user';
import { HttpService } from '@app/services/http-service.service';
import { Instructor } from '@app/models/instructor';
import { Course } from '@app/models/course/course';
import { UserCourse } from '@app/models/course/user-course';
import { Content } from '@app/models/course/content/content';
import { Notification } from '@app/models/notification/notification';

@Injectable()
export class InstructorService {
  instructorUrl = 'instructors';

  constructor(private http: HttpService) {
  }

  getStudents(): Promise<User[]> {
    const url = `${this.instructorUrl}/my-students`;

    return this.http.get<User[]>(url)
      .toPromise()
      .catch(this.handleError);
  }

  getStudentCourses(id: number): Promise<UserCourse[]> {
    const url = `${this.instructorUrl}/user_courses/student/${id}`;

    return this.http.get<UserCourse[]>(url)
      .toPromise()
      .catch(this.handleError);
  }

  getStudentWorkbookForCourse(user_course_id: number): Promise<any> {
    const url = `${this.instructorUrl}/user_courses/workbook/${user_course_id}`;
    return this.http.get(url)
      .toPromise()
      .catch(this.handleError);
  }

  getStudentAssessmentsForCourse(user_id: number, course_id: number): Promise<Content[]> {
    const url = `${this.instructorUrl}/assessments/student/${user_id}/course/${course_id}`;

    return this.http.get<Content[]>(url)
      .toPromise()
      .catch(this.handleError);
  }

  getInstructors(): Promise<Instructor[]> {
    return this.http.get<Instructor[]>(this.instructorUrl)
      .toPromise()
      .catch(this.handleError);
  }

  getUserInstructors(user: User): Promise<Instructor[]> {
    const url = `${this.instructorUrl}/user/${user.id}`;

    return this.http.get<Instructor[]>(url)
      .toPromise()
      .catch(this.handleError);
  }

  getCurrentUserInstructors(): Promise<Instructor[]> {
    const url = `${this.instructorUrl}/user`;

    return this.http.get<Instructor[]>(url)
      .toPromise()
      .catch(this.handleError);
  }

  getInstructorCourses(instructor: Instructor): Promise<Instructor[]> {
    const url = `${this.instructorUrl}/courses/${instructor.id}`;

    return this.http.get<Instructor[]>(url)
      .toPromise()
      .catch(this.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.instructorUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }

  createConnection(instructor: User, students: User[]): Promise<void> {
    const url = `${this.instructorUrl}/connect`;

    return this.http
      .post(url, JSON.stringify({instructor, students}))
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }

  removeInstructor(instructor, student: User): Promise<boolean> {
    const url = `${this.instructorUrl}/disconnect/instructor/${instructor.id}/student/${student.id}`;
    return this.http.delete<boolean>(url)
      .toPromise()
      .catch(this.handleError);
  }

  removeConnection(instructor: Instructor, student: User): Promise<void> {
    const url = `${this.instructorUrl}/remove`;

    return this.http
      .post(url, JSON.stringify({instructor, student}))
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }

  removeStudent(user_id): Promise<void> {
    const url = `${this.instructorUrl}/student/remove/${user_id}`;

    return this.http
      .post(url, null)
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }

  update(user: User): Promise<void> {
    const url = `${this.instructorUrl}/${user.id}`;
    return this.http
      .put(url, JSON.stringify(user))
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }

  addToCourse(instructors: Instructor[], courses: Course[]): Promise<void> {
    const url = `${this.instructorUrl}/course/add`;

    return this.http
      .post(url, JSON.stringify({instructors, courses}))
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }

  removeFromCourse(instructor: Instructor, courses: Course[]): Promise<void> {
    const url = `${this.instructorUrl}/course/remove`;

    return this.http
      .post(url, JSON.stringify({instructor, courses}))
      .toPromise()
      .then(() => null)
      .catch(this.handleError);
  }

  sendInstructorRequest(instructor: Instructor): Promise<Notification> {
    const url = `${this.instructorUrl}/new_invite/${instructor.id}`;

    return this.http
      .post<Notification>(url, null)
      .toPromise()
      .then(res => res)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
