import { Injectable } from '@angular/core';
import { CourseCategory } from '@app/models/course/course-category';
import { HttpService } from '@app/services/http-service.service';

@Injectable()
export class CourseCategoryService {

  categoryUrl = 'course_categories';

  constructor(private http: HttpService) {}

  getCategories(): Promise<CourseCategory[]> {
    return this.http.get<CourseCategory[]>(this.categoryUrl)
      .toPromise()
      .catch(CourseCategoryService.handleError);
  }

  getCategoriesandCourses(): Promise<CourseCategory[]> {
    const url = `${this.categoryUrl}/with-courses`;
    return this.http.get<CourseCategory[]>(url)
      .toPromise()
      .catch(CourseCategoryService.handleError);
  }

  getCategory(id: number): Promise<CourseCategory> {
    const url = `${this.categoryUrl}/${id}`;
    return this.http.get<CourseCategory>(url)
      .toPromise()
      .catch(CourseCategoryService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.categoryUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(CourseCategoryService.handleError);
  }

  create(category: CourseCategory): Promise<CourseCategory> {
    return this.http
      .post<CourseCategory>(this.categoryUrl, JSON.stringify(category))
      .toPromise()
      .catch(CourseCategoryService.handleError);
  }

  update(category: CourseCategory): Promise<CourseCategory> {
    const url = `${this.categoryUrl}/${category.id}`;
    return this.http
      .put(url, JSON.stringify(category))
      .toPromise()
      .then(() => category)
      .catch(CourseCategoryService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
