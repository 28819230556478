<div class="full-width">
  <div class="interactive-wrapper">
    <iframe #frame (load)="resize()" [src]="url" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

    <!--<iframe #frame (load)="resize()" src="../../../../../../../../assets/GoAroundsInteractive/index.html"-->
      <!--style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->

    <!--<iframe #frame (load)="resize()" src="../../../../../../../../assets/Interactive13/interactive-test-13.html"-->
      <!--style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>-->
  </div>
</div>
