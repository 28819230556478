import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apps-menu',
  templateUrl: './apps-menu.component.html',
  styleUrls: ['./apps-menu.component.scss']
})
export class AppsMenuComponent implements OnInit {
  showMenu = false;
  hover = false;
  model = {
    positionX: 0,
    positionY: 0
  }
  constructor() { }

  ngOnInit() {
  }

  toggle(el?: any) {
    if (this.showMenu === false) {
      this.open(el);
    } else {
      this.close();
    }
  }

  open(el?: any) {
    this.showMenu = true;
    this.model.positionX = el._elementRef.nativeElement.offsetLeft - 286;
    this.model.positionY = el._elementRef.nativeElement.offsetTop + 50 + el._elementRef.nativeElement.scrollTop;
  }

  close() {
    setTimeout(() => {
      if (this.hover === false) {
        this.showMenu = false;
      }
    }, 200);
  }
}
