import { Injectable } from '@angular/core';
import { Course, MyCourse } from '@app/models/course/course';
import { HttpService } from '@app/services/http-service.service';
import { UserCourse } from '@app/models/course/user-course';
import { User } from '@app/models/user/user';
import { Content } from '@app/models/course/content/content';
import { CourseAttempt } from '@app/models/course/course-attempt';
import { Observable } from 'rxjs';

@Injectable()
export class CourseService {
  private courseUrl = 'courses';

  constructor(private http: HttpService) {}

  getStudentCourses(id: number): Promise<UserCourse[]> {
    const url = `${this.courseUrl}/user_courses/student/${id}`;

    return this.http
      .get<UserCourse[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getStudentCoursesToRemove(id: number): Promise<UserCourse[]> {
    const url = `${this.courseUrl}/user-courses/remove/student/${id}`;
    return this.http
      .get<UserCourse[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getLearningCatalogWorkbook(id: number): Promise<Course> {
    const url = `${this.courseUrl}/catalog/workbook/${id}`;

    return this.http
      .get<Course>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getMyUserCourses(user?): Promise<UserCourse[]> {
    const url = `${this.courseUrl}/my-user-courses`;
    return this.http
      .get<UserCourse[]>(url, { params: user })
      .toPromise()
      .catch(CourseService.handleError);
  }

  getUserCourseEnrollmentHistory(
    user_course_id: number
  ): Promise<CourseAttempt[]> {
    const url = `${this.courseUrl}/user_courses/${user_course_id}/history`;
    return this.http
      .get<CourseAttempt[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  agreeToTerms(id: number): Promise<boolean> {
    const url = `${this.courseUrl}/user_courses/agree/${id}`;

    return this.http
      .post<boolean>(url, null)
      .toPromise()
      .catch(CourseService.handleError);
  }

  enrollNow(id: number): Promise<boolean> {
    const url = `${this.courseUrl}/enroll_now/${id}`;

    return this.http
      .post<boolean>(url, null)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getUserCourse(id: number): Promise<UserCourse> {
    const url = `${this.courseUrl}/user_courses/${id}`;
    return this.http
      .get<UserCourse>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getUserCourseDetail(course_id: number, user_id: number): Promise<UserCourse> {
    const url = `${this.courseUrl}/user_course/${course_id}/user/${user_id}`;
    return this.http
      .get<UserCourse>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getUserCourses(): Promise<UserCourse[]> {
    const url = `${this.courseUrl}/user_courses`;
    return this.http
      .get<UserCourse[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getCoursesForAddUserDialog(): Promise<Course[]> {
    const url = `${this.courseUrl}/user-courses/add`;
    return this.http
      .get<Course[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getCourses(): Promise<Course[]> {
    return this.http
      .get<Course[]>(this.courseUrl)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getCourseCatalog(): Promise<Course[]> {
    const url = `${this.courseUrl}/catalog`;
    return this.http
      .get<Course[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getCourseContent(user_course: UserCourse): Promise<Content> {
    const url = `${this.courseUrl}/user_courses/content/${user_course.id}`;
    return this.http
      .get<Content>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getUserCourseLibraryContent(): Promise<Content[]> {
    const url = `${this.courseUrl}/user_course/library/content`;
    return this.http
      .get<Content[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getManagerCourses(): Promise<Course[]> {
    const url = `${this.courseUrl}/manager`;
    return this.http
      .get<Course[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getGenericCourses(): Promise<Course[]> {
    const url = `${this.courseUrl}/generic`;

    return this.http
      .get<Course[]>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  getCourseToEdit(id: number): Promise<Course> {
    const url = `${this.courseUrl}/${id}/edit`;
    return this.http
      .get<Course>(url)
      .toPromise()
      .catch(CourseService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.courseUrl}/${id}`;
    return this.http
      .delete(url)
      .toPromise()
      .then(() => null)
      .catch(CourseService.handleError);
  }

  create(course: Course): Observable<Course> {
    return this.http.post<Course>(this.courseUrl, JSON.stringify(course));
  }

  update(course: Course): Observable<void> {
    const url = `${this.courseUrl}/${course.id}`;
    return this.http.put(url, JSON.stringify(course));
  }

  updateActive(course: any): Promise<void> {
    const url = `${this.courseUrl}/update_active/${course.id}`;
    return this.http
      .put(url, JSON.stringify(course))
      .toPromise()
      .then(() => null)
      .catch(CourseService.handleError);
  }

  updateUserCourse(course: MyCourse): Promise<Course> {
    const url = `${this.courseUrl}/user_courses/${course.user_course_id}`;
    return this.http
      .put<Course>(url, JSON.stringify({ archived: course.archived }))
      .toPromise()
      .catch(CourseService.handleError);
  }

  addUsersToCourses(
    courses: Course[],
    users: User[],
    embark_job_id: string,
    flight_ops_event_id: string
  ): Promise<void> {
    const url = `${this.courseUrl}/add/user_courses`;
    return this.http
      .post(
        url,
        JSON.stringify({ courses, users, embark_job_id, flight_ops_event_id })
      )
      .toPromise()
      .then(() => null)
      .catch(CourseService.handleError);
  }

  resetCourseAttempts(
    user_courses: UserCourse[],
    users: User[]
  ): Promise<void> {
    const url = `${this.courseUrl}/course_attempts/reset/${users[0].id}`;
    const payload = JSON.stringify({ user_courses });
    console.log(payload);
    return this.http
      .post(url, payload)
      .toPromise()
      .then(() => null)
      .catch(CourseService.handleError);
  }

  // Needs ctc admin user if called as a ctc admin
  removeUserFromCourses(
    user_courses: UserCourse[],
    user?: User
  ): Promise<void> {
    const url = `${this.courseUrl}/remove/user_courses`;
    return this.http
      .post(url, JSON.stringify({ user_courses, user }))
      .toPromise()
      .then(() => null)
      .catch(CourseService.handleError);
  }

  changeToInProgress(
    course_attempt_id: number,
    user_id: number,
    lesson_id: number,
    stage_id: number
  ): Promise<any> {
    const url = `${this.courseUrl}/change_progress/${course_attempt_id}/${user_id}/${lesson_id}/${stage_id}`;
    return this.http
      .post(url, JSON.stringify(null))
      .toPromise()
      .catch(CourseService.handleError);
  }

  updateEmbarkID(user_course_id: number, embark_job_id: string): Promise<any> {
    const url = `${this.courseUrl}/user_courses/update-embark/${user_course_id}`;
    const data = {
      embark_job_id: embark_job_id,
    };
    return this.http
      .put(url, JSON.stringify(data))
      .toPromise()
      .catch(CourseService.handleError);
  }

  updateFlightOpsEventID(
    user_course_id: number,
    flight_ops_event_id: string
  ): Promise<any> {
    const url = `${this.courseUrl}/user_courses/update-flightopsevent/${user_course_id}`;
    const data = {
      flight_ops_event_id: flight_ops_event_id,
    };
    return this.http
      .put(url, JSON.stringify(data))
      .toPromise()
      .catch(CourseService.handleError);
  }

  reenrollUserToCourse(course_id: number, user_id: number): Promise<any> {
    const url = `${this.courseUrl}/reenroll/${course_id}/${user_id}`;
    return this.http
      .post(url, JSON.stringify(null))
      .toPromise()
      .catch(CourseService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
