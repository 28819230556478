<div fxFlex="100" fxLayout='row'>
  <div class="editor-wrapper" id="assignments-add" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0 pb-100" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Create New Faq</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="general-container" fxLayout="column">
          <form #form="ngForm">
            <div class="form-row">
              <label for="faq-question" class="form-label">Question</label>
              <input id="faq-question" name="faq-question" class="form-input" [(ngModel)]="faq_model.question" placeholder="Question">
            </div>
            <div class="form-row">
              <label for="faq-category" class="form-label">Faq Category</label>
              <div class="full-width mt-1" *ngIf="faq_model?.faq_category">
                <p class="selected-category">{{ faq_model?.faq_category?.name }}</p>
              </div>
              <div class="full-width pb-1">
                <button mat-raised-button color="primary" (click)="addFaqCategory()">
                  Select Category
                </button>
              </div>
            </div>
            <mat-accordion id="help-video-accordion">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="full-width inline-items inline-wrapper">
                    <h5>How to Upload a YouTube or Vimeo Video</h5>
                  </div>
                </mat-expansion-panel-header>
                <div class="full-width">
                  <div class="full-width inline-items inline-wrapper test">
                    <div class="full-width pb-1 pt-1">
                      <p>Follow these steps to upload a YouTube or Vimeo video:</p>
                      <p>Step 1: Find the video you would like to upload.</p>
                      <p>Step 2: For YouTube, click the share button. Next, click "Embed". For Vimeo, click "Share".</p>
                      <P>Step 3: Copy all of the code that is generated for you. </P>
                      <p>Step 4: In the text editor below, click the very last button or "View Code".</p>
                      <p>Step 5: If you cannot see the text area, simply click the bottom border and drag down to expand it open.
                        Paste the code you copied from YouTube or Vimeo into the text area.</p>
                      <p>Step 6: Click the "View Code" button again to un-toggle.</p>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>

            <div class="form-row">
              <label class="form-label">Content</label>
              <app-wysiwyg-editor editorId='faq-content' title="Faq" placeholder="Enter text here..." [(text)]="faq_model.body"></app-wysiwyg-editor>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="save()">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="route()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
