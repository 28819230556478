import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@app/services/data-services/notification.service';
import { BehaviorSubject } from 'rxjs';
import {Notification} from '@app/models/notification/notification';


@Component({
  selector: 'app-ctc-layout',
  templateUrl: './ctc-layout.component.html',
  styleUrls: ['./ctc-layout.component.scss']
})
export class CtcLayoutComponent implements OnInit {

  public notificationsToken$ = new BehaviorSubject<Notification[]>([]);
  public notifications$ = this.notificationsToken$.pipe();

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    if (localStorage.getItem('cirrus-token')) {
      this.notificationService.getMyNotifications().then((notifications) => {
        this.notificationsToken$.next(notifications);
      });
    }
  }

  isNavOver() {
    return window.matchMedia(`(max-width: 960px)`).matches;
  }

}
