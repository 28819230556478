<div fxLayout="row">
  <div class="editor-wrapper" fxLayout="row wrap" fxFlex="grow">
    <mat-card class="p-0" fxFlex="grow">
      <mat-card-title>
        <div class="card-title-text">Users</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="full-width pb-1">
          <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="notify()">Notify</button>
            <button mat-menu-item (click)="addUserToCourse()">
              Add Course
            </button>
            <button mat-menu-item (click)="removeUserFromCourse()">
              Remove Course
            </button>
            <ng-container *appFeature="'training_centers'">
              <button mat-menu-item (click)="addTrainingPartner()">
                Connect Training Partner
              </button>
            </ng-container>
            <ng-container *appLegacy="'legacy_pre_training_partners'">
              <button mat-menu-item (click)="addInstructor()">
                Associate Instructor
              </button>
            </ng-container>
            <ng-container *appLegacy="'legacy_pre_training_partners'">
              <button mat-menu-item (click)="removeInstructor()">
                Remove Instructor
              </button>
            </ng-container>
            <button mat-menu-item (click)="disconnectTrainingPartner()">
              Disconnect Training Partner
            </button>
            <button mat-menu-item (click)="deactivateUser()">
              Deactivate Login
            </button>
            <button mat-menu-item (click)="activateUser()">
              Activate Login
            </button>
          </mat-menu>

          <button
            mat-raised-button
            color="primary"
            [matMenuTriggerFor]="appMenu"
          >
            More Actions
          </button>

          <input
            type="text"
            style="padding: 8px; margin: 15px auto; width: 30%"
            placeholder="Filter Users"
            (keyup)="filterValue.next($event.target.value)"
          />
        </div>

        <mat-progress-bar
          *ngIf="isLoading"
          mode="indeterminate"
        ></mat-progress-bar>
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          class="mat-elevation-z3"
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? toggleSelectAll() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              ></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let user">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(user) : null"
                [checked]="selection.isSelected(user)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let user">{{ user?.contact?.name }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let user">{{ user.email }}</td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let user">
              {{ user?.contact?.contact_user?.phone }}
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
            <td mat-cell *matCellDef="let user">
              {{
                user.ctc_admin ? 'CTC Admin' : (user.role | spaces | titlecase)
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="deactivated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Deactivated?
            </th>
            <td mat-cell *matCellDef="let user">
              {{
                (user.deactivated !== null ? user.deactivated.toString() : '')
                  | titlecase
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef></th>

            <td mat-cell *matCellDef="let user">
              <button
                mat-icon-button
                [matMenuTriggerFor]="msgMenu"
                class="hidden-on-open"
              >
                <mat-icon class="text-muted">more_vert</mat-icon>
              </button>
              <mat-menu #msgMenu="matMenu">
                <button mat-menu-item (click)="viewUserOrders(user)">
                  Orders
                </button>
                <button mat-menu-item (click)="viewTranscripts(user)">
                  Transcripts
                </button>
                <mat-divider></mat-divider>
                <button
                  mat-menu-item
                  (click)="promoteToAdmin(user)"
                  *ngIf="!user.admin"
                >
                  Promote to Administrator
                </button>
                <button
                  mat-menu-item
                  (click)="revokeAdmin(user)"
                  *ngIf="user.admin"
                >
                  Revoke Administrator
                </button>
                <button mat-menu-item (click)="updateSalesforceID(user)">
                  Update User SFID
                </button>
                <button mat-menu-item (click)="impersonateUser(user)">
                  Impersonate
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSize]="15"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
