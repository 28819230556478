<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Create Aircraft</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for="aircraft-name" class="form-label">Name</label>
            <input id="aircraft-name" name="aircraft-name" class="form-input" [(ngModel)]="aircraft.name" placeholder="Name" required>
          </div>
          <div class="form-row">
            <label for="aircraft-desc" class="form-label">Description</label>
            <textarea id="aircraft-desc" name="aircraft-desc" class="form-textarea" [(ngModel)]="aircraft.desc" placeholder="Description"></textarea>
          </div>
          <div class="form-row">
            <label for="aircraft-make" class="form-label">Make</label>
            <input id="aircraft-make" name="aircraft-make" class="form-input" [(ngModel)]="aircraft.make" placeholder="Make">
          </div>
          <div class="form-row">
            <label for="aircraft-model" class="form-label">Model</label>
            <input id="aircraft-model" name="aircraft-model" class="form-input" [(ngModel)]="aircraft.model" placeholder="Model">
          </div>
          <div class="form-row">
            <label for="aircraft-generation" class="form-label">Generation</label>
            <input id="aircraft-generation" name="aircraft-generation" class="form-input" [(ngModel)]="aircraft.generation" placeholder="Generation">
          </div>
          <div class="form-row">
            <label for="aircraft-avionics" class="form-label">Avionics</label>
            <div class="full-width mt-1" *ngIf="aircraft?.avionics">
              <p class="selected-avionics">{{aircraft?.avionics?.name}}</p>
            </div>
            <button class="mr-1" mat-raised-button [color]="'primary'" (click)="addAvionics()">Select Avionics</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="addAircraft();" [disabled]="!form.valid || !aircraft.avionics">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
