import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {LessonFlightLogService} from '@app/services/data-services/lesson-flight-log.service';
import {StudentAssessmentsEntryDialogComponent} from '../student-assessments-entry-dialog/student-assessments-entry-dialog.component';

@Component({
  selector: 'app-student-assessments-approaches-dialog',
  templateUrl: './student-assessments-approaches-dialog.component.html',
  styleUrls: ['./student-assessments-approaches-dialog.component.scss']
})
export class StudentAssessmentsApproachesDialogComponent implements OnInit {
  approaches = [
    { value: 'ILS' },
    { value: 'LOC' },
    { value: 'GPS' },
    { value: 'VOR' },
    { value: 'NBD' },
    { value: 'BC' },
    { value: 'SDF' },
    { value: 'LDA' },
    { value: 'RNAV' },
    { value: 'PAR' },
    { value: 'TACAN' },
    { value: 'MLS' }
  ];
  airport = '';
  runway = '';
  approachType = '';
  quantity = 1;

  constructor(private dialogRef: MatDialogRef<StudentAssessmentsApproachesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    const approaches = [];
    for (var i = 0; i < this.quantity; i++) {
      approaches.push({
        'type': this.approachType,
        'airport': this.airport,
        'runway': this.runway
      });
    }
    this.dialogRef.close(approaches);
  }

  increase() {
    this.quantity++;
  }

  decrease() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }
}
