import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranscriptsService } from '@app/services/data-services/transcripts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseService } from '@app/services/data-services/course.service';
import { ProgressService } from '@app/services/data-services/progress.service';
import { UserProgress } from '@app/models/user/user-progress';
import { ProgressCalculatorService } from '@app/services/helper/progress-calculator.service';
import { Status, StatusMap } from '@app/models/shared/status';
import { CertificatesService } from '@app/services/data-services/certificates.service';
import { PdfService } from '@app/services/helper/pdf.service';
import { UserService } from '@app/services/data-services/user.service';
import { GeneralTableDialogComponent } from '@app/components/shared/general-table-dialog/general-table-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionType } from '@swimlane/ngx-datatable';
import { SnackbarComponent } from '@app/components/shared/snackbar/snackbar.component';
import { BadgesService } from '@app/services/data-services/badges.service';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducers';
import { MatTableConfig } from '@app/models/mat-table/mat-table';
import { downloadPdf } from '@app/helpers/download_pdf.helper';
import { PdfV4Service } from '@app/pdf-v4-service';
import { PdfDownloadFile } from '@app/models/pdf-download-file';

@Component({
  selector: 'app-transcripts-manager',
  templateUrl: './transcripts-manager.component.html',
  styleUrls: ['./transcripts-manager.component.scss'],
})
export class TranscriptsManagerComponent implements OnInit {
  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  @ViewChild('image_container', { static: true }) image_container: ElementRef;
  @ViewChild('image', { static: true }) image: ElementRef;
  dataRows: any[] = [];
  rows: any[] = [];
  columns = [];
  selected: any[] = [];
  userProgress: UserProgress[] = [];
  statuses = Status;
  statusTypes = StatusMap;
  isLoading = false;
  user_id: number;
  url = '';
  dialogRef: any;
  export_transcript = false;
  course_id = null;
  course_attempt_id = null;
  user_name = '';
  subscription: any;
  courseHistory: any[] = [];
  historyRowCount: number;

  constructor(
    private transcriptsService: TranscriptsService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private courseService: CourseService,
    private pdfService: PdfService,
    private progressService: ProgressService,
    private badgeService: BadgesService,
    private calculatorService: ProgressCalculatorService,
    private certificateService: CertificatesService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private pdfV4Service: PdfV4Service
  ) {}

  ngOnInit() {
    this.getRole();
    this.isLoading = true;
    const sub = this.activatedRoute.params.subscribe(params => {
      if (params['id']) {
        this.user_id = params['id'];
        this.courseService
          .getStudentCourses(params['id'])
          .then(user_courses => {
            this.dataRows = user_courses;
            this.progressService
              .getUserProgress(params['id'])
              .then(user_progress => {
                this.userProgress = user_progress;
                this.calculatorService.setProgresses(user_progress);
              })
              .then(() => this.parseCourse());
          })
          .catch(() => (this.isLoading = false));

        this.userService
          .getUserName(this.user_id)
          .then(res => (this.user_name = res));
      }
    });

    sub.unsubscribe();
  }

  parseCourse() {
    try {
      this.dataRows.forEach(user_course => {
        const isComplete: any =
          this.calculatorService.checkForCourseProgressComplete(user_course);
        let overall: any = '0';
        let completed_at: Date = null;

        const progresses = this.getCourseProgress(user_course);

        if (progresses.length) {
          overall = (
            (progresses.filter(progress => progress === true).length /
              progresses.length) *
            100
          ).toFixed(0);
        }

        const start_date = this.userProgress.filter(
          progress =>
            progress.progress_type.toString() === 'course' &&
            progress.course_id === user_course.course_id
        );
        completed_at = start_date.length ? start_date[0].updated_at : null;
        this.rows.push({
          course: user_course,
          course_progress: parseInt(overall, 10),
          completed_at,
          started: start_date.length ? start_date[0].created_at : '',
          courseAttempt: user_course.course_attempt,
          attempts: user_course.attempts,
        });
      });
    } catch (e) {
      this.isLoading = false;
    }

    this.rows = [...this.rows];
    this.isLoading = false;
  }

  getCourseProgress(course) {
    this.calculatorService.progresses = this.userProgress.filter(
      progress => progress.course_attempt_id == course.course_attempt_id
    );

    return this.calculatorService.getTranscriptProgress(course);
  }

  checkCompleted(index: number) {
    return this.rows[index].course_progress > 0
      ? this.rows[index].course_progress === 100
        ? 'Completed'
        : 'In Progress'
      : 'Not Started';
  }

  viewCourseAttemptProgresses(course_attempt_id, course_id) {
    this.course_id = course_id;
    let tableConfig: MatTableConfig = {
      dialogTitle:
        'Progresses Adjustment Tool (Course Attempt ID ' +
        course_attempt_id +
        ')',
      showCancelButton: true,
      customConfirmButton: true,
      dialogConfirmButtonLabel: 'Mark Selected as Complete',
      dialogCancelButtonLabel: 'Cancel',
      selectionType: SelectionType.multiClick,
      hasSidebar: false,
      columns: [
        {
          name: 'Select',
          mat_col_name: 'select',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'ID',
          mat_col_name: 'id',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Progress Type',
          mat_col_name: 'progress_type',
          type_map: false,
          nested_name: '',
        },
        {
          name: 'Status',
          mat_col_name: 'status',
          type_map: false,
          nested_name: '',
        },
      ],
      dataColumns: ['select', 'id', 'progress_type', 'status'],
      rows: this.userProgress.filter(
        progress => progress.course_attempt_id == course_attempt_id
      ),
    };
    this.openDialog(tableConfig, course_attempt_id);
  }
  
  downloadCertificate(user_certificate_id) {
    this.pdfV4Service.downloadCertificate(user_certificate_id)
      .subscribe((data: PdfDownloadFile) => {
        downloadPdf(data);
      });
  }

  openDialog(tableConfig, course_attempt_id): any {
    this.dialogRef = this.dialog.open(GeneralTableDialogComponent, {
      width: '50vw',
      data: tableConfig,
    });

    this.dialogRef.afterClosed().subscribe((progresses: any[]) => {
      progresses = progresses.filter(
        progress => progress.status !== 'complete'
      );
      this.progressService
        .updateProgressesComplete(progresses, course_attempt_id)
        .then(() => {
          const snackbar = this.snackBar.openFromComponent(SnackbarComponent, {
            data: 'Progresses Updated',
          });
          this.subscription = snackbar.afterOpened().subscribe(() => {
            this.badgeService.checkBadges(this.course_id, this.user_id).then();
            this.certificateService
              .checkCertificates(course_attempt_id, this.user_id)
              .then();
            this.progressService
              .getUserProgress(this.user_id)
              .then(user_progress => {
                this.userProgress = user_progress;
                this.calculatorService.setProgresses(user_progress);
              });
          });
        });
    });
  }

  editCertificate(user_certificate_id) {
    const url = `admin/transcripts/${this.user_id}/edit-cert/${user_certificate_id}`;
    this.router.navigate([url]);
  }

  download(cert) {
    this.url = cert.certificate.certificate_image;
    this.image.nativeElement.onload = () => {
      this.pdfService.download(this.image.nativeElement, cert, () => {
        this.url = '';
      });
    };
  }

  downloadTranscript(id, course_attempt_id) {
    this.isLoading = true;
    this.pdfV4Service
      .downloadTranscript(id, course_attempt_id)
      .subscribe((resp: PdfDownloadFile) => {
        downloadPdf(resp);
        this.isLoading = false;
      });
  }

  enrollmentHistory(course_id) {
    const url = `instructor/classroom/transcripts/enrollments/${this.user_id}/course/${course_id}`;
    this.router.navigate([url]);
  }

  export() {
    // window['transcriptDetail'].component.downloadPdf(() => {
    //   this.export_transcript = false;
    //   this.isLoading = false;
    // });
  }

  getRole() {
    return localStorage.getItem('cirrus-role');
  }
}
