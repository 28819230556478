import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Task } from '../../../../models/course/content/task/task';
import { TaskService } from '../../../../services/data-services/task.service';
import { TaskCategoryService } from '../../../../services/data-services/task-category.service';
import { TaskCategory } from '../../../../models/course/content/task/task-category';
import { slideInRight } from '../../../../animations/animations';
import { MatDialog } from '@angular/material/dialog';
import {AddTaskCategoryDialogComponent} from '../add-task/add-task-category-dialog/add-task-category-dialog.component';

@Component({
  selector: 'app-manage-task',
  templateUrl: './manage-task.component.html',
  styleUrls: ['./manage-task.component.scss'],
  animations: [
    slideInRight
  ]
})
export class ManageTaskComponent implements OnInit, OnDestroy {
  task_model: Task;
  task_categories: TaskCategory[];
  subs: any[] = [];
  dialogRef: any;

  constructor(private taskService: TaskService,
              private taskCategoryService: TaskCategoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              public dialog: MatDialog) {
    this.task_model = new Task();
    this.task_model.standards = new Array<string>();
    this.task_model.defer_reasons = new Array<string>();
  }

  ngOnInit() {
    this.subs.push(this.activatedRoute.params.subscribe((params) => {
      this.getTask(parseInt(params['id']));
    }));
    this.getTaskCategories();
  }

  trackFunction(index, item) {
    return index;
  }

  getTaskCategories() {
    this.taskCategoryService.getCategories()
        .then((categories) => this.task_categories = categories);
  }

  updateTask() {
    this.taskService.update(this.task_model)
        .then(() => this.router.navigate(['admin/courses/task']))
        .catch((e) => this.task_model = new Task());
  }

  getTask(id: number) {
    this.taskService.getTask(id)
        .then((task) => {
          if (task) {
            this.task_model = JSON.parse(JSON.stringify(task));
            return;
          }
          this.route();
        });
  }

  removeStandard(index: number) {
    if (this.task_model.standards && this.task_model.standards.length > 0) {
      this.task_model.standards.splice(index, 1);
    }
  }

  cancel() {
    this.route();
  }

  route() {
    this.router.navigate(['admin/courses/task']);
  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe();
    }
  }

  addStandard() {
    this.task_model.standards.push('');
  }
  openDialog(content) {
    const width = '800px';
    const height = '600px';

    this.dialogRef = this.dialog.open(AddTaskCategoryDialogComponent, {
      width,
      height,
      data: content
    });
  }

  addCategory() {
    this.openDialog(this.task_model);

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.task_model.task_category = data;
      }
    });
  }
}
