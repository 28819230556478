import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromApp from '../../store/app.reducers';

export interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Item icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in user-sidenav.
  sub?: IChildItem[]; // Dropdown items
}

export interface IChildItem {
  name: string; // Display text
  state: string; // Router state
}

@Injectable()
export class NavigationService {
  iconTypeMenuTitle = 'Frequently Accessed';
  currentCourse: any;
  subscription: any;
  menuData: any[];

  courseTabs$: any;
  achieveTabs$: any;
  achieveTabs: any;

  adminMenu: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: '/admin/dashboard',
    },
    {
      name: 'USERS',
      type: 'link',
      tooltip: 'Users',
      icon: 'account_circle',
      state: '/admin/user-manager/users',
    },
    {
      name: 'COURSES',
      type: 'menuItem',
      tooltip: 'Courses',
      icon: 'border_color',
    },
    {
      name: 'NOTIFICATIONS',
      type: 'link',
      tooltip: 'Notifications',
      icon: 'notifications',
      state: '/admin/notifications',
    },
    // {
    //   name: 'FAQS',
    //   type: 'menuItem',
    //   tooltip: 'Faqs',
    //   icon: 'live_help'
    // },
    {
      name: 'REPORTS',
      type: 'link',
      tooltip: 'Reports',
      icon: 'show_chart',
      state: '/admin/reports',
    },
    {
      name: 'COMMENT MODERATING',
      type: 'link',
      tooltip: 'Comment Moderating',
      icon: 'speaker_notes',
      state: '/admin/comment-moderating',
    },
    {
      name: 'ORDERS',
      type: 'link',
      tooltip: 'Orders',
      icon: 'shopping_cart',
      state: '/admin/user-order-history',
    },
    {
      name: 'SYSTEM SETTINGS',
      type: 'link',
      tooltip: 'System Settings',
      icon: 'settings',
      state: '/admin/configuration',
    },
  ];

  ctcMenu: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: '/ctc/dashboard',
    },
    {
      name: 'STUDENTS',
      type: 'menuItem',
      tooltip: 'Students',
      icon: 'class',
      state: '/ctc/classroom/students',
    },
    {
      name: 'INSTRUCTORS',
      type: 'link',
      tooltip: 'Bookshelf',
      icon: 'library_books',
      state: '/ctc/instructors',
    },
  ];

  instructorMenu: IMenuItem[] = [
    {
      name: 'DASHBOARD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: '/instructor/dashboard',
    },
    {
      name: 'STUDENTS',
      type: 'menuItem',
      tooltip: 'Students',
      icon: 'class',
    },
    {
      name: 'BOOKSHELF',
      type: 'link',
      tooltip: 'Bookshelf',
      icon: 'library_books',
      state: '/instructor/bookshelf',
    },
    // , {
    //     name: 'NOTIFICATIONS',
    //     type: 'link',
    //     tooltip: 'Notifications',
    //     icon: 'notifications',
    //     state: '/instructor/notifications'
    // }
  ];

  coursesMenu: IMenuItem[] = [
    {
      name: 'Overview',
      state: '/course/overview',
      type: 'link',
    },
    {
      name: 'Workbook',
      state: '/course/workbook',
      type: 'link',
    },
    // {
    //     name: 'Progress',
    //     state: '/course/progress',
    //     type: 'link'
    // },
    // {
    //     name: 'Instructors',
    //     state: '/course/course-instructors',
    //     type: 'link'
    // },
    {
      name: 'Library',
      state: '/course/user-library',
      type: 'link',
    },
  ];

  progressMenu = [
    {
      name: 'Summary',
      state: '/course/progress/summary',
      type: 'link',
    },
    {
      name: 'Lessons',
      state: '/course/progress/lessons-quizzes',
      type: 'link',
    },
    {
      name: 'Assessment Tasks',
      state: '/course/progress/assessments',
      type: 'link',
    },
    {
      name: 'Logbook',
      state: '/course/progress/logbook',
      type: 'link',
    },
  ];

  achievementMenu = [
    {
      name: 'Certificates',
      state: '/achievements/certificates',
      type: 'link',
    },
    {
      name: 'Badges',
      state: '/achievements/badges',
      type: 'link',
    },
  ];

  helpMenu: IMenuItem[] = [
    {
      name: 'General Questions',
      state: '/achievements/certificates',
      type: 'link',
    },
    {
      name: 'Courses',
      state: '/achievements/badges',
      type: 'link',
    },
    {
      name: 'User Accounts',
      state: '/achievements/badges',
      type: 'link',
    },
    {
      name: 'Purchases',
      state: '/achievements/badges',
      type: 'link',
    },
  ];

  menuItems = new BehaviorSubject<IMenuItem[]>(this.adminMenu);
  menuItems$ = this.menuItems.asObservable();

  sideNavItems = new BehaviorSubject<any[]>(this.progressMenu);
  sideNavItems$ = this.sideNavItems.asObservable();

  constructor(private store: Store<fromApp.AppState>) {}

  public setMenuData(data: any) {
    this.menuData = data;
  }

  public setMenu(menuType: string) {
    const subscription = this.store.select('course').subscribe(data => {
      this.currentCourse = data.selectedCourse;
    });

    let menu = [];

    if (menuType === 'admin') {
      this.menuItems = new BehaviorSubject<IMenuItem[]>(this.adminMenu);
      this.menuItems$ = this.menuItems.asObservable();
    } else if (menuType === 'ctc') {
      this.menuItems = new BehaviorSubject<IMenuItem[]>(this.ctcMenu);
      this.menuItems$ = this.menuItems.asObservable();
    } else if (menuType === 'instructor') {
      this.menuItems = new BehaviorSubject<IMenuItem[]>(this.instructorMenu);
      this.menuItems$ = this.menuItems.asObservable();
    } else if (menuType === 'achieveTab') {
      this.achieveTabs = new BehaviorSubject<IMenuItem[]>(this.achievementMenu);
      this.achieveTabs$ = this.achieveTabs.asObservable();
    } else if (menuType === 'courseTab') {
      this.courseTabs$ = new BehaviorSubject<IMenuItem[]>([]);
      if (this.currentCourse) {
        const coursesMenu = [];

        for (let i = 0, len = this.coursesMenu.length; i < len; i++) {
          coursesMenu[i] = {}; // empty object to hold properties added below
          for (const prop in this.coursesMenu[i]) {
            coursesMenu[i][prop] = this.coursesMenu[i][prop]; // copy properties from arObj to ar2
          }
        }

        this.courseTabs$.next(coursesMenu);
      }
    } else if (menuType === 'courseSideNav') {
      this.menuItems = new BehaviorSubject<IMenuItem[]>(this.adminMenu);
      this.menuItems$ = this.menuItems.asObservable();
    } else if (menuType === 'progress') {
      this.setMenuData(this.progressMenu);
    } else if (menuType === 'workbook') {
      // menu = this.coursesMenu.map((item, index, array) => {
      //   item.state = item.state.replace(':id', this.currentCourse.id.toString());
      //   return item;
      // });

      this.sideNavItems = new BehaviorSubject(menu);
      this.sideNavItems$ = this.sideNavItems.asObservable();
    } else if (menuType === 'help') {
      // this.menuItems = new BehaviorSubject<IMenuItem[]>(this.helpMenu);
      // this.menuItems$ = this.menuItems.asObservable();

      // console.log('MENU!!!', menu);

      this.sideNavItems = new BehaviorSubject(menu);
      this.sideNavItems$ = this.sideNavItems.asObservable();
    }

    subscription.unsubscribe();
  }
}
