<div fxFlex="100" fxLayout='row'>
  <div id="assignments-add" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0 pb-100" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Manage Assignment</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="general-container" fxLayout="column">
          <form #form="ngForm">
            <div class="form-row">
              <label for="assignment-name" class="form-label">Name</label>
              <input id="assignment-name" name="form-control-name" class="form-input" [(ngModel)]="assignment_model.name" placeholder="Name">
            </div>
            <div class="form-row">
              <label for="assignment-desc" class="form-label">Description</label>
              <textarea id="assignment-desc" name="form-control-desc" class="form-textarea" [(ngModel)]="assignment_model.desc" placeholder="Description"></textarea>
            </div>
            <div class="form-row">
              <label for="assignment-html" class="form-label">Assignment Content</label>
              <mat-accordion id="help-video-accordion">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <div class="full-width inline-items inline-wrapper">
                      <h5>How to Upload a YouTube or Vimeo Video</h5>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="full-width">
                    <div class="full-width inline-items inline-wrapper test">
                      <div class="full-width pb-1 pt-1">
                        <p>Follow these steps to upload a YouTube or Vimeo video:</p>
                        <p>Step 1: Find the video you would like to upload.</p>
                        <p>Step 2: For YouTube, click the share button. Next, click "Embed". For Vimeo, click "Share".</p>
                        <P>Step 3: Copyall of the code that is generated for you. </P>
                        <p>Step 4: In the text editor below, click the very last button or "View Code".</p>
                        <p>Step 5: If you cannot see the text area, simply click the bottom border and drag down to expand it open.
                          Paste the code you copied from YouTube or Vimeo into the text area.</p>
                        <p>Step 6: Click the "View Code" button again to un-toggle.</p>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <mat-accordion id="help-image-accordion">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <div class="full-width inline-items inline-wrapper">
                      <h5>How to Upload an Image</h5>
                    </div>
                  </mat-expansion-panel-header>
                  <div class="full-width">
                    <div class="full-width inline-items inline-wrapper test">
                      <div class="full-width pb-1 pt-1">
                        <p>To upload an image, click inside the text editor before attempting to upload or the upload will fail and will
                          display the error: "Range out of the editor"</p>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <app-wysiwyg-editor editorId='assignment-html' title="Assignment" placeholder="Enter text here..." [(text)]="assignment_model.content"></app-wysiwyg-editor>
            </div>
          </form>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="update()">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
