<footer class="global-footer">
  <div class="lower-footer-container">
    <p class="body-copy-xsmall">
      © {{copyrightYear}} Cirrus Design Corporation d/b/a Cirrus. All Rights Reserved.
    </p>
    <div class="legal-links-container">
      <a class="secondary-link footer-link" target="blank" href="https://cirrusaircraft.com/privacy/">Privacy Policy</a>
      <span>|</span>
      <a class="secondary-link footer-link" target="blank" href="https://cirrusaircraft.com/terms">Terms of Use</a>
    </div>
  </div>
</footer>
