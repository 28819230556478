<div fxLayout="row" #wrapper>
  <div class="editor-wrapper" fxLayout="row wrap" fxFlex="grow">
    <mat-card class="p-0" fxFlex="grow">
      <mat-card-title>
        <div class="card-title-text">
          <h3 style="margin-top: 0px">{{ user_name }}</h3>
        </div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="mat-box-shadow transcript-card">
          <ngx-datatable
            class="material bg-white"
            [columnMode]="'force'"
            [headerHeight]="50"
            [footerHeight]="50"
            [loadingIndicator]="isLoading"
            [rowHeight]="'auto'"
            [limit]="50"
            [rows]="rows"
          >
            <ngx-datatable-column name="Course" [canAutoResize]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.course?.course?.title }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Version" [canAutoResize]="false">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.course?.course?.major_version }}.{{
                  row?.course?.course?.minor_version
                }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Attempts" [canAutoResize]="false">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                {{ row.attempts }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="Training Provider"
              [canAutoResize]="false"
            >
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row?.course?.course?.created_by }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" [canAutoResize]="false">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                {{ checkCompleted(rowIndex) }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
              name="Progress"
            >
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <div class="progress-span">
                  <div id="myProgress" style="margin-right: 10px">
                    <div
                      id="myBar"
                      [ngStyle]="{ width: row.course_progress + '%' }"
                    ></div>
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Start Date" prop="course.created_at">
              <ng-template let-row="row" ngx-datatable-cell-template>
                {{ row.started | date : 'MMM dd, yyyy' }}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Completed Date">
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <p *ngIf="row.course_progress.toString() == '100'">
                  {{ row.completed_at | date : 'MMM dd, yyyy' }}
                </p>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
            >
              <ng-template
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="msgMenu"
                  class="hidden-on-open"
                >
                  <mat-icon class="text-muted">more_vert</mat-icon>
                </button>
                <mat-menu #msgMenu="matMenu">
                  <button
                    mat-menu-item
                    (click)="
                      viewCourseAttemptProgresses(
                        row.course.course_attempt_id,
                        row.course.course_id
                      )
                    "
                    *ngIf="row.course_progress.toString() == '100'"
                  >
                    View Progress Entries
                  </button>
                  <button
                    mat-menu-item
                    routerLink="/instructor/classroom/transcripts/{{
                      this.user_id
                    }}/course-detail/{{ row.courseAttempt.id }}"
                  >
                    View Detail
                  </button>
                  <button
                    mat-menu-item
                    (click)="editCertificate(row.course.user_certificate_id)"
                    *ngIf="row.course.user_certificate_id"
                  >
                    Edit Certificate
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      downloadCertificate(row.course.user_certificate_id)
                    "
                    *ngIf="row.course.certificate_awarded"
                  >
                    Download Certificate
                  </button>
                  <button
                    mat-menu-item
                    (click)="
                      downloadTranscript(
                        row.course.course.id,
                        row.course.course_attempt_id
                      )
                    "
                  >
                    Download Transcript
                  </button>
                  <button
                    mat-menu-item
                    (click)="enrollmentHistory(row.course.course_id)"
                  >
                    Enrollment History
                  </button>
                </mat-menu>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<div #image_container>
  <img
    #image
    style="visibility: hidden"
    [src]="url"
    alt=""
    crossOrigin="Anonymous"
  />
</div>

<ng-container *ngIf="export_transcript">
  <app-transcript-course-detail
    [course_attempt_id]="course_attempt_id"
    (done)="export()"
  ></app-transcript-course-detail>
</ng-container>
