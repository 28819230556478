import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class IdentityService {

  private subject = new BehaviorSubject<Identity>(new Identity())
  public identity = this.subject.asObservable();
  private returnToIdentity: Identity;

  constructor() {
    const user = localStorage.getItem('cirrus-user');
    const accessToken = localStorage.getItem('cirrus-token');
    const role = localStorage.getItem('cirrus-role');
    const userId = localStorage.getItem('cirrus-user-id');
    const ctcAdmin = JSON.parse(user)?.ctc_admin;
    this.subject.next({ user, accessToken, role, userId, ctcAdmin })
  }

  updateIdentity(identity: Identity) {
    const nextIdentity = Object.assign({}, this.subject.value, identity);
    if (this.shouldUpdateIdentity(nextIdentity)) {
      this.subject.next(nextIdentity);
    }
  }

  impersonate(identity: Identity) {
    this.returnToIdentity = this.subject.value;
    this.subject.next(identity);
  }

  endImpersonation() {
    this.subject.next(this.returnToIdentity);
    this.returnToIdentity = null;
  }

  clear() {
    this.subject.next(new Identity())
  }

  private shouldUpdateIdentity(nextIdentity) {
    const currentKeys = Object.keys(this.subject.value);
    const nextKeys = Object.keys(nextIdentity);

    if (currentKeys.length !== nextKeys.length) {
      return true;
    }
    for (let key of currentKeys) {
      if (this.subject.value[key] !== nextIdentity[key]) {
        return true;
      }
    }

    return false;
  }
}

export class Identity {
  user?: unknown;
  role?: string;
  accessToken?: string;
  userId?: string;
  ctcAdmin? = false;
}
