import { Injectable } from '@angular/core';
import { Notification } from '@app/models/notification/notification';
import { User } from '@app/models/user/user';
import { HttpService } from '@app/services/http-service.service';

@Injectable()
export class NotificationService {
  notificationUrl = 'notifications';

  constructor(private http: HttpService) {}

  clearNotifications(notifications: Notification[]): Promise<boolean> {
    const url = `${this.notificationUrl}/clear`;
    return this.http
      .post<boolean>(url, JSON.stringify({ notifications }))
      .toPromise()
      .catch(NotificationService.handleError);
  }

  getMyNotifications(): Promise<Notification[]> {
    const url = `${this.notificationUrl}/my-notifications`;
    return this.http
      .get<Notification[]>(url)
      .toPromise()
      .catch(NotificationService.handleError);
  }

  // Returns pending instructor invites
  getPendingNotifications(): Promise<Notification[]> {
    const url = `${this.notificationUrl}/instructor/pending`;
    return this.http
      .get<Notification[]>(url)
      .toPromise()
      .catch(NotificationService.handleError);
  }

  acceptInvite(notification: Notification): Promise<Boolean> {
    const url = `instructors/new_invite/accept/${notification.id}`;
    return this.http
      .post<boolean>(url, null)
      .toPromise()
      .catch(NotificationService.handleError);
  }

  declineInvite(notification: Notification): Promise<Boolean> {
    const url = `instructors/new_invite/decline/${notification.id}`;
    return this.http
      .post<boolean>(url, null)
      .toPromise()
      .catch(NotificationService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.notificationUrl}/${id}`;
    return this.http
      .delete(url)
      .toPromise()
      .then(() => null)
      .catch(NotificationService.handleError);
  }

  create(notification: Notification, users: User[]): Promise<Notification> {
    const notific = {
      body: notification.body,
      notific_type: notification.notific_type,
      recipients: users,
    };

    return this.http
      .post<Notification>(this.notificationUrl, JSON.stringify(notific))
      .toPromise()
      .catch(NotificationService.handleError);
  }

  update(notification: Notification): Promise<Notification> {
    const url = `${this.notificationUrl}/${notification.id}`;
    return this.http
      .put(url, JSON.stringify(notification))
      .toPromise()
      .then(() => notification)
      .catch(NotificationService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
