import { Injectable } from '@angular/core';
import { pick } from 'lodash';
import { CourseAttempt } from '@app/models/course/course-attempt';
import { Observable } from 'rxjs';
import { UserCourse } from "@app/models/course/user-course";
import { Course } from "@app/models/course/course";
import { BaseApiV4Service } from '@app/base-api-v4.service';

@Injectable()
export class CourseAttemptService extends BaseApiV4Service{

  getCourseAttempt(courseAttemptId: number): Observable<CourseAttempt> {
    const path = `course_attempts/${courseAttemptId}`;
    return this.http.get<CourseAttempt>(this.buildRequestUrl(path));
  }

  getCourseAttemptsForUserCourse(userCourse: UserCourse): Observable<CourseAttemptIndex> {
    const path = `course_attempts`;
    const params = pick(userCourse, 'course_id', 'user_id');
    return this.http.get<CourseAttemptIndex>(this.buildRequestUrl(path), { params })
  }
}

class CourseAttemptIndex {
  id: number;
  course: Course;
  course_attempts: CourseAttemptIndexRow[];
}

export class CourseAttemptIndexRow {
  id: number;
  course_version?: string;
  started_at?: Date;
  completed_at?: Date;
  status?: string;
}
