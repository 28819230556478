<h2 class="text-body-header">Recent Purchase</h2>

<div>
  <div class="course-wrapper-alt course-padding-alt top-padding">
    <div class="max-width-course top-padding"
         fxLayout='row wrap'
         fxLayout.sm="row"
         fxLayout.xs="column"
         fxLayoutAlign.sm="start"
         fxLayoutAlign.xs="start"
         fxFlex.xs="100">
      <div fxFlex="100"
           fxFlex.sm="100"
           fxFlex.xs="100"
           fxLayout="column"
           fxLayoutAlign="start start">
        <div class="purchase-complete-wrapper">
          <div class="bold-txt">
            <mat-icon class="check">check_circle</mat-icon>
            Purchase complete!
          </div>
          <br />
          <div class="get-started-txt">Thank you for your order. You will see a charge of {{order.order_total | currency:'USD'}} from "Cirrus Design Corporation" on your credit card. For questions about your order, please contact
            <a class="link" href="mailto:learning@cirrusaircraft.com" target="_top">
              learning@cirrusaircraft.com.
            </a>
          </div>
          <br />
          <div class="get-started-txt"><span class="bold-txt">Get Started! </span>Visit the <span class="bold-txt">My Courses</span> page to view all of your purchased courses or
            <a class="link" (click)="viewReceipt()">
              view receipt
            </a>
          </div>
        </div>
        <h4>Jump right in</h4>
        <div fxLayout="row wrap" class="course-summary" *ngFor="let user_course of user_courses">
          <img *ngIf="user_course?.course?.image" [src]='user_course?.course?.image' alt="" class="course-img">
          <img *ngIf="!user_course?.course?.image" [src]="defaultCourseThumb" class="course-img"/>

          <div class="summary-col">
            <h5 class="course-name">{{user_course?.course?.title}}</h5>
            <button mat-button class="start-course-btn" [disabled]="loading !== -1" (click)="startCourse(user_course)"><img *ngIf="loading === user_course.id" width="25" src="assets/images/oval.svg" alt=""> <ng-container *ngIf="loading !== user_course.id">Start Course</ng-container></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
