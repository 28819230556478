<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">User Courses</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="full-width pb-1">
          <input
            type='text'
            style='padding:8px;margin:15px auto;width:30%;'
            placeholder='Filter User Courses'
            (keyup)='updateFilter($event)'
          />
        </div>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable #table class="material bg-white"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [loadingIndicator]="isLoading"
                         [rows]="rows"
                         [selected]="selected"
                         [selectionType]="'row'"
                         (select)="onSelect($event)">
            <ngx-datatable-column name="Course" prop='course.title'></ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
