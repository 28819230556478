<div fxFlex="100" fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0 pb-100" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Assignments</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class='full-width pb-1'>
          <button mat-raised-button color="primary" (click)="addAssignment()"> Create Assignment </button>
        </div>
        <div class="mat-box-shadow classroom-card">
          <ngx-datatable class="material bg-white"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [selected]="selected"
                         [selectionType]="'row'"
                         [rows]="rows">
            <ngx-datatable-column name="Name" [width]="'auto'"></ngx-datatable-column>
            <ngx-datatable-column name="Description" prop="desc" [width]="200"></ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false">
              <ng-template let-row='row' ngx-datatable-cell-template>
                <mat-icon class='edit-icon black' (click)='editAssignment(row)'>edit</mat-icon>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [width]="100"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false">
              <ng-template let-row='row' ngx-datatable-cell-template>
                <mat-icon class='delete-icon black' (click)='deleteAssignment(row)'>delete</mat-icon>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
