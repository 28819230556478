import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@app/services/data-services/content.service';

import { Content } from '@app/models/course/content/content';
import { ContentType, ContentTypeMap } from '@app/models/course/content/content-type';
import { Task } from '@app/models/course/content/task/task';
import { TaskService } from '@app/services/data-services/task.service';
import { slideInRight } from '@app/animations/animations';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddContentDialogComponent } from './add-content-dialog/add-content-dialog.component';

import { DragulaService } from 'ng2-dragula';
import { QuizService} from '@app/services/data-services/quiz.service';
import {COMMA, ENTER, TAB} from '@angular/cdk/keycodes';
import { FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import {HttpService} from '@app/services/http-service.service';
import {Subscription} from 'rxjs';
import { ExamService } from '@app/exams.service';

declare var UIkit: any;
declare var jQuery: any;

@Component({
  selector: 'app-add-content',
  templateUrl: './add-content.component.html',
  styleUrls: ['./add-content.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddContentComponent implements OnInit, AfterViewInit, OnDestroy {
  content_model: Content = new Content();
  map = ContentTypeMap;
  contentEnum = ContentType;
  accessToken: string;
  dialogRef: MatDialogRef<AddContentDialogComponent>;
  separatorKeysCodes = [ENTER, COMMA, TAB];
  selectedQuiz: any;
  selectedExam: any;
  @ViewChild('image', { static: true }) image: any;
  @ViewChild('imageUpload') imageUpload: any;
  imageUrl = '';
  is_loading = false;
  editorConfig = {
    "toolbar": [
      ["bold", "italic", "underline", "strikeThrough", "superscript", "subscript"],
      ["fontName", "fontSize", "color"],
      ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull", "indent", "outdent"],
      ["cut", "copy", "delete", "removeFormat", "undo", "redo"],
      ["paragraph", "blockquote", "removeBlockquote", "horizontalLine", "orderedList", "unorderedList"],
      ["link", "unlink", "image", "code"]
    ]
  };

  public imageUploader: FileUploader = new FileUploader({ });
  public fileUploader: FileUploader = new FileUploader({ });
  public imageUploadUploader: FileUploader = new FileUploader({ });

  drakes: any[] = ['task-bag', 'quiz-bag'];
  subs: any[] = [];
  quizzes: any[];
  drag_subs = new Subscription();

  constructor(private dragulaService: DragulaService,
              private router: Router,
              private contentService: ContentService,
              public snackbar: MatSnackBar,
              private quizService: QuizService,
              private taskService: TaskService,
              private examService: ExamService,
              public dialog: MatDialog,
              private http: HttpService) {
    this.drag_subs.add(
      this.dragulaService.drop().subscribe(() => {
        this.setOrder();
      })
    );
  }

  ngOnInit() {
    this.imageUrl = this.http.getFullUrl(this.imageUrl + 'images/upload/rich_text');
    const url = this.http.getFullUrl('contents/upload');
    const token = localStorage.getItem('cirrus-token');

    this.imageUploader.setOptions({url: url, itemAlias: 'image'});
    this.imageUploader.authTokenHeader = 'Access-Token';
    this.imageUploader.authToken = localStorage.getItem('cirrus-token');
    this.imageUploader.onAfterAddingFile = (item => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    });
    this.imageUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {

      this.content_model.placeholder_image = JSON.parse(response).location;
      if (this.imageUploadUploader.queue.length) {
        this.imageUploadUploader.uploadAll();
      } else if (this.fileUploader.queue.length) {
        this.setFileUploaderOptions(this.content_model.content_type);
      } else {
        this.createContent();
      }
    };

    this.imageUploadUploader.setOptions({url: url, itemAlias: 'image'});
    this.imageUploadUploader.authTokenHeader = 'Access-Token';
    this.imageUploadUploader.authToken = localStorage.getItem('cirrus-token');
    this.imageUploadUploader.onAfterAddingFile = (item => {
      document.getElementById('content-img').style.display = 'block';
      item.withCredentials = false;
    });
    this.imageUploadUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.content_model.upload_image = JSON.parse(response).location;
      if (this.fileUploader.queue.length) {
        this.setFileUploaderOptions(this.content_model.content_type);
      } else {
        this.createContent();
      }
    };

    this.fileUploader.setOptions({url: url, itemAlias: 'file'});
    this.fileUploader.authTokenHeader = 'Access-Token';
    this.fileUploader.authToken = localStorage.getItem('cirrus-token');
    this.fileUploader.onAfterAddingFile = (item => {
      this.content_model.content_filename = item._file.name;

      item.withCredentials = false;
    });
    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      this.content_model.contentFile = JSON.parse(response).location;
      this.content_model.content_filename = JSON.parse(response).location;
      this.createContent();
    };
  }

  ngAfterViewInit() {
  }

  getTasks() {

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.content_model.meta_tags.push(value.trim());

    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: any): void {
    const index = this.content_model.meta_tags.indexOf(tag);

    if (index >= 0) {
      this.content_model.meta_tags.splice(index, 1);
    }
  }

  upload() {
    this.is_loading = true;
    if (this.imageUploader.queue.length) {
      this.imageUploader.uploadAll();
    } else if (this.imageUploadUploader.queue.length) {
      this.imageUploadUploader.uploadAll();
    } else if (this.fileUploader.queue.length) {
      this.setFileUploaderOptions(this.content_model.content_type);
    } else {
      this.createContent();
    }
    document.getElementById('content-img').style.display = 'none';
  }

  createContent() {
    if (this.content_model.tasks) {
      for (let i = 0; i < this.content_model.tasks.length; i++) {
        this.content_model.tasks[i].order = i;
      }
    }

    this.contentService.create(this.content_model)
      .then((content) => this.content_model = new Content())
      .then(() => this.fileUploader.clearQueue())
      .then(() => this.imageUploader.clearQueue())
      .then(() => this.imageUploadUploader.clearQueue())
      .then(() => this.image.nativeElement.style.hidden = 'hidden')
      .then(() => {
        this.snackbar.open('Content added.', '', {
          duration: 2500
        });

        this.router.navigate(['admin/courses/content']);
      });
  }

  addTasks(data) {
    if (data instanceof Array) {
      for (const item of data) {
        // First check if question has already been added
        // const index = this.content_model.tasks.indexOf(item);
        // Only add if doesn't exists
        // if (index === -1) {
          item.required_successful_attempts = 1;
          item.task_type = "practice";
          this.content_model.tasks.push(item);
        // }
      }
    }
  }

  cancel() {
    this.router.navigate(['admin/courses/content']);
  }

  openDialog(type: string, task?: Task) {
      this.dialogRef = this.dialog.open(AddContentDialogComponent, {
        width: '800px',
        height: '600px',
        data: type
      });

      if (type === 'task') {
        this.dialogRef.afterClosed().subscribe((data) => {
          this.addTasks(data);
        });
      } else if (type === 'badge') {
        this.dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            task.badge = data;
          }
        });
      } else if (type === 'quiz') {
        this.dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            this.selectedQuiz = data;
            this.content_model.quiz_id = data.id;
          }
        });
      } else {
        this.dialogRef.afterClosed().subscribe((data) => {
          if (data) {
            this.selectedExam = data;
            this.content_model.exam_id = data.id;
          }
        });
      }
  }

  removeTask(task) {
    if (this.content_model.tasks) {
      const index = this.content_model.tasks.indexOf(task);
      if (index !== -1) {
        this.content_model.tasks.splice(index, 1);
      }
    }
  }

  clearContent() {
    this.content_model.tasks = [];
    this.content_model.url = '';
  }

  ngOnDestroy() {
    if (this.content_model.category === this.contentEnum.flight_assessment || this.content_model.category === this.contentEnum.ground_assessment) {
      for (const drake of this.drakes) {
          this.dragulaService.destroy(drake);
      }
    }
    this.drag_subs.unsubscribe();
  }

  setFileUploaderOptions(content_type) {
    let url = '';
    if (content_type == this.contentEnum.scorm) {
      url = this.http.getFullUrl('contents/upload/scorm');
    } else if (content_type == this.contentEnum.interactive) {
      url = this.http.getFullUrl('contents/upload/interactive');
    } else if (content_type == this.contentEnum.tinCan) {
      url = this.http.getFullUrl('contents/upload/tincan');
    } else {
      url = this.http.getFullUrl('contents/upload');
    }

    this.fileUploader.setOptions({url: url, itemAlias: 'file'});
    this.fileUploader.authTokenHeader = 'Access-Token';
    this.fileUploader.authToken = localStorage.getItem('cirrus-token');
    this.fileUploader.onCompleteItem = (item: any, response: any, status: any, headers: ParsedResponseHeaders) => {
      if (status === 201) {
        this.content_model.contentFile = JSON.parse(response).location; // file is uploaded here
        this.content_model.blob_directory = JSON.parse(response).blob_directory;
        this.content_model.starter_file = JSON.parse(response).resource_file;
        this.createContent();
      } else {
        alert('There was an error uploading your file');
      }
    };

    this.fileUploader.uploadAll();
  }

  setOrder() {
    this.content_model.tasks = this.content_model.tasks.map((task, index) => {
      task.order = index;
      return task;
    });
  }

  updateType(index, value) {
    this.content_model.tasks[index].task_type = value;
  }

}
