import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { DeferReason } from '@app/models/course/content/task/defer-reason';

@Injectable()
export class DeferReasonService {
  deferReasonUrl = 'defer_reasons';

  constructor(private http: HttpService) {
  }

  getDeferReasons(): Promise<DeferReason[]> {
    return this.http.get<DeferReason[]>(this.deferReasonUrl)
      .toPromise()
      .catch(DeferReasonService.handleError);
  }

  getDeferReason(id: number): Promise<DeferReason> {
    const url = `${this.deferReasonUrl}/${id}`;
    return this.http.get<DeferReason>(url)
      .toPromise()
      .catch(DeferReasonService.handleError);
  }

  create(reason: DeferReason): Promise<DeferReason> {
    return this.http.post<DeferReason>(this.deferReasonUrl, JSON.stringify(reason))
      .toPromise()
      .catch(DeferReasonService.handleError);
  }

  update(reason: DeferReason): Promise<DeferReason> {
    const url = `${this.deferReasonUrl}/${reason.id}`;
    return this.http.put<DeferReason>(url, JSON.stringify(reason))
      .toPromise()
      .catch(DeferReasonService.handleError);
  }

  delete(reason: DeferReason): Promise<Boolean> {
    const url = `${this.deferReasonUrl}/${reason.id}`;
    return this.http.delete<boolean>(url)
      .toPromise()
      .catch(DeferReasonService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
