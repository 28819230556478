import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormControl } from '@angular/forms';

import { Course } from '../../../models/course/course';

import { TempDataServiceService } from '../../../services/data-services/temp-data-service.service';
import { GlobalEventManagerService } from '../../../services/global-event-manager/global-event-manager.service';

import * as fromCourse from '../course/store/course.reducers';
import * as CourseActions from '../course/store/course.actions';
import { CourseService } from '@app/services/data-services/course.service';
import { CourseCategoryService } from '@app/services/data-services/course-category.service';
import { CourseCategory } from '@app/models/course/course-category';
import { AircraftService } from '@app/services/data-services/aircraft.service';
import { Aircraft } from '@app/models/aircraft/aircraft';
import { AvionicsService } from '@app/services/data-services/avionics.service';
import { Avionics } from '@app/models/aircraft/avionics';
import { UserCourse } from '@app/models/course/user-course';
import { environment } from '../../../../environments/environment';
import { UserCourseAgreementDialogComponent } from '../my-courses/user-course-agreement-dialog/user-course-agreement-dialog.component';
import { ConfigurationService } from '@app/services/data-services/configuration.service';
import { FeatureService } from '@app/feature.service';

@Component({
  selector: 'app-course-catalog',
  templateUrl: './course-catalog.component.html',
  styleUrls: ['./course-catalog.component.scss'],
})
export class CourseCatalogComponent implements OnInit {
  currentMax = 4;
  courses: any;
  sortFilter = 0;
  dialogRef: any;
  filteredCourses: any = [];
  courseCategories: CourseCategory[];
  aircrafts: Aircraft[] = [];
  avionics: Avionics[] = [];

  editing = false;

  courseFilter: FormControl;

  defaultCourseThumb = environment.defaultThumbnailCourse;

  avionicsFilter: FormControl;
  categoryFilter: FormControl;
  aircraftFilter: FormControl;
  trainingFilter: FormControl;

  selectedAvionic: Avionics;
  selectedCategory: CourseCategory;
  selectedCourse: Course;
  selectedAircraft: Aircraft;
  learningCatalogSettings: any = {};
  userCourses: UserCourse[] = [];
  filter = '';
  is_loading = false;
  sort: any = '';
  coursePreviewEnabled: boolean;

  constructor(
    public dialog: MatDialog,
    private globalEventManagerService: GlobalEventManagerService,
    private tempService: TempDataServiceService,
    private courseService: CourseService,
    private categoryService: CourseCategoryService,
    private configurationService: ConfigurationService,
    private avionicService: AvionicsService,
    private aircraftService: AircraftService,
    private store: Store<fromCourse.State>,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private featureService: FeatureService
  ) { }

  ngOnInit() {
    this.featureService
    .isFeatureEnabled('course_preview')
    .subscribe(feature => (this.coursePreviewEnabled = feature));

    this.store.dispatch(new CourseActions.SetPreview(false));

    this.globalEventManagerService.topBar.emit(true);

    this.avionicService.getAvionics().then(avionics => {
      this.avionics = avionics;
    });

    this.aircraftService.getAircraft().then(aircrafts => {
      this.aircrafts = aircrafts;
    });

    this.categoryService.getCategories().then(categories => {
      this.courseCategories = categories;
    });

    this.is_loading = true;

    this.configurationService.find('learning-catalog').then(configuration => {
      this.learningCatalogSettings = configuration;
    });

    if (this.isLoggedIn()) {
      this.courseService.getUserCourses().then(userCourses => {
        this.userCourses = userCourses;
      });
    }

    this.courseService.getCourseCatalog().then(courses => {
      this.courses = courses;
      this.is_loading = false;

      this.courses = this.markEnrolled();
      this.filteredCourses = this.filterCourses();
    });

    this.avionicsFilter = new FormControl('all');
    this.trainingFilter = new FormControl('all');
    this.aircraftFilter = new FormControl('all');
    this.categoryFilter = new FormControl('all');
    this.courseFilter = new FormControl('all');
  }

  markEnrolled() {
    return this.courses.map(course => {
      course.is_enrolled = this.isEnrolled(course);
      return course;
    });
  }

  public filterChanged() {
    this.filteredCourses = this.filterCourses();
  }

  isLoggedIn() {
    return localStorage.getItem('cirrus-token') != null;
  }

  openDialogOrNavigate(course: Course): void {
    this.router.navigate([`/courses/${course.id}`]);
  }

  filterCourses() {
    const filteredCourses = this.courses
      .filter(course => {
        if (this.aircraftFilter.value === 'all') {
          return true;
        }
        return (
          course.aircrafts.filter(
            aircraft => aircraft.id === this.aircraftFilter.value
          ).length !== 0
        );
      })
      .filter(course => {
        if (this.avionicsFilter.value === 'all') {
          return true;
        }
        return (
          course.avionics.filter(
            avionic => avionic.id === this.avionicsFilter.value
          ).length !== 0
        );
      })
      .filter(course => {
        if (this.trainingFilter.value === 'all') {
          return true;
        }
        const courseExists = course.course_category_id !== 'undefined';
        if (!courseExists) {
          return false;
        }

        return (
          course.course_categories.filter(
            c => c.id === this.trainingFilter.value
          ).length > 0
        );
      })
      .filter(course => {
        if (this.courseFilter.value === 'all') {
          return true;
        }
        if (this.courseFilter.value === 'enrolled') {
          return course.is_enrolled;
        }
        if (this.courseFilter.value === 'free') {
          return !(course.list_price > 0);
        } else {
          return course.list_price;
        }
      })
      .filter(course => {
        if (this.filter.trim() === '') {
          return true;
        }
        return (
          course.overview
            .toLowerCase()
            .indexOf(this.filter.toLowerCase().trim()) !== -1 ||
          course.title
            .toLowerCase()
            .indexOf(this.filter.toLowerCase().trim()) !== -1 ||
          course.created_by
            .toLowerCase()
            .indexOf(this.filter.toLowerCase().trim()) > -1
        );
      });
    if (this.sort !== '') {
      return [
        {
          courses: filteredCourses.sort((a, b) => {
            const t = o => o.title.toLowerCase();
            if (t(a) < t(b)) return -1 * this.sort;
            if (t(a) > t(b)) return 1 * this.sort;
            return 0;
          }),
        },
      ];
    }

    let finalFilteredCourses = [];
    this.learningCatalogSettings.settings.forEach((config, index) => {
      const category = this.courseCategories.find(
        category => category.id === config.id
      );
      const filteredCategory = {
        category: category,
        courses: [],
        showAll: config.showAll,
        currentMax: this.currentMax,
      };
      config.courses.forEach(courseId => {
        const course = filteredCourses.find(course => course.id === courseId);
        if (course !== undefined) filteredCategory.courses.push(course);
      });
      if (filteredCategory.courses.length > 0) {
        finalFilteredCourses.push(filteredCategory);
      }
    });

    if (this.trainingFilter.value !== 'all') {
      finalFilteredCourses = finalFilteredCourses.filter(
        course => course.category.id === this.trainingFilter.value
      );
    }

    return finalFilteredCourses;
  }

  selectEnrolledCourse(course: any) {
    const userCourse =
      this.userCourses[
      this.userCourses.map(item => item.course.id).indexOf(course.id)
      ];
    if (userCourse.has_agreed === false) {
      this.selectCourse(userCourse);
    } else {
      Promise.all([
        this.courseService.getUserCourse(userCourse.id),
        this.courseService.getCourseContent(userCourse),
      ])
        .then(values => {
          const [user_course, course_content] = values;
          this.store.dispatch(new CourseActions.SelectCourse(user_course));
          this.store.dispatch(new CourseActions.SetPreview(false));
          this.store.dispatch(
            new CourseActions.SelectCourseContent(course_content)
          );
          this.router.navigate([`/courses/${course.id}`]);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  isEnrolled(course) {
    let userCourse;
    if (this.userCourses.length > 0) {
      userCourse = this.userCourses.find(
        userCourse => userCourse.course.id === course.id
      );
    }
    return userCourse && !userCourse.is_unenrolled;
  }

  showMore(category) {
    category.currentMax = category.courses.length;
    category.editing = !category.editing;
  }

  showLess(category) {
    category.currentMax = 4;
    category.editing = !category.editing;
  }

  filterItems(type: string) {
    this.courses.sort((a, b) => (a[type] > b[type] ? 1 : -1));
  }

  selectCourse(selected_user_course: any) {
    if (
      (selected_user_course.course.has_agreement &&
        selected_user_course.has_agreed) ||
      !selected_user_course.course.has_agreement
    ) {
      this.store.dispatch(new CourseActions.HasAgreed());
      this.courseService
        .getUserCourse(selected_user_course.id)
        .then(user_course => {
          this.store.dispatch(new CourseActions.SelectCourse(user_course));
        });
      this.courseService
        .getCourseContent(selected_user_course)
        .then(course_content => {
          this.store.dispatch(
            new CourseActions.SelectCourseContent(course_content)
          );
        })
        .then(() => {
          this.router.navigate([`/courses/${selected_user_course.id}`]);
        });
    } else {
      this.openUserAgreementDialog(selected_user_course);
    }
  }

  gridSize() {
    return window.matchMedia(`(max-width: 1200px)`).matches
      ? window.matchMedia(`(max-width: 868px)`).matches
        ? window.matchMedia(`(max-width: 500px)`).matches
          ? 1
          : 2
        : 3
      : 4;
  }

  addToCart(course: any) {
    this.store.dispatch(new CourseActions.AddToCart(course));
  }

  buyNow(course: any) {
    this.store.dispatch(new CourseActions.AddToCart(course));
  }

  openUserAgreementDialog(course, url?: string) {
    const dialogRef = this.dialog.open(UserCourseAgreementDialogComponent, {
      width: '40%',
      data: course,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.agreed) {
        this.selectCourse(course);
      }
    });
  }
}
