import { Injectable } from '@angular/core';
import { Content } from '@app/models/course/content/content';
import { HttpService } from '@app/services/http-service.service';
import { Bookmark } from '@app/models/course/bookmark';
import { Observable } from 'rxjs';

@Injectable()
export class ContentService {
  contentUrl = 'contents';

  constructor(private http: HttpService) {}

  getAllContent(): Promise<Content[]> {
    return this.http.get<Content[]>(this.contentUrl)
      .toPromise()
      .catch(ContentService.handleError);
  }

  getContent(): Observable<any[]> {
    return this.http.get<Content[]>(this.contentUrl)

  }

  getVideoContent(): Observable<any> {
    return this.http.get<Content[]>(`${this.contentUrl}/video`)
  }

  getOneContent(id: number): Promise<Content> {
    const url = `${this.contentUrl}/${id}`;
    return this.http.get<Content>(url)
      .toPromise()
      .catch(ContentService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.contentUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(ContentService.handleError);
  }

  bookmark(id: number): Promise<Bookmark> {

    const url = `${this.contentUrl}/bookmark/${id}`;
    return this.http.post<Bookmark>(url, JSON.stringify({}))
      .toPromise()
      .catch(ContentService.handleError);

  }

  removeBookmark(id: number): Promise<void> {
    const url = `${this.contentUrl}/bookmark/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(ContentService.handleError);

  }

  create(content: Content): Promise<Content> {
    return this.http
      .post<Content>(this.contentUrl, JSON.stringify(content))
      .toPromise()
      .catch(ContentService.handleError);
  }

  update(content: Content): Promise<void> {
    const url = `${this.contentUrl}/${content.id}`;
    return this.http
      .put(url, JSON.stringify(content))
      .toPromise()
      .then(() => null)
      .catch(ContentService.handleError);
  }

  checkForScorm(id: number): Promise<boolean> {
    const url = `${this.contentUrl}/check_for_content/${id}`;
    return this.http
      .post<boolean>(url, null)
      .toPromise()
      .catch(ContentService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
