import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService } from '@app/services/data-services/user.service';
import { FullstoryService } from '@app/services/data-services/fullstory.service';
import { Location } from '@angular/common';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';

import * as CheckoutActions from './ui/user/checkout/store/checkout.actions';
import * as fromApp from './store/app.reducers';
import { filter } from 'rxjs/operators';

declare var SFIDWidget: any;
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'app';
  private identity: any = null;
  location: Location;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    public oidcSecurityService: OidcSecurityService,
    private fullstoryService: FullstoryService
  ) {

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
          'event': 'pageView',
          'pagePath': event.urlAfterRedirects
        })

      });
  }

  ngOnInit() {
    const cirrusUser = JSON.parse(localStorage.getItem('cirrus-user'));
    this.oidcSecurityService.checkAuth().subscribe();

    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    this.fullstoryService.init(environment.fullstoryOrgId);
    if (cirrusUser){
      this.userService.idForAnalytics(cirrusUser);
    }

    const route = localStorage.getItem('requested-url');
    if (route) {
      this.router.navigate([route], { queryParams: {} });
      localStorage.removeItem('requested-url');
    }
    this.checkCart();
  }

  checkCart() {
    const checkout = localStorage.getItem('is-checkout');
    if (checkout) {
      this.router.navigate(['/shopping-cart'], { queryParams: {} });
      const cart = JSON.parse(localStorage.getItem('checkout-state'));
      if (cart) {
        this.store.dispatch(new CheckoutActions.SetOrder(cart.order));
        this.store.dispatch(new CheckoutActions.UpdateCart(cart.order));
      }
    } else {
      this.onFetchData();
    }
    localStorage.removeItem('is-checkout');
    localStorage.removeItem('checkout-state');
  }

  onFetchData() {
    if (localStorage.getItem('cirrus-token') && localStorage.getItem('cirrus-signout') !== 'true') {
      this.store.dispatch(new CheckoutActions.FetchOrder());
    }
  }
}
