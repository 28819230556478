import { Component, OnInit, Input } from '@angular/core';
import { RouterModule, Router } from '@angular/router';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.html',
  styleUrls: ['./tab-bar.scss']
})
export class UserTabbarComponent implements OnInit {
  @Input() navLinks: any[];

  constructor(private routerModule: RouterModule, private router: Router) { }

  ngOnInit() {
    // console.log(this.navLinks);
  }

}
