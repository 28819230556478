import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InstructorService } from '@app/services/data-services/instructor.service';
import { UserService } from '@app/services/data-services/user.service';
import { Instructor } from '@app/models/instructor';
import { User } from '@app/models/user/user';
import { UserRole, UserRoleMap } from '@app/models/user/user-role';
import { NotificationService } from '@app/services/data-services/notification.service';
import { NotificationMessageDialogComponent } from '../notification-message-dialog/notification-message-dialog.component';
import { Notification } from '@app/models/notification/notification';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-notification-admin-dialog',
  templateUrl: './notification-admin-dialog.component.html',
  styleUrls: ['./notification-admin-dialog.component.scss'],
})
export class NotificationAdminDialogComponent implements OnInit {
  selectedUsers: any[] = [];
  selectedInstructors: any[] = [];
  userRows: User[] = [];
  userTemp: User[] = [];
  instructorRows: any[] = [];
  instructorTemp: Instructor[] = [];
  users: User[] = [];
  instructors: Instructor[] = [];
  userRoleMap = UserRoleMap;
  userRoles = UserRole;
  selectedIndex = 0;
  messageDialogRef: any;

  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(
    public dialogRef: MatDialogRef<NotificationAdminDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private instructorService: InstructorService,
    private usersService: UserService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.instructorService
      .getInstructors()
      .then(
        (instructors) =>
          (this.instructorRows = this.instructorTemp = instructors)
      );

    this.usersService
      .getUsers()
      .then((users) => (this.userRows = this.userTemp = users));
  }

  clearSelected(event) {
    // console.log('clearing selected');
    this.selectedIndex = event.index;
    this.selectedUsers = this.selectedInstructors = [];
  }

  onInstructorSelect({ selected }) {
    this.selectedInstructors.splice(0, this.selectedInstructors.length);
    this.selectedInstructors.push(...selected);
    // console.log('selected instructors', this.selectedInstructors);
  }

  onUserSelect({ selected }) {
    this.selectedUsers.splice(0, this.selectedUsers.length);
    this.selectedUsers.push(...selected);
    // console.log('selected users', this.selectedUsers);
  }

  createMessage() {
    const width = '800px';
    const height = '600px';

    this.messageDialogRef = this.dialog.open(
      NotificationMessageDialogComponent,
      {
        width,
        height,
        data:
          this.selectedIndex === 0
            ? this.selectedUsers.length
            : this.selectedInstructors.length,
      }
    );

    this.messageDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.sendNotifications(data);
        this.dialogRef.close(true);
      }
    });
  }

  sendNotifications(data) {
    const notification = new Notification();
    notification.body = data;

    if (this.selectedIndex === 0) {
      this.notificationService
        .create(notification, this.selectedUsers)
        .then(() => {
          this.snackBar.open(
            'Notification sent to ' + this.selectedUsers.length + ' users',
            '',
            { duration: 2000 }
          );
        });
    } else {
      const users = this.selectedInstructors.map(
        (instructor) => instructor.user
      );
      this.notificationService.create(notification, users).then(() => {
        this.snackBar.open(
          'Notification sent to ' + users.length + ' instructors',
          '',
          { duration: 2000 }
        );
      });
    }
  }

  close() {}

  updateUserFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.userTemp.filter(function (d) {
      return (
        d.email.toLowerCase().indexOf(val) !== -1 ||
        $this.userRoleMap[$this.userRoles[d.role]]
          .toLowerCase()
          .indexOf(val) !== -1 ||
        !val
      );
    });

    // Update rows
    this.userRows = temp;
    this.table.offset = 0;
  }

  updateInstructorFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.instructorTemp.filter(function (d) {
      return (
        d.firstName.toLowerCase().indexOf(val) !== -1 ||
        d.lastName.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });

    // Update rows
    this.instructorRows = temp;
    this.table.offset = 0;
  }
}
