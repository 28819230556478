import {Course} from '@app/models/course/course';

export class OrderLineItem {
  id?: number;
  order_id?: number;
  line_item_status?: number;
  product_id?: number;
  line_item_unit_price?: number;
  line_item_shipping?: number;
  line_item_tax?: number;
  line_item_discount?: number;
  line_item_quantity?: number;
  product_title?: string;
  product?: Course;
}

