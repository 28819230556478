export enum NotificationType {
  message = 0,
  student_invite = 1,
  instructor_invite = 2,
  badge = 3,
  certificate = 4,
  training_center_invite = 5,
}

export const NotificationTypeMap = {
  0: 'New Message',
  1: 'New Student Invite',
  2: 'New Instructor Invite',
  3: 'New Badge Completion',
  4: 'New Certificate Completion',
  5: 'New Training Center Invite',
};
