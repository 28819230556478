import {TaskType} from '@app/models/course/content/task/task';

export class TaskDeferment {
  id?: number;
  course_attempt_id?: number;
  course_id?: number;
  user_id?: number;
  content_id?: number;
  task_id?: number;
  lesson_id?: number;
  stage_id?: number;
  task_type?: TaskType;
  defer_reasons?: string[];
}
