import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BaseApiV4Service {
  constructor(
    protected http: HttpClient,
    @Inject('APIV4BaseUrl') private readonly baseUrl: string
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  protected buildRequestUrl(path: string): string {
    return `${this.baseUrl}/${path}`;
  }
}
