import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {BookshelfContentType, BookshelfContentTypeMap, ContentBookshelf} from '@app/models/content-bookshelf';
import {BookshelfService} from '@app/services/data-services/bookshelf.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-bookshelf-manager-overview',
  templateUrl: './bookshelf-manager-overview.component.html',
  styleUrls: ['./bookshelf-manager-overview.component.scss']
})
export class BookshelfManagerOverviewComponent implements OnInit {

  rows: ContentBookshelf[] = [];
  temp: ContentBookshelf[] = [];
  types = BookshelfContentType;
  map = BookshelfContentTypeMap;


  selected = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private router: Router, private bookshelfService: BookshelfService) { }

  ngOnInit() {
    this.bookshelfService.getInstructorBookshelf()
      .then(content => this.rows = this.temp = content);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || d.created_by.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  editContent(row) {
    this.router.navigate(['instructor/bookshelf/manage', row.id]);
  }

  removeContent(row) {
    if (confirm('Are you sure you want to remove content item?')) {
      this.bookshelfService.delete(row.id)
        .then(() => this.rows = this.rows.filter((item) => item.id !== row.id));
    }
  }

  navigate(url) {
    this.router.navigate([url]);
  }

}
