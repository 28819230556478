<mat-nav-list class="side-nav" role="list" id="user-sidenav" *ngIf="menuType !== 'help'">
    <!--==== Side menu items ====-->
    <div class="icon-menu" *ngIf="hasIconTypeMenuItem">
        <!-- Icon menu separator -->
        <div class="mt-1 mb-1 icon-menu-separator">
            <mat-divider [style.margin]="'0 -24px'"></mat-divider>
            <span [ngStyle]="{paddingLeft: 0, marginLeft: '-6px'}" class="nav-section-title text-muted">{{iconTypeMenuTitle}}</span>
        </div>
        <!-- Icon menu items -->
        <div class="icon-menu-item" *ngFor="let item of menuItems">
            <button
                    *ngIf="!item.disabled && item.type === 'icon'"
                    mat-raised-button
                    [matTooltip]="item.tooltip"

                    routerLink="/{{item.state}}"
                    routerLinkActive="mat-bg-primary">
                <mat-icon>{{item.icon}}</mat-icon>
            </button>
        </div>
    </div>

    <!-- Regular menu items -->
    <div class="workbook-sidenav" *ngIf="menuType === 'workbook'">
        <h5>Course Lessons</h5>
        <mat-accordion id="lesson-quizzes-accordion">
            <mat-expansion-panel *ngFor="let item of menuItems; let f = first" class="user-sidenav expansion-bg no-padding-panel panel-buffer"
                                 [expanded]="f ? 'true' : 'false'">
                <mat-expansion-panel-header class="side-nav-header">
                    <!--<i class="material-icons arrow" style='transform: rotate(180deg);'>arrow_drop_down</i>-->
                    {{item?.title}}
                </mat-expansion-panel-header>
                <a
                        routerLinkActive #rla="routerLinkActive"
                        (click)="selectSection('stage' + item?.id + 'lesson' + lesson?.id)"
                        *ngFor="let lesson of item?.lessons | orderBy: 'lesson.order'; let i = index">
                    <div fxFlex='100'
                         fxLayout='row'>
                        <i class="icon-spacing-workbook">
                            <mat-icon class="sidenav-icon" [ngClass]="(lessonComplete(lesson)) ? 'solid' : 'regular'">
                                {{ (lessonComplete(lesson)) ? "label" : "label_outline" }}
                            </mat-icon>
                        </i>
                        <p class='lesson-nav-title'> {{ lesson?.title}}</p>
                    </div>
                    <!--<div class="lesson-nav-title">-->
                    <!--{{lesson.title}}</div>-->
                </a>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <!--<div class="help-sidenav" *ngIf="menuType === 'help';">-->
        <!--<mat-list>-->
            <!--<mat-list-item *ngFor="let item of menuItems; let f = first" (click)="selectSection(item.category.id)">-->
                <!--&lt;!&ndash;<i class="material-icons arrow" style='transform: rotate(180deg);'>arrow_drop_down</i>&ndash;&gt;-->
                <!--{{item?.category?.name}}-->
            <!--</mat-list-item>-->
        <!--</mat-list>-->
    <!--</div>-->

    <div *ngIf="menuType !== 'help' && menuType !== 'workbook'">
        <!-- Regular menu items -->
        <div *ngFor="let item of menuItems">
            <!-- if it's not desabled and not a separator and not icon -->
            <mat-list-item
                    [routerLink]="item.state"
                    sideNavAccordion
                    class="sidebar-list-item"
                    role="listitem"
                    *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'"
                    [ngClass]="{'has-submenu': item.type === 'dropDown'}"
                    [routerLinkActive]="['open','list-item-focused']">
                <a *ngIf="item.type === 'link'">
          <span
                  class="menu-item-tooltip"
                  [matTooltip]="item.tooltip"
                  matTooltipPosition="right"></span>
                    <mat-icon>{{item.icon}}</mat-icon>
                    <span>{{item.name}}</span>
                </a>
                <a *ngIf="item.type === 'dropDown'">
          <span
                  class="menu-item-tooltip"
                  [matTooltip]="item.tooltip"
                  matTooltipPosition="above"></span>
                    <mat-icon>{{item.icon}}</mat-icon>
                    <span>{{item.name }}</span>
                    <span fxFlex></span>
                    <mat-icon class="menu-caret">arrow_drop_down</mat-icon>
                </a>
                <a [href]="item.state" *ngIf="item.type === 'extLink'" target="_blank">
          <span
                  class="menu-item-tooltip"
                  [matTooltip]="item.tooltip"
                  matTooltipPosition="right"></span>
                    <mat-icon>{{item.icon}}</mat-icon>
                    <span>{{item.name }}</span>
                </a>
                <mat-nav-list class="sub-menu" role="list" *ngIf="item.type === 'dropDown'">
                    <mat-list-item
                            routerLinkActive="selected"
                            *ngFor="let subItem of item.sub">
                        <a routerLink="{{item.state ? '/'+item.state : ''}}/{{subItem.state}}">{{subItem.name }}</a>
                    </mat-list-item>
                </mat-nav-list>
            </mat-list-item>

            <!-- If item type is separator -->
            <div class="mt-1 mb-1" *ngIf="!item.disabled && item.type === 'separator'">
                <mat-divider></mat-divider>
                <span class="nav-section-title text-muted" *ngIf="!!item.name">{{item.name}}</span>
            </div>
        </div>
    </div>
</mat-nav-list>

<mat-nav-list class="side-nav" role="list" *ngIf="menuType === 'help'">
  <div class="help-sidenav">
    <mat-list>
      <mat-list-item *ngFor="let item of menuItems; let f = first" (click)="selectSection(item.category.id)">
        <!--<i class="material-icons arrow" style='transform: rotate(180deg);'>arrow_drop_down</i>-->
        {{item?.category?.name}}
      </mat-list-item>
    </mat-list>
  </div>
</mat-nav-list>
