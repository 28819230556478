import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TaskType } from '@app/models/course/content/task/task';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs'
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { StudentAssessmentsEntryDialogComponent } from './student-assessments-entry-dialog/student-assessments-entry-dialog.component';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { DatePipe } from '@angular/common';
import { StudentAssessmentsStandardsDialogComponent } from './student-assessments-standards-dialog/student-assessments-standards-dialog.component';
import { TaskService } from '@app/services/data-services/task.service';
import { ContentTask } from '@app/models/course/content/content-task';
import { TaskAttempt } from '@app/models/course/content/task/task-attempt';
import { LessonFlightLog } from '@app/models/course/workbook/lesson/lesson-flight-log';
import { LessonFlightLogService } from '@app/services/data-services/lesson-flight-log.service';
import * as fromApp from '../../../../../../store/app.reducers';
import * as AssessmentActions from '../store/assessment.actions';
import { TaskDeferment } from '@app/models/course/content/task/task-deferment';
import { TaskDefermentService } from '@app/services/data-services/task-deferment.service';
import { UserCourse } from '@app/models/course/user-course';
import { ProgressService } from '@app/services/data-services/progress.service';
import { ProgressCalculatorService } from '@app/services/helper/progress-calculator.service';
import { UserProgress } from '@app/models/user/user-progress';
import { Lesson } from '@app/models/course/workbook/lesson/lesson';
import { Stage } from '@app/models/course/workbook/stage/stage';
import { CourseService } from '@app/services/data-services/course.service';
import { Content } from '@app/models/course/content/content';

@Component({
  selector: 'app-student-assessments-entry',
  templateUrl: './student-assessments-entry.component.html',
  styleUrls: ['./student-assessments-entry.component.scss']
})
export class StudentAssessmentsEntryComponent implements OnInit, OnDestroy {
  selected: any[] = [];
  newFlightLogEntryDialogRef: MatDialogRef<StudentAssessmentsEntryDialogComponent>;
  newStandardDialogRef: MatDialogRef<StudentAssessmentsStandardsDialogComponent>;
  rows: any[] = [];
  log_rows: any[] = [];
  tasks: any = [];
  assessment: any = [];
  content_tasks: ContentTask[] = [];
  user_id: number;
  course_id: number;
  course_attempt_id: number;
  standards_missed = [];
  lessonFlightLogs: LessonFlightLog[] = [];
  user_name = '';
  content_id: number;
  lesson_flight_logs: LessonFlightLog[];
  task_attempts: TaskAttempt[];
  sub: Subscription = new Subscription();
  task_type = TaskType;
  completed_tasks = [];
  not_started_tasks = [];
  in_progress_tasks = [];
  deferments: TaskDeferment[] = [];
  assessment_deferred_tasks = [];
  selectedCourse: UserCourse = null;
  defer_reasons = [];
  deferredTasks: any[] = [];
  loading = false;
  progresses: UserProgress[] = [];
  assessment_progress = '';
  userCourse: UserCourse;
  content: Content;
  contentStage: Stage;
  contentLesson: Lesson;
  datePipe = new DatePipe('en-US');
  previousAssessmentOfType: Content;
  nextAssessmentOfType: Content;
  sorted = false;
  sortedTimes = 0;
  lastSort: any;
  isCompleted = false;
  @ViewChild('table', {static: true}) table: DatatableComponent;

  constructor(private router: Router,
              public dialog: MatDialog,
              private store: Store<fromApp.AppState>,
              private taskService: TaskService,
              private defermentService: TaskDefermentService,
              private lessonFlightLogService: LessonFlightLogService,
              private calculatorService: ProgressCalculatorService,
              private courseService: CourseService,
              private progressService: ProgressService) {
  }

  ngOnInit() {
    this.store.dispatch(new AssessmentActions.UpdateAssessmentDeferments());
    this.store.dispatch(new AssessmentActions.UpdateSelectedAssessmentLogbooks());

    this.sub.add(this.store.select('assessment').subscribe(state => {
      this.lessonFlightLogs = state.assessmentLogbooks;
      this.task_attempts = state.assessmentTaskEntries;
      if (state.selectedStudent.contact != null) {
        this.user_name = state.selectedStudent.contact.name || '';
      } else {
        this.user_name = '';
      }
      this.user_id = state.selectedStudent.id;
      this.deferments = state.deferments || [];
      this.selectedCourse = state.selectedCourse;
      this.course_id = state.selectedCourse.course_id;
      this.course_attempt_id = this.selectedCourse.course_attempt_id;
      this.defer_reasons = state.deferReasons.map(deferReason => deferReason.reason);
      this.rows = state.selectedAssessment.tasks.sort((a, b) => a.order - b.order);
      this.content_id = state.selectedAssessment.id;
      this.assessment = state.selectedAssessment;
      this.deferredTasks = state.selectedAssessmentDeferredTasks;
      this.loading = state.loading && state.loading.length > 0;
      this.previousAssessmentOfType = state.previousAssessmentOfType;
      this.nextAssessmentOfType = state.nextAssessmentOfType;
      this.isCompleted = state.isCompleted;
      this.log_rows = [];
      this.userCourse = state.selectedCourse;
      this.content = state.selectedAssessment;

      state.selectedAssessmentLogbookEntries.forEach(logbook => {
        this.log_rows.push(logbook);
      });
      this.getInitialTaskProgress();
    }));
    const { course_id, course_attempt_id } = this.selectedCourse;
    this.progressService.getProgressForStudentAssessment(this.user_id, course_id, course_attempt_id).then(progress => {
      this.progresses = progress;
      this.calculatorService.setProgresses(this.progresses);
      const assessment_prog = this.progresses.find(prog =>
        prog.progress_type.toString() === 'content' &&
        this.assessment &&
        prog.lesson_id === this.assessment.lesson_id &&
        prog.item_id === this.content_id);
      if (assessment_prog && assessment_prog.status) {
        this.assessment_progress = assessment_prog.status.toString();
      }
    });
    this.extractLessonAndStage();
  }

  hasSorted(event) {
    if (event.column === this.lastSort) {
      if (this.sortedTimes < 2) {
        this.sortedTimes++;
      } else {
        this.sortedTimes = 0;
        this.resetSort();
        const rows = this.rows.sort((a, b) => a.order - b.order);
        this.rows = [...rows];
      }
    } else {
      this.sortedTimes = 1;
    }
    this.lastSort = event.column;
  }

  resetSort() {
    this.table.sorts = [];
  }

  createEntry(type, index, row, standards_missed?, is_deferment?) {
    const new_attempt = new TaskAttempt();

    new_attempt.course_id = this.assessment.course_id;
    new_attempt.stage_id = this.assessment.stage_id;
    new_attempt.course_attempt_id = this.course_attempt_id;
    new_attempt.lesson_id = this.assessment.lesson_id;
    new_attempt.content_id = this.assessment.id;
    new_attempt.user_id = this.user_id;
    new_attempt.task_id = is_deferment ? row.task.id : row.id;
    // console.log(row);
    const task_type = is_deferment ? row.task.task_type : row.task_type;
    if (task_type.toString() === 'practice') {
      new_attempt.attempt_type = 0;
    } else {
      new_attempt.attempt_type = 1;
    }

    if (type === 'success') {
      new_attempt.success = 1;
    } else if (type === 'perfect') {
      new_attempt.perfect = 1;
    } else {
      new_attempt.failed = 1;
      new_attempt.standards_missed = standards_missed;
    }

    this.taskService.taskEntry(new_attempt)
      .then(task_attempt => {
        this.store.dispatch(new AssessmentActions.AddAttempt(task_attempt));
        this.getTasksProgresses();
      });
  }

  checkProgress() {
    const progress = this.getAssessmentProgressItem();
    this.assessment_progress = progress ? progress.status.toString() : 'not_started';
  }

  updateProgress() {
    const progress = this.getAssessmentProgressItem();
    if (progress) {
      if (progress.status.toString() === 'not_started') {
        this.updateOrCreateInProgress();
      } else {
        this.checkProgress();
      }
    } else if (!progress) {
      this.updateOrCreateInProgress();
    } else {
      this.checkProgress();
    }
  }

  updateOrCreateInProgress(status?) {
    // this.contentStage = {id: this.assessment.stage_id};
    // this.contentLesson = {id: this.assessment.lesson_id};
    console.log('updateOrCreateInProgress', this.userCourse);
    this.calculatorService.setProgresses(this.progresses);
    console.log('Progresses: ', this.progresses);
    const tempStatus = status ? status : 1;
    // console.log('Status 1: ', status);
    this.contentStage = this.userCourse.workbook.stages.find(stage => stage.id == this.assessment.stage_id);
    // console.log('Assessment Stage ID: ', this.assessment.stage_id);
    this.contentLesson = this.contentStage.lessons.find(lesson => lesson.id == this.assessment.lesson_id);
    // console.log('Assessment Lesson ID: ', this.assessment.lesson_id);
    this.calculatorService.syncProgressForContent(this.course_attempt_id,
      {course_id: this.course_id},
      {id: this.assessment.stage_id},
      {id: this.assessment.lesson_id},
      {id: this.content_id},
      null,
      tempStatus,
      false,
      this.user_id)
      .then((res) => {
        console.log('Calculator Service Result: ', res);
        if (res) {
          const { course_id, course_attempt_id } = this.selectedCourse;
          this.progressService.getProgressForStudentAssessment(this.user_id, course_id, course_attempt_id).then(progress => {
            console.log('getUserProgressCourseDetail Result:', progress);
            this.progresses = progress;
            this.calculatorService.setProgresses(this.progresses);
            console.log('setProgresses Result:', this.progresses);
            this.checkProgress();
          });
        }
      });
    console.log('Status 2: ', status);
    if (status === 2) {
      this.checkProgress();
      console.log('If Status = 2: ', status);
      this.calculatorService.checkLessonEntryForSpecificUser(this.course_attempt_id, this.user_id, this.userCourse, this.contentStage, this.contentLesson).then(() => {
        this.calculatorService.checkStageEntryForSpecificUser(this.course_attempt_id, this.user_id, this.userCourse, this.contentStage).then(() => {
          this.calculatorService.checkCourseEntryForSpecificUser(this.course_attempt_id, this.user_id, this.userCourse).then(() => {
          });
        });
      });
    } else if (status === 1 && this.assessment_progress === 'completed') {
      this.courseService.changeToInProgress(this.course_attempt_id, this.user_id, this.assessment.lesson_id, this.assessment.stage_id)
        .then(changedProgresses => null);
      console.log('If Status = 1: ', status);
    }
  }

  extractLessonAndStage() {
    for (const stage of this.selectedCourse.course.workbook.stages) {
      for (const lesson of stage.lessons) {
        for (const content of lesson.contents) {
          if (content.id === this.assessment.id) {
            this.contentLesson = lesson;
            this.contentStage = stage;
          }
        }
      }
    }
  }

  completeAssessment() {
    if ((this.rows.length - this.assessment_deferred_tasks.length - this.completed_tasks.length) === 0) {
      console.log('rows length: ', this.rows.length);
      console.log('assessment deferred tasks length: ', this.assessment_deferred_tasks.length);
      console.log('completed tasks length: ', this.completed_tasks.length);
      if (this.log_rows.length > 0) {
        // console.log('log rows length: ', this.log_rows.length);
        if (this.checkForCompletedDeferrals()) {
          if (confirm('Are you sure you want to complete this assessment?')) {
            this.updateOrCreateInProgress(2);
          }
        } else {
          alert('Cannot complete assessment. You must complete all previous assessment\'s deferrals.');
        }
      } else {
        alert('Cannot complete assessment. You must have at least one logbook entry.');
      }
    } else {
      alert('Cannot complete assessment. There are still some incomplete tasks. All tasks must be completed or deferred before this assessment can be completed.');
    }
  }

  getAssessmentProgressItem() {
    return this.progresses.find(progress =>
      progress.progress_type.toString() === 'content' &&
      this.assessment &&
      progress.lesson_id === this.assessment.lesson_id &&
      progress.item_id === this.content_id
    );
  }

  getPerfect(row) {
    return this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === row.task_type.toString() && task_attempt.task_id === row.id && task_attempt.content_id === this.content_id && task_attempt.perfect).length;
  }

  getMissed(row) {
    return this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === row.task_type.toString() && task_attempt.task_id === row.id && task_attempt.content_id === this.content_id && task_attempt.failed).length;
  }

  getPassed(row) {
    return this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === row.task_type.toString() && task_attempt.task_id === row.id && task_attempt.content_id === this.content_id && task_attempt.success).length;
  }

  undoEntry(row, is_deferment?) {
    // console.log(row);
    const task = is_deferment ? row.task : row;
    const attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === task.task_type.toString() && task_attempt.task_id === task.id && task_attempt.content_id === this.content_id);
    if (attempts.length) {
      attempts.sort((a, b) => a.id - b.id);
      const attempt = attempts.pop();
      this.removeAttempts([attempt], row);
    }
  }

  deferTask(task, index, defer_reasons?) {
    if (!this.nextAssessmentOfType) {
      return;
    }
    const deferment = new TaskDeferment();
    deferment.lesson_id = this.assessment.lesson_id;
    deferment.content_id = this.content_id;
    deferment.course_attempt_id = this.course_attempt_id;
    deferment.course_id = this.selectedCourse.course_id;
    deferment.task_id = task.id;
    deferment.defer_reasons = defer_reasons;
    deferment.stage_id = this.assessment.stage_id;
    deferment.user_id = this.user_id;

    if (task.task_type.toString() === 'practice') {
      deferment.task_type = this.task_type.practice;
    } else {
      deferment.task_type = this.task_type.perform;
    }

    this.defermentService.create(deferment)
      .then(res => {
        this.store.dispatch(new AssessmentActions.AddDeferment(res));
        this.getTasksProgresses();
      });
  }

  undoDefer(row, index) {
    const defermentsToRemove = this.getDeferments(index);

    if (defermentsToRemove.length) {
      this.removeDeferments(defermentsToRemove);
    }
  }

  resetTask(row, index, is_deferment?) {
    const task = is_deferment ? row.task : row;
    const attemptsToRemove = this.task_attempts.filter(task_attempt => task_attempt.task_id === task.id && task_attempt.content_id === this.assessment.id && task_attempt.attempt_type.toString() === task.task_type.toString());
    const defermentsToRemove = this.getDeferments(index);
    this.removeAttempts(attemptsToRemove, row);

    if (defermentsToRemove.length) {
      this.removeDeferments(defermentsToRemove);
    }
  }

  removeDeferments(defermentsToRemove) {
    this.defermentService.delete(defermentsToRemove)
      .then(res => {
        this.store.dispatch(new AssessmentActions.RemoveDeferments(defermentsToRemove));
        this.getTasksProgresses();

        if (this.checkForUpdateProgress()) {
          this.updateOrCreateInProgress(1);
        }
      });
    this.checkProgress();
  }

  removeAttempts(attemptsToRemove, row) {
    this.taskService.remove_attempts(attemptsToRemove)
      .then(res => {
        this.store.dispatch(new AssessmentActions.RemoveAttempts({task_attempts: attemptsToRemove}));
        this.getTasksProgresses();
        if (this.checkForUpdateProgress()) {
          this.updateOrCreateInProgress(1);
        }
      });
  }

  checkForUpdateProgress() {
    return this.assessment_progress === 'completed' && ((this.rows.length - this.assessment_deferred_tasks.length - this.completed_tasks.length) !== 0 || this.log_rows.length === 0 || !this.checkForCompletedDeferrals());
  }

  newEntry() {
    this.newFlightLogEntryDialogRef = this.dialog.open(StudentAssessmentsEntryDialogComponent, {
      hasBackdrop: true,
      width: '760',
      height: '90vh',
      maxHeight: '800px',
      data: {
        course_attempt_id: this.course_attempt_id,
        course_id: this.assessment.course_id,
        stage_id: this.assessment.stage_id,
        lesson_id: this.assessment.lesson_id,
        content_id: this.content_id,
        user_id: this.user_id,
        type: 'new'
      }
    });

    this.newFlightLogEntryDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.store.dispatch(new AssessmentActions.AddLogbook(data));
      }
    });
  }

  viewLogbookEntry(row) {
    this.newFlightLogEntryDialogRef = this.dialog.open(StudentAssessmentsEntryDialogComponent, {
      hasBackdrop: true,
      width: '760',
      height: '90vh',
      maxHeight: '800px',
      data: {
        logbook: row,
        type: 'edit'
      }
    });

    this.newFlightLogEntryDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.store.dispatch(new AssessmentActions.AddLogbook(data));
      }
    });
  }

  removeEntry(row) {
    this.lessonFlightLogService.delete(row.id)
      .then(res => this.store.dispatch(new AssessmentActions.RemoveLogbook(row.id)))
      .then(() => {
        if (this.checkForUpdateProgress()) {
          this.updateOrCreateInProgress(1);
        }
      });
  }

  viewTaskDetail(row) {
    row.content_id = this.content_id;
    this.store.dispatch(new AssessmentActions.ViewTaskDetail({task: row}));
    const url = `instructor/classroom/student/${this.user_id}/course/${this.course_id}/assessments/task-detail/${row.id}`;
    this.router.navigate([url]);
  }

  newStandardEntry(index, row, content_id?, is_deferment = false) {
    this.newStandardDialogRef = this.dialog.open(StudentAssessmentsStandardsDialogComponent, {
      hasBackdrop: true,
      data: is_deferment ? row.task.standards : row.standards || []
    });

    this.newStandardDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.createEntry('failed', index, row, data, is_deferment);
      }
    });
  }

  newDeferReasonEntry(index, row) {
    if (!this.nextAssessmentOfType) {
      alert('You cannot defer this task. There are no assessments available to defer this task to.');
      return;
    }

    const length = this.assessment_deferred_tasks.length;
    const exists = this.getDeferments(index).length;

    if (length < 3 && !exists) {
      this.newStandardDialogRef = this.dialog.open(StudentAssessmentsStandardsDialogComponent, {
        hasBackdrop: true,
        data: this.defer_reasons || []
      });

      this.newStandardDialogRef.afterClosed().subscribe((data) => {
        if (data) {
          const defer_reasons = this.defer_reasons.filter((reason, i) => data.indexOf(i) > -1);
          this.deferTask(row, index, defer_reasons);
        }
      });
    } else {
      if (length >= 3) {
        alert('You cannot defer more than 3 tasks in the current assessment.');
      } else if (exists) {
        alert('You have already deferred this task');
      }
    }
  }

  getDeferments(index) {
    const task = this.rows[index];
    return this.deferments.filter(deferment => deferment.content_id === this.content_id && deferment.lesson_id === this.assessment.lesson_id && deferment.task_id === task.id && deferment.task_type.toString() === task.task_type.toString());
  }

  checkCompleted(index: number, task_type?: string, deferral?: boolean) {
    if (deferral) {
      if (task_type.toString() === 'practice') {
        const task = this.deferredTasks[index].task;
        const num_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'practice' && task_attempt.content_id === this.content_id && task_attempt.task_id === task.id).length;
        const progress = num_attempts >= task.required_successful_attempts ? 'Completed' : num_attempts > 0 ? 'In Progress' : 'Not Started';

        return progress;
      } else {
        const task = this.deferredTasks[index].task;
        const num_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.content_id === this.content_id && task_attempt.task_id === task.id && (task_attempt.perfect || task_attempt.success)).length;
        const missed_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.content_id === this.content_id && task_attempt.task_id === task.id && task_attempt.failed).length;
        const progress = num_attempts >= task.required_successful_attempts ? 'Completed' : (num_attempts || missed_attempts) > 0 ? 'In Progress' : 'Not Started';

        return progress;
      }
    } else {
      if (task_type.toString() === 'practice') {
        const task = this.rows[index];
        const num_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'practice' && task_attempt.content_id === this.content_id && task_attempt.task_id === task.id).length;
        const progress = num_attempts >= task.required_successful_attempts ? 'Completed' : num_attempts > 0 ? 'In Progress' : 'Not Started';
        const deferments = this.getDeferments(index);
        if (deferments.length > 0) {
          return 'Deferred';
        }
        return progress;
      } else {
        const task = this.rows[index];
        const num_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.content_id === this.content_id && task_attempt.task_id === task.id && (task_attempt.perfect || task_attempt.success)).length;
        const missed_attempts = this.task_attempts.filter(task_attempt => task_attempt.attempt_type.toString() === 'perform' && task_attempt.content_id === this.content_id && task_attempt.task_id === task.id && task_attempt.failed).length;
        const progress = num_attempts >= task.required_successful_attempts ? 'Completed' : (num_attempts || missed_attempts) > 0 ? 'In Progress' : 'Not Started';
        const deferments = this.getDeferments(index);
        if (deferments.length > 0) {
          return 'Deferred';
        }

        return progress;
      }
    }
  }

  getInitialTaskProgress() {
    const temp_completed = [];
    const temp_in_progress = [];
    const temp_not_started = [];
    const temp_deferred = [];

    this.rows.forEach((task, index) => {
      const result = this.checkCompleted(index, task.task_type);
      let perfect = 0;
      let passed = 0;
      let missed = 0;
      if (this.assessment.jet_scoring) {
        perfect = this.getPerfect(task);
      }
      passed = this.getPassed(task);
      missed = this.getMissed(task);
      if (result === 'Completed') {
        temp_completed.push(task);
      } else if (result === 'In Progress') {
        temp_in_progress.push(task);
      } else if (result === 'Not Started') {
        temp_not_started.push(task);
      } else if (result === 'Deferred') {
        temp_deferred.push(task);
      }
      this.rows[index]['status'] = result;
      this.rows[index]['perfect'] = perfect;
      this.rows[index]['passed'] = passed;
      this.rows[index]['missed'] = missed;
    });

    this.completed_tasks = temp_completed;
    this.in_progress_tasks = temp_in_progress;
    this.not_started_tasks = temp_not_started;
    this.assessment_deferred_tasks = temp_deferred;
    this.checkProgress();
    this.rows = [...this.rows];
  }

  getTasksProgresses() {
    const temp_completed = [];
    const temp_in_progress = [];
    const temp_not_started = [];
    const temp_deferred = [];

    this.rows.forEach((task, index) => {
      const result = this.checkCompleted(index, task.task_type);
      let perfect = 0;
      let passed = 0;
      let missed = 0;
      if (this.assessment.jet_scoring) {
        perfect = this.getPerfect(task);
      }
      passed = this.getPassed(task);
      missed = this.getMissed(task);
      if (result === 'Completed') {
        temp_completed.push(task);
      } else if (result === 'In Progress') {
        temp_in_progress.push(task);
      } else if (result === 'Not Started') {
        temp_not_started.push(task);
      } else if (result === 'Deferred') {
        temp_deferred.push(task);
      }
      this.rows[index]['status'] = result;
      this.rows[index]['perfect'] = perfect;
      this.rows[index]['passed'] = passed;
      this.rows[index]['missed'] = missed;
    });

    this.completed_tasks = temp_completed;
    this.in_progress_tasks = temp_in_progress;
    this.not_started_tasks = temp_not_started;
    this.assessment_deferred_tasks = temp_deferred;
    this.updateProgress();
    this.rows = [...this.rows];
  }

  getType(row) {
    if (!(row.completedTotalHours > 0)) {
      if (!(row.completedSimHours > 0)) {
        return 'Ground';
      } else {
        return 'Sim';
      }
    } else {
      return 'Flight';
    }
  }

  checkForCompletedDeferrals() {
    const completed = [];

    this.deferredTasks.forEach((deferral, index) => {
      if (this.checkCompleted(index, deferral.task.task_type, true) === 'Completed') {
        completed.push(deferral);
      }
    });
    return completed.length === this.deferredTasks.length;
  }

  onTabChange(event: MatTabChangeEvent) {
    window.dispatchEvent(new Event('resize'));
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
