<div fxFlex='100' fxLayout='row'>
  <div fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Manage Category</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form [formGroup]='form'>
          <div class="form-row">
            <label for="course-title" class="form-label">Name</label>
            <input id="course-title" formControlName='name' class="form-input" [(ngModel)]="task_category.name" placeholder="Title">
          </div>
          <div class="form-row">
            <label for="course-desc" class="form-label">Description</label>
            <textarea id="course-desc" formControlName='desc' class="form-textarea" [(ngModel)]="task_category.desc" placeholder="Description"></textarea>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="updateCategory()">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
