<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title class="">
        <div class="card-title-text">Create Course Task</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for="task-name" class="form-label">Name</label>
            <input id="task-name" name="task_name" class="form-input" [(ngModel)]="task_model.name" placeholder="Name" required>
          </div>
          <div class="form-row">
            <label for='task-type' class='form-label'>Task Category</label>
            <div class='selection-wrapper'>
              <div class="full-width mt-1" *ngIf="task_model?.task_category">
                <p class="selected-task-category">{{task_model?.task_category?.name}}</p>
              </div>
              <button class="mr-1" mat-raised-button [color]="'primary'" (click)="addCategory()">Select Category</button>
            </div>
          </div>
          <div class="form-row">
            <label for="task-standards" class="form-label">Completion Standards</label>
            <div class='form-row' *ngFor='let standard of task_model.standards; trackBy: trackFunction; let i = index' >
              <textarea name="task-standards--{{ i }}" [(ngModel)]='task_model.standards[i]' class="form-textarea"  placeholder="New Standard"></textarea>
              <mat-icon (click)="removeStandard(i)">close</mat-icon>
            </div>
          </div>
          <div class='form-row'>
            <button mat-raised-button [color]="'primary'" (click)='addStandard()'>Add Standard</button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="addTask();" [disabled]="!form.valid || !task_model.task_category">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
