<app-user-instructor-connect-template
  *ngIf="data?.type === 'instructor'"
  [isCtc]="isCtc"
  [remove]="remove"
  [connections]="connections"
  [users]="users"
  (submit)="addInstructor($event)"
></app-user-instructor-connect-template>
<app-user-notify-template
  *ngIf="data?.type === 'notify'"
  [users]="users"
  (submit)="sendMessage($event)"
></app-user-notify-template>
<app-enroll-user-course-template
  *ngIf="data?.type === 'user-course' && data?.reset === false"
  [remove]="remove"
  [users]="users"
  (enroll_users)="addUsersToCourses($event)"
  (remove_users)="removeUserFromCourse($event)"
  [disableModalButton]="disableModalButton$ | async"
></app-enroll-user-course-template>
<app-enroll-user-course-template
  *ngIf="data?.type === 'user-course' && data?.reset === true"
  [reset]="reset"
  [users]="users"
  (reset_attempts)="resetCourseAttempts($event)"
></app-enroll-user-course-template>
<app-user-update-salesforceid-template
  *ngIf="data?.type === 'salesforceID'"
  [update]="update"
  [user]="user"
  [useremail]="data.useremail"
  [salesforceID]="data.salesforceID"
  (update_salesforceid)="updateSalesforceID($event)"
></app-user-update-salesforceid-template>
<app-user-promote-admin-template
  *ngIf="data?.type === 'promote-admin'"
  [promote]="promote"
  [user]="user"
  [useremail]="data.useremail"
  (promote_admin)="promoteToAdmin($event)"
></app-user-promote-admin-template>
<app-user-revoke-admin-template
  *ngIf="data?.type === 'revoke-admin'"
  [revoke]="revoke"
  [user]="user"
  [useremail]="data.useremail"
  (revoke_admin)="revokeAdmin($event)"
></app-user-revoke-admin-template>
