import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InstructorService} from '@app/services/data-services/instructor.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {CourseService} from '@app/services/data-services/course.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-enroll-user-course-template',
  templateUrl: './enroll-user-course-template.component.html',
  styleUrls: ['./enroll-user-course-template.component.scss']
})
export class EnrollUserCourseTemplateComponent implements OnInit {
  @Input() users: any[];
  @Input() disableModalButton = false;
  @Output() enroll_users: EventEmitter<any> = new EventEmitter<any>();
  @Output() remove_users: EventEmitter<any> = new EventEmitter<any>();
  @Output() reset_attempts: EventEmitter<any> = new EventEmitter<any>();
  @Input() remove: boolean;
  @Input() reset: boolean;
  rows: any[] = [];
  temp: any[] = [];
  selected = [];
  selectedInstructor: any;
  isLoading = false;
  @ViewChild('table') table: DatatableComponent;
  @Input() embark_job_id: string;
  @Input() flight_ops_event_id: string;

  constructor(private courseService: CourseService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (!this.remove && !this.reset) {
      this.getCourses();
    } else {
      this.getUserCourses();
    }
  }

  getCourses() {
      this.isLoading = true;
      this.courseService.getCoursesForAddUserDialog()
        .then(courses => {
          this.rows = this.temp = courses;
          this.isLoading = false;
        })
        .catch(err => this.isLoading = false);
  }

  getUserCourses() {
      this.isLoading = true;
      this.courseService.getStudentCoursesToRemove(this.data.content[0].id)
        .then(courses => {
          this.rows = this.temp = courses;
          this.isLoading = false;
        })
        .catch(err => this.isLoading = false);
    }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.rows = this.temp.filter(function(d) {
      return d.title.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // Whenever filter changes, always go back to the first page
    this.table.offset = 0;
  }

  onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }


  enroll() {
    if (this.selected) {
      this.enroll_users.emit({selected: this.selected, embark_job_id: this.embark_job_id, flight_ops_event_id: this.flight_ops_event_id});
    }
  }

  removeUserFromCourse() {
    if (this.selected) {
      this.remove_users.emit(this.selected);
    }
  }
  resetCourseAttempt() {
    if (this.selected) {
      this.reset_attempts.emit(this.selected);
    }
  }
}
