import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ReportsService} from '@app/services/data-services/reports.service';
import {AdminReportsDialogComponent} from './admin-reports-dialog/admin-reports-dialog.component';
import {ShortTextPipe} from '@app/pipes/short-text.pipe';
import {AdminReportsUserDialogComponent} from './admin-reports-dialog/admin-reports-user-dialog/admin-reports-user-dialog.component';
import {HttpService} from '@app/services/http-service.service';
import {CurrencyPipe, DatePipe, DecimalPipe} from '@angular/common';
import {InstructorService} from '@app/services/data-services/instructor.service';
import {UserService} from '@app/services/data-services/user.service';
import {UserProgress} from '@app/models/user/user-progress';
import {ActivatedRoute, Router} from '@angular/router';
import {CourseService} from '@app/services/data-services/course.service';
import {ProgressService} from '@app/services/data-services/progress.service';
import {ProgressCalculatorService} from '@app/services/helper/progress-calculator.service';

@Component({
  selector: 'app-admin-reports-manager',
  templateUrl: './admin-reports-manager.component.html',
  styleUrls: ['./admin-reports-manager.component.scss']
})

export class AdminReportsManagerComponent implements OnInit {

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('image_container', { static: true }) image_container: ElementRef;
  @ViewChild('image', { static: true }) image: ElementRef;

  csv_a = '';
  name = '';
  cname = '';
  is_completed = '';
  created_at = '';
  ground = '';
  flight = '';
  sim = '';
  given = '';
  instApproach = '';
  landings = '';

  rows: any[] = [];
  columns: any[] = [];
  showTable = false;
  spin;

  rptParam;
  viewFn;
  title;

  dt1; dt2;

  users = [];
  usrIds = [];
  usrNameTxt = '';
// uid = '';
  email = '';
  myName = '';

  instructors = [];
  insIds = [];
// insId;
// insName = '';
  insTxt = '';
// insEmail = '';

  trainId = '';
  training;

  courses;
  courseIds = '';
//  courseId = '';

  crsAssess;
  conId = '';
  conCrsId = 0;

  task = '';
  oStatus = '';
  cStatus = '';

  url = '';
  course_id = null;
  export_transcript = false;
  isLoading = false;
  user_id: number;
  dataRows: any[] = [];
  userProgress: UserProgress[] = [];
  user_name = '';
  exporting = false;
  @ViewChild('badge_image') badge_image: ElementRef;
  @ViewChild('default_badge') default_badge: ElementRef;
  @ViewChild('course_detail') course_detail: ElementRef;

// old
  reportSelected = '';
  selected: any[] = [];
  dialogRef: any;
  shortText = new ShortTextPipe();

  constructor(public adminReportsManagerDialog: MatDialog,
              private router: Router,
              private reportsService: ReportsService,
              private instructorService: InstructorService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private courseService: CourseService,
              private progressService: ProgressService,
              private calculatorService: ProgressCalculatorService,
              private http: HttpService,
              public dialog: MatDialog) {

    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    this.dt2 = new Date(dt);
    dt.setDate(dt.getDate() - 7);
    this.dt1 = dt;
    userService.getUserName(JSON.parse(localStorage.getItem('cirrus-user')).id).then(r =>
      this.myName = r
    );
  }

  ngOnInit() {
    this.getRole();
    this.isLoading = true;
  }

  parseCourse() {
    try {
      this.dataRows.forEach(user_course => {
        const isComplete: any = this.calculatorService.checkForCourseProgressComplete(user_course);
        console.log(isComplete);
        let overall: any = '0';
        let completed_at: Date = null;

        const progresses = this.getCourseProgress(user_course);

        if (progresses.length) {
          overall = ((progresses.filter(progress => progress === true).length / progresses.length) * 100).toFixed(0);
        }

        const start_date = this.userProgress.filter(progress => progress.progress_type.toString() ===  'course' && progress.course_id === user_course.course_id);
        // console.log('Start Date from manager: ', start_date);
        completed_at = start_date.length ? start_date[0].updated_at : null;
        this.rows.push(
          {
            course: user_course,
            course_progress: parseInt(overall),
            completed_at,
            started: start_date.length ? start_date[0].created_at : ''
          });
      });
    } catch (e) {
      this.isLoading = false;
    }

    this.rows = [...this.rows];
    this.isLoading = false;
  }

  getCourseProgress(course) {
    this.calculatorService.progresses = this.userProgress.filter(progress => progress.course_attempt_id == course.course_attempt_id);

    return this.calculatorService.getTranscriptProgress(course);
  }

// gui

  view() {
    this.spin = true;
    this.showTable = false;
    this[this.viewFn]();
  }

  is = nm => nm === this.viewFn;

  // Populate Instructor drop-down with list of 1000 instructors
  flt(src, ids, txt) {
    return this[src].filter(e => this[ids].includes(e.id) ||
      e.name.toLowerCase().includes(this[txt].toLowerCase()))
      .filter((e, i) => i < 1000 || this[ids].includes(e.id));
  }
  usrFlt = x => this.flt('users', 'usrIds', 'usrNameTxt');

  insFlt = x => this.flt('instructors', 'insIds', 'insTxt');

  idsSel = (d, ids) => [d, this[ids].includes('ALL') ? 'ALL' : this[d].filter(e => this[ids].includes(e.id)).map(e => e.name).join(', ')];

  insSel = () => this.idsSel('instructors', 'insIds');
  usrSel = () => this.idsSel('users', 'usrIds');
  crsSel = () => this.idsSel('courses', 'courseIds');

  switch(r, title, rptParam= x => []) {
    this.viewFn = r;
    this.title = title;
    this.rptParam = rptParam;
    this.clr();
  }

  clr() {
    this.showTable = false;
    this.rows = [];
  }

  clrOnClose(e, nm) {
    if (!e) {
      this[nm] = '';
    }
  }

  reset() {
    this.viewFn = null;
  }

// io

  fetch = (pth, fn, param= [], dt= `/${this.ts('dt1')}/${this.ts('dt2')}`) => this.http.get(`reports/` + pth + dt + '?'
    + param.map(nm => nm + '=' + this[nm]).join('&')).subscribe((res: any) => fn(res))

  ld(ifc, p, nm) {
    if (ifc) {
      this.fetch(p, res => this[nm] = res.json, [], '');
    }
  }

  ldCourse() {
    this.ld(this.courses == null, `courseList`, 'courses');
  }

  ldUsr() {
    this.ld(this.users.length === 0, `usrs`, 'users');
  }

  ldIns() {
    this.ld(this.instructors.length === 0, `instr`, 'instructors');
  }

// output

  getRptParam = fn => [ ['start', this.dtStr(this.dt1)], ['end', this.dtStr(this.dt2)] ].concat(this.rptParam().filter(e => e[1])).map(fn);
  csvSf = (v, c = ',') => [v].join('').replace(RegExp(`[#${c}]`, 'g'), '');

  csv(a) {
    a.href = encodeURI(`data:text/csv;charset=utf-8,` +
`TITLE,${this.title}
CREATED,${this.dtStr()}
BY,${this.myName}
ROWS,${this.rows.length}
CRITERIA
` +   this.getRptParam(kv => ',' + kv[0] + ',' + this.csvSf(kv[1], '')).join('\n') + '\n\n' +

      this.columns.map(c => '[' + c.name + ']').join(',') + '\n' +
      this.rows.map(row =>
        this.columns.map(c => this.csvSf(row[c.prop])).join(','), this
      ).join('\n')
    );
    a.click();
  }

  print(...numCols) {
    let map;
      map = (fn, p = 'columns') => this[p].map(fn, this).join('');
    let nth;
      nth = n => `th:nth-child(${n}), td:nth-child(${n})`;
    let win;
      win = window.open();

      win.document.write(`
        <html lang="en">
          <table>
            <tr>
              <td>CIRRUS AIRCRAFT</td><td><strong>${this.title}</strong></td><td>${this.dtStr()}</td><td>${this.myName}</td>
            </tr>
          </table>
          <hr>
          <table>
          ${this.getRptParam(kv => `<tr><td>${kv[0]}</td><td>${kv[1]}</td></tr>`).join('')}
          </table><br>
          <table>
            <tr>
              ${map(c => `
              <th>${c.name}</th>`)}
            </tr>
              ${map(row => `
            <tr>
              ${map(c => `
              <td>${[row[c.prop]].join('')}</td>`)}
            </tr>`, 'rows')}
          </table>
          <style>
            th {
              text-align: left;
            }
            td,th {
              padding-right: 15px;
            }
            ${numCols.map(n => nth(n)).join(',')} {
              /*text-align: right;*/
            }
           </style>
        </html>`
      );
    win.print();
  }

// fields

  /* toStr = nm => this[nm] != null ? this[nm] : ''; */
  dt = (v, f = 'shortDate', tz = undefined) => new DatePipe('en-US').transform(v, f, tz);
  dtStr = (v = new Date()) => this.dt(v, 'short');
  dec = v => v ? new DecimalPipe('en-US').transform(v, '1.0-2') : 0;
  ts = nm => this[nm].getTime() / 1000;

  setFld(f, row, r) {
    const [nm, t] = f.split(' ');
    r[nm] = ({
      n: v => this.dec(v),
      d: v => this.dt(v),
      utc: v => this.dt(v, 'short', 'utc'),
      // ct: v => ({9: 'Flight Assessment', 10: 'Ground Assessment'})[v],
      st: v => ({0: 'Not Started', 1: 'In Progress', 2: 'Completed', 3: 'Failed'})[v],
      ost: v => ({0: 'In Progress', 1: 'Completed', 2: 'Totally Refunded', 3: 'Partially Refunded'})[v],
      cmp: v => (row.status === 2 || row.Status === 2) ? this.dt(v) : '',
      std: v => v ? (String(row.standards[v])/*.substr(0,17) + '...'*/) : '',
      $: v => v ? new CurrencyPipe('en-US').transform(v) : 0,
      undefined: v => v
    })[t](row[nm]);
  }
  titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }

  ldTbl(d, flds) {
    this.columns = flds.map(f => {
      let nm;
        nm = f.split(' ')[0];
      if (nm.includes('_')) {
        return ({
          name: this.titleCase(nm.replace(/_/g, ' ').trim()),
          prop: nm
        });
      } else {
        return ({
          name: this.titleCase(nm),
          prop: nm
        });
      }
    });
    this.rows = d.json.map(row => {
      const r = {};
      flds.forEach(f => {
        this.setFld(f, row, r);
      });
      return r;
    });
    this.showTable = true;
    this.spin = false;
  }

  /////// reports

  getCrsAssess = () => this.crsAssess.find(e => e.course_id === this.conCrsId);

  switchAssAct() {
    this.switch('assAct', 'Assessment Activity', x => [
      ['course', this.getCrsAssess().name],
      ['assessments',  this.getCrsAssess().array_agg.filter(e => this.conId === e[0]).map(e => e[1]).join('')]
    ]);
    this.ld(this.crsAssess == null, `courseAssessments`, 'crsAssess');
  }

  assAct() {
    this.fetch(`assessActivity`, res => {
      this.ldTbl(res, [ 'name', 'Type', 'userName', 'status st', 'completed cmp', 'entries n',
      'ground n', 'flight n', 'sim n', 'dualGiven n',
      'taskName', 'passed n', 'perfect n', 'missed n', 'deferred n' ]);

  }, ['conCrsId', 'conId']);
  }

//

  switchAssSum() {
    this.switch('assSum', 'Assessment Summary', x => [this.crsSel()] );
    this.ldCourse();
  }

  assSum() {
    this.fetch(`assessSummary` , res => {
      this.ldTbl(res, [ 'course', 'assessmentName', 'Type', 'enrolled n', 'notStarted n', 'inProgress n', 'completed n',
      'ground n', 'flight n', 'sim n', 'dualGiven n', 'topPassedTask', 'topPerfectTask',
      'topMissedTask', 'topDeferredTask' ]);

    }, ['courseIds']);
  }

//

switchBadgeSum() {
  this.switch('badgeSum', 'Badge Summary');
}

badgeSum() {
  this.fetch(`badgeSummary`, res => {
    this.ldTbl(res, [ 'name', 'Course', 'awardDate d'
    ]);
  });
}

//

switchCertSum() {
  this.switch('certSum', 'Certificate Summary');
}

certSum() {
  this.fetch(`certSummary`, res => {
    this.ldTbl(res, [ 'name', 'Course', 'awardDate d'
    ]);
  });
}


//

switchCrsAct() {
  this.switch('crsAct', 'Course Activity', x => [this.crsSel()]);
  this.ldCourse();
}

crsAct() {
  this.fetch(`courseActivity`, res => {
    this.ldTbl(res, [ 'name', 'userName', 'userId', 'enrolled d', 'status st', 'completed cmp',
      'ground n', 'flight n', 'sim n', 'dualGiven n', 'landings n', 'instApproach n' ]);
  }, [`courseIds`]);
}

//

switchCrsSum() {
  this.switch('crsSum', 'Course Summary');
}

crsSum() {
  this.fetch(`courseSummary`, res => {
    this.ldTbl(res, [ 'name', 'enrolled n', 'notStarted n', 'inProgress n', 'completed n',
    'avgGround n', 'avgFlight n', 'avgSim n', 'avgDualGiven n', 'avgLandings n', 'avgInstApproach n' ]);
  });
}

//

switchInsAct() {
  this.switch('insAct', 'Instructor Activity', x => [this.insSel()]);
  this.ldIns();
}

insAct() {
  this.fetch(`instructorActivity`, res => {
    this.ldTbl(res, [ 'userName', 'date d', 'model', 'flight n', 'dualGiven n', 'XC_ n', 'XC_Legs n',
      'dayLdg n', 'nightLdg n', 'instApproach n', 'remarks',
      'mostMissedTask', '2ndMostMissedTask', '3rdMostMissedTask',
      'mostDeferredTask', '2ndMostDeferredTask', '3rdMostDeferredTask'
    ]);
  }, ['insIds']);
}

//

  switchInsStudentAct() {
    this.switch('insStudentAct', 'Instructor Student Activity', x => [this.insSel()]);
    this.ldIns();
  }

  insStudentAct() {
    this.fetch(`instructorStudentActivity`, res => {
      this.ldTbl(res, [ 'user_id', 'course_id', 'name', 'email', 'cname', 'is_completed', 'created_at', 'updated_at', 'ground', 'flight', 'sim', 'given', 'instApproach', 'landings'
      ]);
    }, ['insIds', `oStatus`]);
  }

//

  downloadTranscript(course_id) {
    this.course_id = course_id;
    this.export_transcript = true;
    this.isLoading = true;
  }

  export() {
    window['transcriptDetail'].component.downloadPdf(() => {
      this.export_transcript = false;
      this.isLoading = false;
    });
  }

  viewDetail(course_id, user_id) {
    const url = `admin/reports/${user_id}/course-detail/${course_id}`;
    window.open(url, '_blank');
  }

  //

switchLogSum() {
  this.switch('logSum', 'Log Summary', x => [this.crsSel(), this.usrSel(), this.insSel()]);
  this.ldCourse();
  this.ldUsr();
  this.ldIns();
}

clrLog(me) {
  this.clr();
  ['usrNameTxt', 'courseIds', 'insTxt'].forEach(nm => {
    if (nm !== me) {
      this[nm] = '';
    }
  });
  if (me !== 'usrNameTxt') {
    this.usrIds = [];
  }
  if (me !== 'insTxt') {
    this.insIds = [];
  }
}

logSum() {
  let colNm = [];
  if (this.usrIds.length > 0) {
    colNm = ['userName'];
  } else if (this.insIds.length > 0) {
    colNm = ['instructorName'];
  } else {
    colNm = ['courseName'];
  }

  this.fetch(`logSummary`, res => {
    this.ldTbl(res, colNm.concat([ 'entries n', 'ground n', 'flight n', 'sim n', 'dualGiven n', 'XC_ n', 'XC_Legs n',
    'solo n', 'PIC_ n', 'night n', 'actualInst n', 'simInst n', 'Track_Nav_Aid n', 'hold n',
    'Day_TO n', 'dayLdg n', 'Night_TO n', 'nightLdg n', 'instApproach n' ]));
  }, [`usrIds`, 'courseIds', 'insIds']);
}

//

switchSysAct() {
  this.switch('sysAct', 'System Activity');
}

sysAct() {
  this.fetch(`systemActivity`, res => {
    this.ldTbl(res, [ 'new_users', 'unique_logins',  'active_enrollments', 'course_completions', 'assessment_completions', 'logs',
      'ground n', 'flight n', 'sim n', 'dual_given n',
      'most_missed_task', 'second_most_missed_task',  'third_most_missed_task']);
  });
}

//

switchTaskSum() {
  this.switch('taskSum', 'Task Summary', x => [['task', this.task]]);
}

taskSum() {
  this.fetch(`taskSummary`, res => {
    this.ldTbl(res, [ 'name', 'assessment', 'Type', 'passed n', 'perfect n', 'missed n', 'deferred n',
      'topReasonMissed std', '2ndReasonMissed std', '3rdReasonMissed std' ]);
  }, ['task']);
}

//

switchTransSum() {
  this.switch('transSum', 'Transaction Summary',  x => [this.usrSel()]);
  this.ldUsr();
}

transSum() {
  this.fetch(`trans`, res => {

    this.ldTbl(res, ['id', 'orderStatus ost', 'userId', 'name', 'email', 'created d', 'completed utc',
    'total $', 'line $', 'shipping $', 'tax $', 'discount $', 'refund $', 'refundDate d']);

    this.columns[4].width = '250';
  }, [`oStatus`, 'usrIds', 'email']);
  // },`oStatus=${this.oStatus}&uid=${this.uid}&email=${this.email}`);
}

//

  switchUsrAct() {
    this.switch('usrAct', 'User Activity',  x => [
      ['training', this.training.filter(t => t.id === this.trainId).map(t => t.name).join(', ')],
      this.usrSel(),
      this.insSel()
    ]);

    this.ld(!this.trainId, `training`, 'training');
    this.ldUsr();
    this.ldIns();
  }

  clrUsrAct(me) {
    this.clr();
    ['usrNameTxt', 'trainId', 'insTxt'].forEach(nm => {
      if (nm !== me) {
        this[nm] = '';
      }
    });
    if (me !== 'usrNameTxt') {
      this.usrIds = [];
    }
    if (me !== 'insTxt') {
      this.insIds = [];
    }
  }

  usrAct() {
    this.fetch(`userActivity`, res => {
      this.ldTbl(res, [ 'userId', 'userName', 'email', 'enrolled n', 'completed n',
      'ground n', 'flight n', 'sim n', 'dualGiven n', 'mostMissedTask', '2ndMostMissedTask',
      '3rdMostMissedTask', 'mostDeferredTask', '2ndMostDeferredTask' ]);
    }, [`usrIds`, 'insIds', 'trainId']
);
  }

  /// old stuff

  courseReport() {
    this.showTable = false;
    this.reportSelected = 'course';
    this.rows = [];
    this.columns = [{name: 'Name'}, {name: 'Description', prop: 'desc', pipe: this.shortText}, {
      name: 'Enrolled Count',
      prop: 'user_count'
    }];
    this.reportsService.getCoursesReport()
      .then(res => this.rows = res)
      .then(() => this.showTable = true);
  }

  instructorReport() {
    this.showTable = false;
    this.reportSelected = 'instructor';
    this.columns = [{name: 'Firstname', prop: 'user.contact.firstname'}, {
      name: 'Lastname',
      prop: 'user.contact.lastname'
    }, {name: 'Email', prop: 'user.email'}, {name: 'Student Count', prop: 'student_count'}];
    this.rows = [];
    this.reportsService.getInstructorsReport()
      .then(res => this.rows = res)
      .then(() => this.showTable = true);
  }

  userReport() {
    this.showTable = false;
    this.reportSelected = 'user';
    this.columns = [{name: 'Firstname', prop: 'contact.firstname'}, {
      name: 'Lastname',
      prop: 'contact.lastname'
    }, {name: 'Email'}, {name: 'Course Count', prop: 'course_count'}];
    this.rows = [];
    this.reportsService.getUsersReport()
      .then(res => this.rows = res)
      .then(() => this.showTable = true);
  }

  openDialog(row) {
    if (this.viewFn) {
      return;
    }

    const data = {
      selected: this.reportSelected,
      data: row.selected[0]
    };

    const width = '80%';
    const height = '80%';

    if (this.reportSelected === 'user') {
      this.dialogRef = this.adminReportsManagerDialog.open(AdminReportsUserDialogComponent, {
        width,
        height,
        data: data
      });
    } else {
      this.dialogRef = this.adminReportsManagerDialog.open(AdminReportsDialogComponent, {
        width,
        height,
        data: data
      });
    }
  }

  getRole() {
    return localStorage.getItem('cirrus-role');
  }
}
