import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-document',
    templateUrl: './document.component.html',
    styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {

    @Input() content: any;
    html: any = '';

    constructor(private sanitizer: DomSanitizer) {

    }

    ngOnInit() {
      if (this.content.content_type === 'assignment') {
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.content.content);
      } else {
        this.html = this.sanitizer.bypassSecurityTrustHtml(this.content.content_html);
      }
    }

}
