import { Lesson } from '@app/models/course/workbook/lesson/lesson';
import { Instructor } from '@app/models/instructor';
import { Status } from '@app/models/shared/status';
import {TaskType} from '@app/models/course/content/task/task';

export class TaskAttempt {
  id?: number;
  course_attempt_id: number;
  course_id?: number;
  stage_id?: number;
  lesson_id?: number;
  content_id?: number;
  task_id?: number;
  instructor_id?: number;
  user_id?: number;
  success?: number;
  failed?: number;
  deferred: number;
  perfect?: number;
  standards_missed?: number[];
  created_at?: Date;
  instructor?: Instructor;
  attempt_type?: TaskType;
}
