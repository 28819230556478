import { Component, OnInit } from '@angular/core';
import {GlobalEventManagerService} from "../../../services/global-event-manager/global-event-manager.service";

@Component({
  selector: 'app-my-learning',
  templateUrl: './my-learning.component.html',
  styleUrls: ['./my-learning.component.scss']
})
export class MyLearningComponent implements OnInit {
  constructor(private globalEventManagerService: GlobalEventManagerService) { }

  ngOnInit() {
    this.globalEventManagerService.topBar.emit(true);
  }

  onActive($event) {
    // console.log($event);
  }

  gridSize() {
    return window.matchMedia(`(max-width: 960px)`).matches ? window.matchMedia(`(max-width: 500px)`).matches ? 1 : 2 : 3;
  }

}
