import {UserProgressType} from './user-progress-type';
import {Status} from 'app/models/shared/status';

export class UserProgress {
    id?: number;
    course_attempt_id: number;
    course_id?: number;
    progress_type?: UserProgressType;
    status?: Status;
    item_id?: number;
    lesson_id?: number;
    score?: number;
    user_id?: number;
    stage_id?: number;
    created_at?: Date;
    updated_at?: Date;
    scorm_progress?: string;
    is_force_completed?: boolean;
}
