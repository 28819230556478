import { Injectable } from '@angular/core';
import { QuizQuestion } from '@app/models/course/content/quiz/quiz_question';
import { HttpService } from '@app/services/http-service.service';

@Injectable()
export class QuestionService {

  questionUrl = 'quiz_questions';

  constructor(private http: HttpService) {
  }

  getQuestions(): Promise<QuizQuestion[]> {
    return this.http.get<QuizQuestion[]>(this.questionUrl)
      .toPromise()
      .catch(QuestionService.handleError);
  }

  getQuestion(id: number): Promise<QuizQuestion> {
    const url = `${this.questionUrl}/${id}`;

    return this.http.get<QuizQuestion>(url)
      .toPromise()
      .catch(QuestionService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.questionUrl}/${id}`;

    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(QuestionService.handleError);
  }

  create(question: QuizQuestion): Promise<QuizQuestion> {
    return this.http
      .post<QuizQuestion>(this.questionUrl, JSON.stringify(question))
      .toPromise()
      .catch(QuestionService.handleError);
  }

  update(question: QuizQuestion): Promise<QuizQuestion> {
    const url = `${this.questionUrl}/${question.id}`;

    return this.http
      .put(url, JSON.stringify(question))
      .toPromise()
      .then(() => question)
      .catch(QuestionService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
