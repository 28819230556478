import { Injectable } from '@angular/core';

@Injectable()
export class StudentService {

  constructor() { }

  getDataHeaders() {
    return [
      {
        prop: 'name',
        name: 'Student Name'
      },
      {
        prop: 'email',
        name: 'Email'
      },
      {
        prop: 'phone',
        name: 'Phone'
      }
    ];
  }
}
