<mat-dialog-content class="content-dialog-wrapper" style="margin: 25px 0!important; position: relative;">
  <button class="close-btn" mat-icon-button mat-dialog-close><mat-icon class="close-icon">close</mat-icon></button>
  <h1 mat-dialog-title>Select Lesson</h1>
  <div class="content-wrapper"
       fxLayout="column wrap">
    <div class="full-width content-wrapper">
      <div class="mat-box-shadow margin-333">
        <ngx-datatable #table
                       class="material bg-white"
                       [columnMode]="'force'"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       [rowHeight]="'auto'"
                       [limit]="50"
                       [rows]="lessons"
                       [selected]="selected"
                       [selectionType]="'single'"
                       [columns]="columns"
                       (select)="onSelect($event)">
        </ngx-datatable>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions style="display: block; text-align: center;">
  <button mat-button type="button" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Select Lesson</button>
</mat-dialog-actions>
