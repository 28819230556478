<form #form="ngForm" id="assessment-entry-form">
  <!--(ngSubmit)="submit(form)"-->
  <h1 mat-dialog-title *ngIf="new">Add Entry</h1>
  <h1 mat-dialog-title *ngIf="edit && !isEditing"></h1>
  <h1 mat-dialog-title *ngIf="isEditing">Edit Entry</h1>
  <div class="full-width" style="height: 35px;" *ngIf="edit && !isEditing">
    <h1 mat-dialog-title>
      View Entry
    </h1>
    <div fxFlex></div>
    <button *ngIf="!isEditing && !isCompleted" mat-raised-button color="primary" (click)="toggleEdit()">Edit</button>
  </div>
  <mat-dialog-content>
    <div class="form-row mt-1">
      <p class="m-0">GENERAL</p>
      <mat-divider></mat-divider>
      <div class="form-row">
        <label for="date">Date</label>
        <input class="form-input float-right" *ngIf="isEditing || new" id="date" name="date" type="date" [(ngModel)]="date">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.lesson_flight_log_date | date}}</span>
      </div>
      <div class="form-row">
        <label for="aircraft-make">Aircraft Make</label>
        <input class="form-input float-right" *ngIf="isEditing || new" disabled=true type="text" id="aircraft-make" placeholder="CIRRUS">
        <span class="float-right" *ngIf="!isEditing && edit">CIRRUS</span>
      </div>
      <div class="form-row">
        <label for="aircraft-model">Aircraft Model</label>
        <div class="selection-wrapper">
          <select id="aircraft-model" *ngIf="isEditing || new" name="aircraft-model" [(ngModel)]="lesson_flight_log.aircraft_model" class="form-select" >
            <optgroup [label]="group.name" *ngFor="let group of modelGroups">
              <option [value]="model.value" *ngFor="let model of group.model">{{model.viewValue}}</option>
            </optgroup>
          </select>
          <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.aircraft_model}}</span>
        </div>
      </div>
      <div class="form-row">
        <label for="tailid">Aircraft ID</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="text" id="tailid" name="tailid" [(ngModel)]="lesson_flight_log.aircraft_tail_id" placeholder="Aircraft ID">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.aircraft_tail_id}}</span>
      </div>
      <div class="form-row">
        <label for="fromairport">From</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="text" id="fromairport" name="fromairport" [(ngModel)]="lesson_flight_log.fromAirport" placeholder="From">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.fromAirport}}</span>
      </div>
      <div class="form-row">
        <label for="toairport">To</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="text" id="toairport" name="toairport" [(ngModel)]="lesson_flight_log.toAirport" placeholder="To">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.toAirport}}</span>
      </div>
      <div class="form-row">
        <label for="routepath">Route</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="text" id="routepath" name="routepath" [(ngModel)]="lesson_flight_log.routePath" placeholder="Route Path">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.routePath}}</span>
      </div>
    </div>
    <div class="form-row mt-1">
      <p class="m-0">TIME</p>
      <mat-divider></mat-divider>
      <div class="form-row">
        <label for="groundinstructionhours">Ground Instruction</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="groundinstructionhours" name="groundinstructionhours" [(ngModel)]="lesson_flight_log.completedGroundInstructionHours" placeholder="Ground Instruction">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedGroundInstructionHours}}</span>
      </div>
      <div class="form-row">
        <label for="completedtotalhours">Total Flight</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="completedtotalhours" name="completedtotalhours" [(ngModel)]="lesson_flight_log.completedTotalHours" placeholder="Total Flight">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedTotalHours}}</span>
      </div>
      <div class="form-row">
        <label for="completedsimhours">Simulator</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="completedsimhours" name="completedsimhours" [(ngModel)]="lesson_flight_log.completedSimHours" placeholder="Simulator">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedSimHours}}</span>
      </div>
      <div class="form-row">
        <label for="completeddualreceivedhours">Dual Received</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="completeddualreceivedhours" name="completeddualreceivedhours" [(ngModel)]="lesson_flight_log.completedDualReceivedHours" placeholder="Dual Received">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedDualReceivedHours}}</span>
      </div>
      <div class="form-row">
        <label for="completedcrosscountryhours">Cross Country</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="completedcrosscountryhours" name="completedcrosscountryhours" [(ngModel)]="lesson_flight_log.completedCrossCountryHours" placeholder="Cross Country">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedCrossCountryHours}}</span>
      </div>
      <div class="form-row">
        <label for="crosscountrylegs">Cross Country Legs</label>
        <div class="float-right">
          {{lesson_flight_log.completedCrossCountryLegs}}
          <button *ngIf="isEditing || new" class=" ml-1 blue-border white-background flight-log-button" mat-raised-button (click)="decrease('completedCrossCountryLegs')">
            <mat-icon class="text-muted-white blue-text">remove</mat-icon>
          </button>
          <button *ngIf="isEditing || new" class="blue-border white-background flight-log-button" mat-raised-button (click)="increase('completedCrossCountryLegs')" >
            <mat-icon class="text-muted-white blue-text">add</mat-icon>
          </button>
        </div>
     </div>
      <div class="form-row">
        <label for="solohours">Solo</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="solohours" name="solohours" [(ngModel)]="lesson_flight_log.completedSoloHours" placeholder="Solo Hours">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedSoloHours}}</span>
      </div>
      <div class="form-row">
        <label for="pichours">PIC</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="pichours" name="pichours" [(ngModel)]="lesson_flight_log.completedPICHours" placeholder="PIC">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedPICHours}}</span>
      </div>
    </div>

    <div class="form-row mt-1">
      <p class="m-0">CONDITIONS</p>
      <mat-divider></mat-divider>
      <div class="form-row">
        <label for="night">Night</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="night" name="night" [(ngModel)]="lesson_flight_log.completedNightHours" placeholder="Night">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedNightHours}}</span>
      </div>
      <div class="form-row">
        <label for="completedActualInstrument">Actual Instrument Hours</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="completedActualInstrument" name="completedActualInstrument" [(ngModel)]="lesson_flight_log.completedActualInstrumentHours" placeholder="Actual Instrument Hours">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedActualInstrumentHours}}</span>
      </div>
      <div class="form-row">
        <label for="siminstrumenthours">Simulated Instrument</label>
        <input class="form-input float-right" *ngIf="isEditing || new" type="number" id="siminstrumenthours" name="siminstrumenthours" [(ngModel)]="lesson_flight_log.completedSimulatedInstrumentHours" placeholder="Simulated Instrument">
        <span class="float-right" *ngIf="!isEditing && edit">{{lesson_flight_log.completedSimulatedInstrumentHours}}</span>
      </div>
      <div class="form-row">
        <label for="trackaidhours">Track Nav Aid</label>
        <span *ngIf="edit && !isEditing" class="float-right">{{!lesson_flight_log.trackNavAid ? "NO" : 'YES'}}</span>
        <mat-slide-toggle *ngIf="isEditing || new" class="float-right" name="trackaidhours" id="trackaidhours" [(ngModel)]="lesson_flight_log.trackNavAid">{{!lesson_flight_log.trackNavAid ? "NO" : 'YES'}}</mat-slide-toggle>
      </div>
      <div class="form-row">
        <label for="completedpatterns">Holding Patterns</label>
        <div class="float-right">
          {{lesson_flight_log.completedHoldingPatterns}}
          <button *ngIf="isEditing || new" class=" ml-1 blue-border white-background flight-log-button" mat-raised-button (click)="decrease('completedHoldingPatterns')">
            <mat-icon class="text-muted-white blue-text">remove</mat-icon>
          </button>
          <button *ngIf="isEditing || new" class="blue-border white-background flight-log-button" mat-raised-button (click)="increase('completedHoldingPatterns')" >
            <mat-icon class="text-muted-white blue-text">add</mat-icon>
          </button>
        </div>
      </div>
      <div class="form-row">
        <label for="daytakeoffs">Day Takeoffs</label>
        <div class="float-right">
          {{lesson_flight_log.completedDayTakeoffs}}
          <button *ngIf="isEditing || new" class="ml-1 blue-border white-background flight-log-button" mat-raised-button (click)="decrease('completedDayTakeoffs')">
            <mat-icon class="text-muted-white blue-text">remove</mat-icon>
          </button>
          <button *ngIf="isEditing || new" class="blue-border white-background flight-log-button" mat-raised-button (click)="increase('completedDayTakeoffs')" >
            <mat-icon class="text-muted-white blue-text">add</mat-icon>
          </button>
        </div>
      </div>
      <div class="form-row">
        <label for="daylandings">Day Landings</label>
        <div class="float-right">
          {{lesson_flight_log.completedDayLandings}}
          <button *ngIf="isEditing || new" class=" ml-1 blue-border white-background flight-log-button" mat-raised-button (click)="decrease('completedDayLandings')">
            <mat-icon class="text-muted-white blue-text">remove</mat-icon>
          </button>
          <button *ngIf="isEditing || new" class="blue-border white-background flight-log-button" mat-raised-button (click)="increase('completedDayLandings')" >
            <mat-icon  class="text-muted-white blue-text">add</mat-icon>
          </button>
        </div>
      </div>
      <div class="form-row">
        <label for="nighttakeoffs">Night Takeoffs</label>
        <div class="float-right">
          {{lesson_flight_log.completedNightTakeoffs}}
          <button *ngIf="isEditing || new" class=" ml-1 blue-border white-background flight-log-button" mat-raised-button (click)="decrease('completedNightTakeoffs')">
            <mat-icon class="text-muted-white blue-text">remove</mat-icon>
          </button>
          <button *ngIf="isEditing || new" class="blue-border white-background flight-log-button" mat-raised-button (click)="increase('completedNightTakeoffs')" >
            <mat-icon  class="text-muted-white blue-text">add</mat-icon>
          </button>
        </div>
      </div>
      <div class="form-row">
        <label for="nightlandings">Night Landings</label>
        <div class="float-right">
          {{lesson_flight_log.completedNightLandings}}
          <button *ngIf="isEditing || new" class="ml-1 blue-border white-background flight-log-button" mat-raised-button (click)="decrease('completedNightLandings')">
            <mat-icon class="text-muted-white blue-text">remove</mat-icon>
          </button>
          <button *ngIf="isEditing || new" class="blue-border white-background flight-log-button" mat-raised-button (click)="increase('completedNightLandings')" >
            <mat-icon class="text-muted-white blue-text">add</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <div id="approaches-container" class="form-row mt-1">
      <div fxLayout="wrap" fxFlex="100">
        <p class="m-0 full-width">APPROACHES</p>
        <mat-divider class="full-width"></mat-divider>
        <div class="form-row">
          <ng-container *ngFor="let approach of approaches; let f = first; let i = index;">
            <mat-divider *ngIf="f" class="full-width"></mat-divider>
            <div class="full-width" fxLayout fxLayoutAlign="space-between center">
              <span>{{approach?.airport}} {{approach?.type}} RWY {{approach?.runway}}</span>
              <span class="hover-pointer" (click)="removeApproach(i)"><mat-icon style="margin-top: 5px" class="black-text" *ngIf="isEditing || new">close</mat-icon></span>
            </div>
            <mat-divider  class="full-width"></mat-divider>
          </ng-container>
          <div class="full-width mt-1" *ngIf="isEditing || new">
            <button mat-raised-button color="primary" (click)="addApproach()" style="display: block; margin: 0 auto;">Add Approach</button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row mt-1">
      <p class="m-0">REMARKS</p>
      <mat-divider></mat-divider>
      <div class="form-row">
        <textarea style="width: 98%;" class="form-input" *ngIf="isEditing || new" type="text" id="commentbody" name="commentbody" [(ngModel)]="lesson_flight_log.comment_body" placeholder="Remarks"></textarea>
        <span *ngIf="!isEditing && edit">{{lesson_flight_log.comment_body}}</span>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <ng-container *ngIf="!edit && new">
      <div fxFlex></div>
      <button mat-button type="button" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" type="submit" (click)="submit()">Done</button>
    </ng-container>
    <ng-container *ngIf="edit">
      <div fxFlex></div>
      <button *ngIf="isEditing" mat-button type="button" mat-dialog-close>Cancel</button>
      <button *ngIf="isEditing" mat-raised-button color="primary" type="submit" (click)="update()">Update</button>
    </ng-container>
  </mat-dialog-actions>
</form>
