import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Order, OrderStatus, OrderStatusMap } from '@app/models/order/order';
import { OrderService } from '@app/services/data-services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocationStrategy } from '@angular/common';

import {
  PaymentType,
  PaymentTypeMap,
  TransactionType,
  TransactionTypeMap,
} from '@app/models/order/payment';

@Component({
  selector: 'app-user-order',
  templateUrl: './user-order.component.html',
  styleUrls: ['./user-order.component.scss'],
})
export class UserOrderComponent implements OnInit {
  order = new Order();
  subscription = new Subscription();
  paymentType = PaymentType;
  paymentTypeMap = PaymentTypeMap;
  transactionType = TransactionType;
  transactionTypeMap = TransactionTypeMap;
  orderStatus = OrderStatus;
  orderStatusMap = OrderStatusMap;
  isDisabled = false;

  currentYear = new Date().getFullYear().toString();

  constructor(
    private orderService: OrderService,
    private router: Router,
    private location: LocationStrategy,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      try {
        const id = parseInt(params['id']);
        this.orderService.getOrder(id).then(res => (this.order = res));
      } catch (e) {
        this.location.back();
      }
    });
  }

  getTotal(unit_price, tax, quantity) {
    if (unit_price && tax && quantity) {
      return (parseFloat(unit_price) + parseFloat(tax)) * parseInt(quantity);
    } else {
      return null;
    }
  }
  refundOrder() {
    this.isDisabled = true;
    const order = new Order();
    order.id = this.order.id;
    order.refunded_amount = this.order.order_total;
    this.orderService.refundOrder(order).then(response => {
      if (response.status == 'false') {
        let message =
          response.resultCode +
          ': ' +
          response.messages[0].message +
          ' Please contact Accounting to process this refund.';
        this.snackBar.open(message, 'Okay');
      } else {
        this.order = response;
        this.snackBar.open('The order has been refunded', null, {
          duration: 3500,
        });
      }
    });
    // console.log("Order: ", this.order);
  }
  updateOrder() {
    const order = new Order();
    order.id = this.order.id;
    order.order_status = parseInt(this.orderStatus[this.order.order_status]);
    order.refunded_date = this.order.refunded_date;
    order.refunded_amount = this.order.refunded_amount;

    this.orderService.updateOrder(order).then(res => this.location.back());
  }
}
