import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { slideInRight } from '@app/animations/animations';
import { TaskCategoryService } from '@app/services/data-services/task-category.service';
import { TaskCategory } from '@app/models/course/content/task/task-category';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector:    'app-add-task-category',
  templateUrl: './add-task-category.component.html',
  styleUrls:   [ './add-task-category.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AddTaskCategoryComponent implements OnInit {

  task_category: TaskCategory;
  rows: TaskCategory[];

  constructor(private taskCategoryService: TaskCategoryService, 
                      private router: Router,
                      public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.task_category = new TaskCategory();
  }

  addCategory() {

    this.taskCategoryService.create(this.task_category)
        .then(() => {
          this.snackbar.open('Updated')
          this.task_category = new TaskCategory()
        });
  }

  cancel() {
    this.router.navigate(['admin/courses/category/task/overview']);
  }

}
