import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalEventManagerService } from '@app/services/global-event-manager/global-event-manager.service';
import { TempDataServiceService } from '@app/services/data-services/temp-data-service.service';
import { Store } from '@ngrx/store';

import * as fromCourse from '../course/store/course.reducers';
import { ContentPlayerComponent } from '../course/workbook/content-player/content-player.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { CourseService } from '@app/services/data-services/course.service';
import {
  ContentType,
  ContentTypeMap,
  ContentCategoryMap,
} from '@app/models/course/content/content-type';
import { ContentService } from '@app/services/data-services/content.service';
import { Bookmark } from '@app/models/course/bookmark';
import { UserService } from '@app/services/data-services/user.service';
import { BookshelfService } from '@app/services/data-services/bookshelf.service';
import {
  BookshelfContentType,
  BookshelfContentTypeMap,
  ContentBookshelf,
} from '@app/models/content-bookshelf';
import { CourseCategoryService } from '@app/services/data-services/course-category.service';

@Component({
  selector: 'app-content-library',
  templateUrl: './content-library.component.html',
  styleUrls: ['./content-library.component.scss'],
})
export class ContentLibraryComponent implements OnInit {
  @ViewChild('sortMenu') sortMenu;
  @ViewChild('viewMenu') viewMenu;

  sortSelection: string;
  viewSelection: string;
  subscription: any;
  catalogMenu = false;
  userCourses: any[] = [];

  currentFilter = 'all';

  avionicsFilter: FormControl;
  aircraftFilter: FormControl;
  trainingFilter: FormControl;
  mediaFilter: FormControl;
  collectionFilter: FormControl;

  catalogTabs: any[] = [
    {
      name: 'All',
      state: 'all',
      type: 'link',
    },
    {
      name: 'Videos',
      state: 'video',
      type: 'link',
    },
    {
      name: 'Interactives',
      state: 'interactive',
      type: 'link',
    },
    {
      name: 'Images',
      state: 'image',
      type: 'link',
    },
    {
      name: 'Slideshows',
      state: 'slideshow',
      type: 'link',
    },
    {
      name: 'Documents',
      state: 'document',
      type: 'link',
    },
    {
      name: 'My Bookshelf',
      state: 'bookshelf',
      type: 'link',
    },
  ];

  tempData: any = [];

  types = ContentType;
  bookshelfTypes = BookshelfContentType;
  map = ContentTypeMap;
  bookshelfMap = BookshelfContentTypeMap;
  categoryMap = ContentCategoryMap;
  content: any[] = [];
  filteredContent: any[] = [];
  contentTypes: any[] = [];

  categories: any[] = [];
  avionics: any[] = [];
  aircrafts: any[] = [];
  mediaTypes: any[] = [];
  mediaCategories: any[] = [];
  bookshelfMedia: any[] = [];
  filter = '';
  bookmarks: Bookmark[] = [];
  bookshelfContent: any[] = [];
  isFiltered = false;
  isLoading = false;

  constructor(
    private globalEventManagerService: GlobalEventManagerService,
    private tempService: TempDataServiceService,
    private courseService: CourseService,
    private contentService: ContentService,
    private userService: UserService,
    private bookshelfService: BookshelfService,
    private categoryService: CourseCategoryService,
    public dialog: MatDialog,
    private store: Store<fromCourse.State>
  ) {}

  ngOnInit() {
    this.globalEventManagerService.showNavbar.emit(false);

    this.sortSelection = 'A-Z';
    this.viewSelection = 'Thumbnails';

    this.avionicsFilter = new FormControl('all');
    this.trainingFilter = new FormControl('all');
    this.aircraftFilter = new FormControl('all');
    this.mediaFilter = new FormControl('all');
    this.collectionFilter = new FormControl('all');

    this.isLoading = true;

    const userCourses = this.courseService.getUserCourseLibraryContent();
    // Only return content for the correct user type.
    let bookshelfContent;
    if (
      ['instructor', 'admin', 'super_admin'].indexOf(
        localStorage.getItem('cirrus-role')
      ) > -1
    ) {
      bookshelfContent = this.bookshelfService.getInstructorBookshelf();
    } else {
      bookshelfContent = this.bookshelfService.getStudentBookshelf();
    }
    const bookmarks = this.userService.getBookmarks();
    const courseCategories = this.categoryService.getCategories();

    Promise.all([userCourses, bookshelfContent, bookmarks, courseCategories])
      .then(values => {
        this.userCourses = values[0];
        this.bookshelfContent = values[1];
        this.bookmarks = values[2];
        this.categories = values[3];
        this.setFilters();
        setTimeout(() => this.extractFilters(this.userCourses), 0);
      })
      .catch(err => {
        console.log(err);
        this.isLoading = false;
      });
  }

  setFilters() {
    const userCourses = this.userCourses;
    userCourses.forEach(userCourse => {
      console.log(userCourse);
      if (userCourse && userCourse.course && userCourse.course.aircrafts) {
        userCourse.course.aircrafts.forEach(aircraft => {
          if (this.aircrafts.map(item => item.id).indexOf(aircraft.id) === -1) {
            this.aircrafts.push(aircraft);
          }
        });
      }

      if (userCourse && userCourse.course && userCourse.course.avionics) {
        userCourse.course.avionics.forEach(avionic => {
          if (this.avionics.map(item => item.id).indexOf(avionic.id) === -1) {
            this.avionics.push(avionic);
          }
        });
      }
    });

    this.mediaTypes = this.mediaCategories.map(category => {
      return {
        type: category.type,
        title: category.title,
      };
    });

    const index = this.mediaTypes
      .map(category => category.title)
      .indexOf('Rich Text');

    if (index > -1 && index !== this.mediaTypes.length - 1) {
      const richText = this.mediaTypes.slice(index, 1);
      this.mediaTypes.push(richText);
    }
  }

  extractFilters(userCourses) {
    // Reset the media types since it depends on bookshelf or not
    this.mediaCategories = [];
    this.bookshelfMedia = [];

    // Populate all the categories
    if (userCourses) {
      if (this.collectionFilter.value === 'all') {
        userCourses.forEach(userCourse => {
          const filteredContent = this.searchContent(userCourse.contents);

          filteredContent.forEach(content => {
            this.filterContent(content);
          });
        });

        const filteredBookshelf = this.searchContent(this.bookshelfContent);

        filteredBookshelf.forEach(bookshelfItem => {
          this.filterBookshelf(bookshelfItem);
        });
      } else if (this.collectionFilter.value === 'bookmarked') {
        userCourses.forEach(userCourse => {
          const filteredContent = this.searchContent(userCourse.contents);

          filteredContent.forEach(content => {
            if (
              this.bookmarks
                .map(bookmark => bookmark.content_id)
                .indexOf(content.id) > -1
            ) {
              this.filterContent(content);
            }
          });
        });
        const filteredBookshelf = this.searchContent(this.bookshelfContent);

        filteredBookshelf.forEach(bookshelfItem => {
          if (
            this.bookmarks
              .map(bookmark => bookmark.bookshelf_id)
              .indexOf(bookshelfItem.id) > -1
          ) {
            this.filterBookshelf(bookshelfItem);
          }
        });
      }
    } else if (this.collectionFilter.value === 'bookshelf') {
      const filteredBookshelf = this.searchContent(this.bookshelfContent);

      filteredBookshelf.forEach(bookshelfItem => {
        this.filterBookshelf(bookshelfItem);
      });
    } else {
      const filteredBookshelf = this.searchContent(this.bookshelfContent);

      filteredBookshelf.forEach(bookshelfItem => {
        if (
          this.bookmarks
            .map(bookmark => bookmark.bookshelf_id)
            .indexOf(bookshelfItem.id) > -1
        ) {
          this.filterBookshelf(bookshelfItem);
        }
      });
    }

    if (this.mediaTypes.length === 0) {
      this.mediaTypes = this.mediaCategories.map(category => {
        return {
          type: category.type,
          title: category.title,
        };
      });
      let index = this.mediaTypes
        .map(category => category.title)
        .indexOf('Rich Text');

      if (index > -1 && index !== this.mediaTypes.length - 1) {
        const richText = this.mediaTypes.splice(index, 1);
        this.mediaTypes.push(...richText);
      }

      index = this.mediaTypes
        .map(category => category.title)
        .indexOf('Tin Can');
      if (index > -1) {
        this.mediaTypes.splice(index, 1);
      }
    }
    this.isLoading = false;
  }

  filterBookshelf(content) {
    if (content.content_type == 0 || content.content_type == 1) {
      if (this.bookshelfMedia.map(item => item.title).indexOf('Video') === -1) {
        this.bookshelfMedia.push({
          type: 'video',
          title: 'Video',
          content: [content],
        });
      } else {
        const index = this.bookshelfMedia
          .map(item => item.title)
          .indexOf('Video');
        if (
          this.bookshelfMedia[index].content
            .map(contentItem => contentItem.id)
            .indexOf(content.id) === -1
        ) {
          this.bookshelfMedia[index].content.push(content);
        }
      }
    } else {
      if (
        this.bookshelfMedia
          .map(item => item.title)
          .indexOf(this.bookshelfMap[content.content_type]) === -1
      ) {
        this.bookshelfMedia.push({
          type: content.content_type,
          title: this.bookshelfMap[this.bookshelfTypes[content.content_type]],
          content: [content],
        });
      } else {
        const index = this.bookshelfMedia
          .map(item => item.title)
          .indexOf(this.bookshelfMap[content.content_type]);
        if (
          this.bookshelfMedia[index].content
            .map(contentItem => contentItem.id)
            .indexOf(content.id) === -1
        ) {
          this.bookshelfMedia[index].content.push(content);
        }
      }
    }
  }

  filterContent(content) {
    if (content.content_type == 0 || content.content_type == 1) {
      if (
        this.mediaCategories.map(item => item.title).indexOf('Video') === -1
      ) {
        this.mediaCategories.push({
          type: 'video',
          title: 'Video',
          content: [content],
        });
      } else {
        const index = this.mediaCategories
          .map(item => item.title)
          .indexOf('Video');
        if (
          this.mediaCategories[index].content
            .map(contentItem => contentItem.id)
            .indexOf(content.id) === -1
        ) {
          this.mediaCategories[index].content.push(content);
        }
      }
    } else {
      if (
        this.mediaCategories
          .map(item => item.title)
          .indexOf(this.map[content.content_type]) === -1
      ) {
        this.mediaCategories.push({
          type: this.categoryMap[content.content_type],
          title: this.map[content.content_type],
          content: [content],
        });
      } else {
        const index = this.mediaCategories
          .map(item => item.title)
          .indexOf(this.map[content.content_type]);
        if (
          this.mediaCategories[index].content
            .map(contentItem => contentItem.id)
            .indexOf(content.id) === -1
        ) {
          this.mediaCategories[index].content.push(content);
        }
      }
    }

    let index = this.mediaCategories
      .map(category => category.title)
      .indexOf('Rich Text');

    if (index > -1 && index !== this.mediaTypes.length - 1) {
      const richText = this.mediaCategories.splice(index, 1);
      this.mediaCategories.push(...richText);
    }

    index = this.mediaCategories
      .map(category => category.title)
      .indexOf('Tin Can');
    if (index > -1) {
      this.mediaCategories.splice(index, 1);
    }
  }

  filterChanged() {
    if (this.collectionFilter.value !== 'bookshelf') {
      const filteredContent = this.userCourses
        .filter(userCourse => {
          if (this.aircraftFilter.value === 'all') {
            return true;
          }
          return (
            userCourse.course.aircrafts.filter(
              aircraft => aircraft.id === this.aircraftFilter.value
            ).length !== 0
          );
        })
        .filter(userCourse => {
          if (this.avionicsFilter.value === 'all') {
            return true;
          }
          return (
            userCourse.course.avionics.filter(
              avionic => avionic.id === this.avionicsFilter.value
            ).length !== 0
          );
        })
        .filter(userCourse => {
          if (this.trainingFilter.value === 'all') {
            return true;
          }
          return (
            userCourse.course.course_categories.filter(
              c => c.id === this.trainingFilter.value
            ).length > 0
          );
        });

      this.extractFilters(filteredContent);
    } else {
      this.extractFilters(this.userCourses);
    }
  }

  searchContent(courseContent) {
    console.log(this.mediaFilter.value);
    return courseContent.filter(content => {
      if (this.mediaFilter.value !== 'all') {
        if (this.mediaFilter.value === 'video') {
          return content.content_type == 0 || content.content_type == 1;
        } else
          return (
            this.categoryMap[content.content_type] == this.mediaFilter.value
          );
      }

      return (
        content.desc.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1 ||
        content.title.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1 ||
        (content.meta_tags &&
          content.meta_tags.filter(tag => {
            return tag.toLowerCase().indexOf(this.filter.toLowerCase()) !== -1;
          }).length !== 0)
      );
    });
  }

  isBookmarked(content) {
    return (
      this.bookmarks.map(item => item.content_id).indexOf(content.id) !== -1
    );
  }

  bookmark(content) {
    const index = this.bookmarks
      .map(item => item.content_id)
      .indexOf(content.id);

    if (index === -1) {
      this.contentService.bookmark(content.id).then(bookmark => {
        this.bookmarks.push(bookmark);
        content.myBookshelf = !content.myBookshelf;
      });
    } else {
      this.contentService.removeBookmark(this.bookmarks[index].id).then(() => {
        this.bookmarks.splice(index, 1);
        content.myBookshelf = !content.myBookshelf;
      });
    }
  }

  bookmarkBookshelf(content) {
    const index = this.bookmarks
      .map(item => item.bookshelf_id)
      .indexOf(content.id);

    if (index === -1) {
      this.bookshelfService.bookmark(content.id).then(bookmark => {
        this.bookmarks.push(bookmark);
        content.myBookshelf = !content.myBookshelf;
      });
    } else {
      this.contentService.removeBookmark(this.bookmarks[index].id).then(() => {
        this.bookmarks.splice(index, 1);
        content.myBookshelf = !content.myBookshelf;
      });
    }
  }

  isBookshelfBookmarked(content) {
    return (
      this.bookmarks.map(item => item.bookshelf_id).indexOf(content.id) !== -1
    );
  }

  filterItems(type: string) {
    this.tempData.sort((a, b) => (a[type] < b[type] ? 1 : -1));
  }

  filterResults(state: any) {
    this.currentFilter = state;
  }

  openDialog(content: any): void {
    content['comments'] = false;
    const dialogRef = this.dialog.open(ContentPlayerComponent, {
      width: '55vw',
      height: '90vh',
      maxWidth: '',
      data: { content },
    });

    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.then(r => (this.bookmarks = r));
      }
    });
  }

  gridSize() {
    return window.matchMedia(`(max-width: 1220px)`).matches
      ? window.matchMedia(`(max-width: 950px)`).matches
        ? window.matchMedia(`(max-width: 500px)`).matches
          ? 1
          : 2
        : 3
      : 4;
  }

  getRole() {
    return localStorage.getItem('cirrus-role');
  }

  // ngOnDestroy() {
  //     this.subscription.unsubscribe();
  // }
}
