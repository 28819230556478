
import {map, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';


import {Store} from '@ngrx/store';
import * as AssessmentActions from './assessment.actions';
import * as fromApp from '../../../../../../store/app.reducers';
import {LessonFlightLogService} from '@app/services/data-services/lesson-flight-log.service';
import {TaskService} from '@app/services/data-services/task.service';
import {InstructorService} from '@app/services/data-services/instructor.service';
import {TaskDefermentService} from '@app/services/data-services/task-deferment.service';
import {DeferReasonService} from '@app/services/data-services/defer-reason.service';
import {ProgressService} from '@app/services/data-services/progress.service';

@Injectable()
export class AssessmentEffects {


  fetchFlightLogs$ = createEffect(() => this.actions$.pipe(
    ofType(AssessmentActions.FETCH_ASSESSMENT_LOGBOOKS),
    switchMap((action: AssessmentActions.FetchAssessmentLogbooks) => {
      if (localStorage.getItem('cirrus-token') && action.payload && action.payload.user_id && action.payload.course_id) {
        this.store$.dispatch(new AssessmentActions.PushLoading('logbooks'));
        return this.flightLogService.getLessonFlightLogsForCourseAttempt(action.payload.user_id, action.payload.course_attempt_id);
      } else {
        return Promise.resolve([]);
      }
    }),
    map(
      (lessonFlightLogs) => {
        if (!lessonFlightLogs) {
          lessonFlightLogs = [];
        }

        return {
          type: AssessmentActions.UPDATE_ASSESSMENT_LOGBOOKS,
          payload: lessonFlightLogs
        };
      }
    ),));


  fetchTaskAttempts$ = createEffect(() => this.actions$.pipe(
    ofType(AssessmentActions.FETCH_ASSESSMENT_ENTRIES),
    switchMap((action: AssessmentActions.FetchAssessmentEntries) => {
      if (localStorage.getItem('cirrus-token') && action.payload && action.payload.course_id && action.payload.user_id) {
        this.store$.dispatch(new AssessmentActions.PushLoading('task_attempts'));
        return this.taskService.getEntriesByCourseAttempt(action.payload.course_id, action.payload.course_attempt_id);
      } else {
        return Promise.resolve([]);
      }
    }),
    map(
      (taskAttempts) => {
        if (!taskAttempts) {
          taskAttempts = [];
        }

        return {
          type: AssessmentActions.UPDATE_ASSESSMENT_ENTRIES,
          payload: taskAttempts
        };
      }
    ),));


  fetchAssessments$ = createEffect(() => this.actions$.pipe(
    ofType(AssessmentActions.FETCH_ASSESSMENTS),
    switchMap((action: AssessmentActions.FetchAssessments) => {
      if (localStorage.getItem('cirrus-token') && action.payload && action.payload.course_id && action.payload.user_id) {
        this.store$.dispatch(new AssessmentActions.PushLoading('assessments'));
        return this.instructorService.getStudentAssessmentsForCourse(action.payload.user_id, action.payload.course_id);
      } else {
        return Promise.resolve([]);
      }
    }),
    map(
      (assessments) => {
        if (!assessments) {
          assessments = [];
        }

        return {
          type: AssessmentActions.UPDATE_ASSESSMENTS,
          payload: assessments
        };
      }
    ),));


  fetchDeferments$ = createEffect(() => this.actions$.pipe(
    ofType(AssessmentActions.FETCH_DEFERMENTS),
    switchMap((action: AssessmentActions.FetchDeferments) => {
      if (localStorage.getItem('cirrus-token') && action.payload && action.payload.course_id && action.payload.user_id) {
        this.store$.dispatch(new AssessmentActions.PushLoading('deferments'));
        return this.defermentService.getTaskDefermentsForCourseAttempt(action.payload.user_id, action.payload.course_attempt_id);
      } else {
        return Promise.resolve([]);
      }
    }),
    map(
      (deferments) => {
        if (!deferments) {
          deferments = [];
        }

        return {
          type: AssessmentActions.UPDATE_DEFERMENTS,
          payload: deferments
        };
      }
    ),));


  fetchDeferReasons$ = createEffect(() => this.actions$.pipe(
    ofType(AssessmentActions.FETCH_DEFER_REASONS),
    switchMap((action: AssessmentActions.FetchDeferments) => {
      if (localStorage.getItem('cirrus-token')) {
        this.store$.dispatch(new AssessmentActions.PushLoading('defer_reasons'));
        return this.deferReasonService.getDeferReasons();
      } else {
        return Promise.resolve([]);
      }
    }),
    map(
      (defer_reasons) => {
        if (!defer_reasons) {
          defer_reasons = [];
        }

        return {
          type: AssessmentActions.UPDATE_DEFER_REASONS,
          payload: defer_reasons
        };
      }
    ),));


  fetchCourseWorkbook$ = createEffect(() => this.actions$.pipe(
    ofType(AssessmentActions.FETCH_COURSE_WORKBOOK),
    switchMap((action: AssessmentActions.FetchCourseWorkbook) => {
      if (localStorage.getItem('cirrus-token') && action.payload) {
        console.log(action.payload);
        return this.instructorService.getStudentWorkbookForCourse(action.payload.id);
      } else {
        return Promise.resolve([]);
      }
      }),
    map(
      (course_workbook) => {
        if(!course_workbook) {
          course_workbook = null;
        }

        return {
          type: AssessmentActions.SET_COURSE_WORKBOOK,
          payload: course_workbook
        };
      }
    ),));


  fetchAssessmentProgress$ = createEffect(() => this.actions$.pipe(
    ofType(AssessmentActions.FETCH_ASSESSMENT_PROGRESS, AssessmentActions.SET_COURSE),
    switchMap((action: AssessmentActions.FetchAssessmentProgress) => {
      if (localStorage.getItem('cirrus-token') && action.payload.course_id && action.payload.user_id) {
        return this.progressService.getUserProgressCourseDetail(action.payload.course_id, action.payload.user_id);
      } else {
        return Promise.resolve([]);
      }
    }),
    map(
      (user_progress) => {
        if (!user_progress) {
          user_progress = [];
        }

        return {
          type: AssessmentActions.UPDATE_ASSESSMENT_PROGRESS,
          payload: user_progress
        };
      }
    ),));

  constructor(private actions$: Actions,
              private flightLogService: LessonFlightLogService,
              private instructorService: InstructorService,
              private defermentService: TaskDefermentService,
              private deferReasonService: DeferReasonService,
              private progressService: ProgressService,
              private taskService: TaskService,
              private store$: Store<fromApp.AppState>) {}
}
