import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from '@app/services/data-services/course.service';
import { PdfService } from '@app/services/helper/pdf.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@app/components/shared/snackbar/snackbar.component';
import { CertificatesService } from '@app/services/data-services/certificates.service';
import { environment } from '../../../../../environments/environment';
import { EmbarkJobDialogComponent } from '@app/components/shared/embark-job-dialog/embark-job-dialog.component';
import { FlightOpsEventDialogComponent } from '@app/components/shared/flight-ops-event-dialog/flight-ops-event-dialog.component';
import { CourseAttemptService } from '@app/services/data-services/course-attempt.service';
import { CourseAttempt } from '@app/models/course/course-attempt';
import { SummaryTotal } from '@app/models/course/summary';
import { UserService } from '@app/services/data-services/user.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

class CourseDetailParams {
  id: string;
  user_id: string;
}

@Component({
  selector: 'app-transcript-course-detail',
  templateUrl: './transcript-course-detail.component.html',
  styleUrls: ['./transcript-course-detail.component.scss'],
})
export class TranscriptCourseDetailComponent implements OnInit {
  requirements: any[] = [];
  userName = '';
  url = null;
  loading = true;
  exporting = false;
  embarkDialog: any;
  flightOpsEventDialog: any;
  contents: any[] = [];
  role = '';
  exportTranscript = false;
  @Input('user_id') user_id: number;
  @Input('course_id') course_id: number;
  @Input('course_attempt_id') course_attempt_id: number;
  @ViewChild('badge_image') badge_image: ElementRef;
  @ViewChild('default_badge') default_badge: ElementRef;
  @ViewChild('course_detail') course_detail: ElementRef;
  @Output('done') done: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('image', { static: true }) image: ElementRef;

  // Remove me once force completion is live...
  force_complete_config: string = environment.force_completion;
  courseAttempt: CourseAttempt;
  lessonTypeTotals: SummaryTotal;
  assessmentTotals: SummaryTotal;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private courseService: CourseService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private certificateService: CertificatesService,
    private pdfService: PdfService,
    private _ngZone: NgZone,
    private courseAttemptService: CourseAttemptService
  ) {
    window['transcriptDetail'] = { component: this, zone: _ngZone };
  }

  ngOnInit() {
    this.loading = true;
    this.role = localStorage.getItem('cirrus-role');

    const inputsOrRouteParams = () => {
      if (this.course_attempt_id) {
        return of({ id: this.course_attempt_id });
      } else {
        return this.activatedRoute.params;
      }
    };

    const getCourseAttempt = ({ id }) =>
      this.courseAttemptService.getCourseAttempt(id);

    inputsOrRouteParams()
      .pipe(switchMap(getCourseAttempt))
      .subscribe(courseAttempt => {
        this.courseAttempt = courseAttempt;
        this.userName = courseAttempt.user_course.user.display_name;
        this.setAssessmentTotals();
        this.setLessonTypeTotals();
        this.loading = false;
        this.done.emit();
      });
  }

  setLessonTypeTotals() {
    const summaries = [
      this.courseAttempt.lesson_types.self_study,
      this.courseAttempt.lesson_types.ground,
      this.courseAttempt.lesson_types.flight,
      this.courseAttempt.lesson_types.simulator,
    ];
    this.lessonTypeTotals = new SummaryTotal(summaries);
  }

  setAssessmentTotals() {
    const summaries = [
      this.courseAttempt.assessments.ground,
      this.courseAttempt.assessments.flight,
    ];
    this.assessmentTotals = new SummaryTotal(summaries);
  }

  isStarted() {
    return !!this.courseAttempt.course.progress;
  }

  isCompleted() {
    const {
      course: { progress },
    } = this.courseAttempt;
    return progress && progress.status === 'completed';
  }

  showEmbarkID() {
    return (
      this.courseAttempt.user_course?.embark_job_id == null &&
      this.role !== 'instructor'
    );
  }

  showFlightOpsEventId() {
    return (
      this.courseAttempt.user_course?.flight_ops_event_id == null &&
      this.role !== 'instructor'
    );
  }

  getProgressLabel() {
    const { overall_progress: overall } = this.courseAttempt;
    if (overall === 100) {
      return 'Completed';
    } else if (overall === 0) {
      return 'Not Started';
    } else {
      return 'In Progress';
    }
  }

  openDialog() {
    this.embarkDialog = this.dialog.open(EmbarkJobDialogComponent);
  }

  setEmbarkID() {
    this.openDialog();
    this.embarkDialog.afterClosed().subscribe(embarkID => {
      if (embarkID) {
        this.handleEmbarkEdit(embarkID);
      }
    });
  }

  openDialog2() {
    this.flightOpsEventDialog = this.dialog.open(FlightOpsEventDialogComponent);
  }

  setFlightOpsEventID() {
    this.openDialog2();
    this.flightOpsEventDialog.afterClosed().subscribe(flightOpsEventID => {
      if (flightOpsEventID) {
        this.handleFlightOpsEventEdit(flightOpsEventID);
      }
    });
  }

  downloadTranscript() {
    this.exportTranscript = true;
    this.loading = true;
  }

  export() {
    window['transcriptDetail'].component.downloadPdf(() => {
      this.exportTranscript = false;
      this.loading = false;
    });
  }

  downloadPdf(callback?: Function) {
    this.exporting = true;
    setTimeout(() => {
      let badge = this.badge_image;
      if (this.default_badge) {
        badge = this.default_badge;
      }
      const container = document.getElementById('export-wrapper');
      this.pdfService.createPdf(
        container,
        this.course_detail.nativeElement.scrollHeight,
        this.userName,
        badge.nativeElement,
        callback
      );
      this.exporting = false;
    }, 0);
  }

  download(cert) {
    this.url = cert.certificate.certificate_image;
    this.image.nativeElement.onload = () => {
      this.pdfService.download(this.image.nativeElement, cert, () => {
        this.url = '';
      });
    };
  }

  downloadCertificate(course_id) {
    this.certificateService
      .getStudentCertificate(course_id, this.user_id)
      .then(cert => this.download(cert));
  }

  handleEmbarkEdit(value) {
    const { user_course } = this.courseAttempt;
    if (value !== user_course.embark_job_id) {
      this.courseService
        .updateEmbarkID(user_course.id, value)
        .then(response => {
          user_course.embark_job_id = value;
          let embarkStatus = value !== null ? 'updated' : 'removed';
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: 'Embark Job ID has been ' + embarkStatus,
          });
        });
    }
  }

  handleFlightOpsEventEdit(value) {
    const { user_course } = this.courseAttempt;
    if (value !== user_course.flight_ops_event_id) {
      this.courseService
        .updateFlightOpsEventID(user_course.id, value)
        .then(response => {
          user_course.flight_ops_event_id = value;
          let flightOpsStatus = value !== null ? 'updated' : 'removed';
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: 'Flight Ops Event ID has been ' + flightOpsStatus,
          });
        });
    }
  }
}
