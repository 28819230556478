<div class="content-title-wrapper">
  <h1 mat-dialog-title>{{ dialogTitle }}</h1>
  <button mat-icon-button mat-dialog-close (click)="close()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<mat-divider></mat-divider>
<mat-dialog-content class="form-padding">
  <div class="margin-333">
    {{ dialogBodyContent }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="action-wrapper">
    <button mat-button [color]="'primary'" type="submit" (click)="save(data)">
      {{ dialogConfirmButtonLabel }}
    </button>
    <button mat-button [color]="'danger'" (click)="close()">
      {{ dialogCancelButtonLabel }}
    </button>
  </div>
</mat-dialog-actions>
