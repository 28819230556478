<div class="full-width">

  <div class="courseheader">
    <div id="course-wrapper-id" class="course-wrapper-alt">
      <div class='header-text-area'>
        <mat-icon class="breadcrumb-icon">keyboard_arrow_left</mat-icon>
        <a class="breadcrumb hover-pointer" (click)="goBack()">Back</a>
        <h2 class="course-title-header">Receipt</h2>
      </div>
    </div>
  </div>
</div>

<div>
  <div class="course-wrapper-alt course-padding-alt top-padding">
    <div class="max-width-course top-padding"
         fxLayout='row wrap'
         fxLayout.sm="row"
         fxLayout.xs="column"
         fxLayoutAlign.sm="start"
         fxLayoutAlign.xs="start"
         fxFlex.xs="100">
      <div fxFlex="100"
           fxFlex.sm="100"
           fxFlex.xs="100"
           fxLayout="column"
           fxLayoutAlign="start start" class="full-width">
        <h5 class="bold-txt">Purchase Date - {{order?.completed_date | date:"MM/dd/yyyy"}}</h5>
        <h5 class="bold-txt">Order #: {{order?.id}}</h5>

        <div class="blue full-width" fxLayout="column">
          <p class="name">Cirrus Design Corporation <br>
          110 Tyson Blvd., Suite 201 <br>
          Alcoa, Tennessee 37701</p>
          
          <div class="full-width move-up">
            <a href="#" class="link">cirrusapproach.com</a>
          </div>
        </div>

        <div class="full-width user-info-row" fxLayout="row">
          <p class="bold-txt">Sold To:</p>
          <div fxLayout="column" class="user-info">
            <p>{{order?.order_bill_to?.bt_first_name}} {{order?.order_bill_to?.bt_last_name}}</p>
            <p class="move-up">{{order?.order_bill_to?.bt_postal_zip}}</p>
          </div>
        </div>

        <div class="full-width">
          <div class="table-container">
            <table>
              <tr id="receipt-info">
                <th>Item</th>
                <th>Ordered</th>
                <th>Quantity</th>
                <th>Price</th>
                <th>Tax</th>
                <th class="align-left">Amount</th>
              </tr>
              <tr *ngFor="let item of order?.order_line_items">
                <td>{{item?.product_title}}</td>
                <td>{{order?.completed_date | date:"MM/dd/yyyy"}}</td>
                <td>{{item?.line_item_quantity}}</td>
                <td>{{item?.line_item_unit_price | currency:"USD"}}</td>
                <td>{{item?.line_item_tax | currency:"USD"}}</td>
                <td class="align-left">{{getTotal(item?.line_item_unit_price, item?.line_item_tax, item?.line_item_quantity) | currency:"USD"}}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="bold-txt align-left pt-25 ">Subtotal: </td>
                <td class="align-left pt-25">{{order.order_total - order.order_tax_amount | currency:'USD'}}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="bold-txt">Tax: </td>
                <td class="align-left">{{order.order_tax_amount | currency:'USD'}}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="bold-txt align-left">Total: </td>
                <td class="align-left">{{order.order_total | currency:'USD'}}</td>
              </tr>
            </table>
          </div>
          <br><br><br>
          <p style="font-size: 10px; padding: 10px"><strong>Return Policy:</strong><br>Cirrus Aircraft’s flight training products are designed to make you a better pilot. If you are not pleased with your purchase, please contact us by phone (domestic - 800.279.4322 Ext. 3); +1.218.529.7200 Ext. 3) or by email (training@cirrusaircraft.com). We will do our best to address any concerns with your purchase, but Cirrus Aircraft is unable to exchange any items purchased from Cirrus Approach. In terms of returning physical training merchandise purchased from the Cirrus Approach website, such merchandise can be returned for a refund within 30 days of the ship date. You are responsible for shipping the merchandise to the address below and all shipping fees (non-refundable). Digital training courses that have not been started can be refunded within 30 days of purchase by sending an email to training@cirrusaircraft.com explaining your refund request. </p>
        </div>
      </div>
    </div>
  </div>
</div>
