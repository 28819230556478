<div id="instructor-connect-wrapper">
  <div class="instructor-connect-title-wrapper">
    <h1 mat-dialog-title *ngIf="!remove">Add ({{users?.length}}) Instructor(s) to Course</h1>
    <h1 mat-dialog-title *ngIf="remove">Remove Instructor from Course</h1>
  </div>
  <mat-dialog-content>
    <div class="full-width pb-1">
      <input
        type='text'
        style='padding:8px;margin:15px auto;width:30%;'
        placeholder='Filter Courses'
        (keyup)='updateFilter($event)'
      />
    </div>

    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
    <table mat-table matSort [dataSource]="rows" class="mat-elevation-z3">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleSelectAll() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let user">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(user) : null"
            [checked]="selection.isSelected(user)"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let course">{{this.remove ? course.course.title : course.title }}</td>
      </ng-container>

      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let course">{{this.remove ? ellipsesLength(course.course.desc) : ellipsesLength(course.desc) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let course; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSize]="15"></mat-paginator>
    <div class="action-wrapper">
      <button mat-button type="button" mat-dialog-close>Cancel</button>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!selection.selected.length">Submit</button>
    </div>
