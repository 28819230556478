import {Component, Inject, OnInit} from '@angular/core';
import {CourseService} from '@app/services/data-services/course.service';
import {UserCourse} from '@app/models/course/user-course';
import {ActivatedRoute} from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ShortTextPipe} from '@app/pipes/short-text.pipe';
import {LocationStrategy} from '@angular/common';

@Component({
  selector: 'app-select-course-dialog',
  templateUrl: './select-course-dialog.component.html',
  styleUrls: ['./select-course-dialog.component.scss']
})
export class SelectCourseDialogComponent implements OnInit {
  courses: UserCourse[] = [];
  selected: any[] = [];
  selectedCourse: UserCourse;
  shortPipe = new ShortTextPipe();
  columns = [{name: 'Name', prop: 'course_version.title'}, {name: 'Description', prop: 'course_version.overview', pipe: this.shortPipe}];

  constructor(public dialogRef: MatDialogRef<SelectCourseDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private courseService: CourseService,
              private location: LocationStrategy) { }

  ngOnInit() {
      if (this.data) {
        this.courseService.getStudentCourses(this.data)
          .then(user_courses => this.courses = user_courses);
      } else {
        this.location.back();
      }
  }

  onSelect(row) {
    this.selectedCourse = row.selected[0];
  }

  save() {
    if (this.selectedCourse) {
      this.dialogRef.close(this.selectedCourse);
    }
  }

  close() {
    this.dialogRef.close();
  }

}
