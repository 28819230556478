<mat-dialog-content class="content-wrapper">
  <div class="content-wrapper"
       fxLayout="column wrap">
    <div class="navtop" fxFlex="100">
      <button mat-icon-button mat-dialog-close (click)="close()"><mat-icon class="close-icon">close</mat-icon></button>
      <div class='content-title-dialog'>Content Title</div>
      <div fxFlex></div>
      <!--<div>-->
        <!--<mat-icon class="bookmark" (click)="content.myBookshelf = !content.myBookshelf" *ngIf="!content.myBookshelf">bookmark_border</mat-icon>-->
        <!--<mat-icon class="bookmark" (click)="content.myBookshelf = !content.myBookshelf" *ngIf="content.myBookshelf">bookmark</mat-icon>-->
      <!--</div>-->
    </div>
    <div class="full-width content-player-wrapper">
      <div class="full-width popout-wrapper">
        <iframe src="https://player.vimeo.com/video/151899971?autoplay=0&loop=1&autopause=0&transparent=0" width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>

    </div>
    <div class="navbottom full-width" fxFlex="100" >
      <button mat-raised-button class='dialog-buttons'>Previous Content</button>
      <div fxFlex=""></div>
      <button mat-raised-button class='dialog-buttons' tabindex="-1">Next Content</button>
    </div>
  </div>
</mat-dialog-content>
