import { Injectable } from '@angular/core';
import { Avionics } from '@app/models/aircraft/avionics';
import { HttpService } from '@app/services/http-service.service';

@Injectable()
export class AvionicsService {

  avionicsUrl = 'avionics';

  constructor(private http: HttpService) {}

  getAvionics(): Promise<Avionics[]> {
    return this.http.get<Avionics[]>(this.avionicsUrl)
      .toPromise()
      .catch(AvionicsService.handleError);
  }

  getOneAvionics(id: number): Promise<Avionics> {
    const url = `${this.avionicsUrl}/${id}`;

    return this.http.get<Avionics>(url)
      .toPromise()
      .catch(AvionicsService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.avionicsUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(AvionicsService.handleError);
  }

  create(avionics: Avionics): Promise<Avionics> {
    return this.http
      .post<Avionics>(this.avionicsUrl, JSON.stringify(avionics))
      .toPromise()
      .catch(AvionicsService.handleError);
  }

  update(avionics: Avionics): Promise<void> {
    const url = `${this.avionicsUrl}/${avionics.id}`;

    return this.http
      .put(url, JSON.stringify(avionics))
      .toPromise()
      .then(() => avionics)
      .catch(AvionicsService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
