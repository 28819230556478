<mat-toolbar class="topbar">
  <button
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <span fxFlex></span>
  <!-- Notification toggle button -->
  <button
    mat-icon-button
    matTooltip="Notifications"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
  >
    <mat-icon>notifications</mat-icon>
    <app-badge *ngIf="notifications?.length">{{
      notifications.length
    }}</app-badge>
  </button>
  <!-- Top left user menu -->
  <div id="account_wrapper" class="icon-spacing">
    <button
      id="account_icon"
      class="icon-spacing"
      mat-icon-button
      [mat-menu-trigger-for]="appMenu"
    >
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
      <ng-container *appFeature="'ctc_admin_2024'">
        <button
          mat-menu-item
          *ngIf="showCTCDashboard()"
          onclick="location.href='/ctc-admin';"
        >
          New CTC Dashboard
        </button>
      </ng-container>
      <ng-container *appFeature="'ctc_admin'">
        <button
          mat-menu-item
          *ngIf="showCTCDashboard()"
          routerLink="ctc/dashboard"
        >
          CTC Dashboard
        </button>
      </ng-container>
      <button
        mat-menu-item
        *ngIf="showInstructorDashboard()"
        routerLink="instructor/dashboard"
      >
        Instructor Dashboard
      </button>
      <button
        mat-menu-item
        *ngIf="showAdminDashboard()"
        routerLink="admin/dashboard"
      >
        Admin Dashboard
      </button>
      <a
        mat-menu-item
        *ngIf="hrefSfProfile()"
        [href]="hrefSfProfile()"
        target="_blank"
        >Manage Profile</a
      >
      <button mat-menu-item routerLink="purchase-history">
        Purchase History
      </button>
      <button mat-menu-item routerLink="recent-activity">My Learning</button>
      <mat-divider></mat-divider>
      <button
        mat-menu-item
        *ngIf="getImpersonation()"
        (click)="logoutOfImpersonation()"
      >
        Impersonation Logout
      </button>
      <button mat-menu-item *ngIf="!getImpersonation()" (click)="logout()">
        Log Out
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
