import {Injectable, EventEmitter, OnInit} from '@angular/core';
import {Notification} from '@app/models/notification/notification';

@Injectable()
export class GlobalEventManagerService {

  constructor() {
    // console.log('Creating instance of service');
  }

  public showNavbar: EventEmitter<any> = new EventEmitter();

  public menu: EventEmitter<any> = new EventEmitter();

  public menuType: EventEmitter<any> = new EventEmitter();

  public topBar: EventEmitter<any> = new EventEmitter();

  public footer: EventEmitter<any> = new EventEmitter();

  public updatedNotifications: EventEmitter<Notification[]> = new EventEmitter();

  public updateNotifications(notifications) {
    this.updatedNotifications.emit(notifications);
  }

  public getMenu(menuType: any) {
    this.menuType.emit(menuType);
  }

  public setMenu(data: any) {
    this.menu.emit(data);
  }

}
