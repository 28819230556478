import { I } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Stage } from '@app/models/course/workbook/stage/stage';
import { HttpService } from '@app/services/http-service.service';
import { FileUploader } from 'ng2-file-upload';
@Component({
  selector: 'app-stage-editor-dialog',
  templateUrl: './stage-editor-dialog.component.html',
  styleUrls: [
    '../../../../../../components/shared/general-table-dialog/general-table-dialog.component.scss',
    './stage-editor-dialog.component.scss',
  ],
})
export class StageEditorDialogComponent implements OnInit {
  stage: Stage = new Stage();
  public image_uploader: FileUploader = new FileUploader({});
  imageUrl: any = '';

  type: string;
  editorConfig = {
    toolbar: [
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
      [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'superscript',
        'subscript',
      ],
      ['fontName', 'fontSize', 'color'],
      ['orderedList', 'unorderedList', 'link', 'image'],
    ],
  };
  constructor(
    public dialogRef: MatDialogRef<StageEditorDialogComponent>,
    private http: HttpService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.stage) {
      this.stage = data.stage.value;
    }

    this.type = data.type;
  }

  ngOnInit() {
    this.imageUrl = this.http.getFullUrl(
      this.imageUrl + 'images/upload/rich_text'
    );
  }

  close() {
    this.dialogRef.close();
  }
  save() {
    this.dialogRef.close(this.stage);
  }
}
