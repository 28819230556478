import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidenav-link',
  templateUrl: './sidenav-link.component.html',
  styleUrls: ['./sidenav-link.component.scss']
})
export class SidenavLinkComponent implements OnInit {
  @Input() public location: string;
  @Input() public isExternal = false;
  @Input() public newTab = false;

  constructor() { }

  ngOnInit(): void {
  }

}
