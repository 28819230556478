import { Component, OnInit } from '@angular/core';
import { IMenuItem } from '@app/services/navigation/navigation.service';

@Component({
  selector: 'app-task-manager',
  templateUrl: './task-manager.component.html',
  styleUrls: ['./task-manager.component.scss'],
})
export class TaskManagerComponent implements OnInit {
  navItems: IMenuItem[] = [
    {
      name: 'Tasks',
      state: '/admin/courses/task/overview',
      type: 'link',
    },
    // {
    //   name: 'Defer Reasons',
    //   state: '/admin/courses/task/defer-reasons-overview',
    //   type: 'link'
    // }
  ];

  constructor() {}

  ngOnInit() {}
}
