<div class="content-wrapper">
  <div class="content-title-wrapper">
    <h1 mat-dialog-title>{{ type }} Stage</h1>
    <button mat-icon-button mat-dialog-close (click)="close()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div class="margin-333">
    <form #form="ngForm" id="course-stage-form" (ngSubmit)="save()">
      <div class="form-row">
        <input
          id="stage-title"
          name="course-title"
          class="form-input"
          [(ngModel)]="stage.title"
          placeholder="Title"
          required
        />
      </div>
      <div class="form-row">
        <app-wysiwyg-editor
          title=""
          [displayEditor]="true"
          [displayUploader]="true"
          editorId="stage-overview"
          placeholder="Stage Overview"
          [(text)]="stage.overview"
        ></app-wysiwyg-editor>
      </div>
      <div class="form-row">
        <label class="form-label">Lessons Are Linear</label>
        <mat-slide-toggle
          [(ngModel)]="stage.lessons_are_linear"
          [color]="'#0087ce'"
          name="course_badge"
        >
          <span>{{ stage.lessons_are_linear ? 'On' : 'Off' }}</span>
          <span class="not-active-info"
            >These lessons must be completed in order</span
          >
        </mat-slide-toggle>
      </div>
    </form>
  </div>
  <mat-divider></mat-divider>
  <mat-dialog-actions>
    <div class="action-wrapper">
      <button
        class="mr-1"
        mat-raised-button
        [color]="'primary'"
        (click)="save()"
      >
        Save
      </button>
    </div>
  </mat-dialog-actions>
</div>
