import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms any camelCase, snake_case, or kebab-case string into a lowercase string with spaces.
 * Searches for the first instance of either an underscore, dash, or capital letter and assumes the case type.
 *
 * @example
 * {{'camelCase' | spaces}}
 * Outputs 'camel case'
 *
 * {{'snake_case' | spaces}}
 * Outputs 'snake case'
 *
 * {{'kebab-case' | spaces}}
 * Outputs 'kebab case'
 */
@Pipe({
  name: 'spaces'
})
export class SpacesPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if (!value) {
      return value;
    }
    const isSnakeCase = value.search('_') > -1;
    const isKebabCase = value.search('-') > -1;
    const isCamelCase = value.match(/[A-Z]/) !== null;

    if(isSnakeCase) {
      return this.transformSnakeCase(value);
    } else if (isKebabCase) {
      return this.transformKebabCase(value);
    } else if (isCamelCase) {
      return this.transformCamelCase(value);
    } else {
      return value;
    }
  }

  private transformSnakeCase(value: string) {
    const elements = value.split('_');
    return elements.join(' ');
  }

  private transformKebabCase(value: string) {
    const elements = value.split('-');
    return elements.join(' ');
  }

  private transformCamelCase(value: string) {
    return value.replace(/(?!^)([A-Z])/g, " $1");
  }
}
