import {Component, OnInit, ViewChild} from '@angular/core';
import { CourseCategory } from '../../../../../models/course/course-category';
import { Router } from '@angular/router';
import { CourseCategoryService } from '../../../../../services/data-services/course-category.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-course-categories-overview',
  templateUrl: './course-categories-overview.component.html',
  styleUrls: ['./course-categories-overview.component.scss']
})
export class CourseCategoriesOverviewComponent implements OnInit {
  course_category: CourseCategory;
  rows: CourseCategory[] = [];
  temp: CourseCategory[] = [];

  @ViewChild('table', { static: true }) table: DatatableComponent;

  constructor(private categoryService: CourseCategoryService, 
              private router: Router,
              public snackbar: MatSnackBar) {
    this.course_category = new CourseCategory();
    this.getCategories();
  }

  ngOnInit() {
  }

  getCategories() {
    this.categoryService.getCategories()
        .then(categories => {
          // Filter out 'uncategorized' default category from being displayed/actionable
          this.rows = this.temp = categories.filter(category => category.id !== 0);
        });
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
  editCategory(category) {
    this.router.navigate(['admin/courses/category/course/manage', category.id]);
  }

  removeCategory(category: CourseCategory) {
    if (confirm('Are you sure you want to remove category?')) {
      this.categoryService.delete(category.id)
        .then(() => {
          this.snackbar.open('Deleted')
          this.rows = this.rows.filter(item => item !== category)
        });
    }
  }
}
