import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit {
  @Input('progress') progress: number;
  @Input('badge') badge: string;
  @Input('percentage') percentage = false;
  constructor() { }

  ngOnInit() {
  }

}
