import {OrderBillTo} from '@app/models/order/order-bill-to';
import {OrderLineItem} from '@app/models/order/order-line-item';
import {Payment} from '@app/models/order/payment';
import {User} from '@app/models/user/user';

export class Order {
  id?: number;
  user_id?: number;
  order_status?: number;
  created_date?: Date;
  completed_date?: Date;
  order_total?: number;
  line_total?: number;
  shipping_amount?: number;
  order_tax_amount?: number;
  discount_amount?: number;
  refunded_date?: Date;
  refunded_amount?: number;
  session?: string;
  order_bill_to?: OrderBillTo;
  order_line_items?: OrderLineItem[];
  payments?: Payment[];
  user?: User;
  created_at?: Date;

  constructor() {
    this.order_line_items = [];
    this.payments = [];
  }
}

export enum OrderStatus {
  in_progress = 0,
  completed = 1,
  totally_refunded = 2,
  partially_refunded = 3
}

export const OrderStatusMap = {
  0: 'In Progress',
  1: 'Completed',
  2: 'Refunded',
  3: 'Partial Refund'
};
