<div fxLayout='row' #wrapper>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">{{user_name}}</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="full-width pb-1">
          <input
            type='text'
            style='padding:8px;margin:15px auto;width:30%;'
            placeholder='Filter Assessments'
            (keyup)='updateFilter($event)'
          />
        </div>
        <div class="mat-box-shadow">
          <ngx-datatable #table class="material bg-white list-view"
                       [columnMode]="'force'"
                       [headerHeight]="50"
                       [footerHeight]="50"
                       [loadingIndicator]="isLoading"
                       [rowHeight]="'auto'"
                       [limit]="50"
                       [rows]="rows">
            <ngx-datatable-column name="Assessment Title" prop="assessment"></ngx-datatable-column>
            <ngx-datatable-column name="Type" prop="type"></ngx-datatable-column>
            <ngx-datatable-column name="Lesson" prop="lesson"></ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status"></ngx-datatable-column>
            <ngx-datatable-column name="Date Completed" prop="completed_date" [pipe]="datePipe"></ngx-datatable-column>
            <ngx-datatable-column name="Logs" prop="logs"></ngx-datatable-column>
            <ngx-datatable-column name="Total" prop="total"></ngx-datatable-column>
            <ngx-datatable-column name="Completed" prop="completed"></ngx-datatable-column>
            <ngx-datatable-column name="In Progress" prop="in_progress"></ngx-datatable-column>
              <ngx-datatable-column
                [sortable]="false"
                [canAutoResize]="false"
                [draggable]="false"
                [resizeable]="false"
                name="Deferred">
                <ng-template let-rowIndex="rowIndex" let-row='row' ngx-datatable-cell-template>
                  <span>
                    {{getNextPreviousOfType(row, rowIndex)}}
                  </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="false"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false">
              <ng-template  let-row='row' let-rowIndex='rowIndex' ngx-datatable-cell-template>
                <button mat-icon-button [matMenuTriggerFor]="entryMenu" class="hidden-on-open">
                  <mat-icon class="text-muted">more_vert</mat-icon>
                </button>
                <mat-menu #entryMenu="matMenu">
                  <button mat-menu-item (click)="viewAssessmentTask(row, rowIndex)">
                    <span>View Assessment</span>
                  </button>
                </mat-menu>
              </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
