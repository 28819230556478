<div class="notification-container">
  <div class="notification-title-container">
    <div class="title-badge-container">
      <h3 class="notifications-title">Notifications</h3>
      <div class="badge-container mat-badge-start-left">
        <span
          matBadge="{{ notificationsLength }}"
          matBadgeOverlap="false"
          matBadgeColor="warn"
        ></span>
      </div>
    </div>
    <div>
      <mat-icon class="pointer icon" (click)="close()">close</mat-icon>
    </div>
  </div>

  <div class="clear-all-container" (click)="clearAll($event)">
    <h6 class="clear-all-text">Clear All</h6>
  </div>

  <mat-list class="notification-list" role="list">
    <!-- Notification item -->
    <mat-list-item
      *ngFor="let n of notifications$ | async"
      class="notific-item"
      role="listitem"
    >
      <div *ngIf="n.notific_type == 'training_center_invite'">
        <app-training-center-invitation
          [invitation]="n"
          [notificationToken]="notificationsToken"
        ></app-training-center-invitation>
      </div>
      <div
        class="mat-list-text"
        *ngIf="n.notific_type !== 'training_center_invite'"
      >
        <div class="timeTrashContainer">
          <small class="time">{{
            n?.created_at | date : 'MMM dd, yyyy'
          }}</small>
          <img
            class="pointer"
            src="assets/images/Trash_Icon.svg"
            (click)="removeNotification(n)"
          />
        </div>

        <h6
          *ngIf="
            n?.notific_type?.toString() === 'instructor_invite' ||
            n?.notific_type?.toString() === 'student_invite'
          "
          class="pending-connection"
        >
          Pending Connection: {{ n?.sender?.contact?.name }}
        </h6>

        <h4 class="message">{{ n?.body }}</h4>

        <small class="from" *ngIf="n?.notific_type?.toString() === 'message'">
          From:
          <span style="text-decoration: underline"
            >{{ n?.sender?.email }}
          </span>
        </small>

        <div
          class="button-group"
          *ngIf="
            n?.notific_type?.toString() === 'instructor_invite' ||
            n?.notific_type?.toString() === 'student_invite'
          "
        >
          <button class="primary-btn pointer" (click)="acceptInvite(n)">
            Accept
          </button>
          <button class="secondary-btn pointer" (click)="declineInvite(n)">
            Decline
          </button>
        </div>
      </div>
    </mat-list-item>
  </mat-list>

  <div class="text-center mt-1" *ngIf="(notifications$ | async).length == 0">
    <h5>No new notifications.</h5>
  </div>
</div>
