import { Injectable } from '@angular/core';
import { Aircraft } from '@app/models/aircraft/aircraft';
import { HttpService } from '@app/services/http-service.service';

@Injectable()
export class AircraftService {

  aircraftUrl = 'aircrafts';

  constructor(private http: HttpService) {}

  getAircraft(): Promise<Aircraft[]> {
    return this.http.get<Aircraft[]>(this.aircraftUrl)
      .toPromise()
      .catch(AircraftService.handleError);
  }

  getOneAircraft(id: number): Promise<Aircraft> {
    const url = `${this.aircraftUrl}/${id}`;

    return this.http.get<Aircraft>(url)
      .toPromise()
      .catch(AircraftService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.aircraftUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(AircraftService.handleError);
  }

  create(aircraft: Aircraft): Promise<Aircraft> {
    return this.http
      .post<Aircraft>(this.aircraftUrl, JSON.stringify(aircraft))
      .toPromise()
      .catch(AircraftService.handleError);
  }

  update(aircraft: Aircraft): Promise<void> {
    const url = `${this.aircraftUrl}/${aircraft.id}`;

    return this.http
      .put(url, JSON.stringify(aircraft))
      .toPromise()
      .then(() => aircraft)
      .catch(AircraftService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

}
