<div class="title-wrapper">
  <div class="left">
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon class="material-icons close pointer">close</mat-icon>
    </button>
  </div>
  <div class="center">
    <h4 class="current-instructor" *ngIf="isConnected">Connected Instructor</h4>
  </div>
  <div class="right"></div>
</div>
<mat-divider class="dialog-divider"></mat-divider>
<mat-dialog-content>
  <div class="instructor-info-wrapper">
    <div>
      <p class="sub-header">Instructor</p>
      <p class="instructor-info">{{content?.user?.contact?.firstname}} {{content?.user?.contact?.lastname}}</p>
    </div>
    <div>
      <p class="sub-header">Qualifications</p>
      <p class="instructor-info">{{content?.user?.contact?.contact_user?.qualifications__c?.split(';').join(", ")}}</p>
    </div>
    <div>
      <p class="sub-header">Location</p>
      <p class="instructor-info">{{content?.user?.contact?.contact_user?.mailingcity}} {{content?.user?.contact?.contact_user?.mailingstate}}</p>
    </div>
    <div>
      <p class="sub-header">Training Center</p>
      <p class="instructor-info">{{content?.user?.contact?.contact_user?.account?.name}}</p>
    </div>
    <div>
      <p class="sub-header">Airport</p>
      <p class="instructor-info">{{content?.user?.contact?.contact_user?.home_airport?.name}}</p>
    </div>
    <div>
      <p class="sub-header">Phone</p>
      <p class="instructor-info">{{content?.user?.contact?.contact_user?.phone}}</p>
    </div>
    <div>
      <p class="sub-header">Email</p>
      <a href="mailto:{{content?.user?.contact?.contact_user?.email}}" target="_top" class="email-link instructor-info">
        {{content?.user?.contact?.contact_user?.email}}
      </a>
    </div>
  </div>
  <div class="action-wrapper">
    <div class="left"></div>
    <div class="center">
      <button class="connect-btn" mat-button *ngIf="!isPending && !isConnected" (click)="connectToInstructor(content)">CONNECT</button>
      <p class="request" *ngIf="isPending && !isConnected">REQUEST SENT</p>
      <button data-dismiss="modal" class="disconnect-btn" mat-button *ngIf="isConnected" (click)="removeInstructor()">DISCONNECT</button>
    </div>
    <div class="right"></div>
  </div>
</mat-dialog-content>
