import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MenuItem, MenuItemName } from '@app/models/menu/menu-item';

@Component({
  selector: 'app-mat-menu',
  templateUrl: './mat-menu.component.html',
  styleUrls: ['./mat-menu.component.scss'],
})
export class MatMenuComponent implements OnInit {
  @Input() inputItems: MenuItem[];
  @Input() menuIcon: [];
  @Input() menu_style?: any;
  @Input() instructorOnly: boolean;
  @Input() iconStyle: object;
  @Input() isToggled?: boolean;

  @Output() emitMenuItem: EventEmitter<any> = new EventEmitter();
  itemImageUrl: string;

  constructor() {}

  ngOnInit(): void {}

  setImage(item: MenuItem) {
    const { image_url } = item;
    return `assets/images/${image_url}`;
  }

  selectItem($event, item) {
    const obj = { $event, item };
    if (item.name === MenuItemName.InstructorOnly) {
      this.instructorOnly = $event.checked;
    }
    this.emitMenuItem.emit(obj);
  }
}
