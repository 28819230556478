<div class="sb-item">
  <mat-list-item class="sidebar-list-item" role="listitem" routerLinkActive="open">
    <a [href]="location" *ngIf="isExternal" [target]="newTab ? '_blank' : '_self'">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>
    <a [routerLink]="location" *ngIf="!isExternal">
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </a>

    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  </mat-list-item>
</div>
