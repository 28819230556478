import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrderBillTo} from '@app/models/order/order-bill-to';
import {Payment} from '@app/models/order/payment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OrderService} from '@app/services/data-services/order.service';
import { FullstoryService } from '@app/services/data-services/fullstory.service';
import { CustomValidators } from 'ng2-validation';
import {environment} from '../../../../../environments/environment';

import * as fromApp from '../../../../store/app.reducers';
import * as CheckoutActions from '../store/checkout.actions';
import {Store} from '@ngrx/store';
import {Order} from '@app/models/order/order';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';

const GENERIC_ERROR_MESSAGE = 'There was an error processing your order. Please refresh and try again, or contact Cirrus directly for further assistance';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent implements OnInit, OnDestroy {
  form: FormGroup;
  error = false;
  errorText: string;
  loading = false;
  // form = new FormGroup({
  //   card_number: new FormControl('5424000000000015', Validators.required),
  //   month: new FormControl('12', Validators.required),
  //   year: new FormControl('2020', Validators.required),
  //   cid: new FormControl('999', Validators.required),
  //   name_on_card: new FormControl('Ellen Johnson', Validators.required),
  //   postal_code: new FormControl('44628', Validators.required),
  // });

  bill_to: OrderBillTo = new OrderBillTo();
  payment: Payment = new Payment();
  order: Order;
  total = 0;
  subscription = new Subscription();
  d = new Date();
  month = this.d.getMonth() + 1;
  year = this.d.getFullYear();
  expirationError = false;

  defaultCourseThumb = environment.defaultThumbnailCourse;

  countries = [
    { name: 'Afghanistan', code: 'AF'},
    { name: 'Åland Islands', code: 'AX'},
    { name: 'Albania', code: 'AL'},
    { name: 'Algeria', code: 'DZ'},
    { name: 'American Samoa', code: 'AS'},
    { name: 'Andorra', code: 'AD'},
    { name: 'Angola', code: 'AO'},
    { name: 'Anguilla', code: 'AI'},
    { name: 'Antarctica', code: 'AQ'},
    { name: 'Antigua and Barbuda', code: 'AG'},
    { name: 'Argentina', code: 'AR'},
    { name: 'Armenia', code: 'AM'},
    { name: 'Aruba', code: 'AW'},
    { name: 'Australia', code: 'AU'},
    { name: 'Austria', code: 'AT'},
    { name: 'Azerbaijan', code: 'AZ'},
    { name: 'Bahrain', code: 'BH'},
    { name: 'Bahamas', code: 'BS'},
    { name: 'Bangladesh', code: 'BD'},
    { name: 'Barbados', code: 'BB'},
    { name: 'Belarus', code: 'BY'},
    { name: 'Belgium', code: 'BE'},
    { name: 'Belize', code: 'BZ'},
    { name: 'Benin', code: 'BJ'},
    { name: 'Bermuda', code: 'BM'},
    { name: 'Bhutan', code: 'BT'},
    { name: 'Bolivia, Plurinational State of', code: 'BO'},
    { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ'},
    { name: 'Bosnia and Herzegovina', code: 'BA'},
    { name: 'Botswana', code: 'BW'},
    { name: 'Bouvet Island', code: 'BV'},
    { name: 'Brazil', code: 'BR'},
    { name: 'British Indian Ocean Territory', code: 'IO'},
    { name: 'Brunei Darussalam', code: 'BN'},
    { name: 'Bulgaria', code: 'BG'},
    { name: 'Burkina Faso', code: 'BF'},
    { name: 'Burundi', code: 'BI'},
    { name: 'Cambodia', code: 'KH'},
    { name: 'Cameroon', code: 'CM'},
    { name: 'Canada', code: 'CA'},
    { name: 'Cape Verde', code: 'CV'},
    { name: 'Cayman Islands', code: 'KY'},
    { name: 'Central African Republic', code: 'CF'},
    { name: 'Chad', code: 'TD'},
    { name: 'Chile', code: 'CL'},
    { name: 'China', code: 'CN'},
    { name: 'Christmas Island', code: 'CX'},
    { name: 'Cocos (Keeling) Islands', code: 'CC'},
    { name: 'Colombia', code: 'CO'},
    { name: 'Comoros', code: 'KM'},
    { name: 'Congo', code: 'CG'},
    { name: 'Congo, the Democratic Republic of the', code: 'CD'},
    { name: 'Cook Islands', code: 'CK'},
    { name: 'Costa Rica', code: 'CR'},
    { name: "Côte d'Ivoire", code: 'CI'},
    { name: 'Croatia', code: 'HR'},
    { name: 'Cuba', code: 'CU'},
    { name: 'Curaçao', code: 'CW'},
    { name: 'Cyprus', code: 'CY'},
    { name: 'Czech Republic', code: 'CZ'},
    { name: 'Denmark', code: 'DK'},
    { name: 'Djibouti', code: 'DJ'},
    { name: 'Dominica', code: 'DM'},
    { name: 'Dominican Republic', code: 'DO'},
    { name: 'Ecuador', code: 'EC'},
    { name: 'Egypt', code: 'EG'},
    { name: 'El Salvador', code: 'SV'},
    { name: 'Equatorial Guinea', code: 'GQ'},
    { name: 'Eritrea', code: 'ER'},
    { name: 'Estonia', code: 'EE'},
    { name: 'Ethiopia', code: 'ET'},
    { name: 'Falkland Islands (Malvinas)', code: 'FK'},
    { name: 'Faroe Islands', code: 'FO'},
    { name: 'Fiji', code: 'FJ'},
    { name: 'Finland', code: 'FI'},
    { name: 'France', code: 'FR'},
    { name: 'French Guiana', code: 'GF'},
    { name: 'French Polynesia', code: 'PF'},
    { name: 'French Southern Territories', code: 'TF'},
    { name: 'Gabon', code: 'GA'},
    { name: 'Gambia', code: 'GM'},
    { name: 'Georgia', code: 'GE'},
    { name: 'Germany', code: 'DE'},
    { name: 'Ghana', code: 'GH'},
    { name: 'Gibraltar', code: 'GI'},
    { name: 'Greece', code: 'GR'},
    { name: 'Greenland', code: 'GL'},
    { name: 'Grenada', code: 'GD'},
    { name: 'Guadeloupe', code: 'GP'},
    { name: 'Guam', code: 'GU'},
    { name: 'Guatemala', code: 'GT'},
    { name: 'Guernsey', code: 'GG'},
    { name: 'Guinea', code: 'GN'},
    { name: 'Guinea-Bissau', code: 'GW'},
    { name: 'Guyana', code: 'GY'},
    { name: 'Haiti', code: 'HT'},
    { name: 'Heard Island and McDonald Islands', code: 'HM'},
    { name: 'Holy See (Vatican City State)', code: 'VA'},
    { name: 'Honduras', code: 'HN'},
    { name: 'Hong Kong', code: 'HK'},
    { name: 'Hungary', code: 'HU'},
    { name: 'Iceland', code: 'IS'},
    { name: 'India', code: 'IN'},
    { name: 'Indonesia', code: 'ID'},
    { name: 'Iran, Islamic Republic of', code: 'IR'},
    { name: 'Iraq', code: 'IQ'},
    { name: 'Ireland', code: 'IE'},
    { name: 'Isle of Man', code: 'IM'},
    { name: 'Israel', code: 'IL'},
    { name: 'Italy', code: 'IT'},
    { name: 'Jamaica', code: 'JM'},
    { name: 'Japan', code: 'JP'},
    { name: 'Jersey', code: 'JE'},
    { name: 'Jordan', code: 'JO'},
    { name: 'Kazakhstan', code: 'KZ'},
    { name: 'Kenya', code: 'KE'},
    { name: 'Kiribati', code: 'KI'},
    { name: 'Korea, Democratic Peoples Republic of', code: 'KP'},
    { name: 'Korea, Republic of', code: 'KR'},
    { name: 'Kuwait', code: 'KW'},
    { name: 'Kyrgyzstan', code: 'KG'},
    { name: 'Lao Peoples Democratic Republic', code: 'LA'},
    { name: 'Latvia', code: 'LV'},
    { name: 'Lebanon', code: 'LB'},
    { name: 'Lesotho', code: 'LS'},
    { name: 'Liberia', code: 'LR'},
    { name: 'Libya', code: 'LY'},
    { name: 'Liechtenstein', code: 'LI'},
    { name: 'Lithuania', code: 'LT'},
    { name: 'Luxembourg', code: 'LU'},
    { name: 'Macao', code: 'MO'},
    { name: 'Macedonia, the Former Yugoslav Republic of', code: 'MK'},
    { name: 'Madagascar', code: 'MG'},
    { name: 'Malawi', code: 'MW'},
    { name: 'Malaysia', code: 'MY'},
    { name: 'Maldives', code: 'MV'},
    { name: 'Mali', code: 'ML'},
    { name: 'Malta', code: 'MT'},
    { name: 'Marshall Islands', code: 'MH'},
    { name: 'Martinique', code: 'MQ'},
    { name: 'Mauritania', code: 'MR'},
    { name: 'Mauritius', code: 'MU'},
    { name: 'Mayotte', code: 'YT'},
    { name: 'Mexico', code: 'MX'},
    { name: 'Micronesia, Federated States of', code: 'FM'},
    { name: 'Moldova, Republic of', code: 'MD'},
    { name: 'Monaco', code: 'MC'},
    { name: 'Mongolia', code: 'MN'},
    { name: 'Montenegro', code: 'ME'},
    { name: 'Montserrat', code: 'MS'},
    { name: 'Morocco', code: 'MA'},
    { name: 'Mozambique', code: 'MZ'},
    { name: 'Myanmar', code: 'MM'},
    { name: 'Namibia', code: 'NA'},
    { name: 'Nauru', code: 'NR'},
    { name: 'Nepal', code: 'NP'},
    { name: 'Netherlands', code: 'NL'},
    { name: 'New Caledonia', code: 'NC'},
    { name: 'New Zealand', code: 'NZ'},
    { name: 'Nicaragua', code: 'NI'},
    { name: 'Niger', code: 'NE'},
    { name: 'Nigeria', code: 'NG'},
    { name: 'Niue', code: 'NU'},
    { name: 'Norfolk Island', code: 'NF'},
    { name: 'Northern Mariana Islands', code: 'MP'},
    { name: 'Norway', code: 'NO'},
    { name: 'Oman', code: 'OM'},
    { name: 'Pakistan', code: 'PK'},
    { name: 'Palau', code: 'PW'},
    { name: 'Palestine, State of', code: 'PS'},
    { name: 'Panama', code: 'PA'},
    { name: 'Papua New Guinea', code: 'PG'},
    { name: 'Paraguay', code: 'PY'},
    { name: 'Peru', code: 'PE'},
    { name: 'Philippines', code: 'PH'},
    { name: 'Pitcairn', code: 'PN'},
    { name: 'Poland', code: 'PL'},
    { name: 'Portugal', code: 'PT'},
    { name: 'Puerto Rico', code: 'PR'},
    { name: 'Qatar', code: 'QA'},
    { name: 'Réunion', code: 'RE'},
    { name: 'Romania', code: 'RO'},
    { name: 'Russian Federation', code: 'RU'},
    { name: 'Rwanda', code: 'RW'},
    { name: 'Saint Barthélemy', code: 'BL'},
    { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH'},
    { name: 'Saint Kitts and Nevis', code: 'KN'},
    { name: 'Saint Lucia', code: 'LC'},
    { name: 'Saint Martin (French part)', code: 'MF'},
    { name: 'Saint Pierre and Miquelon', code: 'PM'},
    { name: 'Saint Vincent and the Grenadines', code: 'VC'},
    { name: 'Samoa', code: 'WS'},
    { name: 'San Marino', code: 'SM'},
    { name: 'Sao Tome and Principe', code: 'ST'},
    { name: 'Saudi Arabia', code: 'SA'},
    { name: 'Senegal', code: 'SN'},
    { name: 'Serbia', code: 'RS'},
    { name: 'Seychelles', code: 'SC'},
    { name: 'Sierra Leone', code: 'SL'},
    { name: 'Singapore', code: 'SG'},
    { name: 'Sint Maarten (Dutch part)', code: 'SX'},
    { name: 'Slovakia', code: 'SK'},
    { name: 'Slovenia', code: 'SI'},
    { name: 'Solomon Islands', code: 'SB'},
    { name: 'Somalia', code: 'SO'},
    { name: 'South Africa', code: 'ZA'},
    { name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
    { name: 'South Sudan', code: 'SS'},
    { name: 'Spain', code: 'ES'},
    { name: 'Sri Lanka', code: 'LK'},
    { name: 'Sudan', code: 'SD'},
    { name: 'Suriname', code: 'SR'},
    { name: 'Svalbard and Jan Mayen', code: 'SJ'},
    { name: 'Swaziland', code: 'SZ'},
    { name: 'Sweden', code: 'SE'},
    { name: 'Switzerland', code: 'CH'},
    { name: 'Syrian Arab Republic', code: 'SY'},
    { name: 'Taiwan, Province of China', code: 'TW'},
    { name: 'Tajikistan', code: 'TJ'},
    { name: 'Tanzania, United Republic of', code: 'TZ'},
    { name: 'Thailand', code: 'TH'},
    { name: 'Timor-Leste', code: 'TL'},
    { name: 'Togo', code: 'TG'},
    { name: 'Tokelau', code: 'TK'},
    { name: 'Tonga', code: 'TO'},
    { name: 'Trinidad and Tobago', code: 'TT'},
    { name: 'Tunisia', code: 'TN'},
    { name: 'Turkey', code: 'TR'},
    { name: 'Turkmenistan', code: 'TM'},
    { name: 'Turks and Caicos Islands', code: 'TC'},
    { name: 'Tuvalu', code: 'TV'},
    { name: 'Uganda', code: 'UG'},
    { name: 'Ukraine', code: 'UA'},
    { name: 'United Arab Emirates', code: 'AE'},
    { name: 'United Kingdom', code: 'GB'},
    { name: 'United States', code: 'US'},
    { name: 'United States Minor Outlying Islands', code: 'UM'},
    { name: 'Uruguay', code: 'UY'},
    { name: 'Uzbekistan', code: 'UZ'},
    { name: 'Vanuatu', code: 'VU'},
    { name: 'Venezuela, Bolivarian Republic of', code: 'VE'},
    { name: 'Vietnam', code: 'VN'},
    { name: 'Virgin Islands, British', code: 'VG'},
    { name: 'Virgin Islands, U.S.', code: 'VI'},
    { name: 'Wallis and Futuna', code: 'WF'},
    { name: 'Western Sahara', code: 'EH'},
    { name: 'Yemen', code: 'YE'},
    { name: 'Zambia', code: 'ZM'},
    { name: 'Zimbabwe', code: 'ZW'},
  ];

  constructor(private orderService: OrderService,
              private fullstoryService: FullstoryService,
              private store$: Store<fromApp.AppState>,
              private router: Router) { }

  ngOnInit() {
    this.subscription.add(this.store$.select('checkout').subscribe(state => {
        this.order = state.order;
        this.total = 0;
        state.order.order_line_items.forEach(lineItem => {
          if (lineItem.product && lineItem.product.list_price) {
            this.total += parseFloat(lineItem.product.list_price.toString());
          }
        });
        this.setForm();
      }));
    }

  setForm() {
    let name = null;
    let zip = null;
    let address = null;

    if (this.order && this.order.user && this.order.user.contact && this.order.user.contact.name) {
      name = this.order.user.contact.name;
    }
    if (this.order && this.order.user && this.order.user.contact && this.order.user.contact.contact_user && this.order.user.contact.contact_user.mailingpostalcode) {
      zip = this.order.user.contact.contact_user.mailingpostalcode;
    }
    if (this.order && this.order.user && this.order.user.contact && this.order.user.contact.contact_user && this.order.user.contact.contact_user.mailingstreet) {
      address = this.order.user.contact.contact_user.mailingstreet;
    }
    this.form = new FormGroup({
      card_number: new FormControl('', [
        Validators.required,
        Validators.minLength(13),
        Validators.maxLength(16),
      ]),
      month: new FormControl('', [
        Validators.required,
      ]),
      year: new FormControl('', [
        Validators.required,
      ]),
      cid: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(4),
      ]),
      name_on_card: new FormControl(name, [
        Validators.required,
      ]),
      address_on_card: new FormControl(address, [
        Validators.required,
      ]),
      postal_code: new FormControl(zip, [
        Validators.required,
      ]),
      // sets default value of the countries dropdown to United States, this.countries[235]
      country: new FormControl(235, [
        Validators.required,
      ]),
    });
  }

  onSubmit() {
    if (this.form.valid) {

      if (this.isDiscoverCard(this.form.get('card_number').value)) {
        this.errorText = 'We are sorry! Discover Cards are currently not accepted';
        this.error = true;
        this.loading = false;
        return;
      }

      this.bill_to.bt_postal_zip = this.form.get('postal_code').value;

      const name = this.form.get('name_on_card').value.split(' ');
      this.bill_to.bt_first_name = name[0];
      this.bill_to.bt_last_name = name[name.length - 1];

      const country = this.countries[this.form.get('country').value];
      this.bill_to.bt_country = country.name;
      this.bill_to.country_code = country.code;
      this.bill_to.bt_address1 = this.form.get('address_on_card').value;

      this.payment.card_number = this.form.get('card_number').value;
      this.payment.card_cid = this.form.get('cid').value;
      this.payment.payment_type = 0;
      // this.payment.card_exp_date = new Date(parseInt(this.form.get('year').value), parseInt(this.form.get('month').value) - 1, 1);
      // this.payment.card_exp_date = new Date(Date.UTC(parseInt(this.form.get('year').value), parseInt(this.form.get('month').value) - 1, 1));
      this.payment.card_exp_date = this.form.get('year').value + '-' + this.form.get('month').value;

      this.loading = true;
      this.error = false;
      this.orderService.purchaseOrder(this.order, this.bill_to, this.payment)
        .then((res) => {
          const response = JSON.parse(res);
          const orderCourseNames = this.order.order_line_items.map(item => item.product.name);

          if (response && response.length) {
            this.errorText = GENERIC_ERROR_MESSAGE;
            this.error = true;
            this.loading = false;
          } else if (res) {
            this.fullstoryService.event('Course Purchase', {
              totalCost_real: parseFloat(this.total.toFixed(2)),
              courseTitles_strs: orderCourseNames,
              userRole_str: this.order.user.role
            });

            this.store$.dispatch(new CheckoutActions.CompletePurchase());
            this.router.navigate(['/recent-purchase', res]);
            this.loading = false;
          }
        })
        .catch((e) => {
          this.loading = false;
          this.error = true;
          this.errorText = GENERIC_ERROR_MESSAGE;
        });
    }
  }

  isDiscoverCard(card: String) {
    const cardno = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    if (card.match(cardno)) {
      return true;
    } else {
      return false;
    }
  }


  checkForNumbers({ keyCode: kc }) {
    // Allow numbers, backspace, left and right arrow keys
    // return kc <= 57 && kc >= 48 || kc >= 96 && kc <= 105 || kc === 8 || kc === 9 || kc === 37 || kc === 39;
}

  checkForNumbersOrLetters(e) {
    return e.keyCode <= 90 && e.keyCode >= 65 || this.checkForNumbers(e);
  }

  checkForValidDate() {
    const month = this.form.get('month').value;
    const year = this.form.get('year').value;

    if (month && year) {
      const date = new Date();
      const compareDate = new Date(year, month);
      this.expirationError = compareDate < date;
    } else {
      this.expirationError = false;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
