import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Instructor} from '@app/models/instructor';
import {ContentPlayerComponent} from '../../workbook/content-player/content-player.component';
import {SnackbarComponent} from '@app/components/shared/snackbar/snackbar.component';
import {InstructorService} from '@app/services/data-services/instructor.service';
import {NotificationService} from '@app/services/data-services/notification.service';
import {Notification} from '@app/models/notification/notification';

@Component({
  selector: 'app-instructor-info-dialog',
  templateUrl: './instructor-info-dialog.component.html',
  styleUrls: ['./instructor-info-dialog.component.scss']
})
export class InstructorInfoDialogComponent implements OnInit {
  content = this.data.content;
  isConnected = this.data.isConnectedInstructor;
  isPending = this.data.isPendingInstructor;
  remove = new EventEmitter();
  constructor(public dialogRef: MatDialogRef<ContentPlayerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private instructorService: InstructorService,
              private notificationService: NotificationService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  connectToInstructor(instructor) {
    this.instructorService.sendInstructorRequest(instructor)
      .then(res => {
        this.close(res);
        const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
          data: 'Invite sent'
        });
      })
      .catch(res => {
        if (res.indexOf('422') > -1) {
          const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
            data: 'Invite already sent'
          });
        }
      });
  }

  removeInstructor() {
    this.dialogRef.close('remove');
  }

  close(res: Notification) {
    this.dialogRef.close(res);
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
