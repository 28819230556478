import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import {Content} from '@app/models/course/content/content';
import {DatatableComponent} from "@swimlane/ngx-datatable";
import {ContentType, ContentTypeMap} from "@app/models/course/content/content-type";
import {ContentService} from "@app/services/data-services/content.service";
import {FormArray, FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-version-dialog',
  templateUrl: './version-dialog.component.html',
  styleUrls: ['./version-dialog.component.scss']
})
export class VersionDialogComponent implements OnInit {
  version: Array<any> = [];
  constructor(public dialogRef: MatDialogRef<VersionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.version["major_version"] = this.data.majorVersion;
    this.version["minor_version"] = this.data.minorVersion + 1;
  }

  ngOnInit() {

  }

  close() {
    this.dialogRef.close();
  }


  save() {
    this.dialogRef.close(this.version);
  }
}
