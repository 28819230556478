import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Content } from '@app/models/course/content/content';
import { ContentService } from '@app/services/data-services/content.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ContentType, ContentTypeMap } from '@app/models/course/content/content-type';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-content-manager-overview',
  templateUrl: './content-manager-overview.component.html',
  styleUrls: ['./content-manager-overview.component.scss']
})
export class ContentManagerOverviewComponent implements OnInit {
  selected = [];
  rows: Content[] = [];
  temp: Content[] = [];
  isLoading: boolean = false;
  @ViewChild('table', { static: true }) table: DatatableComponent;

  map = ContentTypeMap;
  content_type = ContentType;

  constructor(private router: Router,
              private contentService: ContentService,
              public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.getContent();
  }

  getContent() {
    this.contentService.getAllContent()
        .then((content) =>{
          this.rows = this.temp = content;
          this.isLoading = false;
        });
  }

  navigate(url) {
    this.router.navigate([url]);
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;

    const temp = this.temp.filter(d => {
      return d.title.toLowerCase().indexOf(val) !== -1
        || d.desc && d.desc.toLowerCase().indexOf(val) !== -1
        || d.content_type && $this.map[d.content_type].toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  editContent(row) {
    this.router.navigate(['admin/courses/content/manage', row.id]);
  }

  removeContent(row) {
    if (confirm('Are you sure you want to remove content item?')) {
      this.contentService.delete(row.id)
        .then(() => {
          this.snackbar.open('Content deleted', '', {
            duration: 2500
          });
          this.rows = this.rows.filter((item) => item.id !== row.id);
        });
    }
  }
}
