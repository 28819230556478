<div class="course-wrapper-alt heading">
  <h2>Authorized Training Network</h2>
  <p>Use the search tool below to connect directly with your instructor for programs or courses requiring an instructor.</p>
</div>

<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
<div class="course-wrapper-alt">
    <div [hidden]="isLoading" class="training-centers" fxLayout="column" fxLayoutAlign="center">
      <app-search
        [hidden]="isLoading"
        [dataSource]="trainingCentersDataSource"
        [additionalSearchTerms]="[qualificationSearchTerm]"
        [searchToken]="searchToken$"
      >
      </app-search>

      <div [hidden]="!(showCompleteList | async)">
        <h4>Search Results</h4>
        <app-training-partners-table
          [trainingPartners]="trainingCentersDataSource"
          [refreshToken]="refreshToken$"
          noRowsMessage="No instructors found"
          [deviceType]="deviceType$ | async"
        ></app-training-partners-table>

        <mat-paginator
          #paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        ></mat-paginator>
      </div>

      <div [hidden]="isLoading">
        <h4>Current Connections</h4>
        <app-training-partners-table
          [trainingPartners]="existingConnectionsDataSource"
          [refreshToken]="refreshToken$"
          noRowsMessage="You are not connected to any instructors."
          [deviceType]="deviceType$ | async"
        ></app-training-partners-table>
      </div>
    </div>
    
    <section class="partner-info">
      <h3>Need an Instructor?</h3>
      <p class="small">Our global network of over 700+ flight training professionals are highly-trained and ready to help you on your learning journey.</p>

      <div class="partner-types">
        <div class="type">
          <h3>CAMPUS</h3>
          <img src="assets/images/campus-icon.svg">
          <img src="assets/images/plus-icon.svg">
          <h6>Cirrus Aircraft Training Campus</h6>
          <div class="text-overlay">
            <p>Our Cirrus Aircraft Campuses provide the convenience of a factory-direct experience near you. Services include aircraft delivery, flight training, aircraft management, factory-direct service and more.</p>
          </div>
        </div>

        <div class="type">
          <h3>CTC</h3>
          <img src="assets/images/ctc-icon.svg">
          <img src="assets/images/plus-icon.svg">
          <h6>Cirrus Training Center</h6>
          <div class="text-overlay">
            <p>CTCs are dedicated flight training centers specializing in Cirrus Aircraft training. All CTC’s are trained and evaluated by our own training specialists to get you flying in a Cirrus quickly, efficiently and safely.</p>
          </div>
        </div>

        <div class="type">
          <h3>CSIP</h3>
          <img src="assets/images/csip-icon.svg">
          <img src="assets/images/plus-icon.svg">
          <h6>Cirrus Standardized Instructor Pilot</h6>
          <div class="text-overlay">
            <p>CSIPs are independent flight training professionals who are experts at Cirrus flight training. Each CSIP is trained and evaluated by our own training specialists to ensure a factory-level experience.</p>
          </div>
        </div>
      </div>

      <div class="partner-map-section">
        <p>Please click below to find an instructor near you.</p>
        <button (click)="toggleMap()" [ngClass]="showMap ? 'activated' : 'inactive'">Find an Instructor <img src="assets/images/down-chevron.svg" /></button>
        <div 
          class="partner-map-container"
          *ngIf="showMap == true" [@toggle]="showMap == true"
        >
          <iframe allow="geolocation" class="locator-map" height="100%" id="locator-map" src="https://locator.cirrusaircraft.com?filters_state=open" style="border:none;" title="locator map" width="100%">
          </iframe>
        </div>
      </div>
    </section>
</div>
