<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Instructors</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="full-width pb-1">
          <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="addToCourse()">Add Course</button>
            <button mat-menu-item (click)="removeFromCourse()">Remove Course</button>
            <button *ngIf='isCtc' mat-menu-item (click)="addTrainingPartner()">Connect Student</button>
            <button *ngIf='isCtc' mat-menu-item (click)="removeTrainingPartner()">Disconnect Student</button>
          </mat-menu>

          <button  mat-raised-button color="primary" [matMenuTriggerFor]="appMenu">
            More Actions
          </button>

          <input
            type='text'
            style='padding:8px;margin:15px auto;width:30%;'
            placeholder='Filter Instructors'
            (keyup)='updateFilter($event)'
          />
        </div>
        <div class="mat-box-shadow margin-333">
          <ngx-datatable #table
                         class="material bg-white"
                         [columnMode]="'force'"
                         [headerHeight]="50"
                         [footerHeight]="50"
                         [rowHeight]="'auto'"
                         [limit]="50"
                         [rows]="rows"
                         [loadingIndicator]="isLoading"
                         [selected]="selection.selected"
                         [selectionType]="'checkbox'"
                         (select)="onSelect($event)">
            <ngx-datatable-column
              [width]="30"
              [sortable]="false"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
              [headerCheckboxable]="true"
              [checkboxable]="true">
            </ngx-datatable-column>
            <ngx-datatable-column
              [sortable]="true"
              [canAutoResize]="true"
              [draggable]="false"
              [resizeable]="false"
              name="Name">
              <ng-template let-row='row' ngx-datatable-cell-template>
                {{row?.user?.contact?.name}}
              </ng-template>
              <ng-template let-row='row' *ngIf='isCtc' ngx-datatable-cell-template>
                {{row?.user?.name}}
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" prop='user.email'></ngx-datatable-column>
            <ngx-datatable-column *ngIf='!isCtc' name="Phone" prop='user.contact.contact_user.phone'></ngx-datatable-column>
            <ngx-datatable-column *ngIf='isCtc' name="Phone" prop='user.contact.phone'></ngx-datatable-column>
          </ngx-datatable>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
