<div fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Students</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <div class="full-width pb-1">

          <mat-menu #appMenu="matMenu">
            <button mat-menu-item (click)="notify()">Notify</button>
            <button mat-menu-item (click)="addInstructors()">Associate Instructor</button>
            <button mat-menu-item (click)="removeInstructor()">Remove Instructor</button>
          </mat-menu>

          <button mat-raised-button color="primary" [matMenuTriggerFor]="appMenu" *ngIf="isCtc">
            More Actions
          </button>


          <input type='text' style='padding:8px;margin:15px auto;width:30%;' placeholder='Filter Students'
            (keyup)='updateFilter($event)' />
        </div>

        <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        <table mat-table matSort [dataSource]="rows" class="mat-elevation-z3">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? toggleSelectAll() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let user">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(user) : null"
                [checked]="selection.isSelected(user)" [disabled]="!isSelectable(user)"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let user">{{isCtc ? user?.contact_user?.contact?.name : user?.contact?.contact_user?.name }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let user">{{ user.email }}</td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
            <td mat-cell *matCellDef="let user">{{ isCtc ? user?.contact_user?.phone : user?.contact?.contact_user?.phone  }}</td>
          </ng-container>

          <ng-container matColumnDef="role" *ngIf='isCtc'>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
            <td mat-cell *matCellDef="let user">{{ user.role | spaces | titlecase }}</td>
          </ng-container>

          <ng-container matColumnDef="status" *ngIf='isCtc'>
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
            <td mat-cell *matCellDef="let user">{{ (user.status ? user.status.toString() : '') | titlecase }}</td>
          </ng-container>

          <ng-container matColumnDef="menu">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let user">
              <button mat-icon-button [matMenuTriggerFor]="msgMenu" class="hidden-on-open">
                <mat-icon class="text-muted">more_vert</mat-icon>
              </button>
              <mat-menu #msgMenu="matMenu">
                <button mat-menu-item (click)='TCIConnections(user)' *ngIf='isCtc'>TCI Connections</button>
                <button mat-menu-item (click)='viewTranscripts(user)'>Transcripts</button>
                <button mat-menu-item (click)='viewAssessments()'>Assessments</button>
                <button mat-menu-item *ngIf='isCtc && user.status !== "disconnected"' (click)='showRemoveStudentAlert(user)'>Disconnect</button>
                <button mat-menu-item *ngIf='!isCtc' (click)='showRemoveStudentAlert(user)'>Remove</button>

              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSize]="15"></mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
