<div fxFlex='100' fxLayout='row'>
  <div class="editor-wrapper" fxLayout='row wrap' fxFlex='grow'>
    <mat-card class="p-0" fxFlex='grow'>
      <mat-card-title>
        <div class="card-title-text">Edit Avionics</div>
        <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form #form="ngForm">
          <div class="form-row">
            <label for="avionics-name" class="form-label">Name</label>
            <input id="avionics-name" name="avionics-name" class="form-input" [(ngModel)]="avionics.name" placeholder="Name" required>
          </div>
          <div class="form-row">
            <label for="avionics-desc" class="form-label">Description</label>
            <textarea id="avionics-desc" name="avionics-desc" class="form-textarea" [(ngModel)]="avionics.desc" placeholder="Description"></textarea>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="submit-bar" class="sidebar-panel navigation-hold" @slideInRight fxFlex="nogrow">
    <button class="btn save-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="updateAvionics()" [disabled]="!form.valid">Save <mat-icon>save</mat-icon></button>
    <button class="btn cancel-btn" fxLayout="row" fxLayoutAlign="space-between center" (click)="cancel()">Cancel <mat-icon>cancel</mat-icon></button>
  </div>
</div>
