import { Component, OnInit } from '@angular/core';
import {Assignment} from '@app/models/course/assignment';
import {slideInRight} from '@app/animations/animations';
import {AssignmentService} from '@app/services/data-services/assignment.service';
import {Router} from '@angular/router';
import {HttpService} from '@app/services/http-service.service';

@Component({
  selector: 'app-assignments-manager-add',
  templateUrl: './assignments-manager-add.component.html',
  styleUrls: ['./assignments-manager-add.component.scss'],
  animations: [
    slideInRight
  ]
})
export class AssignmentsManagerAddComponent implements OnInit {
  assignment_model = new Assignment();
  imageUrl = '';

  constructor(private assignmentService: AssignmentService, private router: Router, private http: HttpService) { }

  ngOnInit() {
  }

  createAssignment() {
    this.assignmentService.create(this.assignment_model)
      .then(() => this.assignment_model = new Assignment());
  }

  cancel() {
    this.router.navigate(['instructor/classroom/assignments']);
  }

}
