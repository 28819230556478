import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ApiCsrfInterceptor implements HttpInterceptor {
  static readonly excludeMethods = ['GET', 'HEAD'];

  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let modifiedRequest = request;
    if (!ApiCsrfInterceptor.excludeMethods.includes(request.method)) {
      const xsrfToken = this.cookieService.get('XSRF-TOKEN');
      modifiedRequest = request.clone({
        headers: request.headers.set('X-CSRF-TOKEN', xsrfToken)
      });
    }
    return next.handle(modifiedRequest);
  }
}
