import { Component, OnInit } from '@angular/core';
import {IMenuItem} from '@app/services/navigation/navigation.service';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.scss']
})
export class UserManagerComponent implements OnInit {
  navItems: IMenuItem[] = [
    {
      name: 'Users',
      state: '/admin/user-manager/users',
      type: 'link'
    },
    {
      name: 'Instructors',
      state: '/admin/user-manager/instructors',
      type: 'link'
    }
  ];
  constructor() {
  }

  ngOnInit() {
  }


}
