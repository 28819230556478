<mat-card
  class="bg-white material no-margin"
  [ngClass]="
    instructor ? 'lesson-section-card-instructor' : 'lesson-section-card'
  "
>
  <div class="item-row">
    <div class="centered">
      <mat-icon
        [ngClass]="instructor ? 'instructor-only' : 'default-lesson-section'"
        >menu</mat-icon
      >
      <div class="lesson-section-title">
        {{ instructor ? 'Instructor Lesson Section' : 'Student Lesson Section' }}
      </div>
    </div>
    <div class="row" *ngIf="form">
      <app-mat-menu
      *ngIf="instructor"
      [menuIcon]="'more_vert'"
      (emitMenuItem)="menuClick($event)"
      [inputItems]="instructor ? menuItemsInstructor : menuItemsStudent"
      [iconStyle]="{
        color: instructor ? 'white' : 'black',
        backgroundColor: instructor ? '#444' : 'white'
      }"
      [isToggled]="toggleValue"
      [menu_style]="menu_style_intro"
    ></app-mat-menu>
      <mat-icon
        [ngClass]="instructor ? 'instructor-only' : 'default-lesson-section'"
        (click)="section_collapsed = !section_collapsed"
        >{{ section_collapsed ? 'arrow_drop_down' : 'arrow_drop_up' }}</mat-icon
      >
    </div>
  </div>

  <ng-container *ngIf="!section_collapsed && form" [formGroup]="form">
    <app-content-section-item
      [item_name]="'Intro Section'"
      (emitRichText)="addRichText($event)"
      [instructor]="instructor"
      [menu_style]="menu_style_media"
    ></app-content-section-item>
    <app-content-section-item
      [item_name]="'Media'"
      (emitContent)="addContent($event)"
      [instructor]="instructor"
      [menu_style]="menu_style_media"
    ></app-content-section-item>
  </ng-container>
</mat-card>
