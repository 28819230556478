import { Injectable } from '@angular/core';

import { HttpService } from '@app/services/http-service.service';
import { Lesson } from '@app/models/course/workbook/lesson/lesson';
import { catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class LessonService {
  private lessonUrl = 'lessons';

  constructor(private http: HttpService) {
  }

  getLessonsOverview(): Promise<any[]> {
    const url = `${this.lessonUrl}/overview`;

    return this.http.get<any[]>(url)
      .toPromise()
      .catch(LessonService.handleError);
  }

  getLessonsForDialog(): Promise<Lesson[]> {
    const url = `${this.lessonUrl}/dialog`;
    return this.http.get<Lesson[]>(url)
      .toPromise()
      .catch(LessonService.handleError);

  }

  findLesson(id: number): Observable<Lesson> {
    const url = `${this.lessonUrl}/${id}`;
    return this.http.get<Lesson>(url).pipe(
      catchError(LessonService.handleError)
    )

  }

  archiveLesson(id: number): Promise<void> {
    const url = `${this.lessonUrl}/${id}/archive`;

    return this.http.put(url, '')
      .toPromise()
      .then(() => null)
      .catch(LessonService.handleError);
  }

  updateArchive(lesson: any): Promise<void> {
    const url = `${this.lessonUrl}/update_archive/${lesson.id}`;
    return this.http
    .put(url, JSON.stringify(lesson))
    .toPromise()
    .then(() => null)
    .catch(LessonService.handleError);
  }

  create(lesson: Lesson): Promise<void> {
    const url = `${this.lessonUrl}`;
    return this.http.post(url, lesson)
    .toPromise()
    .then(() => null)
    .catch(LessonService.handleError);
  }

  update(lesson: Lesson): Promise<void> {
    const url = `${this.lessonUrl}/${lesson.id}`;
    return this.http.put(url, lesson)
      .toPromise()
      .then(() => null)
      .catch(LessonService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
