import {
  Component,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Notification } from '@app/models/notification/notification';
import { NotificationService } from '@app/services/data-services/notification.service';
import { GlobalEventManagerService } from '@app/services/global-event-manager/global-event-manager.service';
import { SnackbarComponent } from '@app/components/shared/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;
  @Input() updatedFlag;
  @Input() notifications$: Observable<Notification[]>;
  @Input() notificationsToken: BehaviorSubject<Notification[]>;

  notificationsLength: number;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    private globalEventManagerService: GlobalEventManagerService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('cirrus-token')) {
      this.notificationService.getMyNotifications().then(notif => {
        if (notif.length) {
          this.notificationsLength = notif.length;
        }
      });
    }

    this.router.events.subscribe(routeChange => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
  }

  acceptInvite(notification: Notification) {
    this.notificationService
      .acceptInvite(notification)
      .then(() => this.notificationService.getMyNotifications())
      .then(notifications => {
        this.notificationsLength = notifications.length;

        this.notificationsToken.next(notifications);
        this.globalEventManagerService.updateNotifications(notifications);
      })
      .then(() => {
        const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
          data: 'Accepted Invitation',
        });
      });
  }

  declineInvite(notification: Notification) {
    if (confirm('Are you sure you want to decline invite?')) {
      this.notificationService
        .declineInvite(notification)
        .then(() => this.notificationService.getMyNotifications())
        .then(notifications => {
          this.notificationsToken.next(notifications);
          this.notificationsLength = notifications.length;
        })
        .then(() => {
          const snackBar = this.snackBar.openFromComponent(SnackbarComponent, {
            data: 'Declined Invitation',
          });
        });
    }
  }

  clearAll(e) {
    e.preventDefault();
    this.notifications$
      .pipe(first()) // Unsubscribe after the first iteration so we don't continually clear notifications
      .subscribe(notifications => {
        this.notificationsLength = notifications.length;
        this.notificationService
          .clearNotifications(notifications)
          .then(() => this.notificationService.getMyNotifications())
          .then(newNotifications => {
            this.notificationsToken.next(newNotifications);
            this.globalEventManagerService.updateNotifications(
              newNotifications
            );
          });
      });
  }

  removeNotification(notification) {
    this.notificationService
      .clearNotifications([notification])
      .then(() => this.notificationService.getMyNotifications())
      .then(newNotifications => {
        this.notificationsLength = newNotifications.length;

        this.notificationsToken.next(newNotifications);
        this.globalEventManagerService.updateNotifications(newNotifications);
      });
  }

  close() {
    this.notificPanel.close();
  }
}
