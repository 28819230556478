import { Injectable } from '@angular/core';
import { HttpService } from '@app/services/http-service.service';
import { ContentBookshelf } from '@app/models/content-bookshelf';
import { Bookmark } from '@app/models/course/bookmark';
import { Content } from '@app/models/course/content/content';

@Injectable()
export class BookshelfService {
  bookshelfUrl = 'bookshelves';

  constructor(private http: HttpService) {}

  getStudentBookshelf(): Promise<Content[]> {
    return this.http.get<Content[]>(`${this.bookshelfUrl}/student`)
      .toPromise()
      .catch(BookshelfService.handleError);
  }


  getOneContentBookshelf(id: number): Promise<ContentBookshelf> {
    const url = `${this.bookshelfUrl}/${id}`;
    return this.http.get<ContentBookshelf>(url)
      .toPromise()
      .catch(BookshelfService.handleError);
  }

  getInstructorBookshelf(): Promise<ContentBookshelf[]> {
    const url = `${this.bookshelfUrl}/instructor`;

    return this.http.get<ContentBookshelf[]>(url)
      .toPromise()
      .catch(BookshelfService.handleError);
  }

  delete(id: number): Promise<void> {
    const url = `${this.bookshelfUrl}/${id}`;
    return this.http.delete(url)
      .toPromise()
      .then(() => null)
      .catch(BookshelfService.handleError);
  }


  create(content: ContentBookshelf): Promise<ContentBookshelf> {
    return this.http
      .post<ContentBookshelf>(this.bookshelfUrl, JSON.stringify(content))
      .toPromise()
      .catch(BookshelfService.handleError);
  }

  update(content: ContentBookshelf): Promise<void> {
    const url = `${this.bookshelfUrl}/${content.id}`;
    return this.http
      .put(url, JSON.stringify(content))
      .toPromise()
      .then(() => null)
      .catch(BookshelfService.handleError);
  }

  bookmark(id: number): Promise<Bookmark> {
    const url = `${this.bookshelfUrl}/bookmark/${id}`;
    return this.http.post<Bookmark>(url, JSON.stringify({}))
      .toPromise()
      .catch(BookshelfService.handleError);
  }

  private static handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
