import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Exam } from '@app/models/course/content/quiz/exam';
import { ExamService } from '@app/exams.service';

@Component({
  selector: 'app-exam-dialog-template',
  templateUrl: './exam-dialog-template.component.html',
  styleUrls: ['./exam-dialog-template.component.scss']
})
export class ExamDialogTemplateComponent implements OnInit, AfterViewInit {
  @Input() course: any;
  @Output() add_exam: EventEmitter<any> = new EventEmitter<any>();
  selected = [];
  rows: Exam[] = [];
  temp: Exam[] = [];
  @ViewChild('table', { static: true }) table: DatatableComponent;
  selectedExam: any;

  constructor(private examService: ExamService) {}

  ngOnInit() {
    this.examService.getExams()
    .subscribe(exams => {
      this.rows = exams;
    })
  }

  ngAfterViewInit() {
    this.table.offset = 0;
  }

  save() {
    if (this.selectedExam) {
      this.add_exam.emit(this.selectedExam);
    }
  }

  onSelect(row) {
    this.selectedExam = row.selected[0];
  }

  cancel() {
    this.add_exam.emit();
  }

  close() {
    this.cancel();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const $this = this;
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || d.desc.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

}
